import { Toaster as RHToaster, ToastBar, ToastOptions, toast as rhToast } from "react-hot-toast";
import cn from "classnames";
import { ReactNode } from "react";
import { X } from "lucide-react";
import { nanoid } from "nanoid";

export function Toaster() {
  return (
    <RHToaster
      position="top-right"
      toastOptions={{ duration: 50000, style: { padding: 0 }, className: "w-[1000px]" }}
    >
      {(t) => (
        <ToastBar toast={t} style={{ width: 550 }}>
          {({ icon, message }) => (
            <div className="flex rounded-xl">
              <div
                className={cn("rounded-l-lg w-12", {
                  "bg-red": t.type === "error",
                  "bg-bottleGreen ": t.type === "success",
                })}
              />
              <div className="h-full w-full items-center justify-center p-2">{message}</div>
            </div>
          )}
        </ToastBar>
      )}
    </RHToaster>
  );
}

export const toastText = (title: string | ReactNode, text: string | ReactNode, id?: string) => (
  <div>
    <div className="flex items-center justify-between gap-x-4">
      <div className="text-lg font-semibold text-midnight">{title}</div>
      <X onClick={() => rhToast.dismiss(id)} size="16px" className="cursor-pointer" />
    </div>

    <span className="text-grey">{text}</span>
  </div>
);

export const toast = {
  success: (title: string | ReactNode, text: string | ReactNode, options?: ToastOptions) => {
    const id = nanoid();
    return rhToast.success(toastText(title, text, id), { id, ...options });
  },
  error: (title: string | ReactNode, text: string | ReactNode, options?: ToastOptions) => {
    const id = nanoid();
    return rhToast.error(toastText(title, text, id), { id, ...options });
  },
};
