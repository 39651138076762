import { useEffect, useState } from "react";
import { useUser } from "$/state/user";
import { ROUTE_PATHS } from "$/configs/routes";
import {
  Session,
  SessionState,
  useCancelSessionMutation,
  useCancelUserPackageMutation,
  useGetSessionByIdQuery,
  useUpdateUserPackageMutation,
} from "$/graphql/types.generated";
import { format, isFuture } from "date-fns";
import { useNavigate } from "react-router";
import { SchedulerProvider } from "../Sessions/Scheduler/schedulerContext";
import { Schedule } from "../Sessions/Coach";
import { app } from "$/configs/app.config";
import { CoacheeSessionCard } from "$/components/SessionCard/CoacheeSessionCard";
import { orderBy } from "lodash";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import cn from "classnames";
import { Modal } from "$/components/Modal";
import { Button } from "$/components/Button";
import { useCompanySubscription } from "$/state/company-subscription";
import { X } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
import EndOfProgramme from "./EndOfProgramme";

const Tile = ({ title, subtitle, href, className, onClick = () => {} }) => {
  const content = (
    <div>
      <h3 className="text-2xl font-semibold">{title}</h3>
      <p className="text-lg">{subtitle}</p>
    </div>
  );
  if (href) {
    return (
      <a
        href={href}
        className={cn(className, "rounded-3xl p-4 hover:no-underline hover:opacity-80")}
      >
        {content}
      </a>
    );
  } else {
    return (
      <div
        onClick={onClick}
        className={cn(
          className,
          "rounded-3xl p-4 hover:no-underline hover:opacity-80 cursor-pointer",
        )}
      >
        {content}
      </div>
    );
  }
};

const getSessionNumberAsText = (sessionLimit: number, bookedSessions: number) => {
  if (sessionLimit === bookedSessions - 1) return "final";
  if (bookedSessions === 0) return "first";
  if (bookedSessions === 1) return "second";
  if (bookedSessions === 2) return "third";
  if (bookedSessions === 3) return "fourth";
  if (bookedSessions === 4) return "fifth";
  if (bookedSessions === 5) return "sixth";
  if (bookedSessions === 6) return "seventh";
  if (bookedSessions === 7) return "eighth";
  if (bookedSessions === 8) return "ninth";
  if (bookedSessions === 9) return "tenth";
  return "next";
};

export const PackageDashboard = () => {
  const user = useUser();
  const companySubscription = useCompanySubscription();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [endPackageModalOpen, setEndPackageModalOpen] = useState(false);
  const [showScheduler, setShowScheduler] = useState<null | "automatic" | "manual">(null);

  const SPONSOR_SUB_NAVIGATION_ITEMS = [
    {
      id: "id1",
      navTitle: "More Happi 101",
      title: "Tell your team about More Happi",
      href: "https://midnight-revolve-64c.notion.site/1-Tell-your-team-about-More-Happi-baefc4a0fcc24c69830fbf214ecf0a08",
    },
    {
      title: "Learn how to use More Happi",
      subtitle: 'Watch our "How to use Coaching" playlist',
      href: ROUTE_PATHS.ONBOARDING_VIDEOS,
    },
    {
      title: "Manage my team",
      subtitle: "Add, edit or remove users",
      href: ROUTE_PATHS.COMPANY.MANAGE_TEAM,
    },
  ];

  const TEAM_MEMBER_SUB_NAVIGATION_ITEMS = [
    {
      title: "Learn how to use More Happi",
      subtitle: 'Watch our "How to use Coaching" playlist',
      href: ROUTE_PATHS.ONBOARDING_VIDEOS,
    },
  ];

  const INDIVIDUAL_SUB_NAVIGATION_ITEMS = [
    {
      title: "Learn how to use More Happi",
      subtitle: 'Watch our "How to use Coaching" playlist',
      href: ROUTE_PATHS.ONBOARDING_VIDEOS,
    },
    {
      title: "Change my coach",
      subtitle: "Change the currently selected coach for your package",
      onClick: () => navigate("/coaches?ctx=change"),
    },
  ];

  const NAV_ITEMS = user.currentUser?.isAdmin
    ? SPONSOR_SUB_NAVIGATION_ITEMS
    : companySubscription.state.subscriptionCustomer
    ? TEAM_MEMBER_SUB_NAVIGATION_ITEMS
    : INDIVIDUAL_SUB_NAVIGATION_ITEMS;

  const currentPackage = user.currentUser.activePackage;
  const coachFirstName = currentPackage?.coach?.displayName.split(" ")[0];

  const nextSessionId = orderBy(
    currentPackage?.validSessions.filter(
      (session) => session.state === SessionState.Confirmed || SessionState.ConfirmedCoach,
    ),
    "startDate",
    "asc",
  ).find((session) => {
    return isFuture(new Date(session.startsAt));
  })?.id;

  const nextSession = useGetSessionByIdQuery({ id: nextSessionId }, { enabled: !!nextSessionId });
  const updateUserPackage = useUpdateUserPackageMutation();
  const cancel = useCancelSessionMutation();
  const cancelProgramme = useCancelUserPackageMutation();
  const programmeQuiz = useGetProgrammeQuizQuery({ id: `programmes/${currentPackage?.plan}` });

  useEffect(() => {
    if (
      !nextSessionId ||
      (nextSession.isFetched && !nextSession?.data) ||
      user?.currentUser.activePackage?.validSessions?.length === 0
    ) {
      setShowScheduler("automatic");
    } else if (searchParams.get("showScheduler") === "true") {
      setShowScheduler("manual");
    }
  }, [nextSession.isLoading]);

  if (user.isLoading) return <div>Loading...</div>;

  if (!currentPackage) return <EndOfProgramme />;

  return (
    <div className="max-w-[1240px] my-10 mx-auto px-4">
      {programmeQuiz?.isSuccess && (
        <h2 className=" max-w-[750px] mx-auto text-center font-normal mb-1">
          You are on the{" "}
          {programmeQuiz.data.PageItem.content.docsPage ? (
            <a
              href={programmeQuiz.data.PageItem.content.docsPage}
              target="_blank"
              rel="noreferrer"
              className="text-midnight underline hover:no-underline"
            >
              <span className="font-semibold">
                {programmeQuiz.data.PageItem.content.displayName}
              </span>
            </a>
          ) : (
            <span className="font-semibold">{programmeQuiz.data.PageItem.content.displayName}</span>
          )}{" "}
          programme
        </h2>
      )}
      <p className="text-center text-lg">
        You have booked {currentPackage.validSessions.length} out of {currentPackage?.sessionCount}{" "}
        sessions in your programme.{" "}
        {currentPackage.endDate && (
          <span>
            Your programme ends on {format(new Date(currentPackage.endDate), "dd/MM/yyyy")}.
          </span>
        )}
      </p>
      {nextSession?.data && (
        <>
          <h1 className="font-semibold text-center max-w-[650px] mx-auto">
            Your{" "}
            {getSessionNumberAsText(
              currentPackage.sessionCount,
              currentPackage.validSessions.findIndex(
                (session) => session.id === nextSession.data.getSession.id,
              ) ?? 0,
            )}{" "}
            session with {coachFirstName} is upcoming
          </h1>
          <div className="flex items-center justify-center">
            {nextSession?.data?.getSession ? (
              <CoacheeSessionCard
                session={nextSession?.data?.getSession as Session}
                timescale="future"
                onPlaceAnother={() => setShowScheduler("manual")}
                placeAnotherText={`Book ${getSessionNumberAsText(
                  currentPackage.sessionCount,
                  currentPackage?.validSessions.length,
                )} session`}
                hideActions={currentPackage.hitSessionLimit}
              />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}

      {showScheduler && (
        <div className={cn({ "bg-white my-10 p-8 rounded-3xl": showScheduler === "manual" })}>
          {showScheduler === "manual" && (
            <Button tertiary leftIcon={<X />} onClick={() => setShowScheduler(null)}>
              Close
            </Button>
          )}

          {!currentPackage.hitSessionLimit && (
            <>
              <h1 className="font-semibold text-center max-w-[500px] mx-auto scroll">
                Book your{" "}
                {currentPackage.validSessions.length > 0 ? "next session" : "first session"} with{" "}
                {coachFirstName} now ({(currentPackage?.validSessions?.length ?? 0) + 1}/
                {currentPackage?.sessionCount})
              </h1>
              <div className="flex items-center justify-center gap-4 mt-20">
                <img
                  src={`${app.CLOUDFRONT.USER_ASSETS}/${currentPackage.coachId}/thumbnail.jpg`}
                  alt="profile"
                  className="w-12 h-12 rounded-full"
                />
                <h3 className="font-semibold text-2xl m-0">{coachFirstName}&apos;s availability</h3>
              </div>
              <SchedulerProvider>
                <Schedule
                  coachId={currentPackage.coachId}
                  coachHandle={currentPackage.coach.handle}
                  postBookingUrl="dashboard"
                  context={{
                    package: currentPackage.id,
                  }}
                  lastDate={currentPackage.endDate}
                />
              </SchedulerProvider>
            </>
          )}

          {currentPackage.hitSessionLimit && (
            <div className="flex flex-col items-center">
              <h1 className="font-semibold text-center mx-auto mt-8">
                You have booked all the sessions for this plan
              </h1>
              <p className="text-center text-lg">
                We only allow you to have one plan active at any time. You will be able to start a
                new plan when your current one is over.
              </p>
              <p className="text-center text-lg">You can book an ad-hoc session at any time.</p>
              <a href="/coaches" className="">
                <Button primary>Book an ad-hoc session</Button>
              </a>
            </div>
          )}
        </div>
      )}

      {companySubscription.state.subscriptionCustomer && (
        <div className="flex gap-4 mt-8">
          <Tile
            className={cn(
              "w-full sm:w-[49%] md:w-[33%] bg-white border border-solid border-bottleGreen",
            )}
            title="I want to start a new programme"
            subtitle="This will end your current programme, cancelling any sessions you have booked and will start a new 3 or 6 session programme."
            href="/enquiry"
          />
          <Tile
            className={cn(
              "w-full sm:w-[49%] md:w-[33%] bg-white border border-solid border-yellow",
            )}
            title="I want to change my coach"
            subtitle="This will change your coach for your current programme."
            href="/coaches?ctx=change"
          />
          <Tile
            className="w-full sm:w-[49%] md:w-[33%] bg-white border border-solid border-red"
            title="I want to end my programme"
            subtitle="This will end your current programme, cancelling any sessions you have booked. You will still be able to book ad-hoc sessions."
            onClick={async () => {
              setEndPackageModalOpen(true);
            }}
          />
        </div>
      )}

      <div>
        <a href="/sessions" className="hover:no-underline">
          <div className="bg-white mt-4 rounded-xl p-4 mt-20 cursor-pointer hover:opacity-80">
            <div className="text-3xl w-full text-center font-semibold">
              View my previous and upcoming sessions
            </div>
          </div>
        </a>
        <h2 className="mt-6 text-center text-3xl mb-4">Other quick actions</h2>
        <div className="flex flex-wrap gap-2 justify-center ">
          {NAV_ITEMS.map((navItem, index) => (
            <Tile
              key={`navItem-${index}`}
              className={cn("w-full sm:w-[49%] md:w-[24%] bg-white")}
              {...navItem}
            />
          ))}
        </div>
        {companySubscription.state.subscriptionCustomer && (
          <div className="flex flex-col items-center mt-20 gap-4 max-w-[600px] text-center mx-auto">
            <img src="/images/Umbrella.svg" alt="" />
            <h2 className="text-4xl mb-0"> Need a coaching session about something urgent? </h2>
            <p className="text-lg">
              {" "}
              Book an ad-hoc coaching session with one of our next available coaches{" "}
            </p>
            <a href="/coaches">
              <Button large primary>
                Book an ad-hoc session
              </Button>
            </a>
          </div>
        )}
      </div>

      {endPackageModalOpen && (
        <Modal onClose={() => setEndPackageModalOpen(false)}>
          <h1 className="text-center mb-2">Are you sure you want to cancel your programme?</h1>
          <p className="text-center text-lg">
            This will cancel any future sessions you have booked on this programme.
          </p>
          <p className="text-center text-lg">You will still be able to book ad-hoc sessions.</p>
          <div className="flex justify-between gap-4 mt-8">
            <Button large primary onClick={() => setEndPackageModalOpen(false)}>
              Keep my programme
            </Button>
            <Button
              large
              error
              onClick={async () => {
                await cancelProgramme.mutateAsync({
                  id: currentPackage.id,
                });
                await Promise.all(
                  user.currentUser.activePackage.sessions
                    .filter(
                      (s) =>
                        s.state === (SessionState.Confirmed || SessionState.ConfirmedCoach) &&
                        isFuture(new Date(s.startsAt)),
                    )
                    .map(async (s) => {
                      return cancel.mutateAsync({
                        sessionId: s!.id,
                        reason: "User has cancelled their programme",
                      });
                    }),
                );
                navigate(0);
              }}
            >
              Cancel my programme
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
