import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Input, Space } from "antd";
import { useState } from "react";

interface SearchFilterProps {
  name: string;
  handleSearch: (value: any) => void;
  value?: string;
  handleReset: () => void;
}

export function SearchFilter({ name, handleSearch, value, handleReset }: SearchFilterProps) {
  const [_value, setValue] = useState(value ?? "");
  return (
    <div className="p-md">
      <Input
        placeholder={`Search ${name}`}
        value={_value}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={() => {
          handleSearch(_value);
        }}
        className="search-input-table-ant-d"
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(_value)}
          icon={<SearchOutlined />}
          size="small"
          className="search-button-table-ant-d"
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setValue("");
            handleReset();
          }}
          size="small"
          className="search-button-table-ant-d"
        >
          Reset
        </Button>
      </Space>
    </div>
  );
}

interface SelectFilterProps {
  handleSearch: (value: any) => void;
  options: { label: string; value: string; checked: boolean }[];
  handleReset: () => void;
}

export function SelectFilter({ handleSearch, options, handleReset }: SelectFilterProps) {
  const [_value, setValue] = useState(options);
  return (
    <div className="p-md ">
      {_value?.map((option) => {
        return (
          <div key={option.label} className="p-2">
            <Checkbox
              key={`${option.label}-checkbox-${option.checked}`}
              checked={option.checked ?? false}
              onChange={(e) =>
                setValue((prev) => {
                  const updated = [...prev];
                  const index = prev.findIndex(({ value }) => value === option.value);
                  updated[index] = { ..._value[index], checked: e.target.checked };
                  return updated;
                })
              }
            >
              {option.label}
            </Checkbox>
          </div>
        );
      })}
      <hr className="border-solid border-lightGrey " />

      <Space>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(Object.fromEntries(_value.map(({ value, checked }) => [value, checked])))
          }
          icon={<SearchOutlined />}
          size="small"
          className="search-button-table-ant-d"
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setValue([]);
            handleReset();
          }}
          size="small"
          className="search-button-table-ant-d"
        >
          Reset
        </Button>
      </Space>
    </div>
  );
}
