import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "$/components/Toaster";
import { Spin } from "antd";
import { BrowserRouter } from "react-router-dom";

import { IntercomProvider } from "react-use-intercom";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AuthProvider } from "$/state/auth";
import { UserProvider } from "$/state/user";
import { CompanyProvider } from "$/state/company";
import { CompanySubscriptionProvider } from "$/state/company-subscription";

import { LoadingSpinner } from "$/components/LoadingSpinner";
import { ErrorBoundary } from "$/components/ErrorBoundary";

import { Pages } from "$/pages";

import { queryClient } from "$/utils/query-client";

import { app } from "$/configs/app.config";
import { FeatureProvider } from "./state/features";

Spin.setDefaultIndicator(<LoadingSpinner />);

export const App = () => {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <CompanySubscriptionProvider>
              <UserProvider>
                <CompanyProvider>
                  <FeatureProvider>
                    <IntercomProvider appId={app.INTERCOM_APP_ID}>
                      <Toaster />
                      <ReactQueryDevtools />
                      <BrowserRouter>
                        <Pages />
                      </BrowserRouter>
                    </IntercomProvider>
                  </FeatureProvider>
                </CompanyProvider>
              </UserProvider>
            </CompanySubscriptionProvider>
          </AuthProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};
