import { Button, Divider } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Link } from "react-router-dom";

import { useUser } from "$/state/user";

import { ROUTE_PATHS } from "$/configs/routes";

export const SubscriptionRedirectModal = ({ isOpen, onOk, onClose }: any) => {
  const user = useUser();

  const modalMessageHelper = () => {
    if (user?.currentUser?.isAdmin || user?.currentUser?.role === "individual") {
      return "Your subscription is currently inactive. You can resolve this by visiting the Billing page, you can get to it by clicking the link below.";
    }

    if (user?.currentUser?.isTeamMember) {
      return "Your subscription is currently inactive. Please contact your administrator to resolve this issue in order to book a coaching call.";
    }
  };

  return (
    <Modal
      visible={isOpen}
      onOk={onOk}
      onCancel={onClose}
      cancelButtonProps={{ style: { display: "none" } }}
      title="Your Subscription"
      okText="Ok"
    >
      <p>{modalMessageHelper()}</p>
      <Divider />
      {(user?.currentUser?.isAdmin || user?.currentUser?.role === "individual") && (
        <div className="payment-portal-container">
          <Link to={ROUTE_PATHS.COMPANY.BILLING}>
            <Button>Manage Subscription</Button>
          </Link>
        </div>
      )}
    </Modal>
  );
};
