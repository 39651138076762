import { Button } from "$/components/Button";
import {
  ListUserPackagesQuery,
  OrderByArg,
  SessionState,
  useListUserPackagesQuery,
} from "$/graphql/types.generated";
import { getProgrammes, useUser } from "$/state/user";
import { Table } from "$/components/Table";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { Plus } from "lucide-react";

const columnHelper = createColumnHelper<ListUserPackagesQuery["listUserPackages"]>();

export const PackageManagement = () => {
  const user = useUser();

  const hasActive = user.currentUser.activePackage;
  const hasInactive = user.currentUser.activePackage
    ? user.currentUser.packages?.length > 1
    : user.currentUser.packages?.length > 0;

  const packages = orderBy(
    getProgrammes(user.currentUser.packages),
    ["expired", "hitSessionLimit", "createdAt"],
    ["asc", "asc", "desc"],
  );

  const columns = [
    columnHelper.accessor((col) => col, {
      header: "Status",
      cell: ({ getValue }) => {
        const col = getValue();
        if (col.expired) return <div>Expired</div>;
        if (col.hitSessionLimit) return <div>Session limit reached</div>;
        return <div>Active</div>;
      },
      enableSorting: false,
    }),
    columnHelper.accessor((col) => col.label, {
      header: "Label",
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((col) => col.createdAt, {
      header: "Start date",
      cell: ({ getValue }) => <span>{dayjs(getValue()).format("DD/MM/YYYY")}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((col) => col.sessionCount, {
      header: "Number of sessions",
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
    }),
    columnHelper.accessor((col) => col.endDate, {
      header: "Expires",
      cell: ({ getValue }) => {
        const date = getValue();
        if (!date) return <span>N/A</span>;
        return <span>{dayjs(getValue()).format("DD/MM/YYYY")}</span>;
      },
      enableSorting: false,
    }),
    columnHelper.accessor((col) => col.coach.displayName, {
      header: "Coach",
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
    }),
  ];

  if (hasActive) {
    return (
      <div>
        <h1>You have an active package</h1>
        <Table columns={columns} data={packages} filters={[]} setFilters={() => {}} />
      </div>
    );
  }

  if (hasInactive) {
    return (
      <div>
        <div className="flex flex-col md:flex-row items-baseline justify-between gap-4">
          <h1 className="mb-0">You don&apos;t have any active packages</h1>
          <a href="/enquiry" target="_blank" rel="noopener">
            <Button primary leftIcon={<Plus size={16} />}>
              Add new package
            </Button>
          </a>
        </div>
        <Table columns={columns} data={packages} filters={[]} setFilters={() => {}} />
      </div>
    );
  }
};
