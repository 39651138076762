// This is a slightly hacky way to get different coach package lists for individuals and teams.
// We did this as part of an experiement in March 2024.
// In the future it would probably be better to add something to the database such as CoachPackage.individual (boolean) and CoachPackage.team (boolean)

import { AccountType, CoachPackage } from "$/graphql/types.generated";

export const coachPackagesForCompanyType = (accountType: AccountType) => {
  const team = [
    "c5310bda-7620-48fd-b0a7-e8aa22ab7aac", // Cope with anxiety and self-doubt
    "70aacf95-ef37-4ddd-8091-62e55026de74", // Develop better habits for my wellbeing
    "2f29cc6f-353a-4292-ace4-0faa7a2d227a", // Feel more confident in new situations
    "1ed9e12e-8197-497e-9af6-4fa833e9dcdd", // Going back to work after a break / parental leave
    "4712ad73-6811-47f6-9304-3b58562eef47", // How to be successful in my role
    "912649e0-61a5-417c-ac2a-f440dd3a7748", // Finding my purpose
    "fdc58c11-cf71-4957-9983-344bcc362ce8", // Improve a relationship
    "63a8782b-27dd-4aa1-be93-fda2dcf1950f", // Manage stress and avoid burnout
    "ac967000-2695-4534-b0fe-575aa4f80224", // Being a better parent
    "a2107b58-ea7a-44ce-b8d7-67f1cee6da55", // Resolve conflicts and communicate better
    "cf1339b3-e9d4-44bb-aca3-351123150dd9", // Set and reach career + life goals
    "2e48068a-a4d7-432c-abf6-7925da680c33", // Solve a problem / make a clear decision
    "feedfdf7-901b-47dd-ac6e-2e07476ae8ab", // Use my time more effectively
    "1bc3a3cb-9f1b-403d-ad92-05f09650605e", // Being successful in a new role
    "c84ee5c1-74cf-4efd-9510-8a42eceb8c8c", // Returning from parental leave
    "082bb4db-88e7-4a78-952b-55ccfef95a35", // Facing redundancy
    "3cee5bdd-6b13-4d39-9f10-5cd8f68a9be1", // Something else
  ];

  const individual = [
    "812a9ee9-2a20-4d33-8f69-3f04c2eb31ac", // Finding a new job / making a career change
    "a851c884-614e-4562-ac88-a241bb5a7fd7", // Setting up a side hustle or business
    "082bb4db-88e7-4a78-952b-55ccfef95a35", // Facing redundancy
    "1ed9e12e-8197-497e-9af6-4fa833e9dcdd", // Going back to work after a break / parental leave
    "4712ad73-6811-47f6-9304-3b58562eef47", // How to be successful in my role
    "c5310bda-7620-48fd-b0a7-e8aa22ab7aac", // Cope with anxiety and self-doubt
    "70aacf95-ef37-4ddd-8091-62e55026de74", // Develop better habits for my wellbeing
    "2f29cc6f-353a-4292-ace4-0faa7a2d227a", // Feel more confident in new situations
    "912649e0-61a5-417c-ac2a-f440dd3a7748", // Finding my purpose
    "fdc58c11-cf71-4957-9983-344bcc362ce8", // Improve a relationship
    "63a8782b-27dd-4aa1-be93-fda2dcf1950f", // Manage stress and avoid burnout
    "ac967000-2695-4534-b0fe-575aa4f80224", // Being a better parent
    "a2107b58-ea7a-44ce-b8d7-67f1cee6da55", // Resolve conflicts and communicate better
    "cf1339b3-e9d4-44bb-aca3-351123150dd9", // Set and reach career + life goals
    "2e48068a-a4d7-432c-abf6-7925da680c33", // Solve a problem / make a clear decision
    "feedfdf7-901b-47dd-ac6e-2e07476ae8ab", // Use my time more effectively
    "3cee5bdd-6b13-4d39-9f10-5cd8f68a9be1", // Something else
  ];

  return accountType === AccountType.Individual ? individual : team;
};

export const orderCoachPackages = (coachPackages: Array<CoachPackage>, accountType: AccountType) =>
  coachPackagesForCompanyType(accountType).map((id) =>
    coachPackages.find((coachPackage) => coachPackage.id === id),
  );
