import React, { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Form, Image, Layout, Space, Typography } from "antd";

import { HtmlMeta } from "$/components/HtmlMeta";
import { Message } from "$/components/Message";

import { DATA_TEST_IDS } from "$/elements.config";

import { WEB_ASSETS_URL } from "$/configs/app.config";

import { Mixpanel } from "$/tracking";
import { useForm } from "react-hook-form";
import { Input } from "$/components/Input";
import { Eye, EyeOff } from "lucide-react";
// import Checkbox from "$/components/Checkbox";
import { Button } from "$/components/Button";
import * as z from "zod";
import * as CompanyEmailValidator from "company-email-validator";
import { zodResolver } from "@hookform/resolvers/zod";
import { AccountType } from "$/graphql/types.generated";
import { useUser } from "$/state/user";

const GreenCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect width="24" height="24" rx="6" fill="#006A39" />
    <path
      d="M5.56006 11.5707L10.0681 16.2934L18.4401 7.7067"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const WithCheck = ({ text }: { text: string }) => {
  return (
    <div className="flex gap-2">
      <GreenCheck />
      {text}
    </div>
  );
};

export const RegisterIndividual = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="w-full h-full flex justify-center">
      <HtmlMeta title="Sign Up" />

      <div>
        <div className="text-center max-w-[1000px]">
          <div className="login-wrap">
            <Image
              className="login-img"
              preview={false}
              src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
              alt="logo"
            />
          </div>

          <Image src="/images/Sponsor_checklist.svg" className="h-[150px] w-[150px]" />

          <div className="mb-12">
            <h1> Take your wellbeing into your own hands </h1>
            <p className="text-lg">Solve burnout, overwhelm, challenges, conflicts and problems</p>
          </div>
          <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
            <div className="bg-white rounded-xl p-4 w-full md:w-[47%] text-center">
              <h2>Monthly plan</h2>
              <p>Unlimited one-to-one-support</p>
              <p>Coaching sessions so that you can solve challenges or develop your career</p>

              <div>
                <p className="text-3xl font-semibold">
                  £49.99<span className="text-lg">/month</span>
                  <span className="text-xs pl-1">(inc VAT)</span>
                </p>
              </div>
              <a href="/auth/register?t=individual">
                <Button primary>Get started</Button>
              </a>
            </div>
            <div className="flex flex-col gap-8 items-start w-full md:w-[47%]">
              <WithCheck text="Next day More Happi coaching" />
              <WithCheck text="No cap, unlimited 100% confidential sessions" />
              <WithCheck text="Easy access via web app" />
              <WithCheck text="Pay-as-you-go, no long-term contracts" />
            </div>
          </div>
          <a href="/auth/register?t=individual">
            <Button primary large className="mt-8">
              Create your new account
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
