import { Button } from "$/components/Button";
import { HtmlMeta } from "$/components/HtmlMeta";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { useGetSessionByIdQuery, useMarkNoShowMutation } from "$/graphql/types.generated";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { useUser } from "$/state/user";
import { DateTime } from "luxon";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

interface IProps extends IPageBaseProps {}

export const NoShowPage: React.FC<IProps> = () => {
  useScrollTo();
  const navigate = useNavigate();
  const params = useParams();
  const markNoShow = useMarkNoShowMutation();
  const user = useUser();

  const { data, isLoading } = useGetSessionByIdQuery({
    id: params.sessionId,
  });

  const session = data?.getSession;

  return (
    <div className="mb-20 w-screen flex flex-col justify-center">
      <HtmlMeta title="Mark as no show" />
      <div className="h-screen w-screen flex items-center justify-center">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="flex justify-center items-center w-full">
            <div className="w-[90%] md:w-[600px] gap-y-4 flex flex-col items-center mt-[-100px]">
              {DateTime.fromISO(session?.startsAt).diffNow("minute").minutes < 0 ? (
                <>
                  <img
                    src="/images/Contact_us.svg"
                    alt=""
                    className="h-[100px] w-[100px] md:h-[200px] md:w-[200px]"
                  />
                  <h1 className="text-4xl text-center">Mark this booking as a no show?</h1>
                  <div className="text-lg flex flex-col gap-y-4">
                    <p className=" text-center mb-0">
                      By marking this session as a no-show, you are confirming that you have
                      followed the instructions in the troubleshooting guide including:
                    </p>
                    <ul>
                      <li>You have ensured that you are using the correct Google Meet link</li>
                      <li>You have emailed the coachee with the call details</li>
                      <li>You have waited for 15 minutes</li>
                    </ul>
                    <p>
                      If after taking the troubleshooting steps AND after 15 minutes, your coachee
                      hasn&apos;t shown up, ONLY THEN please mark them as a no-show with the button
                      below.
                    </p>
                    <p>
                      If your call has not taken place for other reasons, or you&apos;d like to give
                      us more information about the no-show, please let us know by emailing{" "}
                      <a href="mailto:hey@morehappi.com" className="underline">
                        hey@morehappi.com
                      </a>
                    </p>
                  </div>
                  <Button
                    primary
                    large
                    isSubmitting={markNoShow.isPending}
                    onClick={async () => {
                      await markNoShow.mutateAsync({
                        sessionId: session!.id,
                      });
                      navigate(`/sessions/${session?.id}/no-show/confirm`);
                    }}
                  >
                    Mark as no show
                  </Button>
                </>
              ) : (
                <>
                  {user?.currentUser ? (
                    <>
                      <h1 className="text-4xl text-center mb-0">Cannot mark as no show</h1>

                      <p className="text-lg mb-0">
                        The start date of this session has not passed yet. If you want to cancel
                        this session instead, please follow the cancellation instructions in the
                        session confirmation email.
                      </p>
                    </>
                  ) : (
                    <>
                      <h1 className="text-4xl text-center mb-4">
                        You must be logged in to mark as no show
                      </h1>

                      <p className="text-lg mb-4 text-center">
                        To mark this session as a no show, you must login to your More Happi
                        account. If you don&apos;t have an account or are unsure how to do that,
                        please email{" "}
                        <a href="mailto:hey@morehappi.com" className="underline">
                          support
                        </a>
                        .
                      </p>
                      <a href="/auth/login" className="w-full md:w-[50%]">
                        <Button primary className="w-full" large>
                          Go to login page
                        </Button>
                      </a>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
