import { HtmlMeta } from "$/components/HtmlMeta";
import Tabs from "$/components/Tabs/Tabs";
import { IPageBaseProps } from "$/interfaces";
import { useUser } from "$/state/user";
import { Layout } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CoachList } from "./components/CoachList";
import { CoacheeList } from "./components/CoacheeList";

interface IProps extends IPageBaseProps {}

export const BookingList: React.FC<IProps> = () => {
  const user = useUser();
  const [searchParams] = useSearchParams();
  const [review, setReview] = useState(searchParams.get("review"));
  const [tab, setTab] = useState(user.currentUser.coach ? 0 : 1);

  return (
    <Layout>
      <HtmlMeta title="Where to start" />
      <Layout.Content className="site-layout-content">
        <div className="max-w-[600px] mx-auto">
          <h1 className="py-12 text-4xl font-strong text-center">Your bookings</h1>
          {user.currentUser.coach && (
            <Tabs
              tabs={[{ text: "As a coach" }, { text: "As a coachee" }]}
              activeTab={tab}
              setTab={setTab}
            />
          )}
          {tab === 0 && <CoachList setReview={setReview} />}
          {tab === 1 && <CoacheeList setReview={setReview} review={review} />}
        </div>
      </Layout.Content>
    </Layout>
  );
};
