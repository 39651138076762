import { createContext, useContext, useEffect, useState } from "react";
import { FeatureStatus, useListFeaturesQuery } from "$/graphql/types.generated";

import { useUser } from "../user";

const FeatureContext = createContext<Array<string>>([]);

export const FeatureProvider = ({ children }: any) => {
  const user = useUser();
  const [features, setFeatures] = useState([]);

  const { data, isSuccess, isPending, isError } = useListFeaturesQuery(
    { companyId: user?.currentUser?.teamMember?.companyId },
    {
      enabled: !!user?.currentUser?.id,
    },
  );

  useEffect(() => {
    if (isSuccess) {
      const onSuccess = async () => {
        const validFeatures = data.listFeatures.filter((feature) => {
          if (feature.status === FeatureStatus.Full) return true;
          if (
            feature.status === FeatureStatus.Partial &&
            data.listFeatureCompanies
              .map((companyFeature) => companyFeature.feature.name)
              .includes(feature.name)
          ) {
            return true;
          }
          return false;
        });
        if (validFeatures.length) {
          setFeatures(validFeatures.map(({ name }) => name));
        } else {
          setFeatures([]);
        }
      };
      onSuccess();
    }
  }, [isSuccess, isPending, data]);

  useEffect(() => {
    if (isError || !user?.currentUser?.id) {
      setFeatures(["programmesPublic"]); // TODO: This is just a placeholder until I move it to the backend
    }
  }, [isError, isPending, data]);

  return <FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>;
};

export const useFeatures = () => useContext(FeatureContext);
