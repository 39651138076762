import { LoadingSpinner } from "$/components/LoadingSpinner";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
import { getProgrammes, useUser } from "$/state/user";
import { orderBy } from "lodash";
import { formatProgrammeData } from "../Enquiry/helpers/storyblok";
import { SessionState } from "$/graphql/types.generated";
import { differenceInDays, parseISO } from "date-fns";
import { Button } from "$/components/Button";
import pluralize from "pluralize";
import { DateTime } from "luxon";

export default function SubscriptionEndOfProgramme() {
  const user = useUser();

  const mostRecentProgramme = orderBy(
    getProgrammes(user.currentUser.packages),
    ["expired", "hitSessionLimit", "createdAt"],
    ["asc", "asc", "desc"],
  )[0];

  const recentEndDate =
    DateTime.fromISO(mostRecentProgramme.endDate) > DateTime.now().minus({ days: 30 });

  if (!recentEndDate) return <></>;

  const completed = mostRecentProgramme.hitSessionLimit;
  const expired = !!mostRecentProgramme.expired;
  const sessionsCompleted = mostRecentProgramme.validSessions.filter(
    (session) => session.state === SessionState.Confirmed || SessionState.ConfirmedCoach,
  ).length;

  const timeline =
    expired || !mostRecentProgramme.validSessions.length
      ? differenceInDays(
          parseISO(mostRecentProgramme.createdAt),
          parseISO(mostRecentProgramme.endDate),
        )
      : differenceInDays(
          parseISO(mostRecentProgramme.createdAt),
          parseISO(orderBy(mostRecentProgramme.validSessions, ["startsAt"], ["desc"])[0].startsAt),
        );

  const storyblokInfoQuery = useGetProgrammeQuizQuery({
    id: `programmes/${mostRecentProgramme.plan}`,
  });
  const storyblokInfo = storyblokInfoQuery?.data?.PageItem?.content
    ? formatProgrammeData(storyblokInfoQuery.data?.PageItem?.content)
    : null;

  if (storyblokInfoQuery.isLoading) return <LoadingSpinner />;

  return (
    <div className="w-full mx-auto flex py-2 bg-skin rounded-lg justify-around">
      <div className="max-w-[1280px] w-full flex">
        <div className="w-[25%] flex justify-end m-auto">
          <img
            src="/images/Sponsor_checklist.svg"
            alt=""
            className="h-[100px] md:h-[200px] w-[100px] md:w-[200px]"
          />
        </div>
        <div className="w-[75%] flex flex-col items-center mx-auto gap-4 text-center mt-8">
          <h2 className="m-0">
            {completed && (
              <>
                You did it! You completed your{" "}
                <span className="underline">{storyblokInfo?.displayName}</span> programme.
              </>
            )}
            {expired && !completed && (
              <>
                Your <span className="underline">{storyblokInfo?.displayName}</span> programme has
                now ended.
              </>
            )}
          </h2>
          <div className="flex flex-col md:flex-row">
            {mostRecentProgramme.sessionCount > 1 && sessionsCompleted > 0 && (
              <h3 className="my-0 mr-1">
                You completed {sessionsCompleted} {pluralize("session", sessionsCompleted)} in{" "}
                {Math.abs(timeline)} days.
              </h3>
            )}
            <h3 className="m-0">Want to keep going?</h3>
          </div>

          <a href="/enquiry">
            <Button primary large>
              Find a skill to develop
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
