import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { CoachEvent } from "$/graphql/types.generated";
import { DateTime } from "luxon";

type AvailableSlot = {
  startsAt: DateTime;
  endsAt: DateTime;
  id: string;
  handle: string;
  colour?: string;
};

const SchedulerContext = createContext<{
  calendar: Array<Array<number>>;
  month: DateTime;
  nextMonth: () => void;
  prevMonth: () => void;
  availableSlots: {
    month: Array<AvailableSlot>;
    day: Array<AvailableSlot>;
    threeDays: Record<string, Array<AvailableSlot>>;
  };
  selectedDate: DateTime;
  setSelectedDate: React.Dispatch<React.SetStateAction<DateTime>>;
  interval: number;
  setInterval: React.Dispatch<React.SetStateAction<number>>;
  setEvents: React.Dispatch<React.SetStateAction<Array<CoachEvent>>>;
  //@ts-ignore
}>({});

interface SchedulerProviderProps extends React.ComponentPropsWithoutRef<"div"> {
  availability?: Array<CoachEvent>;
}

interface CoachEventWithColour extends CoachEvent {
  colour?: string;
}

export const SchedulerProvider = ({ children, availability }: SchedulerProviderProps) => {
  const [events, setEvents] = useState<Array<CoachEventWithColour>>(availability ?? []);
  const [month, setMonth] = useState(DateTime.now().startOf("month"));
  const [selectedDate, setSelectedDate] = useState(DateTime.now().startOf("day"));
  const [interval, setInterval] = useState(60);
  const [availableSlots, setAvailableSlots] = useState<{
    month: Array<AvailableSlot>;
    day: Array<AvailableSlot>;
    threeDays: Record<string, Array<AvailableSlot>>;
  }>({ month: [], day: [], threeDays: {} });

  const nextMonth = () => setMonth((curr) => curr.plus({ month: 1 }).startOf("month"));
  const prevMonth = () => setMonth((curr) => curr.minus({ month: 1 }).startOf("month"));

  const calendar = useMemo(() => {
    const cal: Array<Array<number>> = new Array(Array.from({ length: 7 }, () => 0));

    let { weekday } = month;
    for (let i = 1; i <= month.daysInMonth; i += 1) {
      cal[cal.length - 1][weekday - 1] = i;

      if (weekday === 7) {
        weekday = 1;
        cal.push(Array.from({ length: 7 }, () => 0));
      } else {
        weekday += 1;
      }
    }
    return cal;
  }, [month]);

  useEffect(() => {
    if (events?.length) {
      const slotsForMonth = events
        .filter((slot) => {
          return DateTime.fromISO(slot.startsAt).hasSame(month, "month");
        })
        .map((slot) => ({
          startsAt: DateTime.fromISO(slot.startsAt),
          endsAt: DateTime.fromISO(slot.endsAt),
          id: slot.id,
          handle: slot.coach?.handle,
          colour: slot?.colour,
        }));

      const slotsForDay = slotsForMonth.filter((slot) => {
        return slot.startsAt.hasSame(selectedDate, "day");
      });

      const slotsFor3Days = events
        .map((slot) => ({
          ...slot,
          startsAt: DateTime.fromISO(slot.startsAt),
          endsAt: DateTime.fromISO(slot.endsAt),
        }))
        .filter(({ startsAt }) => {
          const yesterday = selectedDate.minus({ days: 1 }).hasSame(startsAt, "day");
          const today = selectedDate.hasSame(startsAt, "day");
          const tomorrow = selectedDate.plus({ days: 1 }).hasSame(startsAt, "day");
          return yesterday || today || tomorrow;
        })
        .reduce(
          (acc, slot) => {
            const date = slot.startsAt.toFormat("yyyy-MM-dd");
            acc[date] = [...acc[date], slot];
            return acc;
          },
          {
            [selectedDate.minus({ day: 1 }).toFormat("yyyy-MM-dd")]: [],
            [selectedDate.toFormat("yyyy-MM-dd")]: [],
            [selectedDate.plus({ day: 1 }).toFormat("yyyy-MM-dd")]: [],
          },
        );

      setAvailableSlots({ month: slotsForMonth, day: slotsForDay, threeDays: slotsFor3Days });
    }
  }, [events, month, selectedDate]);

  return (
    <SchedulerContext.Provider
      value={{
        calendar,
        month,
        nextMonth,
        prevMonth,
        selectedDate,
        availableSlots,
        setSelectedDate,
        interval,
        setInterval,
        setEvents,
      }}
    >
      {children}
    </SchedulerContext.Provider>
  );
};

export const useScheduler = () => useContext(SchedulerContext);
