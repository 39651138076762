import { OrderByArg, SessionState, useListSessionsQuery } from "$/graphql/types.generated";
import { useUser } from "$/state/user";
import { addMinutes, startOfHour } from "date-fns";

import { CoachSessionCard } from "$/components/SessionCard/CoachSessionCard";
import { useMemo, useState } from "react";
import { BookingListProps } from "./CoacheeList";
import { NoSessionCard } from "./NoSessionCard";
import { ToggleLeft, ToggleRight } from "lucide-react";

export const CoachList = ({ setReview }: BookingListProps) => {
  const user = useUser();
  const [showUpcomingSessions, setShowUpcomingSessions] = useState<boolean>(true);
  const [showPastSessions, setShowPastSessions] = useState<boolean>(true);

  const { data: futureCoachSessions } = useListSessionsQuery({
    where: {
      coachId: { equals: user.currentUser?.coach?.id || null },
      startsAt: { gte: addMinutes(startOfHour(new Date()), 30) },
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
    },
    orderBy: [{ startsAt: OrderByArg.Asc }],
  });

  const { data: pastCoachSessions } = useListSessionsQuery({
    where: {
      coachId: { equals: user.currentUser?.coach?.id || null },
      startsAt: { lt: addMinutes(startOfHour(new Date()), 30) },
    },
    orderBy: [{ startsAt: OrderByArg.Desc }],
    take: 20,
  });

  const pastSessionTitle = useMemo(() => {
    if (pastCoachSessions?.listSessions.length > 0) {
      return `Past ${pastCoachSessions?.listSessions.length} ${
        pastCoachSessions?.listSessions.length > 1 ? "Sessions" : "Session"
      }`;
    }
    return "Past Sessions";
  }, [pastCoachSessions]);

  return (
    <>
      <div className="mb-8">
        <div className="flex justify-between">
          <h3 className="text-2xl">Upcoming sessions</h3>
          <div className="flex gap-2">
            <p>Hide upcoming sessions</p>
            {showUpcomingSessions ? (
              <ToggleLeft
                className="cursor-pointer"
                onClick={() => setShowUpcomingSessions(false)}
              />
            ) : (
              <ToggleRight
                className="cursor-pointer"
                onClick={() => setShowUpcomingSessions(true)}
              />
            )}
          </div>
        </div>

        {showUpcomingSessions && (
          <div>
            {futureCoachSessions?.listSessions.length ? (
              <div className="flex flex-col gap-y-6">
                {futureCoachSessions?.listSessions.map((session) => (
                  <CoachSessionCard key={session?.id} session={session} timescale="future" />
                ))}
              </div>
            ) : (
              <NoSessionCard
                message="You don't have any upcoming sessions!"
                imageSrc="/images/user_tools/everyday.svg"
                altText="No Upcoming Sessions"
              />
            )}
          </div>
        )}
      </div>
      <div>
        <div className="flex justify-between">
          <h3 className="text-2xl">{pastSessionTitle}</h3>
          <div className="flex gap-2">
            <p>Hide Past sessions</p>
            {showPastSessions ? (
              <ToggleLeft className="cursor-pointer" onClick={() => setShowPastSessions(false)} />
            ) : (
              <ToggleRight className="cursor-pointer" onClick={() => setShowPastSessions(true)} />
            )}
          </div>
        </div>

        {showPastSessions && (
          <div>
            {pastCoachSessions?.listSessions?.length ? (
              <div className="flex flex-col gap-y-6">
                {pastCoachSessions?.listSessions.map((session) => (
                  <CoachSessionCard
                    key={session?.id}
                    session={session}
                    timescale="past"
                    onReview={setReview}
                  />
                ))}
              </div>
            ) : (
              <NoSessionCard
                message="You don't have any past sessions!"
                imageSrc="/images/user_tools/promotions.svg"
                altText="No Past Sessions"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
