import { Button } from "$/components/Button";
import CoachTestimonials from "$/components/CoachCard/CoachTestimonials";
import { app } from "$/configs/app.config";
import { Coach } from "$/graphql/types.generated";
import Calendar from "$/icons/Calendar";
import { Mixpanel } from "$/tracking";

export default function CarouselCard({
  coach,
  trackingPrefix,
}: {
  coach: Coach;
  trackingPrefix: string;
}) {
  return (
    <div className="flex flex-col md:flex-row bg-white rounded-2xl h-[500px] md:h-[300px]">
      <div>
        <img
          src={`${app.CLOUDFRONT.USER_ASSETS}/${coach.id}/thumbnail.jpg`}
          alt="profile"
          className="w-full md:!w-[300px] rounded-t-2xl md:rounded-none md:rounded-l-2xl"
        />
      </div>
      <div className="p-3 text-left flex flex-col grow justify-between gap-2">
        <div>
          <div className="w-full flex flex-row md:flex-col justify-between items-center md:justify-start md:items-start">
            <h1 className="text-2xl font-semibold mb-0">{coach.displayName}</h1>
            <CoachTestimonials coach={coach} />
          </div>
          <p className="text-sm md:text-lg mt-2 mb-3 line-clamp-2">{coach.headline}</p>
          <div className="flex justify-between items-center">
            <a
              href={`/coach/${coach.handle}`}
              target="_blank"
              rel="noreferrer"
              className="underline"
              id={`${trackingPrefix ? `${trackingPrefix}-` : ""}coach-card-read-more`}
            >
              Read More
            </a>
            <a
              href={`/coach/${coach.handle}`}
              className="flex justify-end hover:no-underline"
              id={`${trackingPrefix ? `${trackingPrefix}-` : ""}coach-card-view-availability`}
            >
              <Button
                primary
                leftIcon={<Calendar />}
                onClick={() => {
                  Mixpanel.track(`${trackingPrefix}: View availability`);
                }}
                id={`${trackingPrefix ? `${trackingPrefix}-` : ""}coach-card-view-availability`}
              >
                View availability
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
