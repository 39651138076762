import mixpanel from "mixpanel-browser";
import { pickBy } from "lodash";

const canTrack = import.meta.env.VITE_MIXPANEL_ACTIVE === "true";

const removeNullProperties = (props: object) =>
  Object.fromEntries(Object.entries(props).filter(([k, v]) => v !== null));

export enum TrackingEventName {
  PageView = "Page View",
}

export const Mixpanel = {
  // @ts-ignore
  loaded: () => !!mixpanel.__loaded,
  init: () => {
    if (canTrack && !Mixpanel.loaded())
      mixpanel.init(import.meta.env.VITE_MIXPANEL, {
        persistence: "cookie",
      });
  },
  formatAmountForTracking: (amount: number) => {
    const formattedAmount = new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(amount / 100);

    return Number(formattedAmount);
  },
  identify: (id?: string) => {
    if (canTrack || !id) mixpanel.identify(id);
  },
  alias: (email: string) => {
    if (canTrack) mixpanel.alias(email);
  },
  track: (name: string, props = {}) => {
    if (!Mixpanel.loaded()) {
      console.info(`mixpanel track called before init, ${name}`);
      return;
    }
    if (canTrack) mixpanel.track(name, removeNullProperties(props));
  },
  reset: () => {
    if (canTrack) mixpanel.reset();
  },
  people: {
    trackCharge: (amount: number, currency: string) => {
      if (canTrack) {
        mixpanel.people.track_charge(amount, {
          original_currency: currency,
        });
      }
    },
    increment: (property: string, value: number) => {
      if (canTrack) {
        mixpanel.people.increment(property, value);
      }
    },
    setOnce: (props: any = {}) => {
      if (canTrack) mixpanel.people?.set(removeNullProperties(props));
    },
    set: (props: any = {}) => {
      if (canTrack) mixpanel.people?.set(removeNullProperties(props));
    },
    setProp: (key: string, value: any) => {
      if (canTrack) mixpanel.people.set(key, value);
    },
  },
};
