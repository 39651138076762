import { Navigate } from "react-router-dom";
import { useAuth } from "$/state/auth";
import { useEffect } from "react";

export const SignOut = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
  });

  return <Navigate to="/" />;
};
