import cn from "classnames";
import { times } from "lodash";
import { ComponentPropsWithoutRef, useCallback, useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";

interface PageButtonProps extends ComponentPropsWithoutRef<"div"> {
  active?: boolean;
}

const PageButton = ({ children, onClick, active, ...rest }: PageButtonProps) => {
  return (
    <div
      aria-label="pagination-button-start-1"
      className={cn(
        "flex justify-center items-center rounded-md w-7 h-7 aspect-square hover:opacity-70 cursor-pointer mr-2 bg-white text-black border border-solid border-orange",
        { " text-orange": active },
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
};

interface PaginationProps {
  pageCount: number;
  page?: number;
  buttonCount?: number;
  handleNext?: () => void;
  handlePrevious?: () => void;
  handleChange?: (newPage: number) => void;
}

export function Pagination({
  pageCount = 0,
  page = 0,
  buttonCount = 3,
  handleNext = () => null,
  handlePrevious = () => null,
  handleChange = () => null,
}: PaginationProps) {
  const getCurrentStart = useCallback(
    (currentPage) => {
      if (pageCount - buttonCount <= 0) return 0;
      return currentPage > pageCount - buttonCount - 1 ? pageCount - buttonCount : currentPage;
    },
    [buttonCount, pageCount],
  );
  const [currentStart, setCurrentStart] = useState(getCurrentStart);
  const numberOfButtons = pageCount < buttonCount ? pageCount : buttonCount;

  useEffect(() => {
    setCurrentStart(getCurrentStart(page));
  }, [page, pageCount, getCurrentStart]);
  if (!pageCount || isNaN(currentStart)) return null;

  const hasBeforePage = page > 0 && page < pageCount - numberOfButtons + 1;

  return (
    <div className="flex items-center">
      <PageButton onClick={() => page > 0 && handlePrevious()}>
        <ChevronLeft size="1.3rem" color={page > 0 ? "black" : "lightgrey"} />
      </PageButton>

      {page > 1 && pageCount !== 3 && (
        <div className="hidden! md:block!">
          <PageButton onClick={() => handleChange(0)}>1</PageButton>
        </div>
      )}
      {page > 2 && pageCount !== 4 && (
        <div className="mr-2 hidden! md:block! flex items-center">
          <MoreHorizontal color="lightGrey" />
        </div>
      )}
      {hasBeforePage && <PageButton onClick={() => handleChange(page - 1)}>{page}</PageButton>}

      {times(hasBeforePage ? numberOfButtons - 1 : numberOfButtons, (index) => (
        <PageButton
          key={`pagination-${currentStart + index}`}
          aria-label={`pagination-button-${currentStart + index}`}
          active={currentStart + index === page}
          onClick={() => handleChange(currentStart + index)}
        >
          {currentStart + index + 1}
        </PageButton>
      ))}
      {page < pageCount - 3 && pageCount !== 4 && (
        <div className="mr-2 hidden! md:block!  items-center flex">
          <MoreHorizontal color="lightGrey" />
        </div>
      )}
      {page < pageCount - 2 && pageCount !== 3 && (
        <div className="hidden! md:block! ">
          <PageButton onClick={() => handleChange(pageCount - 1)}>{pageCount}</PageButton>
        </div>
      )}
      <PageButton onClick={() => page < pageCount - 1 && handleNext()}>
        <ChevronRight size="1.2rem" color={page < pageCount - 1 ? "black" : "lightgrey"} />
      </PageButton>
    </div>
  );
}
