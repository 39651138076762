import { Button } from "$/components/Button";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetProgrammeIntrosQuery } from "$/graphql/storyblok/types.generated";
import { shuffle } from "lodash";

const ButtonIntro = ({ setProgramme, send, programmes }) => {
  const newProgrammeList = [...programmes.list];
  const shuffledProgrammes = shuffle(newProgrammeList);

  return (
    <>
      <h1 className="text-center text-2xl md:text-4xl font-semibold mb-9">{programmes.title}</h1>
      <p className="text-center mb-14 text-lg">{programmes.subtitle}</p>

      <div className="w-full flex flex-col gap-4 justify-between">
        {shuffledProgrammes.map((prog) => (
          <Button
            key={prog.path}
            primary
            large
            onClick={() => {
              setProgramme(prog.path);
              send({ next: "why", value: prog.path });
            }}
            className="w-full"
          >
            {prog.text}
          </Button>
        ))}
      </div>
    </>
  );
};

const SingleIntro = ({ title, subtitle, path, setProgramme, send }) => {
  return (
    <>
      <h1 className="text-center text-2xl md:text-4xl font-semibold mb-9">{title}</h1>
      <p className="text-center mb-14 text-lg">{subtitle}</p>
      <Button
        primary
        large
        className="w-full"
        onClick={() => {
          setProgramme(path);
          send({ next: "why", value: path });
        }}
      >
        Continue
      </Button>
    </>
  );
};

export default function Intro({ setProgramme, send, title, subtitle }) {
  const [searchParams] = useSearchParams();
  const [intro, setIntro] = useState(null);
  const p = searchParams.get("i") ?? "default";
  const programmeIntro = useGetProgrammeIntrosQuery({ id: `programme-intros/${p}` });

  useEffect(() => {
    if (programmeIntro.isSuccess) {
      const { content } = programmeIntro.data.ProgrammeItem;

      setIntro({
        title: content.title,
        subtitle: content.subtitle,
        list: content.list.map((listItem) => ({
          text: listItem.text,
          path: listItem.quiz.cached_url.split("/")[1],
        })),
      });
    }
  }, [programmeIntro.isSuccess]);

  if (programmeIntro.isLoading) {
    return <div />;
  }

  return (
    <>
      {searchParams.get("p") ? (
        <SingleIntro
          title={title}
          subtitle={subtitle}
          path={searchParams.get("p")}
          send={send}
          setProgramme={setProgramme}
        />
      ) : (
        <>{intro && <ButtonIntro setProgramme={setProgramme} send={send} programmes={intro} />}</>
      )}
    </>
  );
}
