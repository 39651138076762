import { ListSessionsQuery } from "$/graphql/types.generated";
import dayjs from "dayjs";
import { orderBy } from "lodash";

export const getSessions = (sessions: ListSessionsQuery, isFuture: boolean) => {
  if (!sessions) {
    return [];
  }

  return orderBy(
    sessions?.listSessions?.filter(
      (session) =>
        session !== null &&
        (isFuture
          ? dayjs().subtract(30, "minutes").isBefore(dayjs(session.startsAt))
          : dayjs().subtract(30, "minutes").isAfter(dayjs(session.startsAt))),
    ),
    "startsAt",
    isFuture ? "asc" : "desc",
  );
};
