// @ts-nocheck
import type { CognitoUser } from "@aws-amplify/auth";

import { User } from "$/graphql/types.generated";

export type AuthState = {
  isLoading: boolean;
  error: Error | undefined;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  userConfig: CognitoUser | undefined;
  cognitoId: string | undefined;
};

export type UpdatableUserAttributes = {
  familyName?: string;
  givenName?: string;
  bio?: string;
  picture?: string;
  phoneNumber?: string;
  country?: string;
  city?: string;
  workspaceId?: string;
  userId?: string;
  type?: string;
};

export type AuthReducerAction =
  | { type: "LOGOUT" }
  | { type: "IS_LOGGING_IN" }
  | {
      type: "LOGIN_SUCCESS";
      userConfig: CognitoUser;
    }
  | { type: "LOGIN_FAILURE"; error: Error | undefined }
  | { type: "LOGOUT_SUCCESS" }
  | {
      type: "UPDATE_USER";
      updatedUserAttributes?: UpdatableUserAttributes;
    }
  | { type: "SET_CURRENT_USER"; currentUser: User };

const initialState = {
  isLoading: true,
  error: undefined,
  isAuthenticated: false,
  isAuthenticating: true,
  userConfig: undefined,
  cognitoId: undefined,
};

export function authReducer(
  state: AuthState,
  action: AuthReducerAction,
): AuthState {
  switch (action.type) {
    case "LOGOUT": {
      return {
        ...initialState,
        isAuthenticating: false,
      };
    }
    case "IS_LOGGING_IN": {
      return initialState;
    }
    case "LOGIN_SUCCESS": {
      return {
        isLoading: false,
        error: undefined,
        isAuthenticated: true,
        isAuthenticating: false,
        userConfig: action.userConfig,
        cognitoId: action.userConfig.username,
      };
    }
    case "LOGIN_FAILURE": {
      return {
        ...initialState,
        isLoading: false,
        error: action.error,
        isAuthenticating: false,
      };
    }
    case "LOGOUT_SUCCESS": {
      return {
        ...initialState,
        isLoading: false,
        isAuthenticating: false,
      };
    }
    case "UPDATE_USER": {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.updatedUserAttributes,
        },
      };
    }

    default: {
      throw new Error(`Unsupported action type: ${JSON.stringify(action)}`);
    }
  }
}
