import Auth, { CognitoUser } from "@aws-amplify/auth";
import cookieCutter from "cookie-cutter";
import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import { IDENTITY_LOCALSTORAGE_KEY, JWT_LOCALSTORAGE_KEY } from "$/configs/app.config";

import { logoutUser } from "$/utils/auth";
import { Mixpanel } from "$/tracking";

import type { UpdatableUserAttributes } from "./auth-reducer";
import { authReducer, AuthReducerAction, AuthState } from "./auth-reducer";

type AuthContextValue = [AuthState, React.Dispatch<AuthReducerAction>];

const AuthContext = createContext<AuthContextValue | null>(null);

const AuthProvider: React.FC = (props) => {
  const [state, dispatch] = useReducer(authReducer, {
    isLoading: false,
    error: undefined,
    isAuthenticated: false,
    isAuthenticating: true,
    userConfig: undefined,
    cognitoId: undefined,
  });

  const value = useMemo(() => [state, dispatch], [state]);

  // @ts-ignore
  return <AuthContext.Provider value={value} {...props} />;
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  const [state, dispatch] = context;

  const setIdentityId = useCallback(async () => {
    const credentials = await Auth.currentCredentials();
    cookieCutter.set(IDENTITY_LOCALSTORAGE_KEY, credentials.identityId);
  }, []);

  const setToken = useCallback((cognitoUser: CognitoUser) => {
    cookieCutter.set(
      JWT_LOCALSTORAGE_KEY,
      cognitoUser.getSignInUserSession()?.getIdToken().getJwtToken() || "",
    );
  }, []);

  const getCurrentCognitoUser = useCallback(async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    return cognitoUser;
  }, []);

  const logout = useCallback(async () => {
    await logoutUser();

    Mixpanel.track("Auth Signed Out");

    return dispatch({ type: "LOGOUT" });
  }, [dispatch]);

  const initializeUser = useCallback(async () => {
    try {
      const cognitoUser = await getCurrentCognitoUser();

      setToken(cognitoUser);

      await setIdentityId();

      Mixpanel.track("Auth Signed In");

      dispatch({
        type: "LOGIN_SUCCESS",
        userConfig: cognitoUser,
      });
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        dispatch({ type: "LOGIN_FAILURE", error: e });
      } else if (JSON.stringify(e).toLowerCase().includes("no current user")) {
        dispatch({ type: "LOGIN_FAILURE", error: undefined });
      } else {
        // todo fix this type
        // @ts-ignore
        dispatch({ type: "LOGIN_FAILURE", error: new Error(e) });
      }

      cookieCutter.set(JWT_LOCALSTORAGE_KEY, "", {
        expires: new Date(0),
      });
    }
  }, [dispatch, setIdentityId, setToken]);

  const updateUserAttributes = useCallback(
    (attributesToUpdate?: UpdatableUserAttributes) => {
      dispatch({
        type: "UPDATE_USER",
        updatedUserAttributes: attributesToUpdate,
      });
    },
    [dispatch],
  );

  return {
    state,
    dispatch,
    logout,
    getCurrentCognitoUser,
    initializeUser,
    updateUserAttributes,
  };
}

export { AuthProvider, useAuth };
