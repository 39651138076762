import cn from "classnames";
import { LoadingSpinner } from "../LoadingSpinner";

export interface CustomButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  event?: string;
  eventProps?: {};
  isSubmitting?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  tertiarySolid?: boolean;
  flat?: boolean;
  error?: boolean;
  errorSecondary?: boolean;
  disabledView?: boolean;
  small?: boolean;
  large?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  disableDefaultStyling?: boolean;
}

export const Button = ({
  onClick,
  children,
  event,
  eventProps,
  isSubmitting,
  type,
  primary,
  secondary,
  tertiary,
  tertiarySolid,
  flat,
  error,
  errorSecondary,
  disabledView,
  small,
  large,
  className = "",
  leftIcon,
  rightIcon,
  disabled,
  disableDefaultStyling,
  color,
  ...props
}: CustomButtonProps) => {
  return (
    <button
      type={type}
      className={cn(
        "btn",
        {
          "btn-primary": primary && !flat,
          "btn-primary__flat": primary && flat,
          "btn-secondary": secondary,
          "btn-tertiary": tertiary,
          "btn-tertiary-solid": tertiarySolid,
          "btn-error": error,
          "btn-error-secondary": errorSecondary,
          "btn-small": small,
          "btn-large": large,
          "btn-default": !small && !large && !disableDefaultStyling,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled || isSubmitting || disabledView}
      {...props}
    >
      <div className="flex items-center justify-center gap-x-2">
        {leftIcon && !isSubmitting && leftIcon}
        {isSubmitting ? (
          <LoadingSpinner
            className="border-lightGrey border-t-black"
            color={color ?? tertiary ? "#006A39" : "#F5C900"}
          />
        ) : null}
        {children}
        {rightIcon && rightIcon}
      </div>
    </button>
  );
};
