import { Select, SelectProps } from "../index";

interface RepeatingSessionsProps extends SelectProps {}
interface NumberOfSessionsProps extends SelectProps {
  repeat: string;
  repeatAmount: { label: number; value: number };
  sessionsLeft?: number;
}

const repeatingAmounts = [
  { label: "Does not repeat", value: "No" },
  { label: "Weekly on the same day", value: "weeklySameDay" },
  { label: "Fortnightly on the same day", value: "fortnightlySameDay" },
  { label: "Monthly on the same day", value: "monthlySameDay" },
  { label: "Monthly on the same date", value: "monthlySameDate" },
];

export function RepeatingSessionsSelect({ onChange, ...props }: RepeatingSessionsProps) {
  return (
    <>
      <p className="m-0 mt-6 font-bold">Do you want this session to repeat?</p>
      <Select
        defaultValue={{ label: "Does not repeat", value: "No" }}
        options={repeatingAmounts.map((opt) => ({ label: opt?.label, value: opt?.value }))}
        onChange={(newVal) => onChange(newVal.value)}
        {...props}
      />
    </>
  );
}

export function NumberOfSessionsSelect({
  onChange,
  repeat,
  repeatAmount,
  sessionsLeft,
  ...props
}: NumberOfSessionsProps) {
  let choicesArray = [];

  const numberOfWeeks = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const numberOfFortnights = [2, 3, 4, 5, 6];
  const numberOfMonths = [2, 3];

  if (repeat === "weeklySameDay") {
    choicesArray = numberOfWeeks;
  }

  if (repeat === "fortnightlySameDay") {
    choicesArray = numberOfFortnights;
  }

  if (repeat === "monthlySameDay" || repeat === "monthlySameDate") {
    choicesArray = numberOfMonths;
  }

  if (sessionsLeft) {
    // due to starting at 2, need to -1 from this
    choicesArray = choicesArray.slice(0, sessionsLeft - 1);
  }

  if (repeat === "No") return null;

  return (
    <>
      <p className="m-0 font-bold">How many sessions should be created?</p>
      <Select
        options={choicesArray.map((opt) => ({ label: opt, value: opt }))}
        onChange={(newVal) => onChange(newVal)}
        value={repeatAmount}
        {...props}
      />
    </>
  );
}
