import React from "react";

import { useIntercom } from "$/hooks";
import { WEB_ASSETS_URL } from "$/configs/app.config";

export interface IProps {
  children: React.ReactNode;
  disableHeader?: boolean;
  disableFooter?: boolean;
}

export const PublicLayout: React.FC<IProps> = (props) => {
  useIntercom();

  return (
    <div className="mh-layout">
      <div className="flex flex-col w-full h-full">
        {!props.disableHeader && (
          <nav className="w-full px-4 md:px-12 border-0 border-solid border-b border-b-skin">
            <a href="https://morehappi.com">
              <img src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`} alt="logo" className="h-[64px]" />
            </a>
          </nav>
        )}
        {props.children}
      </div>
    </div>
  );
};
