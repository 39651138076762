import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { Mixpanel, TrackingEventName } from "$/tracking";

import { ICompBaseProps } from "$/interfaces";

interface IProps extends ICompBaseProps {
  title: React.ReactNode;
  disableSiteName?: boolean;
}

export const HtmlMeta: React.FC<IProps> = (props) => {
  useEffect(() => {
    Mixpanel.track(TrackingEventName.PageView, {
      page_title: props.title,
    });
  }, []);

  return (
    <Helmet>
      <title>{props.title || ""} | More Happi</title>
    </Helmet>
  );
};
