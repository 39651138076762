import { useUser } from "$/state/user";
import { useCompany } from "$/state/company";
import { useCompanySubscription } from "$/state/company-subscription";

import { Button, message } from "antd";

import { UserRole, useGetPortalPageMutation } from "$/graphql/types.generated";

import { Mixpanel } from "$/tracking";

export const ManageSubscriptionButton = ({ text = "Manage Subscription" }: { text?: string }) => {
  const { currentUser } = useUser();
  const { currentCompany, update } = useCompany();

  const companySubscription = useCompanySubscription();

  const onCancel = () => {
    Mixpanel.track("Company Subscription Cancelled");

    return companySubscription.setSubscriptionStatus({
      active: false,
      status: "non_renewing",
    });
  };

  const onResume = () => {
    Mixpanel.track("Company Subscription Resumed");

    return companySubscription.setSubscriptionStatus({
      active: true,
      status: "active",
    });
  };

  const openPortal = (data: any) => {
    const chargebeeInstance = companySubscription.getChargebeeInstance();

    chargebeeInstance?.setPortalSession(() => Promise.resolve(data?.getPortalPage));

    const chargebeePortal = chargebeeInstance.createChargebeePortal();

    chargebeePortal.open({
      subscriptionCancelled: onCancel,
      subscriptionResumed: onResume,
      scheduledCancellationRemoved: onResume,
      subscriptionReactivated: onResume,
    });

    Mixpanel.track("Company Subscription Portal Opened");
  };

  const getPortalPageMutation = useGetPortalPageMutation({
    onError: () =>
      message.error(
        "There was a problem opening the subscription portal. Please try again or contact support.",
      ),
    onSuccess: openPortal,
  });

  const onManage = () => {
    if (!currentCompany?.id) return;

    return getPortalPageMutation.mutate({
      companyId: currentCompany.companyAdmin?.chargebeeCustomerId || currentCompany?.id,
    });
  };

  const disabled =
    !(currentUser?.isAdmin || currentUser?.role === UserRole.Individual) ||
    getPortalPageMutation.isError;

  return (
    <Button
      onClick={onManage}
      type="primary"
      disabled={disabled}
      loading={getPortalPageMutation.isPending}
    >
      {text}
    </Button>
  );
};
