import {
  CHARGEBEE_ITEM_PRICE_ID_STARTER,
  CHARGEBEE_ITEM_PRICE_ID_MONTHLY_INDIVIDUAL,
} from "$/configs/app.config";
import { AccountType, Company } from "$/graphql/types.generated";

import { tablePricingData } from "$/utils/table_data/pricingTierTableInfo";

export const getItemPriceId = (company: Company) => {
  if (company.accountType === AccountType.Individual) {
    return CHARGEBEE_ITEM_PRICE_ID_MONTHLY_INDIVIDUAL;
  }
  return CHARGEBEE_ITEM_PRICE_ID_STARTER;
};

export const getTablePricingData = (company: any) => {
  return tablePricingData;
};
