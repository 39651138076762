//@ts-nocheck

import { ActivationState, TeamMemberWhereInput } from "$/graphql/types.generated";
import { merge } from "lodash";

export const formatFilters = (
  filters: Array<{ id: string; value: string | boolean | Record<string, boolean> }>,
) => {
  const _filters: TeamMemberWhereInput = {};

  filters.forEach(({ id, value }) => {
    if (id === "Manager") {
      //@ts-ignore-next-line
      if (value.true) {
        merge(_filters, { OR: [{ isManager: { equals: true } }] });
      }
      //@ts-ignore-next-line
      if (value.false) {
        merge(_filters, { OR: [{ isManager: { equals: false } }] });
      }
    }

    if (id === "Status") {
      const activeActivationStates = Object.keys(
        Object.fromEntries(Object.entries(value).filter(([key, _value]) => _value)),
      );

      if (activeActivationStates.includes(ActivationState.Invited)) {
        // Invited and pending are basically the same thing from the user perspective
        activeActivationStates.push(ActivationState.Pending);
      }

      if (activeActivationStates.length) {
        merge(_filters, {
          user: {
            activationState: {
              in: Object.keys(
                Object.fromEntries(Object.entries(value).filter(([key, _value]) => _value)),
              ),
            },
          },
        });
      }
    }

    if (id === "First Name") {
      merge(_filters, { user: { firstName: { contains: value } } });
    }

    if (id === "Last Name") {
      merge(_filters, { user: { lastName: { contains: value } } });
    }

    if (id === "Email") {
      merge(_filters, { user: { email: { contains: value } } });
    }
  });

  return _filters;
};
