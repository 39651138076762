import React from "react";
import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { Button } from "$/components/Button";

interface IProps extends IPageBaseProps {}

export const CancelConfirmPage: React.FC<IProps> = () => {
  useScrollTo();

  return (
    <div className="mb-20">
      <HtmlMeta title="Cancel session" />

      <div className="flex justify-center items-center w-full mt-8">
        <div className="w-[90%] md:w-[600px] h-[80vh] gap-y-4 flex flex-col items-center justify-center">
          <img src="/images/Tools.svg" alt="" className="h-[220px]" />
          <h1 className="text-4xl text-center">Your booking has been cancelled</h1>

          <div className="flex">
            <a href="/">
              <Button primary large>
                Back home
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
