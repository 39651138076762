import { createContext, useContext, useEffect } from "react";
import { format } from "date-fns";

import { useQueryClient } from "@tanstack/react-query";

import { getActivePackage, useUser } from "$/state/user";
import { useCompanySubscription } from "$/state/company-subscription";

import {
  AccountType,
  CompanyAdminUpdateInput,
  CompanyUpdateInput,
  GetCompanyQuery,
  SessionState,
  useGetCompanyQuery,
  useUpdateCompanyAdminMutation,
  useUpdateCompanyMutation,
} from "$/graphql/types.generated";

import { Mixpanel } from "$/tracking";
import dayjs from "dayjs";

export type CurrentCompany = GetCompanyQuery["getCompany"] & {
  isEnterprise: boolean;
};

export enum CompanyFeaturePermissions {
  coachDirectory = "coachDirectory",
}

export type CompanyPermissions = {
  coachDirectory: boolean;
};

export interface ICompanyContext {
  isLoading: boolean;
  isError: boolean;
  currentCompany?: CurrentCompany | null;
  update: any;
  updateAdmin: any;
  permissions: CompanyPermissions;
}

const CompanyContext = createContext<ICompanyContext>({
  isLoading: false,
  isError: false,
  currentCompany: undefined,
  update: undefined,
  updateAdmin: undefined,
  permissions: {
    coachDirectory: true,
  },
});

const withSelectors = (company: GetCompanyQuery["getCompany"]) => {
  return {
    ...company,
    isEnterprise:
      company?.accountType === AccountType.Enterprise ||
      company?.accountType === AccountType.EnterpriseTrial,
  };
};

export const CompanyProvider = ({ children }: any) => {
  const queryClient = useQueryClient();

  const user = useUser();
  const companySubscription = useCompanySubscription();

  const where = {
    id: user.currentUser?.teamMember?.companyId || "",
  };

  const { isLoading, isError, data } = useGetCompanyQuery(
    { where },
    {
      enabled: !!user.currentUser?.teamMember?.companyId,
    },
  );

  const updateCompany = useUpdateCompanyMutation({
    onSuccess: () => {
      Mixpanel.track("Company Updated");

      return queryClient.invalidateQueries({ queryKey: ["GetCompany", where] });
    },
  });

  const updateCompanyAdmin = useUpdateCompanyAdminMutation({
    onSuccess: () => {
      Mixpanel.track("Company Admin Updated");

      return queryClient.invalidateQueries({ queryKey: ["GetCompany", where] });
    },
  });

  const update = (input: CompanyUpdateInput) =>
    updateCompany.mutate({
      where: { id: data?.getCompany?.id },
      data: input,
    });

  const updateAdmin = (input: CompanyAdminUpdateInput) =>
    updateCompanyAdmin.mutate({
      where: { companyId: data?.getCompany?.id },
      data: input,
    });

  useEffect(() => {
    if (data?.getCompany?.companyAdmin) {
      const { subscriptionActive, chargebeeSubscriptionStatus, chargebeeCurrentTermEnd } =
        data.getCompany.companyAdmin;

      const isFree = data?.getCompany.accountType === "free" && !subscriptionActive;

      const isEnterprise = data?.getCompany.accountType === AccountType.Enterprise;

      const isIndividualWithSubscription =
        data.getCompany.accountType === AccountType.Individual && subscriptionActive;

      const isSMEWithSubscription =
        data.getCompany.accountType === AccountType.Starter && subscriptionActive;

      const isSMEWithExpiredSubscription =
        data.getCompany.accountType === AccountType.Starter && !subscriptionActive;

      const hasActivePackage = !!getActivePackage(user.currentUser);

      const packageCustomer = user.currentUser.packages.length > 0;

      if (isSMEWithExpiredSubscription) {
        return companySubscription.setSubscriptionStatus({
          active: false,
          status: "expired",
          packageCustomer,
          subscriptionCustomer: true,
        });
      }

      if (isFree) {
        return companySubscription.setSubscriptionStatus({
          active: false,
          status: undefined,
          packageCustomer,
          subscriptionCustomer: true,
        });
      }

      if (isIndividualWithSubscription || isSMEWithSubscription) {
        return companySubscription.setSubscriptionStatus({
          active: true,
          status: "active",
          packageCustomer,
          subscriptionCustomer: true,
        });
      }

      // If the company is enterprise then there subscription is always active
      if (isEnterprise) {
        return companySubscription.setSubscriptionStatus({
          active: true,
          status: "active",
          packageCustomer,
          subscriptionCustomer: true,
        });
      }

      if (packageCustomer) {
        if (hasActivePackage) {
          return companySubscription.setSubscriptionStatus({
            active: false,
            status: "activePackage",
            packageCustomer,
          });
        }

        if (!hasActivePackage) {
          return companySubscription.setSubscriptionStatus({
            active: false,
            status: "expiredPackage",
            packageCustomer,
          });
        }
      }

      const isSubscriptionActive = subscriptionActive || chargebeeSubscriptionStatus === "active";

      const cancellingAt =
        chargebeeCurrentTermEnd && dayjs(chargebeeCurrentTermEnd).startOf("day").toDate();

      return companySubscription.setSubscriptionStatus({
        active: isSubscriptionActive,
        status: String(chargebeeSubscriptionStatus),
        cancellingAt,
      });
    }
  }, [data?.getCompany?.id]);

  const currentCompany = data?.getCompany && (withSelectors(data?.getCompany) as CurrentCompany);

  const getPermissions = (): CompanyPermissions => {
    if (!currentCompany) {
      return {
        coachDirectory: true,
      };
    }

    return {
      coachDirectory: true,
    };
  };

  return (
    <CompanyContext.Provider
      value={{
        isLoading,
        isError,
        currentCompany,
        update,
        updateAdmin,
        permissions: getPermissions(),
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);
