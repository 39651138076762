import { createContext, useContext } from "react";

const ScrollContext = createContext<{ scrollToBottom: () => void }>({ scrollToBottom: () => null });

interface ScrollProviderProps extends React.ComponentPropsWithoutRef<"div"> {
  scrollRef: { current?: HTMLDivElement };
}

export const ScrollProvider = ({ children, scrollRef }: ScrollProviderProps) => {
  if (scrollRef?.current) {
    const scrollToBottom = () =>
      scrollRef?.current?.scrollTo(
        0,
        scrollRef?.current.scrollHeight - scrollRef?.current.clientHeight,
      );
    return <ScrollContext.Provider value={{ scrollToBottom }}>{children}</ScrollContext.Provider>;
  }
  return <>{children}</>;
};

export const useScroll = () => useContext(ScrollContext);
