import React, { useRef } from "react";
import { Button } from "$/components/Button";
import { Input } from "$/components/Input";
import cn from "classnames";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Controller, useForm } from "react-hook-form";
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
  Provider,
} from "@chargebee/chargebee-js-react-wrapper";
import { Select } from "$/components/Select";
import countries from "i18n-iso-countries";
import {
  AccountType,
  BookingHold,
  BookingHoldState,
  useCheckoutMutation,
  useCreateAccountMutation,
  useCreateSessionMutation,
  useCreateUserPackageMutation,
  useUpsertBookingHoldMutation,
} from "$/graphql/types.generated";
import { Eye, EyeOff } from "lucide-react";
import { Auth } from "@aws-amplify/auth";
import { Mixpanel } from "$/tracking";
import { useAuth } from "$/state/auth";
import { useNavigate } from "react-router";
import { useUser } from "$/state/user";
import { useSearchParams } from "react-router-dom";
import { packages } from "../Enquiry/components/Programme";
import { addWeeks, endOfDay } from "date-fns";
import { useCompanySubscription } from "$/state/company-subscription";
import { DateTime } from "luxon";

const newUserSchema = z.object({
  firstName: z.string().nonempty({ message: "First name is required" }),
  lastName: z.string().nonempty({ message: "Last name is required" }),
  email: z.string().email({ message: "Invalid email" }),
  company: z.string().optional(),
  password: z.string().min(8, `Must contain at least 8 characters, 1 letter and 1 number`),
});

const addressSchema = z.object({
  address: z.object({
    line1: z.string().nonempty({ message: "Address is required" }),
    city: z.string().nonempty({ message: "City is required" }),
    state: z.string().nonempty({ message: "County is required" }),
    postCode: z.string().nonempty({ message: "Postcode is required" }),
    country: z.string().nonempty({ message: "Country is required" }),
  }),
});

interface CheckoutFormProps {
  bookingHold?: BookingHold;
}

export default function CheckoutForm({ bookingHold }: CheckoutFormProps) {
  const ref = useRef();
  const user = useUser();
  const companySubscription = useCompanySubscription();
  const auth = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [paymentError, setPaymentError] = React.useState<string | null>(null);

  const schema = user?.currentUser?.id ? addressSchema : newUserSchema.and(addressSchema);

  const email =
    bookingHold?.enquiry?.user?.email ||
    bookingHold?.enquiry?.enquiryChoices?.find((choice) => choice.key === "email").value;

  const programmeName = searchParams.get("programme");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: email ?? "",
      company: "",
      password: "",
      address: {
        line1: "",
        city: "",
        state: "",
        postCode: "",
        country: "GB",
      },
    },
  });

  const checkout = useCheckoutMutation();
  const createAccount = useCreateAccountMutation();
  const createUserPackage = useCreateUserPackageMutation();
  const createSession = useCreateSessionMutation();
  const upsertBookingHold = useUpsertBookingHoldMutation();

  return (
    <div>
      <div>
        {!user.currentUser?.id && (
          <>
            <h2 className="mb-4">Your details</h2>
            <div className="flex flex-wrap justify-between gap-y-2">
              <Input
                label="Email"
                helperTextClasses={cn({ hidden: !errors.email })}
                required
                placeholder="name@email.com"
                register={register}
                name="email"
                pattern={{
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                }}
                disabled
                error={errors.email}
              />
              <Input
                label="First name"
                wrapperClass="w-full xl:!w-[49%]"
                helperTextClasses={cn({ hidden: !errors.firstName })}
                placeholder="First name"
                required
                name="firstName"
                register={register}
                error={errors.firstName}
              />
              <Input
                label="Last name"
                wrapperClass="w-full xl:!w-[49%]"
                helperTextClasses={cn({ hidden: !errors.lastName })}
                placeholder="Last name"
                required
                name="lastName"
                register={register}
                error={errors.lastName}
              />

              <Input
                label="Create a password"
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                wrapperClass="w-full xl:!w-[49%]"
                required
                register={register}
                pattern={{
                  value: /^(?!.* )(?=.*[0-9])(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                  message: "Must contain at least 8 characters, 1 letter and 1 number",
                }}
                helperTextClasses={cn({ hidden: !errors.password })}
                rightIcon={
                  <div
                    className="h-full flex items-center cursor-pointer"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <Eye size="1.3rem" /> : <EyeOff size="1.3rem" />}
                  </div>
                }
                error={errors.password}
              />
              <Input
                label="Company name"
                wrapperClass="w-full xl:!w-[49%]"
                placeholder="Optional"
                helperTextClasses={cn({ hidden: !errors.company })}
                name="company"
                register={register}
                error={errors.company}
              />
            </div>
            <hr className="border-0 bg-gray-200 h-px mt-4" />
          </>
        )}
        <h2 className="mb-4">Card Details</h2>
        <Provider cbInstance={window.Chargebee.getInstance()}>
          <CardComponent ref={ref} className="flex flex-wrap w-full justify-between gap-y-2">
            <div className="w-full">
              <label className="mb-2 block">
                <span className="ml-1 text-red">*</span> Card Number
              </label>

              <CardNumber placeholder={"4111 1111 1111 1111"} className="input" />
            </div>
            <div className="w-full xl:!w-[49%]">
              <label className="mb-2 block">
                <span className="ml-1 text-red">*</span> Expiry
              </label>
              <CardExpiry className="input" />
            </div>
            <div className="w-full xl:!w-[49%]">
              <label className="mb-2 block">
                <span className="ml-1 text-red">*</span> CVC
              </label>

              <CardCVV className="input" />
            </div>
          </CardComponent>
        </Provider>
        {paymentError && <div className="bg-pink p-4 mt-4 rounded-2xl">{paymentError}</div>}
        <hr className="border-0 bg-gray-200 h-px mt-4" />
        <h2 className="mb-4">Billing Address</h2>
        <div className="flex flex-wrap justify-between gap-y-4">
          <Input
            label="Line 1"
            wrapperClass="w-full xl:!w-[49%]"
            helperTextClasses={cn({ hidden: !errors.address?.line1 })}
            required
            placeholder="1 London Road"
            name="address.line1"
            register={register}
            error={errors.address?.line1}
          />
          <Input
            label="City"
            wrapperClass="w-full xl:!w-[49%]"
            helperTextClasses={cn({ hidden: !errors.address?.city })}
            required
            placeholder="London"
            name="address.city"
            register={register}
            error={errors.address?.city}
          />
          <Input
            label="County"
            wrapperClass="w-full xl:!w-[49%]"
            helperTextClasses={cn({ hidden: !errors.address?.state })}
            required
            placeholder="Greater London"
            name="address.state"
            register={register}
            error={errors.address?.state}
          />
          <Input
            label="Postcode"
            wrapperClass="w-full xl:!w-[49%]"
            required
            placeholder="SW1A 1AA"
            helperTextClasses={cn({ hidden: !errors.address?.postCode })}
            name="address.postCode"
            register={register}
            error={errors.address?.postCode}
          />
          <Controller
            name="address.country"
            control={control}
            render={({ field }) => (
              <Select
                options={Object.entries(countries.getNames("en")).map(([key, value]) => ({
                  label: value,
                  value: key,
                }))}
                label="Country"
                wrapperClass="w-full xl:!w-[49%]"
                defaultValue={{ label: "United Kingdom", value: "GB" }}
                required
                {...field}
                value={{
                  label: countries.getNames("en")[field.value],
                  value: field.value,
                }}
                onChange={({ value }) => field.onChange({ target: { value } })}
                error={errors.address?.country}
              />
            )}
          />
        </div>

        <Button
          primary
          large
          className="w-full mt-12"
          isSubmitting={isSubmitting}
          onClick={async (e) => {
            handleSubmit(async ({ password, ...values }: z.infer<typeof schema>) => {
              const token = await ref!.current!.tokenize({});

              const packageDetails = packages.find(({ title }) => title === programmeName);

              if (token?.token) {
                const payment = await checkout.mutateAsync({
                  input: {
                    email: user.currentUser?.id ? user.currentUser?.email : values.email,
                    firstName: user.currentUser?.id
                      ? user.currentUser?.firstName
                      : values.firstName,
                    lastName: user.currentUser?.id ? user.currentUser?.lastName : values.lastName,
                    company: user.currentUser?.id
                      ? user.currentUser?.teamMember?.company
                      : values.company,
                    plan: packageDetails.chargebeeId,
                    token: token.token,
                    address: values.address,
                  },
                });

                if (!payment.checkout.success) {
                  setPaymentError(payment.checkout.message);
                } else {
                  let userId = user.currentUser?.id;
                  if (values.email && password) {
                    try {
                      const _email = values.email?.trim().toLowerCase();

                      const newUser = await Auth.signUp({
                        username: _email,
                        password,
                        attributes: {
                          email: _email,
                          given_name: values.firstName,
                          family_name: values.lastName,
                          "custom:companyName": values.company.trim().length
                            ? values.company
                            : "Personal",
                          "custom:role": "admin",
                          "custom:type": AccountType.Individual,
                        },
                      });

                      const createdUser = await createAccount.mutateAsync({
                        input: {
                          cognitoId: newUser.userSub,
                          companyName: values.company.trim().length ? values.company : "Personal",
                          email: _email,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          type: AccountType.Individual,
                        },
                      });

                      userId = createdUser.createAccount.id;

                      await Auth.signIn(_email, password);

                      Mixpanel.track("Company Created", {
                        companyName: values.company.trim().length ? values.company : "Personal",
                      });

                      await auth.initializeUser();
                    } catch (err: any) {
                      console.error(err);
                    }
                  }
                  try {
                    const createdPackage = await createUserPackage.mutateAsync({
                      userId,
                      coachId: bookingHold.coach.id,
                      label: packageDetails?.label ?? packageDetails.title,
                      plan: bookingHold.enquiry.enquiryChoices.find(
                        (choice) => choice.key === "path",
                      ).value,
                      sessionCount: packageDetails.sessionCount,
                      endDate: packageDetails.expiryWeeks
                        ? addWeeks(endOfDay(new Date()), packageDetails.expiryWeeks)
                        : null,
                    });

                    companySubscription.setSubscriptionStatus({
                      active: true,
                      status: "activePackage",
                      packageCustomer: true,
                    });

                    const sessionTime = DateTime.fromISO(bookingHold?.sessionStartsAt);

                    await createSession.mutateAsync({
                      input: {
                        teamMemberId: createdPackage.createUserPackage.user.teamMember.id,
                        coachId: bookingHold?.coach?.id!,
                        startsAt: sessionTime.toISO(),
                        endsAt: sessionTime.plus({ hour: 1 }).toISO(),
                        approaches: [],
                        categories: [],
                        specialisms: [],
                        audienceExperiences: [],
                        packages: [],
                        packageId: createdPackage.createUserPackage.id,
                      },
                    });

                    await upsertBookingHold.mutateAsync({
                      create: { enquiry: { connect: { id: bookingHold.enquiry.id } } },
                      update: {
                        state: BookingHoldState.Booked,
                      },
                      where: {
                        id: bookingHold.id,
                      },
                    });

                    await user.invalidate();

                    setTimeout(() => {
                      navigate(`/dashboard?ctx=checkout-success`);
                    }, 1000);
                  } catch (err) {
                    console.error(err);
                    navigate(`/dashboard?ctx=checkout-failed`);
                  }
                }
              }
            })(e);
          }}
        >
          Pay
        </Button>
        <a href="https://chargebee.com" className="mt-4" target="_blank" rel="noreferrer">
          <h4 className="!text-[#92A1A8] text-center mt-4 w-full text-sm"> Powered by Chargebee</h4>
        </a>
      </div>
    </div>
  );
}
