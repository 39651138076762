export function Heart({ fill = "none", stroke = "currentColor", size = 16, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill={fill}
      {...rest}
    >
      <path
        d="M18.4203 2.54311C15.9828 2.54311 13.5828 4.11811 12.7953 6.29311C12.0078 4.11811 9.60779 2.54311 7.17029 2.54311C5.67845 2.54311 4.2477 3.13574 3.19281 4.19063C2.13792 5.24552 1.54529 6.67626 1.54529 8.16811C1.54529 13.0431 5.67029 17.9181 12.7953 22.7931C19.9203 17.9181 24.0453 13.0431 24.0453 8.16811C24.0453 6.67626 23.4527 5.24552 22.3978 4.19063C21.3429 3.13574 19.9121 2.54311 18.4203 2.54311V2.54311Z"
        stroke={stroke}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
