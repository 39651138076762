import { message, Modal, Typography } from "antd";

import {
  TeamMember,
  UserRole,
  useUpdateCompanySponsorMutation,
  useUpdateUserMutation,
} from "$/graphql/types.generated";

import { Mixpanel } from "$/tracking";

interface ConfirmRoleChangeModalProps {
  isOpen: boolean;
  onClose: (modalVisible: boolean) => void;
  teamMember: TeamMember;
  updatedRole: UserRole;
  onSuccess: () => void;
}

export const ConfirmRoleChangeModal = ({
  isOpen,
  onClose,
  teamMember,
  updatedRole,
  onSuccess = () => null,
}: ConfirmRoleChangeModalProps) => {
  const mutationOptions = {
    onSuccess: () => {
      message.success(
        `${teamMember?.user?.firstName} ${teamMember?.user?.lastName} role has been changed to ${updatedRole}.`,
      );

      Mixpanel.track("Team Member Role Updated", {
        role: updatedRole,
      });
      onSuccess();

      onClose(false);
    },
    onError: () =>
      message.error(
        `We could not change ${teamMember?.user?.firstName} ${teamMember?.user?.lastName} role to ${updatedRole}. Please try again or chat with us.`,
      ),
  };

  const updateUserMutation = useUpdateUserMutation(mutationOptions);
  const updateCompanySponsorMutation = useUpdateCompanySponsorMutation(mutationOptions);

  const handleOk = async () => {
    if (updatedRole === UserRole.Sponsor) {
      if (!teamMember?.user?.id || !teamMember?.companyId) return;

      return updateCompanySponsorMutation.mutateAsync({
        userId: teamMember?.user?.id,
        companyId: teamMember?.companyId,
      });
    }

    return updateUserMutation.mutateAsync({
      where: {
        id: teamMember?.user?.id,
      },
      data: {
        role: updatedRole,
      },
    });
  };

  const handleCancel = () => onClose(false);

  const staffToAdminMessage =
    updatedRole === UserRole.Admin &&
    teamMember?.user?.role === UserRole.TeamMember &&
    `You are about to change the account privileges of ${teamMember?.user?.email} to ADMINISTRATOR, are you sure you want to do this?`;

  const adminToStaffMessage =
    updatedRole === UserRole.TeamMember &&
    teamMember?.user?.role === UserRole.Admin &&
    `You are about to change the account privileges of ${teamMember?.user?.email} to TEAM MEMBER, are you sure you want to do this?`;

  const adminToSponsorMessage =
    updatedRole === UserRole.Sponsor &&
    (teamMember?.user?.role === UserRole.Sponsor || teamMember?.user?.role === UserRole.Admin) &&
    `You are about to change the account privileges of ${teamMember?.user?.email} to SPONSOR. This will change the primary billing contact to ${teamMember?.user?.firstName} ${teamMember?.user?.lastName}.`;

  return (
    <Modal title="Change Account Type" open={isOpen} onOk={handleOk} onCancel={handleCancel}>
      <Typography.Paragraph>
        {staffToAdminMessage}
        {adminToStaffMessage}
        {adminToSponsorMessage}
      </Typography.Paragraph>
    </Modal>
  );
};
