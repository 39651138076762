import { Button } from "$/components/Button";
import { Star, Smileys } from "$/components/Icons";
import { Modal } from "$/components/Modal";
import { TextArea, TextAreaWithButton } from "$/components/TextArea";
import { useEffect, useState } from "react";
import { ArrowLeft, ArrowRight, Check, X } from "lucide-react";
import { NPS } from "./NPS";
import { Session, useCreateSessionReviewMutation } from "$/graphql/types.generated";
import { useIsMobile } from "$/hooks";
import { toast } from "$/components/Toaster";

function DidTheCallTakePlacePage({ onNext }: { onNext: (value: boolean) => void }) {
  return (
    <div className="text-center h-full flex flex-col">
      <h1>Did your call take place?</h1>
      <div className="flex grow items-center w-[300px] mx-auto gap-x-4 justify-center ">
        <Button error large leftIcon={<X color="white" />} onClick={() => onNext(false)}>
          No it didn&apos;t
        </Button>
        <Button
          className="bg-bottleGreen border-bottleGreen border-solid text-white"
          large
          rightIcon={<Check color="white" />}
          onClick={() => onNext(true)}
        >
          Yes it did
        </Button>
      </div>
    </div>
  );
}

function MissedSessionPage() {
  return (
    <div className="text-center h-full">
      <h1>We&apos;re sorry to hear that</h1>
      <p>
        DO WE WANT TO FIND OUT WHO CAUSED THE MISS HERE? IF IT WAS THE COACH WE PROBS WANT TO
        APOLOGISE AND FOLLOW UP. IF IT WAS THE CLIENT WE PROBABLY WANT TO REITERATE ABOUT OUR
        CANCELLATION POLICY
      </p>
    </div>
  );
}

function HowDoYouFeelPage({ onNext }: { onNext: (value: number) => void }) {
  const isMobile = useIsMobile();
  return (
    <div className="text-center h-full flex flex-col">
      <h1>How do you feel now about the situation you took to your session?</h1>
      <div className="flex grow items-center justify-center gap-x-2">
        <div className="cursor-pointer" onClick={() => onNext(1)}>
          <Smileys.Sad size={isMobile ? 45 : 75} />
        </div>
        <div className="cursor-pointer" onClick={() => onNext(2)}>
          <Smileys.Unhappy size={isMobile ? 45 : 75} />
        </div>
        <div className="cursor-pointer" onClick={() => onNext(3)}>
          <Smileys.Medium size={isMobile ? 45 : 75} />
        </div>
        <div className="cursor-pointer" onClick={() => onNext(4)}>
          <Smileys.Happy size={isMobile ? 45 : 75} />
        </div>
        <div className="cursor-pointer" onClick={() => onNext(5)}>
          <Smileys.Ecstatic size={isMobile ? 45 : 75} />
        </div>
      </div>
    </div>
  );
}

function RateYourCoachPage({
  onNext,
  ratingValue,
}: {
  onNext: (value: number) => void;
  ratingValue: number;
}) {
  const isMobile = useIsMobile();
  const [stars, setStars] = useState(ratingValue || 1);
  return (
    <div className="text-center h-full flex flex-col justify-between">
      <h1>How would you rate your coach?</h1>
      <h2>This rating is shared with your coach.</h2>
      <h2>You&apos;ll have a chance to leave a testimonial and/or feedback on the next screens.</h2>
      <div>
        {Array.from({ length: 5 }).map((_, i) => (
          <Star
            key={`star-${i}`}
            stroke="#D5881C"
            fill={stars > i ? "#D5881C" : "none"}
            onClick={() => setStars(i + 1)}
            className="cursor-pointer"
            size={isMobile ? 45 : 75}
          />
        ))}
      </div>
      <Button
        primary
        large
        onClick={() => onNext(stars)}
        className="w-fit mx-auto"
        rightIcon={<ArrowRight size="16px" />}
      >
        Next
      </Button>
    </div>
  );
}

function AnonymousTestimonialPage({
  onNext,
  coach,
  testimonialValue,
}: {
  onNext: (value: string) => void;
  coach: string;
  testimonialValue: string;
}) {
  const [testimonial, setTestimonial] = useState(testimonialValue);

  return (
    <div className="flex flex-col justify-between text-center h-full">
      <div>
        <h1>Your anonymous testimonial about your session with {coach.split(" ")[0]}</h1>
        <p>This testimonial is shared with your coach and made public on their profile.</p>
        <p>Any developmental feedback should go in the next section.</p>
      </div>
      <TextAreaWithButton
        value={testimonial}
        onChange={(event) => setTestimonial(event.target.value)}
        rows={4}
        handleClick={() => onNext(testimonial)}
        buttonText="Submit"
      />
    </div>
  );
}

function DevelopmentalFeedbackPage({
  onNext,
  coach,
  feedbackValue,
}: {
  onNext: (value: string) => void;
  coach: string;
  feedbackValue: string;
}) {
  const [feedback, setFeedback] = useState(feedbackValue);

  return (
    <div className="flex flex-col justify-between text-center h-full">
      <div>
        <h1>Is there anything else {coach.split(" ")[0]} could have done to support you?</h1>
        <p>
          More Happi coaches give their time to develop their skills and become exceptional coaches.
          Sharing your feedback with them will help them improve.
        </p>
        <p>
          This feedback is private and intended solely for your coach. It will not be shared
          publicly.
        </p>
      </div>
      <TextAreaWithButton
        value={feedback}
        onChange={(event) => setFeedback(event.target.value)}
        rows={4}
        handleClick={() => onNext(feedback)}
        buttonText="Submit"
      />
    </div>
  );
}

function NPSPage({ onNext, submitting }: { onNext: (value: number) => void; submitting: boolean }) {
  const [score, setScore] = useState<number | undefined>();
  return (
    <div className="text-center h-full flex flex-col gap-y-8">
      <div>
        <h1>
          On a scale of 0 to 10, how likely are you to recommend More Happi to those looking to
          progress in their career and life?
        </h1>
        <p>Click on the dots to select a rating.</p>
      </div>
      <div className="w-[95%] md:w-[90%] mx-auto">
        <NPS onChange={setScore} />
      </div>
      <Button
        primary
        large
        onClick={() => onNext(score)}
        disabled={!score}
        className="w-fit mx-auto"
        isSubmitting={submitting}
      >
        Submit review
      </Button>
    </div>
  );
}

interface SessionReviewModalProps {
  sessionId: string;
  session: Session;
  onClose: (shouldRefetch?: boolean) => void;
}

interface SubmitProps {
  coachRating: number;
  extraSupport?: string;
  feelingScore: number;
  nps: number;
  testimonial?: string;
  tookPlace: boolean;
}

export function SessionReviewModal({ sessionId, onClose, session }: SessionReviewModalProps) {
  const [page, setPage] = useState("didTheCallTakePlace");
  const [history, setHistory] = useState([]);
  const [values, setValues] = useState<SubmitProps>({
    coachRating: 0,
    feelingScore: 0,
    nps: 0,
    tookPlace: false,
  });

  const createSessionReview = useCreateSessionReviewMutation();

  const submit = async (toSubmit: SubmitProps) => {
    await createSessionReview.mutateAsync({
      data: {
        session: { connect: { id: sessionId } },
        ...toSubmit,
      },
    });

    if (toSubmit.tookPlace === false) {
      toast.error(
        "Thank you for letting us know",
        <div>
          We&apos;ll notify our support team. If you&apos;d like to book with this coach again,{" "}
          <a href={`/coach/${session.coach?.handle}`} className="text-textGrey underline">
            click here
          </a>
        </div>,
      );
    } else {
      toast.success(
        "Thank you for your review!",
        <div>
          Thanks to you, {session.coach?.displayName} will gain valuable feedback and get better at
          their practice. To book again with {session.coach?.displayName?.split(" ")[0]},{" "}
          <a href={`/coach/${session.coach.handle}`}>click here</a>
        </div>,
      );
    }
    onClose(true);
  };

  return (
    <Modal onClose={onClose} size="lg">
      {page !== "didTheCallTakePlace" && (
        <div
          className="absolute flex w-16 h-6 cursor-pointer top-3 left-3"
          onClick={() => {
            const _history = [...history];
            const newPage = _history.pop();
            setPage(newPage);
            setHistory(_history);
          }}
        >
          <ArrowLeft /> <p className="pl-1">Back</p>
        </div>
      )}
      <div className="h-[600px] md:h-[450px] pt-8 pb-0 flex flex-col justify-between items-between">
        {page === "didTheCallTakePlace" && (
          <DidTheCallTakePlacePage
            onNext={(value) => {
              setValues((prev) => ({ ...prev, tookPlace: value }));
              if (value === false) {
                return submit({ ...values, tookPlace: false });
              }
              setHistory((prev) => [...prev, page]);
              setPage("howDoYouFeel");
            }}
          />
        )}
        {page === "missedSession" && <MissedSessionPage />}
        {page === "howDoYouFeel" && (
          <HowDoYouFeelPage
            onNext={(value) => {
              setValues((prev) => ({ ...prev, feelingScore: value }));
              setHistory((prev) => [...prev, page]);
              setPage("rateYourCoach");
            }}
          />
        )}
        {page === "rateYourCoach" && (
          <RateYourCoachPage
            ratingValue={values.coachRating}
            onNext={(value) => {
              setValues((prev) => ({ ...prev, coachRating: value }));
              setHistory((prev) => [...prev, page]);
              setPage("testimonial");
            }}
          />
        )}
        {page === "testimonial" && (
          <AnonymousTestimonialPage
            coach={session.coach?.displayName}
            testimonialValue={values.testimonial || ""}
            onNext={(value) => {
              setValues((prev) => ({ ...prev, testimonial: value }));
              setHistory((prev) => [...prev, page]);
              setPage("feedback");
            }}
          />
        )}
        {page === "feedback" && (
          <DevelopmentalFeedbackPage
            coach={session.coach?.displayName}
            feedbackValue={values.extraSupport || ""}
            onNext={(value) => {
              setValues((prev) => ({ ...prev, extraSupport: value }));
              setHistory((prev) => [...prev, page]);
              setPage("nps");
            }}
          />
        )}
        {page === "nps" && (
          <NPSPage
            submitting={createSessionReview.isPending}
            onNext={(value) => {
              setValues((prev) => ({ ...prev, nps: value }));
              submit({ ...values, nps: value });
            }}
          />
        )}
      </div>
    </Modal>
  );
}
