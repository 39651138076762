import { CoacheeSessionCard } from "$/components/SessionCard/CoacheeSessionCard";
import { OrderByArg, Session as dbSession, useListSessionsQuery } from "$/graphql/types.generated";
import { useUser } from "$/state/user";
import React, { useState } from "react";
import { getSessions } from "../helpers/getSessions";
import { NoSessionCard } from "./NoSessionCard";
import { SessionReviewModal } from "./SessionReviewModal";
import { ToggleLeft, ToggleRight } from "lucide-react";

export interface BookingListProps {
  setReview: React.Dispatch<React.SetStateAction<string | null>>;
}

interface CoacheeListProps extends BookingListProps {
  review: string | null;
}

export const CoacheeList = ({ setReview, review }: CoacheeListProps) => {
  const user = useUser();
  const { data: sessions, refetch } = useListSessionsQuery({
    where: {
      teamMemberId: { equals: user.currentUser?.teamMember?.id || null },
    },
    orderBy: [{ startsAt: OrderByArg.Desc }],
  });
  const [showUpcomingSessions, setShowUpcomingSessions] = useState<boolean>(true);
  const [showPastSessions, setShowPastSessions] = useState<boolean>(true);

  const futureSessions = getSessions(sessions, true);
  const pastSessions = getSessions(sessions, false);
  return (
    <>
      <div className="mb-8">
        <div className="flex justify-between">
          <h3 className="text-2xl">Upcoming sessions</h3>
          <div className="flex gap-2">
            <p>Hide upcoming sessions</p>
            {showUpcomingSessions ? (
              <ToggleLeft
                className="cursor-pointer"
                onClick={() => setShowUpcomingSessions(false)}
              />
            ) : (
              <ToggleRight
                className="cursor-pointer"
                onClick={() => setShowUpcomingSessions(true)}
              />
            )}
          </div>
        </div>

        {showUpcomingSessions && (
          <div>
            {futureSessions?.length ? (
              <div className="flex flex-col gap-y-6">
                {futureSessions?.map((session) => (
                  <CoacheeSessionCard key={session?.id} session={session} timescale="future" />
                ))}
              </div>
            ) : (
              <NoSessionCard
                message="You don't have any upcoming sessions!"
                imageSrc="/images/user_tools/everyday.svg"
                altText="No Upcoming Sessions"
              />
            )}
          </div>
        )}
      </div>
      <div>
        <div className="flex justify-between">
          <h3 className="text-2xl">Past sessions</h3>
          <div className="flex gap-2">
            <p>Hide Past sessions</p>
            {showPastSessions ? (
              <ToggleLeft className="cursor-pointer" onClick={() => setShowPastSessions(false)} />
            ) : (
              <ToggleRight className="cursor-pointer" onClick={() => setShowPastSessions(true)} />
            )}
          </div>
        </div>

        {showPastSessions && (
          <div>
            {pastSessions?.length ? (
              <div className="flex flex-col gap-y-6">
                {pastSessions?.map((session) => (
                  <CoacheeSessionCard
                    key={session?.id}
                    session={session}
                    timescale="past"
                    onReview={setReview}
                  />
                ))}
              </div>
            ) : (
              <NoSessionCard
                message="You don't have any past sessions!"
                imageSrc="/images/user_tools/promotions.svg"
                altText="No Past Sessions"
              />
            )}
          </div>
        )}
      </div>

      {review && (
        <SessionReviewModal
          onClose={async (shouldRefetch) => {
            if (shouldRefetch) await refetch();
            setReview(null);
          }}
          session={sessions?.listSessions?.find((session) => session?.id === review) as dbSession}
          sessionId={review}
        />
      )}
    </>
  );
};
