import { useSearchParams } from "react-router-dom";
import { useGetCoachQuery } from "$/graphql/types.generated";
import { BuildPackage, ExplorePackage, ProgressPackage } from "../Enquiry/components/Programme";
import { app } from "$/configs/app.config";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
import { formatProgrammeData } from "../Enquiry/helpers/storyblok";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { useIsMobile } from "$/hooks";
import { DateTime } from "luxon";

function PackageCheckout({ bookingHold }) {
  const isMobile = useIsMobile(1280);
  const [searchParams] = useSearchParams();

  const coach = useGetCoachQuery({ where: { id: bookingHold?.coach.id } });

  const programme = bookingHold.enquiry?.enquiryChoices?.find((choice) => choice.key === "path")
    ?.value;

  const packageType = searchParams.get("programme");

  const programmeQuiz = useGetProgrammeQuizQuery(
    { id: `programmes/${programme}` },
    { enabled: !!programme },
  );

  const programmeData =
    programmeQuiz?.data?.PageItem?.content &&
    formatProgrammeData(programmeQuiz?.data?.PageItem?.content);

  let Package = null;

  if (!programmeData) return <LoadingSpinner />;

  if (packageType === "explore") Package = ExplorePackage;
  if (packageType === "build") Package = BuildPackage;
  if (packageType === "progress") Package = ProgressPackage;
  if (!packageType) Package = () => <LoadingSpinner />;

  return (
    <div>
      <Package data={programmeData.programmes} small={isMobile} />
      <div className="mt-4 bg-skin xl:bg-white px-5 py-4 rounded-2xl flex items-center gap-6">
        <img
          src={`${app.CLOUDFRONT.USER_ASSETS}/${coach?.data?.getCoach?.id}/thumbnail.jpg`}
          alt=""
          className="rounded-full w-20 h-20"
        />
        <div>
          <h3 className="text-2xl font-semibold mb-0">
            {" "}
            with {coach?.data?.getCoach?.displayName}
          </h3>
          {bookingHold.startsAt && (
            <p className="text-lg mb-2">
              <b>First session:</b>{" "}
              {DateTime.fromISO(bookingHold.sessionStartsAt).toLocaleString({
                weekday: "long",
                month: "long",
                day: "numeric",
                hour: "numeric",
                hourCycle: "h12",
              })}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default function CheckoutDetails({ bookingHold }) {
  const [searchParams] = useSearchParams();

  if (searchParams.get("ctx") === "enquiry") return <PackageCheckout bookingHold={bookingHold} />;
}
