import React from "react";
import { Alert, List } from "antd";

import { ICompBaseProps } from "$/interfaces";

interface IProps extends ICompBaseProps {
  errors: Array<{ message: string }>;
}

export const WarningMessage = (props: IProps) =>
  props.errors !== undefined &&
  props.errors.length > 0 && (
    <Alert
      message={
        <List
          dataSource={props.errors}
          renderItem={(item) => {
            const isErrorString = typeof item === "string";
            return <List.Item>{isErrorString ? item : item.message}</List.Item>;
          }}
        />
      }
      showIcon
      type="warning"
      style={{ textAlign: "left" }}
    />
  );
