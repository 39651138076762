import cx from "classnames";
import React from "react";

import { IPageBaseProps } from "$/interfaces";

import { HtmlMeta } from "$/components/HtmlMeta";

import styles from "./styles.module.less";

interface IProps extends IPageBaseProps {}

export const Error404: React.FC<IProps> = (props) => {
  return (
    <div
      className={cx(
        styles["comp-wrapper"],
        `g-comp--${Error404.displayName}`,
        props.className,
      )}
      style={props.style}
    >
      <HtmlMeta title="404" />
      <h2>Error 404</h2>
    </div>
  );
};
