import React, { useEffect, useState } from "react";
import { useScroll } from "$/utils/scroll";
import { WEB_ASSETS_URL } from "$/configs/app.config";
import Markdown from "react-markdown";

const Loading = () => (
  <div className="flex w-[60px] justify-between">
    <div className="first-circle h-4 w-4 animate-bounce rounded-full bg-yellow p-2" />
    <div className="second-circle h-4 w-4 animate-bounce rounded-full bg-yellow p-2" />
    <div className="third-circle h-4 w-4 animate-bounce rounded-full bg-yellow p-2" />
  </div>
);

export default function MHMessage({
  text,
  firstOfBlock = false,
  noDelay,
  loadingTime = 2000,
  delay = 0,
}: {
  text: string;
  delay?: number;
  firstOfBlock?: boolean;
  loadingTime?: number;
  noDelay?: boolean;
}) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const { scrollToBottom } = useScroll();

  useEffect(() => {
    setTimeout(() => setShow(true), noDelay ? 0 : delay);
    setTimeout(() => setLoading(false), noDelay ? 0 : loadingTime);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [show, delay]);

  return (
    <>
      {show && (
        <>
          {firstOfBlock && (
            <img src={`${WEB_ASSETS_URL}/User_Icon_Smiley_round.svg`} alt="" className="h-8 w-8" />
          )}
          <div className="mh-message mb-2 w-fit min-w-[300px] max-w-[80%] md:max-w-[70%] rounded-3xl text-lg bg-white text-midnight text-left p-4 whitespace-pre-wrap">
            {loading ? <Loading /> : <Markdown>{text.trim()}</Markdown>}
          </div>
        </>
      )}
    </>
  );
}
