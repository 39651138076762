import React, { useEffect } from "react";
import { Layout } from "antd";

import { useUser } from "$/state/user";
import { useCompany } from "$/state/company";
import { Navigation } from "$/components/Navigation";
import { useIntercom, useIsMobile } from "$/hooks";
import { Mixpanel } from "$/tracking";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { SubscriptionNotice } from "$/components/SubscriptionNotice";
import { ImpersonationNotice } from "$/components/ImpersonationNotice";

export interface IProps {
  children: React.ReactNode;
  disableHeader?: boolean;
  disableFooter?: boolean;
  disableSubscriptionBanner?: boolean;
  page?: string;
}

export const MasterLayout: React.FC<IProps> = (props) => {
  useIntercom();

  const user = useUser();
  const company = useCompany();

  if (!user.currentUser?.id || !company.currentCompany?.id) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      {user.currentUser.isImpersonating && <ImpersonationNotice />}
      {!props.disableSubscriptionBanner && <SubscriptionNotice />}
      <div className="w-screen overflow-x-hidden">
        {props?.disableHeader || <Navigation />}
        {/*<OnboardingVideoBanner />*/}
        {props.children}
      </div>
    </>
  );
};
