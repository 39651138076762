import { Select } from "antd";

import { useListCoachAudienceApproachesQuery } from "$/graphql/types.generated";

interface Props {
  onChange: (value: string) => void;
  selected: any;
}

export const ApproachesFilter = ({ onChange, selected }: Props) => {
  const { isLoading, error, data } = useListCoachAudienceApproachesQuery();

  const options = data?.listCoachApproaches?.map((category: any) => ({
    label: category.label,
    value: category.value,
  }));

  return (
    <Select
      onChange={onChange}
      placeholder="Approaches"
      loading={isLoading}
      disabled={isLoading || !!error}
      options={options}
      defaultValue={selected}
      mode="multiple"
      allowClear
      showSearch
      size="middle"
      maxTagCount={1}
      style={{
        width: "100%",
      }}
    />
  );
};
