import { useEffect, useState } from "react";

export const useIsMobile = (width?: number) => {
  const _width = width || 821;
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    setIsMobile(window?.innerWidth < _width);

    const updateMedia = () => {
      // @ts-ignore
      setIsMobile(window?.innerWidth < _width);
    };

    window?.addEventListener("resize", updateMedia);

    return () => window?.removeEventListener("resize", updateMedia);
  }, []);

  return isMobile;
};
