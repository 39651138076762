import { DateTime } from "luxon";

interface SessionData {
  teamMemberId: string;
  coachId: string;
  startsAt: string;
  endsAt: string;
  approaches: any[];
  categories: any[];
  specialisms: any[];
  audienceExperiences: any[];
  packages: any[];
  packageId: string;
  coachBooking: boolean;
}

export type RepeatType =
  | "No"
  | "weeklySameDay"
  | "fortnightlySameDay"
  | "monthlySameDay"
  | "monthlySameDate";

const getNthWeekdayOfMonth = (date: DateTime, nthWeek: number, dayOfWeek: number) => {
  const firstOfMonth = date.startOf("month");
  const firstDayOfMonth = firstOfMonth.weekday;
  const dayDiff = (dayOfWeek + 7 - firstDayOfMonth) % 7;
  const firstWeekday = firstOfMonth.plus({ days: dayDiff });

  return firstWeekday.plus({ weeks: nthWeek - 1 });
};

const calculateNthWeek = (date: DateTime) => {
  const dayOfMonth = date.day;
  return Math.ceil(dayOfMonth / 7);
};

const addMonthRetainingWeekday = (date: DateTime, monthsToAdd: number) => {
  const nthWeek = calculateNthWeek(date);
  const dayOfWeek = date.weekday;
  const nextMonthDate = date.plus({ months: monthsToAdd });
  return getNthWeekdayOfMonth(nextMonthDate, nthWeek, dayOfWeek);
};

export const repeatSessions = (
  input: SessionData,
  repeat: RepeatType,
  repeatAmount: number,
  dateAndTime: DateTime,
) => {
  const sessionData = input;
  const repeatingArray = [];

  if (repeat === "No") {
    return [
      {
        input: {
          ...sessionData,
          startsAt: dateAndTime.toISO(),
          endsAt: dateAndTime.plus({ hours: 1 }).toISO(),
        },
      },
    ];
  }

  if (repeat === "weeklySameDay") {
    for (let i = 0; i < repeatAmount; i += 1) {
      repeatingArray.push({
        input: {
          ...sessionData,
          startsAt: dateAndTime.plus({ weeks: i }).toISO(),
          endsAt: dateAndTime.plus({ weeks: i, hours: 1 }).toISO(),
        },
      });
    }
    return repeatingArray;
  }

  if (repeat === "fortnightlySameDay") {
    for (let i = 0; i < repeatAmount; i += 2) {
      repeatingArray.push({
        input: {
          ...sessionData,
          startsAt: dateAndTime.plus({ weeks: i }).toISO(),
          endsAt: dateAndTime.plus({ weeks: i, hours: 1 }).toISO(),
        },
      });
    }
    return repeatingArray;
  }

  if (repeat === "monthlySameDay") {
    for (let i = 0; i < repeatAmount; i += 1) {
      repeatingArray.push({
        ...sessionData,
        startsAt: addMonthRetainingWeekday(dateAndTime, i).toISO(),
        endsAt: addMonthRetainingWeekday(dateAndTime.plus({ hours: 1 }), i).toISO(),
      });
    }
    return repeatingArray;
  }

  if (repeat === "monthlySameDate") {
    for (let i = 0; i < repeatAmount; i += 1) {
      repeatingArray.push({
        input: {
          ...sessionData,
          startsAt: dateAndTime.plus({ months: i }).toISO(),
          endsAt: dateAndTime.plus({ months: i, hours: 1 }).toISO(),
        },
      });
    }
    return repeatingArray;
  }
};
