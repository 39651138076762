export function Happy({ size = 75 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 76 75"
      fill="none"
    >
      <path
        d="M38 75C58.7107 75 75.5 58.2107 75.5 37.5C75.5 16.7893 58.7107 0 38 0C17.2893 0 0.5 16.7893 0.5 37.5C0.5 58.2107 17.2893 75 38 75Z"
        fill="#FDB56F"
      />
      <path
        d="M29.88 28.8701C31.0619 28.8701 32.02 27.912 32.02 26.7301C32.02 25.5482 31.0619 24.5901 29.88 24.5901C28.6981 24.5901 27.74 25.5482 27.74 26.7301C27.74 27.912 28.6981 28.8701 29.88 28.8701Z"
        fill="#504F4E"
      />
      <path
        d="M44.7979 28.8352C45.9645 28.6458 46.7567 27.5466 46.5673 26.38C46.378 25.2133 45.2787 24.4211 44.1121 24.6105C42.9455 24.7999 42.1533 25.8991 42.3426 27.0657C42.532 28.2323 43.6313 29.0246 44.7979 28.8352Z"
        fill="#504F4E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4081 36.3002C19.0078 35.9581 19.7712 36.1669 20.1133 36.7665C23.6874 43.0313 30.4267 47.25 38.1499 47.25C45.873 47.25 52.6124 43.0313 56.1865 36.7665C56.5286 36.1669 57.292 35.9581 57.8916 36.3002C58.4912 36.6423 58.7 37.4057 58.3579 38.0054C54.3572 45.018 46.8069 49.75 38.1499 49.75C29.4928 49.75 21.9426 45.018 17.9418 38.0054C17.5997 37.4057 17.8085 36.6423 18.4081 36.3002Z"
        fill="#504F4E"
      />
    </svg>
  );
}
