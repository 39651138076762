import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";

import { logoutUser } from "$/utils/auth";

const onError = (error: any) => {
  const errorType = error?.errorType?.toLowerCase();
  const message = error?.message?.toLowerCase();

  console.error("error", error);

  if (
    error &&
    (error?.includes("expired") ||
      error?.includes("invalid") ||
      errorType === "UnauthorizedException" ||
      message?.includes("expired"))
  ) {
    return logoutUser();
  }

  return error;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
  mutationCache: new MutationCache({
    onError,
  }),
  queryCache: new QueryCache({
    onError,
  }),
});
