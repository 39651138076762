export function Star({ color = "none", stroke = "currentColor", size = 16, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill={color}
      {...rest}
    >
      <path
        d="M12.7953 18.6681L6.42029 22.4181L7.92029 15.2931L2.67029 10.4181L9.79529 9.66811L12.7953 2.91811L15.7953 9.66811L22.9203 10.4181L17.6703 15.2931L19.1703 22.4181L12.7953 18.6681Z"
        stroke={stroke}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
