import { useScheduler } from "./schedulerContext";
import cn from "classnames";
import { Button } from "$/components/Button";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useIsMobile } from "$/hooks";
import { TimezoneSelect } from "$/components/Select/Selectors/Timezone";
import { useUser } from "$/state/user";
import { DateTime, Settings } from "luxon";
import { Modal } from "$/components/Modal";

interface DayProps {
  date: DateTime;
  selected?: boolean;
  hasAvailability?: boolean;
  onClick?: () => void;
}

function Day({ date, selected, hasAvailability, onClick = () => null }: DayProps) {
  return (
    <div className="flex justify-center w-full mb-4">
      <div
        className={cn(
          "flex flex-col justify-center items-center w-[60px] h-[60px] cursor-pointer",
          {
            "bg-orange rounded-full": selected,
            "rounded-full border border-solid border-black": !selected,
          },
        )}
        onClick={onClick}
      >
        {date.toFormat("ccc")}
        <div className="text-2xl">{date.toFormat("dd")}</div>
      </div>
    </div>
  );
}

const TimezoneModal = ({ timezone }: { timezone: string }) => {
  const user = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [tz, setTz] = useState(timezone ?? DateTime.now().toFormat("z"));
  const navigate = useNavigate();

  const close = () => {
    setIsOpen(false);
    setTz(timezone ?? DateTime.now().toFormat("z"));
  };

  return (
    <div>
      <div className="underline cursor-pointer" onClick={() => setIsOpen(true)}>
        {timezone ?? DateTime.now().toFormat("z")}
      </div>
      {isOpen ? (
        <Modal onClose={close}>
          <TimezoneSelect value={tz} onChange={(newValue) => setTz(newValue)} />
          <div className="flex items-center justify-between">
            <Button error onClick={close} className="w-[48%]">
              Cancel
            </Button>
            <Button
              primary
              onClick={async () => {
                Settings.defaultZone = tz;
                localStorage.setItem("timezone", tz);
                if (user?.currentUser) {
                  await user.update({
                    timezone: tz,
                  });
                }
                navigate(0);
                setIsOpen(false);
              }}
              className="w-[48%]"
            >
              Save
            </Button>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

interface SchedulerTimesProps {
  hideTimeScheduler: () => void;
  selectTime: (date: DateTime, time: number) => void;
  openKey?: boolean;
}

export function SchedulerTimes({ hideTimeScheduler, selectTime, openKey }: SchedulerTimesProps) {
  const user = useUser();

  const { selectedDate, setSelectedDate, availableSlots, nextMonth, prevMonth } = useScheduler();
  const isMobile = useIsMobile();
  const [minMaxTimes, setMinMaxTimes] = useState({ start: 24, end: 0 });

  useEffect(() => {
    const minMax = Object.entries(availableSlots.threeDays).reduce((acc, [day, slots]) => {
      const min = Math.min(...slots.map((slot) => slot.startsAt.hour));
      const max = Math.max(...slots.map((slot) => slot.startsAt.hour));

      const overallMin = Math.min(acc.start, min);
      const overallMax = Math.max(acc.end, max);
      return { start: overallMin, end: overallMax };
    }, minMaxTimes);

    setMinMaxTimes(minMax);
  }, [availableSlots, selectedDate]);

  return (
    <div className="mx-2 md:mx-4 my-8">
      {openKey && (
        <div className="flex justify-center w-[741px] mb-4">
          <section className="flex gap-x-4 mt-4 mx-auto">
            <div className="flex items-center gap-x-2">
              <div
                className={cn(
                  "h-[18px] w-[24px] items-center justify-center bg-transparent relative flex",
                )}
              >
                <div className="w-4 h-4 bg-yellow rounded-lg" />
              </div>
              <div className="text-sm">Open slots</div>
            </div>

            <div className="flex items-center gap-x-2">
              <div
                className={cn(
                  "h-[18px] w-[24px] items-center justify-center bg-transparent relative flex",
                )}
              >
                <div className="w-4 h-4 bg-darkGreen rounded-lg" />
              </div>
              <div className="text-sm">Your More Happi availability</div>
            </div>
            <div className="flex items-center gap-x-2">
              <div
                className={cn(
                  "h-[18px] w-[24px] items-center justify-center bg-transparent relative flex",
                )}
              >
                <div className="w-4 h-4 bg-red rounded-lg" />
              </div>
              <div className="text-sm">Existing Sessions</div>
            </div>
          </section>
        </div>
      )}
      <div>
        <div className="flex items-center justify-between px-4 mb-4">
          <div className="w-full md:w-[30%]">
            <Button
              className="border border-[#1E1E1E]! text-[#1E1E1E]! bg-transparent"
              onClick={hideTimeScheduler}
              leftIcon={<ArrowLeft />}
            >
              Back to calendar view
            </Button>
          </div>

          <TimezoneModal timezone={user?.currentUser?.timezone} />
        </div>
      </div>
      <div className="flex items-center justify-between px-4 md:mx-4">
        <div className=" w-[30%] flex justify-start">
          <div
            className={cn(
              "bg-yellow rounded-full w-9 h-9 flex items-center justify-center cursor-pointer",
              { "opacity-50 cursor-default": selectedDate.hasSame(DateTime.now(), "day") },
            )}
            onClick={() =>
              setSelectedDate((prev) => {
                const newDate = prev.minus({ day: 3 });
                if (newDate.diff(DateTime.now(), "days").days < 0) {
                  return DateTime.now();
                }
                if (newDate.month !== selectedDate.month) {
                  prevMonth();
                }
                return newDate;
              })
            }
          >
            <ArrowLeft />
          </div>
        </div>
        <div className="font-semibold">
          {selectedDate.toFormat(isMobile ? "MMM yy" : "MMMM yyyy")}
        </div>
        <div className="w-[30%] flex justify-end">
          <div
            className="bg-yellow rounded-full w-9 h-9 flex items-center justify-center cursor-pointer"
            onClick={() =>
              setSelectedDate((prev) => {
                const newDate = prev.plus({ day: 3 });
                if (newDate.month !== selectedDate.month) {
                  nextMonth();
                }
                return newDate;
              })
            }
          >
            <ArrowRight />
          </div>
        </div>
      </div>
      <div className="md:w-[750px] mx-auto my-4" key={selectedDate.toISO()}>
        <div className="flex gap-[1px] w-full min-h-[450px] my-8 justify-center">
          {
            <div className="flex gap-x-4 w-full">
              {Object.entries(availableSlots.threeDays).map(([day, slots], dayIndex) => (
                <div
                  className={cn("w-full flex flex-col items-center", {
                    "hidden md:!block": dayIndex !== 1,
                  })}
                  key={`availability-day-${day}`}
                >
                  <Day
                    date={DateTime.fromISO(day)}
                    onClick={() => setSelectedDate(DateTime.fromISO(day))}
                    hasAvailability={slots.length > 0}
                    selected={selectedDate.hasSame(DateTime.fromISO(day), "day")}
                  />
                  {slots.length === 0 ? (
                    <div className="w-full  h-full flex justify-center  font-semibold">
                      No availability
                    </div>
                  ) : (
                    <div className="w-full flex flex-col gap-2">
                      {slots.map((slot, index) => {
                        return (
                          <div
                            key={`time-${day}-${minMaxTimes.start + index}`}
                            className={cn(
                              "rounded-lg w-full h-[40px] flex justify-center items-center",
                              {
                                "bg-yellow cursor-pointer": !slot?.colour,
                                "bg-darkGreen text-white cursor-pointer": slot?.colour === "green",
                                "bg-red cursor-not-allowed": slot?.colour === "red",
                              },
                            )}
                            id={`schedule-time-${slot.startsAt.toFormat("HH:mm")}`}
                            onClick={() => {
                              if (slot?.colour !== "red") {
                                selectTime(DateTime.fromISO(day), slot?.startsAt.hour);
                              }
                            }}
                          >
                            {slot.startsAt.toFormat("h:mm a")}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          }
        </div>
      </div>
    </div>
  );
}
