import { Carousel } from "react-responsive-carousel";
import CarouselCard from "./CarouselCard";
import { useState } from "react";
import { Button } from "../Button";
import { Mixpanel } from "$/tracking";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { Coach } from "$/graphql/types.generated";
import cn from "classnames";
import { useIsMobile } from "$/hooks";

export default function CoachCarousel({
  coaches,
  trackingPrefix,
}: {
  coaches: Array<Coach>;
  trackingPrefix?: string;
}) {
  const [carouselSlide, setCarouselSlide] = useState(0);
  const isMobile = useIsMobile();

  return (
    <>
      <Carousel
        centerMode
        centerSlidePercentage={isMobile ? 90 : 63}
        showArrows={false}
        swipeable
        emulateTouch
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        selectedItem={carouselSlide}
        onChange={(index) => setCarouselSlide(index)}
      >
        {coaches.map((coach) => (
          <div className="px-2" key={coach.handle}>
            <CarouselCard coach={coach} trackingPrefix={trackingPrefix} />
          </div>
        ))}
      </Carousel>
      <div className="flex justify-between mt-4 items-center">
        <div
          className={cn(
            "rounded-full flex items-center justify-center h-[44px] w-[44px] bg-yellow cursor-pointer hover:opacity-80",
            {
              "opacity-60 hover:opacity-60 !cursor-not-allowed": carouselSlide === 0,
            },
          )}
          onClick={() => setCarouselSlide((prev) => (prev > 0 ? prev - 1 : prev))}
        >
          <ArrowLeft />
        </div>
        <div className="flex gap-x-2">
          {[...Array(coaches.length)].map((coach, index) => (
            <div
              className={cn("rounded-full h-[15px] w-[15px] bg-yellow opacity-40", {
                "!opacity-100": carouselSlide === index,
              })}
            />
          ))}
        </div>
        <div
          className={cn(
            "rounded-full flex items-center justify-center h-[44px] w-[44px] bg-yellow cursor-pointer hover:opacity-80",
            {
              "opacity-60 hover:opacity-60 !cursor-not-allowed": carouselSlide === coaches.length,
            },
          )}
          onClick={() => setCarouselSlide((prev) => (prev < 3 ? prev + 1 : prev))}
        >
          <ArrowRight />
        </div>
      </div>
    </>
  );
}
