import cn from "classnames";
import React, { forwardRef, useState } from "react";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import { Info } from "lucide-react";

export interface InputProps extends Omit<React.ComponentPropsWithoutRef<"input">, "pattern"> {
  label?: string | React.ReactNode;
  large?: boolean;
  small?: boolean;
  leftIcon?: any;
  rightIcon?: any;
  wrapperClass?: string;
  helperText?: string;
  helperTextClasses?: string;
  isInvalid?: boolean;
  hideLabel?: boolean;
  labelClasses?: string;
  type?: string;
  numberWarning?: string;
  register?: (name: string, options: Record<string, unknown>) => Record<string, unknown>;
  pattern?: { value: RegExp; message: string };
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  popover?: string | React.ReactNode;
}

export const getError = (
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined,
  label?: string,
) => {
  if (error?.type === "required") {
    if (label) return `Please enter your ${label.toLowerCase()}`;
    return `Please enter a value`;
  }
  return error?.message as string;
};

export const InputWrapper = ({
  children,
  leftIcon,
  rightIcon,
  label,
  id,
  className,
  required,
  helperText,
  isInvalid,
  hideLabel,
  helperTextClasses,
  small,
  labelClasses,
  error,
  popover,
}: InputProps) => {
  return (
    <div className={cn("w-full", className)}>
      <label className={cn("relative block text-gray-700", labelClasses)} htmlFor={id}>
        {leftIcon && <div className="absolute top-[48%] left-3">{leftIcon}</div>}
        {!hideLabel && (
          <div className="mb-2 text-left flex items-baseline gap-x-1">
            <Popover placement="top-start">
              {required && <span className="ml-1 text-red">*</span>}
              {label}
              {popover && (
                <>
                  <PopoverTrigger>
                    <div className="h-full flex items-center cursor-pointer">
                      <Info size={16} />
                    </div>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div className="max-w-[300px] p-2">{popover}</div>
                  </PopoverContent>
                </>
              )}
            </Popover>
          </div>
        )}
        {children}
        {rightIcon && (
          <div
            className={cn("absolute top-[45%] mt-[9px] right-3", {
              "!top-[10%] right-2": small,
            })}
          >
            {rightIcon}
          </div>
        )}
      </label>

      <div className={cn("w-full text-center text-red", helperTextClasses)}>
        {error
          ? getError(error, typeof label === "string" ? label : undefined)
          : helperText || <span>&nbsp;</span>}
      </div>
    </div>
  );
};

export const Input = ({
  label,
  leftIcon,
  rightIcon,
  large,
  small,
  className,
  helperText,
  isInvalid,
  wrapperClass = "",
  hideLabel,
  labelClasses,
  helperTextClasses,
  type,
  register = () => ({ empty: null }),
  pattern,
  required,
  error,
  popover,
  disabled,
  ...props
}: InputProps) => {
  return (
    <InputWrapper
      label={label}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      required={required}
      className={wrapperClass}
      hideLabel={hideLabel}
      helperText={helperText}
      isInvalid={isInvalid}
      small={small}
      labelClasses={labelClasses}
      helperTextClasses={helperTextClasses}
      error={error}
      popover={popover}
    >
      <input
        className={cn(
          "input",
          {
            "input-icon-left": leftIcon,
            "input-large": large,
            "input-small": small,
            "!border-red": !!error,
            "bg-lightGrey": disabled,
          },
          className,
        )}
        required={required}
        type={type || "text"}
        {...props}
        disabled={disabled}
        {...register(props.name || "", { required, pattern })}
      />
    </InputWrapper>
  );
};
