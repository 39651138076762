import { Button } from "$/components/Button";
import CoachTestimonials from "$/components/CoachCard/CoachTestimonials";
import { Modal } from "$/components/Modal";
import { app } from "$/configs/app.config";
import { Coach, useCoachAvailabilityQuery } from "$/graphql/types.generated";

import { Mixpanel } from "$/tracking";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  format,
  isBefore,
  parseISO,
  startOfDay,
} from "date-fns";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";

export default function ResultCoachCard({
  coach,
  onSelect,
}: {
  coach: Coach;
  onSelect: () => void;
}) {
  const [readMore, setReadMore] = useState(false);
  const { data, isLoading } = useCoachAvailabilityQuery({
    coachId: coach.id,
    startsAt: addDays(startOfDay(new Date()), 1).toISOString(),
    endsAt: addMonths(endOfDay(new Date()), 1).toISOString(),
  });

  const [availability, setAvailability] = useState<{
    nextSession: string;
    sessionsThisWeek: number;
    sessionsThisMonth: number;
  }>();

  useEffect(() => {
    if (data) {
      const ordered = orderBy(data.coachAvailability, ["startsAt"], ["asc"]);
      const nextSession = ordered[0];
      const sessionsThisWeek = ordered.filter((session) =>
        isBefore(parseISO(session.startsAt), addWeeks(endOfDay(new Date()), 1)),
      );

      setAvailability({
        nextSession: nextSession?.startsAt
          ? format(parseISO(nextSession.startsAt), "dd/MM/yyyy ha").toLowerCase()
          : "N/A",
        sessionsThisWeek: sessionsThisWeek.length,
        sessionsThisMonth: ordered.length,
      });
    }
  }, [data, isLoading]);

  return (
    <div className={"flex flex-col bg-white rounded-2xl min-h-[500px]"}>
      <div>
        <img
          src={`${app.CLOUDFRONT.USER_ASSETS}/${coach.id}/thumbnail.jpg`}
          alt="profile"
          className={"w-full md:w-[250px] rounded-t-2xl rounded-b-none"}
        />
      </div>
      <div className={"p-3 text-left flex flex-col grow justify-between gap-2 w-[250px]"}>
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="w-full flex flex-row md:flex-col h-[60px] justify-between items-center md:justify-start md:items-start">
              <h1 className="max-w-full text-xl font-semibold mb-0 pb-2 overflow-hidden text-ellipsis whitespace-nowrap">
                {coach.displayName}
              </h1>
              <CoachTestimonials coach={coach} />
            </div>
            <p className="min-h-[60px] text-sm md:text-lg mt-2 mb-3 line-clamp-2">
              {coach.headline}
            </p>
            <p>
              <span className="font-semibold">Next slot:</span> {availability?.nextSession}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <div
              onClick={() => setReadMore(true)}
              className="underline cursor-pointer"
              id="enquiry-coach-card-read-more"
            >
              Read More
            </div>

            <Button
              primary
              onClick={() => {
                Mixpanel.track("Enquiry: Select coach");
                onSelect();
              }}
              id="enquiry-select-coach"
            >
              Select this coach
            </Button>
          </div>
        </div>
      </div>
      {readMore && (
        <Modal size="lg" onClose={() => setReadMore(false)}>
          <div>
            <h1 className="mb-0">{coach.displayName}</h1>
            <h2>{coach.headline}</h2>
            <p>{coach.bio}</p>
            <div>
              <span className="font-semibold">Next available slot:</span>{" "}
              {availability?.nextSession}
            </div>
            <div>
              <span className="font-semibold">Available slots in next 7 days:</span>{" "}
              {availability?.sessionsThisWeek}
            </div>
            <div>
              <span className="font-semibold">Available slots in next 30 days:</span>{" "}
              {availability?.sessionsThisMonth}
            </div>
            <div className="flex justify-end">
              <Button
                primary
                large
                onClick={() => {
                  Mixpanel.track("Enquiry: Select coach");
                  onSelect();
                }}
                id="enquiry-select-coach"
              >
                Select this coach
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
