export default function Calendar() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 186">
        <path
          id="layer1"
          d="M1.00586 2.88672H17.8809V18.0742H1.00586V2.88672Z"
          stroke="#07041A"
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="layer2"
          d="M15.6309 15.8242H3.25586V5.13672H15.6309V15.8242Z"
          stroke="#07041A"
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="layer1_2"
          d="M13.3809 1.19922V6.26172M9.44336 1.19922V6.26172V1.19922ZM5.50586 1.19922V6.26172V1.19922Z"
          stroke="#07041A"
          strokeWidth="0.75"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
