import { useState } from "react";
import { message, Modal, Typography } from "antd";

import {
  TeamMember,
  useRemoveTeamMemberMutation,
} from "$/graphql/types.generated";

import { Mixpanel } from "$/tracking";

interface Props {
  isOpen: boolean;
  onClose: (modalVisible: boolean) => void;
  teamMember: TeamMember;
  refetch: () => void;
}

export const ConfirmTeamMemberRemovalModal = ({
  isOpen,
  onClose,
  teamMember,
  refetch,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const removeTeamMember = useRemoveTeamMemberMutation({
    onSuccess: () => {
      message.success(
        `${teamMember?.user?.firstName} ${teamMember?.user?.lastName} has been removed`,
      );

      Mixpanel.track("Team Member Removed");

      setLoading(false);
      onClose(false);
      refetch();
    },
    onError: () => {
      message.error(
        `${teamMember?.user?.firstName} ${teamMember?.user?.lastName} has not been removed. Please try again or contact us`,
      );

      setLoading(false);
      onClose(false);
      refetch();
    },
  });

  const handleOk = async () => {
    setLoading(true);

    await removeTeamMember.mutateAsync({
      teamMemberId: teamMember.id,
    });
  };

  const handleCancel = () => onClose(false);

  return (
    <Modal
      title={`Remove ${teamMember?.user?.firstName} ${teamMember?.user?.lastName}?`}
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
      closable={loading}
    >
      <Typography.Paragraph>
        Are you sure you want to remove {teamMember?.user?.firstName}{" "}
        {teamMember?.user?.lastName} from your team. This will deactivate their
        account and prevent them from logging in.
      </Typography.Paragraph>
    </Modal>
  );
};
