import { Button } from "$/components/Button";
import { Modal } from "$/components/Modal";
import {
  NumberOfSessionsSelect,
  RepeatingSessionsSelect,
} from "$/components/Select/Selectors/RepeatingSessions";
import {
  GetSessionByIdQuery,
  SessionState,
  useCountSessionsQuery,
  useCreateSessionMutation,
} from "$/graphql/types.generated";
import { useUser } from "$/state/user";
import { MapPin } from "lucide-react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { repeatSessions, RepeatType } from "../helpers/repeatSessions";
import pluralize from "pluralize";
import { Mixpanel } from "$/tracking";

export const Confirm = ({
  setCurrentStep,
  session,
  futureSession,
  isProgramme,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  session: GetSessionByIdQuery;
  futureSession: { date: DateTime; time: number };
  isProgramme: boolean;
}) => {
  const [showThanksModal, setShowThanksModal] = useState<boolean>(false);
  const [repeat, setRepeat] = useState<RepeatType>("No");
  const [repeatAmount, setRepeatAmount] = useState<{ label: number; value: number }>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const user = useUser();
  const createSession = useCreateSessionMutation();

  const { data: totalSessionCountProgramme } = useCountSessionsQuery({
    where: {
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
      userPackageId: { equals: session.getSession.userPackage?.id },
    },
  });

  const sessionsLeft =
    session.getSession?.userPackage?.sessionCount - totalSessionCountProgramme?.countSessions; // eslint-disable-line no-unsafe-optional-chaining

  const date = futureSession.date.toFormat("dd/MM/yyyy");
  const dateAndTime = futureSession.date.plus({ hours: futureSession.time });

  const timezoneDiff =
    futureSession.date.setZone(user.currentUser.timezone).offset -
    futureSession.date.setZone(session.getSession.teamMember.user.timezone).offset;

  const submit = async () => {
    setLoading(true);

    const input = {
      teamMemberId: session?.getSession?.teamMember?.id!,
      coachId: user?.currentUser?.coach?.id!,
      startsAt: dateAndTime.toISO(),
      endsAt: dateAndTime.plus({ hour: 1 }).toISO(),
      approaches: [],
      categories: [],
      specialisms: [],
      audienceExperiences: [],
      packages: [],
      packageId: isProgramme ? session?.getSession?.userPackage?.id : null,
      coachBooking: true,
    };

    const repeatedSessions = repeatSessions(input, repeat, repeatAmount?.value, dateAndTime);

    try {
      const createdSessions = repeatedSessions.map(async (programme) => {
        const createdSession = await createSession.mutateAsync({
          input: programme.input,
        });
      });
      setTimeout(() => {
        // slightly delay to better user experience
        setShowThanksModal(true);
      }, 2000);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setRepeatAmount(null);
  }, [repeat]);

  return (
    <div className="w-full">
      <section className="mx-2 md:mx-0 mb-8 bg-white rounded-xl">
        <div className="flex flex-col items-center p-6 w-full md:w-[70%] mx-auto text-center">
          <h2>
            {isProgramme ? "Programme coaching session" : "Ad-hoc coaching session"} with{" "}
            {session.getSession.teamMember.user.firstName}
          </h2>
          <h1 className="text-2xl md:text-4xl font-semibold ">{dateAndTime.toFormat("DDDD")}</h1>
          <h2 className="text-2xl md:text-4xl font-normal">
            {dateAndTime.toFormat("h a")} to {dateAndTime.plus({ hour: 1 }).toFormat("h a")}
          </h2>
          {timezoneDiff !== 0 && (
            <div className="flex w-full justify-center">
              <h2 className="text-xl md:text-2xl font-normal">
                Coachee&apos;s time: {dateAndTime.minus({ minutes: timezoneDiff }).toFormat("h a")}{" "}
                to {dateAndTime.plus({ hour: 1 }).minus({ minutes: timezoneDiff }).toFormat("h a")}
              </h2>
            </div>
          )}
          <div className="flex flex-col md:flex-row gap-x-12 md:text-lg items-center">
            <div className="flex items-center gap-x-1 text-xl">
              <MapPin fill="#006A39" size={12} /> Online via Google Meet
            </div>
          </div>
          <div className="flex flex-col items-center gap-x-1 m-0">
            <RepeatingSessionsSelect onChange={(value) => setRepeat(value)} />
            <NumberOfSessionsSelect
              onChange={(value) => setRepeatAmount(value)}
              repeat={repeat}
              repeatAmount={repeatAmount}
              sessionsLeft={isProgramme ? sessionsLeft : null}
            />
            <p className="m-0 italic">Sessions will be repeated for a maximum of 3 months:</p>
            <p className="m-0 italic">
              Create up to 12 weekly sessions, 6 fortnightly sessions or 3 monthly sessions.
            </p>
          </div>
          <div className="flex flex-col-reverse md:flex-row w-full gap-4 mt-8">
            <Button tertiary large className="w-full" onClick={() => setCurrentStep("selectTime")}>
              Find another time
            </Button>
            <Button
              primary
              large
              className="w-full"
              disabled={loading || (!repeatAmount && repeat !== "No")}
              onClick={() => {
                Mixpanel.track("Coach Booking: Confirmed", {
                  coach: session.getSession.coachId,
                  coachee: session.getSession.teamMember.user.id,
                });
                submit();
              }}
            >
              {loading ? "Loading..." : "Yes, book now"}
            </Button>
          </div>
        </div>
      </section>

      {showThanksModal && (
        <Modal size="lg" onClose={() => {}}>
          <div className="flex flex-col items-center md:w-[80%] mx-auto gap-y-2 text-center">
            <img src="/images/My_account.svg" alt="" className="h-[120px] w-[120px]" />
            <h1 className="text-2xl md:text-4xl font-semibold">
              Your {pluralize("session", repeatAmount?.value || 1)}{" "}
              {pluralize("has", repeatAmount?.value || 1)} been created!
            </h1>
            <p>
              You can now find the {pluralize("session", repeatAmount?.value || 1)} in your google
              calendar, as well as in your future sessions.
            </p>
            <p>
              {session.getSession.teamMember.user.firstName} has been emailed with the details and
              the {pluralize("session", repeatAmount?.value || 1)} has been added to their calendar.
            </p>
            <p>
              Thank you for using this new feature! Any feedback is welcome as we are constantly
              trying to improve this service.
            </p>
            <Button
              primary
              large
              className="w-full md:w-auto"
              onClick={() => navigate("/sessions")}
            >
              Back to your sessions
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
