import React from "react";
import { Layout, PageHeader } from "antd";
import { useNavigate } from "react-router-dom";

import { HtmlMeta } from "$/components/HtmlMeta";
import { CompanySubscriptionSettings } from "$/components/Settings/CompanySubscription";
import { useRoleRedirect, useScrollTo } from "$/hooks";
import { UserRole } from "$/graphql/types.generated";
import { IPageBaseProps } from "$/interfaces";
import { ROUTE_PATHS } from "$/configs/routes";
import { BackIcon } from "$/icons";

interface IProps extends IPageBaseProps {}

export const CompanyBilling: React.FC<IProps> = () => {
  const navigate = useNavigate();
  useScrollTo();

  useRoleRedirect([UserRole.Admin, UserRole.Sponsor, UserRole.Individual, UserRole.Superadmin]);

  return (
    <Layout>
      <HtmlMeta title="Billing" />
      <Layout.Content className="site-layout-content">
        <PageHeader
          ghost
          className="site-page-header"
          title="Billing"
          onBack={() => navigate(ROUTE_PATHS.DASHBOARD)}
          backIcon={<BackIcon style={{ fontSize: "40px" }} />}
        />
        <CompanySubscriptionSettings />
      </Layout.Content>
    </Layout>
  );
};
