import { useState } from "react";
import { Button, Col, Form, message, Modal, Row } from "antd";

import { ManualInviteForm } from "./ManualInviteForm";
import { CsvImport } from "./CsvImport";

import {
  CreateTeamMemberFromInviteInput,
  useInviteTeamMembersMutation,
  UserRole,
} from "$/graphql/types.generated";

import { DATA_TEST_IDS } from "$/elements.config";

import { Mixpanel } from "$/tracking";

interface IProps {
  companyId: string;
  isInviteModalOpen: boolean;
  onToggleInviteModel: () => void;
  refetchTeamMembers?: () => Promise<any>;
  isEnterprise?: boolean;
}

interface RawUser {
  email?: string;
  firstName?: string;
  lastName?: string;
  accountType?: string;
  isManager?: boolean;
}

export const InviteTeamMemberModal = ({
  companyId,
  isInviteModalOpen,
  onToggleInviteModel,
  refetchTeamMembers,
  isEnterprise,
}: IProps) => {
  const [formType, setFormType] = useState("addEmails");
  const [manualInviteForm] = Form.useForm();

  const [csvData, setCsvData] = useState([]);
  const [csvError, setCsvError] = useState([]);
  const [csvInviteNumbers, setCsvInviteNumbers] = useState(0);

  const reset = () => {
    setCsvData([]);
    setCsvError([]);
    setCsvInviteNumbers(0);
  };

  const onClose = () => {
    reset();
    setFormType("addEmails");
    manualInviteForm.resetFields();
    onToggleInviteModel();
  };

  const inviteTeamMembers = useInviteTeamMembersMutation({
    onError: () => {
      message.error("There was a problem inviting Humans to your team. Please contact support.");

      return onClose();
    },
    onSuccess: (data, { input }) => {
      if (!data.inviteTeamMembers?.success || !data.inviteTeamMembers?.failed) {
        return onClose();
      }

      const { success, failed } = data.inviteTeamMembers;

      if (success.length > 0) {
        message.success(
          `${success.length} humans have been invited. They will receive a temporary password via email to setup their account.`,
        );
      }

      if (failed.length > 0) {
        message.error(`${failed.length} humans were not invited succesfully.`);
      }

      if (refetchTeamMembers) {
        refetchTeamMembers();
      }

      Mixpanel.track("Team Members Invited", {
        companyId: input.companyId,
        count: success,
      });

      if (input?.teamMembers?.length) {
        for (const teamMember of input.teamMembers) {
          Mixpanel.track("Team Member Invited", {
            companyId: input.companyId,
            ...teamMember,
          });
        }
      }

      return onClose();
    },
  });

  const formatInviteInput = (inviteData: RawUser[]): CreateTeamMemberFromInviteInput[] => {
    // @ts-ignore
    return inviteData.map((rawUser: RawUser) => ({
      email: rawUser.email?.toLowerCase(),
      firstName: rawUser.firstName,
      lastName: rawUser.lastName,
      role: rawUser.accountType ? rawUser.accountType?.toLowerCase() : UserRole.TeamMember,
      isManager: !!rawUser.isManager,
    }));
  };

  const onSendInvites = async () => {
    setCsvError([]);

    if (formType === "addEmails") {
      const values = await manualInviteForm.validateFields();

      if (values) {
        const teamMembers = formatInviteInput(csvData);

        setCsvInviteNumbers(teamMembers.length);

        inviteTeamMembers.mutate({
          input: {
            companyId,
            teamMembers: formatInviteInput(values.users),
          },
        });
      }
    }

    if (formType === "importEmails") {
      if (csvData) {
        const teamMembers = formatInviteInput(csvData);

        setCsvInviteNumbers(teamMembers.length);

        inviteTeamMembers.mutate({
          input: {
            companyId,
            teamMembers,
          },
        });
      }
    }
  };

  return (
    <Modal
      centered
      visible={isInviteModalOpen}
      width="1000px"
      onOk={onSendInvites}
      onCancel={() => onClose()}
      okText={inviteTeamMembers.isPending ? `Sending Invites...` : `Send Invites`}
      confirmLoading={inviteTeamMembers.isPending}
      closable={!inviteTeamMembers.isPending}
      destroyOnClose
    >
      <div data-cy={DATA_TEST_IDS.TEAMS.IMPORT.MODAL.BODY}>
        <Row gutter={16}>
          <Col span={18}>
            <h2>Invite Humans</h2>
            <p>Invited users will be sent an email inviting them to create their account.</p>
            {!isEnterprise && (
              <p>
                Inviting members over your subscription number will charge you immediately for the
                remainder of the month and will be added to your regular subscription going
                forwards. You can manage your subscription{" "}
                <a href="/company/billing" className="underline">
                  here
                </a>
                .
              </p>
            )}
          </Col>

          <Col span={6}>
            {formType === "addEmails" && (
              <Button
                shape="round"
                className="mr-md invite-team-members-btn"
                onClick={() => {
                  setFormType("importEmails");
                  reset();
                }}
                data-cy={DATA_TEST_IDS.TEAMS.IMPORT.MODAL.BUTTONS.CSV_IMPORT}
              >
                CSV Import
              </Button>
            )}
          </Col>
        </Row>

        <div
          style={{
            opacity: inviteTeamMembers.isPending ? "0.5" : "none",
            pointerEvents: inviteTeamMembers.isPending ? "none" : "inherit",
          }}
        >
          <div className="mb-lg">
            <div className="mt-s">
              {formType === "importEmails" ? (
                <CsvImport
                  setCsvData={setCsvData}
                  setCsvInviteNumbers={setCsvInviteNumbers}
                  csvData={csvData}
                  csvInviteNumbers={csvInviteNumbers}
                  csvError={csvError}
                  setCsvError={setCsvError}
                  reset={reset}
                />
              ) : (
                <ManualInviteForm manualInviteForm={manualInviteForm} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
