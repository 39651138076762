/* eslint-disable max-len */

if (!import.meta.env.MODE) {
  throw new Error("No environment variable set run with either development or production");
}

export const JWT_LOCALSTORAGE_KEY =
  import.meta.env.MODE === "development" ? "mh_id_token_dev" : "mh_id_token";
export const IDENTITY_LOCALSTORAGE_KEY =
  import.meta.env.MODE === "development" ? "mh_identity_id_dev" : "mh_identity_id";

export const WEB_ASSETS_URL = `https://d2re4krkd7ad9u.cloudfront.net/web`;
export const CHARGEBEE_ITEM_PRICE_ID_STARTER =
  import.meta.env.MODE === "development" ? "Team-Monthly-GBP-Monthly" : "Team-Monthly-GBP-Monthly";

export const CHARGEBEE_ITEM_PRICE_ID_MONTHLY_INDIVIDUAL = "Individual-monthly-GBP-Monthly";

export const app = {
  __DEV__: import.meta.env.DEV,
  //
  ENV: import.meta.env.MODE,
  PAGINATION_COUNT_TAKE_DEFAULT: 10000,
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL,
  GOOGLE_ANALYTICS_ID: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
  GOOGLE_OPTIMIZE_ID: import.meta.env.VITE_GOOGLE_OPTIMIZE_ID,
  INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
  JWT_LOCALSTORAGE_KEY,
  IDENTITY_LOCALSTORAGE_KEY,
  COACHING_URLS: {
    RELATIONSHIPS: import.meta.env.VITE_RELATIONSHIPS_URL,
    PURPOSE: import.meta.env.VITE_PURPOSE_URL,
    SELF: import.meta.env.VITE_SELF_URL,
    PARENTING: import.meta.env.VITE_PARENTING_URL,
    WORK: import.meta.env.VITE_WORK_URL,
    NEXT_AVAILABLE: import.meta.env.VITE_NEXT_AVAILABLE_URL,
  },
  NEXT_AVAILABLE_URL: import.meta.env.VITE_NEXT_AVAILABLE_URL,
  CLOUDFRONT: {
    USER_ASSETS: import.meta.env.VITE_USER_ASSETS_CLOUDFRONT,
  },
  API_KEY: import.meta.env.VITE_API_KEY,
  STORYBLOK_TOKEN: import.meta.env.VITE_STORYBLOK_TOKEN,
  STORYBLOK_DRAFT: import.meta.env.VITE_STORYBLOK_DRAFT,
};
