import React, { useEffect, useState } from "react";
import { HtmlMeta } from "$/components/HtmlMeta";
import { IPageBaseProps } from "$/interfaces";
import { WEB_ASSETS_URL } from "$/configs/app.config";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import { useScrollTo } from "$/hooks";
import CheckoutForm from "./CheckoutForm";
import CheckoutDetails from "./CheckoutDetails";
import { useSearchParams } from "react-router-dom";
import { useGetLastBookingHoldQuery } from "$/graphql/types.generated";
import { Countdown } from "../Enquiry/components/Countdown";
import { DateTime } from "luxon";
import { LoadingSpinner } from "$/components/LoadingSpinner";

countries.registerLocale(en);

interface IProps extends IPageBaseProps {}

export const CheckoutPage: React.FC<IProps> = () => {
  useScrollTo();
  const [searchParams] = useSearchParams();
  const [initialCount, setInitialCount] = useState(900);
  const [showTimer, setShowTimer] = useState(false);

  const { isLoading, data } = useGetLastBookingHoldQuery({
    where: {
      enquiry: {
        id: {
          equals: searchParams.get("eid"),
        },
      },
    },
  });
  const bookingHold = data?.listBookingHolds[0];

  useEffect(() => {
    const setInitialTime = () => {
      if (data) {
        setInitialCount(
          Number(
            DateTime.fromISO(bookingHold?.endsAt)
              .diff(DateTime.now())
              .toFormat("s"),
          ),
        );

        setShowTimer(true);
      }
    };
    setInitialTime();
  }, [bookingHold, data]);

  if (isLoading)
    return (
      <div className="flex flex-col w-full h-[70vh] items-center justify-center">
        <h1 className="text-4xl mb-10">Generating checkout page...</h1>
        <LoadingSpinner className="!h-12 !w-12" />
      </div>
    );

  return (
    <div className="w-screen">
      <div className="mx-auto">
        <HtmlMeta title="Enquiry" />
        <div className="w-full flex flex-col xl:flex-row h-full overflow-hidden">
          <div className="flex xl:w-[50%] bg-white  xl:bg-transparent flex-col items-center justify-center relative">
            <img src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`} alt="More Happi" height="100px" />
            {showTimer && (
              <Countdown
                initialCount={initialCount}
                coachName={bookingHold.coach.displayName}
                coachHandle={bookingHold.coach.handle}
                sessionTime={bookingHold.sessionStartsAt}
              />
            )}
            <div className="xl:w-[500px] pt-8">
              <CheckoutDetails bookingHold={bookingHold} />
            </div>
          </div>
          <div className="bg-white pt-8 xl:p-0 w-full xl:w-[50%] xl:min-w-[750px] flex flex-col justify-center overflow-y-hidden">
            <div className="w-full overflow-y-scroll p-2 xl:p-10">
              <div className="max-w-[750px] mx-auto">
                <CheckoutForm bookingHold={bookingHold} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
