import { Button } from "$/components/Button";
import { GetSessionByIdQuery } from "$/graphql/types.generated";
import { useIsMobile } from "$/hooks";
import { useUser } from "$/state/user";
import { Check, X } from "lucide-react";
import { useNavigate } from "react-router";

export const Intro = ({
  setCurrentStep,
  session,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  session: GetSessionByIdQuery;
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const user = useUser();

  return (
    <div className="w-full flex flex-col justify-center text-center max-w-[800px]">
      <section className="mx-2 md:mx-0 mb-8 bg-white rounded-xl">
        <div className="flex flex-col items-center p-6 w-full md:p-12 mx-auto text-center">
          <div className="flex self-center">
            <img src="/images/Book_a_session.svg" alt="" className="h-[150px] w-[150px]" />
          </div>
          <h2 className="text-center text-xl md:text-2xl font-semibold mb-4">
            As your session with {session.getSession?.teamMember?.user?.firstName} has just ended,
            you can keep the momentum going by booking an agreed time together.
          </h2>
          <p>
            From here, you can book your next session without the need for adding More Happi
            availability.
          </p>
          <p>
            You can also add in repeat bookings! <br />
            You can repeat sessions for a maximum of 3 months, with varying intervals, on the
            confirmation page.
          </p>
          <p>
            <b>Please note:</b> You can&apos;t currently create new programmes or schedule programme
            sessions past the session limit.
          </p>
          <div className="w-full flex flex-col gap-4 text-center text-lg">
            <div className="flex justify-center self-center gap-4 text-center pl-6">
              <Button error large leftIcon={<X color="white" />} onClick={() => navigate(-1)}>
                {isMobile ? "No" : "No, go back"}
              </Button>
              <Button
                className="bg-bottleGreen border-bottleGreen border-solid text-white"
                large
                rightIcon={<Check color="white" />}
                onClick={() => {
                  if (
                    session?.getSession?.userPackage &&
                    session.getSession.coachId === user.currentUser?.coach?.id
                  ) {
                    setCurrentStep("programme");
                  } else setCurrentStep("selectTime");
                }}
              >
                {isMobile ? "Yes" : "Yes, book session"}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
