import React, { useEffect } from "react";

import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";

import { useNavigate, useSearchParams } from "react-router-dom";

import {
  BookingHoldState,
  SessionState,
  useCancelSessionMutation,
  useCreateSessionMutation,
  useCreateUserPackageMutation,
  useGetLastBookingHoldQuery,
  useUpdateUserPackageMutation,
  useUpsertBookingHoldMutation,
} from "$/graphql/types.generated";
import { LoadingSpinner } from "$/components/LoadingSpinner";

import { useUser } from "$/state/user";
import { useCompanySubscription } from "$/state/company-subscription";
import { addWeeks, endOfDay, isFuture } from "date-fns";
import { packages } from "../Enquiry/components/Programme";
import { toast } from "$/components/Toaster";
import { DateTime } from "luxon";

interface IProps extends IPageBaseProps {}

export const AddPackagePage: React.FC<IProps> = () => {
  useScrollTo();
  const navigate = useNavigate();
  const user = useUser();
  const [searchParams] = useSearchParams();
  const createUserPackage = useCreateUserPackageMutation();
  const updateUserPackage = useUpdateUserPackageMutation();
  const cancel = useCancelSessionMutation();
  const createSession = useCreateSessionMutation();
  const upsertBookingHold = useUpsertBookingHoldMutation();

  const bookingHoldQuery = useGetLastBookingHoldQuery({
    where: {
      enquiry: {
        id: {
          equals: searchParams.get("eid"),
        },
      },
      state: { equals: BookingHoldState.Hold },
    },
  });

  const bookingHold = bookingHoldQuery?.data?.listBookingHolds[0];
  const enquiry = bookingHold?.enquiry;
  const sessionTime = DateTime.fromISO(bookingHold?.sessionStartsAt);
  const packageType = searchParams.get("programme");

  useEffect(() => {
    if (enquiry.enquiryChoices) {
      const createPackage = async () => {
        try {
          const packageDetails = packages.find(({ title }) => title === packageType);
          const activePackageId = user?.currentUser?.activePackage?.id;

          const createdPackage = await createUserPackage.mutateAsync({
            userId: user.currentUser.id,
            coachId: bookingHold.coach.id,
            label: packageDetails.label ?? packageDetails.title,
            plan: enquiry.enquiryChoices.find((choice) => choice.key === "path").value,
            sessionCount: packageDetails.sessionCount,
            endDate: packageDetails.expiryWeeks
              ? addWeeks(endOfDay(new Date()), packageDetails.expiryWeeks)
              : null,
          });

          if (activePackageId) {
            // end the existing package if there is one
            await updateUserPackage.mutateAsync({
              where: { id: activePackageId },
              data: { endDate: new Date() },
            });
            await Promise.all(
              user.currentUser.activePackage.sessions
                .filter(
                  (s) =>
                    s.state === (SessionState.Confirmed || SessionState.ConfirmedCoach) &&
                    isFuture(new Date(s.startsAt)),
                )
                .map(async (s) => {
                  return cancel.mutateAsync({
                    sessionId: s!.id,
                    reason: "User has cancelled their programme",
                  });
                }),
            );
          }

          // create first session from bookingHold
          const session = await createSession.mutateAsync({
            input: {
              teamMemberId: user?.currentUser?.teamMember?.id!,
              coachId: bookingHold?.coach?.id!,
              startsAt: sessionTime.toISO(),
              endsAt: sessionTime.plus({ hour: 1 }).toISO(),
              approaches: [],
              categories: [],
              specialisms: [],
              audienceExperiences: [],
              packages: [],
              packageId: createdPackage.createUserPackage.id,
            },
          });

          const completeBookingHold = await upsertBookingHold.mutateAsync({
            create: { enquiry: { connect: { id: enquiry.id } } },
            update: {
              state: BookingHoldState.Booked,
            },
            where: {
              id: bookingHold.id,
            },
          });

          await user.invalidate();
          setTimeout(() => {
            // slightly delay to allow the user query to refetch - stops a flash of the subscription dashboard if its the first package
            navigate("/");
          }, 2000);
        } catch (e) {
          toast.error(
            "Error adding programme to your account. Redirecting to dashboard",
            "Please try again or contact support",
          );
          setTimeout(() => {
            navigate("/");
          }, 5000);
        }
      };
      createPackage();
    }
  }, [bookingHoldQuery.isLoading]);

  return (
    <div className="my-10 w-screen">
      <div className="mx-auto">
        <HtmlMeta title="Add programme" />
        <div className="w-screen h-screen flex items-center justify-center">
          <div className="bg-white p-20 rounded-xl text-center">
            <h1>Adding programme to your account</h1>
            <p>Please wait for this page to redirect you</p>
            <LoadingSpinner className="!h-12 !w-12" />
          </div>
        </div>
      </div>
    </div>
  );
};
