import { GetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
export interface ProgrammeTitleSubtitle {
  title: string;
  subtitle: string;
}
export interface ProgrammeChoice extends ProgrammeTitleSubtitle {
  options: string[];
}

export interface ExplainImage extends ProgrammeTitleSubtitle {
  image: string;
}

export interface ProgrammeDetails extends ProgrammeTitleSubtitle {
  buildText: string;
  exploreText: string;
  progressText: string;
  genericText: string;
  allowed: {
    one: boolean;
    three: boolean;
    six: boolean;
  };
}

export interface ProgrammeTestimonial extends ProgrammeTitleSubtitle {
  testimonial: {
    header: string;
    body: string;
    avatar: string;
    job: string;
  };
}

export interface ProgrammeTextInput extends ProgrammeTitleSubtitle {
  placeholder: string;
}

export interface ProgrammeBreakdown extends ProgrammeTitleSubtitle {
  breakdown: Array<ProgrammeTitleSubtitle>;
}

export interface EnquiryState {
  displayName: string;
  docsUrl: string;
  intro: ProgrammeTitleSubtitle;
  why: ProgrammeChoice;
  whatHaveYouTried: ProgrammeChoice;
  coachExplain: ExplainImage;
  targetDate: ProgrammeChoice;
  testimonial: ProgrammeTestimonial;
  howWillYouKnow: ProgrammeTextInput;
  breakdown: ProgrammeBreakdown;
  programmes: ProgrammeDetails;
}

export const formatProgrammeData = (
  data: GetProgrammeQuizQuery["PageItem"]["content"],
): EnquiryState => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      if (!Array.isArray(value)) return [key, value];
      const { component, ...info } = value[0];

      if (component === "programmeMultiChoice" || component === "programmeSingleChoice") {
        const output = {
          title: info.title,
          subtitle: info.subtitle,
          options: info.options.map((opt) => opt.label),
        };
        return [key, output];
      } else if (component === "explainImage") {
        const output = {
          title: info.title,
          subtitle: info.subtitle,
          image: info.image.filename,
        };
        return [key, output];
      } else if (component === "programmeDetails") {
        const output = {
          title: info.title,
          subtitle: info.subtitle,
          buildText: info.buildText,
          exploreText: info.exploreText,
          progressText: info.progressText,
          genericText: info.genericText,
        };
        return [key, output];
      } else if (component === "programmeTestimonial") {
        const output = {
          title: info.title,
          subtitle: info.subtitle,
          testimonial: {
            header: info.header,
            body: info.body,
            avatar: info.avatar.filename,
            job: info.job,
          },
        };
        return [key, output];
      } else if (component === "programmeTextInput") {
        const output = {
          title: info.title,
          subtitle: info.subtitle,
          placeholder: info.placeholder,
        };
        return [key, output];
      } else if (component === "programmeBreakdown") {
        const output = {
          title: info.title,
          subtitle: info.subtitle,
          breakdown: info.steps.map((b) => ({
            title: b.title,
            subtitle: b.subtitle,
          })),
        };
        return [key, output];
      } else if (component === "TitleSubtitle") {
        const output = {
          title: info.title,
          subtitle: info.subtitle,
        };
        return [key, output];
      }
      return [key, value];
    }),
  ) as EnquiryState;
};
