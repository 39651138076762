import { Button } from "$/components/Button";
import { Send } from "..";

export const SingleChoice = ({
  send,
  title,
  subtitle,
  options,
  next,
}: {
  send: Send;
  title: string;
  subtitle: string;
  options: Array<{ label: string; state: string }>;
  next?: string;
}) => {
  return (
    <>
      <h1 className="text-center text-2xl md:text-4xl font-semibold mb-9">{title}</h1>
      <p className="text-center mb-14 text-lg">{subtitle}</p>
      <div className="w-full flex flex-col gap-4">
        {options.map((option) => (
          <Button
            key={option.label.replaceAll(" ", "-")}
            onClick={() => send({ next: option.state ?? next, value: option.data ?? option.label })}
            className=" w-full"
            primary
            large
          >
            {option.label}
          </Button>
        ))}
      </div>
    </>
  );
};
