import cn from "classnames";

export default function UserMessage({
  children,
  className,
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div className="flex w-full justify-end">
      <div
        className={cn(
          "mb-2 w-fit max-w-[80%] md:max-w-[70%] text-lg rounded-3xl bg-yellow p-4",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
}
