import { LoadingSpinner } from "$/components/LoadingSpinner";
import { useGetProgrammeQuizQuery } from "$/graphql/storyblok/types.generated";
import { getProgrammes, useUser } from "$/state/user";
import { difference, orderBy } from "lodash";
import { formatProgrammeData } from "../Enquiry/helpers/storyblok";
import { SessionState } from "$/graphql/types.generated";
import { differenceInDays, parse, parseISO } from "date-fns";
import { Button } from "$/components/Button";
import pluralize from "pluralize";

export default function EndOfProgramme() {
  const user = useUser();

  const mostRecentProgramme = orderBy(
    getProgrammes(user.currentUser.packages),
    ["expired", "hitSessionLimit", "createdAt"],
    ["asc", "asc", "desc"],
  )[0];

  const completed = mostRecentProgramme.hitSessionLimit;
  const expired = !!mostRecentProgramme.expired;
  const sessionsCompleted = mostRecentProgramme.validSessions.filter(
    (session) => session.state === SessionState.Confirmed || SessionState.ConfirmedCoach,
  ).length;

  const timeline =
    expired || !mostRecentProgramme.validSessions.length
      ? differenceInDays(
          parseISO(mostRecentProgramme.createdAt),
          parseISO(mostRecentProgramme.endDate),
        )
      : differenceInDays(
          parseISO(mostRecentProgramme.createdAt),
          parseISO(orderBy(mostRecentProgramme.validSessions, ["startsAt"], ["desc"])[0].startsAt),
        );

  const storyblokInfoQuery = useGetProgrammeQuizQuery({
    id: `programmes/${mostRecentProgramme.plan}`,
  });
  const storyblokInfo = storyblokInfoQuery?.data?.PageItem?.content
    ? formatProgrammeData(storyblokInfoQuery.data?.PageItem?.content)
    : null;

  if (storyblokInfoQuery.isLoading) return <LoadingSpinner />;

  return (
    <div className="max-w-[1280px] mx-auto">
      <div className="flex flex-col items-center md:w-[80%] mx-auto gap-4 text-center mt-8">
        <img src="/images/Sponsor_checklist.svg" alt="" className="h-[200px] w-[200px] mb-4" />
        <h1 className="mb-0">
          {completed && (
            <>
              You did it! You completed your{" "}
              <span className="underline">{storyblokInfo?.displayName}</span> programme.
            </>
          )}
          {expired && !completed && (
            <>
              Your <span className="underline">{storyblokInfo?.displayName}</span> programme has now
              ended.
            </>
          )}
        </h1>
        {mostRecentProgramme.sessionCount > 1 && sessionsCompleted > 0 && (
          <h2>
            You completed {sessionsCompleted} {pluralize("session", sessionsCompleted)} in{" "}
            {Math.abs(timeline)} days.
          </h2>
        )}
        <h2 className="my-0 mr-1">
          We&apos;re sorry but we no longer offer Programmes to Individuals.
        </h2>
        <p className="my-0 mr-1 text-lg">
          In order to continue using More Happi, you can set up a Team account.
        </p>
        <p className="my-0 mr-1 text-lg">
          It&apos;s better value for you, and you can create one with as little as two people.
        </p>
        <p className="text-center mb-2 text-lg">
          If you have any questions, please contact{" "}
          <a href="mailto:hey@morehappi.com">hey@morehappi.com</a>
        </p>
        <a href="https://morehappi.com" target="_blank" rel="noreferrer">
          <Button primary large>
            Find out more
          </Button>
        </a>
      </div>
    </div>
  );
}
