import { useUser } from "$/state/user";
import { Modal } from "$/components/Modal";
import { Button } from "$/components/Button";
import { Input } from "$/components/Input";
import { Select } from "$/components/Select";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TimezoneSelect } from "$/components/Select/Selectors/Timezone";
import { DateTime } from "luxon";

const schema = z.object({
  timezone: z.string().min(1, "Please select a timezone"),
});

export function TimezoneModal({ onClose }) {
  const { update } = useUser();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      timezone: DateTime.local().zoneName,
    },
  });

  return (
    <Modal size="lg" onClose={() => null} closeButton={false}>
      <div className="flex flex-col items-center md:w-[80%] mx-auto gap-y-2 text-center">
        <h1 className="text-2xl md:text-4xl font-semibold">What timezone are you currently in?</h1>
        <p>
          We need this to make sure we show you availability in the correct timezone. You can change
          it on your profile later.
        </p>
        <Controller
          name="timezone"
          control={control}
          render={({ field }) => <TimezoneSelect {...field} error={errors.timezone} />}
        />

        <div className="flex flex-col-reverse gap-4 md:flex-row w-full items-center justify-center">
          <Button
            primary
            large
            isSubmitting={isSubmitting}
            onClick={async (e) => {
              handleSubmit(async (values: z.infer<typeof schema>) => {
                await update({
                  timezone: values.timezone,
                });
                onClose();
              })(e);
            }}
            className="w-full mt-6"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
