import cn from "classnames";
import { useEffect, useState } from "react";
import { getError } from "../Input";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

interface CheckboxProps extends React.ComponentPropsWithoutRef<"input"> {
  checked?: boolean;
  controlled?: boolean;
  isDisabled?: boolean;
  label?: string | React.ReactNode;
  labelClass?: string;
  isReadOnly?: boolean;
  onChange?: (newState: boolean) => void;
  containerClass?: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

export default function Checkbox({
  checked,
  label,
  isReadOnly,
  isDisabled,
  onChange = () => null,
  className,
  containerClass = "justify-center",
  labelClass,
  controlled,
  error,
  ...props
}: CheckboxProps) {
  return (
    <div>
      <div className={cn("flex", containerClass)}>
        <label className={cn("inline-flex items-center", labelClass)}>
          <input
            type="checkbox"
            disabled={isDisabled}
            className={cn(
              "form-checkbox h-4 w-4 rounded text-primary focus:ring-0 focus:ring-transparent",
              {
                "cursor-pointer": !isReadOnly,
                "cursor-auto": isReadOnly,
                "!text-lightGrey": isDisabled,
              },
              className,
            )}
            {...props}
            checked={checked ?? false}
            onChange={onChange}
          />
          {label && (
            <span
              className={cn("ml-2 cursor-pointer text-gray-700", {
                "cursor-pointer": !isReadOnly,
                "cursor-text": isReadOnly,
              })}
            >
              {label}
            </span>
          )}
        </label>
      </div>
      {error && (
        <div
          className={cn("w-full text-center", {
            "text-red": error,
          })}
        >
          {getError(error, typeof label === "string" ? label : undefined)}
        </div>
      )}
    </div>
  );
}
