import { useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";

import {
  Button,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Space,
  Typography,
} from "antd";

import { HtmlMeta } from "$/components/HtmlMeta";
import { Message } from "$/components/Message";

import { WEB_ASSETS_URL } from "$/configs/app.config";

import { Mixpanel } from "$/tracking";

/**
 * Form Validation Schema
 */
type FormInputs = {
  email: string;
};

const defaultValues = {
  email: "",
};

export const VerifyAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = qs.parse(location.search);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [resendVerificationCode, setResendVerificationCode] =
    useState<boolean>(false);

  const emailFromQuery = decodeURIComponent(
    String(query.email || query.e),
  ).toLowerCase();

  const handleCodeVerify = async (code: string) => {
    setError(null);

    if (!emailFromQuery) {
      return setResendVerificationCode(true);
    }

    try {
      setLoading(true);

      const formattedEmail = emailFromQuery?.trim().toLowerCase();

      Mixpanel.alias(formattedEmail);

      const result = await Auth.confirmSignUp(
        String(formattedEmail),
        String(code),
      );

      if (result === "SUCCESS") {
        Mixpanel.track("Company Verified");

        return navigate(`/auth/login?action=verified`);
      }

      return setLoading(false);
    } catch (err: any) {
      setError(err.message);
      return setLoading(false);
    }
  };

  useEffect(() => {
    if (query.email && query.code && !loading && !error) {
      handleCodeVerify(String(query.code));
    }
  }, [query]);

  const onSubmit = async (values: FormInputs) => {
    setError(null);

    try {
      setLoading(true);

      const email = values.email.toLowerCase();

      await Auth.resendSignUp(email);

      setResendVerificationCode(false);

      return setLoading(false);
    } catch (err: any) {
      setError(err.message);
      return setLoading(false);
    }
  };

  const onSubmitFailed = (err: any) => setError(err.message);

  return (
    <Layout className="mh-layout-public">
      <HtmlMeta title="Verify Account" />

      <Layout.Content>
        <div className="site-login-content">
          <div className="login-wrap">
            <Link to="/auth/login">
              <Image
                className="login-img"
                preview={false}
                src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`}
                alt="logo"
              />
            </Link>

            <Space direction="vertical" size={32}>
              <Typography.Title level={1}>Verify your account</Typography.Title>

              <div className="m-lg">
                <Typography.Title level={5}>
                  One last step to setup your account
                </Typography.Title>
              </div>

              <div className="auth-form-wrap">
                {resendVerificationCode ? (
                  <Form
                    name="verify"
                    initialValues={defaultValues}
                    layout="vertical"
                    onFinish={(values) => onSubmit(values)}
                    onFinishFailed={onSubmitFailed}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please enter your email",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        size="large"
                        block
                        type="primary"
                        htmlType="submit"
                        disabled={loading}
                        loading={loading}
                      >
                        {loading ? "Loading... " : " Send Instructions"}
                      </Button>
                    </Form.Item>
                  </Form>
                ) : (
                  <div>
                    <p>
                      We have sent a verification link to{" "}
                      <strong>{emailFromQuery}</strong>. Please verify your
                      account to continue.
                    </p>
                  </div>
                )}

                {error && <Message type="error" items={[{ message: error }]} />}

                <Divider />

                <Typography.Link
                  onClick={() => {
                    setError(null);
                    setResendVerificationCode(true);
                  }}
                >
                  Send a verification link
                </Typography.Link>
              </div>
            </Space>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
