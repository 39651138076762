import { Button } from "$/components/Button";
import { TextArea } from "$/components/TextArea";
import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { Send } from "..";

export const TextInput = ({
  send,
  title,
  subtitle,
  next,
  value = "",
}: {
  send: Send;
  title: string;
  subtitle: string;
  next?: string;
  value?: string;
}) => {
  const [inputVal, setInputValue] = useState(
    CryptoJS.AES.decrypt(value, import.meta.env.VITE_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8) ??
      "",
  );

  useEffect(() => {
    if (value.length > 0) {
      setInputValue(
        CryptoJS.AES.decrypt(value, import.meta.env.VITE_ENCRYPTION_KEY).toString(
          CryptoJS.enc.Utf8,
        ),
      );
    }
  }, [value]);

  return (
    <>
      <h1 className="text-center text-2xl font-semibold mb-9">{title}</h1>
      <p className="text-center mb-14 text-lg">{subtitle}</p>
      <TextArea
        placeholder="I'll have achieved...&#10;I'll feel...&#10;&#10;Examples: “I will lead meetings confidently”, “I'll feel confident in my new role”, “I'll feel less stressed and more in control”"
        onChange={(event) => setInputValue(event.target.value)}
        value={inputVal}
        limit={300}
      />
      <Button
        primary
        large
        className="w-full mt-12"
        onClick={() =>
          send({
            next,
            value: CryptoJS.AES.encrypt(inputVal, import.meta.env.VITE_ENCRYPTION_KEY).toString(),
          })
        }
      >
        Continue
      </Button>
      <Button
        tertiary
        large
        className="w-full mt-4"
        onClick={() =>
          send({
            next,
            value: CryptoJS.AES.encrypt(
              "i dont know",
              import.meta.env.VITE_ENCRYPTION_KEY,
            ).toString(),
          })
        }
      >
        I don&apos;t know
      </Button>
    </>
  );
};
