import React from "react";
import { Layout, PageHeader } from "antd";

import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { ROUTE_PATHS } from "$/configs/routes";
import { BackIcon } from "$/icons";
import { Mixpanel } from "$/tracking";
import YouTube from "react-youtube";
import { useCompany } from "$/state/company";
import { useNavigate } from "react-router-dom";

interface IProps extends IPageBaseProps {}

export const tempMap: { [key: string]: string } = {
  "ESMT Berlin": "GtOBYUX9MnU",
};

export const useOnboardingVideo = () => {
  const company = useCompany();

  if (company.isLoading || company.isError || !company || !company.currentCompany) {
    return null;
  }

  const videoId = tempMap[company?.currentCompany?.name];

  if (!videoId) {
    return null;
  }

  return { videoId, company: company.currentCompany };
};

const OnboardingVideoPlayer = () => {
  const onboardingVideo = useOnboardingVideo();

  const eventProps = { companyName: onboardingVideo?.company.name };

  if (!onboardingVideo) {
    return null;
  }

  return (
    <YouTube
      videoId={onboardingVideo.videoId}
      className="onboarding_video"
      id="onboarding_video"
      title={onboardingVideo.company.name}
      onPlay={() => Mixpanel.track(`onboarding_video_play`, eventProps)}
      onEnd={() => Mixpanel.track(`onboarding_video_end`, eventProps)}
    />
  );
};

export const VideoOnboarding: React.FC<IProps> = () => {
  const navigate = useNavigate();

  useScrollTo();

  return (
    <Layout>
      <HtmlMeta title="Video Onboarding" />
      <Layout.Content className="site-layout-content">
        <PageHeader
          ghost
          className="site-page-header"
          title="Video Onboarding"
          onBack={() => navigate(ROUTE_PATHS.DASHBOARD)}
          backIcon={<BackIcon style={{ fontSize: "40px" }} />}
        />
        <OnboardingVideoPlayer />
      </Layout.Content>
    </Layout>
  );
};
