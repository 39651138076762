import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { DATA_TEST_IDS } from "$/elements.config";

const { Paragraph } = Typography;

export const ManualInviteForm = ({ manualInviteForm }: any) => {
  return (
    <Form name="inviteEmails" autoComplete="off" form={manualInviteForm}>
      <Form.List name="users">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => {
              return (
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 4 }}
                    lg={{ span: 5 }}
                    xl={{ span: 5 }}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "email"]}
                      label="Email"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      fieldKey={[field.key, "email"]}
                      className={index === 0 ? "firstRow" : "multipleRow"}
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 3, offset: 1 }}
                    lg={{ span: 3, offset: 1 }}
                    xl={{ span: 3, offset: 1 }}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "accountType"]}
                      label="Account Type"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      fieldKey={[field.key, "accountType"]}
                      className={index === 0 ? "firstRow" : "multipleRow"}
                      rules={[
                        {
                          required: false,
                          message: "Missing account type",
                        },
                      ]}
                    >
                      <Select defaultValue="team_member">
                        <Select.Option key="team_member" value="team_member">
                          Team Member
                        </Select.Option>
                        <Select.Option key="admin" value="admin">
                          Admin
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 3, offset: 1 }}
                    lg={{ span: 3, offset: 1 }}
                    xl={{ span: 3, offset: 1 }}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "firstName"]}
                      fieldKey={[field.key, "firstName"]}
                      className={index === 0 ? "firstRow" : "multipleRow"}
                      label="First Name"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        { required: true, message: "Missing first name" },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 3, offset: 1 }}
                    lg={{ span: 3, offset: 1 }}
                    xl={{ span: 3, offset: 1 }}
                  >
                    <Form.Item
                      {...field}
                      label="Last Name"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      name={[field.name, "lastName"]}
                      className={index === 0 ? "firstRow" : "multipleRow"}
                      fieldKey={[field.key, "lastName"]}
                      rules={[{ required: true, message: "Missing last name" }]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 2, offset: 2 }}
                    lg={{ span: 3, offset: 2 }}
                    xl={{ span: 3, offset: 2 }}
                  >
                    <Tooltip
                      placement="topLeft"
                      title="Click here if this person is managing a team and would benefit from accessing our Leadership content."
                    >
                      <Form.Item
                        {...field}
                        label="Is a Manager?"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name={[field.name, "isManager"]}
                        className={index === 0 ? "firstRow" : "multipleRow"}
                        fieldKey={[field.key, "isManager"]}
                        valuePropName="checked"
                      >
                        <Checkbox>
                          <span className="xs-only">Is a manager?</span>
                        </Checkbox>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 3, offset: 1 }}
                    lg={{ span: 1 }}
                    xl={{ span: 1 }}
                  >
                    <Button
                      className="xs-only"
                      onClick={() => {
                        remove(field.name);
                      }}
                    >
                      <Paragraph className="remove-text">Remove</Paragraph>
                      <MinusCircleOutlined />
                    </Button>
                    <MinusCircleOutlined
                      className={
                        index === 0
                          ? "sm-and-up first-row"
                          : "sm-and-up multipleRow"
                      }
                      onClick={() => remove(field.name)}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} className="xs-only">
                    <Divider plain />
                  </Col>
                </Row>
              );
            })}

            <Form.Item>
              <Button
                type="dashed"
                onClick={add}
                block
                icon={<PlusOutlined />}
                data-cy={DATA_TEST_IDS.TEAMS.IMPORT.MODAL.BUTTONS.ADD_USER}
              >
                Add user
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
