const Star = ({ filled, half, size, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 25 25"
    color="#ADB5BD"
    {...rest}
  >
    <defs>
      <linearGradient id={`half_grad_${filled}_${half}`}>
        {half ? (
          <>
            <stop offset={`${half * 100}%`} stopColor="#F5C900" />
            <stop offset={`${100 - half * 100}%`} stopColor="grey" stopOpacity="1" />
          </>
        ) : filled ? (
          <stop offset="100%" stopColor="#F5C900" />
        ) : (
          <stop offset="100%" stopColor="#ADB5BD" />
        )}
      </linearGradient>
    </defs>
    <path
      d="M12.7953 18.6681L6.42029 22.4181L7.92029 15.2931L2.67029 10.4181L9.79529 9.66811L12.7953 2.91811L15.7953 9.66811L22.9203 10.4181L17.6703 15.2931L19.1703 22.4181L12.7953 18.6681Z"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={`url(#half_grad_${filled}_${half})`}
    />
  </svg>
);

export const Stars = ({ stars, size = 24 }: { stars: number | string; size?: number }) => {
  const _stars = typeof stars !== "number" ? Number(stars) : stars;

  return (
    <div className="flex w-fit">
      {Array.from({ length: 5 }).map((_, i) => {
        const half = Math.floor(_stars) === i && _stars % 1;
        const filled = Math.floor(_stars) > i;
        return <Star key={`star-${i}`} size={size} filled={filled} half={half} />;
      })}
    </div>
  );
};
