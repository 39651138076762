import { useState } from "react";
import { message, Modal, Typography } from "antd";

import { TeamMember, useRestoreTeamMemberMutation } from "$/graphql/types.generated";

import { Mixpanel } from "$/tracking";

interface Props {
  isOpen: boolean;
  onClose: (modalVisible: boolean) => void;
  teamMember: TeamMember;
  refetch: () => void;
}

export const ConfirmTeamMemberRestoreModal = ({ isOpen, onClose, teamMember, refetch }: Props) => {
  const [loading, setLoading] = useState(false);

  const restoreTeamMember = useRestoreTeamMemberMutation({
    onSuccess: () => {
      message.success(
        `${teamMember?.user?.firstName} ${teamMember?.user?.lastName} has been restored`,
      );

      Mixpanel.track("Team Member Restored");

      setLoading(false);
      onClose(false);
      refetch();
    },
    onError: () => {
      message.error(
        `${teamMember?.user?.firstName} ${teamMember?.user?.lastName} could not been restored. Please try again or contact us`,
      );

      setLoading(false);
      onClose(false);
      refetch();
    },
  });

  const handleOk = async () => {
    setLoading(true);

    await restoreTeamMember.mutateAsync({
      teamMemberId: teamMember.id,
    });
  };

  const handleCancel = () => onClose(false);

  return (
    <Modal
      title={`Restore ${teamMember?.user?.firstName} ${teamMember?.user?.lastName}?`}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
      closable={loading}
    >
      <Typography.Paragraph>
        Are you sure you want to restore {teamMember?.user?.firstName} {teamMember?.user?.lastName}{" "}
        to your team. This will reactivate their account and result in increased billing.
      </Typography.Paragraph>
      <Typography.Paragraph italic>
        {teamMember?.user?.firstName} may need to reset their password to access their account.
      </Typography.Paragraph>
    </Modal>
  );
};
