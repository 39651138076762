// @ts-nocheck
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Button, Col, Divider, notification, Row, Select, Tag, Typography } from "antd";

import { useUser } from "$/state/user";
import { useCompany } from "$/state/company";
import { useCompanySubscription } from "$/state/company-subscription";
import { ErrorMessage } from "$/components/ErrorMessage";
import { InviteTeamMemberModal } from "$/components/Modals/InviteTeamMemberModal";
import { ConfirmRoleChangeModal } from "$/components/Modals/ConfirmRoleChangeModal";
import { ConfirmTeamMemberRemovalModal } from "$/components/Modals/ConfirmTeamMemberRemovalModal";
import { TeamListActions } from "./components/TeamListActions";
import { ROUTE_PATHS } from "$/configs/routes";
import {
  useListTeamMembersQuery,
  UserRole,
  useUpdateTeamMemberMutation,
  ActivationState,
  OrderByArg,
  ListTeamMembersQuery,
  TeamMember,
} from "$/graphql/types.generated";
import { ICompBaseProps } from "$/interfaces";
import { Mixpanel } from "$/tracking";
import { Pagination } from "../Pagination";
import { merge } from "lodash";
import { formatFilters } from "./utils/formatFilters";
import { Table } from "../Table";
import { createColumnHelper } from "@tanstack/react-table";
import { ConfirmTeamMemberRestoreModal } from "../Modals/ConfirmTeamMemberRestoreModal";
import { ConfirmPasswordResetModal } from "../Modals/ConfirmPasswordResetModal";

interface IProps extends ICompBaseProps {}

const statues = {
  [ActivationState.Activated]: {
    color: "green",
    label: "Joined",
  },
  [ActivationState.Disabled]: {
    color: "red",
    label: "Archived",
  },
  [ActivationState.Invited]: {
    color: "default",
    label: "Invited",
  },
  [ActivationState.Pending]: {
    color: "default",
    label: "Invited",
  },
};

export const TeamList: React.FC<IProps> = (props) => {
  const user = useUser();
  const company = useCompany();
  const companySubscription = useCompanySubscription();

  const updateTeamMember = useUpdateTeamMemberMutation({
    onSuccess: () => {
      return Mixpanel.track("Team Member Updated");
    },
  });

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  const listWhere = {
    companyId: {
      equals: company?.currentCompany?.id,
    },
    ...merge(
      {
        user: {
          id: { not: undefined },
        },
      },
      formatFilters(filters),
    ),
  };

  const { isLoading, isError, data, error, refetch } = useListTeamMembersQuery({
    where: { ...listWhere },
    skip: page * 10,
    take: 10,
    orderBy: [
      { user: { activationState: OrderByArg.Asc } },
      {
        createdAt: OrderByArg.Asc,
      },
    ],
    activeWhere: merge(
      {},
      { ...listWhere },
      { user: { activationState: { equals: ActivationState.Activated } } },
    ),
  });

  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [isRestoreModalOpen, setRestoreModalOpen] = useState<boolean>(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState<boolean>(false);

  const [confirmTeamMember, setConfirmTeamMember] = useState<TeamMember | null>(null);
  const [confirmRoleUpdatedValue, setConfirmRoleUpdatedValue] = useState<string>("");

  const onToggleInviteModel = () => {
    setIsInviteModalOpen(!isInviteModalOpen);
  };

  const onRemoveTeamMember = (teamMember: TeamMember) => {
    setConfirmTeamMember(teamMember);
    setRemoveModalOpen(true);
  };

  const onRestoreTeamMember = (teamMember: TeamMember) => {
    setConfirmTeamMember(teamMember);
    setRestoreModalOpen(true);
  };

  const onChangeSponsor = (teamMember: TeamMember) => {
    setConfirmRoleUpdatedValue(UserRole.Sponsor);
    setConfirmTeamMember(teamMember);
    setConfirmModalOpen(true);
  };

  const onResetPassword = (teamMember: TeamMember) => {
    setConfirmTeamMember(teamMember);
    setPasswordModalOpen(true);
  };

  const openManagerNotification = ({ name }: { name: string }) => {
    const args = {
      message: "Manager Status Changed",
      description: `You have changed the managerial status of ${name}. `,
      duration: 5,
      className: "notification",
    };

    notification.open(args);
  };

  const columnHelper = createColumnHelper<ListTeamMembersQuery["listTeamMembers"]>();

  const columns = [
    columnHelper.accessor((teamMember) => teamMember?.user?.firstName, {
      header: "First Name",
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
      filterType: "text",
      filterFn: "search",
    }),
    columnHelper.accessor((teamMember) => teamMember?.user?.lastName, {
      header: "Last Name",
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
      filterFn: "search",
    }),
    columnHelper.accessor((teamMember) => teamMember.user.email, {
      header: "Email",
      cell: ({ getValue }) => <span>{getValue()}</span>,
      enableSorting: false,
      filterFn: "search",
    }),
    columnHelper.accessor((teamMember) => teamMember.user.activationState, {
      header: "Status",
      cell: ({ getValue }) => {
        const status = getValue();
        const { color, label } = statues[status];
        return (
          <Tag color={color} className="accountTypeColumn">
            {label}
          </Tag>
        );
      },
      enableSorting: false,
      filterFn: "select",
      filterOptions: [
        { label: "Joined", value: ActivationState.Activated },
        { label: "Invited", value: ActivationState.Invited },
        { label: "Archived", value: ActivationState.Disabled },
      ],
    }),
    columnHelper.accessor((teamMember) => teamMember.user.role, {
      header: "Account type",
      cell: ({ getValue }) => {
        const role = getValue();
        return (
          <span className="accountTypeColumn">
            {role === UserRole.TeamMember ? "Team Member" : role}
          </span>
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor((teamMember) => teamMember, {
      header: "Change role",
      cell: ({ getValue }) => {
        const teamMember = getValue();
        const onChangeRole = (role: string) => {
          setConfirmRoleUpdatedValue(role);
          setConfirmTeamMember(teamMember);
          setConfirmModalOpen(true);
        };

        return (
          <Select
            defaultValue={teamMember.user.role}
            value={teamMember.user.role}
            style={{ width: 136 }}
            onChange={(role) => onChangeRole(role)}
            disabled={teamMember.archivedAt || teamMember?.user.role === "sponsor"}
          >
            <Select.Option key="team_member" value="team_member">
              Team Member
            </Select.Option>
            <Select.Option key="admin" value="admin">
              Admin
            </Select.Option>
            {teamMember?.user.role === "sponsor" && (
              <Select.Option key="sponsor" value="sponsor">
                Sponsor
              </Select.Option>
            )}
          </Select>
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor((teamMember) => teamMember, {
      header: "Manager",
      cell: ({ getValue }) => {
        const teamMember = getValue();
        return (
          <Select
            defaultValue={teamMember.isManager}
            value={teamMember.isManager}
            style={{ width: 120 }}
            onChange={(newValue) => {
              updateTeamMember.mutate(
                {
                  where: { id: teamMember.id },
                  data: { isManager: newValue },
                },
                {
                  onSuccess: () => {
                    Mixpanel.track("Team Member Manager Status Changed");

                    openManagerNotification({
                      name: `${teamMember.user.firstName} ${teamMember.user.lastName}`,
                    });

                    refetch();
                  },
                },
              );
            }}
            disabled={teamMember.archivedAt}
          >
            <Select.Option key={0} value={true}>
              Yes
            </Select.Option>
            <Select.Option key={1} value={false}>
              No
            </Select.Option>
          </Select>
        );
      },
      enableSorting: false,
      filterFn: "select",
      filterOptions: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    }),
    columnHelper.accessor((teamMember) => teamMember, {
      header: "Action",
      cell: ({ getValue }) => {
        const teamMember = getValue();
        return (
          <TeamListActions
            onRemoveTeamMember={onRemoveTeamMember}
            onRestoreTeamMember={onRestoreTeamMember}
            onChangeSponsor={onChangeSponsor}
            onResetPassword={onResetPassword}
            teamMember={teamMember}
            refetchTeamMembers={refetch}
          />
        );
      },
      enableSorting: false,
    }),
  ];

  const canInvite = !company?.currentCompany.isEnterprise && !companySubscription.state.isActive;

  return (
    <div style={{ height: "100%" }}>
      {canInvite && (
        <div>
          <Typography.Paragraph>
            To get going, first create your subscription, then you can send your invites. Give your
            team a heads up that they will hear from us and we will do the rest{" "}
            <Link to={ROUTE_PATHS.COMPANY.BILLING}>
              <Button type="primary">Create Subscription</Button>
            </Link>
          </Typography.Paragraph>

          <Divider />
        </div>
      )}
      <Row gutter={16} justify="space-between">
        <Col>
          <Typography.Title level={5}>{data?.activeCount} active humans</Typography.Title>
        </Col>
        {!user?.currentUser?.isTeamMember && (
          <Col>
            <Button
              type="default"
              className="invite-team-members-btn"
              onClick={() => onToggleInviteModel()}
              disabled={canInvite}
              // data-cy={DATA_TEST_IDS.TEAMS.IMPORT.BUTTON}
            >
              Invite Humans
            </Button>
          </Col>
        )}
      </Row>
      {isError && <ErrorMessage errors={error} />}
      <InviteTeamMemberModal
        onToggleInviteModel={onToggleInviteModel}
        isInviteModalOpen={isInviteModalOpen}
        refetchTeamMembers={refetch}
        companyId={company.currentCompany.id}
        isEnterprise={company.currentCompany.isEnterprise}
      />
      <ConfirmRoleChangeModal
        isOpen={isConfirmModalOpen}
        onClose={setConfirmModalOpen}
        teamMember={confirmTeamMember}
        updatedRole={confirmRoleUpdatedValue as UserRole}
        onSuccess={() => refetch()}
      />
      <ConfirmTeamMemberRemovalModal
        isOpen={isRemoveModalOpen}
        onClose={setRemoveModalOpen}
        teamMember={confirmTeamMember}
        refetch={refetch}
      />
      <ConfirmTeamMemberRestoreModal
        isOpen={isRestoreModalOpen}
        onClose={setRestoreModalOpen}
        teamMember={confirmTeamMember}
        refetch={refetch}
      />
      <ConfirmPasswordResetModal
        isOpen={isPasswordModalOpen}
        onClose={setPasswordModalOpen}
        teamMember={confirmTeamMember}
        refetch={refetch}
      />
      {!isLoading && (
        <>
          <Table
            data={data?.listTeamMembers}
            columns={columns}
            filters={filters}
            setFilters={setFilters}
          />

          <div className="mt-4">
            <Pagination
              pageCount={Math.ceil((data?.count ?? 1) / 10)}
              page={page}
              handlePrevious={() => setPage((prev) => prev - 1)}
              handleNext={() => setPage((prev) => prev + 1)}
              handleChange={(_page) => setPage(_page)}
            />
          </div>
        </>
      )}
    </div>
  );
};
