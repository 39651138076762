// @ts-nocheck
import cn from "classnames";
import {
  Table as TanstackTable,
  Column,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getSortedRowModel,
  flexRender,
  FilterFnOption,
} from "@tanstack/react-table";
import { Popover, PopoverContent, PopoverTrigger, usePopoverContext } from "../Popover";
import { FilterIcon, SearchIcon } from "lucide-react";
import { SearchFilter, SelectFilter } from "./Filters";

function Filter({ column }: { column: Column<any, unknown>; table: TanstackTable<any> }) {
  const columnFilterValue = column.getFilterValue();
  if (column.columnDef.filterFn === ("search" as FilterFnOption<"search">)) {
    return (
      <Popover key={`${columnFilterValue}`}>
        <PopoverTrigger>
          <div className="h-full flex items-center cursor-pointer">
            <SearchIcon size="0.75rem" stroke={columnFilterValue ? "orange" : "black"} />
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <SearchFilter
            name="first name"
            value={columnFilterValue}
            handleSearch={(val) => column.setFilterValue(val)}
            handleReset={() => column.setFilterValue()}
          />
        </PopoverContent>
      </Popover>
    );
  } else if (column.columnDef.filterFn === ("select" as FilterFnOption<"select">)) {
    return (
      <Popover key={columnFilterValue}>
        <PopoverTrigger>
          <div className="h-full flex items-center cursor-pointer">
            <FilterIcon size="0.75rem" stroke={columnFilterValue ? "orange" : "black"} />
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <SelectFilter
            options={column.columnDef.filterOptions.map((option) => {
              const curr = columnFilterValue ? columnFilterValue[option.value] : false;
              return {
                ...option,
                checked: curr,
              };
            })}
            handleSearch={(val) => column.setFilterValue(val)}
            handleReset={() => column.setFilterValue()}
          />
        </PopoverContent>
      </Popover>
    );
  }
  return null;
}

export function Table({ columns, data, filters, setFilters }) {
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      search: () => {},
      select: () => {},
    },
    state: {
      columnFilters: filters,
    },
    onColumnFiltersChange: setFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <div className="overflow-x-scroll scrollbar-hide">
      <table className="w-full min-w-[1100px] mt-2">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: cn(
                            "flex items-center justify-between gap-x-1 bg-[#FAFAFA] p-4 font-[500] whitespace-nowrap",
                            {
                              "cursor-pointer select-none": header.column.getCanSort(),
                            },
                          ),
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted() as string] ?? null}

                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className="bg-white">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="p-4">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
