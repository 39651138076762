import React, { useEffect, useState } from "react";
import { Button, Layout } from "antd";
import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { Mixpanel } from "$/tracking";
import YouTube from "react-youtube";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import { Play } from "lucide-react";
import { useUser } from "$/state/user";
import { ROUTE_PATHS } from "$/configs/routes";

interface IProps extends IPageBaseProps {}

const YOUTUBE_PLAYLIST_ITEMS_API = "https://www.googleapis.com/youtube/v3/playlistItems";

interface YoutubePlaylistVideo {
  etag: string;
  id: string;
  kind: string;
  snippet: {
    channelId: string;
    channelTitle: string;
    description: string;
    playlistId: string;
    position: number;
    publishedAt: string;
    resourceId: {
      kind: string;
      videoId: string;
    };
    thumbnails: {
      medium: {
        url: string;
        height: number;
        width: number;
      };
    };
    title: string;
    videoOwnerChannelId: string;
    videoOwnerChannelTitle: string;
  };
  status: { privacyStatus: "public" | "private" };
}

export const VideoOnboardingPlaylist: React.FC<IProps> = () => {
  const user = useUser();
  const [searchParams] = useSearchParams();
  const [videos, setVideos] = useState<Array<YoutubePlaylistVideo>>([]);
  const [currentVideo, setCurrentVideo] = useState<YoutubePlaylistVideo>();
  const [upcomingVideos, setUpcomingVideos] = useState<Array<YoutubePlaylistVideo>>([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [tracked, setTracked] = useState(false);

  useScrollTo();

  useEffect(() => {
    const getData = async () => {
      const sponsorPlaylist = import.meta.env.VITE_YOUTUBE_SPONSOR_PLAYLIST_ID;
      const memberPlaylist = import.meta.env.VITE_YOUTUBE_MEMBER_PLAYLIST_ID;
      const playlistId = user.currentUser?.isAdmin ? sponsorPlaylist : memberPlaylist;
      const res = (await axios(
        `${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet,status&maxResults=50&playlistId=${playlistId}&key=${
          import.meta.env.VITE_YOUTUBE_API_KEY
        }`,
      )) as { data: { items: Array<YoutubePlaylistVideo> } };

      const playlistVideos = res.data.items.filter(
        ({ status }) => status.privacyStatus === "public",
      );

      if (searchParams.get("video")) {
        const videoIndex = playlistVideos.findIndex(
          (video) => video.snippet.resourceId.videoId === searchParams.get("video"),
        );
        setCurrentVideoIndex(videoIndex ?? 0);
        setUpcomingVideos(playlistVideos.slice(videoIndex + 1, videoIndex + 4));
        setCurrentVideo(playlistVideos[videoIndex ?? 0]);
      } else {
        setUpcomingVideos(playlistVideos.slice(1, 4));
        setCurrentVideo(playlistVideos[0]);
      }

      setVideos(playlistVideos);
    };
    getData();
  }, []);

  return (
    <Layout>
      <HtmlMeta title="Video Onboarding" />

      <div className="mt-10 mb-20 w-full xl:max-w-[1260px] md:mx-auto overflow-x-hidden">
        <div className="w-full px-2">
          <div className="text-center mb-12 ">
            <h1 className="text-3xl font-semibold">Welcome to More Happi 101!</h1>
            <h2 className="text-lg md:text-xl !text-darkGrey xl:max-w-[750px] xl:mx-auto font-normal">
              In these short videos, our team will walk you through the basics of coaching, what it
              can help you with, and how to make the most out of More Happi.
            </h2>
          </div>
          <div className="flex flex-col-reverse md:flex-row gap-x-9">
            <div className="w-full md:w-[45%] bg-white rounded-3xl p-6 h-fit max-h-[75vh] overflow-y-scroll scrollbar-hide">
              <h2>More Happi 101 {user.currentUser?.isAdmin ? "for admins" : ""}</h2>
              <ol className="pl-4">
                {videos.map((video, index) => (
                  <a
                    href={`/101?video=${video.snippet.resourceId.videoId}`}
                    className="text-black list-none flex items-center gap-x-2 mb-4 hover:no-underline"
                  >
                    <div
                      className={cn(
                        "w-[22px] h-[22px] bg-beige rounded-full flex items-center justify-center text-sm text-midnight ",
                        {
                          "!bg-yellow": currentVideoIndex === index,
                        },
                      )}
                    >
                      {index + 1}
                    </div>
                    <li
                      className={cn("text-textGrey underline", {
                        "font-semibold !text-midnight !no-underline": currentVideoIndex === index,
                      })}
                    >
                      {video.snippet.title}
                    </li>
                  </a>
                ))}
              </ol>
            </div>
            <div className="w-full md:grow">
              <div>
                <div className="rounded-lg">
                  <YouTube
                    videoId={currentVideo?.snippet.resourceId.videoId}
                    className="onboarding_video rounded-xl"
                    id="onboarding_video"
                    onPlay={() => {
                      if (tracked) return;
                      Mixpanel.track(`onboarding_video_play`, {
                        videoId: currentVideo?.id,
                        videoTitle: currentVideo?.snippet.title,
                      });
                      setTracked(true);
                    }}
                  />
                </div>
              </div>
              {upcomingVideos.length > 0 && (
                <div className="mt-6">
                  <h3 className="text-2xl font-semibold mb-4">Up next</h3>
                  <div className="flex flex-col md:flex-row w-[80%] mx-auto md:w-full gap-4 mb-8 md:mb-0">
                    {upcomingVideos?.map((video, index) => (
                      <a
                        href={`/101?video=${video.snippet.resourceId.videoId}`}
                        className=" hover:no-underline hover:opacity-75 flex flex-col justify-between h-full"
                      >
                        <div className="relative">
                          <img
                            src={video.snippet.thumbnails.medium?.url}
                            alt=""
                            className="w-full rounded-2xl"
                          />
                          <div className="absolute w-full h-full top-0 left-0 ">
                            <div className="flex h-full w-full justify-center items-center">
                              <div className="w-16 h-10 bg-red rounded-lg flex items-center justify-center">
                                <Play size={24} fill="white" stroke="white" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <h3 className="text-base truncate mt-2">
                          {currentVideoIndex + index + 2}. {video.snippet.title}
                        </h3>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {user.currentUser?.isAdmin && (
            <div className="mt-24 w-full mx-auto flex flex-col items-center md:w-[500px] text-center">
              <img
                src="/images/Sponsor_checklist.svg"
                alt="Complete our sponsor checklist"
                className="h-[300px]"
              />
              <h2 className="text-4xl">Check out our FAQs</h2>
              <p className="text-xl">For any further questions, check out our FAQ articles!</p>
              <a href="https://intercom.help/more-happi/en/collections/2828356-faqs">
                <Button type="primary" size="large">
                  View More Happi FAQs
                </Button>
              </a>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};
