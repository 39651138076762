import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchData } from './client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The `AWSDate` scalar type provided by AWS AppSync, represents a valid
   * ***extended*** [ISO 8601
   * Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other
   * words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The
   * scalar can also accept "negative years" of the form `-YYYY` which correspond to
   * years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are
   * both valid dates.  This scalar type can also accept an optional [time zone
   * offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For
   * example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and
   * "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be
   * `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The
   * seconds field in the timezone offset will be considered valid even though it is
   * not part of the ISO 8601 standard.
   */
  AWSDate: { input: any; output: any; }
  /**
   * The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid
   * ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations)
   * string. In other words, this scalar type accepts datetime strings of the form
   * `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the
   * form `-YYYY` which correspond to years before `0000`. For example,
   * "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime
   * strings.  The field after the two digit seconds field is a nanoseconds field. It
   * can accept between 1 and 9 digits. So, for example,
   * "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and
   * "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The
   * seconds and nanoseconds fields are optional (the seconds field must be specified
   * if the nanoseconds field is to be used).  The [time zone
   * offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is
   * compulsory for this scalar. The time zone offset must either be `Z`
   * (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds
   * field in the timezone offset will be considered valid even though it is not part
   * of the ISO 8601 standard.
   */
  AWSDateTime: { input: any; output: any; }
  /**
   * The `AWSEmail` scalar type provided by AWS AppSync, represents an Email address
   * string that complies with [RFC 822](https://www.ietf.org/rfc/rfc822.txt). For
   * example, "**username@example.com**" is a valid Email address.
   */
  AWSEmail: { input: any; output: any; }
  /**
   * The `AWSJSON` scalar type provided by AWS AppSync, represents a JSON string that
   * complies with [RFC 8259](https://tools.ietf.org/html/rfc8259).  Maps like
   * "**{\\"upvotes\\": 10}**", lists like "**[1,2,3]**", and scalar values like
   * "**\\"AWSJSON example string\\"**", "**1**", and "**true**" are accepted as
   * valid JSON and will automatically be parsed and loaded in the resolver mapping
   * templates as Maps, Lists, or Scalar values rather than as the literal input
   * strings.  Invalid JSON strings like "**{a: 1}**", "**{'a': 1}**" and "**Unquoted
   * string**" will throw GraphQL validation errors.
   */
  AWSJSON: { input: any; output: any; }
  /**
   * The `AWSURL` scalar type provided by AWS AppSync, represents a valid URL string
   * (Ex: <https://www.amazon.com/>). The URL may use any scheme and may also be a
   * local URL (Ex: <http://localhost/>).  URLs without schemes like "**amazon.com**"
   * or "**www.amazon.com**" are considered invalid. URLs which contain double
   * slashes (two consecutive forward slashes) in their path are also considered invalid.
   */
  AWSURL: { input: any; output: any; }
};

export type AwsDateFilter = {
  equals?: InputMaybe<Scalars['AWSDate']['input']>;
  gt?: InputMaybe<Scalars['AWSDate']['input']>;
  gte?: InputMaybe<Scalars['AWSDate']['input']>;
  in?: InputMaybe<Array<Scalars['AWSDate']['input']>>;
  lt?: InputMaybe<Scalars['AWSDate']['input']>;
  lte?: InputMaybe<Scalars['AWSDate']['input']>;
  not?: InputMaybe<AwsDateFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSDate']['input']>>;
};

export type AwsDateListFilter = {
  equals?: InputMaybe<Array<Scalars['AWSDate']['input']>>;
  has?: InputMaybe<Scalars['AWSDate']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['AWSDate']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['AWSDate']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AwsDateNullableFilter = {
  equals?: InputMaybe<Scalars['AWSDate']['input']>;
  gt?: InputMaybe<Scalars['AWSDate']['input']>;
  gte?: InputMaybe<Scalars['AWSDate']['input']>;
  in?: InputMaybe<Array<Scalars['AWSDate']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['AWSDate']['input']>;
  lte?: InputMaybe<Scalars['AWSDate']['input']>;
  not?: InputMaybe<AwsDateFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSDate']['input']>>;
};

export type AwsDateTimeFilter = {
  equals?: InputMaybe<Scalars['AWSDateTime']['input']>;
  gt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  gte?: InputMaybe<Scalars['AWSDateTime']['input']>;
  in?: InputMaybe<Array<Scalars['AWSDateTime']['input']>>;
  lt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lte?: InputMaybe<Scalars['AWSDateTime']['input']>;
  not?: InputMaybe<AwsDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSDateTime']['input']>>;
};

export type AwsDateTimeListFilter = {
  equals?: InputMaybe<Array<Scalars['AWSDateTime']['input']>>;
  has?: InputMaybe<Scalars['AWSDateTime']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['AWSDateTime']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['AWSDateTime']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AwsDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['AWSDateTime']['input']>;
  gt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  gte?: InputMaybe<Scalars['AWSDateTime']['input']>;
  in?: InputMaybe<Array<Scalars['AWSDateTime']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lte?: InputMaybe<Scalars['AWSDateTime']['input']>;
  not?: InputMaybe<AwsDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSDateTime']['input']>>;
};

export type AwsEmailFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['AWSEmail']['input']>;
  in?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  not?: InputMaybe<AwsEmailFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type AwsEmailListFilter = {
  equals?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  has?: InputMaybe<Scalars['AWSEmail']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AwsEmailNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['AWSEmail']['input']>;
  in?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AwsEmailFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSEmail']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type AwsjsonFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['AWSJSON']['input']>;
  in?: InputMaybe<Array<Scalars['AWSJSON']['input']>>;
  not?: InputMaybe<AwsjsonFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSJSON']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type AwsjsonListFilter = {
  equals?: InputMaybe<Array<Scalars['AWSJSON']['input']>>;
  has?: InputMaybe<Scalars['AWSJSON']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['AWSJSON']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['AWSJSON']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AwsjsonNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['AWSJSON']['input']>;
  in?: InputMaybe<Array<Scalars['AWSJSON']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AwsjsonFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSJSON']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type AwsurlFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['AWSURL']['input']>;
  in?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  not?: InputMaybe<AwsurlFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type AwsurlListFilter = {
  equals?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  has?: InputMaybe<Scalars['AWSURL']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AwsurlNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['AWSURL']['input']>;
  in?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AwsurlFilter>;
  notIn?: InputMaybe<Array<Scalars['AWSURL']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum AccountType {
  Enterprise = 'enterprise',
  EnterpriseTrial = 'enterprise_trial',
  Free = 'free',
  Individual = 'individual',
  Pro = 'pro',
  Restricted = 'restricted',
  Starter = 'starter'
}

export type AccountTypeEnumFilter = {
  equals?: InputMaybe<AccountType>;
  in?: InputMaybe<Array<AccountType>>;
  not?: InputMaybe<AccountTypeEnumFilter>;
  notIn?: InputMaybe<Array<AccountType>>;
};

export type AccountTypeEnumListFilter = {
  equals?: InputMaybe<Array<AccountType>>;
  has?: InputMaybe<AccountType>;
  hasEvery?: InputMaybe<Array<AccountType>>;
  hasSome?: InputMaybe<Array<AccountType>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ActivationState {
  Activated = 'activated',
  Disabled = 'disabled',
  Invited = 'invited',
  Pending = 'pending'
}

export type ActivationStateEnumFilter = {
  equals?: InputMaybe<ActivationState>;
  in?: InputMaybe<Array<ActivationState>>;
  not?: InputMaybe<ActivationStateEnumFilter>;
  notIn?: InputMaybe<Array<ActivationState>>;
};

export type ActivationStateEnumListFilter = {
  equals?: InputMaybe<Array<ActivationState>>;
  has?: InputMaybe<ActivationState>;
  hasEvery?: InputMaybe<Array<ActivationState>>;
  hasSome?: InputMaybe<Array<ActivationState>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminCreateCompanyInput = {
  accountType: AccountType;
  chargebeeCustomerId?: InputMaybe<Scalars['String']['input']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sponsorEmail: Scalars['String']['input'];
  sponsorFirstName: Scalars['String']['input'];
  sponsorLastName: Scalars['String']['input'];
};

export type AllCoachAvailabilityInput = {
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type AllCoachAvailabilityResponse = {
  __typename?: 'AllCoachAvailabilityResponse';
  endsAt: Scalars['AWSDateTime']['output'];
  startsAt: Scalars['AWSDateTime']['output'];
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Int']['output'];
};

export type BookingHold = {
  __typename?: 'BookingHold';
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['AWSDateTime']['output']>;
  enquiry: Enquiry;
  enquiryId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  sessionStartsAt?: Maybe<Scalars['AWSDateTime']['output']>;
  startsAt?: Maybe<Scalars['AWSDateTime']['output']>;
  state: BookingHoldState;
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['String']['output']>;
};

export type BookingHoldCoachCreateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutBookingHoldInput>;
  create?: InputMaybe<CoachCreateWithoutBookingHoldInput>;
};

export type BookingHoldCoachUpdateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutBookingHoldInput>;
  create?: InputMaybe<CoachCreateWithoutBookingHoldInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachUpdateWithoutBookingHoldInput>;
  upsert?: InputMaybe<CoachUpsertWithoutBookingHoldInput>;
};

export type BookingHoldConnectOrCreateWithoutCoachInput = {
  create: BookingHoldCreateWithoutCoachInput;
  where: BookingHoldWhereUniqueInput;
};

export type BookingHoldConnectOrCreateWithoutEnquiryInput = {
  create: BookingHoldCreateWithoutEnquiryInput;
  where: BookingHoldWhereUniqueInput;
};

export type BookingHoldConnectOrCreateWithoutTeamMemberInput = {
  create: BookingHoldCreateWithoutTeamMemberInput;
  where: BookingHoldWhereUniqueInput;
};

export type BookingHoldCreateInput = {
  coach?: InputMaybe<BookingHoldCoachCreateNestedInput>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  enquiry?: InputMaybe<BookingHoldEnquiryCreateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
  teamMember?: InputMaybe<BookingHoldTeamMemberCreateNestedInput>;
};

export type BookingHoldCreateManyInput = {
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
};

export type BookingHoldCreateWithoutCoachInput = {
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  enquiry?: InputMaybe<BookingHoldEnquiryCreateNestedInput>;
  enquiryId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
  teamMember?: InputMaybe<BookingHoldTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingHoldCreateWithoutEnquiryInput = {
  coach?: InputMaybe<BookingHoldCoachCreateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
  teamMember?: InputMaybe<BookingHoldTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingHoldCreateWithoutTeamMemberInput = {
  coach?: InputMaybe<BookingHoldCoachCreateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  enquiry?: InputMaybe<BookingHoldEnquiryCreateNestedInput>;
  enquiryId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
};

export type BookingHoldEnquiryCreateNestedInput = {
  connect?: InputMaybe<EnquiryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EnquiryConnectOrCreateWithoutBookingHoldInput>;
  create?: InputMaybe<EnquiryCreateWithoutBookingHoldInput>;
};

export type BookingHoldEnquiryUpdateNestedInput = {
  connect?: InputMaybe<EnquiryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EnquiryConnectOrCreateWithoutBookingHoldInput>;
  create?: InputMaybe<EnquiryCreateWithoutBookingHoldInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<EnquiryUpdateWithoutBookingHoldInput>;
  upsert?: InputMaybe<EnquiryUpsertWithoutBookingHoldInput>;
};

export type BookingHoldExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<BookingHoldWhereInput>>;
  NOT?: InputMaybe<Array<BookingHoldWhereInput>>;
  OR?: InputMaybe<Array<BookingHoldWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  enquiry?: InputMaybe<EnquiryWhereInput>;
  enquiryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  state?: InputMaybe<BookingHoldStateEnumFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  teamMemberId?: InputMaybe<StringNullableFilter>;
};

export type BookingHoldFilter = {
  every?: InputMaybe<BookingHoldWhereInputWithoutNullables>;
  none?: InputMaybe<BookingHoldWhereInputWithoutNullables>;
  some?: InputMaybe<BookingHoldWhereInputWithoutNullables>;
};

export type BookingHoldOrderByInput = {
  coach?: InputMaybe<CoachOrderByInput>;
  coachId?: InputMaybe<OrderByArg>;
  endsAt?: InputMaybe<OrderByArg>;
  enquiry?: InputMaybe<EnquiryOrderByInput>;
  enquiryId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  sessionStartsAt?: InputMaybe<OrderByArg>;
  startsAt?: InputMaybe<OrderByArg>;
  state?: InputMaybe<OrderByArg>;
  teamMember?: InputMaybe<TeamMemberOrderByInput>;
  teamMemberId?: InputMaybe<OrderByArg>;
};

export type BookingHoldScalarWhereInput = {
  AND?: InputMaybe<Array<BookingHoldScalarWhereInput>>;
  NOT?: InputMaybe<Array<BookingHoldScalarWhereInput>>;
  OR?: InputMaybe<Array<BookingHoldScalarWhereInput>>;
  coachId?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  enquiryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  sessionStartsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  state?: InputMaybe<BookingHoldStateEnumFilter>;
  teamMemberId?: InputMaybe<StringNullableFilter>;
};

export enum BookingHoldState {
  Booked = 'BOOKED',
  Hold = 'HOLD',
  TimedOut = 'TIMED_OUT'
}

export type BookingHoldStateEnumFilter = {
  equals?: InputMaybe<BookingHoldState>;
  in?: InputMaybe<Array<BookingHoldState>>;
  not?: InputMaybe<BookingHoldStateEnumFilter>;
  notIn?: InputMaybe<Array<BookingHoldState>>;
};

export type BookingHoldStateEnumListFilter = {
  equals?: InputMaybe<Array<BookingHoldState>>;
  has?: InputMaybe<BookingHoldState>;
  hasEvery?: InputMaybe<Array<BookingHoldState>>;
  hasSome?: InputMaybe<Array<BookingHoldState>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookingHoldTeamMemberCreateNestedInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateWithoutBookingHoldInput>;
  create?: InputMaybe<TeamMemberCreateWithoutBookingHoldInput>;
};

export type BookingHoldTeamMemberUpdateNestedInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateWithoutBookingHoldInput>;
  create?: InputMaybe<TeamMemberCreateWithoutBookingHoldInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamMemberUpdateWithoutBookingHoldInput>;
  upsert?: InputMaybe<TeamMemberUpsertWithoutBookingHoldInput>;
};

export type BookingHoldUpdateInput = {
  coach?: InputMaybe<BookingHoldCoachUpdateNestedInput>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  enquiry?: InputMaybe<BookingHoldEnquiryUpdateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
  teamMember?: InputMaybe<BookingHoldTeamMemberUpdateNestedInput>;
};

export type BookingHoldUpdateManyInput = {
  data: BookingHoldUpdateInput;
  where: BookingHoldScalarWhereInput;
};

export type BookingHoldUpdateWithWhereUniqueWithoutCoachInput = {
  data: BookingHoldUpdateWithoutCoachInput;
  where: BookingHoldExtendedWhereUniqueInput;
};

export type BookingHoldUpdateWithWhereUniqueWithoutEnquiryInput = {
  data: BookingHoldUpdateWithoutEnquiryInput;
  where: BookingHoldExtendedWhereUniqueInput;
};

export type BookingHoldUpdateWithWhereUniqueWithoutTeamMemberInput = {
  data: BookingHoldUpdateWithoutTeamMemberInput;
  where: BookingHoldExtendedWhereUniqueInput;
};

export type BookingHoldUpdateWithoutCoachInput = {
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  enquiry?: InputMaybe<BookingHoldEnquiryUpdateNestedInput>;
  enquiryId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
  teamMember?: InputMaybe<BookingHoldTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingHoldUpdateWithoutEnquiryInput = {
  coach?: InputMaybe<BookingHoldCoachUpdateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
  teamMember?: InputMaybe<BookingHoldTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingHoldUpdateWithoutTeamMemberInput = {
  coach?: InputMaybe<BookingHoldCoachUpdateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  enquiry?: InputMaybe<BookingHoldEnquiryUpdateNestedInput>;
  enquiryId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionStartsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<BookingHoldState>;
};

export type BookingHoldUpsertWithWhereUniqueWithoutCoachInput = {
  create: BookingHoldCreateWithoutCoachInput;
  update: BookingHoldUpdateWithoutCoachInput;
  where: BookingHoldExtendedWhereUniqueInput;
};

export type BookingHoldUpsertWithWhereUniqueWithoutEnquiryInput = {
  create: BookingHoldCreateWithoutEnquiryInput;
  update: BookingHoldUpdateWithoutEnquiryInput;
  where: BookingHoldExtendedWhereUniqueInput;
};

export type BookingHoldUpsertWithWhereUniqueWithoutTeamMemberInput = {
  create: BookingHoldCreateWithoutTeamMemberInput;
  update: BookingHoldUpdateWithoutTeamMemberInput;
  where: BookingHoldExtendedWhereUniqueInput;
};

export type BookingHoldUpsertWithoutCoachInput = {
  create: BookingHoldCreateWithoutCoachInput;
  update: BookingHoldUpdateWithoutCoachInput;
};

export type BookingHoldUpsertWithoutEnquiryInput = {
  create: BookingHoldCreateWithoutEnquiryInput;
  update: BookingHoldUpdateWithoutEnquiryInput;
};

export type BookingHoldUpsertWithoutTeamMemberInput = {
  create: BookingHoldCreateWithoutTeamMemberInput;
  update: BookingHoldUpdateWithoutTeamMemberInput;
};

export type BookingHoldWhereInput = {
  AND?: InputMaybe<Array<BookingHoldWhereInput>>;
  NOT?: InputMaybe<Array<BookingHoldWhereInput>>;
  OR?: InputMaybe<Array<BookingHoldWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  enquiry?: InputMaybe<EnquiryWhereInput>;
  enquiryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  sessionStartsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  state?: InputMaybe<BookingHoldStateEnumFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  teamMemberId?: InputMaybe<StringNullableFilter>;
};

export type BookingHoldWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<BookingHoldWhereInput>>;
  NOT?: InputMaybe<Array<BookingHoldWhereInput>>;
  OR?: InputMaybe<Array<BookingHoldWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  enquiry?: InputMaybe<EnquiryWhereInput>;
  enquiryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  sessionStartsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  state?: InputMaybe<BookingHoldStateEnumFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  teamMemberId?: InputMaybe<StringNullableFilter>;
};

export type BookingHoldWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilter>;
};

export type BooleanListFilter = {
  equals?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  has?: InputMaybe<Scalars['Boolean']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type BooleanNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilter>;
};

export type CalendarSetting = {
  __typename?: 'CalendarSetting';
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastSynced?: Maybe<Scalars['AWSDateTime']['output']>;
  nextSyncToken?: Maybe<Scalars['String']['output']>;
  pushChannelId?: Maybe<Scalars['String']['output']>;
  pushExpiresAt?: Maybe<Scalars['AWSDateTime']['output']>;
  pushResourceId?: Maybe<Scalars['String']['output']>;
  pushResourceUri?: Maybe<Scalars['String']['output']>;
  source: CalendarSource;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type CalendarSettingCoachCreateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutCalendarSettingInput>;
  create?: InputMaybe<CoachCreateWithoutCalendarSettingInput>;
};

export type CalendarSettingCoachUpdateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutCalendarSettingInput>;
  create?: InputMaybe<CoachCreateWithoutCalendarSettingInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachUpdateWithoutCalendarSettingInput>;
  upsert?: InputMaybe<CoachUpsertWithoutCalendarSettingInput>;
};

export type CalendarSettingConnectOrCreateWithoutCoachInput = {
  create: CalendarSettingCreateWithoutCoachInput;
  where: CalendarSettingWhereUniqueInput;
};

export type CalendarSettingCreateInput = {
  coach?: InputMaybe<CalendarSettingCoachCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  externalId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastSynced?: InputMaybe<Scalars['AWSDateTime']['input']>;
  nextSyncToken?: InputMaybe<Scalars['String']['input']>;
  pushChannelId?: InputMaybe<Scalars['String']['input']>;
  pushExpiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  pushResourceId?: InputMaybe<Scalars['String']['input']>;
  pushResourceUri?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<CalendarSource>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CalendarSettingCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  externalId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastSynced?: InputMaybe<Scalars['AWSDateTime']['input']>;
  nextSyncToken?: InputMaybe<Scalars['String']['input']>;
  pushChannelId?: InputMaybe<Scalars['String']['input']>;
  pushExpiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  pushResourceId?: InputMaybe<Scalars['String']['input']>;
  pushResourceUri?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<CalendarSource>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CalendarSettingCreateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  externalId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastSynced?: InputMaybe<Scalars['AWSDateTime']['input']>;
  nextSyncToken?: InputMaybe<Scalars['String']['input']>;
  pushChannelId?: InputMaybe<Scalars['String']['input']>;
  pushExpiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  pushResourceId?: InputMaybe<Scalars['String']['input']>;
  pushResourceUri?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<CalendarSource>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CalendarSettingExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CalendarSettingWhereInput>>;
  NOT?: InputMaybe<Array<CalendarSettingWhereInput>>;
  OR?: InputMaybe<Array<CalendarSettingWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastSynced?: InputMaybe<AwsDateTimeNullableFilter>;
  nextSyncToken?: InputMaybe<StringNullableFilter>;
  pushChannelId?: InputMaybe<StringNullableFilter>;
  pushExpiresAt?: InputMaybe<AwsDateTimeNullableFilter>;
  pushResourceId?: InputMaybe<StringNullableFilter>;
  pushResourceUri?: InputMaybe<StringNullableFilter>;
  source?: InputMaybe<CalendarSourceEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CalendarSettingFilter = {
  every?: InputMaybe<CalendarSettingWhereInputWithoutNullables>;
  none?: InputMaybe<CalendarSettingWhereInputWithoutNullables>;
  some?: InputMaybe<CalendarSettingWhereInputWithoutNullables>;
};

export type CalendarSettingOrderByInput = {
  coach?: InputMaybe<CoachOrderByInput>;
  coachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  externalId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  lastSynced?: InputMaybe<OrderByArg>;
  nextSyncToken?: InputMaybe<OrderByArg>;
  pushChannelId?: InputMaybe<OrderByArg>;
  pushExpiresAt?: InputMaybe<OrderByArg>;
  pushResourceId?: InputMaybe<OrderByArg>;
  pushResourceUri?: InputMaybe<OrderByArg>;
  source?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CalendarSettingScalarWhereInput = {
  AND?: InputMaybe<Array<CalendarSettingScalarWhereInput>>;
  NOT?: InputMaybe<Array<CalendarSettingScalarWhereInput>>;
  OR?: InputMaybe<Array<CalendarSettingScalarWhereInput>>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastSynced?: InputMaybe<AwsDateTimeNullableFilter>;
  nextSyncToken?: InputMaybe<StringNullableFilter>;
  pushChannelId?: InputMaybe<StringNullableFilter>;
  pushExpiresAt?: InputMaybe<AwsDateTimeNullableFilter>;
  pushResourceId?: InputMaybe<StringNullableFilter>;
  pushResourceUri?: InputMaybe<StringNullableFilter>;
  source?: InputMaybe<CalendarSourceEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CalendarSettingUpdateInput = {
  coach?: InputMaybe<CalendarSettingCoachUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastSynced?: InputMaybe<Scalars['AWSDateTime']['input']>;
  nextSyncToken?: InputMaybe<Scalars['String']['input']>;
  pushChannelId?: InputMaybe<Scalars['String']['input']>;
  pushExpiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  pushResourceId?: InputMaybe<Scalars['String']['input']>;
  pushResourceUri?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<CalendarSource>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CalendarSettingUpdateManyInput = {
  data: CalendarSettingUpdateInput;
  where: CalendarSettingScalarWhereInput;
};

export type CalendarSettingUpdateWithWhereUniqueWithoutCoachInput = {
  data: CalendarSettingUpdateWithoutCoachInput;
  where: CalendarSettingExtendedWhereUniqueInput;
};

export type CalendarSettingUpdateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastSynced?: InputMaybe<Scalars['AWSDateTime']['input']>;
  nextSyncToken?: InputMaybe<Scalars['String']['input']>;
  pushChannelId?: InputMaybe<Scalars['String']['input']>;
  pushExpiresAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  pushResourceId?: InputMaybe<Scalars['String']['input']>;
  pushResourceUri?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<CalendarSource>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CalendarSettingUpsertWithWhereUniqueWithoutCoachInput = {
  create: CalendarSettingCreateWithoutCoachInput;
  update: CalendarSettingUpdateWithoutCoachInput;
  where: CalendarSettingExtendedWhereUniqueInput;
};

export type CalendarSettingUpsertWithoutCoachInput = {
  create: CalendarSettingCreateWithoutCoachInput;
  update: CalendarSettingUpdateWithoutCoachInput;
};

export type CalendarSettingWhereInput = {
  AND?: InputMaybe<Array<CalendarSettingWhereInput>>;
  NOT?: InputMaybe<Array<CalendarSettingWhereInput>>;
  OR?: InputMaybe<Array<CalendarSettingWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  lastSynced?: InputMaybe<AwsDateTimeNullableFilter>;
  nextSyncToken?: InputMaybe<StringNullableFilter>;
  pushChannelId?: InputMaybe<StringNullableFilter>;
  pushExpiresAt?: InputMaybe<AwsDateTimeNullableFilter>;
  pushResourceId?: InputMaybe<StringNullableFilter>;
  pushResourceUri?: InputMaybe<StringNullableFilter>;
  source?: InputMaybe<CalendarSourceEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CalendarSettingWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CalendarSettingWhereInput>>;
  NOT?: InputMaybe<Array<CalendarSettingWhereInput>>;
  OR?: InputMaybe<Array<CalendarSettingWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastSynced?: InputMaybe<AwsDateTimeNullableFilter>;
  nextSyncToken?: InputMaybe<StringNullableFilter>;
  pushChannelId?: InputMaybe<StringNullableFilter>;
  pushExpiresAt?: InputMaybe<AwsDateTimeNullableFilter>;
  pushResourceId?: InputMaybe<StringNullableFilter>;
  pushResourceUri?: InputMaybe<StringNullableFilter>;
  source?: InputMaybe<CalendarSourceEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CalendarSettingWhereUniqueInput = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum CalendarSource {
  Google = 'GOOGLE'
}

export type CalendarSourceEnumFilter = {
  equals?: InputMaybe<CalendarSource>;
  in?: InputMaybe<Array<CalendarSource>>;
  not?: InputMaybe<CalendarSourceEnumFilter>;
  notIn?: InputMaybe<Array<CalendarSource>>;
};

export type CalendarSourceEnumListFilter = {
  equals?: InputMaybe<Array<CalendarSource>>;
  has?: InputMaybe<CalendarSource>;
  hasEvery?: InputMaybe<Array<CalendarSource>>;
  hasSome?: InputMaybe<Array<CalendarSource>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CancelUserPackageResponse = {
  __typename?: 'CancelUserPackageResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckoutAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  postCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CheckoutInput = {
  address: CheckoutAddressInput;
  company: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  plan: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CheckoutResponse = {
  __typename?: 'CheckoutResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Coach = {
  __typename?: 'Coach';
  approaches: Array<CoachApproach>;
  audienceExperiences: Array<CoachAudienceExperience>;
  avatarKey?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  bookings: Array<BookingHold>;
  calendarId?: Maybe<Scalars['String']['output']>;
  calendarSettings?: Maybe<CalendarSetting>;
  categories: Array<CoachCategory>;
  coachingSince?: Maybe<Scalars['AWSDateTime']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  events: Array<CoachEvent>;
  excludeIndividuals: Scalars['Boolean']['output'];
  gender?: Maybe<Gender>;
  handle?: Maybe<Scalars['String']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  languages?: Maybe<Scalars['AWSJSON']['output']>;
  packages: Array<CoachPackage>;
  position?: Maybe<Scalars['Int']['output']>;
  pronouns?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  reviews: Array<CoachReview>;
  sessions: Array<Session>;
  specialisms: Array<CoachSpecialism>;
  trainingSchool?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  userPackages: Array<UserPackage>;
  ycmbUrl?: Maybe<Scalars['String']['output']>;
};

export type CoachApproach = {
  __typename?: 'CoachApproach';
  coaches: Array<Coach>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sessions: Array<SessionApproach>;
  updatedAt: Scalars['AWSDateTime']['output'];
  value: Scalars['String']['output'];
};

export type CoachApproachCoachesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachApproachInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachApproachInput>>;
};

export type CoachApproachCoachesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachApproachInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachApproachInput>>;
  delete?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachUpdateWithWhereUniqueWithoutCoachApproachInput>>;
  updateMany?: InputMaybe<Array<CoachUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachUpsertWithWhereUniqueWithoutCoachApproachInput>>;
};

export type CoachApproachConnectOrCreateWithoutCoachInput = {
  create: CoachApproachCreateWithoutCoachInput;
  where: CoachApproachWhereUniqueInput;
};

export type CoachApproachConnectOrCreateWithoutSessionApproachInput = {
  create: CoachApproachCreateWithoutSessionApproachInput;
  where: CoachApproachWhereUniqueInput;
};

export type CoachApproachCreateInput = {
  coaches?: InputMaybe<CoachApproachCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachApproachSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachApproachCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachApproachCreateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachApproachSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachApproachCreateWithoutSessionApproachInput = {
  coaches?: InputMaybe<CoachApproachCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachApproachExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CoachApproachWhereInput>>;
  NOT?: InputMaybe<Array<CoachApproachWhereInput>>;
  OR?: InputMaybe<Array<CoachApproachWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionApproachFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachApproachFilter = {
  every?: InputMaybe<CoachApproachWhereInputWithoutNullables>;
  none?: InputMaybe<CoachApproachWhereInputWithoutNullables>;
  some?: InputMaybe<CoachApproachWhereInputWithoutNullables>;
};

export type CoachApproachOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionApproachOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachApproachScalarWhereInput = {
  AND?: InputMaybe<Array<CoachApproachScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoachApproachScalarWhereInput>>;
  OR?: InputMaybe<Array<CoachApproachScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachApproachSessionsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionApproachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionApproachConnectOrCreateWithoutCoachApproachInput>>;
  create?: InputMaybe<Array<SessionApproachCreateWithoutCoachApproachInput>>;
};

export type CoachApproachSessionsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionApproachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionApproachConnectOrCreateWithoutCoachApproachInput>>;
  create?: InputMaybe<Array<SessionApproachCreateWithoutCoachApproachInput>>;
  delete?: InputMaybe<Array<SessionApproachExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionApproachScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionApproachExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionApproachWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionApproachUpdateWithWhereUniqueWithoutCoachApproachInput>>;
  updateMany?: InputMaybe<Array<SessionApproachUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionApproachUpsertWithWhereUniqueWithoutCoachApproachInput>>;
};

export type CoachApproachUpdateInput = {
  coaches?: InputMaybe<CoachApproachCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachApproachSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachApproachUpdateManyInput = {
  data: CoachApproachUpdateInput;
  where: CoachApproachScalarWhereInput;
};

export type CoachApproachUpdateWithWhereUniqueWithoutCoachInput = {
  data: CoachApproachUpdateWithoutCoachInput;
  where: CoachApproachExtendedWhereUniqueInput;
};

export type CoachApproachUpdateWithWhereUniqueWithoutSessionApproachInput = {
  data: CoachApproachUpdateWithoutSessionApproachInput;
  where: CoachApproachExtendedWhereUniqueInput;
};

export type CoachApproachUpdateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachApproachSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachApproachUpdateWithoutSessionApproachInput = {
  coaches?: InputMaybe<CoachApproachCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachApproachUpsertWithWhereUniqueWithoutCoachInput = {
  create: CoachApproachCreateWithoutCoachInput;
  update: CoachApproachUpdateWithoutCoachInput;
  where: CoachApproachExtendedWhereUniqueInput;
};

export type CoachApproachUpsertWithWhereUniqueWithoutSessionApproachInput = {
  create: CoachApproachCreateWithoutSessionApproachInput;
  update: CoachApproachUpdateWithoutSessionApproachInput;
  where: CoachApproachExtendedWhereUniqueInput;
};

export type CoachApproachUpsertWithoutCoachInput = {
  create: CoachApproachCreateWithoutCoachInput;
  update: CoachApproachUpdateWithoutCoachInput;
};

export type CoachApproachUpsertWithoutSessionApproachInput = {
  create: CoachApproachCreateWithoutSessionApproachInput;
  update: CoachApproachUpdateWithoutSessionApproachInput;
};

export type CoachApproachWhereInput = {
  AND?: InputMaybe<Array<CoachApproachWhereInput>>;
  NOT?: InputMaybe<Array<CoachApproachWhereInput>>;
  OR?: InputMaybe<Array<CoachApproachWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionApproachFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachApproachWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CoachApproachWhereInput>>;
  NOT?: InputMaybe<Array<CoachApproachWhereInput>>;
  OR?: InputMaybe<Array<CoachApproachWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionApproachFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachApproachWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CoachApproachesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachApproachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachApproachConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachApproachCreateWithoutCoachInput>>;
};

export type CoachApproachesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachApproachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachApproachConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachApproachCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<CoachApproachExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachApproachScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachApproachExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachApproachWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachApproachUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<CoachApproachUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachApproachUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachAudienceExperience = {
  __typename?: 'CoachAudienceExperience';
  coaches: Array<Coach>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sessions: Array<SessionAudienceExperience>;
  updatedAt: Scalars['AWSDateTime']['output'];
  value: Scalars['String']['output'];
};

export type CoachAudienceExperienceCoachesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachAudienceExperienceInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachAudienceExperienceInput>>;
};

export type CoachAudienceExperienceCoachesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachAudienceExperienceInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachAudienceExperienceInput>>;
  delete?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachUpdateWithWhereUniqueWithoutCoachAudienceExperienceInput>>;
  updateMany?: InputMaybe<Array<CoachUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachUpsertWithWhereUniqueWithoutCoachAudienceExperienceInput>>;
};

export type CoachAudienceExperienceConnectOrCreateWithoutCoachInput = {
  create: CoachAudienceExperienceCreateWithoutCoachInput;
  where: CoachAudienceExperienceWhereUniqueInput;
};

export type CoachAudienceExperienceConnectOrCreateWithoutSessionAudienceExperienceInput = {
  create: CoachAudienceExperienceCreateWithoutSessionAudienceExperienceInput;
  where: CoachAudienceExperienceWhereUniqueInput;
};

export type CoachAudienceExperienceCreateInput = {
  coaches?: InputMaybe<CoachAudienceExperienceCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachAudienceExperienceSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachAudienceExperienceCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachAudienceExperienceCreateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachAudienceExperienceSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachAudienceExperienceCreateWithoutSessionAudienceExperienceInput = {
  coaches?: InputMaybe<CoachAudienceExperienceCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachAudienceExperienceExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  NOT?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  OR?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionAudienceExperienceFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachAudienceExperienceFilter = {
  every?: InputMaybe<CoachAudienceExperienceWhereInputWithoutNullables>;
  none?: InputMaybe<CoachAudienceExperienceWhereInputWithoutNullables>;
  some?: InputMaybe<CoachAudienceExperienceWhereInputWithoutNullables>;
};

export type CoachAudienceExperienceOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionAudienceExperienceOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachAudienceExperienceScalarWhereInput = {
  AND?: InputMaybe<Array<CoachAudienceExperienceScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoachAudienceExperienceScalarWhereInput>>;
  OR?: InputMaybe<Array<CoachAudienceExperienceScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachAudienceExperienceSessionsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionAudienceExperienceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionAudienceExperienceConnectOrCreateWithoutCoachAudienceExperienceInput>>;
  create?: InputMaybe<Array<SessionAudienceExperienceCreateWithoutCoachAudienceExperienceInput>>;
};

export type CoachAudienceExperienceSessionsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionAudienceExperienceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionAudienceExperienceConnectOrCreateWithoutCoachAudienceExperienceInput>>;
  create?: InputMaybe<Array<SessionAudienceExperienceCreateWithoutCoachAudienceExperienceInput>>;
  delete?: InputMaybe<Array<SessionAudienceExperienceExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionAudienceExperienceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionAudienceExperienceExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionAudienceExperienceWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionAudienceExperienceUpdateWithWhereUniqueWithoutCoachAudienceExperienceInput>>;
  updateMany?: InputMaybe<Array<SessionAudienceExperienceUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionAudienceExperienceUpsertWithWhereUniqueWithoutCoachAudienceExperienceInput>>;
};

export type CoachAudienceExperienceUpdateInput = {
  coaches?: InputMaybe<CoachAudienceExperienceCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachAudienceExperienceSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachAudienceExperienceUpdateManyInput = {
  data: CoachAudienceExperienceUpdateInput;
  where: CoachAudienceExperienceScalarWhereInput;
};

export type CoachAudienceExperienceUpdateWithWhereUniqueWithoutCoachInput = {
  data: CoachAudienceExperienceUpdateWithoutCoachInput;
  where: CoachAudienceExperienceExtendedWhereUniqueInput;
};

export type CoachAudienceExperienceUpdateWithWhereUniqueWithoutSessionAudienceExperienceInput = {
  data: CoachAudienceExperienceUpdateWithoutSessionAudienceExperienceInput;
  where: CoachAudienceExperienceExtendedWhereUniqueInput;
};

export type CoachAudienceExperienceUpdateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachAudienceExperienceSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachAudienceExperienceUpdateWithoutSessionAudienceExperienceInput = {
  coaches?: InputMaybe<CoachAudienceExperienceCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachAudienceExperienceUpsertWithWhereUniqueWithoutCoachInput = {
  create: CoachAudienceExperienceCreateWithoutCoachInput;
  update: CoachAudienceExperienceUpdateWithoutCoachInput;
  where: CoachAudienceExperienceExtendedWhereUniqueInput;
};

export type CoachAudienceExperienceUpsertWithWhereUniqueWithoutSessionAudienceExperienceInput = {
  create: CoachAudienceExperienceCreateWithoutSessionAudienceExperienceInput;
  update: CoachAudienceExperienceUpdateWithoutSessionAudienceExperienceInput;
  where: CoachAudienceExperienceExtendedWhereUniqueInput;
};

export type CoachAudienceExperienceUpsertWithoutCoachInput = {
  create: CoachAudienceExperienceCreateWithoutCoachInput;
  update: CoachAudienceExperienceUpdateWithoutCoachInput;
};

export type CoachAudienceExperienceUpsertWithoutSessionAudienceExperienceInput = {
  create: CoachAudienceExperienceCreateWithoutSessionAudienceExperienceInput;
  update: CoachAudienceExperienceUpdateWithoutSessionAudienceExperienceInput;
};

export type CoachAudienceExperienceWhereInput = {
  AND?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  NOT?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  OR?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionAudienceExperienceFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachAudienceExperienceWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  NOT?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  OR?: InputMaybe<Array<CoachAudienceExperienceWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionAudienceExperienceFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachAudienceExperienceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CoachAudienceExperiencesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachAudienceExperienceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachAudienceExperienceConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachAudienceExperienceCreateWithoutCoachInput>>;
};

export type CoachAudienceExperiencesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachAudienceExperienceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachAudienceExperienceConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachAudienceExperienceCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<CoachAudienceExperienceExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachAudienceExperienceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachAudienceExperienceExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachAudienceExperienceWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachAudienceExperienceUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<CoachAudienceExperienceUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachAudienceExperienceUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachAvailabilityInput = {
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachBookingsCreateNestedInput = {
  connect?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BookingHoldConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<BookingHoldCreateWithoutCoachInput>>;
};

export type CoachBookingsUpdateNestedInput = {
  connect?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BookingHoldConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<BookingHoldCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<BookingHoldExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BookingHoldScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BookingHoldExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  update?: InputMaybe<Array<BookingHoldUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<BookingHoldUpdateManyInput>>;
  upsert?: InputMaybe<Array<BookingHoldUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachCalendarSettingsCreateNestedInput = {
  connect?: InputMaybe<CalendarSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CalendarSettingConnectOrCreateWithoutCoachInput>;
  create?: InputMaybe<CalendarSettingCreateWithoutCoachInput>;
};

export type CoachCalendarSettingsUpdateNestedInput = {
  connect?: InputMaybe<CalendarSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CalendarSettingConnectOrCreateWithoutCoachInput>;
  create?: InputMaybe<CalendarSettingCreateWithoutCoachInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CalendarSettingUpdateWithoutCoachInput>;
  upsert?: InputMaybe<CalendarSettingUpsertWithoutCoachInput>;
};

export type CoachCategoriesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachCategoryConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachCategoryCreateWithoutCoachInput>>;
};

export type CoachCategoriesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachCategoryConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachCategoryCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<CoachCategoryExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachCategoryExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachCategoryUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<CoachCategoryUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachCategoryUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachCategory = {
  __typename?: 'CoachCategory';
  coaches: Array<Coach>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sessions: Array<SessionCategory>;
  updatedAt: Scalars['AWSDateTime']['output'];
  value: Scalars['String']['output'];
};

export type CoachCategoryCoachesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachCategoryInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachCategoryInput>>;
};

export type CoachCategoryCoachesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachCategoryInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachCategoryInput>>;
  delete?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachUpdateWithWhereUniqueWithoutCoachCategoryInput>>;
  updateMany?: InputMaybe<Array<CoachUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachUpsertWithWhereUniqueWithoutCoachCategoryInput>>;
};

export type CoachCategoryConnectOrCreateWithoutCoachInput = {
  create: CoachCategoryCreateWithoutCoachInput;
  where: CoachCategoryWhereUniqueInput;
};

export type CoachCategoryConnectOrCreateWithoutSessionCategoryInput = {
  create: CoachCategoryCreateWithoutSessionCategoryInput;
  where: CoachCategoryWhereUniqueInput;
};

export type CoachCategoryCreateInput = {
  coaches?: InputMaybe<CoachCategoryCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachCategorySessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachCategoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachCategoryCreateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachCategorySessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachCategoryCreateWithoutSessionCategoryInput = {
  coaches?: InputMaybe<CoachCategoryCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachCategoryExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CoachCategoryWhereInput>>;
  NOT?: InputMaybe<Array<CoachCategoryWhereInput>>;
  OR?: InputMaybe<Array<CoachCategoryWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionCategoryFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachCategoryFilter = {
  every?: InputMaybe<CoachCategoryWhereInputWithoutNullables>;
  none?: InputMaybe<CoachCategoryWhereInputWithoutNullables>;
  some?: InputMaybe<CoachCategoryWhereInputWithoutNullables>;
};

export type CoachCategoryOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionCategoryOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<CoachCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoachCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<CoachCategoryScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachCategorySessionsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionCategoryConnectOrCreateWithoutCoachCategoryInput>>;
  create?: InputMaybe<Array<SessionCategoryCreateWithoutCoachCategoryInput>>;
};

export type CoachCategorySessionsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionCategoryConnectOrCreateWithoutCoachCategoryInput>>;
  create?: InputMaybe<Array<SessionCategoryCreateWithoutCoachCategoryInput>>;
  delete?: InputMaybe<Array<SessionCategoryExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionCategoryExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionCategoryUpdateWithWhereUniqueWithoutCoachCategoryInput>>;
  updateMany?: InputMaybe<Array<SessionCategoryUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionCategoryUpsertWithWhereUniqueWithoutCoachCategoryInput>>;
};

export type CoachCategoryUpdateInput = {
  coaches?: InputMaybe<CoachCategoryCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachCategorySessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCategoryUpdateManyInput = {
  data: CoachCategoryUpdateInput;
  where: CoachCategoryScalarWhereInput;
};

export type CoachCategoryUpdateWithWhereUniqueWithoutCoachInput = {
  data: CoachCategoryUpdateWithoutCoachInput;
  where: CoachCategoryExtendedWhereUniqueInput;
};

export type CoachCategoryUpdateWithWhereUniqueWithoutSessionCategoryInput = {
  data: CoachCategoryUpdateWithoutSessionCategoryInput;
  where: CoachCategoryExtendedWhereUniqueInput;
};

export type CoachCategoryUpdateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachCategorySessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCategoryUpdateWithoutSessionCategoryInput = {
  coaches?: InputMaybe<CoachCategoryCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCategoryUpsertWithWhereUniqueWithoutCoachInput = {
  create: CoachCategoryCreateWithoutCoachInput;
  update: CoachCategoryUpdateWithoutCoachInput;
  where: CoachCategoryExtendedWhereUniqueInput;
};

export type CoachCategoryUpsertWithWhereUniqueWithoutSessionCategoryInput = {
  create: CoachCategoryCreateWithoutSessionCategoryInput;
  update: CoachCategoryUpdateWithoutSessionCategoryInput;
  where: CoachCategoryExtendedWhereUniqueInput;
};

export type CoachCategoryUpsertWithoutCoachInput = {
  create: CoachCategoryCreateWithoutCoachInput;
  update: CoachCategoryUpdateWithoutCoachInput;
};

export type CoachCategoryUpsertWithoutSessionCategoryInput = {
  create: CoachCategoryCreateWithoutSessionCategoryInput;
  update: CoachCategoryUpdateWithoutSessionCategoryInput;
};

export type CoachCategoryWhereInput = {
  AND?: InputMaybe<Array<CoachCategoryWhereInput>>;
  NOT?: InputMaybe<Array<CoachCategoryWhereInput>>;
  OR?: InputMaybe<Array<CoachCategoryWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionCategoryFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachCategoryWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CoachCategoryWhereInput>>;
  NOT?: InputMaybe<Array<CoachCategoryWhereInput>>;
  OR?: InputMaybe<Array<CoachCategoryWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionCategoryFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CoachConnectOrCreateWithoutBookingHoldInput = {
  create: CoachCreateWithoutBookingHoldInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutCalendarSettingInput = {
  create: CoachCreateWithoutCalendarSettingInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutCoachApproachInput = {
  create: CoachCreateWithoutCoachApproachInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutCoachAudienceExperienceInput = {
  create: CoachCreateWithoutCoachAudienceExperienceInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutCoachCategoryInput = {
  create: CoachCreateWithoutCoachCategoryInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutCoachEventInput = {
  create: CoachCreateWithoutCoachEventInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutCoachPackageInput = {
  create: CoachCreateWithoutCoachPackageInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutCoachReviewInput = {
  create: CoachCreateWithoutCoachReviewInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutCoachSpecialismInput = {
  create: CoachCreateWithoutCoachSpecialismInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutSessionInput = {
  create: CoachCreateWithoutSessionInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutUserInput = {
  create: CoachCreateWithoutUserInput;
  where: CoachWhereUniqueInput;
};

export type CoachConnectOrCreateWithoutUserPackageInput = {
  create: CoachCreateWithoutUserPackageInput;
  where: CoachWhereUniqueInput;
};

export type CoachCreateInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateManyInput = {
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutBookingHoldInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutCalendarSettingInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutCoachApproachInput = {
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutCoachAudienceExperienceInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutCoachCategoryInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutCoachEventInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutCoachPackageInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutCoachReviewInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutCoachSpecialismInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutSessionInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutUserInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesCreateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachCreateWithoutUserPackageInput = {
  approaches?: InputMaybe<CoachApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesCreateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsCreateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsCreateNestedInput>;
  categories?: InputMaybe<CoachCategoriesCreateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsCreateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesCreateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsCreateNestedInput>;
  sessions?: InputMaybe<CoachSessionsCreateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsCreateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachEvent = {
  __typename?: 'CoachEvent';
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  creator?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['AWSDateTime']['output']>;
  eventHtmlLink?: Maybe<Scalars['String']['output']>;
  eventId: Scalars['String']['output'];
  eventType?: Maybe<Scalars['String']['output']>;
  iCalUID?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  organizer?: Maybe<Scalars['String']['output']>;
  recurrence?: Maybe<Scalars['String']['output']>;
  recurringEventId?: Maybe<Scalars['String']['output']>;
  session: Array<Session>;
  startsAt?: Maybe<Scalars['AWSDateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type CoachEventCoachCreateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutCoachEventInput>;
  create?: InputMaybe<CoachCreateWithoutCoachEventInput>;
};

export type CoachEventCoachUpdateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutCoachEventInput>;
  create?: InputMaybe<CoachCreateWithoutCoachEventInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachUpdateWithoutCoachEventInput>;
  upsert?: InputMaybe<CoachUpsertWithoutCoachEventInput>;
};

export type CoachEventConnectOrCreateWithoutCoachInput = {
  create: CoachEventCreateWithoutCoachInput;
  where: CoachEventWhereUniqueInput;
};

export type CoachEventConnectOrCreateWithoutSessionInput = {
  create: CoachEventCreateWithoutSessionInput;
  where: CoachEventWhereUniqueInput;
};

export type CoachEventCreateInput = {
  coach?: InputMaybe<CoachEventCoachCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eventHtmlLink?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['String']['input'];
  eventType?: InputMaybe<Scalars['String']['input']>;
  iCalUID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  recurringEventId?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<CoachEventSessionCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachEventCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eventHtmlLink?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['String']['input'];
  eventType?: InputMaybe<Scalars['String']['input']>;
  iCalUID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  recurringEventId?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachEventCreateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eventHtmlLink?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['String']['input'];
  eventType?: InputMaybe<Scalars['String']['input']>;
  iCalUID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  recurringEventId?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<CoachEventSessionCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachEventCreateWithoutSessionInput = {
  coach?: InputMaybe<CoachEventCoachCreateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eventHtmlLink?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['String']['input'];
  eventType?: InputMaybe<Scalars['String']['input']>;
  iCalUID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  recurringEventId?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachEventExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CoachEventWhereInput>>;
  NOT?: InputMaybe<Array<CoachEventWhereInput>>;
  OR?: InputMaybe<Array<CoachEventWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  creator?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  eventHtmlLink?: InputMaybe<StringNullableFilter>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<StringNullableFilter>;
  iCalUID?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<StringNullableFilter>;
  recurrence?: InputMaybe<StringNullableFilter>;
  recurringEventId?: InputMaybe<StringNullableFilter>;
  session?: InputMaybe<SessionFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  status?: InputMaybe<StringNullableFilter>;
  timeZone?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachEventFilter = {
  every?: InputMaybe<CoachEventWhereInputWithoutNullables>;
  none?: InputMaybe<CoachEventWhereInputWithoutNullables>;
  some?: InputMaybe<CoachEventWhereInputWithoutNullables>;
};

export type CoachEventOrderByInput = {
  coach?: InputMaybe<CoachOrderByInput>;
  coachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  creator?: InputMaybe<OrderByArg>;
  endsAt?: InputMaybe<OrderByArg>;
  eventHtmlLink?: InputMaybe<OrderByArg>;
  eventId?: InputMaybe<OrderByArg>;
  eventType?: InputMaybe<OrderByArg>;
  iCalUID?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  organizer?: InputMaybe<OrderByArg>;
  recurrence?: InputMaybe<OrderByArg>;
  recurringEventId?: InputMaybe<OrderByArg>;
  session?: InputMaybe<SessionOrderByInput>;
  startsAt?: InputMaybe<OrderByArg>;
  status?: InputMaybe<OrderByArg>;
  timeZone?: InputMaybe<OrderByArg>;
  title?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CoachEventScalarWhereInput = {
  AND?: InputMaybe<Array<CoachEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoachEventScalarWhereInput>>;
  OR?: InputMaybe<Array<CoachEventScalarWhereInput>>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  creator?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  eventHtmlLink?: InputMaybe<StringNullableFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventType?: InputMaybe<StringNullableFilter>;
  iCalUID?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  organizer?: InputMaybe<StringNullableFilter>;
  recurrence?: InputMaybe<StringNullableFilter>;
  recurringEventId?: InputMaybe<StringNullableFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  status?: InputMaybe<StringNullableFilter>;
  timeZone?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachEventSessionCreateNestedInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionConnectOrCreateWithoutCoachEventInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutCoachEventInput>>;
};

export type CoachEventSessionUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionConnectOrCreateWithoutCoachEventInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutCoachEventInput>>;
  delete?: InputMaybe<Array<SessionExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionUpdateWithWhereUniqueWithoutCoachEventInput>>;
  updateMany?: InputMaybe<Array<SessionUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionUpsertWithWhereUniqueWithoutCoachEventInput>>;
};

export type CoachEventUpdateInput = {
  coach?: InputMaybe<CoachEventCoachUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eventHtmlLink?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  iCalUID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  recurringEventId?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<CoachEventSessionUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachEventUpdateManyInput = {
  data: CoachEventUpdateInput;
  where: CoachEventScalarWhereInput;
};

export type CoachEventUpdateWithWhereUniqueWithoutCoachInput = {
  data: CoachEventUpdateWithoutCoachInput;
  where: CoachEventExtendedWhereUniqueInput;
};

export type CoachEventUpdateWithWhereUniqueWithoutSessionInput = {
  data: CoachEventUpdateWithoutSessionInput;
  where: CoachEventExtendedWhereUniqueInput;
};

export type CoachEventUpdateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eventHtmlLink?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  iCalUID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  recurringEventId?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<CoachEventSessionUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachEventUpdateWithoutSessionInput = {
  coach?: InputMaybe<CoachEventCoachUpdateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  creator?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  eventHtmlLink?: InputMaybe<Scalars['String']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  iCalUID?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizer?: InputMaybe<Scalars['String']['input']>;
  recurrence?: InputMaybe<Scalars['String']['input']>;
  recurringEventId?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachEventUpsertWithWhereUniqueWithoutCoachInput = {
  create: CoachEventCreateWithoutCoachInput;
  update: CoachEventUpdateWithoutCoachInput;
  where: CoachEventExtendedWhereUniqueInput;
};

export type CoachEventUpsertWithWhereUniqueWithoutSessionInput = {
  create: CoachEventCreateWithoutSessionInput;
  update: CoachEventUpdateWithoutSessionInput;
  where: CoachEventExtendedWhereUniqueInput;
};

export type CoachEventUpsertWithoutCoachInput = {
  create: CoachEventCreateWithoutCoachInput;
  update: CoachEventUpdateWithoutCoachInput;
};

export type CoachEventUpsertWithoutSessionInput = {
  create: CoachEventCreateWithoutSessionInput;
  update: CoachEventUpdateWithoutSessionInput;
};

export type CoachEventWhereInput = {
  AND?: InputMaybe<Array<CoachEventWhereInput>>;
  NOT?: InputMaybe<Array<CoachEventWhereInput>>;
  OR?: InputMaybe<Array<CoachEventWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  creator?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  eventHtmlLink?: InputMaybe<StringNullableFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventType?: InputMaybe<StringNullableFilter>;
  iCalUID?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  organizer?: InputMaybe<StringNullableFilter>;
  recurrence?: InputMaybe<StringNullableFilter>;
  recurringEventId?: InputMaybe<StringNullableFilter>;
  session?: InputMaybe<SessionFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  status?: InputMaybe<StringNullableFilter>;
  timeZone?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachEventWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CoachEventWhereInput>>;
  NOT?: InputMaybe<Array<CoachEventWhereInput>>;
  OR?: InputMaybe<Array<CoachEventWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  creator?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  eventHtmlLink?: InputMaybe<StringNullableFilter>;
  eventId?: InputMaybe<StringFilter>;
  eventType?: InputMaybe<StringNullableFilter>;
  iCalUID?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  organizer?: InputMaybe<StringNullableFilter>;
  recurrence?: InputMaybe<StringNullableFilter>;
  recurringEventId?: InputMaybe<StringNullableFilter>;
  session?: InputMaybe<SessionFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  status?: InputMaybe<StringNullableFilter>;
  timeZone?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CoachEventWhereUniqueInput = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CoachEventsCreateNestedInput = {
  connect?: InputMaybe<Array<CoachEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachEventConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachEventCreateWithoutCoachInput>>;
};

export type CoachEventsUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachEventConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachEventCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<CoachEventExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachEventExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachEventWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachEventUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<CoachEventUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachEventUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CoachWhereInput>>;
  NOT?: InputMaybe<Array<CoachWhereInput>>;
  OR?: InputMaybe<Array<CoachWhereInput>>;
  approaches?: InputMaybe<CoachApproachFilter>;
  audienceExperiences?: InputMaybe<CoachAudienceExperienceFilter>;
  avatarKey?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  bookings?: InputMaybe<BookingHoldFilter>;
  calendarId?: InputMaybe<StringNullableFilter>;
  calendarSettings?: InputMaybe<CalendarSettingWhereInput>;
  categories?: InputMaybe<CoachCategoryFilter>;
  coachingSince?: InputMaybe<AwsDateTimeNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  displayName?: InputMaybe<StringNullableFilter>;
  events?: InputMaybe<CoachEventFilter>;
  excludeIndividuals?: InputMaybe<BooleanFilter>;
  gender?: InputMaybe<GenderEnumFilter>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<AwsjsonNullableFilter>;
  packages?: InputMaybe<CoachPackageFilter>;
  position?: InputMaybe<IntNullableFilter>;
  pronouns?: InputMaybe<StringNullableFilter>;
  published?: InputMaybe<BooleanNullableFilter>;
  reviews?: InputMaybe<CoachReviewFilter>;
  sessions?: InputMaybe<SessionFilter>;
  specialisms?: InputMaybe<CoachSpecialismFilter>;
  trainingSchool?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<UserPackageFilter>;
  ycmbUrl?: InputMaybe<StringNullableFilter>;
};

export type CoachFilter = {
  every?: InputMaybe<CoachWhereInputWithoutNullables>;
  none?: InputMaybe<CoachWhereInputWithoutNullables>;
  some?: InputMaybe<CoachWhereInputWithoutNullables>;
};

export type CoachOperationInput = {
  position?: InputMaybe<IntOperation>;
};

export type CoachOrderByInput = {
  approaches?: InputMaybe<CoachApproachOrderByInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperienceOrderByInput>;
  avatarKey?: InputMaybe<OrderByArg>;
  bio?: InputMaybe<OrderByArg>;
  bookings?: InputMaybe<BookingHoldOrderByInput>;
  calendarId?: InputMaybe<OrderByArg>;
  calendarSettings?: InputMaybe<CalendarSettingOrderByInput>;
  categories?: InputMaybe<CoachCategoryOrderByInput>;
  coachingSince?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  displayName?: InputMaybe<OrderByArg>;
  events?: InputMaybe<CoachEventOrderByInput>;
  excludeIndividuals?: InputMaybe<OrderByArg>;
  gender?: InputMaybe<OrderByArg>;
  handle?: InputMaybe<OrderByArg>;
  headline?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  languages?: InputMaybe<OrderByArg>;
  packages?: InputMaybe<CoachPackageOrderByInput>;
  position?: InputMaybe<OrderByArg>;
  pronouns?: InputMaybe<OrderByArg>;
  published?: InputMaybe<OrderByArg>;
  reviews?: InputMaybe<CoachReviewOrderByInput>;
  sessions?: InputMaybe<SessionOrderByInput>;
  specialisms?: InputMaybe<CoachSpecialismOrderByInput>;
  trainingSchool?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
  userPackages?: InputMaybe<UserPackageOrderByInput>;
  ycmbUrl?: InputMaybe<OrderByArg>;
};

export type CoachPackage = {
  __typename?: 'CoachPackage';
  coaches: Array<Coach>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sessions: Array<SessionPackage>;
  updatedAt: Scalars['AWSDateTime']['output'];
  value: Scalars['String']['output'];
};

export type CoachPackageCoachesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachPackageInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachPackageInput>>;
};

export type CoachPackageCoachesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachPackageInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachPackageInput>>;
  delete?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachUpdateWithWhereUniqueWithoutCoachPackageInput>>;
  updateMany?: InputMaybe<Array<CoachUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachUpsertWithWhereUniqueWithoutCoachPackageInput>>;
};

export type CoachPackageConnectOrCreateWithoutCoachInput = {
  create: CoachPackageCreateWithoutCoachInput;
  where: CoachPackageWhereUniqueInput;
};

export type CoachPackageConnectOrCreateWithoutSessionPackageInput = {
  create: CoachPackageCreateWithoutSessionPackageInput;
  where: CoachPackageWhereUniqueInput;
};

export type CoachPackageCreateInput = {
  coaches?: InputMaybe<CoachPackageCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachPackageSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachPackageCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachPackageCreateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachPackageSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachPackageCreateWithoutSessionPackageInput = {
  coaches?: InputMaybe<CoachPackageCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachPackageExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CoachPackageWhereInput>>;
  NOT?: InputMaybe<Array<CoachPackageWhereInput>>;
  OR?: InputMaybe<Array<CoachPackageWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionPackageFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachPackageFilter = {
  every?: InputMaybe<CoachPackageWhereInputWithoutNullables>;
  none?: InputMaybe<CoachPackageWhereInputWithoutNullables>;
  some?: InputMaybe<CoachPackageWhereInputWithoutNullables>;
};

export type CoachPackageOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionPackageOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachPackageScalarWhereInput = {
  AND?: InputMaybe<Array<CoachPackageScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoachPackageScalarWhereInput>>;
  OR?: InputMaybe<Array<CoachPackageScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachPackageSessionsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionPackageConnectOrCreateWithoutCoachPackageInput>>;
  create?: InputMaybe<Array<SessionPackageCreateWithoutCoachPackageInput>>;
};

export type CoachPackageSessionsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionPackageConnectOrCreateWithoutCoachPackageInput>>;
  create?: InputMaybe<Array<SessionPackageCreateWithoutCoachPackageInput>>;
  delete?: InputMaybe<Array<SessionPackageExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionPackageExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionPackageUpdateWithWhereUniqueWithoutCoachPackageInput>>;
  updateMany?: InputMaybe<Array<SessionPackageUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionPackageUpsertWithWhereUniqueWithoutCoachPackageInput>>;
};

export type CoachPackageUpdateInput = {
  coaches?: InputMaybe<CoachPackageCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachPackageSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachPackageUpdateManyInput = {
  data: CoachPackageUpdateInput;
  where: CoachPackageScalarWhereInput;
};

export type CoachPackageUpdateWithWhereUniqueWithoutCoachInput = {
  data: CoachPackageUpdateWithoutCoachInput;
  where: CoachPackageExtendedWhereUniqueInput;
};

export type CoachPackageUpdateWithWhereUniqueWithoutSessionPackageInput = {
  data: CoachPackageUpdateWithoutSessionPackageInput;
  where: CoachPackageExtendedWhereUniqueInput;
};

export type CoachPackageUpdateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachPackageSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachPackageUpdateWithoutSessionPackageInput = {
  coaches?: InputMaybe<CoachPackageCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachPackageUpsertWithWhereUniqueWithoutCoachInput = {
  create: CoachPackageCreateWithoutCoachInput;
  update: CoachPackageUpdateWithoutCoachInput;
  where: CoachPackageExtendedWhereUniqueInput;
};

export type CoachPackageUpsertWithWhereUniqueWithoutSessionPackageInput = {
  create: CoachPackageCreateWithoutSessionPackageInput;
  update: CoachPackageUpdateWithoutSessionPackageInput;
  where: CoachPackageExtendedWhereUniqueInput;
};

export type CoachPackageUpsertWithoutCoachInput = {
  create: CoachPackageCreateWithoutCoachInput;
  update: CoachPackageUpdateWithoutCoachInput;
};

export type CoachPackageUpsertWithoutSessionPackageInput = {
  create: CoachPackageCreateWithoutSessionPackageInput;
  update: CoachPackageUpdateWithoutSessionPackageInput;
};

export type CoachPackageWhereInput = {
  AND?: InputMaybe<Array<CoachPackageWhereInput>>;
  NOT?: InputMaybe<Array<CoachPackageWhereInput>>;
  OR?: InputMaybe<Array<CoachPackageWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionPackageFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachPackageWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CoachPackageWhereInput>>;
  NOT?: InputMaybe<Array<CoachPackageWhereInput>>;
  OR?: InputMaybe<Array<CoachPackageWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionPackageFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachPackageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CoachPackagesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachPackageConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachPackageCreateWithoutCoachInput>>;
};

export type CoachPackagesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachPackageConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachPackageCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<CoachPackageExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachPackageExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachPackageUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<CoachPackageUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachPackageUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachReview = {
  __typename?: 'CoachReview';
  body?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type CoachReviewCoachCreateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutCoachReviewInput>;
  create?: InputMaybe<CoachCreateWithoutCoachReviewInput>;
};

export type CoachReviewCoachUpdateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutCoachReviewInput>;
  create?: InputMaybe<CoachCreateWithoutCoachReviewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachUpdateWithoutCoachReviewInput>;
  upsert?: InputMaybe<CoachUpsertWithoutCoachReviewInput>;
};

export type CoachReviewCompanyCreateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateWithoutCoachReviewInput>;
  create?: InputMaybe<CompanyCreateWithoutCoachReviewInput>;
};

export type CoachReviewCompanyUpdateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateWithoutCoachReviewInput>;
  create?: InputMaybe<CompanyCreateWithoutCoachReviewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CompanyUpdateWithoutCoachReviewInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutCoachReviewInput>;
};

export type CoachReviewConnectOrCreateWithoutCoachInput = {
  create: CoachReviewCreateWithoutCoachInput;
  where: CoachReviewWhereUniqueInput;
};

export type CoachReviewConnectOrCreateWithoutCompanyInput = {
  create: CoachReviewCreateWithoutCompanyInput;
  where: CoachReviewWhereUniqueInput;
};

export type CoachReviewConnectOrCreateWithoutUserInput = {
  create: CoachReviewCreateWithoutUserInput;
  where: CoachReviewWhereUniqueInput;
};

export type CoachReviewCreateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<CoachReviewCoachCreateNestedInput>;
  company?: InputMaybe<CoachReviewCompanyCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachReviewUserCreateNestedInput>;
};

export type CoachReviewCreateManyInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachReviewCreateWithoutCoachInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CoachReviewCompanyCreateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachReviewUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CoachReviewCreateWithoutCompanyInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<CoachReviewCoachCreateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachReviewUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CoachReviewCreateWithoutUserInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<CoachReviewCoachCreateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CoachReviewCompanyCreateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachReviewExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CoachReviewWhereInput>>;
  NOT?: InputMaybe<Array<CoachReviewWhereInput>>;
  OR?: InputMaybe<Array<CoachReviewWhereInput>>;
  body?: InputMaybe<StringNullableFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type CoachReviewFilter = {
  every?: InputMaybe<CoachReviewWhereInputWithoutNullables>;
  none?: InputMaybe<CoachReviewWhereInputWithoutNullables>;
  some?: InputMaybe<CoachReviewWhereInputWithoutNullables>;
};

export type CoachReviewOrderByInput = {
  body?: InputMaybe<OrderByArg>;
  coach?: InputMaybe<CoachOrderByInput>;
  coachId?: InputMaybe<OrderByArg>;
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  rating?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
};

export type CoachReviewScalarWhereInput = {
  AND?: InputMaybe<Array<CoachReviewScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoachReviewScalarWhereInput>>;
  OR?: InputMaybe<Array<CoachReviewScalarWhereInput>>;
  body?: InputMaybe<StringNullableFilter>;
  coachId?: InputMaybe<StringNullableFilter>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type CoachReviewUpdateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<CoachReviewCoachUpdateNestedInput>;
  company?: InputMaybe<CoachReviewCompanyUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachReviewUserUpdateNestedInput>;
};

export type CoachReviewUpdateManyInput = {
  data: CoachReviewUpdateInput;
  where: CoachReviewScalarWhereInput;
};

export type CoachReviewUpdateWithWhereUniqueWithoutCoachInput = {
  data: CoachReviewUpdateWithoutCoachInput;
  where: CoachReviewExtendedWhereUniqueInput;
};

export type CoachReviewUpdateWithWhereUniqueWithoutCompanyInput = {
  data: CoachReviewUpdateWithoutCompanyInput;
  where: CoachReviewExtendedWhereUniqueInput;
};

export type CoachReviewUpdateWithWhereUniqueWithoutUserInput = {
  data: CoachReviewUpdateWithoutUserInput;
  where: CoachReviewExtendedWhereUniqueInput;
};

export type CoachReviewUpdateWithoutCoachInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CoachReviewCompanyUpdateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachReviewUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CoachReviewUpdateWithoutCompanyInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<CoachReviewCoachUpdateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachReviewUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CoachReviewUpdateWithoutUserInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<CoachReviewCoachUpdateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CoachReviewCompanyUpdateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CoachReviewUpsertWithWhereUniqueWithoutCoachInput = {
  create: CoachReviewCreateWithoutCoachInput;
  update: CoachReviewUpdateWithoutCoachInput;
  where: CoachReviewExtendedWhereUniqueInput;
};

export type CoachReviewUpsertWithWhereUniqueWithoutCompanyInput = {
  create: CoachReviewCreateWithoutCompanyInput;
  update: CoachReviewUpdateWithoutCompanyInput;
  where: CoachReviewExtendedWhereUniqueInput;
};

export type CoachReviewUpsertWithWhereUniqueWithoutUserInput = {
  create: CoachReviewCreateWithoutUserInput;
  update: CoachReviewUpdateWithoutUserInput;
  where: CoachReviewExtendedWhereUniqueInput;
};

export type CoachReviewUpsertWithoutCoachInput = {
  create: CoachReviewCreateWithoutCoachInput;
  update: CoachReviewUpdateWithoutCoachInput;
};

export type CoachReviewUpsertWithoutCompanyInput = {
  create: CoachReviewCreateWithoutCompanyInput;
  update: CoachReviewUpdateWithoutCompanyInput;
};

export type CoachReviewUpsertWithoutUserInput = {
  create: CoachReviewCreateWithoutUserInput;
  update: CoachReviewUpdateWithoutUserInput;
};

export type CoachReviewUserCreateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutCoachReviewInput>;
  create?: InputMaybe<UserCreateWithoutCoachReviewInput>;
};

export type CoachReviewUserUpdateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutCoachReviewInput>;
  create?: InputMaybe<UserCreateWithoutCoachReviewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutCoachReviewInput>;
  upsert?: InputMaybe<UserUpsertWithoutCoachReviewInput>;
};

export type CoachReviewWhereInput = {
  AND?: InputMaybe<Array<CoachReviewWhereInput>>;
  NOT?: InputMaybe<Array<CoachReviewWhereInput>>;
  OR?: InputMaybe<Array<CoachReviewWhereInput>>;
  body?: InputMaybe<StringNullableFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  rating?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type CoachReviewWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CoachReviewWhereInput>>;
  NOT?: InputMaybe<Array<CoachReviewWhereInput>>;
  OR?: InputMaybe<Array<CoachReviewWhereInput>>;
  body?: InputMaybe<StringNullableFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type CoachReviewWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CoachReviewsCreateNestedInput = {
  connect?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachReviewConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachReviewCreateWithoutCoachInput>>;
};

export type CoachReviewsUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachReviewConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachReviewCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<CoachReviewExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachReviewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachReviewExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachReviewUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<CoachReviewUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachReviewUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachScalarWhereInput = {
  AND?: InputMaybe<Array<CoachScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoachScalarWhereInput>>;
  OR?: InputMaybe<Array<CoachScalarWhereInput>>;
  avatarKey?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  calendarId?: InputMaybe<StringNullableFilter>;
  coachingSince?: InputMaybe<AwsDateTimeNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  displayName?: InputMaybe<StringNullableFilter>;
  excludeIndividuals?: InputMaybe<BooleanFilter>;
  gender?: InputMaybe<GenderEnumFilter>;
  handle?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  languages?: InputMaybe<AwsjsonNullableFilter>;
  position?: InputMaybe<IntNullableFilter>;
  pronouns?: InputMaybe<StringNullableFilter>;
  published?: InputMaybe<BooleanNullableFilter>;
  trainingSchool?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  ycmbUrl?: InputMaybe<StringNullableFilter>;
};

export type CoachSessionsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutCoachInput>>;
};

export type CoachSessionsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<SessionExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<SessionUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachSpecialism = {
  __typename?: 'CoachSpecialism';
  coaches: Array<Coach>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  sessions: Array<SessionSpecialism>;
  updatedAt: Scalars['AWSDateTime']['output'];
  value: Scalars['String']['output'];
};

export type CoachSpecialismCoachesCreateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachSpecialismInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachSpecialismInput>>;
};

export type CoachSpecialismCoachesUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachConnectOrCreateWithoutCoachSpecialismInput>>;
  create?: InputMaybe<Array<CoachCreateWithoutCoachSpecialismInput>>;
  delete?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachUpdateWithWhereUniqueWithoutCoachSpecialismInput>>;
  updateMany?: InputMaybe<Array<CoachUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachUpsertWithWhereUniqueWithoutCoachSpecialismInput>>;
};

export type CoachSpecialismConnectOrCreateWithoutCoachInput = {
  create: CoachSpecialismCreateWithoutCoachInput;
  where: CoachSpecialismWhereUniqueInput;
};

export type CoachSpecialismConnectOrCreateWithoutSessionSpecialismInput = {
  create: CoachSpecialismCreateWithoutSessionSpecialismInput;
  where: CoachSpecialismWhereUniqueInput;
};

export type CoachSpecialismCreateInput = {
  coaches?: InputMaybe<CoachSpecialismCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachSpecialismSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachSpecialismCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachSpecialismCreateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  sessions?: InputMaybe<CoachSpecialismSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachSpecialismCreateWithoutSessionSpecialismInput = {
  coaches?: InputMaybe<CoachSpecialismCoachesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type CoachSpecialismExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  NOT?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  OR?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionSpecialismFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachSpecialismFilter = {
  every?: InputMaybe<CoachSpecialismWhereInputWithoutNullables>;
  none?: InputMaybe<CoachSpecialismWhereInputWithoutNullables>;
  some?: InputMaybe<CoachSpecialismWhereInputWithoutNullables>;
};

export type CoachSpecialismOrderByInput = {
  coaches?: InputMaybe<CoachOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionSpecialismOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type CoachSpecialismScalarWhereInput = {
  AND?: InputMaybe<Array<CoachSpecialismScalarWhereInput>>;
  NOT?: InputMaybe<Array<CoachSpecialismScalarWhereInput>>;
  OR?: InputMaybe<Array<CoachSpecialismScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachSpecialismSessionsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionSpecialismWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionSpecialismConnectOrCreateWithoutCoachSpecialismInput>>;
  create?: InputMaybe<Array<SessionSpecialismCreateWithoutCoachSpecialismInput>>;
};

export type CoachSpecialismSessionsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionSpecialismWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionSpecialismConnectOrCreateWithoutCoachSpecialismInput>>;
  create?: InputMaybe<Array<SessionSpecialismCreateWithoutCoachSpecialismInput>>;
  delete?: InputMaybe<Array<SessionSpecialismExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionSpecialismScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionSpecialismExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionSpecialismWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionSpecialismUpdateWithWhereUniqueWithoutCoachSpecialismInput>>;
  updateMany?: InputMaybe<Array<SessionSpecialismUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionSpecialismUpsertWithWhereUniqueWithoutCoachSpecialismInput>>;
};

export type CoachSpecialismUpdateInput = {
  coaches?: InputMaybe<CoachSpecialismCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachSpecialismSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachSpecialismUpdateManyInput = {
  data: CoachSpecialismUpdateInput;
  where: CoachSpecialismScalarWhereInput;
};

export type CoachSpecialismUpdateWithWhereUniqueWithoutCoachInput = {
  data: CoachSpecialismUpdateWithoutCoachInput;
  where: CoachSpecialismExtendedWhereUniqueInput;
};

export type CoachSpecialismUpdateWithWhereUniqueWithoutSessionSpecialismInput = {
  data: CoachSpecialismUpdateWithoutSessionSpecialismInput;
  where: CoachSpecialismExtendedWhereUniqueInput;
};

export type CoachSpecialismUpdateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<CoachSpecialismSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachSpecialismUpdateWithoutSessionSpecialismInput = {
  coaches?: InputMaybe<CoachSpecialismCoachesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CoachSpecialismUpsertWithWhereUniqueWithoutCoachInput = {
  create: CoachSpecialismCreateWithoutCoachInput;
  update: CoachSpecialismUpdateWithoutCoachInput;
  where: CoachSpecialismExtendedWhereUniqueInput;
};

export type CoachSpecialismUpsertWithWhereUniqueWithoutSessionSpecialismInput = {
  create: CoachSpecialismCreateWithoutSessionSpecialismInput;
  update: CoachSpecialismUpdateWithoutSessionSpecialismInput;
  where: CoachSpecialismExtendedWhereUniqueInput;
};

export type CoachSpecialismUpsertWithoutCoachInput = {
  create: CoachSpecialismCreateWithoutCoachInput;
  update: CoachSpecialismUpdateWithoutCoachInput;
};

export type CoachSpecialismUpsertWithoutSessionSpecialismInput = {
  create: CoachSpecialismCreateWithoutSessionSpecialismInput;
  update: CoachSpecialismUpdateWithoutSessionSpecialismInput;
};

export type CoachSpecialismWhereInput = {
  AND?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  NOT?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  OR?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionSpecialismFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachSpecialismWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  NOT?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  OR?: InputMaybe<Array<CoachSpecialismWhereInput>>;
  coaches?: InputMaybe<CoachFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionSpecialismFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type CoachSpecialismWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CoachSpecialismsCreateNestedInput = {
  connect?: InputMaybe<Array<CoachSpecialismWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachSpecialismConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachSpecialismCreateWithoutCoachInput>>;
};

export type CoachSpecialismsUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachSpecialismWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachSpecialismConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<CoachSpecialismCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<CoachSpecialismExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachSpecialismScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachSpecialismExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachSpecialismWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachSpecialismUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<CoachSpecialismUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachSpecialismUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachUpdateInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateManyInput = {
  data: CoachUpdateInput;
  where: CoachScalarWhereInput;
};

export type CoachUpdateWithWhereUniqueWithoutBookingHoldInput = {
  data: CoachUpdateWithoutBookingHoldInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutCalendarSettingInput = {
  data: CoachUpdateWithoutCalendarSettingInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutCoachApproachInput = {
  data: CoachUpdateWithoutCoachApproachInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutCoachAudienceExperienceInput = {
  data: CoachUpdateWithoutCoachAudienceExperienceInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutCoachCategoryInput = {
  data: CoachUpdateWithoutCoachCategoryInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutCoachEventInput = {
  data: CoachUpdateWithoutCoachEventInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutCoachPackageInput = {
  data: CoachUpdateWithoutCoachPackageInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutCoachReviewInput = {
  data: CoachUpdateWithoutCoachReviewInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutCoachSpecialismInput = {
  data: CoachUpdateWithoutCoachSpecialismInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutSessionInput = {
  data: CoachUpdateWithoutSessionInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutUserInput = {
  data: CoachUpdateWithoutUserInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithWhereUniqueWithoutUserPackageInput = {
  data: CoachUpdateWithoutUserPackageInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpdateWithoutBookingHoldInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutCalendarSettingInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutCoachApproachInput = {
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutCoachAudienceExperienceInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutCoachCategoryInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutCoachEventInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutCoachPackageInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutCoachReviewInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutCoachSpecialismInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutSessionInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutUserInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackages?: InputMaybe<CoachUserPackagesUpdateNestedInput>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpdateWithoutUserPackageInput = {
  approaches?: InputMaybe<CoachApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<CoachAudienceExperiencesUpdateNestedInput>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bookings?: InputMaybe<CoachBookingsUpdateNestedInput>;
  calendarId?: InputMaybe<Scalars['String']['input']>;
  calendarSettings?: InputMaybe<CoachCalendarSettingsUpdateNestedInput>;
  categories?: InputMaybe<CoachCategoriesUpdateNestedInput>;
  coachingSince?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  events?: InputMaybe<CoachEventsUpdateNestedInput>;
  excludeIndividuals?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Gender>;
  handle?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Scalars['AWSJSON']['input']>;
  packages?: InputMaybe<CoachPackagesUpdateNestedInput>;
  position?: InputMaybe<Scalars['Int']['input']>;
  pronouns?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviews?: InputMaybe<CoachReviewsUpdateNestedInput>;
  sessions?: InputMaybe<CoachSessionsUpdateNestedInput>;
  specialisms?: InputMaybe<CoachSpecialismsUpdateNestedInput>;
  trainingSchool?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<CoachUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
  ycmbUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CoachUpsertWithWhereUniqueWithoutBookingHoldInput = {
  create: CoachCreateWithoutBookingHoldInput;
  update: CoachUpdateWithoutBookingHoldInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutCalendarSettingInput = {
  create: CoachCreateWithoutCalendarSettingInput;
  update: CoachUpdateWithoutCalendarSettingInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutCoachApproachInput = {
  create: CoachCreateWithoutCoachApproachInput;
  update: CoachUpdateWithoutCoachApproachInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutCoachAudienceExperienceInput = {
  create: CoachCreateWithoutCoachAudienceExperienceInput;
  update: CoachUpdateWithoutCoachAudienceExperienceInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutCoachCategoryInput = {
  create: CoachCreateWithoutCoachCategoryInput;
  update: CoachUpdateWithoutCoachCategoryInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutCoachEventInput = {
  create: CoachCreateWithoutCoachEventInput;
  update: CoachUpdateWithoutCoachEventInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutCoachPackageInput = {
  create: CoachCreateWithoutCoachPackageInput;
  update: CoachUpdateWithoutCoachPackageInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutCoachReviewInput = {
  create: CoachCreateWithoutCoachReviewInput;
  update: CoachUpdateWithoutCoachReviewInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutCoachSpecialismInput = {
  create: CoachCreateWithoutCoachSpecialismInput;
  update: CoachUpdateWithoutCoachSpecialismInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutSessionInput = {
  create: CoachCreateWithoutSessionInput;
  update: CoachUpdateWithoutSessionInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutUserInput = {
  create: CoachCreateWithoutUserInput;
  update: CoachUpdateWithoutUserInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithWhereUniqueWithoutUserPackageInput = {
  create: CoachCreateWithoutUserPackageInput;
  update: CoachUpdateWithoutUserPackageInput;
  where: CoachExtendedWhereUniqueInput;
};

export type CoachUpsertWithoutBookingHoldInput = {
  create: CoachCreateWithoutBookingHoldInput;
  update: CoachUpdateWithoutBookingHoldInput;
};

export type CoachUpsertWithoutCalendarSettingInput = {
  create: CoachCreateWithoutCalendarSettingInput;
  update: CoachUpdateWithoutCalendarSettingInput;
};

export type CoachUpsertWithoutCoachApproachInput = {
  create: CoachCreateWithoutCoachApproachInput;
  update: CoachUpdateWithoutCoachApproachInput;
};

export type CoachUpsertWithoutCoachAudienceExperienceInput = {
  create: CoachCreateWithoutCoachAudienceExperienceInput;
  update: CoachUpdateWithoutCoachAudienceExperienceInput;
};

export type CoachUpsertWithoutCoachCategoryInput = {
  create: CoachCreateWithoutCoachCategoryInput;
  update: CoachUpdateWithoutCoachCategoryInput;
};

export type CoachUpsertWithoutCoachEventInput = {
  create: CoachCreateWithoutCoachEventInput;
  update: CoachUpdateWithoutCoachEventInput;
};

export type CoachUpsertWithoutCoachPackageInput = {
  create: CoachCreateWithoutCoachPackageInput;
  update: CoachUpdateWithoutCoachPackageInput;
};

export type CoachUpsertWithoutCoachReviewInput = {
  create: CoachCreateWithoutCoachReviewInput;
  update: CoachUpdateWithoutCoachReviewInput;
};

export type CoachUpsertWithoutCoachSpecialismInput = {
  create: CoachCreateWithoutCoachSpecialismInput;
  update: CoachUpdateWithoutCoachSpecialismInput;
};

export type CoachUpsertWithoutSessionInput = {
  create: CoachCreateWithoutSessionInput;
  update: CoachUpdateWithoutSessionInput;
};

export type CoachUpsertWithoutUserInput = {
  create: CoachCreateWithoutUserInput;
  update: CoachUpdateWithoutUserInput;
};

export type CoachUpsertWithoutUserPackageInput = {
  create: CoachCreateWithoutUserPackageInput;
  update: CoachUpdateWithoutUserPackageInput;
};

export type CoachUserCreateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutCoachInput>;
  create?: InputMaybe<UserCreateWithoutCoachInput>;
};

export type CoachUserPackagesCreateNestedInput = {
  connect?: InputMaybe<Array<UserPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPackageConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<UserPackageCreateWithoutCoachInput>>;
};

export type CoachUserPackagesUpdateNestedInput = {
  connect?: InputMaybe<Array<UserPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPackageConnectOrCreateWithoutCoachInput>>;
  create?: InputMaybe<Array<UserPackageCreateWithoutCoachInput>>;
  delete?: InputMaybe<Array<UserPackageExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPackageExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPackageUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<UserPackageUpdateManyInput>>;
  upsert?: InputMaybe<Array<UserPackageUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type CoachUserUpdateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutCoachInput>;
  create?: InputMaybe<UserCreateWithoutCoachInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutCoachInput>;
  upsert?: InputMaybe<UserUpsertWithoutCoachInput>;
};

export type CoachWhereInput = {
  AND?: InputMaybe<Array<CoachWhereInput>>;
  NOT?: InputMaybe<Array<CoachWhereInput>>;
  OR?: InputMaybe<Array<CoachWhereInput>>;
  approaches?: InputMaybe<CoachApproachFilter>;
  audienceExperiences?: InputMaybe<CoachAudienceExperienceFilter>;
  avatarKey?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  bookings?: InputMaybe<BookingHoldFilter>;
  calendarId?: InputMaybe<StringNullableFilter>;
  calendarSettings?: InputMaybe<CalendarSettingWhereInput>;
  categories?: InputMaybe<CoachCategoryFilter>;
  coachingSince?: InputMaybe<AwsDateTimeNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  displayName?: InputMaybe<StringNullableFilter>;
  events?: InputMaybe<CoachEventFilter>;
  excludeIndividuals?: InputMaybe<BooleanFilter>;
  gender?: InputMaybe<GenderEnumFilter>;
  handle?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  languages?: InputMaybe<AwsjsonNullableFilter>;
  packages?: InputMaybe<CoachPackageFilter>;
  position?: InputMaybe<IntNullableFilter>;
  pronouns?: InputMaybe<StringNullableFilter>;
  published?: InputMaybe<BooleanNullableFilter>;
  reviews?: InputMaybe<CoachReviewFilter>;
  sessions?: InputMaybe<SessionFilter>;
  specialisms?: InputMaybe<CoachSpecialismFilter>;
  trainingSchool?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
  userPackages?: InputMaybe<UserPackageFilter>;
  ycmbUrl?: InputMaybe<StringNullableFilter>;
};

export type CoachWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CoachWhereInput>>;
  NOT?: InputMaybe<Array<CoachWhereInput>>;
  OR?: InputMaybe<Array<CoachWhereInput>>;
  approaches?: InputMaybe<CoachApproachFilter>;
  audienceExperiences?: InputMaybe<CoachAudienceExperienceFilter>;
  avatarKey?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  bookings?: InputMaybe<BookingHoldFilter>;
  calendarId?: InputMaybe<StringNullableFilter>;
  calendarSettings?: InputMaybe<CalendarSettingWhereInput>;
  categories?: InputMaybe<CoachCategoryFilter>;
  coachingSince?: InputMaybe<AwsDateTimeNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  displayName?: InputMaybe<StringNullableFilter>;
  events?: InputMaybe<CoachEventFilter>;
  excludeIndividuals?: InputMaybe<BooleanFilter>;
  gender?: InputMaybe<GenderEnumFilter>;
  handle?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  languages?: InputMaybe<AwsjsonNullableFilter>;
  packages?: InputMaybe<CoachPackageFilter>;
  position?: InputMaybe<IntNullableFilter>;
  pronouns?: InputMaybe<StringNullableFilter>;
  published?: InputMaybe<BooleanNullableFilter>;
  reviews?: InputMaybe<CoachReviewFilter>;
  sessions?: InputMaybe<SessionFilter>;
  specialisms?: InputMaybe<CoachSpecialismFilter>;
  trainingSchool?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
  userPackages?: InputMaybe<UserPackageFilter>;
  ycmbUrl?: InputMaybe<StringNullableFilter>;
};

export type CoachWhereUniqueInput = {
  handle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Company = {
  __typename?: 'Company';
  accountType: AccountType;
  coachReviews: Array<CoachReview>;
  companyAdmin?: Maybe<CompanyAdmin>;
  createdAt: Scalars['AWSDateTime']['output'];
  features: Array<FeatureCompany>;
  hubspotCompanyId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  intercomCompanyId?: Maybe<Scalars['String']['output']>;
  logoKey?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<CompanyParent>;
  parentCompanyId?: Maybe<Scalars['String']['output']>;
  reportUrl?: Maybe<Scalars['String']['output']>;
  teamMembers: Array<TeamMember>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type CompanyAdmin = {
  __typename?: 'CompanyAdmin';
  chargebeeCancelScheduleCreatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  chargebeeCancelledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  chargebeeCurrentTermEnd?: Maybe<Scalars['AWSDateTime']['output']>;
  chargebeeCurrentTermStart?: Maybe<Scalars['AWSDateTime']['output']>;
  chargebeeCustomerId?: Maybe<Scalars['String']['output']>;
  chargebeeSubscriptionId?: Maybe<Scalars['String']['output']>;
  chargebeeSubscriptionStatus?: Maybe<Scalars['String']['output']>;
  chargebeeTotalSeats: Scalars['Int']['output'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  subscriptionActive?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type CompanyAdminCompanyCreateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateWithoutCompanyAdminInput>;
  create?: InputMaybe<CompanyCreateWithoutCompanyAdminInput>;
};

export type CompanyAdminCompanyUpdateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateWithoutCompanyAdminInput>;
  create?: InputMaybe<CompanyCreateWithoutCompanyAdminInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CompanyUpdateWithoutCompanyAdminInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutCompanyAdminInput>;
};

export type CompanyAdminConnectOrCreateWithoutCompanyInput = {
  create: CompanyAdminCreateWithoutCompanyInput;
  where: CompanyAdminWhereUniqueInput;
};

export type CompanyAdminCreateInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCustomerId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionStatus?: InputMaybe<Scalars['String']['input']>;
  chargebeeTotalSeats?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<CompanyAdminCompanyCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subscriptionActive?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyAdminCreateManyInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCustomerId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionStatus?: InputMaybe<Scalars['String']['input']>;
  chargebeeTotalSeats?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subscriptionActive?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyAdminCreateWithoutCompanyInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCustomerId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionStatus?: InputMaybe<Scalars['String']['input']>;
  chargebeeTotalSeats?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subscriptionActive?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyAdminExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CompanyAdminWhereInput>>;
  NOT?: InputMaybe<Array<CompanyAdminWhereInput>>;
  OR?: InputMaybe<Array<CompanyAdminWhereInput>>;
  chargebeeCancelScheduleCreatedAt?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCancelledAt?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCurrentTermEnd?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCurrentTermStart?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCustomerId?: InputMaybe<StringNullableFilter>;
  chargebeeSubscriptionId?: InputMaybe<StringNullableFilter>;
  chargebeeSubscriptionStatus?: InputMaybe<StringNullableFilter>;
  chargebeeTotalSeats?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  subscriptionActive?: InputMaybe<BooleanNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyAdminFilter = {
  every?: InputMaybe<CompanyAdminWhereInputWithoutNullables>;
  none?: InputMaybe<CompanyAdminWhereInputWithoutNullables>;
  some?: InputMaybe<CompanyAdminWhereInputWithoutNullables>;
};

export type CompanyAdminOperationInput = {
  chargebeeTotalSeats?: InputMaybe<IntOperation>;
};

export type CompanyAdminOrderByInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<OrderByArg>;
  chargebeeCancelledAt?: InputMaybe<OrderByArg>;
  chargebeeCurrentTermEnd?: InputMaybe<OrderByArg>;
  chargebeeCurrentTermStart?: InputMaybe<OrderByArg>;
  chargebeeCustomerId?: InputMaybe<OrderByArg>;
  chargebeeSubscriptionId?: InputMaybe<OrderByArg>;
  chargebeeSubscriptionStatus?: InputMaybe<OrderByArg>;
  chargebeeTotalSeats?: InputMaybe<OrderByArg>;
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  subscriptionActive?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CompanyAdminScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyAdminScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyAdminScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyAdminScalarWhereInput>>;
  chargebeeCancelScheduleCreatedAt?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCancelledAt?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCurrentTermEnd?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCurrentTermStart?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCustomerId?: InputMaybe<StringNullableFilter>;
  chargebeeSubscriptionId?: InputMaybe<StringNullableFilter>;
  chargebeeSubscriptionStatus?: InputMaybe<StringNullableFilter>;
  chargebeeTotalSeats?: InputMaybe<IntFilter>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  subscriptionActive?: InputMaybe<BooleanNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyAdminUpdateInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCustomerId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionStatus?: InputMaybe<Scalars['String']['input']>;
  chargebeeTotalSeats?: InputMaybe<Scalars['Int']['input']>;
  company?: InputMaybe<CompanyAdminCompanyUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subscriptionActive?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyAdminUpdateManyInput = {
  data: CompanyAdminUpdateInput;
  where: CompanyAdminScalarWhereInput;
};

export type CompanyAdminUpdateWithWhereUniqueWithoutCompanyInput = {
  data: CompanyAdminUpdateWithoutCompanyInput;
  where: CompanyAdminExtendedWhereUniqueInput;
};

export type CompanyAdminUpdateWithoutCompanyInput = {
  chargebeeCancelScheduleCreatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermEnd?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCurrentTermStart?: InputMaybe<Scalars['AWSDateTime']['input']>;
  chargebeeCustomerId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  chargebeeSubscriptionStatus?: InputMaybe<Scalars['String']['input']>;
  chargebeeTotalSeats?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  subscriptionActive?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyAdminUpsertWithWhereUniqueWithoutCompanyInput = {
  create: CompanyAdminCreateWithoutCompanyInput;
  update: CompanyAdminUpdateWithoutCompanyInput;
  where: CompanyAdminExtendedWhereUniqueInput;
};

export type CompanyAdminUpsertWithoutCompanyInput = {
  create: CompanyAdminCreateWithoutCompanyInput;
  update: CompanyAdminUpdateWithoutCompanyInput;
};

export type CompanyAdminWhereInput = {
  AND?: InputMaybe<Array<CompanyAdminWhereInput>>;
  NOT?: InputMaybe<Array<CompanyAdminWhereInput>>;
  OR?: InputMaybe<Array<CompanyAdminWhereInput>>;
  chargebeeCancelScheduleCreatedAt?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCancelledAt?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCurrentTermEnd?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCurrentTermStart?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCustomerId?: InputMaybe<StringNullableFilter>;
  chargebeeSubscriptionId?: InputMaybe<StringNullableFilter>;
  chargebeeSubscriptionStatus?: InputMaybe<StringNullableFilter>;
  chargebeeTotalSeats?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  subscriptionActive?: InputMaybe<BooleanNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyAdminWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CompanyAdminWhereInput>>;
  NOT?: InputMaybe<Array<CompanyAdminWhereInput>>;
  OR?: InputMaybe<Array<CompanyAdminWhereInput>>;
  chargebeeCancelScheduleCreatedAt?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCancelledAt?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCurrentTermEnd?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCurrentTermStart?: InputMaybe<AwsDateTimeNullableFilter>;
  chargebeeCustomerId?: InputMaybe<StringNullableFilter>;
  chargebeeSubscriptionId?: InputMaybe<StringNullableFilter>;
  chargebeeSubscriptionStatus?: InputMaybe<StringNullableFilter>;
  chargebeeTotalSeats?: InputMaybe<IntFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  subscriptionActive?: InputMaybe<BooleanNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyAdminWhereUniqueInput = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyCoachReviewsCreateNestedInput = {
  connect?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachReviewConnectOrCreateWithoutCompanyInput>>;
  create?: InputMaybe<Array<CoachReviewCreateWithoutCompanyInput>>;
};

export type CompanyCoachReviewsUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachReviewConnectOrCreateWithoutCompanyInput>>;
  create?: InputMaybe<Array<CoachReviewCreateWithoutCompanyInput>>;
  delete?: InputMaybe<Array<CoachReviewExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachReviewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachReviewExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachReviewUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<CoachReviewUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachReviewUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type CompanyCompanyAdminCreateNestedInput = {
  connect?: InputMaybe<CompanyAdminWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyAdminConnectOrCreateWithoutCompanyInput>;
  create?: InputMaybe<CompanyAdminCreateWithoutCompanyInput>;
};

export type CompanyCompanyAdminUpdateNestedInput = {
  connect?: InputMaybe<CompanyAdminWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyAdminConnectOrCreateWithoutCompanyInput>;
  create?: InputMaybe<CompanyAdminCreateWithoutCompanyInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CompanyAdminUpdateWithoutCompanyInput>;
  upsert?: InputMaybe<CompanyAdminUpsertWithoutCompanyInput>;
};

export type CompanyConnectOrCreateWithoutCoachReviewInput = {
  create: CompanyCreateWithoutCoachReviewInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyConnectOrCreateWithoutCompanyAdminInput = {
  create: CompanyCreateWithoutCompanyAdminInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyConnectOrCreateWithoutCompanyParentInput = {
  create: CompanyCreateWithoutCompanyParentInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyConnectOrCreateWithoutFeatureCompanyInput = {
  create: CompanyCreateWithoutFeatureCompanyInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyConnectOrCreateWithoutTeamMemberInput = {
  create: CompanyCreateWithoutTeamMemberInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsCreateNestedInput>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesCreateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<CompanyParentCreateNestedInput>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyCreateManyInput = {
  accountType?: InputMaybe<AccountType>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyCreateWithoutCoachReviewInput = {
  accountType?: InputMaybe<AccountType>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesCreateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<CompanyParentCreateNestedInput>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyCreateWithoutCompanyAdminInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesCreateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<CompanyParentCreateNestedInput>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyCreateWithoutCompanyParentInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsCreateNestedInput>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesCreateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyCreateWithoutFeatureCompanyInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsCreateNestedInput>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<CompanyParentCreateNestedInput>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyCreateWithoutTeamMemberInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsCreateNestedInput>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesCreateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<CompanyParentCreateNestedInput>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  accountType?: InputMaybe<AccountTypeEnumFilter>;
  coachReviews?: InputMaybe<CoachReviewFilter>;
  companyAdmin?: InputMaybe<CompanyAdminWhereInput>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  features?: InputMaybe<FeatureCompanyFilter>;
  hubspotCompanyId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<StringNullableFilter>;
  logoKey?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<CompanyParentWhereInput>;
  parentCompanyId?: InputMaybe<StringNullableFilter>;
  reportUrl?: InputMaybe<StringNullableFilter>;
  teamMembers?: InputMaybe<TeamMemberFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyFeaturesCreateNestedInput = {
  connect?: InputMaybe<Array<FeatureCompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeatureCompanyConnectOrCreateWithoutCompanyInput>>;
  create?: InputMaybe<Array<FeatureCompanyCreateWithoutCompanyInput>>;
};

export type CompanyFeaturesUpdateNestedInput = {
  connect?: InputMaybe<Array<FeatureCompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeatureCompanyConnectOrCreateWithoutCompanyInput>>;
  create?: InputMaybe<Array<FeatureCompanyCreateWithoutCompanyInput>>;
  delete?: InputMaybe<Array<FeatureCompanyExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeatureCompanyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeatureCompanyExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<FeatureCompanyWhereUniqueInput>>;
  update?: InputMaybe<Array<FeatureCompanyUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<FeatureCompanyUpdateManyInput>>;
  upsert?: InputMaybe<Array<FeatureCompanyUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type CompanyFilter = {
  every?: InputMaybe<CompanyWhereInputWithoutNullables>;
  none?: InputMaybe<CompanyWhereInputWithoutNullables>;
  some?: InputMaybe<CompanyWhereInputWithoutNullables>;
};

export type CompanyOrderByInput = {
  accountType?: InputMaybe<OrderByArg>;
  coachReviews?: InputMaybe<CoachReviewOrderByInput>;
  companyAdmin?: InputMaybe<CompanyAdminOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  features?: InputMaybe<FeatureCompanyOrderByInput>;
  hubspotCompanyId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  intercomCompanyId?: InputMaybe<OrderByArg>;
  logoKey?: InputMaybe<OrderByArg>;
  name?: InputMaybe<OrderByArg>;
  parent?: InputMaybe<CompanyParentOrderByInput>;
  parentCompanyId?: InputMaybe<OrderByArg>;
  reportUrl?: InputMaybe<OrderByArg>;
  teamMembers?: InputMaybe<TeamMemberOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CompanyParent = {
  __typename?: 'CompanyParent';
  companies: Array<Company>;
  createdAt: Scalars['AWSDateTime']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type CompanyParentCompaniesCreateNestedInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompanyConnectOrCreateWithoutCompanyParentInput>>;
  create?: InputMaybe<Array<CompanyCreateWithoutCompanyParentInput>>;
};

export type CompanyParentCompaniesUpdateNestedInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompanyConnectOrCreateWithoutCompanyParentInput>>;
  create?: InputMaybe<Array<CompanyCreateWithoutCompanyParentInput>>;
  delete?: InputMaybe<Array<CompanyExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  update?: InputMaybe<Array<CompanyUpdateWithWhereUniqueWithoutCompanyParentInput>>;
  updateMany?: InputMaybe<Array<CompanyUpdateManyInput>>;
  upsert?: InputMaybe<Array<CompanyUpsertWithWhereUniqueWithoutCompanyParentInput>>;
};

export type CompanyParentConnectOrCreateWithoutCompanyInput = {
  create: CompanyParentCreateWithoutCompanyInput;
  where: CompanyParentWhereUniqueInput;
};

export type CompanyParentCreateInput = {
  companies?: InputMaybe<CompanyParentCompaniesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyParentCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyParentCreateNestedInput = {
  connect?: InputMaybe<CompanyParentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyParentConnectOrCreateWithoutCompanyInput>;
  create?: InputMaybe<CompanyParentCreateWithoutCompanyInput>;
};

export type CompanyParentCreateWithoutCompanyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  domain: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyParentExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<CompanyParentWhereInput>>;
  NOT?: InputMaybe<Array<CompanyParentWhereInput>>;
  OR?: InputMaybe<Array<CompanyParentWhereInput>>;
  companies?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyParentFilter = {
  every?: InputMaybe<CompanyParentWhereInputWithoutNullables>;
  none?: InputMaybe<CompanyParentWhereInputWithoutNullables>;
  some?: InputMaybe<CompanyParentWhereInputWithoutNullables>;
};

export type CompanyParentOrderByInput = {
  companies?: InputMaybe<CompanyOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  domain?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  name?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type CompanyParentScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyParentScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyParentScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyParentScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyParentUpdateInput = {
  companies?: InputMaybe<CompanyParentCompaniesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyParentUpdateManyInput = {
  data: CompanyParentUpdateInput;
  where: CompanyParentScalarWhereInput;
};

export type CompanyParentUpdateNestedInput = {
  connect?: InputMaybe<CompanyParentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyParentConnectOrCreateWithoutCompanyInput>;
  create?: InputMaybe<CompanyParentCreateWithoutCompanyInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CompanyParentUpdateWithoutCompanyInput>;
  upsert?: InputMaybe<CompanyParentUpsertWithoutCompanyInput>;
};

export type CompanyParentUpdateWithWhereUniqueWithoutCompanyInput = {
  data: CompanyParentUpdateWithoutCompanyInput;
  where: CompanyParentExtendedWhereUniqueInput;
};

export type CompanyParentUpdateWithoutCompanyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyParentUpsertWithWhereUniqueWithoutCompanyInput = {
  create: CompanyParentCreateWithoutCompanyInput;
  update: CompanyParentUpdateWithoutCompanyInput;
  where: CompanyParentExtendedWhereUniqueInput;
};

export type CompanyParentUpsertWithoutCompanyInput = {
  create: CompanyParentCreateWithoutCompanyInput;
  update: CompanyParentUpdateWithoutCompanyInput;
};

export type CompanyParentWhereInput = {
  AND?: InputMaybe<Array<CompanyParentWhereInput>>;
  NOT?: InputMaybe<Array<CompanyParentWhereInput>>;
  OR?: InputMaybe<Array<CompanyParentWhereInput>>;
  companies?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyParentWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CompanyParentWhereInput>>;
  NOT?: InputMaybe<Array<CompanyParentWhereInput>>;
  OR?: InputMaybe<Array<CompanyParentWhereInput>>;
  companies?: InputMaybe<CompanyFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  domain?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyParentWhereUniqueInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyScalarWhereInput>>;
  accountType?: InputMaybe<AccountTypeEnumFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  hubspotCompanyId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  intercomCompanyId?: InputMaybe<StringNullableFilter>;
  logoKey?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCompanyId?: InputMaybe<StringNullableFilter>;
  reportUrl?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyTeamMembersCreateNestedInput = {
  connect?: InputMaybe<Array<TeamMemberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamMemberConnectOrCreateWithoutCompanyInput>>;
  create?: InputMaybe<Array<TeamMemberCreateWithoutCompanyInput>>;
};

export type CompanyTeamMembersUpdateNestedInput = {
  connect?: InputMaybe<Array<TeamMemberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamMemberConnectOrCreateWithoutCompanyInput>>;
  create?: InputMaybe<Array<TeamMemberCreateWithoutCompanyInput>>;
  delete?: InputMaybe<Array<TeamMemberExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamMemberScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamMemberExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamMemberWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamMemberUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<TeamMemberUpdateManyInput>>;
  upsert?: InputMaybe<Array<TeamMemberUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type CompanyUpdateInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsUpdateNestedInput>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesUpdateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<CompanyParentUpdateNestedInput>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyUpdateManyInput = {
  data: CompanyUpdateInput;
  where: CompanyScalarWhereInput;
};

export type CompanyUpdateWithWhereUniqueWithoutCoachReviewInput = {
  data: CompanyUpdateWithoutCoachReviewInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpdateWithWhereUniqueWithoutCompanyAdminInput = {
  data: CompanyUpdateWithoutCompanyAdminInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpdateWithWhereUniqueWithoutCompanyParentInput = {
  data: CompanyUpdateWithoutCompanyParentInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpdateWithWhereUniqueWithoutFeatureCompanyInput = {
  data: CompanyUpdateWithoutFeatureCompanyInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpdateWithWhereUniqueWithoutTeamMemberInput = {
  data: CompanyUpdateWithoutTeamMemberInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpdateWithoutCoachReviewInput = {
  accountType?: InputMaybe<AccountType>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesUpdateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<CompanyParentUpdateNestedInput>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyUpdateWithoutCompanyAdminInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesUpdateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<CompanyParentUpdateNestedInput>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyUpdateWithoutCompanyParentInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsUpdateNestedInput>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesUpdateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyUpdateWithoutFeatureCompanyInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsUpdateNestedInput>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<CompanyParentUpdateNestedInput>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<CompanyTeamMembersUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyUpdateWithoutTeamMemberInput = {
  accountType?: InputMaybe<AccountType>;
  coachReviews?: InputMaybe<CompanyCoachReviewsUpdateNestedInput>;
  companyAdmin?: InputMaybe<CompanyCompanyAdminUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  features?: InputMaybe<CompanyFeaturesUpdateNestedInput>;
  hubspotCompanyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomCompanyId?: InputMaybe<Scalars['String']['input']>;
  logoKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<CompanyParentUpdateNestedInput>;
  parentCompanyId?: InputMaybe<Scalars['String']['input']>;
  reportUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type CompanyUpsertWithWhereUniqueWithoutCoachReviewInput = {
  create: CompanyCreateWithoutCoachReviewInput;
  update: CompanyUpdateWithoutCoachReviewInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpsertWithWhereUniqueWithoutCompanyAdminInput = {
  create: CompanyCreateWithoutCompanyAdminInput;
  update: CompanyUpdateWithoutCompanyAdminInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpsertWithWhereUniqueWithoutCompanyParentInput = {
  create: CompanyCreateWithoutCompanyParentInput;
  update: CompanyUpdateWithoutCompanyParentInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpsertWithWhereUniqueWithoutFeatureCompanyInput = {
  create: CompanyCreateWithoutFeatureCompanyInput;
  update: CompanyUpdateWithoutFeatureCompanyInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpsertWithWhereUniqueWithoutTeamMemberInput = {
  create: CompanyCreateWithoutTeamMemberInput;
  update: CompanyUpdateWithoutTeamMemberInput;
  where: CompanyExtendedWhereUniqueInput;
};

export type CompanyUpsertWithoutCoachReviewInput = {
  create: CompanyCreateWithoutCoachReviewInput;
  update: CompanyUpdateWithoutCoachReviewInput;
};

export type CompanyUpsertWithoutCompanyAdminInput = {
  create: CompanyCreateWithoutCompanyAdminInput;
  update: CompanyUpdateWithoutCompanyAdminInput;
};

export type CompanyUpsertWithoutCompanyParentInput = {
  create: CompanyCreateWithoutCompanyParentInput;
  update: CompanyUpdateWithoutCompanyParentInput;
};

export type CompanyUpsertWithoutFeatureCompanyInput = {
  create: CompanyCreateWithoutFeatureCompanyInput;
  update: CompanyUpdateWithoutFeatureCompanyInput;
};

export type CompanyUpsertWithoutTeamMemberInput = {
  create: CompanyCreateWithoutTeamMemberInput;
  update: CompanyUpdateWithoutTeamMemberInput;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  accountType?: InputMaybe<AccountTypeEnumFilter>;
  coachReviews?: InputMaybe<CoachReviewFilter>;
  companyAdmin?: InputMaybe<CompanyAdminWhereInput>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  features?: InputMaybe<FeatureCompanyFilter>;
  hubspotCompanyId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  intercomCompanyId?: InputMaybe<StringNullableFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  logoKey?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<CompanyParentWhereInput>;
  parentCompanyId?: InputMaybe<StringNullableFilter>;
  reportUrl?: InputMaybe<StringNullableFilter>;
  teamMembers?: InputMaybe<TeamMemberFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  accountType?: InputMaybe<AccountTypeEnumFilter>;
  coachReviews?: InputMaybe<CoachReviewFilter>;
  companyAdmin?: InputMaybe<CompanyAdminWhereInput>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  features?: InputMaybe<FeatureCompanyFilter>;
  hubspotCompanyId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  intercomCompanyId?: InputMaybe<StringNullableFilter>;
  logoKey?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parent?: InputMaybe<CompanyParentWhereInput>;
  parentCompanyId?: InputMaybe<StringNullableFilter>;
  reportUrl?: InputMaybe<StringNullableFilter>;
  teamMembers?: InputMaybe<TeamMemberFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAccountInput = {
  cognitoId: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  createChargebeeCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  type: AccountType;
};

export type CreateBookingHoldTimerInput = {
  coachId: Scalars['String']['input'];
  enquiryId: Scalars['String']['input'];
  sessionStartsAt: Scalars['AWSDateTime']['input'];
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSessionInput = {
  approaches?: InputMaybe<Array<Scalars['String']['input']>>;
  audienceExperiences?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  coachBooking?: InputMaybe<Scalars['Boolean']['input']>;
  coachId: Scalars['String']['input'];
  endsAt: Scalars['AWSDateTime']['input'];
  packageId?: InputMaybe<Scalars['String']['input']>;
  packages?: InputMaybe<Array<Scalars['String']['input']>>;
  specialisms?: InputMaybe<Array<Scalars['String']['input']>>;
  startsAt: Scalars['AWSDateTime']['input'];
  teamMemberId: Scalars['String']['input'];
};

export type CreateTeamMemberFromInviteInput = {
  email: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  isManager: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  role: UserRole;
};

export type Enquiry = {
  __typename?: 'Enquiry';
  booking: Array<BookingHold>;
  createdAt: Scalars['AWSDateTime']['output'];
  email?: Maybe<Scalars['AWSEmail']['output']>;
  enquiryChoices: Array<EnquiryChoice>;
  id: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type EnquiryBookingCreateNestedInput = {
  connect?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BookingHoldConnectOrCreateWithoutEnquiryInput>>;
  create?: InputMaybe<Array<BookingHoldCreateWithoutEnquiryInput>>;
};

export type EnquiryBookingUpdateNestedInput = {
  connect?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BookingHoldConnectOrCreateWithoutEnquiryInput>>;
  create?: InputMaybe<Array<BookingHoldCreateWithoutEnquiryInput>>;
  delete?: InputMaybe<Array<BookingHoldExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BookingHoldScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BookingHoldExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  update?: InputMaybe<Array<BookingHoldUpdateWithWhereUniqueWithoutEnquiryInput>>;
  updateMany?: InputMaybe<Array<BookingHoldUpdateManyInput>>;
  upsert?: InputMaybe<Array<BookingHoldUpsertWithWhereUniqueWithoutEnquiryInput>>;
};

export type EnquiryChoice = {
  __typename?: 'EnquiryChoice';
  createdAt: Scalars['AWSDateTime']['output'];
  enquiry: Enquiry;
  enquiryId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  value: Scalars['String']['output'];
};

export type EnquiryChoiceConnectOrCreateWithoutEnquiryInput = {
  create: EnquiryChoiceCreateWithoutEnquiryInput;
  where: EnquiryChoiceWhereUniqueInput;
};

export type EnquiryChoiceCreateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  enquiry?: InputMaybe<EnquiryChoiceEnquiryCreateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type EnquiryChoiceCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type EnquiryChoiceCreateWithoutEnquiryInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type EnquiryChoiceEnquiryCreateNestedInput = {
  connect?: InputMaybe<EnquiryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EnquiryConnectOrCreateWithoutEnquiryChoiceInput>;
  create?: InputMaybe<EnquiryCreateWithoutEnquiryChoiceInput>;
};

export type EnquiryChoiceEnquiryUpdateNestedInput = {
  connect?: InputMaybe<EnquiryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EnquiryConnectOrCreateWithoutEnquiryChoiceInput>;
  create?: InputMaybe<EnquiryCreateWithoutEnquiryChoiceInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<EnquiryUpdateWithoutEnquiryChoiceInput>;
  upsert?: InputMaybe<EnquiryUpsertWithoutEnquiryChoiceInput>;
};

export type EnquiryChoiceExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  NOT?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  OR?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  enquiry?: InputMaybe<EnquiryWhereInput>;
  enquiryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type EnquiryChoiceFilter = {
  every?: InputMaybe<EnquiryChoiceWhereInputWithoutNullables>;
  none?: InputMaybe<EnquiryChoiceWhereInputWithoutNullables>;
  some?: InputMaybe<EnquiryChoiceWhereInputWithoutNullables>;
};

export type EnquiryChoiceOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
  enquiry?: InputMaybe<EnquiryOrderByInput>;
  enquiryId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  key?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type EnquiryChoiceScalarWhereInput = {
  AND?: InputMaybe<Array<EnquiryChoiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<EnquiryChoiceScalarWhereInput>>;
  OR?: InputMaybe<Array<EnquiryChoiceScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  enquiryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type EnquiryChoiceUpdateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  enquiry?: InputMaybe<EnquiryChoiceEnquiryUpdateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryChoiceUpdateManyInput = {
  data: EnquiryChoiceUpdateInput;
  where: EnquiryChoiceScalarWhereInput;
};

export type EnquiryChoiceUpdateWithWhereUniqueWithoutEnquiryInput = {
  data: EnquiryChoiceUpdateWithoutEnquiryInput;
  where: EnquiryChoiceExtendedWhereUniqueInput;
};

export type EnquiryChoiceUpdateWithoutEnquiryInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryChoiceUpsertWithWhereUniqueWithoutEnquiryInput = {
  create: EnquiryChoiceCreateWithoutEnquiryInput;
  update: EnquiryChoiceUpdateWithoutEnquiryInput;
  where: EnquiryChoiceExtendedWhereUniqueInput;
};

export type EnquiryChoiceUpsertWithoutEnquiryInput = {
  create: EnquiryChoiceCreateWithoutEnquiryInput;
  update: EnquiryChoiceUpdateWithoutEnquiryInput;
};

export type EnquiryChoiceWhereInput = {
  AND?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  NOT?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  OR?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  enquiry?: InputMaybe<EnquiryWhereInput>;
  enquiryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type EnquiryChoiceWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  NOT?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  OR?: InputMaybe<Array<EnquiryChoiceWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  enquiry?: InputMaybe<EnquiryWhereInput>;
  enquiryId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type EnquiryChoiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryConnectOrCreateWithoutBookingHoldInput = {
  create: EnquiryCreateWithoutBookingHoldInput;
  where: EnquiryWhereUniqueInput;
};

export type EnquiryConnectOrCreateWithoutEnquiryChoiceInput = {
  create: EnquiryCreateWithoutEnquiryChoiceInput;
  where: EnquiryWhereUniqueInput;
};

export type EnquiryConnectOrCreateWithoutUserInput = {
  create: EnquiryCreateWithoutUserInput;
  where: EnquiryWhereUniqueInput;
};

export type EnquiryCreateInput = {
  booking?: InputMaybe<EnquiryBookingCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiryChoices?: InputMaybe<EnquiryEnquiryChoicesCreateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<EnquiryUserCreateNestedInput>;
};

export type EnquiryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type EnquiryCreateWithoutBookingHoldInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiryChoices?: InputMaybe<EnquiryEnquiryChoicesCreateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<EnquiryUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryCreateWithoutEnquiryChoiceInput = {
  booking?: InputMaybe<EnquiryBookingCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<EnquiryUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryCreateWithoutUserInput = {
  booking?: InputMaybe<EnquiryBookingCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiryChoices?: InputMaybe<EnquiryEnquiryChoicesCreateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type EnquiryEnquiryChoicesCreateNestedInput = {
  connect?: InputMaybe<Array<EnquiryChoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EnquiryChoiceConnectOrCreateWithoutEnquiryInput>>;
  create?: InputMaybe<Array<EnquiryChoiceCreateWithoutEnquiryInput>>;
};

export type EnquiryEnquiryChoicesUpdateNestedInput = {
  connect?: InputMaybe<Array<EnquiryChoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EnquiryChoiceConnectOrCreateWithoutEnquiryInput>>;
  create?: InputMaybe<Array<EnquiryChoiceCreateWithoutEnquiryInput>>;
  delete?: InputMaybe<Array<EnquiryChoiceExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EnquiryChoiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EnquiryChoiceExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<EnquiryChoiceWhereUniqueInput>>;
  update?: InputMaybe<Array<EnquiryChoiceUpdateWithWhereUniqueWithoutEnquiryInput>>;
  updateMany?: InputMaybe<Array<EnquiryChoiceUpdateManyInput>>;
  upsert?: InputMaybe<Array<EnquiryChoiceUpsertWithWhereUniqueWithoutEnquiryInput>>;
};

export type EnquiryExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<EnquiryWhereInput>>;
  NOT?: InputMaybe<Array<EnquiryWhereInput>>;
  OR?: InputMaybe<Array<EnquiryWhereInput>>;
  booking?: InputMaybe<BookingHoldFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailNullableFilter>;
  enquiryChoices?: InputMaybe<EnquiryChoiceFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type EnquiryFilter = {
  every?: InputMaybe<EnquiryWhereInputWithoutNullables>;
  none?: InputMaybe<EnquiryWhereInputWithoutNullables>;
  some?: InputMaybe<EnquiryWhereInputWithoutNullables>;
};

export type EnquiryOrderByInput = {
  booking?: InputMaybe<BookingHoldOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  email?: InputMaybe<OrderByArg>;
  enquiryChoices?: InputMaybe<EnquiryChoiceOrderByInput>;
  id?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
};

export type EnquiryScalarWhereInput = {
  AND?: InputMaybe<Array<EnquiryScalarWhereInput>>;
  NOT?: InputMaybe<Array<EnquiryScalarWhereInput>>;
  OR?: InputMaybe<Array<EnquiryScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailNullableFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type EnquiryUpdateInput = {
  booking?: InputMaybe<EnquiryBookingUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiryChoices?: InputMaybe<EnquiryEnquiryChoicesUpdateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<EnquiryUserUpdateNestedInput>;
};

export type EnquiryUpdateManyInput = {
  data: EnquiryUpdateInput;
  where: EnquiryScalarWhereInput;
};

export type EnquiryUpdateWithWhereUniqueWithoutBookingHoldInput = {
  data: EnquiryUpdateWithoutBookingHoldInput;
  where: EnquiryExtendedWhereUniqueInput;
};

export type EnquiryUpdateWithWhereUniqueWithoutEnquiryChoiceInput = {
  data: EnquiryUpdateWithoutEnquiryChoiceInput;
  where: EnquiryExtendedWhereUniqueInput;
};

export type EnquiryUpdateWithWhereUniqueWithoutUserInput = {
  data: EnquiryUpdateWithoutUserInput;
  where: EnquiryExtendedWhereUniqueInput;
};

export type EnquiryUpdateWithoutBookingHoldInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiryChoices?: InputMaybe<EnquiryEnquiryChoicesUpdateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<EnquiryUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryUpdateWithoutEnquiryChoiceInput = {
  booking?: InputMaybe<EnquiryBookingUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<EnquiryUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type EnquiryUpdateWithoutUserInput = {
  booking?: InputMaybe<EnquiryBookingUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiryChoices?: InputMaybe<EnquiryEnquiryChoicesUpdateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type EnquiryUpsertWithWhereUniqueWithoutBookingHoldInput = {
  create: EnquiryCreateWithoutBookingHoldInput;
  update: EnquiryUpdateWithoutBookingHoldInput;
  where: EnquiryExtendedWhereUniqueInput;
};

export type EnquiryUpsertWithWhereUniqueWithoutEnquiryChoiceInput = {
  create: EnquiryCreateWithoutEnquiryChoiceInput;
  update: EnquiryUpdateWithoutEnquiryChoiceInput;
  where: EnquiryExtendedWhereUniqueInput;
};

export type EnquiryUpsertWithWhereUniqueWithoutUserInput = {
  create: EnquiryCreateWithoutUserInput;
  update: EnquiryUpdateWithoutUserInput;
  where: EnquiryExtendedWhereUniqueInput;
};

export type EnquiryUpsertWithoutBookingHoldInput = {
  create: EnquiryCreateWithoutBookingHoldInput;
  update: EnquiryUpdateWithoutBookingHoldInput;
};

export type EnquiryUpsertWithoutEnquiryChoiceInput = {
  create: EnquiryCreateWithoutEnquiryChoiceInput;
  update: EnquiryUpdateWithoutEnquiryChoiceInput;
};

export type EnquiryUpsertWithoutUserInput = {
  create: EnquiryCreateWithoutUserInput;
  update: EnquiryUpdateWithoutUserInput;
};

export type EnquiryUserCreateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutEnquiryInput>;
  create?: InputMaybe<UserCreateWithoutEnquiryInput>;
};

export type EnquiryUserUpdateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutEnquiryInput>;
  create?: InputMaybe<UserCreateWithoutEnquiryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutEnquiryInput>;
  upsert?: InputMaybe<UserUpsertWithoutEnquiryInput>;
};

export type EnquiryWhereInput = {
  AND?: InputMaybe<Array<EnquiryWhereInput>>;
  NOT?: InputMaybe<Array<EnquiryWhereInput>>;
  OR?: InputMaybe<Array<EnquiryWhereInput>>;
  booking?: InputMaybe<BookingHoldFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailNullableFilter>;
  enquiryChoices?: InputMaybe<EnquiryChoiceFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type EnquiryWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<EnquiryWhereInput>>;
  NOT?: InputMaybe<Array<EnquiryWhereInput>>;
  OR?: InputMaybe<Array<EnquiryWhereInput>>;
  booking?: InputMaybe<BookingHoldFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailNullableFilter>;
  enquiryChoices?: InputMaybe<EnquiryChoiceFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type EnquiryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FailedInviteTeamMemberResponse = {
  __typename?: 'FailedInviteTeamMemberResponse';
  reason: Scalars['String']['output'];
  teamMemberEmail: Scalars['String']['output'];
};

export type Feature = {
  __typename?: 'Feature';
  companies: Array<FeatureCompany>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: FeatureStatus;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type FeatureCompaniesCreateNestedInput = {
  connect?: InputMaybe<Array<FeatureCompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeatureCompanyConnectOrCreateWithoutFeatureInput>>;
  create?: InputMaybe<Array<FeatureCompanyCreateWithoutFeatureInput>>;
};

export type FeatureCompaniesUpdateNestedInput = {
  connect?: InputMaybe<Array<FeatureCompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeatureCompanyConnectOrCreateWithoutFeatureInput>>;
  create?: InputMaybe<Array<FeatureCompanyCreateWithoutFeatureInput>>;
  delete?: InputMaybe<Array<FeatureCompanyExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeatureCompanyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeatureCompanyExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<FeatureCompanyWhereUniqueInput>>;
  update?: InputMaybe<Array<FeatureCompanyUpdateWithWhereUniqueWithoutFeatureInput>>;
  updateMany?: InputMaybe<Array<FeatureCompanyUpdateManyInput>>;
  upsert?: InputMaybe<Array<FeatureCompanyUpsertWithWhereUniqueWithoutFeatureInput>>;
};

export type FeatureCompany = {
  __typename?: 'FeatureCompany';
  company: Company;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  feature: Feature;
  featureId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type FeatureCompanyCompanyCreateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateWithoutFeatureCompanyInput>;
  create?: InputMaybe<CompanyCreateWithoutFeatureCompanyInput>;
};

export type FeatureCompanyCompanyUpdateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateWithoutFeatureCompanyInput>;
  create?: InputMaybe<CompanyCreateWithoutFeatureCompanyInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CompanyUpdateWithoutFeatureCompanyInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutFeatureCompanyInput>;
};

export type FeatureCompanyConnectOrCreateWithoutCompanyInput = {
  create: FeatureCompanyCreateWithoutCompanyInput;
  where: FeatureCompanyWhereUniqueInput;
};

export type FeatureCompanyConnectOrCreateWithoutFeatureInput = {
  create: FeatureCompanyCreateWithoutFeatureInput;
  where: FeatureCompanyWhereUniqueInput;
};

export type FeatureCompanyCreateInput = {
  company?: InputMaybe<FeatureCompanyCompanyCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  feature?: InputMaybe<FeatureCompanyFeatureCreateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCompanyCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCompanyCreateWithoutCompanyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  feature?: InputMaybe<FeatureCompanyFeatureCreateNestedInput>;
  featureId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCompanyCreateWithoutFeatureInput = {
  company?: InputMaybe<FeatureCompanyCompanyCreateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCompanyExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  NOT?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  OR?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  feature?: InputMaybe<FeatureWhereInput>;
  featureId?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type FeatureCompanyFeatureCreateNestedInput = {
  connect?: InputMaybe<FeatureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeatureConnectOrCreateWithoutFeatureCompanyInput>;
  create?: InputMaybe<FeatureCreateWithoutFeatureCompanyInput>;
};

export type FeatureCompanyFeatureUpdateNestedInput = {
  connect?: InputMaybe<FeatureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeatureConnectOrCreateWithoutFeatureCompanyInput>;
  create?: InputMaybe<FeatureCreateWithoutFeatureCompanyInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<FeatureUpdateWithoutFeatureCompanyInput>;
  upsert?: InputMaybe<FeatureUpsertWithoutFeatureCompanyInput>;
};

export type FeatureCompanyFilter = {
  every?: InputMaybe<FeatureCompanyWhereInputWithoutNullables>;
  none?: InputMaybe<FeatureCompanyWhereInputWithoutNullables>;
  some?: InputMaybe<FeatureCompanyWhereInputWithoutNullables>;
};

export type FeatureCompanyOrderByInput = {
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  feature?: InputMaybe<FeatureOrderByInput>;
  featureId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type FeatureCompanyScalarWhereInput = {
  AND?: InputMaybe<Array<FeatureCompanyScalarWhereInput>>;
  NOT?: InputMaybe<Array<FeatureCompanyScalarWhereInput>>;
  OR?: InputMaybe<Array<FeatureCompanyScalarWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  featureId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type FeatureCompanyUpdateInput = {
  company?: InputMaybe<FeatureCompanyCompanyUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  feature?: InputMaybe<FeatureCompanyFeatureUpdateNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCompanyUpdateManyInput = {
  data: FeatureCompanyUpdateInput;
  where: FeatureCompanyScalarWhereInput;
};

export type FeatureCompanyUpdateWithWhereUniqueWithoutCompanyInput = {
  data: FeatureCompanyUpdateWithoutCompanyInput;
  where: FeatureCompanyExtendedWhereUniqueInput;
};

export type FeatureCompanyUpdateWithWhereUniqueWithoutFeatureInput = {
  data: FeatureCompanyUpdateWithoutFeatureInput;
  where: FeatureCompanyExtendedWhereUniqueInput;
};

export type FeatureCompanyUpdateWithoutCompanyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  feature?: InputMaybe<FeatureCompanyFeatureUpdateNestedInput>;
  featureId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCompanyUpdateWithoutFeatureInput = {
  company?: InputMaybe<FeatureCompanyCompanyUpdateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCompanyUpsertWithWhereUniqueWithoutCompanyInput = {
  create: FeatureCompanyCreateWithoutCompanyInput;
  update: FeatureCompanyUpdateWithoutCompanyInput;
  where: FeatureCompanyExtendedWhereUniqueInput;
};

export type FeatureCompanyUpsertWithWhereUniqueWithoutFeatureInput = {
  create: FeatureCompanyCreateWithoutFeatureInput;
  update: FeatureCompanyUpdateWithoutFeatureInput;
  where: FeatureCompanyExtendedWhereUniqueInput;
};

export type FeatureCompanyUpsertWithoutCompanyInput = {
  create: FeatureCompanyCreateWithoutCompanyInput;
  update: FeatureCompanyUpdateWithoutCompanyInput;
};

export type FeatureCompanyUpsertWithoutFeatureInput = {
  create: FeatureCompanyCreateWithoutFeatureInput;
  update: FeatureCompanyUpdateWithoutFeatureInput;
};

export type FeatureCompanyWhereInput = {
  AND?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  NOT?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  OR?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  feature?: InputMaybe<FeatureWhereInput>;
  featureId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type FeatureCompanyWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  NOT?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  OR?: InputMaybe<Array<FeatureCompanyWhereInput>>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  feature?: InputMaybe<FeatureWhereInput>;
  featureId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type FeatureCompanyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FeatureConnectOrCreateWithoutFeatureCompanyInput = {
  create: FeatureCreateWithoutFeatureCompanyInput;
  where: FeatureWhereUniqueInput;
};

export type FeatureCreateInput = {
  companies?: InputMaybe<FeatureCompaniesCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status: FeatureStatus;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status: FeatureStatus;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureCreateWithoutFeatureCompanyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status: FeatureStatus;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<FeatureWhereInput>>;
  NOT?: InputMaybe<Array<FeatureWhereInput>>;
  OR?: InputMaybe<Array<FeatureWhereInput>>;
  companies?: InputMaybe<FeatureCompanyFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<FeatureStatusEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type FeatureFilter = {
  every?: InputMaybe<FeatureWhereInputWithoutNullables>;
  none?: InputMaybe<FeatureWhereInputWithoutNullables>;
  some?: InputMaybe<FeatureWhereInputWithoutNullables>;
};

export type FeatureOrderByInput = {
  companies?: InputMaybe<FeatureCompanyOrderByInput>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  name?: InputMaybe<OrderByArg>;
  status?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type FeatureScalarWhereInput = {
  AND?: InputMaybe<Array<FeatureScalarWhereInput>>;
  NOT?: InputMaybe<Array<FeatureScalarWhereInput>>;
  OR?: InputMaybe<Array<FeatureScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<FeatureStatusEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export enum FeatureStatus {
  Disabled = 'disabled',
  Full = 'full',
  Partial = 'partial'
}

export type FeatureStatusEnumFilter = {
  equals?: InputMaybe<FeatureStatus>;
  in?: InputMaybe<Array<FeatureStatus>>;
  not?: InputMaybe<FeatureStatusEnumFilter>;
  notIn?: InputMaybe<Array<FeatureStatus>>;
};

export type FeatureStatusEnumListFilter = {
  equals?: InputMaybe<Array<FeatureStatus>>;
  has?: InputMaybe<FeatureStatus>;
  hasEvery?: InputMaybe<Array<FeatureStatus>>;
  hasSome?: InputMaybe<Array<FeatureStatus>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FeatureUpdateInput = {
  companies?: InputMaybe<FeatureCompaniesUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FeatureStatus>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureUpdateManyInput = {
  data: FeatureUpdateInput;
  where: FeatureScalarWhereInput;
};

export type FeatureUpdateWithWhereUniqueWithoutFeatureCompanyInput = {
  data: FeatureUpdateWithoutFeatureCompanyInput;
  where: FeatureExtendedWhereUniqueInput;
};

export type FeatureUpdateWithoutFeatureCompanyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FeatureStatus>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type FeatureUpsertWithWhereUniqueWithoutFeatureCompanyInput = {
  create: FeatureCreateWithoutFeatureCompanyInput;
  update: FeatureUpdateWithoutFeatureCompanyInput;
  where: FeatureExtendedWhereUniqueInput;
};

export type FeatureUpsertWithoutFeatureCompanyInput = {
  create: FeatureCreateWithoutFeatureCompanyInput;
  update: FeatureUpdateWithoutFeatureCompanyInput;
};

export type FeatureWhereInput = {
  AND?: InputMaybe<Array<FeatureWhereInput>>;
  NOT?: InputMaybe<Array<FeatureWhereInput>>;
  OR?: InputMaybe<Array<FeatureWhereInput>>;
  companies?: InputMaybe<FeatureCompanyFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<FeatureStatusEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type FeatureWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<FeatureWhereInput>>;
  NOT?: InputMaybe<Array<FeatureWhereInput>>;
  OR?: InputMaybe<Array<FeatureWhereInput>>;
  companies?: InputMaybe<FeatureCompanyFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<FeatureStatusEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type FeatureWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatListFilter = {
  equals?: InputMaybe<Array<Scalars['Float']['input']>>;
  has?: InputMaybe<Scalars['Float']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['Float']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['Float']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatOperation = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export enum Gender {
  Female = 'female',
  Male = 'male'
}

export type GenderEnumFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<GenderEnumFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type GenderEnumListFilter = {
  equals?: InputMaybe<Array<Gender>>;
  has?: InputMaybe<Gender>;
  hasEvery?: InputMaybe<Array<Gender>>;
  hasSome?: InputMaybe<Array<Gender>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HostedPageType = {
  __typename?: 'HostedPageType';
  checkoutInfo?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Int']['output'];
  embed: Scalars['Boolean']['output'];
  expiresAt: Scalars['Int']['output'];
  failureReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  object?: Maybe<Scalars['String']['output']>;
  passThruContent?: Maybe<Scalars['String']['output']>;
  resourceVersion: Scalars['String']['output'];
  state: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type ImpersonateCompanyResponse = {
  __typename?: 'ImpersonateCompanyResponse';
  companyId: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntListFilter = {
  equals?: InputMaybe<Array<Scalars['Int']['input']>>;
  has?: InputMaybe<Scalars['Int']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['Int']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['Int']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntOperation = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type InviteTeamMembersInput = {
  companyId: Scalars['String']['input'];
  teamMembers?: InputMaybe<Array<CreateTeamMemberFromInviteInput>>;
};

export type InviteTeamMembersResponse = {
  __typename?: 'InviteTeamMembersResponse';
  failed?: Maybe<Array<FailedInviteTeamMemberResponse>>;
  success?: Maybe<Array<TeamMember>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminChangeEmail: Scalars['Boolean']['output'];
  adminChangePassword: Scalars['Boolean']['output'];
  adminCreateCompany: Company;
  adminDeleteTeamMember: Scalars['Boolean']['output'];
  adminImpersonateCompany: ImpersonateCompanyResponse;
  adminResendInvites: Scalars['Boolean']['output'];
  cancelSession?: Maybe<Session>;
  cancelSessionCoach?: Maybe<Session>;
  cancelUserPackage: CancelUserPackageResponse;
  checkout?: Maybe<CheckoutResponse>;
  createAccount?: Maybe<User>;
  /**   Create a new BookingHold record. */
  createBookingHold: BookingHold;
  createBookingHoldTimer?: Maybe<BookingHold>;
  /**   Create a new CalendarSetting record. */
  createCalendarSetting: CalendarSetting;
  /**   Create a new Coach record. */
  createCoach: Coach;
  /**   Create a new CoachApproach record. */
  createCoachApproach: CoachApproach;
  /**   Create a new CoachAudienceExperience record. */
  createCoachAudienceExperience: CoachAudienceExperience;
  /**   Create a new CoachCategory record. */
  createCoachCategory: CoachCategory;
  /**   Create a new CoachEvent record. */
  createCoachEvent: CoachEvent;
  /**   Create a new CoachPackage record. */
  createCoachPackage: CoachPackage;
  /**   Create a new CoachReview record. */
  createCoachReview: CoachReview;
  /**   Create a new CoachSpecialism record. */
  createCoachSpecialism: CoachSpecialism;
  /**   Create a new Company record. */
  createCompany: Company;
  /**   Create a new CompanyAdmin record. */
  createCompanyAdmin: CompanyAdmin;
  /**   Create a new CompanyParent record. */
  createCompanyParent: CompanyParent;
  /**   Create a new Enquiry record. */
  createEnquiry: Enquiry;
  /**   Create a new EnquiryChoice record. */
  createEnquiryChoice: EnquiryChoice;
  /**   Create a new Feature record. */
  createFeature: Feature;
  /**   Create a new FeatureCompany record. */
  createFeatureCompany: FeatureCompany;
  /**   Create multiple new BookingHolds records. */
  createManyBookingHolds: BatchPayload;
  /**   Create multiple new CalendarSettings records. */
  createManyCalendarSettings: BatchPayload;
  /**   Create multiple new CoachApproaches records. */
  createManyCoachApproaches: BatchPayload;
  /**   Create multiple new CoachAudienceExperiences records. */
  createManyCoachAudienceExperiences: BatchPayload;
  /**   Create multiple new CoachCategories records. */
  createManyCoachCategories: BatchPayload;
  /**   Create multiple new CoachEvents records. */
  createManyCoachEvents: BatchPayload;
  /**   Create multiple new CoachPackages records. */
  createManyCoachPackages: BatchPayload;
  /**   Create multiple new CoachReviews records. */
  createManyCoachReviews: BatchPayload;
  /**   Create multiple new CoachSpecialisms records. */
  createManyCoachSpecialisms: BatchPayload;
  /**   Create multiple new Coaches records. */
  createManyCoaches: BatchPayload;
  /**   Create multiple new Companies records. */
  createManyCompanies: BatchPayload;
  /**   Create multiple new CompanyAdmins records. */
  createManyCompanyAdmins: BatchPayload;
  /**   Create multiple new CompanyParents records. */
  createManyCompanyParents: BatchPayload;
  /**   Create multiple new Enquiries records. */
  createManyEnquiries: BatchPayload;
  /**   Create multiple new EnquiryChoices records. */
  createManyEnquiryChoices: BatchPayload;
  /**   Create multiple new FeatureCompanies records. */
  createManyFeatureCompanies: BatchPayload;
  /**   Create multiple new Features records. */
  createManyFeatures: BatchPayload;
  /**   Create multiple new SessionApproaches records. */
  createManySessionApproaches: BatchPayload;
  /**   Create multiple new SessionAudienceExperiences records. */
  createManySessionAudienceExperiences: BatchPayload;
  /**   Create multiple new SessionCategories records. */
  createManySessionCategories: BatchPayload;
  /**   Create multiple new SessionPackages records. */
  createManySessionPackages: BatchPayload;
  /**   Create multiple new SessionPreCalls records. */
  createManySessionPreCalls: BatchPayload;
  /**   Create multiple new SessionReviews records. */
  createManySessionReviews: BatchPayload;
  /**   Create multiple new SessionSpecialisms records. */
  createManySessionSpecialisms: BatchPayload;
  /**   Create multiple new Sessions records. */
  createManySessions: BatchPayload;
  /**   Create multiple new TeamMemberOutcomes records. */
  createManyTeamMemberOutcomes: BatchPayload;
  /**   Create multiple new TeamMembers records. */
  createManyTeamMembers: BatchPayload;
  /**   Create multiple new UserOnboardingChoices records. */
  createManyUserOnboardingChoices: BatchPayload;
  /**   Create multiple new UserPackages records. */
  createManyUserPackages: BatchPayload;
  /**   Create multiple new UserSettings records. */
  createManyUserSettings: BatchPayload;
  /**   Create multiple new Users records. */
  createManyUsers: BatchPayload;
  /**   Create a new Session record. */
  createSession: Session;
  /**   Create a new SessionApproach record. */
  createSessionApproach: SessionApproach;
  /**   Create a new SessionAudienceExperience record. */
  createSessionAudienceExperience: SessionAudienceExperience;
  /**   Create a new SessionCategory record. */
  createSessionCategory: SessionCategory;
  /**   Create a new SessionPackage record. */
  createSessionPackage: SessionPackage;
  /**   Create a new SessionPreCall record. */
  createSessionPreCall: SessionPreCall;
  /**   Create a new SessionReview record. */
  createSessionReview: SessionReview;
  /**   Create a new SessionSpecialism record. */
  createSessionSpecialism: SessionSpecialism;
  createSessionWithTags?: Maybe<Session>;
  /**   Create a new TeamMember record. */
  createTeamMember: TeamMember;
  /**   Create a new TeamMemberOutcome record. */
  createTeamMemberOutcome: TeamMemberOutcome;
  /**   Create a new User record. */
  createUser: User;
  /**   Create a new UserOnboardingChoice record. */
  createUserOnboardingChoice: UserOnboardingChoice;
  /**   Create a new UserPackage record. */
  createUserPackage: UserPackage;
  /**   Create a new UserSetting record. */
  createUserSetting: UserSetting;
  /**   Delete a single existing BookingHold record. */
  deleteBookingHold: BookingHold;
  /**   Delete a single existing CalendarSetting record. */
  deleteCalendarSetting: CalendarSetting;
  /**   Delete a single existing Coach record. */
  deleteCoach: Coach;
  /**   Delete a single existing CoachApproach record. */
  deleteCoachApproach: CoachApproach;
  /**   Delete a single existing CoachAudienceExperience record. */
  deleteCoachAudienceExperience: CoachAudienceExperience;
  /**   Delete a single existing CoachCategory record. */
  deleteCoachCategory: CoachCategory;
  /**   Delete a single existing CoachEvent record. */
  deleteCoachEvent: CoachEvent;
  /**   Delete a single existing CoachPackage record. */
  deleteCoachPackage: CoachPackage;
  /**   Delete a single existing CoachReview record. */
  deleteCoachReview: CoachReview;
  /**   Delete a single existing CoachSpecialism record. */
  deleteCoachSpecialism: CoachSpecialism;
  /**   Delete a single existing Company record. */
  deleteCompany: Company;
  /**   Delete a single existing CompanyAdmin record. */
  deleteCompanyAdmin: CompanyAdmin;
  /**   Delete a single existing CompanyParent record. */
  deleteCompanyParent: CompanyParent;
  /**   Delete a single existing Enquiry record. */
  deleteEnquiry: Enquiry;
  /**   Delete a single existing EnquiryChoice record. */
  deleteEnquiryChoice: EnquiryChoice;
  /**   Delete a single existing Feature record. */
  deleteFeature: Feature;
  /**   Delete a single existing FeatureCompany record. */
  deleteFeatureCompany: FeatureCompany;
  /**   Delete multiple existing BookingHolds records. */
  deleteManyBookingHolds: BatchPayload;
  /**   Delete multiple existing CalendarSettings records. */
  deleteManyCalendarSettings: BatchPayload;
  /**   Delete multiple existing CoachApproaches records. */
  deleteManyCoachApproaches: BatchPayload;
  /**   Delete multiple existing CoachAudienceExperiences records. */
  deleteManyCoachAudienceExperiences: BatchPayload;
  /**   Delete multiple existing CoachCategories records. */
  deleteManyCoachCategories: BatchPayload;
  /**   Delete multiple existing CoachEvents records. */
  deleteManyCoachEvents: BatchPayload;
  /**   Delete multiple existing CoachPackages records. */
  deleteManyCoachPackages: BatchPayload;
  /**   Delete multiple existing CoachReviews records. */
  deleteManyCoachReviews: BatchPayload;
  /**   Delete multiple existing CoachSpecialisms records. */
  deleteManyCoachSpecialisms: BatchPayload;
  /**   Delete multiple existing Coaches records. */
  deleteManyCoaches: BatchPayload;
  /**   Delete multiple existing Companies records. */
  deleteManyCompanies: BatchPayload;
  /**   Delete multiple existing CompanyAdmins records. */
  deleteManyCompanyAdmins: BatchPayload;
  /**   Delete multiple existing CompanyParents records. */
  deleteManyCompanyParents: BatchPayload;
  /**   Delete multiple existing Enquiries records. */
  deleteManyEnquiries: BatchPayload;
  /**   Delete multiple existing EnquiryChoices records. */
  deleteManyEnquiryChoices: BatchPayload;
  /**   Delete multiple existing FeatureCompanies records. */
  deleteManyFeatureCompanies: BatchPayload;
  /**   Delete multiple existing Features records. */
  deleteManyFeatures: BatchPayload;
  /**   Delete multiple existing SessionApproaches records. */
  deleteManySessionApproaches: BatchPayload;
  /**   Delete multiple existing SessionAudienceExperiences records. */
  deleteManySessionAudienceExperiences: BatchPayload;
  /**   Delete multiple existing SessionCategories records. */
  deleteManySessionCategories: BatchPayload;
  /**   Delete multiple existing SessionPackages records. */
  deleteManySessionPackages: BatchPayload;
  /**   Delete multiple existing SessionPreCalls records. */
  deleteManySessionPreCalls: BatchPayload;
  /**   Delete multiple existing SessionReviews records. */
  deleteManySessionReviews: BatchPayload;
  /**   Delete multiple existing SessionSpecialisms records. */
  deleteManySessionSpecialisms: BatchPayload;
  /**   Delete multiple existing Sessions records. */
  deleteManySessions: BatchPayload;
  /**   Delete multiple existing TeamMemberOutcomes records. */
  deleteManyTeamMemberOutcomes: BatchPayload;
  /**   Delete multiple existing TeamMembers records. */
  deleteManyTeamMembers: BatchPayload;
  /**   Delete multiple existing UserOnboardingChoices records. */
  deleteManyUserOnboardingChoices: BatchPayload;
  /**   Delete multiple existing UserPackages records. */
  deleteManyUserPackages: BatchPayload;
  /**   Delete multiple existing UserSettings records. */
  deleteManyUserSettings: BatchPayload;
  /**   Delete multiple existing Users records. */
  deleteManyUsers: BatchPayload;
  /**   Delete a single existing Session record. */
  deleteSession: Session;
  /**   Delete a single existing SessionApproach record. */
  deleteSessionApproach: SessionApproach;
  /**   Delete a single existing SessionAudienceExperience record. */
  deleteSessionAudienceExperience: SessionAudienceExperience;
  /**   Delete a single existing SessionCategory record. */
  deleteSessionCategory: SessionCategory;
  /**   Delete a single existing SessionPackage record. */
  deleteSessionPackage: SessionPackage;
  /**   Delete a single existing SessionPreCall record. */
  deleteSessionPreCall: SessionPreCall;
  /**   Delete a single existing SessionReview record. */
  deleteSessionReview: SessionReview;
  /**   Delete a single existing SessionSpecialism record. */
  deleteSessionSpecialism: SessionSpecialism;
  /**   Delete a single existing TeamMember record. */
  deleteTeamMember: TeamMember;
  /**   Delete a single existing TeamMemberOutcome record. */
  deleteTeamMemberOutcome: TeamMemberOutcome;
  /**   Delete a single existing User record. */
  deleteUser: User;
  /**   Delete a single existing UserOnboardingChoice record. */
  deleteUserOnboardingChoice: UserOnboardingChoice;
  /**   Delete a single existing UserPackage record. */
  deleteUserPackage: UserPackage;
  /**   Delete a single existing UserSetting record. */
  deleteUserSetting: UserSetting;
  getHostedPage: HostedPageType;
  getPortalPage: PortalPageType;
  inviteTeamMembers: InviteTeamMembersResponse;
  markNoShow?: Maybe<Session>;
  removeTeamMember: Scalars['Boolean']['output'];
  removeTeamMembers: Scalars['Int']['output'];
  resendTeamMemberInvite: TeamMember;
  restoreTeamMember: Scalars['Boolean']['output'];
  /**   Update a single existing BookingHold record. */
  updateBookingHold: BookingHold;
  /**   Update a single existing CalendarSetting record. */
  updateCalendarSetting: CalendarSetting;
  /**   Update a single existing Coach record. */
  updateCoach: Coach;
  /**   Update a single existing CoachApproach record. */
  updateCoachApproach: CoachApproach;
  /**   Update a single existing CoachAudienceExperience record. */
  updateCoachAudienceExperience: CoachAudienceExperience;
  /**   Update a single existing CoachCategory record. */
  updateCoachCategory: CoachCategory;
  /**   Update a single existing CoachEvent record. */
  updateCoachEvent: CoachEvent;
  /**   Update a single existing CoachPackage record. */
  updateCoachPackage: CoachPackage;
  /**   Update a single existing CoachReview record. */
  updateCoachReview: CoachReview;
  /**   Update a single existing CoachSpecialism record. */
  updateCoachSpecialism: CoachSpecialism;
  /**   Update a single existing Company record. */
  updateCompany: Company;
  /**   Update a single existing CompanyAdmin record. */
  updateCompanyAdmin: CompanyAdmin;
  /**   Update a single existing CompanyParent record. */
  updateCompanyParent: CompanyParent;
  updateCompanySponsor?: Maybe<Company>;
  /**   Update a single existing Enquiry record. */
  updateEnquiry: Enquiry;
  /**   Update a single existing EnquiryChoice record. */
  updateEnquiryChoice: EnquiryChoice;
  /**   Update a single existing Feature record. */
  updateFeature: Feature;
  /**   Update a single existing FeatureCompany record. */
  updateFeatureCompany: FeatureCompany;
  /**   Update multiple existing BookingHolds records. */
  updateManyBookingHolds: BatchPayload;
  /**   Update multiple existing CalendarSettings records. */
  updateManyCalendarSettings: BatchPayload;
  /**   Update multiple existing CoachApproaches records. */
  updateManyCoachApproaches: BatchPayload;
  /**   Update multiple existing CoachAudienceExperiences records. */
  updateManyCoachAudienceExperiences: BatchPayload;
  /**   Update multiple existing CoachCategories records. */
  updateManyCoachCategories: BatchPayload;
  /**   Update multiple existing CoachEvents records. */
  updateManyCoachEvents: BatchPayload;
  /**   Update multiple existing CoachPackages records. */
  updateManyCoachPackages: BatchPayload;
  /**   Update multiple existing CoachReviews records. */
  updateManyCoachReviews: BatchPayload;
  /**   Update multiple existing CoachSpecialisms records. */
  updateManyCoachSpecialisms: BatchPayload;
  /**   Update multiple existing Coaches records. */
  updateManyCoaches: BatchPayload;
  /**   Update multiple existing Companies records. */
  updateManyCompanies: BatchPayload;
  /**   Update multiple existing CompanyAdmins records. */
  updateManyCompanyAdmins: BatchPayload;
  /**   Update multiple existing CompanyParents records. */
  updateManyCompanyParents: BatchPayload;
  /**   Update multiple existing Enquiries records. */
  updateManyEnquiries: BatchPayload;
  /**   Update multiple existing EnquiryChoices records. */
  updateManyEnquiryChoices: BatchPayload;
  /**   Update multiple existing FeatureCompanies records. */
  updateManyFeatureCompanies: BatchPayload;
  /**   Update multiple existing Features records. */
  updateManyFeatures: BatchPayload;
  /**   Update multiple existing SessionApproaches records. */
  updateManySessionApproaches: BatchPayload;
  /**   Update multiple existing SessionAudienceExperiences records. */
  updateManySessionAudienceExperiences: BatchPayload;
  /**   Update multiple existing SessionCategories records. */
  updateManySessionCategories: BatchPayload;
  /**   Update multiple existing SessionPackages records. */
  updateManySessionPackages: BatchPayload;
  /**   Update multiple existing SessionPreCalls records. */
  updateManySessionPreCalls: BatchPayload;
  /**   Update multiple existing SessionReviews records. */
  updateManySessionReviews: BatchPayload;
  /**   Update multiple existing SessionSpecialisms records. */
  updateManySessionSpecialisms: BatchPayload;
  /**   Update multiple existing Sessions records. */
  updateManySessions: BatchPayload;
  /**   Update multiple existing TeamMemberOutcomes records. */
  updateManyTeamMemberOutcomes: BatchPayload;
  /**   Update multiple existing TeamMembers records. */
  updateManyTeamMembers: BatchPayload;
  /**   Update multiple existing UserOnboardingChoices records. */
  updateManyUserOnboardingChoices: BatchPayload;
  /**   Update multiple existing UserPackages records. */
  updateManyUserPackages: BatchPayload;
  /**   Update multiple existing UserSettings records. */
  updateManyUserSettings: BatchPayload;
  /**   Update multiple existing Users records. */
  updateManyUsers: BatchPayload;
  /**   Update a single existing Session record. */
  updateSession: Session;
  /**   Update a single existing SessionApproach record. */
  updateSessionApproach: SessionApproach;
  /**   Update a single existing SessionAudienceExperience record. */
  updateSessionAudienceExperience: SessionAudienceExperience;
  /**   Update a single existing SessionCategory record. */
  updateSessionCategory: SessionCategory;
  /**   Update a single existing SessionPackage record. */
  updateSessionPackage: SessionPackage;
  /**   Update a single existing SessionPreCall record. */
  updateSessionPreCall: SessionPreCall;
  /**   Update a single existing SessionReview record. */
  updateSessionReview: SessionReview;
  /**   Update a single existing SessionSpecialism record. */
  updateSessionSpecialism: SessionSpecialism;
  /**   Update a single existing TeamMember record. */
  updateTeamMember: TeamMember;
  /**   Update a single existing TeamMemberOutcome record. */
  updateTeamMemberOutcome: TeamMemberOutcome;
  /**   Update a single existing User record. */
  updateUser: User;
  /**   Update a single existing UserOnboardingChoice record. */
  updateUserOnboardingChoice: UserOnboardingChoice;
  /**   Update a single existing UserPackage record. */
  updateUserPackage: UserPackage;
  /**   Update a single existing UserSetting record. */
  updateUserSetting: UserSetting;
  updateUserToIndividual?: Maybe<User>;
  /**   Create a new BookingHold record if it does not exist, or updates it if it does. */
  upsertBookingHold: BookingHold;
  /**   Create a new CalendarSetting record if it does not exist, or updates it if it does. */
  upsertCalendarSetting: CalendarSetting;
  /**   Create a new Coach record if it does not exist, or updates it if it does. */
  upsertCoach: Coach;
  /**   Create a new CoachApproach record if it does not exist, or updates it if it does. */
  upsertCoachApproach: CoachApproach;
  /**   Create a new CoachAudienceExperience record if it does not exist, or updates it if it does. */
  upsertCoachAudienceExperience: CoachAudienceExperience;
  /**   Create a new CoachCategory record if it does not exist, or updates it if it does. */
  upsertCoachCategory: CoachCategory;
  /**   Create a new CoachEvent record if it does not exist, or updates it if it does. */
  upsertCoachEvent: CoachEvent;
  /**   Create a new CoachPackage record if it does not exist, or updates it if it does. */
  upsertCoachPackage: CoachPackage;
  /**   Create a new CoachReview record if it does not exist, or updates it if it does. */
  upsertCoachReview: CoachReview;
  /**   Create a new CoachSpecialism record if it does not exist, or updates it if it does. */
  upsertCoachSpecialism: CoachSpecialism;
  /**   Create a new Company record if it does not exist, or updates it if it does. */
  upsertCompany: Company;
  /**   Create a new CompanyAdmin record if it does not exist, or updates it if it does. */
  upsertCompanyAdmin: CompanyAdmin;
  /**   Create a new CompanyParent record if it does not exist, or updates it if it does. */
  upsertCompanyParent: CompanyParent;
  /**   Create a new Enquiry record if it does not exist, or updates it if it does. */
  upsertEnquiry: Enquiry;
  /**   Create a new EnquiryChoice record if it does not exist, or updates it if it does. */
  upsertEnquiryChoice: EnquiryChoice;
  /**   Create a new Feature record if it does not exist, or updates it if it does. */
  upsertFeature: Feature;
  /**   Create a new FeatureCompany record if it does not exist, or updates it if it does. */
  upsertFeatureCompany: FeatureCompany;
  /**   Create a new Session record if it does not exist, or updates it if it does. */
  upsertSession: Session;
  /**   Create a new SessionApproach record if it does not exist, or updates it if it does. */
  upsertSessionApproach: SessionApproach;
  /**   Create a new SessionAudienceExperience record if it does not exist, or updates it if it does. */
  upsertSessionAudienceExperience: SessionAudienceExperience;
  /**   Create a new SessionCategory record if it does not exist, or updates it if it does. */
  upsertSessionCategory: SessionCategory;
  /**   Create a new SessionPackage record if it does not exist, or updates it if it does. */
  upsertSessionPackage: SessionPackage;
  /**   Create a new SessionPreCall record if it does not exist, or updates it if it does. */
  upsertSessionPreCall: SessionPreCall;
  /**   Create a new SessionReview record if it does not exist, or updates it if it does. */
  upsertSessionReview: SessionReview;
  /**   Create a new SessionSpecialism record if it does not exist, or updates it if it does. */
  upsertSessionSpecialism: SessionSpecialism;
  /**   Create a new TeamMember record if it does not exist, or updates it if it does. */
  upsertTeamMember: TeamMember;
  /**   Create a new TeamMemberOutcome record if it does not exist, or updates it if it does. */
  upsertTeamMemberOutcome: TeamMemberOutcome;
  /**   Create a new User record if it does not exist, or updates it if it does. */
  upsertUser: User;
  /**   Create a new UserOnboardingChoice record if it does not exist, or updates it if it does. */
  upsertUserOnboardingChoice: UserOnboardingChoice;
  /**   Create a new UserPackage record if it does not exist, or updates it if it does. */
  upsertUserPackage: UserPackage;
  /**   Create a new UserSetting record if it does not exist, or updates it if it does. */
  upsertUserSetting: UserSetting;
};


export type MutationAdminChangeEmailArgs = {
  email: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAdminChangePasswordArgs = {
  password: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAdminCreateCompanyArgs = {
  input: AdminCreateCompanyInput;
};


export type MutationAdminDeleteTeamMemberArgs = {
  teamMemberId: Scalars['String']['input'];
};


export type MutationAdminImpersonateCompanyArgs = {
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAdminResendInvitesArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationCancelSessionArgs = {
  reason: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationCancelSessionCoachArgs = {
  reason: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationCancelUserPackageArgs = {
  id: Scalars['String']['input'];
};


export type MutationCheckoutArgs = {
  input: CheckoutInput;
};


export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


export type MutationCreateBookingHoldArgs = {
  data: BookingHoldCreateInput;
};


export type MutationCreateBookingHoldTimerArgs = {
  input: CreateBookingHoldTimerInput;
};


export type MutationCreateCalendarSettingArgs = {
  data: CalendarSettingCreateInput;
};


export type MutationCreateCoachArgs = {
  data: CoachCreateInput;
};


export type MutationCreateCoachApproachArgs = {
  data: CoachApproachCreateInput;
};


export type MutationCreateCoachAudienceExperienceArgs = {
  data: CoachAudienceExperienceCreateInput;
};


export type MutationCreateCoachCategoryArgs = {
  data: CoachCategoryCreateInput;
};


export type MutationCreateCoachEventArgs = {
  data: CoachEventCreateInput;
};


export type MutationCreateCoachPackageArgs = {
  data: CoachPackageCreateInput;
};


export type MutationCreateCoachReviewArgs = {
  data: CoachReviewCreateInput;
};


export type MutationCreateCoachSpecialismArgs = {
  data: CoachSpecialismCreateInput;
};


export type MutationCreateCompanyArgs = {
  data: CompanyCreateInput;
};


export type MutationCreateCompanyAdminArgs = {
  data: CompanyAdminCreateInput;
};


export type MutationCreateCompanyParentArgs = {
  data: CompanyParentCreateInput;
};


export type MutationCreateEnquiryArgs = {
  data: EnquiryCreateInput;
};


export type MutationCreateEnquiryChoiceArgs = {
  data: EnquiryChoiceCreateInput;
};


export type MutationCreateFeatureArgs = {
  data: FeatureCreateInput;
};


export type MutationCreateFeatureCompanyArgs = {
  data: FeatureCompanyCreateInput;
};


export type MutationCreateManyBookingHoldsArgs = {
  data?: InputMaybe<Array<BookingHoldCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCalendarSettingsArgs = {
  data?: InputMaybe<Array<CalendarSettingCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCoachApproachesArgs = {
  data?: InputMaybe<Array<CoachApproachCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCoachAudienceExperiencesArgs = {
  data?: InputMaybe<Array<CoachAudienceExperienceCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCoachCategoriesArgs = {
  data?: InputMaybe<Array<CoachCategoryCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCoachEventsArgs = {
  data?: InputMaybe<Array<CoachEventCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCoachPackagesArgs = {
  data?: InputMaybe<Array<CoachPackageCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCoachReviewsArgs = {
  data?: InputMaybe<Array<CoachReviewCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCoachSpecialismsArgs = {
  data?: InputMaybe<Array<CoachSpecialismCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCoachesArgs = {
  data?: InputMaybe<Array<CoachCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCompaniesArgs = {
  data?: InputMaybe<Array<CompanyCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCompanyAdminsArgs = {
  data?: InputMaybe<Array<CompanyAdminCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCompanyParentsArgs = {
  data?: InputMaybe<Array<CompanyParentCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyEnquiriesArgs = {
  data?: InputMaybe<Array<EnquiryCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyEnquiryChoicesArgs = {
  data?: InputMaybe<Array<EnquiryChoiceCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyFeatureCompaniesArgs = {
  data?: InputMaybe<Array<FeatureCompanyCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyFeaturesArgs = {
  data?: InputMaybe<Array<FeatureCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySessionApproachesArgs = {
  data?: InputMaybe<Array<SessionApproachCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySessionAudienceExperiencesArgs = {
  data?: InputMaybe<Array<SessionAudienceExperienceCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySessionCategoriesArgs = {
  data?: InputMaybe<Array<SessionCategoryCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySessionPackagesArgs = {
  data?: InputMaybe<Array<SessionPackageCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySessionPreCallsArgs = {
  data?: InputMaybe<Array<SessionPreCallCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySessionReviewsArgs = {
  data?: InputMaybe<Array<SessionReviewCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySessionSpecialismsArgs = {
  data?: InputMaybe<Array<SessionSpecialismCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySessionsArgs = {
  data?: InputMaybe<Array<SessionCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyTeamMemberOutcomesArgs = {
  data?: InputMaybe<Array<TeamMemberOutcomeCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyTeamMembersArgs = {
  data?: InputMaybe<Array<TeamMemberCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserOnboardingChoicesArgs = {
  data?: InputMaybe<Array<UserOnboardingChoiceCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserPackagesArgs = {
  data?: InputMaybe<Array<UserPackageCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUserSettingsArgs = {
  data?: InputMaybe<Array<UserSettingCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyUsersArgs = {
  data?: InputMaybe<Array<UserCreateManyInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateSessionArgs = {
  data: SessionCreateInput;
};


export type MutationCreateSessionApproachArgs = {
  data: SessionApproachCreateInput;
};


export type MutationCreateSessionAudienceExperienceArgs = {
  data: SessionAudienceExperienceCreateInput;
};


export type MutationCreateSessionCategoryArgs = {
  data: SessionCategoryCreateInput;
};


export type MutationCreateSessionPackageArgs = {
  data: SessionPackageCreateInput;
};


export type MutationCreateSessionPreCallArgs = {
  data: SessionPreCallCreateInput;
};


export type MutationCreateSessionReviewArgs = {
  data: SessionReviewCreateInput;
};


export type MutationCreateSessionSpecialismArgs = {
  data: SessionSpecialismCreateInput;
};


export type MutationCreateSessionWithTagsArgs = {
  input: CreateSessionInput;
};


export type MutationCreateTeamMemberArgs = {
  data: TeamMemberCreateInput;
};


export type MutationCreateTeamMemberOutcomeArgs = {
  data: TeamMemberOutcomeCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUserOnboardingChoiceArgs = {
  data: UserOnboardingChoiceCreateInput;
};


export type MutationCreateUserPackageArgs = {
  data: UserPackageCreateInput;
};


export type MutationCreateUserSettingArgs = {
  data: UserSettingCreateInput;
};


export type MutationDeleteBookingHoldArgs = {
  where: BookingHoldExtendedWhereUniqueInput;
};


export type MutationDeleteCalendarSettingArgs = {
  where: CalendarSettingExtendedWhereUniqueInput;
};


export type MutationDeleteCoachArgs = {
  where: CoachExtendedWhereUniqueInput;
};


export type MutationDeleteCoachApproachArgs = {
  where: CoachApproachExtendedWhereUniqueInput;
};


export type MutationDeleteCoachAudienceExperienceArgs = {
  where: CoachAudienceExperienceExtendedWhereUniqueInput;
};


export type MutationDeleteCoachCategoryArgs = {
  where: CoachCategoryExtendedWhereUniqueInput;
};


export type MutationDeleteCoachEventArgs = {
  where: CoachEventExtendedWhereUniqueInput;
};


export type MutationDeleteCoachPackageArgs = {
  where: CoachPackageExtendedWhereUniqueInput;
};


export type MutationDeleteCoachReviewArgs = {
  where: CoachReviewExtendedWhereUniqueInput;
};


export type MutationDeleteCoachSpecialismArgs = {
  where: CoachSpecialismExtendedWhereUniqueInput;
};


export type MutationDeleteCompanyArgs = {
  where: CompanyExtendedWhereUniqueInput;
};


export type MutationDeleteCompanyAdminArgs = {
  where: CompanyAdminExtendedWhereUniqueInput;
};


export type MutationDeleteCompanyParentArgs = {
  where: CompanyParentExtendedWhereUniqueInput;
};


export type MutationDeleteEnquiryArgs = {
  where: EnquiryExtendedWhereUniqueInput;
};


export type MutationDeleteEnquiryChoiceArgs = {
  where: EnquiryChoiceExtendedWhereUniqueInput;
};


export type MutationDeleteFeatureArgs = {
  where: FeatureExtendedWhereUniqueInput;
};


export type MutationDeleteFeatureCompanyArgs = {
  where: FeatureCompanyExtendedWhereUniqueInput;
};


export type MutationDeleteManyBookingHoldsArgs = {
  where: BookingHoldWhereInput;
};


export type MutationDeleteManyCalendarSettingsArgs = {
  where: CalendarSettingWhereInput;
};


export type MutationDeleteManyCoachApproachesArgs = {
  where: CoachApproachWhereInput;
};


export type MutationDeleteManyCoachAudienceExperiencesArgs = {
  where: CoachAudienceExperienceWhereInput;
};


export type MutationDeleteManyCoachCategoriesArgs = {
  where: CoachCategoryWhereInput;
};


export type MutationDeleteManyCoachEventsArgs = {
  where: CoachEventWhereInput;
};


export type MutationDeleteManyCoachPackagesArgs = {
  where: CoachPackageWhereInput;
};


export type MutationDeleteManyCoachReviewsArgs = {
  where: CoachReviewWhereInput;
};


export type MutationDeleteManyCoachSpecialismsArgs = {
  where: CoachSpecialismWhereInput;
};


export type MutationDeleteManyCoachesArgs = {
  where: CoachWhereInput;
};


export type MutationDeleteManyCompaniesArgs = {
  where: CompanyWhereInput;
};


export type MutationDeleteManyCompanyAdminsArgs = {
  where: CompanyAdminWhereInput;
};


export type MutationDeleteManyCompanyParentsArgs = {
  where: CompanyParentWhereInput;
};


export type MutationDeleteManyEnquiriesArgs = {
  where: EnquiryWhereInput;
};


export type MutationDeleteManyEnquiryChoicesArgs = {
  where: EnquiryChoiceWhereInput;
};


export type MutationDeleteManyFeatureCompaniesArgs = {
  where: FeatureCompanyWhereInput;
};


export type MutationDeleteManyFeaturesArgs = {
  where: FeatureWhereInput;
};


export type MutationDeleteManySessionApproachesArgs = {
  where: SessionApproachWhereInput;
};


export type MutationDeleteManySessionAudienceExperiencesArgs = {
  where: SessionAudienceExperienceWhereInput;
};


export type MutationDeleteManySessionCategoriesArgs = {
  where: SessionCategoryWhereInput;
};


export type MutationDeleteManySessionPackagesArgs = {
  where: SessionPackageWhereInput;
};


export type MutationDeleteManySessionPreCallsArgs = {
  where: SessionPreCallWhereInput;
};


export type MutationDeleteManySessionReviewsArgs = {
  where: SessionReviewWhereInput;
};


export type MutationDeleteManySessionSpecialismsArgs = {
  where: SessionSpecialismWhereInput;
};


export type MutationDeleteManySessionsArgs = {
  where: SessionWhereInput;
};


export type MutationDeleteManyTeamMemberOutcomesArgs = {
  where: TeamMemberOutcomeWhereInput;
};


export type MutationDeleteManyTeamMembersArgs = {
  where: TeamMemberWhereInput;
};


export type MutationDeleteManyUserOnboardingChoicesArgs = {
  where: UserOnboardingChoiceWhereInput;
};


export type MutationDeleteManyUserPackagesArgs = {
  where: UserPackageWhereInput;
};


export type MutationDeleteManyUserSettingsArgs = {
  where: UserSettingWhereInput;
};


export type MutationDeleteManyUsersArgs = {
  where: UserWhereInput;
};


export type MutationDeleteSessionArgs = {
  where: SessionExtendedWhereUniqueInput;
};


export type MutationDeleteSessionApproachArgs = {
  where: SessionApproachExtendedWhereUniqueInput;
};


export type MutationDeleteSessionAudienceExperienceArgs = {
  where: SessionAudienceExperienceExtendedWhereUniqueInput;
};


export type MutationDeleteSessionCategoryArgs = {
  where: SessionCategoryExtendedWhereUniqueInput;
};


export type MutationDeleteSessionPackageArgs = {
  where: SessionPackageExtendedWhereUniqueInput;
};


export type MutationDeleteSessionPreCallArgs = {
  where: SessionPreCallExtendedWhereUniqueInput;
};


export type MutationDeleteSessionReviewArgs = {
  where: SessionReviewExtendedWhereUniqueInput;
};


export type MutationDeleteSessionSpecialismArgs = {
  where: SessionSpecialismExtendedWhereUniqueInput;
};


export type MutationDeleteTeamMemberArgs = {
  where: TeamMemberExtendedWhereUniqueInput;
};


export type MutationDeleteTeamMemberOutcomeArgs = {
  where: TeamMemberOutcomeExtendedWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserExtendedWhereUniqueInput;
};


export type MutationDeleteUserOnboardingChoiceArgs = {
  where: UserOnboardingChoiceExtendedWhereUniqueInput;
};


export type MutationDeleteUserPackageArgs = {
  where: UserPackageExtendedWhereUniqueInput;
};


export type MutationDeleteUserSettingArgs = {
  where: UserSettingExtendedWhereUniqueInput;
};


export type MutationGetHostedPageArgs = {
  companyId: Scalars['String']['input'];
  itemPriceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationGetPortalPageArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationInviteTeamMembersArgs = {
  input: InviteTeamMembersInput;
};


export type MutationMarkNoShowArgs = {
  sessionId: Scalars['String']['input'];
};


export type MutationRemoveTeamMemberArgs = {
  teamMemberId: Scalars['String']['input'];
};


export type MutationRemoveTeamMembersArgs = {
  companyId: Scalars['String']['input'];
  teamMemberIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationResendTeamMemberInviteArgs = {
  teamMemberId: Scalars['String']['input'];
};


export type MutationRestoreTeamMemberArgs = {
  teamMemberId: Scalars['String']['input'];
};


export type MutationUpdateBookingHoldArgs = {
  data?: InputMaybe<BookingHoldUpdateInput>;
  where: BookingHoldExtendedWhereUniqueInput;
};


export type MutationUpdateCalendarSettingArgs = {
  data?: InputMaybe<CalendarSettingUpdateInput>;
  where: CalendarSettingExtendedWhereUniqueInput;
};


export type MutationUpdateCoachArgs = {
  data?: InputMaybe<CoachUpdateInput>;
  operation?: InputMaybe<CoachOperationInput>;
  where: CoachExtendedWhereUniqueInput;
};


export type MutationUpdateCoachApproachArgs = {
  data?: InputMaybe<CoachApproachUpdateInput>;
  where: CoachApproachExtendedWhereUniqueInput;
};


export type MutationUpdateCoachAudienceExperienceArgs = {
  data?: InputMaybe<CoachAudienceExperienceUpdateInput>;
  where: CoachAudienceExperienceExtendedWhereUniqueInput;
};


export type MutationUpdateCoachCategoryArgs = {
  data?: InputMaybe<CoachCategoryUpdateInput>;
  where: CoachCategoryExtendedWhereUniqueInput;
};


export type MutationUpdateCoachEventArgs = {
  data?: InputMaybe<CoachEventUpdateInput>;
  where: CoachEventExtendedWhereUniqueInput;
};


export type MutationUpdateCoachPackageArgs = {
  data?: InputMaybe<CoachPackageUpdateInput>;
  where: CoachPackageExtendedWhereUniqueInput;
};


export type MutationUpdateCoachReviewArgs = {
  data?: InputMaybe<CoachReviewUpdateInput>;
  where: CoachReviewExtendedWhereUniqueInput;
};


export type MutationUpdateCoachSpecialismArgs = {
  data?: InputMaybe<CoachSpecialismUpdateInput>;
  where: CoachSpecialismExtendedWhereUniqueInput;
};


export type MutationUpdateCompanyArgs = {
  data?: InputMaybe<CompanyUpdateInput>;
  where: CompanyExtendedWhereUniqueInput;
};


export type MutationUpdateCompanyAdminArgs = {
  data?: InputMaybe<CompanyAdminUpdateInput>;
  operation?: InputMaybe<CompanyAdminOperationInput>;
  where: CompanyAdminExtendedWhereUniqueInput;
};


export type MutationUpdateCompanyParentArgs = {
  data?: InputMaybe<CompanyParentUpdateInput>;
  where: CompanyParentExtendedWhereUniqueInput;
};


export type MutationUpdateCompanySponsorArgs = {
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUpdateEnquiryArgs = {
  data?: InputMaybe<EnquiryUpdateInput>;
  where: EnquiryExtendedWhereUniqueInput;
};


export type MutationUpdateEnquiryChoiceArgs = {
  data?: InputMaybe<EnquiryChoiceUpdateInput>;
  where: EnquiryChoiceExtendedWhereUniqueInput;
};


export type MutationUpdateFeatureArgs = {
  data?: InputMaybe<FeatureUpdateInput>;
  where: FeatureExtendedWhereUniqueInput;
};


export type MutationUpdateFeatureCompanyArgs = {
  data?: InputMaybe<FeatureCompanyUpdateInput>;
  where: FeatureCompanyExtendedWhereUniqueInput;
};


export type MutationUpdateManyBookingHoldsArgs = {
  data?: InputMaybe<BookingHoldUpdateInput>;
  where: BookingHoldWhereInput;
};


export type MutationUpdateManyCalendarSettingsArgs = {
  data?: InputMaybe<CalendarSettingUpdateInput>;
  where: CalendarSettingWhereInput;
};


export type MutationUpdateManyCoachApproachesArgs = {
  data?: InputMaybe<CoachApproachUpdateInput>;
  where: CoachApproachWhereInput;
};


export type MutationUpdateManyCoachAudienceExperiencesArgs = {
  data?: InputMaybe<CoachAudienceExperienceUpdateInput>;
  where: CoachAudienceExperienceWhereInput;
};


export type MutationUpdateManyCoachCategoriesArgs = {
  data?: InputMaybe<CoachCategoryUpdateInput>;
  where: CoachCategoryWhereInput;
};


export type MutationUpdateManyCoachEventsArgs = {
  data?: InputMaybe<CoachEventUpdateInput>;
  where: CoachEventWhereInput;
};


export type MutationUpdateManyCoachPackagesArgs = {
  data?: InputMaybe<CoachPackageUpdateInput>;
  where: CoachPackageWhereInput;
};


export type MutationUpdateManyCoachReviewsArgs = {
  data?: InputMaybe<CoachReviewUpdateInput>;
  where: CoachReviewWhereInput;
};


export type MutationUpdateManyCoachSpecialismsArgs = {
  data?: InputMaybe<CoachSpecialismUpdateInput>;
  where: CoachSpecialismWhereInput;
};


export type MutationUpdateManyCoachesArgs = {
  data?: InputMaybe<CoachUpdateInput>;
  operation?: InputMaybe<CoachOperationInput>;
  where: CoachWhereInput;
};


export type MutationUpdateManyCompaniesArgs = {
  data?: InputMaybe<CompanyUpdateInput>;
  where: CompanyWhereInput;
};


export type MutationUpdateManyCompanyAdminsArgs = {
  data?: InputMaybe<CompanyAdminUpdateInput>;
  operation?: InputMaybe<CompanyAdminOperationInput>;
  where: CompanyAdminWhereInput;
};


export type MutationUpdateManyCompanyParentsArgs = {
  data?: InputMaybe<CompanyParentUpdateInput>;
  where: CompanyParentWhereInput;
};


export type MutationUpdateManyEnquiriesArgs = {
  data?: InputMaybe<EnquiryUpdateInput>;
  where: EnquiryWhereInput;
};


export type MutationUpdateManyEnquiryChoicesArgs = {
  data?: InputMaybe<EnquiryChoiceUpdateInput>;
  where: EnquiryChoiceWhereInput;
};


export type MutationUpdateManyFeatureCompaniesArgs = {
  data?: InputMaybe<FeatureCompanyUpdateInput>;
  where: FeatureCompanyWhereInput;
};


export type MutationUpdateManyFeaturesArgs = {
  data?: InputMaybe<FeatureUpdateInput>;
  where: FeatureWhereInput;
};


export type MutationUpdateManySessionApproachesArgs = {
  data?: InputMaybe<SessionApproachUpdateInput>;
  where: SessionApproachWhereInput;
};


export type MutationUpdateManySessionAudienceExperiencesArgs = {
  data?: InputMaybe<SessionAudienceExperienceUpdateInput>;
  where: SessionAudienceExperienceWhereInput;
};


export type MutationUpdateManySessionCategoriesArgs = {
  data?: InputMaybe<SessionCategoryUpdateInput>;
  where: SessionCategoryWhereInput;
};


export type MutationUpdateManySessionPackagesArgs = {
  data?: InputMaybe<SessionPackageUpdateInput>;
  where: SessionPackageWhereInput;
};


export type MutationUpdateManySessionPreCallsArgs = {
  data?: InputMaybe<SessionPreCallUpdateInput>;
  where: SessionPreCallWhereInput;
};


export type MutationUpdateManySessionReviewsArgs = {
  data?: InputMaybe<SessionReviewUpdateInput>;
  where: SessionReviewWhereInput;
};


export type MutationUpdateManySessionSpecialismsArgs = {
  data?: InputMaybe<SessionSpecialismUpdateInput>;
  where: SessionSpecialismWhereInput;
};


export type MutationUpdateManySessionsArgs = {
  data?: InputMaybe<SessionUpdateInput>;
  where: SessionWhereInput;
};


export type MutationUpdateManyTeamMemberOutcomesArgs = {
  data?: InputMaybe<TeamMemberOutcomeUpdateInput>;
  where: TeamMemberOutcomeWhereInput;
};


export type MutationUpdateManyTeamMembersArgs = {
  data?: InputMaybe<TeamMemberUpdateInput>;
  where: TeamMemberWhereInput;
};


export type MutationUpdateManyUserOnboardingChoicesArgs = {
  data?: InputMaybe<UserOnboardingChoiceUpdateInput>;
  where: UserOnboardingChoiceWhereInput;
};


export type MutationUpdateManyUserPackagesArgs = {
  data?: InputMaybe<UserPackageUpdateInput>;
  operation?: InputMaybe<UserPackageOperationInput>;
  where: UserPackageWhereInput;
};


export type MutationUpdateManyUserSettingsArgs = {
  data?: InputMaybe<UserSettingUpdateInput>;
  where: UserSettingWhereInput;
};


export type MutationUpdateManyUsersArgs = {
  data?: InputMaybe<UserUpdateInput>;
  operation?: InputMaybe<UserOperationInput>;
  where: UserWhereInput;
};


export type MutationUpdateSessionArgs = {
  data?: InputMaybe<SessionUpdateInput>;
  where: SessionExtendedWhereUniqueInput;
};


export type MutationUpdateSessionApproachArgs = {
  data?: InputMaybe<SessionApproachUpdateInput>;
  where: SessionApproachExtendedWhereUniqueInput;
};


export type MutationUpdateSessionAudienceExperienceArgs = {
  data?: InputMaybe<SessionAudienceExperienceUpdateInput>;
  where: SessionAudienceExperienceExtendedWhereUniqueInput;
};


export type MutationUpdateSessionCategoryArgs = {
  data?: InputMaybe<SessionCategoryUpdateInput>;
  where: SessionCategoryExtendedWhereUniqueInput;
};


export type MutationUpdateSessionPackageArgs = {
  data?: InputMaybe<SessionPackageUpdateInput>;
  where: SessionPackageExtendedWhereUniqueInput;
};


export type MutationUpdateSessionPreCallArgs = {
  data?: InputMaybe<SessionPreCallUpdateInput>;
  where: SessionPreCallExtendedWhereUniqueInput;
};


export type MutationUpdateSessionReviewArgs = {
  data?: InputMaybe<SessionReviewUpdateInput>;
  where: SessionReviewExtendedWhereUniqueInput;
};


export type MutationUpdateSessionSpecialismArgs = {
  data?: InputMaybe<SessionSpecialismUpdateInput>;
  where: SessionSpecialismExtendedWhereUniqueInput;
};


export type MutationUpdateTeamMemberArgs = {
  data?: InputMaybe<TeamMemberUpdateInput>;
  where: TeamMemberExtendedWhereUniqueInput;
};


export type MutationUpdateTeamMemberOutcomeArgs = {
  data?: InputMaybe<TeamMemberOutcomeUpdateInput>;
  where: TeamMemberOutcomeExtendedWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data?: InputMaybe<UserUpdateInput>;
  operation?: InputMaybe<UserOperationInput>;
  where: UserExtendedWhereUniqueInput;
};


export type MutationUpdateUserOnboardingChoiceArgs = {
  data?: InputMaybe<UserOnboardingChoiceUpdateInput>;
  where: UserOnboardingChoiceExtendedWhereUniqueInput;
};


export type MutationUpdateUserPackageArgs = {
  data?: InputMaybe<UserPackageUpdateInput>;
  operation?: InputMaybe<UserPackageOperationInput>;
  where: UserPackageExtendedWhereUniqueInput;
};


export type MutationUpdateUserSettingArgs = {
  data?: InputMaybe<UserSettingUpdateInput>;
  where: UserSettingExtendedWhereUniqueInput;
};


export type MutationUpdateUserToIndividualArgs = {
  userId: Scalars['String']['input'];
};


export type MutationUpsertBookingHoldArgs = {
  create: BookingHoldCreateInput;
  update: BookingHoldUpdateInput;
  where: BookingHoldExtendedWhereUniqueInput;
};


export type MutationUpsertCalendarSettingArgs = {
  create: CalendarSettingCreateInput;
  update: CalendarSettingUpdateInput;
  where: CalendarSettingExtendedWhereUniqueInput;
};


export type MutationUpsertCoachArgs = {
  create: CoachCreateInput;
  update: CoachUpdateInput;
  where: CoachExtendedWhereUniqueInput;
};


export type MutationUpsertCoachApproachArgs = {
  create: CoachApproachCreateInput;
  update: CoachApproachUpdateInput;
  where: CoachApproachExtendedWhereUniqueInput;
};


export type MutationUpsertCoachAudienceExperienceArgs = {
  create: CoachAudienceExperienceCreateInput;
  update: CoachAudienceExperienceUpdateInput;
  where: CoachAudienceExperienceExtendedWhereUniqueInput;
};


export type MutationUpsertCoachCategoryArgs = {
  create: CoachCategoryCreateInput;
  update: CoachCategoryUpdateInput;
  where: CoachCategoryExtendedWhereUniqueInput;
};


export type MutationUpsertCoachEventArgs = {
  create: CoachEventCreateInput;
  update: CoachEventUpdateInput;
  where: CoachEventExtendedWhereUniqueInput;
};


export type MutationUpsertCoachPackageArgs = {
  create: CoachPackageCreateInput;
  update: CoachPackageUpdateInput;
  where: CoachPackageExtendedWhereUniqueInput;
};


export type MutationUpsertCoachReviewArgs = {
  create: CoachReviewCreateInput;
  update: CoachReviewUpdateInput;
  where: CoachReviewExtendedWhereUniqueInput;
};


export type MutationUpsertCoachSpecialismArgs = {
  create: CoachSpecialismCreateInput;
  update: CoachSpecialismUpdateInput;
  where: CoachSpecialismExtendedWhereUniqueInput;
};


export type MutationUpsertCompanyArgs = {
  create: CompanyCreateInput;
  update: CompanyUpdateInput;
  where: CompanyExtendedWhereUniqueInput;
};


export type MutationUpsertCompanyAdminArgs = {
  create: CompanyAdminCreateInput;
  update: CompanyAdminUpdateInput;
  where: CompanyAdminExtendedWhereUniqueInput;
};


export type MutationUpsertCompanyParentArgs = {
  create: CompanyParentCreateInput;
  update: CompanyParentUpdateInput;
  where: CompanyParentExtendedWhereUniqueInput;
};


export type MutationUpsertEnquiryArgs = {
  create: EnquiryCreateInput;
  update: EnquiryUpdateInput;
  where: EnquiryExtendedWhereUniqueInput;
};


export type MutationUpsertEnquiryChoiceArgs = {
  create: EnquiryChoiceCreateInput;
  update: EnquiryChoiceUpdateInput;
  where: EnquiryChoiceExtendedWhereUniqueInput;
};


export type MutationUpsertFeatureArgs = {
  create: FeatureCreateInput;
  update: FeatureUpdateInput;
  where: FeatureExtendedWhereUniqueInput;
};


export type MutationUpsertFeatureCompanyArgs = {
  create: FeatureCompanyCreateInput;
  update: FeatureCompanyUpdateInput;
  where: FeatureCompanyExtendedWhereUniqueInput;
};


export type MutationUpsertSessionArgs = {
  create: SessionCreateInput;
  update: SessionUpdateInput;
  where: SessionExtendedWhereUniqueInput;
};


export type MutationUpsertSessionApproachArgs = {
  create: SessionApproachCreateInput;
  update: SessionApproachUpdateInput;
  where: SessionApproachExtendedWhereUniqueInput;
};


export type MutationUpsertSessionAudienceExperienceArgs = {
  create: SessionAudienceExperienceCreateInput;
  update: SessionAudienceExperienceUpdateInput;
  where: SessionAudienceExperienceExtendedWhereUniqueInput;
};


export type MutationUpsertSessionCategoryArgs = {
  create: SessionCategoryCreateInput;
  update: SessionCategoryUpdateInput;
  where: SessionCategoryExtendedWhereUniqueInput;
};


export type MutationUpsertSessionPackageArgs = {
  create: SessionPackageCreateInput;
  update: SessionPackageUpdateInput;
  where: SessionPackageExtendedWhereUniqueInput;
};


export type MutationUpsertSessionPreCallArgs = {
  create: SessionPreCallCreateInput;
  update: SessionPreCallUpdateInput;
  where: SessionPreCallExtendedWhereUniqueInput;
};


export type MutationUpsertSessionReviewArgs = {
  create: SessionReviewCreateInput;
  update: SessionReviewUpdateInput;
  where: SessionReviewExtendedWhereUniqueInput;
};


export type MutationUpsertSessionSpecialismArgs = {
  create: SessionSpecialismCreateInput;
  update: SessionSpecialismUpdateInput;
  where: SessionSpecialismExtendedWhereUniqueInput;
};


export type MutationUpsertTeamMemberArgs = {
  create: TeamMemberCreateInput;
  update: TeamMemberUpdateInput;
  where: TeamMemberExtendedWhereUniqueInput;
};


export type MutationUpsertTeamMemberOutcomeArgs = {
  create: TeamMemberOutcomeCreateInput;
  update: TeamMemberOutcomeUpdateInput;
  where: TeamMemberOutcomeExtendedWhereUniqueInput;
};


export type MutationUpsertUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserExtendedWhereUniqueInput;
};


export type MutationUpsertUserOnboardingChoiceArgs = {
  create: UserOnboardingChoiceCreateInput;
  update: UserOnboardingChoiceUpdateInput;
  where: UserOnboardingChoiceExtendedWhereUniqueInput;
};


export type MutationUpsertUserPackageArgs = {
  create: UserPackageCreateInput;
  update: UserPackageUpdateInput;
  where: UserPackageExtendedWhereUniqueInput;
};


export type MutationUpsertUserSettingArgs = {
  create: UserSettingCreateInput;
  update: UserSettingUpdateInput;
  where: UserSettingExtendedWhereUniqueInput;
};

export enum NullArg {
  Null = 'NULL'
}

export enum OrderByArg {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PortalPageType = {
  __typename?: 'PortalPageType';
  accessUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  allCoachAvailability?: Maybe<Array<Maybe<AllCoachAvailabilityResponse>>>;
  coachAvailability?: Maybe<Array<Maybe<CoachEvent>>>;
  /**   Count the number of BookingHolds records. */
  countBookingHolds: Scalars['Int']['output'];
  /**   Count the number of CalendarSettings records. */
  countCalendarSettings: Scalars['Int']['output'];
  /**   Count the number of CoachApproaches records. */
  countCoachApproaches: Scalars['Int']['output'];
  /**   Count the number of CoachAudienceExperiences records. */
  countCoachAudienceExperiences: Scalars['Int']['output'];
  /**   Count the number of CoachCategories records. */
  countCoachCategories: Scalars['Int']['output'];
  /**   Count the number of CoachEvents records. */
  countCoachEvents: Scalars['Int']['output'];
  /**   Count the number of CoachPackages records. */
  countCoachPackages: Scalars['Int']['output'];
  /**   Count the number of CoachReviews records. */
  countCoachReviews: Scalars['Int']['output'];
  /**   Count the number of CoachSpecialisms records. */
  countCoachSpecialisms: Scalars['Int']['output'];
  /**   Count the number of Coaches records. */
  countCoaches: Scalars['Int']['output'];
  /**   Count the number of Companies records. */
  countCompanies: Scalars['Int']['output'];
  /**   Count the number of CompanyAdmins records. */
  countCompanyAdmins: Scalars['Int']['output'];
  /**   Count the number of CompanyParents records. */
  countCompanyParents: Scalars['Int']['output'];
  /**   Count the number of Enquiries records. */
  countEnquiries: Scalars['Int']['output'];
  /**   Count the number of EnquiryChoices records. */
  countEnquiryChoices: Scalars['Int']['output'];
  /**   Count the number of FeatureCompanies records. */
  countFeatureCompanies: Scalars['Int']['output'];
  /**   Count the number of Features records. */
  countFeatures: Scalars['Int']['output'];
  /**   Count the number of SessionApproaches records. */
  countSessionApproaches: Scalars['Int']['output'];
  /**   Count the number of SessionAudienceExperiences records. */
  countSessionAudienceExperiences: Scalars['Int']['output'];
  /**   Count the number of SessionCategories records. */
  countSessionCategories: Scalars['Int']['output'];
  /**   Count the number of SessionPackages records. */
  countSessionPackages: Scalars['Int']['output'];
  /**   Count the number of SessionPreCalls records. */
  countSessionPreCalls: Scalars['Int']['output'];
  /**   Count the number of SessionReviews records. */
  countSessionReviews: Scalars['Int']['output'];
  /**   Count the number of SessionSpecialisms records. */
  countSessionSpecialisms: Scalars['Int']['output'];
  /**   Count the number of Sessions records. */
  countSessions: Scalars['Int']['output'];
  /**   Count the number of TeamMemberOutcomes records. */
  countTeamMemberOutcomes: Scalars['Int']['output'];
  /**   Count the number of TeamMembers records. */
  countTeamMembers: Scalars['Int']['output'];
  /**   Count the number of UserOnboardingChoices records. */
  countUserOnboardingChoices: Scalars['Int']['output'];
  /**   Count the number of UserPackages records. */
  countUserPackages: Scalars['Int']['output'];
  /**   Count the number of UserSettings records. */
  countUserSettings: Scalars['Int']['output'];
  /**   Count the number of Users records. */
  countUsers: Scalars['Int']['output'];
  /**   Retrieve a single BookingHold record by unique identifier. */
  getBookingHold?: Maybe<BookingHold>;
  /**   Retrieve a single CalendarSetting record by unique identifier. */
  getCalendarSetting?: Maybe<CalendarSetting>;
  /**   Retrieve a single Coach record by unique identifier. */
  getCoach?: Maybe<Coach>;
  /**   Retrieve a single CoachApproach record by unique identifier. */
  getCoachApproach?: Maybe<CoachApproach>;
  /**   Retrieve a single CoachAudienceExperience record by unique identifier. */
  getCoachAudienceExperience?: Maybe<CoachAudienceExperience>;
  /**   Retrieve a single CoachCategory record by unique identifier. */
  getCoachCategory?: Maybe<CoachCategory>;
  /**   Retrieve a single CoachEvent record by unique identifier. */
  getCoachEvent?: Maybe<CoachEvent>;
  /**   Retrieve a single CoachPackage record by unique identifier. */
  getCoachPackage?: Maybe<CoachPackage>;
  /**   Retrieve a single CoachReview record by unique identifier. */
  getCoachReview?: Maybe<CoachReview>;
  /**   Retrieve a single CoachSpecialism record by unique identifier. */
  getCoachSpecialism?: Maybe<CoachSpecialism>;
  /**   Retrieve a single Company record by unique identifier. */
  getCompany?: Maybe<Company>;
  /**   Retrieve a single CompanyAdmin record by unique identifier. */
  getCompanyAdmin?: Maybe<CompanyAdmin>;
  /**   Retrieve a single CompanyParent record by unique identifier. */
  getCompanyParent?: Maybe<CompanyParent>;
  /**   Retrieve a single Enquiry record by unique identifier. */
  getEnquiry?: Maybe<Enquiry>;
  /**   Retrieve a single EnquiryChoice record by unique identifier. */
  getEnquiryChoice?: Maybe<EnquiryChoice>;
  /**   Retrieve a single Feature record by unique identifier. */
  getFeature?: Maybe<Feature>;
  /**   Retrieve a single FeatureCompany record by unique identifier. */
  getFeatureCompany?: Maybe<FeatureCompany>;
  /**   Retrieve a single Session record by unique identifier. */
  getSession?: Maybe<Session>;
  /**   Retrieve a single SessionApproach record by unique identifier. */
  getSessionApproach?: Maybe<SessionApproach>;
  /**   Retrieve a single SessionAudienceExperience record by unique identifier. */
  getSessionAudienceExperience?: Maybe<SessionAudienceExperience>;
  /**   Retrieve a single SessionCategory record by unique identifier. */
  getSessionCategory?: Maybe<SessionCategory>;
  /**   Retrieve a single SessionPackage record by unique identifier. */
  getSessionPackage?: Maybe<SessionPackage>;
  /**   Retrieve a single SessionPreCall record by unique identifier. */
  getSessionPreCall?: Maybe<SessionPreCall>;
  /**   Retrieve a single SessionReview record by unique identifier. */
  getSessionReview?: Maybe<SessionReview>;
  /**   Retrieve a single SessionSpecialism record by unique identifier. */
  getSessionSpecialism?: Maybe<SessionSpecialism>;
  /**   Retrieve a single TeamMember record by unique identifier. */
  getTeamMember?: Maybe<TeamMember>;
  /**   Retrieve a single TeamMemberOutcome record by unique identifier. */
  getTeamMemberOutcome?: Maybe<TeamMemberOutcome>;
  /**   Retrieve a single User record by unique identifier. */
  getUser?: Maybe<User>;
  /**   Retrieve a single UserOnboardingChoice record by unique identifier. */
  getUserOnboardingChoice?: Maybe<UserOnboardingChoice>;
  /**   Retrieve a single UserPackage record by unique identifier. */
  getUserPackage?: Maybe<UserPackage>;
  /**   Retrieve a single UserSetting record by unique identifier. */
  getUserSetting?: Maybe<UserSetting>;
  /**   Retrieve a list of BookingHolds records. */
  listBookingHolds?: Maybe<Array<BookingHold>>;
  /**   Retrieve a list of CalendarSettings records. */
  listCalendarSettings?: Maybe<Array<CalendarSetting>>;
  /**   Retrieve a list of CoachApproaches records. */
  listCoachApproaches?: Maybe<Array<CoachApproach>>;
  /**   Retrieve a list of CoachAudienceExperiences records. */
  listCoachAudienceExperiences?: Maybe<Array<CoachAudienceExperience>>;
  /**   Retrieve a list of CoachCategories records. */
  listCoachCategories?: Maybe<Array<CoachCategory>>;
  /**   Retrieve a list of CoachEvents records. */
  listCoachEvents?: Maybe<Array<CoachEvent>>;
  /**   Retrieve a list of CoachPackages records. */
  listCoachPackages?: Maybe<Array<CoachPackage>>;
  /**   Retrieve a list of CoachReviews records. */
  listCoachReviews?: Maybe<Array<CoachReview>>;
  /**   Retrieve a list of CoachSpecialisms records. */
  listCoachSpecialisms?: Maybe<Array<CoachSpecialism>>;
  /**   Retrieve a list of Coaches records. */
  listCoaches?: Maybe<Array<Coach>>;
  /**   Retrieve a list of Companies records. */
  listCompanies?: Maybe<Array<Company>>;
  /**   Retrieve a list of CompanyAdmins records. */
  listCompanyAdmins?: Maybe<Array<CompanyAdmin>>;
  /**   Retrieve a list of CompanyParents records. */
  listCompanyParents?: Maybe<Array<CompanyParent>>;
  /**   Retrieve a list of Enquiries records. */
  listEnquiries?: Maybe<Array<Enquiry>>;
  /**   Retrieve a list of EnquiryChoices records. */
  listEnquiryChoices?: Maybe<Array<EnquiryChoice>>;
  /**   Retrieve a list of FeatureCompanies records. */
  listFeatureCompanies?: Maybe<Array<FeatureCompany>>;
  /**   Retrieve a list of Features records. */
  listFeatures?: Maybe<Array<Feature>>;
  /**   Retrieve a list of SessionApproaches records. */
  listSessionApproaches?: Maybe<Array<SessionApproach>>;
  /**   Retrieve a list of SessionAudienceExperiences records. */
  listSessionAudienceExperiences?: Maybe<Array<SessionAudienceExperience>>;
  /**   Retrieve a list of SessionCategories records. */
  listSessionCategories?: Maybe<Array<SessionCategory>>;
  /**   Retrieve a list of SessionPackages records. */
  listSessionPackages?: Maybe<Array<SessionPackage>>;
  /**   Retrieve a list of SessionPreCalls records. */
  listSessionPreCalls?: Maybe<Array<SessionPreCall>>;
  /**   Retrieve a list of SessionReviews records. */
  listSessionReviews?: Maybe<Array<SessionReview>>;
  /**   Retrieve a list of SessionSpecialisms records. */
  listSessionSpecialisms?: Maybe<Array<SessionSpecialism>>;
  /**   Retrieve a list of Sessions records. */
  listSessions?: Maybe<Array<Session>>;
  /**   Retrieve a list of TeamMemberOutcomes records. */
  listTeamMemberOutcomes?: Maybe<Array<TeamMemberOutcome>>;
  /**   Retrieve a list of TeamMembers records. */
  listTeamMembers?: Maybe<Array<TeamMember>>;
  /**   Retrieve a list of UserOnboardingChoices records. */
  listUserOnboardingChoices?: Maybe<Array<UserOnboardingChoice>>;
  /**   Retrieve a list of UserPackages records. */
  listUserPackages?: Maybe<Array<UserPackage>>;
  /**   Retrieve a list of UserSettings records. */
  listUserSettings?: Maybe<Array<UserSetting>>;
  /**   Retrieve a list of Users records. */
  listUsers?: Maybe<Array<User>>;
  searchCoaches?: Maybe<Array<Maybe<Coach>>>;
  searchCoachesByAvailability?: Maybe<Array<Maybe<Coach>>>;
  whoAmI: User;
};


export type QueryAllCoachAvailabilityArgs = {
  end: Scalars['AWSDateTime']['input'];
  start: Scalars['AWSDateTime']['input'];
};


export type QueryCoachAvailabilityArgs = {
  coachId: Scalars['String']['input'];
  endsAt: Scalars['String']['input'];
  startsAt: Scalars['String']['input'];
};


export type QueryCountBookingHoldsArgs = {
  orderBy?: InputMaybe<Array<BookingHoldOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BookingHoldWhereInput>;
};


export type QueryCountCalendarSettingsArgs = {
  orderBy?: InputMaybe<Array<CalendarSettingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CalendarSettingWhereInput>;
};


export type QueryCountCoachApproachesArgs = {
  orderBy?: InputMaybe<Array<CoachApproachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachApproachWhereInput>;
};


export type QueryCountCoachAudienceExperiencesArgs = {
  orderBy?: InputMaybe<Array<CoachAudienceExperienceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachAudienceExperienceWhereInput>;
};


export type QueryCountCoachCategoriesArgs = {
  orderBy?: InputMaybe<Array<CoachCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachCategoryWhereInput>;
};


export type QueryCountCoachEventsArgs = {
  orderBy?: InputMaybe<Array<CoachEventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachEventWhereInput>;
};


export type QueryCountCoachPackagesArgs = {
  orderBy?: InputMaybe<Array<CoachPackageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachPackageWhereInput>;
};


export type QueryCountCoachReviewsArgs = {
  orderBy?: InputMaybe<Array<CoachReviewOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachReviewWhereInput>;
};


export type QueryCountCoachSpecialismsArgs = {
  orderBy?: InputMaybe<Array<CoachSpecialismOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachSpecialismWhereInput>;
};


export type QueryCountCoachesArgs = {
  orderBy?: InputMaybe<Array<CoachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachWhereInput>;
};


export type QueryCountCompaniesArgs = {
  orderBy?: InputMaybe<Array<CompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryCountCompanyAdminsArgs = {
  orderBy?: InputMaybe<Array<CompanyAdminOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyAdminWhereInput>;
};


export type QueryCountCompanyParentsArgs = {
  orderBy?: InputMaybe<Array<CompanyParentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyParentWhereInput>;
};


export type QueryCountEnquiriesArgs = {
  orderBy?: InputMaybe<Array<EnquiryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EnquiryWhereInput>;
};


export type QueryCountEnquiryChoicesArgs = {
  orderBy?: InputMaybe<Array<EnquiryChoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EnquiryChoiceWhereInput>;
};


export type QueryCountFeatureCompaniesArgs = {
  orderBy?: InputMaybe<Array<FeatureCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeatureCompanyWhereInput>;
};


export type QueryCountFeaturesArgs = {
  orderBy?: InputMaybe<Array<FeatureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeatureWhereInput>;
};


export type QueryCountSessionApproachesArgs = {
  orderBy?: InputMaybe<Array<SessionApproachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionApproachWhereInput>;
};


export type QueryCountSessionAudienceExperiencesArgs = {
  orderBy?: InputMaybe<Array<SessionAudienceExperienceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionAudienceExperienceWhereInput>;
};


export type QueryCountSessionCategoriesArgs = {
  orderBy?: InputMaybe<Array<SessionCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionCategoryWhereInput>;
};


export type QueryCountSessionPackagesArgs = {
  orderBy?: InputMaybe<Array<SessionPackageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionPackageWhereInput>;
};


export type QueryCountSessionPreCallsArgs = {
  orderBy?: InputMaybe<Array<SessionPreCallOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionPreCallWhereInput>;
};


export type QueryCountSessionReviewsArgs = {
  orderBy?: InputMaybe<Array<SessionReviewOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionReviewWhereInput>;
};


export type QueryCountSessionSpecialismsArgs = {
  orderBy?: InputMaybe<Array<SessionSpecialismOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionSpecialismWhereInput>;
};


export type QueryCountSessionsArgs = {
  orderBy?: InputMaybe<Array<SessionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryCountTeamMemberOutcomesArgs = {
  orderBy?: InputMaybe<Array<TeamMemberOutcomeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamMemberOutcomeWhereInput>;
};


export type QueryCountTeamMembersArgs = {
  orderBy?: InputMaybe<Array<TeamMemberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamMemberWhereInput>;
};


export type QueryCountUserOnboardingChoicesArgs = {
  orderBy?: InputMaybe<Array<UserOnboardingChoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserOnboardingChoiceWhereInput>;
};


export type QueryCountUserPackagesArgs = {
  orderBy?: InputMaybe<Array<UserPackageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserPackageWhereInput>;
};


export type QueryCountUserSettingsArgs = {
  orderBy?: InputMaybe<Array<UserSettingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSettingWhereInput>;
};


export type QueryCountUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGetBookingHoldArgs = {
  where: BookingHoldExtendedWhereUniqueInput;
};


export type QueryGetCalendarSettingArgs = {
  where: CalendarSettingExtendedWhereUniqueInput;
};


export type QueryGetCoachArgs = {
  where: CoachExtendedWhereUniqueInput;
};


export type QueryGetCoachApproachArgs = {
  where: CoachApproachExtendedWhereUniqueInput;
};


export type QueryGetCoachAudienceExperienceArgs = {
  where: CoachAudienceExperienceExtendedWhereUniqueInput;
};


export type QueryGetCoachCategoryArgs = {
  where: CoachCategoryExtendedWhereUniqueInput;
};


export type QueryGetCoachEventArgs = {
  where: CoachEventExtendedWhereUniqueInput;
};


export type QueryGetCoachPackageArgs = {
  where: CoachPackageExtendedWhereUniqueInput;
};


export type QueryGetCoachReviewArgs = {
  where: CoachReviewExtendedWhereUniqueInput;
};


export type QueryGetCoachSpecialismArgs = {
  where: CoachSpecialismExtendedWhereUniqueInput;
};


export type QueryGetCompanyArgs = {
  where: CompanyExtendedWhereUniqueInput;
};


export type QueryGetCompanyAdminArgs = {
  where: CompanyAdminExtendedWhereUniqueInput;
};


export type QueryGetCompanyParentArgs = {
  where: CompanyParentExtendedWhereUniqueInput;
};


export type QueryGetEnquiryArgs = {
  where: EnquiryExtendedWhereUniqueInput;
};


export type QueryGetEnquiryChoiceArgs = {
  where: EnquiryChoiceExtendedWhereUniqueInput;
};


export type QueryGetFeatureArgs = {
  where: FeatureExtendedWhereUniqueInput;
};


export type QueryGetFeatureCompanyArgs = {
  where: FeatureCompanyExtendedWhereUniqueInput;
};


export type QueryGetSessionArgs = {
  where: SessionExtendedWhereUniqueInput;
};


export type QueryGetSessionApproachArgs = {
  where: SessionApproachExtendedWhereUniqueInput;
};


export type QueryGetSessionAudienceExperienceArgs = {
  where: SessionAudienceExperienceExtendedWhereUniqueInput;
};


export type QueryGetSessionCategoryArgs = {
  where: SessionCategoryExtendedWhereUniqueInput;
};


export type QueryGetSessionPackageArgs = {
  where: SessionPackageExtendedWhereUniqueInput;
};


export type QueryGetSessionPreCallArgs = {
  where: SessionPreCallExtendedWhereUniqueInput;
};


export type QueryGetSessionReviewArgs = {
  where: SessionReviewExtendedWhereUniqueInput;
};


export type QueryGetSessionSpecialismArgs = {
  where: SessionSpecialismExtendedWhereUniqueInput;
};


export type QueryGetTeamMemberArgs = {
  where: TeamMemberExtendedWhereUniqueInput;
};


export type QueryGetTeamMemberOutcomeArgs = {
  where: TeamMemberOutcomeExtendedWhereUniqueInput;
};


export type QueryGetUserArgs = {
  where: UserExtendedWhereUniqueInput;
};


export type QueryGetUserOnboardingChoiceArgs = {
  where: UserOnboardingChoiceExtendedWhereUniqueInput;
};


export type QueryGetUserPackageArgs = {
  where: UserPackageExtendedWhereUniqueInput;
};


export type QueryGetUserSettingArgs = {
  where: UserSettingExtendedWhereUniqueInput;
};


export type QueryListBookingHoldsArgs = {
  orderBy?: InputMaybe<Array<BookingHoldOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BookingHoldWhereInput>;
};


export type QueryListCalendarSettingsArgs = {
  orderBy?: InputMaybe<Array<CalendarSettingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CalendarSettingWhereInput>;
};


export type QueryListCoachApproachesArgs = {
  orderBy?: InputMaybe<Array<CoachApproachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachApproachWhereInput>;
};


export type QueryListCoachAudienceExperiencesArgs = {
  orderBy?: InputMaybe<Array<CoachAudienceExperienceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachAudienceExperienceWhereInput>;
};


export type QueryListCoachCategoriesArgs = {
  orderBy?: InputMaybe<Array<CoachCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachCategoryWhereInput>;
};


export type QueryListCoachEventsArgs = {
  orderBy?: InputMaybe<Array<CoachEventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachEventWhereInput>;
};


export type QueryListCoachPackagesArgs = {
  orderBy?: InputMaybe<Array<CoachPackageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachPackageWhereInput>;
};


export type QueryListCoachReviewsArgs = {
  orderBy?: InputMaybe<Array<CoachReviewOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachReviewWhereInput>;
};


export type QueryListCoachSpecialismsArgs = {
  orderBy?: InputMaybe<Array<CoachSpecialismOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachSpecialismWhereInput>;
};


export type QueryListCoachesArgs = {
  orderBy?: InputMaybe<Array<CoachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachWhereInput>;
};


export type QueryListCompaniesArgs = {
  orderBy?: InputMaybe<Array<CompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyWhereInput>;
};


export type QueryListCompanyAdminsArgs = {
  orderBy?: InputMaybe<Array<CompanyAdminOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyAdminWhereInput>;
};


export type QueryListCompanyParentsArgs = {
  orderBy?: InputMaybe<Array<CompanyParentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CompanyParentWhereInput>;
};


export type QueryListEnquiriesArgs = {
  orderBy?: InputMaybe<Array<EnquiryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EnquiryWhereInput>;
};


export type QueryListEnquiryChoicesArgs = {
  orderBy?: InputMaybe<Array<EnquiryChoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EnquiryChoiceWhereInput>;
};


export type QueryListFeatureCompaniesArgs = {
  orderBy?: InputMaybe<Array<FeatureCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeatureCompanyWhereInput>;
};


export type QueryListFeaturesArgs = {
  orderBy?: InputMaybe<Array<FeatureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeatureWhereInput>;
};


export type QueryListSessionApproachesArgs = {
  orderBy?: InputMaybe<Array<SessionApproachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionApproachWhereInput>;
};


export type QueryListSessionAudienceExperiencesArgs = {
  orderBy?: InputMaybe<Array<SessionAudienceExperienceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionAudienceExperienceWhereInput>;
};


export type QueryListSessionCategoriesArgs = {
  orderBy?: InputMaybe<Array<SessionCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionCategoryWhereInput>;
};


export type QueryListSessionPackagesArgs = {
  orderBy?: InputMaybe<Array<SessionPackageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionPackageWhereInput>;
};


export type QueryListSessionPreCallsArgs = {
  orderBy?: InputMaybe<Array<SessionPreCallOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionPreCallWhereInput>;
};


export type QueryListSessionReviewsArgs = {
  orderBy?: InputMaybe<Array<SessionReviewOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionReviewWhereInput>;
};


export type QueryListSessionSpecialismsArgs = {
  orderBy?: InputMaybe<Array<SessionSpecialismOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionSpecialismWhereInput>;
};


export type QueryListSessionsArgs = {
  orderBy?: InputMaybe<Array<SessionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryListTeamMemberOutcomesArgs = {
  orderBy?: InputMaybe<Array<TeamMemberOutcomeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamMemberOutcomeWhereInput>;
};


export type QueryListTeamMembersArgs = {
  orderBy?: InputMaybe<Array<TeamMemberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TeamMemberWhereInput>;
};


export type QueryListUserOnboardingChoicesArgs = {
  orderBy?: InputMaybe<Array<UserOnboardingChoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserOnboardingChoiceWhereInput>;
};


export type QueryListUserPackagesArgs = {
  orderBy?: InputMaybe<Array<UserPackageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserPackageWhereInput>;
};


export type QueryListUserSettingsArgs = {
  orderBy?: InputMaybe<Array<UserSettingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserSettingWhereInput>;
};


export type QueryListUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QuerySearchCoachesArgs = {
  availability?: InputMaybe<CoachAvailabilityInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachWhereInput>;
};


export type QuerySearchCoachesByAvailabilityArgs = {
  availability?: InputMaybe<CoachAvailabilityInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CoachWhereInput>;
};

export type Session = {
  __typename?: 'Session';
  approaches: Array<SessionApproach>;
  audienceExperiences: Array<SessionAudienceExperience>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['AWSDateTime']['output']>;
  categories: Array<SessionCategory>;
  coach?: Maybe<Coach>;
  coachCategoryId?: Maybe<Scalars['String']['output']>;
  coachEventId?: Maybe<Scalars['String']['output']>;
  coachId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  endsAt?: Maybe<Scalars['AWSDateTime']['output']>;
  event?: Maybe<CoachEvent>;
  externalId?: Maybe<Scalars['String']['output']>;
  googleEventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  noShow?: Maybe<Scalars['Boolean']['output']>;
  packages: Array<SessionPackage>;
  sessionPreCall?: Maybe<SessionPreCall>;
  sessionReview?: Maybe<SessionReview>;
  specialisms: Array<SessionSpecialism>;
  startsAt?: Maybe<Scalars['AWSDateTime']['output']>;
  state: SessionState;
  teamMember?: Maybe<TeamMember>;
  teamMemberId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
  userPackage?: Maybe<UserPackage>;
  userPackageId?: Maybe<Scalars['String']['output']>;
};

export type SessionApproach = {
  __typename?: 'SessionApproach';
  approach: CoachApproach;
  approachId: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  session: Session;
  sessionId: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type SessionApproachApproachCreateNestedInput = {
  connect?: InputMaybe<CoachApproachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachApproachConnectOrCreateWithoutSessionApproachInput>;
  create?: InputMaybe<CoachApproachCreateWithoutSessionApproachInput>;
};

export type SessionApproachApproachUpdateNestedInput = {
  connect?: InputMaybe<CoachApproachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachApproachConnectOrCreateWithoutSessionApproachInput>;
  create?: InputMaybe<CoachApproachCreateWithoutSessionApproachInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachApproachUpdateWithoutSessionApproachInput>;
  upsert?: InputMaybe<CoachApproachUpsertWithoutSessionApproachInput>;
};

export type SessionApproachConnectOrCreateWithoutCoachApproachInput = {
  create: SessionApproachCreateWithoutCoachApproachInput;
  where: SessionApproachWhereUniqueInput;
};

export type SessionApproachConnectOrCreateWithoutSessionInput = {
  create: SessionApproachCreateWithoutSessionInput;
  where: SessionApproachWhereUniqueInput;
};

export type SessionApproachCreateInput = {
  approach?: InputMaybe<SessionApproachApproachCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<SessionApproachSessionCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionApproachCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionApproachCreateWithoutCoachApproachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<SessionApproachSessionCreateNestedInput>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionApproachCreateWithoutSessionInput = {
  approach?: InputMaybe<SessionApproachApproachCreateNestedInput>;
  approachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionApproachExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<SessionApproachWhereInput>>;
  NOT?: InputMaybe<Array<SessionApproachWhereInput>>;
  OR?: InputMaybe<Array<SessionApproachWhereInput>>;
  approach?: InputMaybe<CoachApproachWhereInput>;
  approachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<StringFilter>;
  sessionId_approachId?: InputMaybe<SessionIdApproachIdFieldsInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionApproachFilter = {
  every?: InputMaybe<SessionApproachWhereInputWithoutNullables>;
  none?: InputMaybe<SessionApproachWhereInputWithoutNullables>;
  some?: InputMaybe<SessionApproachWhereInputWithoutNullables>;
};

export type SessionApproachOrderByInput = {
  approach?: InputMaybe<CoachApproachOrderByInput>;
  approachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  session?: InputMaybe<SessionOrderByInput>;
  sessionId?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type SessionApproachScalarWhereInput = {
  AND?: InputMaybe<Array<SessionApproachScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionApproachScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionApproachScalarWhereInput>>;
  approachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionApproachSessionCreateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionApproachInput>;
  create?: InputMaybe<SessionCreateWithoutSessionApproachInput>;
};

export type SessionApproachSessionUpdateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionApproachInput>;
  create?: InputMaybe<SessionCreateWithoutSessionApproachInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionUpdateWithoutSessionApproachInput>;
  upsert?: InputMaybe<SessionUpsertWithoutSessionApproachInput>;
};

export type SessionApproachUpdateInput = {
  approach?: InputMaybe<SessionApproachApproachUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<SessionApproachSessionUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionApproachUpdateManyInput = {
  data: SessionApproachUpdateInput;
  where: SessionApproachScalarWhereInput;
};

export type SessionApproachUpdateWithWhereUniqueWithoutCoachApproachInput = {
  data: SessionApproachUpdateWithoutCoachApproachInput;
  where: SessionApproachExtendedWhereUniqueInput;
};

export type SessionApproachUpdateWithWhereUniqueWithoutSessionInput = {
  data: SessionApproachUpdateWithoutSessionInput;
  where: SessionApproachExtendedWhereUniqueInput;
};

export type SessionApproachUpdateWithoutCoachApproachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<SessionApproachSessionUpdateNestedInput>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionApproachUpdateWithoutSessionInput = {
  approach?: InputMaybe<SessionApproachApproachUpdateNestedInput>;
  approachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionApproachUpsertWithWhereUniqueWithoutCoachApproachInput = {
  create: SessionApproachCreateWithoutCoachApproachInput;
  update: SessionApproachUpdateWithoutCoachApproachInput;
  where: SessionApproachExtendedWhereUniqueInput;
};

export type SessionApproachUpsertWithWhereUniqueWithoutSessionInput = {
  create: SessionApproachCreateWithoutSessionInput;
  update: SessionApproachUpdateWithoutSessionInput;
  where: SessionApproachExtendedWhereUniqueInput;
};

export type SessionApproachUpsertWithoutCoachApproachInput = {
  create: SessionApproachCreateWithoutCoachApproachInput;
  update: SessionApproachUpdateWithoutCoachApproachInput;
};

export type SessionApproachUpsertWithoutSessionInput = {
  create: SessionApproachCreateWithoutSessionInput;
  update: SessionApproachUpdateWithoutSessionInput;
};

export type SessionApproachWhereInput = {
  AND?: InputMaybe<Array<SessionApproachWhereInput>>;
  NOT?: InputMaybe<Array<SessionApproachWhereInput>>;
  OR?: InputMaybe<Array<SessionApproachWhereInput>>;
  approach?: InputMaybe<CoachApproachWhereInput>;
  approachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionApproachWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<SessionApproachWhereInput>>;
  NOT?: InputMaybe<Array<SessionApproachWhereInput>>;
  OR?: InputMaybe<Array<SessionApproachWhereInput>>;
  approach?: InputMaybe<CoachApproachWhereInput>;
  approachId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionApproachWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_approachId?: InputMaybe<SessionIdApproachIdFieldsInput>;
};

export type SessionApproachesCreateNestedInput = {
  connect?: InputMaybe<Array<SessionApproachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionApproachConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionApproachCreateWithoutSessionInput>>;
};

export type SessionApproachesUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionApproachWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionApproachConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionApproachCreateWithoutSessionInput>>;
  delete?: InputMaybe<Array<SessionApproachExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionApproachScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionApproachExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionApproachWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionApproachUpdateWithWhereUniqueWithoutSessionInput>>;
  updateMany?: InputMaybe<Array<SessionApproachUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionApproachUpsertWithWhereUniqueWithoutSessionInput>>;
};

export type SessionAudienceExperience = {
  __typename?: 'SessionAudienceExperience';
  audienceExperience: CoachAudienceExperience;
  audienceExperienceId: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  sessions: Session;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type SessionAudienceExperienceAudienceExperienceCreateNestedInput = {
  connect?: InputMaybe<CoachAudienceExperienceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachAudienceExperienceConnectOrCreateWithoutSessionAudienceExperienceInput>;
  create?: InputMaybe<CoachAudienceExperienceCreateWithoutSessionAudienceExperienceInput>;
};

export type SessionAudienceExperienceAudienceExperienceUpdateNestedInput = {
  connect?: InputMaybe<CoachAudienceExperienceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachAudienceExperienceConnectOrCreateWithoutSessionAudienceExperienceInput>;
  create?: InputMaybe<CoachAudienceExperienceCreateWithoutSessionAudienceExperienceInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachAudienceExperienceUpdateWithoutSessionAudienceExperienceInput>;
  upsert?: InputMaybe<CoachAudienceExperienceUpsertWithoutSessionAudienceExperienceInput>;
};

export type SessionAudienceExperienceConnectOrCreateWithoutCoachAudienceExperienceInput = {
  create: SessionAudienceExperienceCreateWithoutCoachAudienceExperienceInput;
  where: SessionAudienceExperienceWhereUniqueInput;
};

export type SessionAudienceExperienceConnectOrCreateWithoutSessionInput = {
  create: SessionAudienceExperienceCreateWithoutSessionInput;
  where: SessionAudienceExperienceWhereUniqueInput;
};

export type SessionAudienceExperienceCreateInput = {
  audienceExperience?: InputMaybe<SessionAudienceExperienceAudienceExperienceCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionAudienceExperienceSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionAudienceExperienceCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionAudienceExperienceCreateWithoutCoachAudienceExperienceInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionAudienceExperienceSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionAudienceExperienceCreateWithoutSessionInput = {
  audienceExperience?: InputMaybe<SessionAudienceExperienceAudienceExperienceCreateNestedInput>;
  audienceExperienceId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionAudienceExperienceExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  NOT?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  OR?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  audienceExperience?: InputMaybe<CoachAudienceExperienceWhereInput>;
  audienceExperienceId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<StringFilter>;
  sessionId_audienceExperienceId?: InputMaybe<SessionIdAudienceExperienceIdFieldsInput>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionAudienceExperienceFilter = {
  every?: InputMaybe<SessionAudienceExperienceWhereInputWithoutNullables>;
  none?: InputMaybe<SessionAudienceExperienceWhereInputWithoutNullables>;
  some?: InputMaybe<SessionAudienceExperienceWhereInputWithoutNullables>;
};

export type SessionAudienceExperienceOrderByInput = {
  audienceExperience?: InputMaybe<CoachAudienceExperienceOrderByInput>;
  audienceExperienceId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  sessionId?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type SessionAudienceExperienceScalarWhereInput = {
  AND?: InputMaybe<Array<SessionAudienceExperienceScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionAudienceExperienceScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionAudienceExperienceScalarWhereInput>>;
  audienceExperienceId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionAudienceExperienceSessionsCreateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionAudienceExperienceInput>;
  create?: InputMaybe<SessionCreateWithoutSessionAudienceExperienceInput>;
};

export type SessionAudienceExperienceSessionsUpdateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionAudienceExperienceInput>;
  create?: InputMaybe<SessionCreateWithoutSessionAudienceExperienceInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionUpdateWithoutSessionAudienceExperienceInput>;
  upsert?: InputMaybe<SessionUpsertWithoutSessionAudienceExperienceInput>;
};

export type SessionAudienceExperienceUpdateInput = {
  audienceExperience?: InputMaybe<SessionAudienceExperienceAudienceExperienceUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionAudienceExperienceSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionAudienceExperienceUpdateManyInput = {
  data: SessionAudienceExperienceUpdateInput;
  where: SessionAudienceExperienceScalarWhereInput;
};

export type SessionAudienceExperienceUpdateWithWhereUniqueWithoutCoachAudienceExperienceInput = {
  data: SessionAudienceExperienceUpdateWithoutCoachAudienceExperienceInput;
  where: SessionAudienceExperienceExtendedWhereUniqueInput;
};

export type SessionAudienceExperienceUpdateWithWhereUniqueWithoutSessionInput = {
  data: SessionAudienceExperienceUpdateWithoutSessionInput;
  where: SessionAudienceExperienceExtendedWhereUniqueInput;
};

export type SessionAudienceExperienceUpdateWithoutCoachAudienceExperienceInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionAudienceExperienceSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionAudienceExperienceUpdateWithoutSessionInput = {
  audienceExperience?: InputMaybe<SessionAudienceExperienceAudienceExperienceUpdateNestedInput>;
  audienceExperienceId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionAudienceExperienceUpsertWithWhereUniqueWithoutCoachAudienceExperienceInput = {
  create: SessionAudienceExperienceCreateWithoutCoachAudienceExperienceInput;
  update: SessionAudienceExperienceUpdateWithoutCoachAudienceExperienceInput;
  where: SessionAudienceExperienceExtendedWhereUniqueInput;
};

export type SessionAudienceExperienceUpsertWithWhereUniqueWithoutSessionInput = {
  create: SessionAudienceExperienceCreateWithoutSessionInput;
  update: SessionAudienceExperienceUpdateWithoutSessionInput;
  where: SessionAudienceExperienceExtendedWhereUniqueInput;
};

export type SessionAudienceExperienceUpsertWithoutCoachAudienceExperienceInput = {
  create: SessionAudienceExperienceCreateWithoutCoachAudienceExperienceInput;
  update: SessionAudienceExperienceUpdateWithoutCoachAudienceExperienceInput;
};

export type SessionAudienceExperienceUpsertWithoutSessionInput = {
  create: SessionAudienceExperienceCreateWithoutSessionInput;
  update: SessionAudienceExperienceUpdateWithoutSessionInput;
};

export type SessionAudienceExperienceWhereInput = {
  AND?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  NOT?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  OR?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  audienceExperience?: InputMaybe<CoachAudienceExperienceWhereInput>;
  audienceExperienceId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  sessionId?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionAudienceExperienceWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  NOT?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  OR?: InputMaybe<Array<SessionAudienceExperienceWhereInput>>;
  audienceExperience?: InputMaybe<CoachAudienceExperienceWhereInput>;
  audienceExperienceId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionAudienceExperienceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_audienceExperienceId?: InputMaybe<SessionIdAudienceExperienceIdFieldsInput>;
};

export type SessionAudienceExperiencesCreateNestedInput = {
  connect?: InputMaybe<Array<SessionAudienceExperienceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionAudienceExperienceConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionAudienceExperienceCreateWithoutSessionInput>>;
};

export type SessionAudienceExperiencesUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionAudienceExperienceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionAudienceExperienceConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionAudienceExperienceCreateWithoutSessionInput>>;
  delete?: InputMaybe<Array<SessionAudienceExperienceExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionAudienceExperienceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionAudienceExperienceExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionAudienceExperienceWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionAudienceExperienceUpdateWithWhereUniqueWithoutSessionInput>>;
  updateMany?: InputMaybe<Array<SessionAudienceExperienceUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionAudienceExperienceUpsertWithWhereUniqueWithoutSessionInput>>;
};

export type SessionCategoriesCreateNestedInput = {
  connect?: InputMaybe<Array<SessionCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionCategoryConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionCategoryCreateWithoutSessionInput>>;
};

export type SessionCategoriesUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionCategoryConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionCategoryCreateWithoutSessionInput>>;
  delete?: InputMaybe<Array<SessionCategoryExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionCategoryExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionCategoryUpdateWithWhereUniqueWithoutSessionInput>>;
  updateMany?: InputMaybe<Array<SessionCategoryUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionCategoryUpsertWithWhereUniqueWithoutSessionInput>>;
};

export type SessionCategory = {
  __typename?: 'SessionCategory';
  category: CoachCategory;
  categoryId: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  sessions: Session;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type SessionCategoryCategoryCreateNestedInput = {
  connect?: InputMaybe<CoachCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCategoryConnectOrCreateWithoutSessionCategoryInput>;
  create?: InputMaybe<CoachCategoryCreateWithoutSessionCategoryInput>;
};

export type SessionCategoryCategoryUpdateNestedInput = {
  connect?: InputMaybe<CoachCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCategoryConnectOrCreateWithoutSessionCategoryInput>;
  create?: InputMaybe<CoachCategoryCreateWithoutSessionCategoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachCategoryUpdateWithoutSessionCategoryInput>;
  upsert?: InputMaybe<CoachCategoryUpsertWithoutSessionCategoryInput>;
};

export type SessionCategoryConnectOrCreateWithoutCoachCategoryInput = {
  create: SessionCategoryCreateWithoutCoachCategoryInput;
  where: SessionCategoryWhereUniqueInput;
};

export type SessionCategoryConnectOrCreateWithoutSessionInput = {
  create: SessionCategoryCreateWithoutSessionInput;
  where: SessionCategoryWhereUniqueInput;
};

export type SessionCategoryCreateInput = {
  category?: InputMaybe<SessionCategoryCategoryCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionCategorySessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionCategoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionCategoryCreateWithoutCoachCategoryInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionCategorySessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionCategoryCreateWithoutSessionInput = {
  category?: InputMaybe<SessionCategoryCategoryCreateNestedInput>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionCategoryExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<SessionCategoryWhereInput>>;
  NOT?: InputMaybe<Array<SessionCategoryWhereInput>>;
  OR?: InputMaybe<Array<SessionCategoryWhereInput>>;
  category?: InputMaybe<CoachCategoryWhereInput>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<StringFilter>;
  sessionId_categoryId?: InputMaybe<SessionIdCategoryIdFieldsInput>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionCategoryFilter = {
  every?: InputMaybe<SessionCategoryWhereInputWithoutNullables>;
  none?: InputMaybe<SessionCategoryWhereInputWithoutNullables>;
  some?: InputMaybe<SessionCategoryWhereInputWithoutNullables>;
};

export type SessionCategoryOrderByInput = {
  category?: InputMaybe<CoachCategoryOrderByInput>;
  categoryId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  sessionId?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type SessionCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<SessionCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionCategoryScalarWhereInput>>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionCategorySessionsCreateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionCategoryInput>;
  create?: InputMaybe<SessionCreateWithoutSessionCategoryInput>;
};

export type SessionCategorySessionsUpdateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionCategoryInput>;
  create?: InputMaybe<SessionCreateWithoutSessionCategoryInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionUpdateWithoutSessionCategoryInput>;
  upsert?: InputMaybe<SessionUpsertWithoutSessionCategoryInput>;
};

export type SessionCategoryUpdateInput = {
  category?: InputMaybe<SessionCategoryCategoryUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionCategorySessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionCategoryUpdateManyInput = {
  data: SessionCategoryUpdateInput;
  where: SessionCategoryScalarWhereInput;
};

export type SessionCategoryUpdateWithWhereUniqueWithoutCoachCategoryInput = {
  data: SessionCategoryUpdateWithoutCoachCategoryInput;
  where: SessionCategoryExtendedWhereUniqueInput;
};

export type SessionCategoryUpdateWithWhereUniqueWithoutSessionInput = {
  data: SessionCategoryUpdateWithoutSessionInput;
  where: SessionCategoryExtendedWhereUniqueInput;
};

export type SessionCategoryUpdateWithoutCoachCategoryInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionCategorySessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionCategoryUpdateWithoutSessionInput = {
  category?: InputMaybe<SessionCategoryCategoryUpdateNestedInput>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionCategoryUpsertWithWhereUniqueWithoutCoachCategoryInput = {
  create: SessionCategoryCreateWithoutCoachCategoryInput;
  update: SessionCategoryUpdateWithoutCoachCategoryInput;
  where: SessionCategoryExtendedWhereUniqueInput;
};

export type SessionCategoryUpsertWithWhereUniqueWithoutSessionInput = {
  create: SessionCategoryCreateWithoutSessionInput;
  update: SessionCategoryUpdateWithoutSessionInput;
  where: SessionCategoryExtendedWhereUniqueInput;
};

export type SessionCategoryUpsertWithoutCoachCategoryInput = {
  create: SessionCategoryCreateWithoutCoachCategoryInput;
  update: SessionCategoryUpdateWithoutCoachCategoryInput;
};

export type SessionCategoryUpsertWithoutSessionInput = {
  create: SessionCategoryCreateWithoutSessionInput;
  update: SessionCategoryUpdateWithoutSessionInput;
};

export type SessionCategoryWhereInput = {
  AND?: InputMaybe<Array<SessionCategoryWhereInput>>;
  NOT?: InputMaybe<Array<SessionCategoryWhereInput>>;
  OR?: InputMaybe<Array<SessionCategoryWhereInput>>;
  category?: InputMaybe<CoachCategoryWhereInput>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  sessionId?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionCategoryWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<SessionCategoryWhereInput>>;
  NOT?: InputMaybe<Array<SessionCategoryWhereInput>>;
  OR?: InputMaybe<Array<SessionCategoryWhereInput>>;
  category?: InputMaybe<CoachCategoryWhereInput>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_categoryId?: InputMaybe<SessionIdCategoryIdFieldsInput>;
};

export type SessionCoachCreateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<CoachCreateWithoutSessionInput>;
};

export type SessionCoachUpdateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<CoachCreateWithoutSessionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachUpdateWithoutSessionInput>;
  upsert?: InputMaybe<CoachUpsertWithoutSessionInput>;
};

export type SessionConnectOrCreateWithoutCoachEventInput = {
  create: SessionCreateWithoutCoachEventInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutCoachInput = {
  create: SessionCreateWithoutCoachInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutSessionApproachInput = {
  create: SessionCreateWithoutSessionApproachInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutSessionAudienceExperienceInput = {
  create: SessionCreateWithoutSessionAudienceExperienceInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutSessionCategoryInput = {
  create: SessionCreateWithoutSessionCategoryInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutSessionPackageInput = {
  create: SessionCreateWithoutSessionPackageInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutSessionPreCallInput = {
  create: SessionCreateWithoutSessionPreCallInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutSessionReviewInput = {
  create: SessionCreateWithoutSessionReviewInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutSessionSpecialismInput = {
  create: SessionCreateWithoutSessionSpecialismInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutTeamMemberInput = {
  create: SessionCreateWithoutTeamMemberInput;
  where: SessionWhereUniqueInput;
};

export type SessionConnectOrCreateWithoutUserPackageInput = {
  create: SessionCreateWithoutUserPackageInput;
  where: SessionWhereUniqueInput;
};

export type SessionCreateInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
};

export type SessionCreateManyInput = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionCreateWithoutCoachEventInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutCoachInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutSessionApproachInput = {
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutSessionAudienceExperienceInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutSessionCategoryInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutSessionPackageInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutSessionPreCallInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutSessionReviewInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutSessionSpecialismInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutTeamMemberInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageCreateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionCreateWithoutUserPackageInput = {
  approaches?: InputMaybe<SessionApproachesCreateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesCreateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesCreateNestedInput>;
  coach?: InputMaybe<SessionCoachCreateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventCreateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesCreateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallCreateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewCreateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsCreateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberCreateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionEventCreateNestedInput = {
  connect?: InputMaybe<CoachEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachEventConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<CoachEventCreateWithoutSessionInput>;
};

export type SessionEventUpdateNestedInput = {
  connect?: InputMaybe<CoachEventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachEventConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<CoachEventCreateWithoutSessionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachEventUpdateWithoutSessionInput>;
  upsert?: InputMaybe<CoachEventUpsertWithoutSessionInput>;
};

export type SessionExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<SessionWhereInput>>;
  NOT?: InputMaybe<Array<SessionWhereInput>>;
  OR?: InputMaybe<Array<SessionWhereInput>>;
  approaches?: InputMaybe<SessionApproachFilter>;
  audienceExperiences?: InputMaybe<SessionAudienceExperienceFilter>;
  cancellationReason?: InputMaybe<StringNullableFilter>;
  cancelledAt?: InputMaybe<AwsDateTimeNullableFilter>;
  categories?: InputMaybe<SessionCategoryFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachCategoryId?: InputMaybe<StringNullableFilter>;
  coachEventId?: InputMaybe<StringNullableFilter>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  event?: InputMaybe<CoachEventWhereInput>;
  externalId?: InputMaybe<StringNullableFilter>;
  googleEventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<StringNullableFilter>;
  noShow?: InputMaybe<BooleanNullableFilter>;
  packages?: InputMaybe<SessionPackageFilter>;
  sessionPreCall?: InputMaybe<SessionPreCallWhereInput>;
  sessionReview?: InputMaybe<SessionReviewWhereInput>;
  specialisms?: InputMaybe<SessionSpecialismFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  state?: InputMaybe<SessionStateEnumFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  teamMemberId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userPackage?: InputMaybe<UserPackageWhereInput>;
  userPackageId?: InputMaybe<StringNullableFilter>;
};

export type SessionFilter = {
  every?: InputMaybe<SessionWhereInputWithoutNullables>;
  none?: InputMaybe<SessionWhereInputWithoutNullables>;
  some?: InputMaybe<SessionWhereInputWithoutNullables>;
};

export type SessionIdApproachIdFieldsInput = {
  approachId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type SessionIdAudienceExperienceIdFieldsInput = {
  audienceExperienceId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type SessionIdCategoryIdFieldsInput = {
  categoryId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type SessionIdPackageIdFieldsInput = {
  packageId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type SessionIdSpecialismIdFieldsInput = {
  sessionId: Scalars['String']['input'];
  specialismId: Scalars['String']['input'];
};

export type SessionOrderByInput = {
  approaches?: InputMaybe<SessionApproachOrderByInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperienceOrderByInput>;
  cancellationReason?: InputMaybe<OrderByArg>;
  cancelledAt?: InputMaybe<OrderByArg>;
  categories?: InputMaybe<SessionCategoryOrderByInput>;
  coach?: InputMaybe<CoachOrderByInput>;
  coachCategoryId?: InputMaybe<OrderByArg>;
  coachEventId?: InputMaybe<OrderByArg>;
  coachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  endsAt?: InputMaybe<OrderByArg>;
  event?: InputMaybe<CoachEventOrderByInput>;
  externalId?: InputMaybe<OrderByArg>;
  googleEventId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  location?: InputMaybe<OrderByArg>;
  noShow?: InputMaybe<OrderByArg>;
  packages?: InputMaybe<SessionPackageOrderByInput>;
  sessionPreCall?: InputMaybe<SessionPreCallOrderByInput>;
  sessionReview?: InputMaybe<SessionReviewOrderByInput>;
  specialisms?: InputMaybe<SessionSpecialismOrderByInput>;
  startsAt?: InputMaybe<OrderByArg>;
  state?: InputMaybe<OrderByArg>;
  teamMember?: InputMaybe<TeamMemberOrderByInput>;
  teamMemberId?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  userPackage?: InputMaybe<UserPackageOrderByInput>;
  userPackageId?: InputMaybe<OrderByArg>;
};

export type SessionPackage = {
  __typename?: 'SessionPackage';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  package: CoachPackage;
  packageId: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  sessions: Session;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type SessionPackageConnectOrCreateWithoutCoachPackageInput = {
  create: SessionPackageCreateWithoutCoachPackageInput;
  where: SessionPackageWhereUniqueInput;
};

export type SessionPackageConnectOrCreateWithoutSessionInput = {
  create: SessionPackageCreateWithoutSessionInput;
  where: SessionPackageWhereUniqueInput;
};

export type SessionPackageCreateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<SessionPackagePackageCreateNestedInput>;
  sessions?: InputMaybe<SessionPackageSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPackageCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPackageCreateWithoutCoachPackageInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionPackageSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPackageCreateWithoutSessionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<SessionPackagePackageCreateNestedInput>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPackageExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<SessionPackageWhereInput>>;
  NOT?: InputMaybe<Array<SessionPackageWhereInput>>;
  OR?: InputMaybe<Array<SessionPackageWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<CoachPackageWhereInput>;
  packageId?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  sessionId_packageId?: InputMaybe<SessionIdPackageIdFieldsInput>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionPackageFilter = {
  every?: InputMaybe<SessionPackageWhereInputWithoutNullables>;
  none?: InputMaybe<SessionPackageWhereInputWithoutNullables>;
  some?: InputMaybe<SessionPackageWhereInputWithoutNullables>;
};

export type SessionPackageOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  package?: InputMaybe<CoachPackageOrderByInput>;
  packageId?: InputMaybe<OrderByArg>;
  sessionId?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type SessionPackagePackageCreateNestedInput = {
  connect?: InputMaybe<CoachPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachPackageConnectOrCreateWithoutSessionPackageInput>;
  create?: InputMaybe<CoachPackageCreateWithoutSessionPackageInput>;
};

export type SessionPackagePackageUpdateNestedInput = {
  connect?: InputMaybe<CoachPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachPackageConnectOrCreateWithoutSessionPackageInput>;
  create?: InputMaybe<CoachPackageCreateWithoutSessionPackageInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachPackageUpdateWithoutSessionPackageInput>;
  upsert?: InputMaybe<CoachPackageUpsertWithoutSessionPackageInput>;
};

export type SessionPackageScalarWhereInput = {
  AND?: InputMaybe<Array<SessionPackageScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionPackageScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionPackageScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  packageId?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionPackageSessionsCreateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionPackageInput>;
  create?: InputMaybe<SessionCreateWithoutSessionPackageInput>;
};

export type SessionPackageSessionsUpdateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionPackageInput>;
  create?: InputMaybe<SessionCreateWithoutSessionPackageInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionUpdateWithoutSessionPackageInput>;
  upsert?: InputMaybe<SessionUpsertWithoutSessionPackageInput>;
};

export type SessionPackageUpdateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<SessionPackagePackageUpdateNestedInput>;
  sessions?: InputMaybe<SessionPackageSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPackageUpdateManyInput = {
  data: SessionPackageUpdateInput;
  where: SessionPackageScalarWhereInput;
};

export type SessionPackageUpdateWithWhereUniqueWithoutCoachPackageInput = {
  data: SessionPackageUpdateWithoutCoachPackageInput;
  where: SessionPackageExtendedWhereUniqueInput;
};

export type SessionPackageUpdateWithWhereUniqueWithoutSessionInput = {
  data: SessionPackageUpdateWithoutSessionInput;
  where: SessionPackageExtendedWhereUniqueInput;
};

export type SessionPackageUpdateWithoutCoachPackageInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionPackageSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPackageUpdateWithoutSessionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  package?: InputMaybe<SessionPackagePackageUpdateNestedInput>;
  packageId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPackageUpsertWithWhereUniqueWithoutCoachPackageInput = {
  create: SessionPackageCreateWithoutCoachPackageInput;
  update: SessionPackageUpdateWithoutCoachPackageInput;
  where: SessionPackageExtendedWhereUniqueInput;
};

export type SessionPackageUpsertWithWhereUniqueWithoutSessionInput = {
  create: SessionPackageCreateWithoutSessionInput;
  update: SessionPackageUpdateWithoutSessionInput;
  where: SessionPackageExtendedWhereUniqueInput;
};

export type SessionPackageUpsertWithoutCoachPackageInput = {
  create: SessionPackageCreateWithoutCoachPackageInput;
  update: SessionPackageUpdateWithoutCoachPackageInput;
};

export type SessionPackageUpsertWithoutSessionInput = {
  create: SessionPackageCreateWithoutSessionInput;
  update: SessionPackageUpdateWithoutSessionInput;
};

export type SessionPackageWhereInput = {
  AND?: InputMaybe<Array<SessionPackageWhereInput>>;
  NOT?: InputMaybe<Array<SessionPackageWhereInput>>;
  OR?: InputMaybe<Array<SessionPackageWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  package?: InputMaybe<CoachPackageWhereInput>;
  packageId?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionPackageWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<SessionPackageWhereInput>>;
  NOT?: InputMaybe<Array<SessionPackageWhereInput>>;
  OR?: InputMaybe<Array<SessionPackageWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  package?: InputMaybe<CoachPackageWhereInput>;
  packageId?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionWhereInput>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionPackageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_packageId?: InputMaybe<SessionIdPackageIdFieldsInput>;
};

export type SessionPackagesCreateNestedInput = {
  connect?: InputMaybe<Array<SessionPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionPackageConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionPackageCreateWithoutSessionInput>>;
};

export type SessionPackagesUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionPackageConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionPackageCreateWithoutSessionInput>>;
  delete?: InputMaybe<Array<SessionPackageExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionPackageExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionPackageUpdateWithWhereUniqueWithoutSessionInput>>;
  updateMany?: InputMaybe<Array<SessionPackageUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionPackageUpsertWithWhereUniqueWithoutSessionInput>>;
};

export type SessionPreCall = {
  __typename?: 'SessionPreCall';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  session?: Maybe<Session>;
  sessionId: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type SessionPreCallConnectOrCreateWithoutSessionInput = {
  create: SessionPreCallCreateWithoutSessionInput;
  where: SessionPreCallWhereUniqueInput;
};

export type SessionPreCallCreateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Int']['input'];
  session?: InputMaybe<SessionPreCallSessionCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPreCallCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPreCallCreateWithoutSessionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPreCallExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<SessionPreCallWhereInput>>;
  NOT?: InputMaybe<Array<SessionPreCallWhereInput>>;
  OR?: InputMaybe<Array<SessionPreCallWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<IntFilter>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionPreCallFilter = {
  every?: InputMaybe<SessionPreCallWhereInputWithoutNullables>;
  none?: InputMaybe<SessionPreCallWhereInputWithoutNullables>;
  some?: InputMaybe<SessionPreCallWhereInputWithoutNullables>;
};

export type SessionPreCallOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  score?: InputMaybe<OrderByArg>;
  session?: InputMaybe<SessionOrderByInput>;
  sessionId?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type SessionPreCallScalarWhereInput = {
  AND?: InputMaybe<Array<SessionPreCallScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionPreCallScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionPreCallScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  score?: InputMaybe<IntFilter>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionPreCallSessionCreateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionPreCallInput>;
  create?: InputMaybe<SessionCreateWithoutSessionPreCallInput>;
};

export type SessionPreCallSessionUpdateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionPreCallInput>;
  create?: InputMaybe<SessionCreateWithoutSessionPreCallInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionUpdateWithoutSessionPreCallInput>;
  upsert?: InputMaybe<SessionUpsertWithoutSessionPreCallInput>;
};

export type SessionPreCallUpdateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  session?: InputMaybe<SessionPreCallSessionUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPreCallUpdateManyInput = {
  data: SessionPreCallUpdateInput;
  where: SessionPreCallScalarWhereInput;
};

export type SessionPreCallUpdateWithWhereUniqueWithoutSessionInput = {
  data: SessionPreCallUpdateWithoutSessionInput;
  where: SessionPreCallExtendedWhereUniqueInput;
};

export type SessionPreCallUpdateWithoutSessionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionPreCallUpsertWithWhereUniqueWithoutSessionInput = {
  create: SessionPreCallCreateWithoutSessionInput;
  update: SessionPreCallUpdateWithoutSessionInput;
  where: SessionPreCallExtendedWhereUniqueInput;
};

export type SessionPreCallUpsertWithoutSessionInput = {
  create: SessionPreCallCreateWithoutSessionInput;
  update: SessionPreCallUpdateWithoutSessionInput;
};

export type SessionPreCallWhereInput = {
  AND?: InputMaybe<Array<SessionPreCallWhereInput>>;
  NOT?: InputMaybe<Array<SessionPreCallWhereInput>>;
  OR?: InputMaybe<Array<SessionPreCallWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  score?: InputMaybe<IntFilter>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionPreCallWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<SessionPreCallWhereInput>>;
  NOT?: InputMaybe<Array<SessionPreCallWhereInput>>;
  OR?: InputMaybe<Array<SessionPreCallWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  score?: InputMaybe<IntFilter>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionPreCallWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionReview = {
  __typename?: 'SessionReview';
  coachRating: Scalars['Int']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  extraSupport?: Maybe<Scalars['String']['output']>;
  feelingScore: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  nps: Scalars['Int']['output'];
  session?: Maybe<Session>;
  sessionId: Scalars['String']['output'];
  testimonial?: Maybe<Scalars['String']['output']>;
  tookPlace: Scalars['Boolean']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type SessionReviewConnectOrCreateWithoutSessionInput = {
  create: SessionReviewCreateWithoutSessionInput;
  where: SessionReviewWhereUniqueInput;
};

export type SessionReviewCreateInput = {
  coachRating: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  extraSupport?: InputMaybe<Scalars['String']['input']>;
  feelingScore: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  nps: Scalars['Int']['input'];
  session?: InputMaybe<SessionReviewSessionCreateNestedInput>;
  testimonial?: InputMaybe<Scalars['String']['input']>;
  tookPlace: Scalars['Boolean']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionReviewCreateManyInput = {
  coachRating: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  extraSupport?: InputMaybe<Scalars['String']['input']>;
  feelingScore: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  nps: Scalars['Int']['input'];
  testimonial?: InputMaybe<Scalars['String']['input']>;
  tookPlace: Scalars['Boolean']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionReviewCreateWithoutSessionInput = {
  coachRating: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  extraSupport?: InputMaybe<Scalars['String']['input']>;
  feelingScore: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  nps: Scalars['Int']['input'];
  testimonial?: InputMaybe<Scalars['String']['input']>;
  tookPlace: Scalars['Boolean']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionReviewExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<SessionReviewWhereInput>>;
  NOT?: InputMaybe<Array<SessionReviewWhereInput>>;
  OR?: InputMaybe<Array<SessionReviewWhereInput>>;
  coachRating?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  extraSupport?: InputMaybe<StringNullableFilter>;
  feelingScore?: InputMaybe<IntFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<BooleanNullableFilter>;
  nps?: InputMaybe<IntFilter>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  testimonial?: InputMaybe<StringNullableFilter>;
  tookPlace?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionReviewFilter = {
  every?: InputMaybe<SessionReviewWhereInputWithoutNullables>;
  none?: InputMaybe<SessionReviewWhereInputWithoutNullables>;
  some?: InputMaybe<SessionReviewWhereInputWithoutNullables>;
};

export type SessionReviewOrderByInput = {
  coachRating?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  extraSupport?: InputMaybe<OrderByArg>;
  feelingScore?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  isHidden?: InputMaybe<OrderByArg>;
  nps?: InputMaybe<OrderByArg>;
  session?: InputMaybe<SessionOrderByInput>;
  sessionId?: InputMaybe<OrderByArg>;
  testimonial?: InputMaybe<OrderByArg>;
  tookPlace?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type SessionReviewScalarWhereInput = {
  AND?: InputMaybe<Array<SessionReviewScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionReviewScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionReviewScalarWhereInput>>;
  coachRating?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  extraSupport?: InputMaybe<StringNullableFilter>;
  feelingScore?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isHidden?: InputMaybe<BooleanNullableFilter>;
  nps?: InputMaybe<IntFilter>;
  sessionId?: InputMaybe<StringFilter>;
  testimonial?: InputMaybe<StringNullableFilter>;
  tookPlace?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionReviewSessionCreateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionReviewInput>;
  create?: InputMaybe<SessionCreateWithoutSessionReviewInput>;
};

export type SessionReviewSessionUpdateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionReviewInput>;
  create?: InputMaybe<SessionCreateWithoutSessionReviewInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionUpdateWithoutSessionReviewInput>;
  upsert?: InputMaybe<SessionUpsertWithoutSessionReviewInput>;
};

export type SessionReviewUpdateInput = {
  coachRating?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  extraSupport?: InputMaybe<Scalars['String']['input']>;
  feelingScore?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  nps?: InputMaybe<Scalars['Int']['input']>;
  session?: InputMaybe<SessionReviewSessionUpdateNestedInput>;
  testimonial?: InputMaybe<Scalars['String']['input']>;
  tookPlace?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionReviewUpdateManyInput = {
  data: SessionReviewUpdateInput;
  where: SessionReviewScalarWhereInput;
};

export type SessionReviewUpdateWithWhereUniqueWithoutSessionInput = {
  data: SessionReviewUpdateWithoutSessionInput;
  where: SessionReviewExtendedWhereUniqueInput;
};

export type SessionReviewUpdateWithoutSessionInput = {
  coachRating?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  extraSupport?: InputMaybe<Scalars['String']['input']>;
  feelingScore?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  nps?: InputMaybe<Scalars['Int']['input']>;
  testimonial?: InputMaybe<Scalars['String']['input']>;
  tookPlace?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionReviewUpsertWithWhereUniqueWithoutSessionInput = {
  create: SessionReviewCreateWithoutSessionInput;
  update: SessionReviewUpdateWithoutSessionInput;
  where: SessionReviewExtendedWhereUniqueInput;
};

export type SessionReviewUpsertWithoutSessionInput = {
  create: SessionReviewCreateWithoutSessionInput;
  update: SessionReviewUpdateWithoutSessionInput;
};

export type SessionReviewWhereInput = {
  AND?: InputMaybe<Array<SessionReviewWhereInput>>;
  NOT?: InputMaybe<Array<SessionReviewWhereInput>>;
  OR?: InputMaybe<Array<SessionReviewWhereInput>>;
  coachRating?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  extraSupport?: InputMaybe<StringNullableFilter>;
  feelingScore?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isHidden?: InputMaybe<BooleanNullableFilter>;
  isNot?: InputMaybe<NullArg>;
  nps?: InputMaybe<IntFilter>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<StringFilter>;
  testimonial?: InputMaybe<StringNullableFilter>;
  tookPlace?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionReviewWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<SessionReviewWhereInput>>;
  NOT?: InputMaybe<Array<SessionReviewWhereInput>>;
  OR?: InputMaybe<Array<SessionReviewWhereInput>>;
  coachRating?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  extraSupport?: InputMaybe<StringNullableFilter>;
  feelingScore?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  isHidden?: InputMaybe<BooleanNullableFilter>;
  nps?: InputMaybe<IntFilter>;
  session?: InputMaybe<SessionWhereInput>;
  sessionId?: InputMaybe<StringFilter>;
  testimonial?: InputMaybe<StringNullableFilter>;
  tookPlace?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionReviewWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionScalarWhereInput = {
  AND?: InputMaybe<Array<SessionScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionScalarWhereInput>>;
  cancellationReason?: InputMaybe<StringNullableFilter>;
  cancelledAt?: InputMaybe<AwsDateTimeNullableFilter>;
  coachCategoryId?: InputMaybe<StringNullableFilter>;
  coachEventId?: InputMaybe<StringNullableFilter>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  googleEventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringNullableFilter>;
  noShow?: InputMaybe<BooleanNullableFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  state?: InputMaybe<SessionStateEnumFilter>;
  teamMemberId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userPackageId?: InputMaybe<StringNullableFilter>;
};

export type SessionSessionPreCallCreateNestedInput = {
  connect?: InputMaybe<SessionPreCallWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionPreCallConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<SessionPreCallCreateWithoutSessionInput>;
};

export type SessionSessionPreCallUpdateNestedInput = {
  connect?: InputMaybe<SessionPreCallWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionPreCallConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<SessionPreCallCreateWithoutSessionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionPreCallUpdateWithoutSessionInput>;
  upsert?: InputMaybe<SessionPreCallUpsertWithoutSessionInput>;
};

export type SessionSessionReviewCreateNestedInput = {
  connect?: InputMaybe<SessionReviewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionReviewConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<SessionReviewCreateWithoutSessionInput>;
};

export type SessionSessionReviewUpdateNestedInput = {
  connect?: InputMaybe<SessionReviewWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionReviewConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<SessionReviewCreateWithoutSessionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionReviewUpdateWithoutSessionInput>;
  upsert?: InputMaybe<SessionReviewUpsertWithoutSessionInput>;
};

export type SessionSpecialism = {
  __typename?: 'SessionSpecialism';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  sessions: Session;
  specialism: CoachSpecialism;
  specialismId: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type SessionSpecialismConnectOrCreateWithoutCoachSpecialismInput = {
  create: SessionSpecialismCreateWithoutCoachSpecialismInput;
  where: SessionSpecialismWhereUniqueInput;
};

export type SessionSpecialismConnectOrCreateWithoutSessionInput = {
  create: SessionSpecialismCreateWithoutSessionInput;
  where: SessionSpecialismWhereUniqueInput;
};

export type SessionSpecialismCreateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionSpecialismSessionsCreateNestedInput>;
  specialism?: InputMaybe<SessionSpecialismSpecialismCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionSpecialismCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionSpecialismCreateWithoutCoachSpecialismInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionSpecialismSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionSpecialismCreateWithoutSessionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  specialism?: InputMaybe<SessionSpecialismSpecialismCreateNestedInput>;
  specialismId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionSpecialismExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  NOT?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  OR?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<StringFilter>;
  sessionId_specialismId?: InputMaybe<SessionIdSpecialismIdFieldsInput>;
  sessions?: InputMaybe<SessionWhereInput>;
  specialism?: InputMaybe<CoachSpecialismWhereInput>;
  specialismId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionSpecialismFilter = {
  every?: InputMaybe<SessionSpecialismWhereInputWithoutNullables>;
  none?: InputMaybe<SessionSpecialismWhereInputWithoutNullables>;
  some?: InputMaybe<SessionSpecialismWhereInputWithoutNullables>;
};

export type SessionSpecialismOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  sessionId?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionOrderByInput>;
  specialism?: InputMaybe<CoachSpecialismOrderByInput>;
  specialismId?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
};

export type SessionSpecialismScalarWhereInput = {
  AND?: InputMaybe<Array<SessionSpecialismScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionSpecialismScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionSpecialismScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  specialismId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionSpecialismSessionsCreateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionSpecialismInput>;
  create?: InputMaybe<SessionCreateWithoutSessionSpecialismInput>;
};

export type SessionSpecialismSessionsUpdateNestedInput = {
  connect?: InputMaybe<SessionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SessionConnectOrCreateWithoutSessionSpecialismInput>;
  create?: InputMaybe<SessionCreateWithoutSessionSpecialismInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SessionUpdateWithoutSessionSpecialismInput>;
  upsert?: InputMaybe<SessionUpsertWithoutSessionSpecialismInput>;
};

export type SessionSpecialismSpecialismCreateNestedInput = {
  connect?: InputMaybe<CoachSpecialismWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachSpecialismConnectOrCreateWithoutSessionSpecialismInput>;
  create?: InputMaybe<CoachSpecialismCreateWithoutSessionSpecialismInput>;
};

export type SessionSpecialismSpecialismUpdateNestedInput = {
  connect?: InputMaybe<CoachSpecialismWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachSpecialismConnectOrCreateWithoutSessionSpecialismInput>;
  create?: InputMaybe<CoachSpecialismCreateWithoutSessionSpecialismInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachSpecialismUpdateWithoutSessionSpecialismInput>;
  upsert?: InputMaybe<CoachSpecialismUpsertWithoutSessionSpecialismInput>;
};

export type SessionSpecialismUpdateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionSpecialismSessionsUpdateNestedInput>;
  specialism?: InputMaybe<SessionSpecialismSpecialismUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionSpecialismUpdateManyInput = {
  data: SessionSpecialismUpdateInput;
  where: SessionSpecialismScalarWhereInput;
};

export type SessionSpecialismUpdateWithWhereUniqueWithoutCoachSpecialismInput = {
  data: SessionSpecialismUpdateWithoutCoachSpecialismInput;
  where: SessionSpecialismExtendedWhereUniqueInput;
};

export type SessionSpecialismUpdateWithWhereUniqueWithoutSessionInput = {
  data: SessionSpecialismUpdateWithoutSessionInput;
  where: SessionSpecialismExtendedWhereUniqueInput;
};

export type SessionSpecialismUpdateWithoutCoachSpecialismInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<SessionSpecialismSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionSpecialismUpdateWithoutSessionInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  specialism?: InputMaybe<SessionSpecialismSpecialismUpdateNestedInput>;
  specialismId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionSpecialismUpsertWithWhereUniqueWithoutCoachSpecialismInput = {
  create: SessionSpecialismCreateWithoutCoachSpecialismInput;
  update: SessionSpecialismUpdateWithoutCoachSpecialismInput;
  where: SessionSpecialismExtendedWhereUniqueInput;
};

export type SessionSpecialismUpsertWithWhereUniqueWithoutSessionInput = {
  create: SessionSpecialismCreateWithoutSessionInput;
  update: SessionSpecialismUpdateWithoutSessionInput;
  where: SessionSpecialismExtendedWhereUniqueInput;
};

export type SessionSpecialismUpsertWithoutCoachSpecialismInput = {
  create: SessionSpecialismCreateWithoutCoachSpecialismInput;
  update: SessionSpecialismUpdateWithoutCoachSpecialismInput;
};

export type SessionSpecialismUpsertWithoutSessionInput = {
  create: SessionSpecialismCreateWithoutSessionInput;
  update: SessionSpecialismUpdateWithoutSessionInput;
};

export type SessionSpecialismWhereInput = {
  AND?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  NOT?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  OR?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  sessionId?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionWhereInput>;
  specialism?: InputMaybe<CoachSpecialismWhereInput>;
  specialismId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionSpecialismWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  NOT?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  OR?: InputMaybe<Array<SessionSpecialismWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionId?: InputMaybe<StringFilter>;
  sessions?: InputMaybe<SessionWhereInput>;
  specialism?: InputMaybe<CoachSpecialismWhereInput>;
  specialismId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type SessionSpecialismWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_specialismId?: InputMaybe<SessionIdSpecialismIdFieldsInput>;
};

export type SessionSpecialismsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionSpecialismWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionSpecialismConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionSpecialismCreateWithoutSessionInput>>;
};

export type SessionSpecialismsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionSpecialismWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionSpecialismConnectOrCreateWithoutSessionInput>>;
  create?: InputMaybe<Array<SessionSpecialismCreateWithoutSessionInput>>;
  delete?: InputMaybe<Array<SessionSpecialismExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionSpecialismScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionSpecialismExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionSpecialismWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionSpecialismUpdateWithWhereUniqueWithoutSessionInput>>;
  updateMany?: InputMaybe<Array<SessionSpecialismUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionSpecialismUpsertWithWhereUniqueWithoutSessionInput>>;
};

export enum SessionState {
  Cancelled = 'CANCELLED',
  CancelledCoach = 'CANCELLED_COACH',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  ConfirmedCoach = 'CONFIRMED_COACH'
}

export type SessionStateEnumFilter = {
  equals?: InputMaybe<SessionState>;
  in?: InputMaybe<Array<SessionState>>;
  not?: InputMaybe<SessionStateEnumFilter>;
  notIn?: InputMaybe<Array<SessionState>>;
};

export type SessionStateEnumListFilter = {
  equals?: InputMaybe<Array<SessionState>>;
  has?: InputMaybe<SessionState>;
  hasEvery?: InputMaybe<Array<SessionState>>;
  hasSome?: InputMaybe<Array<SessionState>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SessionTeamMemberCreateNestedInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<TeamMemberCreateWithoutSessionInput>;
};

export type SessionTeamMemberUpdateNestedInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<TeamMemberCreateWithoutSessionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamMemberUpdateWithoutSessionInput>;
  upsert?: InputMaybe<TeamMemberUpsertWithoutSessionInput>;
};

export type SessionUpdateInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
};

export type SessionUpdateManyInput = {
  data: SessionUpdateInput;
  where: SessionScalarWhereInput;
};

export type SessionUpdateWithWhereUniqueWithoutCoachEventInput = {
  data: SessionUpdateWithoutCoachEventInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutCoachInput = {
  data: SessionUpdateWithoutCoachInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutSessionApproachInput = {
  data: SessionUpdateWithoutSessionApproachInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutSessionAudienceExperienceInput = {
  data: SessionUpdateWithoutSessionAudienceExperienceInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutSessionCategoryInput = {
  data: SessionUpdateWithoutSessionCategoryInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutSessionPackageInput = {
  data: SessionUpdateWithoutSessionPackageInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutSessionPreCallInput = {
  data: SessionUpdateWithoutSessionPreCallInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutSessionReviewInput = {
  data: SessionUpdateWithoutSessionReviewInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutSessionSpecialismInput = {
  data: SessionUpdateWithoutSessionSpecialismInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutTeamMemberInput = {
  data: SessionUpdateWithoutTeamMemberInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithWhereUniqueWithoutUserPackageInput = {
  data: SessionUpdateWithoutUserPackageInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpdateWithoutCoachEventInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutCoachInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutSessionApproachInput = {
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutSessionAudienceExperienceInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutSessionCategoryInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutSessionPackageInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutSessionPreCallInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutSessionReviewInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutSessionSpecialismInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutTeamMemberInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userPackage?: InputMaybe<SessionUserPackageUpdateNestedInput>;
  userPackageId?: InputMaybe<Scalars['String']['input']>;
};

export type SessionUpdateWithoutUserPackageInput = {
  approaches?: InputMaybe<SessionApproachesUpdateNestedInput>;
  audienceExperiences?: InputMaybe<SessionAudienceExperiencesUpdateNestedInput>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  cancelledAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  categories?: InputMaybe<SessionCategoriesUpdateNestedInput>;
  coach?: InputMaybe<SessionCoachUpdateNestedInput>;
  coachCategoryId?: InputMaybe<Scalars['String']['input']>;
  coachEventId?: InputMaybe<Scalars['String']['input']>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  event?: InputMaybe<SessionEventUpdateNestedInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  googleEventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  noShow?: InputMaybe<Scalars['Boolean']['input']>;
  packages?: InputMaybe<SessionPackagesUpdateNestedInput>;
  sessionPreCall?: InputMaybe<SessionSessionPreCallUpdateNestedInput>;
  sessionReview?: InputMaybe<SessionSessionReviewUpdateNestedInput>;
  specialisms?: InputMaybe<SessionSpecialismsUpdateNestedInput>;
  startsAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  state?: InputMaybe<SessionState>;
  teamMember?: InputMaybe<SessionTeamMemberUpdateNestedInput>;
  teamMemberId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SessionUpsertWithWhereUniqueWithoutCoachEventInput = {
  create: SessionCreateWithoutCoachEventInput;
  update: SessionUpdateWithoutCoachEventInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutCoachInput = {
  create: SessionCreateWithoutCoachInput;
  update: SessionUpdateWithoutCoachInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutSessionApproachInput = {
  create: SessionCreateWithoutSessionApproachInput;
  update: SessionUpdateWithoutSessionApproachInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutSessionAudienceExperienceInput = {
  create: SessionCreateWithoutSessionAudienceExperienceInput;
  update: SessionUpdateWithoutSessionAudienceExperienceInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutSessionCategoryInput = {
  create: SessionCreateWithoutSessionCategoryInput;
  update: SessionUpdateWithoutSessionCategoryInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutSessionPackageInput = {
  create: SessionCreateWithoutSessionPackageInput;
  update: SessionUpdateWithoutSessionPackageInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutSessionPreCallInput = {
  create: SessionCreateWithoutSessionPreCallInput;
  update: SessionUpdateWithoutSessionPreCallInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutSessionReviewInput = {
  create: SessionCreateWithoutSessionReviewInput;
  update: SessionUpdateWithoutSessionReviewInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutSessionSpecialismInput = {
  create: SessionCreateWithoutSessionSpecialismInput;
  update: SessionUpdateWithoutSessionSpecialismInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutTeamMemberInput = {
  create: SessionCreateWithoutTeamMemberInput;
  update: SessionUpdateWithoutTeamMemberInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithWhereUniqueWithoutUserPackageInput = {
  create: SessionCreateWithoutUserPackageInput;
  update: SessionUpdateWithoutUserPackageInput;
  where: SessionExtendedWhereUniqueInput;
};

export type SessionUpsertWithoutCoachEventInput = {
  create: SessionCreateWithoutCoachEventInput;
  update: SessionUpdateWithoutCoachEventInput;
};

export type SessionUpsertWithoutCoachInput = {
  create: SessionCreateWithoutCoachInput;
  update: SessionUpdateWithoutCoachInput;
};

export type SessionUpsertWithoutSessionApproachInput = {
  create: SessionCreateWithoutSessionApproachInput;
  update: SessionUpdateWithoutSessionApproachInput;
};

export type SessionUpsertWithoutSessionAudienceExperienceInput = {
  create: SessionCreateWithoutSessionAudienceExperienceInput;
  update: SessionUpdateWithoutSessionAudienceExperienceInput;
};

export type SessionUpsertWithoutSessionCategoryInput = {
  create: SessionCreateWithoutSessionCategoryInput;
  update: SessionUpdateWithoutSessionCategoryInput;
};

export type SessionUpsertWithoutSessionPackageInput = {
  create: SessionCreateWithoutSessionPackageInput;
  update: SessionUpdateWithoutSessionPackageInput;
};

export type SessionUpsertWithoutSessionPreCallInput = {
  create: SessionCreateWithoutSessionPreCallInput;
  update: SessionUpdateWithoutSessionPreCallInput;
};

export type SessionUpsertWithoutSessionReviewInput = {
  create: SessionCreateWithoutSessionReviewInput;
  update: SessionUpdateWithoutSessionReviewInput;
};

export type SessionUpsertWithoutSessionSpecialismInput = {
  create: SessionCreateWithoutSessionSpecialismInput;
  update: SessionUpdateWithoutSessionSpecialismInput;
};

export type SessionUpsertWithoutTeamMemberInput = {
  create: SessionCreateWithoutTeamMemberInput;
  update: SessionUpdateWithoutTeamMemberInput;
};

export type SessionUpsertWithoutUserPackageInput = {
  create: SessionCreateWithoutUserPackageInput;
  update: SessionUpdateWithoutUserPackageInput;
};

export type SessionUserPackageCreateNestedInput = {
  connect?: InputMaybe<UserPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserPackageConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<UserPackageCreateWithoutSessionInput>;
};

export type SessionUserPackageUpdateNestedInput = {
  connect?: InputMaybe<UserPackageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserPackageConnectOrCreateWithoutSessionInput>;
  create?: InputMaybe<UserPackageCreateWithoutSessionInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserPackageUpdateWithoutSessionInput>;
  upsert?: InputMaybe<UserPackageUpsertWithoutSessionInput>;
};

export type SessionWhereInput = {
  AND?: InputMaybe<Array<SessionWhereInput>>;
  NOT?: InputMaybe<Array<SessionWhereInput>>;
  OR?: InputMaybe<Array<SessionWhereInput>>;
  approaches?: InputMaybe<SessionApproachFilter>;
  audienceExperiences?: InputMaybe<SessionAudienceExperienceFilter>;
  cancellationReason?: InputMaybe<StringNullableFilter>;
  cancelledAt?: InputMaybe<AwsDateTimeNullableFilter>;
  categories?: InputMaybe<SessionCategoryFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachCategoryId?: InputMaybe<StringNullableFilter>;
  coachEventId?: InputMaybe<StringNullableFilter>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  event?: InputMaybe<CoachEventWhereInput>;
  externalId?: InputMaybe<StringNullableFilter>;
  googleEventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  location?: InputMaybe<StringNullableFilter>;
  noShow?: InputMaybe<BooleanNullableFilter>;
  packages?: InputMaybe<SessionPackageFilter>;
  sessionPreCall?: InputMaybe<SessionPreCallWhereInput>;
  sessionReview?: InputMaybe<SessionReviewWhereInput>;
  specialisms?: InputMaybe<SessionSpecialismFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  state?: InputMaybe<SessionStateEnumFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  teamMemberId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userPackage?: InputMaybe<UserPackageWhereInput>;
  userPackageId?: InputMaybe<StringNullableFilter>;
};

export type SessionWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<SessionWhereInput>>;
  NOT?: InputMaybe<Array<SessionWhereInput>>;
  OR?: InputMaybe<Array<SessionWhereInput>>;
  approaches?: InputMaybe<SessionApproachFilter>;
  audienceExperiences?: InputMaybe<SessionAudienceExperienceFilter>;
  cancellationReason?: InputMaybe<StringNullableFilter>;
  cancelledAt?: InputMaybe<AwsDateTimeNullableFilter>;
  categories?: InputMaybe<SessionCategoryFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachCategoryId?: InputMaybe<StringNullableFilter>;
  coachEventId?: InputMaybe<StringNullableFilter>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  event?: InputMaybe<CoachEventWhereInput>;
  externalId?: InputMaybe<StringNullableFilter>;
  googleEventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringNullableFilter>;
  noShow?: InputMaybe<BooleanNullableFilter>;
  packages?: InputMaybe<SessionPackageFilter>;
  sessionPreCall?: InputMaybe<SessionPreCallWhereInput>;
  sessionReview?: InputMaybe<SessionReviewWhereInput>;
  specialisms?: InputMaybe<SessionSpecialismFilter>;
  startsAt?: InputMaybe<AwsDateTimeNullableFilter>;
  state?: InputMaybe<SessionStateEnumFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  teamMemberId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userPackage?: InputMaybe<UserPackageWhereInput>;
  userPackageId?: InputMaybe<StringNullableFilter>;
};

export type SessionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  mode?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /**   Event triggered when a new BookingHold record is created. */
  onCreatedBookingHold: BookingHold;
  /**   Event triggered when a new CalendarSetting record is created. */
  onCreatedCalendarSetting: CalendarSetting;
  /**   Event triggered when a new Coach record is created. */
  onCreatedCoach: Coach;
  /**   Event triggered when a new CoachApproach record is created. */
  onCreatedCoachApproach: CoachApproach;
  /**   Event triggered when a new CoachAudienceExperience record is created. */
  onCreatedCoachAudienceExperience: CoachAudienceExperience;
  /**   Event triggered when a new CoachCategory record is created. */
  onCreatedCoachCategory: CoachCategory;
  /**   Event triggered when a new CoachEvent record is created. */
  onCreatedCoachEvent: CoachEvent;
  /**   Event triggered when a new CoachPackage record is created. */
  onCreatedCoachPackage: CoachPackage;
  /**   Event triggered when a new CoachReview record is created. */
  onCreatedCoachReview: CoachReview;
  /**   Event triggered when a new CoachSpecialism record is created. */
  onCreatedCoachSpecialism: CoachSpecialism;
  /**   Event triggered when a new Company record is created. */
  onCreatedCompany: Company;
  /**   Event triggered when a new CompanyAdmin record is created. */
  onCreatedCompanyAdmin: CompanyAdmin;
  /**   Event triggered when a new CompanyParent record is created. */
  onCreatedCompanyParent: CompanyParent;
  /**   Event triggered when a new Enquiry record is created. */
  onCreatedEnquiry: Enquiry;
  /**   Event triggered when a new EnquiryChoice record is created. */
  onCreatedEnquiryChoice: EnquiryChoice;
  /**   Event triggered when a new Feature record is created. */
  onCreatedFeature: Feature;
  /**   Event triggered when a new FeatureCompany record is created. */
  onCreatedFeatureCompany: FeatureCompany;
  /**   Event triggered when multiple new BookingHolds records are created. */
  onCreatedManyBookingHolds: BatchPayload;
  /**   Event triggered when multiple new CalendarSettings records are created. */
  onCreatedManyCalendarSettings: BatchPayload;
  /**   Event triggered when multiple new CoachApproaches records are created. */
  onCreatedManyCoachApproaches: BatchPayload;
  /**   Event triggered when multiple new CoachAudienceExperiences records are created. */
  onCreatedManyCoachAudienceExperiences: BatchPayload;
  /**   Event triggered when multiple new CoachCategories records are created. */
  onCreatedManyCoachCategories: BatchPayload;
  /**   Event triggered when multiple new CoachEvents records are created. */
  onCreatedManyCoachEvents: BatchPayload;
  /**   Event triggered when multiple new CoachPackages records are created. */
  onCreatedManyCoachPackages: BatchPayload;
  /**   Event triggered when multiple new CoachReviews records are created. */
  onCreatedManyCoachReviews: BatchPayload;
  /**   Event triggered when multiple new CoachSpecialisms records are created. */
  onCreatedManyCoachSpecialisms: BatchPayload;
  /**   Event triggered when multiple new Coaches records are created. */
  onCreatedManyCoaches: BatchPayload;
  /**   Event triggered when multiple new Companies records are created. */
  onCreatedManyCompanies: BatchPayload;
  /**   Event triggered when multiple new CompanyAdmins records are created. */
  onCreatedManyCompanyAdmins: BatchPayload;
  /**   Event triggered when multiple new CompanyParents records are created. */
  onCreatedManyCompanyParents: BatchPayload;
  /**   Event triggered when multiple new Enquiries records are created. */
  onCreatedManyEnquiries: BatchPayload;
  /**   Event triggered when multiple new EnquiryChoices records are created. */
  onCreatedManyEnquiryChoices: BatchPayload;
  /**   Event triggered when multiple new FeatureCompanies records are created. */
  onCreatedManyFeatureCompanies: BatchPayload;
  /**   Event triggered when multiple new Features records are created. */
  onCreatedManyFeatures: BatchPayload;
  /**   Event triggered when multiple new SessionApproaches records are created. */
  onCreatedManySessionApproaches: BatchPayload;
  /**   Event triggered when multiple new SessionAudienceExperiences records are created. */
  onCreatedManySessionAudienceExperiences: BatchPayload;
  /**   Event triggered when multiple new SessionCategories records are created. */
  onCreatedManySessionCategories: BatchPayload;
  /**   Event triggered when multiple new SessionPackages records are created. */
  onCreatedManySessionPackages: BatchPayload;
  /**   Event triggered when multiple new SessionPreCalls records are created. */
  onCreatedManySessionPreCalls: BatchPayload;
  /**   Event triggered when multiple new SessionReviews records are created. */
  onCreatedManySessionReviews: BatchPayload;
  /**   Event triggered when multiple new SessionSpecialisms records are created. */
  onCreatedManySessionSpecialisms: BatchPayload;
  /**   Event triggered when multiple new Sessions records are created. */
  onCreatedManySessions: BatchPayload;
  /**   Event triggered when multiple new TeamMemberOutcomes records are created. */
  onCreatedManyTeamMemberOutcomes: BatchPayload;
  /**   Event triggered when multiple new TeamMembers records are created. */
  onCreatedManyTeamMembers: BatchPayload;
  /**   Event triggered when multiple new UserOnboardingChoices records are created. */
  onCreatedManyUserOnboardingChoices: BatchPayload;
  /**   Event triggered when multiple new UserPackages records are created. */
  onCreatedManyUserPackages: BatchPayload;
  /**   Event triggered when multiple new UserSettings records are created. */
  onCreatedManyUserSettings: BatchPayload;
  /**   Event triggered when multiple new Users records are created. */
  onCreatedManyUsers: BatchPayload;
  /**   Event triggered when a new Session record is created. */
  onCreatedSession: Session;
  /**   Event triggered when a new SessionApproach record is created. */
  onCreatedSessionApproach: SessionApproach;
  /**   Event triggered when a new SessionAudienceExperience record is created. */
  onCreatedSessionAudienceExperience: SessionAudienceExperience;
  /**   Event triggered when a new SessionCategory record is created. */
  onCreatedSessionCategory: SessionCategory;
  /**   Event triggered when a new SessionPackage record is created. */
  onCreatedSessionPackage: SessionPackage;
  /**   Event triggered when a new SessionPreCall record is created. */
  onCreatedSessionPreCall: SessionPreCall;
  /**   Event triggered when a new SessionReview record is created. */
  onCreatedSessionReview: SessionReview;
  /**   Event triggered when a new SessionSpecialism record is created. */
  onCreatedSessionSpecialism: SessionSpecialism;
  /**   Event triggered when a new TeamMember record is created. */
  onCreatedTeamMember: TeamMember;
  /**   Event triggered when a new TeamMemberOutcome record is created. */
  onCreatedTeamMemberOutcome: TeamMemberOutcome;
  /**   Event triggered when a new User record is created. */
  onCreatedUser: User;
  /**   Event triggered when a new UserOnboardingChoice record is created. */
  onCreatedUserOnboardingChoice: UserOnboardingChoice;
  /**   Event triggered when a new UserPackage record is created. */
  onCreatedUserPackage: UserPackage;
  /**   Event triggered when a new UserSetting record is created. */
  onCreatedUserSetting: UserSetting;
  /**   Event triggered when an existing BookingHold record is deleted. */
  onDeletedBookingHold: BookingHold;
  /**   Event triggered when an existing CalendarSetting record is deleted. */
  onDeletedCalendarSetting: CalendarSetting;
  /**   Event triggered when an existing Coach record is deleted. */
  onDeletedCoach: Coach;
  /**   Event triggered when an existing CoachApproach record is deleted. */
  onDeletedCoachApproach: CoachApproach;
  /**   Event triggered when an existing CoachAudienceExperience record is deleted. */
  onDeletedCoachAudienceExperience: CoachAudienceExperience;
  /**   Event triggered when an existing CoachCategory record is deleted. */
  onDeletedCoachCategory: CoachCategory;
  /**   Event triggered when an existing CoachEvent record is deleted. */
  onDeletedCoachEvent: CoachEvent;
  /**   Event triggered when an existing CoachPackage record is deleted. */
  onDeletedCoachPackage: CoachPackage;
  /**   Event triggered when an existing CoachReview record is deleted. */
  onDeletedCoachReview: CoachReview;
  /**   Event triggered when an existing CoachSpecialism record is deleted. */
  onDeletedCoachSpecialism: CoachSpecialism;
  /**   Event triggered when an existing Company record is deleted. */
  onDeletedCompany: Company;
  /**   Event triggered when an existing CompanyAdmin record is deleted. */
  onDeletedCompanyAdmin: CompanyAdmin;
  /**   Event triggered when an existing CompanyParent record is deleted. */
  onDeletedCompanyParent: CompanyParent;
  /**   Event triggered when an existing Enquiry record is deleted. */
  onDeletedEnquiry: Enquiry;
  /**   Event triggered when an existing EnquiryChoice record is deleted. */
  onDeletedEnquiryChoice: EnquiryChoice;
  /**   Event triggered when an existing Feature record is deleted. */
  onDeletedFeature: Feature;
  /**   Event triggered when an existing FeatureCompany record is deleted. */
  onDeletedFeatureCompany: FeatureCompany;
  /**   Event triggered when multiple existing BookingHolds records are deleted. */
  onDeletedManyBookingHolds: BatchPayload;
  /**   Event triggered when multiple existing CalendarSettings records are deleted. */
  onDeletedManyCalendarSettings: BatchPayload;
  /**   Event triggered when multiple existing CoachApproaches records are deleted. */
  onDeletedManyCoachApproaches: BatchPayload;
  /**   Event triggered when multiple existing CoachAudienceExperiences records are deleted. */
  onDeletedManyCoachAudienceExperiences: BatchPayload;
  /**   Event triggered when multiple existing CoachCategories records are deleted. */
  onDeletedManyCoachCategories: BatchPayload;
  /**   Event triggered when multiple existing CoachEvents records are deleted. */
  onDeletedManyCoachEvents: BatchPayload;
  /**   Event triggered when multiple existing CoachPackages records are deleted. */
  onDeletedManyCoachPackages: BatchPayload;
  /**   Event triggered when multiple existing CoachReviews records are deleted. */
  onDeletedManyCoachReviews: BatchPayload;
  /**   Event triggered when multiple existing CoachSpecialisms records are deleted. */
  onDeletedManyCoachSpecialisms: BatchPayload;
  /**   Event triggered when multiple existing Coaches records are deleted. */
  onDeletedManyCoaches: BatchPayload;
  /**   Event triggered when multiple existing Companies records are deleted. */
  onDeletedManyCompanies: BatchPayload;
  /**   Event triggered when multiple existing CompanyAdmins records are deleted. */
  onDeletedManyCompanyAdmins: BatchPayload;
  /**   Event triggered when multiple existing CompanyParents records are deleted. */
  onDeletedManyCompanyParents: BatchPayload;
  /**   Event triggered when multiple existing Enquiries records are deleted. */
  onDeletedManyEnquiries: BatchPayload;
  /**   Event triggered when multiple existing EnquiryChoices records are deleted. */
  onDeletedManyEnquiryChoices: BatchPayload;
  /**   Event triggered when multiple existing FeatureCompanies records are deleted. */
  onDeletedManyFeatureCompanies: BatchPayload;
  /**   Event triggered when multiple existing Features records are deleted. */
  onDeletedManyFeatures: BatchPayload;
  /**   Event triggered when multiple existing SessionApproaches records are deleted. */
  onDeletedManySessionApproaches: BatchPayload;
  /**   Event triggered when multiple existing SessionAudienceExperiences records are deleted. */
  onDeletedManySessionAudienceExperiences: BatchPayload;
  /**   Event triggered when multiple existing SessionCategories records are deleted. */
  onDeletedManySessionCategories: BatchPayload;
  /**   Event triggered when multiple existing SessionPackages records are deleted. */
  onDeletedManySessionPackages: BatchPayload;
  /**   Event triggered when multiple existing SessionPreCalls records are deleted. */
  onDeletedManySessionPreCalls: BatchPayload;
  /**   Event triggered when multiple existing SessionReviews records are deleted. */
  onDeletedManySessionReviews: BatchPayload;
  /**   Event triggered when multiple existing SessionSpecialisms records are deleted. */
  onDeletedManySessionSpecialisms: BatchPayload;
  /**   Event triggered when multiple existing Sessions records are deleted. */
  onDeletedManySessions: BatchPayload;
  /**   Event triggered when multiple existing TeamMemberOutcomes records are deleted. */
  onDeletedManyTeamMemberOutcomes: BatchPayload;
  /**   Event triggered when multiple existing TeamMembers records are deleted. */
  onDeletedManyTeamMembers: BatchPayload;
  /**   Event triggered when multiple existing UserOnboardingChoices records are deleted. */
  onDeletedManyUserOnboardingChoices: BatchPayload;
  /**   Event triggered when multiple existing UserPackages records are deleted. */
  onDeletedManyUserPackages: BatchPayload;
  /**   Event triggered when multiple existing UserSettings records are deleted. */
  onDeletedManyUserSettings: BatchPayload;
  /**   Event triggered when multiple existing Users records are deleted. */
  onDeletedManyUsers: BatchPayload;
  /**   Event triggered when an existing Session record is deleted. */
  onDeletedSession: Session;
  /**   Event triggered when an existing SessionApproach record is deleted. */
  onDeletedSessionApproach: SessionApproach;
  /**   Event triggered when an existing SessionAudienceExperience record is deleted. */
  onDeletedSessionAudienceExperience: SessionAudienceExperience;
  /**   Event triggered when an existing SessionCategory record is deleted. */
  onDeletedSessionCategory: SessionCategory;
  /**   Event triggered when an existing SessionPackage record is deleted. */
  onDeletedSessionPackage: SessionPackage;
  /**   Event triggered when an existing SessionPreCall record is deleted. */
  onDeletedSessionPreCall: SessionPreCall;
  /**   Event triggered when an existing SessionReview record is deleted. */
  onDeletedSessionReview: SessionReview;
  /**   Event triggered when an existing SessionSpecialism record is deleted. */
  onDeletedSessionSpecialism: SessionSpecialism;
  /**   Event triggered when an existing TeamMember record is deleted. */
  onDeletedTeamMember: TeamMember;
  /**   Event triggered when an existing TeamMemberOutcome record is deleted. */
  onDeletedTeamMemberOutcome: TeamMemberOutcome;
  /**   Event triggered when an existing User record is deleted. */
  onDeletedUser: User;
  /**   Event triggered when an existing UserOnboardingChoice record is deleted. */
  onDeletedUserOnboardingChoice: UserOnboardingChoice;
  /**   Event triggered when an existing UserPackage record is deleted. */
  onDeletedUserPackage: UserPackage;
  /**   Event triggered when an existing UserSetting record is deleted. */
  onDeletedUserSetting: UserSetting;
  /**   Event triggered when a BookingHold record is either created, updated, or deleted. */
  onMutatedBookingHold: BookingHold;
  /**   Event triggered when a CalendarSetting record is either created, updated, or deleted. */
  onMutatedCalendarSetting: CalendarSetting;
  /**   Event triggered when a Coach record is either created, updated, or deleted. */
  onMutatedCoach: Coach;
  /**   Event triggered when a CoachApproach record is either created, updated, or deleted. */
  onMutatedCoachApproach: CoachApproach;
  /**   Event triggered when a CoachAudienceExperience record is either created, updated, or deleted. */
  onMutatedCoachAudienceExperience: CoachAudienceExperience;
  /**   Event triggered when a CoachCategory record is either created, updated, or deleted. */
  onMutatedCoachCategory: CoachCategory;
  /**   Event triggered when a CoachEvent record is either created, updated, or deleted. */
  onMutatedCoachEvent: CoachEvent;
  /**   Event triggered when a CoachPackage record is either created, updated, or deleted. */
  onMutatedCoachPackage: CoachPackage;
  /**   Event triggered when a CoachReview record is either created, updated, or deleted. */
  onMutatedCoachReview: CoachReview;
  /**   Event triggered when a CoachSpecialism record is either created, updated, or deleted. */
  onMutatedCoachSpecialism: CoachSpecialism;
  /**   Event triggered when a Company record is either created, updated, or deleted. */
  onMutatedCompany: Company;
  /**   Event triggered when a CompanyAdmin record is either created, updated, or deleted. */
  onMutatedCompanyAdmin: CompanyAdmin;
  /**   Event triggered when a CompanyParent record is either created, updated, or deleted. */
  onMutatedCompanyParent: CompanyParent;
  /**   Event triggered when a Enquiry record is either created, updated, or deleted. */
  onMutatedEnquiry: Enquiry;
  /**   Event triggered when a EnquiryChoice record is either created, updated, or deleted. */
  onMutatedEnquiryChoice: EnquiryChoice;
  /**   Event triggered when a Feature record is either created, updated, or deleted. */
  onMutatedFeature: Feature;
  /**   Event triggered when a FeatureCompany record is either created, updated, or deleted. */
  onMutatedFeatureCompany: FeatureCompany;
  /**   Event triggered when multiple BookingHolds records are either created, updated, or deleted. */
  onMutatedManyBookingHolds: BatchPayload;
  /**   Event triggered when multiple CalendarSettings records are either created, updated, or deleted. */
  onMutatedManyCalendarSettings: BatchPayload;
  /**   Event triggered when multiple CoachApproaches records are either created, updated, or deleted. */
  onMutatedManyCoachApproaches: BatchPayload;
  /**   Event triggered when multiple CoachAudienceExperiences records are either created, updated, or deleted. */
  onMutatedManyCoachAudienceExperiences: BatchPayload;
  /**   Event triggered when multiple CoachCategories records are either created, updated, or deleted. */
  onMutatedManyCoachCategories: BatchPayload;
  /**   Event triggered when multiple CoachEvents records are either created, updated, or deleted. */
  onMutatedManyCoachEvents: BatchPayload;
  /**   Event triggered when multiple CoachPackages records are either created, updated, or deleted. */
  onMutatedManyCoachPackages: BatchPayload;
  /**   Event triggered when multiple CoachReviews records are either created, updated, or deleted. */
  onMutatedManyCoachReviews: BatchPayload;
  /**   Event triggered when multiple CoachSpecialisms records are either created, updated, or deleted. */
  onMutatedManyCoachSpecialisms: BatchPayload;
  /**   Event triggered when multiple Coaches records are either created, updated, or deleted. */
  onMutatedManyCoaches: BatchPayload;
  /**   Event triggered when multiple Companies records are either created, updated, or deleted. */
  onMutatedManyCompanies: BatchPayload;
  /**   Event triggered when multiple CompanyAdmins records are either created, updated, or deleted. */
  onMutatedManyCompanyAdmins: BatchPayload;
  /**   Event triggered when multiple CompanyParents records are either created, updated, or deleted. */
  onMutatedManyCompanyParents: BatchPayload;
  /**   Event triggered when multiple Enquiries records are either created, updated, or deleted. */
  onMutatedManyEnquiries: BatchPayload;
  /**   Event triggered when multiple EnquiryChoices records are either created, updated, or deleted. */
  onMutatedManyEnquiryChoices: BatchPayload;
  /**   Event triggered when multiple FeatureCompanies records are either created, updated, or deleted. */
  onMutatedManyFeatureCompanies: BatchPayload;
  /**   Event triggered when multiple Features records are either created, updated, or deleted. */
  onMutatedManyFeatures: BatchPayload;
  /**   Event triggered when multiple SessionApproaches records are either created, updated, or deleted. */
  onMutatedManySessionApproaches: BatchPayload;
  /**   Event triggered when multiple SessionAudienceExperiences records are either created, updated, or deleted. */
  onMutatedManySessionAudienceExperiences: BatchPayload;
  /**   Event triggered when multiple SessionCategories records are either created, updated, or deleted. */
  onMutatedManySessionCategories: BatchPayload;
  /**   Event triggered when multiple SessionPackages records are either created, updated, or deleted. */
  onMutatedManySessionPackages: BatchPayload;
  /**   Event triggered when multiple SessionPreCalls records are either created, updated, or deleted. */
  onMutatedManySessionPreCalls: BatchPayload;
  /**   Event triggered when multiple SessionReviews records are either created, updated, or deleted. */
  onMutatedManySessionReviews: BatchPayload;
  /**   Event triggered when multiple SessionSpecialisms records are either created, updated, or deleted. */
  onMutatedManySessionSpecialisms: BatchPayload;
  /**   Event triggered when multiple Sessions records are either created, updated, or deleted. */
  onMutatedManySessions: BatchPayload;
  /**   Event triggered when multiple TeamMemberOutcomes records are either created, updated, or deleted. */
  onMutatedManyTeamMemberOutcomes: BatchPayload;
  /**   Event triggered when multiple TeamMembers records are either created, updated, or deleted. */
  onMutatedManyTeamMembers: BatchPayload;
  /**   Event triggered when multiple UserOnboardingChoices records are either created, updated, or deleted. */
  onMutatedManyUserOnboardingChoices: BatchPayload;
  /**   Event triggered when multiple UserPackages records are either created, updated, or deleted. */
  onMutatedManyUserPackages: BatchPayload;
  /**   Event triggered when multiple UserSettings records are either created, updated, or deleted. */
  onMutatedManyUserSettings: BatchPayload;
  /**   Event triggered when multiple Users records are either created, updated, or deleted. */
  onMutatedManyUsers: BatchPayload;
  /**   Event triggered when a Session record is either created, updated, or deleted. */
  onMutatedSession: Session;
  /**   Event triggered when a SessionApproach record is either created, updated, or deleted. */
  onMutatedSessionApproach: SessionApproach;
  /**   Event triggered when a SessionAudienceExperience record is either created, updated, or deleted. */
  onMutatedSessionAudienceExperience: SessionAudienceExperience;
  /**   Event triggered when a SessionCategory record is either created, updated, or deleted. */
  onMutatedSessionCategory: SessionCategory;
  /**   Event triggered when a SessionPackage record is either created, updated, or deleted. */
  onMutatedSessionPackage: SessionPackage;
  /**   Event triggered when a SessionPreCall record is either created, updated, or deleted. */
  onMutatedSessionPreCall: SessionPreCall;
  /**   Event triggered when a SessionReview record is either created, updated, or deleted. */
  onMutatedSessionReview: SessionReview;
  /**   Event triggered when a SessionSpecialism record is either created, updated, or deleted. */
  onMutatedSessionSpecialism: SessionSpecialism;
  /**   Event triggered when a TeamMember record is either created, updated, or deleted. */
  onMutatedTeamMember: TeamMember;
  /**   Event triggered when a TeamMemberOutcome record is either created, updated, or deleted. */
  onMutatedTeamMemberOutcome: TeamMemberOutcome;
  /**   Event triggered when a User record is either created, updated, or deleted. */
  onMutatedUser: User;
  /**   Event triggered when a UserOnboardingChoice record is either created, updated, or deleted. */
  onMutatedUserOnboardingChoice: UserOnboardingChoice;
  /**   Event triggered when a UserPackage record is either created, updated, or deleted. */
  onMutatedUserPackage: UserPackage;
  /**   Event triggered when a UserSetting record is either created, updated, or deleted. */
  onMutatedUserSetting: UserSetting;
  /**   Event triggered when an existing BookingHold record is updated. */
  onUpdatedBookingHold: BookingHold;
  /**   Event triggered when an existing CalendarSetting record is updated. */
  onUpdatedCalendarSetting: CalendarSetting;
  /**   Event triggered when an existing Coach record is updated. */
  onUpdatedCoach: Coach;
  /**   Event triggered when an existing CoachApproach record is updated. */
  onUpdatedCoachApproach: CoachApproach;
  /**   Event triggered when an existing CoachAudienceExperience record is updated. */
  onUpdatedCoachAudienceExperience: CoachAudienceExperience;
  /**   Event triggered when an existing CoachCategory record is updated. */
  onUpdatedCoachCategory: CoachCategory;
  /**   Event triggered when an existing CoachEvent record is updated. */
  onUpdatedCoachEvent: CoachEvent;
  /**   Event triggered when an existing CoachPackage record is updated. */
  onUpdatedCoachPackage: CoachPackage;
  /**   Event triggered when an existing CoachReview record is updated. */
  onUpdatedCoachReview: CoachReview;
  /**   Event triggered when an existing CoachSpecialism record is updated. */
  onUpdatedCoachSpecialism: CoachSpecialism;
  /**   Event triggered when an existing Company record is updated. */
  onUpdatedCompany: Company;
  /**   Event triggered when an existing CompanyAdmin record is updated. */
  onUpdatedCompanyAdmin: CompanyAdmin;
  /**   Event triggered when an existing CompanyParent record is updated. */
  onUpdatedCompanyParent: CompanyParent;
  /**   Event triggered when an existing Enquiry record is updated. */
  onUpdatedEnquiry: Enquiry;
  /**   Event triggered when an existing EnquiryChoice record is updated. */
  onUpdatedEnquiryChoice: EnquiryChoice;
  /**   Event triggered when an existing Feature record is updated. */
  onUpdatedFeature: Feature;
  /**   Event triggered when an existing FeatureCompany record is updated. */
  onUpdatedFeatureCompany: FeatureCompany;
  /**   Event triggered when multiple existing BookingHolds records are updated. */
  onUpdatedManyBookingHolds: BatchPayload;
  /**   Event triggered when multiple existing CalendarSettings records are updated. */
  onUpdatedManyCalendarSettings: BatchPayload;
  /**   Event triggered when multiple existing CoachApproaches records are updated. */
  onUpdatedManyCoachApproaches: BatchPayload;
  /**   Event triggered when multiple existing CoachAudienceExperiences records are updated. */
  onUpdatedManyCoachAudienceExperiences: BatchPayload;
  /**   Event triggered when multiple existing CoachCategories records are updated. */
  onUpdatedManyCoachCategories: BatchPayload;
  /**   Event triggered when multiple existing CoachEvents records are updated. */
  onUpdatedManyCoachEvents: BatchPayload;
  /**   Event triggered when multiple existing CoachPackages records are updated. */
  onUpdatedManyCoachPackages: BatchPayload;
  /**   Event triggered when multiple existing CoachReviews records are updated. */
  onUpdatedManyCoachReviews: BatchPayload;
  /**   Event triggered when multiple existing CoachSpecialisms records are updated. */
  onUpdatedManyCoachSpecialisms: BatchPayload;
  /**   Event triggered when multiple existing Coaches records are updated. */
  onUpdatedManyCoaches: BatchPayload;
  /**   Event triggered when multiple existing Companies records are updated. */
  onUpdatedManyCompanies: BatchPayload;
  /**   Event triggered when multiple existing CompanyAdmins records are updated. */
  onUpdatedManyCompanyAdmins: BatchPayload;
  /**   Event triggered when multiple existing CompanyParents records are updated. */
  onUpdatedManyCompanyParents: BatchPayload;
  /**   Event triggered when multiple existing Enquiries records are updated. */
  onUpdatedManyEnquiries: BatchPayload;
  /**   Event triggered when multiple existing EnquiryChoices records are updated. */
  onUpdatedManyEnquiryChoices: BatchPayload;
  /**   Event triggered when multiple existing FeatureCompanies records are updated. */
  onUpdatedManyFeatureCompanies: BatchPayload;
  /**   Event triggered when multiple existing Features records are updated. */
  onUpdatedManyFeatures: BatchPayload;
  /**   Event triggered when multiple existing SessionApproaches records are updated. */
  onUpdatedManySessionApproaches: BatchPayload;
  /**   Event triggered when multiple existing SessionAudienceExperiences records are updated. */
  onUpdatedManySessionAudienceExperiences: BatchPayload;
  /**   Event triggered when multiple existing SessionCategories records are updated. */
  onUpdatedManySessionCategories: BatchPayload;
  /**   Event triggered when multiple existing SessionPackages records are updated. */
  onUpdatedManySessionPackages: BatchPayload;
  /**   Event triggered when multiple existing SessionPreCalls records are updated. */
  onUpdatedManySessionPreCalls: BatchPayload;
  /**   Event triggered when multiple existing SessionReviews records are updated. */
  onUpdatedManySessionReviews: BatchPayload;
  /**   Event triggered when multiple existing SessionSpecialisms records are updated. */
  onUpdatedManySessionSpecialisms: BatchPayload;
  /**   Event triggered when multiple existing Sessions records are updated. */
  onUpdatedManySessions: BatchPayload;
  /**   Event triggered when multiple existing TeamMemberOutcomes records are updated. */
  onUpdatedManyTeamMemberOutcomes: BatchPayload;
  /**   Event triggered when multiple existing TeamMembers records are updated. */
  onUpdatedManyTeamMembers: BatchPayload;
  /**   Event triggered when multiple existing UserOnboardingChoices records are updated. */
  onUpdatedManyUserOnboardingChoices: BatchPayload;
  /**   Event triggered when multiple existing UserPackages records are updated. */
  onUpdatedManyUserPackages: BatchPayload;
  /**   Event triggered when multiple existing UserSettings records are updated. */
  onUpdatedManyUserSettings: BatchPayload;
  /**   Event triggered when multiple existing Users records are updated. */
  onUpdatedManyUsers: BatchPayload;
  /**   Event triggered when an existing Session record is updated. */
  onUpdatedSession: Session;
  /**   Event triggered when an existing SessionApproach record is updated. */
  onUpdatedSessionApproach: SessionApproach;
  /**   Event triggered when an existing SessionAudienceExperience record is updated. */
  onUpdatedSessionAudienceExperience: SessionAudienceExperience;
  /**   Event triggered when an existing SessionCategory record is updated. */
  onUpdatedSessionCategory: SessionCategory;
  /**   Event triggered when an existing SessionPackage record is updated. */
  onUpdatedSessionPackage: SessionPackage;
  /**   Event triggered when an existing SessionPreCall record is updated. */
  onUpdatedSessionPreCall: SessionPreCall;
  /**   Event triggered when an existing SessionReview record is updated. */
  onUpdatedSessionReview: SessionReview;
  /**   Event triggered when an existing SessionSpecialism record is updated. */
  onUpdatedSessionSpecialism: SessionSpecialism;
  /**   Event triggered when an existing TeamMember record is updated. */
  onUpdatedTeamMember: TeamMember;
  /**   Event triggered when an existing TeamMemberOutcome record is updated. */
  onUpdatedTeamMemberOutcome: TeamMemberOutcome;
  /**   Event triggered when an existing User record is updated. */
  onUpdatedUser: User;
  /**   Event triggered when an existing UserOnboardingChoice record is updated. */
  onUpdatedUserOnboardingChoice: UserOnboardingChoice;
  /**   Event triggered when an existing UserPackage record is updated. */
  onUpdatedUserPackage: UserPackage;
  /**   Event triggered when an existing UserSetting record is updated. */
  onUpdatedUserSetting: UserSetting;
  /**   Event triggered when a BookingHold record is either created or updated. */
  onUpsertedBookingHold: BookingHold;
  /**   Event triggered when a CalendarSetting record is either created or updated. */
  onUpsertedCalendarSetting: CalendarSetting;
  /**   Event triggered when a Coach record is either created or updated. */
  onUpsertedCoach: Coach;
  /**   Event triggered when a CoachApproach record is either created or updated. */
  onUpsertedCoachApproach: CoachApproach;
  /**   Event triggered when a CoachAudienceExperience record is either created or updated. */
  onUpsertedCoachAudienceExperience: CoachAudienceExperience;
  /**   Event triggered when a CoachCategory record is either created or updated. */
  onUpsertedCoachCategory: CoachCategory;
  /**   Event triggered when a CoachEvent record is either created or updated. */
  onUpsertedCoachEvent: CoachEvent;
  /**   Event triggered when a CoachPackage record is either created or updated. */
  onUpsertedCoachPackage: CoachPackage;
  /**   Event triggered when a CoachReview record is either created or updated. */
  onUpsertedCoachReview: CoachReview;
  /**   Event triggered when a CoachSpecialism record is either created or updated. */
  onUpsertedCoachSpecialism: CoachSpecialism;
  /**   Event triggered when a Company record is either created or updated. */
  onUpsertedCompany: Company;
  /**   Event triggered when a CompanyAdmin record is either created or updated. */
  onUpsertedCompanyAdmin: CompanyAdmin;
  /**   Event triggered when a CompanyParent record is either created or updated. */
  onUpsertedCompanyParent: CompanyParent;
  /**   Event triggered when a Enquiry record is either created or updated. */
  onUpsertedEnquiry: Enquiry;
  /**   Event triggered when a EnquiryChoice record is either created or updated. */
  onUpsertedEnquiryChoice: EnquiryChoice;
  /**   Event triggered when a Feature record is either created or updated. */
  onUpsertedFeature: Feature;
  /**   Event triggered when a FeatureCompany record is either created or updated. */
  onUpsertedFeatureCompany: FeatureCompany;
  /**   Event triggered when a Session record is either created or updated. */
  onUpsertedSession: Session;
  /**   Event triggered when a SessionApproach record is either created or updated. */
  onUpsertedSessionApproach: SessionApproach;
  /**   Event triggered when a SessionAudienceExperience record is either created or updated. */
  onUpsertedSessionAudienceExperience: SessionAudienceExperience;
  /**   Event triggered when a SessionCategory record is either created or updated. */
  onUpsertedSessionCategory: SessionCategory;
  /**   Event triggered when a SessionPackage record is either created or updated. */
  onUpsertedSessionPackage: SessionPackage;
  /**   Event triggered when a SessionPreCall record is either created or updated. */
  onUpsertedSessionPreCall: SessionPreCall;
  /**   Event triggered when a SessionReview record is either created or updated. */
  onUpsertedSessionReview: SessionReview;
  /**   Event triggered when a SessionSpecialism record is either created or updated. */
  onUpsertedSessionSpecialism: SessionSpecialism;
  /**   Event triggered when a TeamMember record is either created or updated. */
  onUpsertedTeamMember: TeamMember;
  /**   Event triggered when a TeamMemberOutcome record is either created or updated. */
  onUpsertedTeamMemberOutcome: TeamMemberOutcome;
  /**   Event triggered when a User record is either created or updated. */
  onUpsertedUser: User;
  /**   Event triggered when a UserOnboardingChoice record is either created or updated. */
  onUpsertedUserOnboardingChoice: UserOnboardingChoice;
  /**   Event triggered when a UserPackage record is either created or updated. */
  onUpsertedUserPackage: UserPackage;
  /**   Event triggered when a UserSetting record is either created or updated. */
  onUpsertedUserSetting: UserSetting;
};


export type SubscriptionOnCreatedBookingHoldArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCoachArgs = {
  handle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCoachEventArgs = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCoachPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCoachReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedCompanyParentArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedEnquiryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedEnquiryChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedFeatureArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedFeatureCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedSessionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedSessionApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_approachId?: InputMaybe<SessionIdApproachIdFieldsInput>;
};


export type SubscriptionOnCreatedSessionAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_audienceExperienceId?: InputMaybe<SessionIdAudienceExperienceIdFieldsInput>;
};


export type SubscriptionOnCreatedSessionCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_categoryId?: InputMaybe<SessionIdCategoryIdFieldsInput>;
};


export type SubscriptionOnCreatedSessionPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_packageId?: InputMaybe<SessionIdPackageIdFieldsInput>;
};


export type SubscriptionOnCreatedSessionPreCallArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedSessionReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedSessionSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_specialismId?: InputMaybe<SessionIdSpecialismIdFieldsInput>;
};


export type SubscriptionOnCreatedTeamMemberArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedUserOnboardingChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedUserPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnCreatedUserSettingArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_key?: InputMaybe<UserIdKeyFieldsInput>;
};


export type SubscriptionOnDeletedBookingHoldArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCoachArgs = {
  handle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCoachEventArgs = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCoachPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCoachReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedCompanyParentArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedEnquiryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedEnquiryChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedFeatureArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedFeatureCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedSessionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedSessionApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_approachId?: InputMaybe<SessionIdApproachIdFieldsInput>;
};


export type SubscriptionOnDeletedSessionAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_audienceExperienceId?: InputMaybe<SessionIdAudienceExperienceIdFieldsInput>;
};


export type SubscriptionOnDeletedSessionCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_categoryId?: InputMaybe<SessionIdCategoryIdFieldsInput>;
};


export type SubscriptionOnDeletedSessionPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_packageId?: InputMaybe<SessionIdPackageIdFieldsInput>;
};


export type SubscriptionOnDeletedSessionPreCallArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedSessionReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedSessionSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_specialismId?: InputMaybe<SessionIdSpecialismIdFieldsInput>;
};


export type SubscriptionOnDeletedTeamMemberArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedUserOnboardingChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedUserPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnDeletedUserSettingArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_key?: InputMaybe<UserIdKeyFieldsInput>;
};


export type SubscriptionOnMutatedBookingHoldArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCoachArgs = {
  handle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCoachEventArgs = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCoachPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCoachReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedCompanyParentArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedEnquiryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedEnquiryChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedFeatureArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedFeatureCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedSessionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedSessionApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_approachId?: InputMaybe<SessionIdApproachIdFieldsInput>;
};


export type SubscriptionOnMutatedSessionAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_audienceExperienceId?: InputMaybe<SessionIdAudienceExperienceIdFieldsInput>;
};


export type SubscriptionOnMutatedSessionCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_categoryId?: InputMaybe<SessionIdCategoryIdFieldsInput>;
};


export type SubscriptionOnMutatedSessionPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_packageId?: InputMaybe<SessionIdPackageIdFieldsInput>;
};


export type SubscriptionOnMutatedSessionPreCallArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedSessionReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedSessionSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_specialismId?: InputMaybe<SessionIdSpecialismIdFieldsInput>;
};


export type SubscriptionOnMutatedTeamMemberArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedUserOnboardingChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedUserPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnMutatedUserSettingArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_key?: InputMaybe<UserIdKeyFieldsInput>;
};


export type SubscriptionOnUpdatedBookingHoldArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCoachArgs = {
  handle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCoachEventArgs = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCoachPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCoachReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedCompanyParentArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedEnquiryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedEnquiryChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedFeatureArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedFeatureCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedSessionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedSessionApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_approachId?: InputMaybe<SessionIdApproachIdFieldsInput>;
};


export type SubscriptionOnUpdatedSessionAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_audienceExperienceId?: InputMaybe<SessionIdAudienceExperienceIdFieldsInput>;
};


export type SubscriptionOnUpdatedSessionCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_categoryId?: InputMaybe<SessionIdCategoryIdFieldsInput>;
};


export type SubscriptionOnUpdatedSessionPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_packageId?: InputMaybe<SessionIdPackageIdFieldsInput>;
};


export type SubscriptionOnUpdatedSessionPreCallArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedSessionReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedSessionSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_specialismId?: InputMaybe<SessionIdSpecialismIdFieldsInput>;
};


export type SubscriptionOnUpdatedTeamMemberArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedUserOnboardingChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedUserPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpdatedUserSettingArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_key?: InputMaybe<UserIdKeyFieldsInput>;
};


export type SubscriptionOnUpsertedBookingHoldArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCalendarSettingArgs = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCoachArgs = {
  handle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCoachApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCoachAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCoachCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCoachEventArgs = {
  eventId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCoachPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCoachReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCoachSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCompanyAdminArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedCompanyParentArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedEnquiryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedEnquiryChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedFeatureArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedFeatureCompanyArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedSessionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedSessionApproachArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_approachId?: InputMaybe<SessionIdApproachIdFieldsInput>;
};


export type SubscriptionOnUpsertedSessionAudienceExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_audienceExperienceId?: InputMaybe<SessionIdAudienceExperienceIdFieldsInput>;
};


export type SubscriptionOnUpsertedSessionCategoryArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_categoryId?: InputMaybe<SessionIdCategoryIdFieldsInput>;
};


export type SubscriptionOnUpsertedSessionPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_packageId?: InputMaybe<SessionIdPackageIdFieldsInput>;
};


export type SubscriptionOnUpsertedSessionPreCallArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedSessionReviewArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedSessionSpecialismArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  sessionId_specialismId?: InputMaybe<SessionIdSpecialismIdFieldsInput>;
};


export type SubscriptionOnUpsertedTeamMemberArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedTeamMemberOutcomeArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedUserArgs = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedUserOnboardingChoiceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedUserPackageArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionOnUpsertedUserSettingArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_key?: InputMaybe<UserIdKeyFieldsInput>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  archivedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  bookings: Array<BookingHold>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  isManager?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  onboardingCallJoined?: Maybe<Scalars['Boolean']['output']>;
  onboardingCallRegistered?: Maybe<Scalars['Boolean']['output']>;
  originalImpersonationCompanyId?: Maybe<Scalars['String']['output']>;
  outcome?: Maybe<TeamMemberOutcome>;
  outcomeId?: Maybe<Scalars['String']['output']>;
  sessions: Array<Session>;
  updatedAt: Scalars['AWSDateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type TeamMemberBookingsCreateNestedInput = {
  connect?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BookingHoldConnectOrCreateWithoutTeamMemberInput>>;
  create?: InputMaybe<Array<BookingHoldCreateWithoutTeamMemberInput>>;
};

export type TeamMemberBookingsUpdateNestedInput = {
  connect?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BookingHoldConnectOrCreateWithoutTeamMemberInput>>;
  create?: InputMaybe<Array<BookingHoldCreateWithoutTeamMemberInput>>;
  delete?: InputMaybe<Array<BookingHoldExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BookingHoldScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BookingHoldExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<BookingHoldWhereUniqueInput>>;
  update?: InputMaybe<Array<BookingHoldUpdateWithWhereUniqueWithoutTeamMemberInput>>;
  updateMany?: InputMaybe<Array<BookingHoldUpdateManyInput>>;
  upsert?: InputMaybe<Array<BookingHoldUpsertWithWhereUniqueWithoutTeamMemberInput>>;
};

export type TeamMemberCompanyCreateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateWithoutTeamMemberInput>;
  create?: InputMaybe<CompanyCreateWithoutTeamMemberInput>;
};

export type TeamMemberCompanyUpdateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyConnectOrCreateWithoutTeamMemberInput>;
  create?: InputMaybe<CompanyCreateWithoutTeamMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CompanyUpdateWithoutTeamMemberInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutTeamMemberInput>;
};

export type TeamMemberConnectOrCreateWithoutBookingHoldInput = {
  create: TeamMemberCreateWithoutBookingHoldInput;
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberConnectOrCreateWithoutCompanyInput = {
  create: TeamMemberCreateWithoutCompanyInput;
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberConnectOrCreateWithoutSessionInput = {
  create: TeamMemberCreateWithoutSessionInput;
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberConnectOrCreateWithoutTeamMemberOutcomeInput = {
  create: TeamMemberCreateWithoutTeamMemberOutcomeInput;
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberConnectOrCreateWithoutUserInput = {
  create: TeamMemberCreateWithoutUserInput;
  where: TeamMemberWhereUniqueInput;
};

export type TeamMemberCreateInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsCreateNestedInput>;
  company?: InputMaybe<TeamMemberCompanyCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeCreateNestedInput>;
  sessions?: InputMaybe<TeamMemberSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserCreateNestedInput>;
};

export type TeamMemberCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type TeamMemberCreateWithoutBookingHoldInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  company?: InputMaybe<TeamMemberCompanyCreateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeCreateNestedInput>;
  outcomeId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<TeamMemberSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberCreateWithoutCompanyInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeCreateNestedInput>;
  outcomeId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<TeamMemberSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberCreateWithoutSessionInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsCreateNestedInput>;
  company?: InputMaybe<TeamMemberCompanyCreateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeCreateNestedInput>;
  outcomeId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberCreateWithoutTeamMemberOutcomeInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsCreateNestedInput>;
  company?: InputMaybe<TeamMemberCompanyCreateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<TeamMemberSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberCreateWithoutUserInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsCreateNestedInput>;
  company?: InputMaybe<TeamMemberCompanyCreateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeCreateNestedInput>;
  outcomeId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<TeamMemberSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type TeamMemberExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<TeamMemberWhereInput>>;
  NOT?: InputMaybe<Array<TeamMemberWhereInput>>;
  OR?: InputMaybe<Array<TeamMemberWhereInput>>;
  archivedAt?: InputMaybe<AwsDateTimeNullableFilter>;
  bookings?: InputMaybe<BookingHoldFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<BooleanNullableFilter>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  onboardingCallJoined?: InputMaybe<BooleanNullableFilter>;
  onboardingCallRegistered?: InputMaybe<BooleanNullableFilter>;
  originalImpersonationCompanyId?: InputMaybe<StringNullableFilter>;
  outcome?: InputMaybe<TeamMemberOutcomeWhereInput>;
  outcomeId?: InputMaybe<StringNullableFilter>;
  sessions?: InputMaybe<SessionFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberFilter = {
  every?: InputMaybe<TeamMemberWhereInputWithoutNullables>;
  none?: InputMaybe<TeamMemberWhereInputWithoutNullables>;
  some?: InputMaybe<TeamMemberWhereInputWithoutNullables>;
};

export type TeamMemberOrderByInput = {
  archivedAt?: InputMaybe<OrderByArg>;
  bookings?: InputMaybe<BookingHoldOrderByInput>;
  company?: InputMaybe<CompanyOrderByInput>;
  companyId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  isManager?: InputMaybe<OrderByArg>;
  jobTitle?: InputMaybe<OrderByArg>;
  onboardingCallJoined?: InputMaybe<OrderByArg>;
  onboardingCallRegistered?: InputMaybe<OrderByArg>;
  originalImpersonationCompanyId?: InputMaybe<OrderByArg>;
  outcome?: InputMaybe<TeamMemberOutcomeOrderByInput>;
  outcomeId?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
};

export type TeamMemberOutcome = {
  __typename?: 'TeamMemberOutcome';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  teamMembers: Array<TeamMember>;
  updatedAt: Scalars['AWSDateTime']['output'];
  value: Scalars['String']['output'];
};

export type TeamMemberOutcomeConnectOrCreateWithoutTeamMemberInput = {
  create: TeamMemberOutcomeCreateWithoutTeamMemberInput;
  where: TeamMemberOutcomeWhereUniqueInput;
};

export type TeamMemberOutcomeCreateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  teamMembers?: InputMaybe<TeamMemberOutcomeTeamMembersCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type TeamMemberOutcomeCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type TeamMemberOutcomeCreateNestedInput = {
  connect?: InputMaybe<TeamMemberOutcomeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberOutcomeConnectOrCreateWithoutTeamMemberInput>;
  create?: InputMaybe<TeamMemberOutcomeCreateWithoutTeamMemberInput>;
};

export type TeamMemberOutcomeCreateWithoutTeamMemberInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type TeamMemberOutcomeExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  NOT?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  OR?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<StringFilter>;
  teamMembers?: InputMaybe<TeamMemberFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TeamMemberOutcomeFilter = {
  every?: InputMaybe<TeamMemberOutcomeWhereInputWithoutNullables>;
  none?: InputMaybe<TeamMemberOutcomeWhereInputWithoutNullables>;
  some?: InputMaybe<TeamMemberOutcomeWhereInputWithoutNullables>;
};

export type TeamMemberOutcomeOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  teamMembers?: InputMaybe<TeamMemberOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type TeamMemberOutcomeScalarWhereInput = {
  AND?: InputMaybe<Array<TeamMemberOutcomeScalarWhereInput>>;
  NOT?: InputMaybe<Array<TeamMemberOutcomeScalarWhereInput>>;
  OR?: InputMaybe<Array<TeamMemberOutcomeScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TeamMemberOutcomeTeamMembersCreateNestedInput = {
  connect?: InputMaybe<Array<TeamMemberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamMemberConnectOrCreateWithoutTeamMemberOutcomeInput>>;
  create?: InputMaybe<Array<TeamMemberCreateWithoutTeamMemberOutcomeInput>>;
};

export type TeamMemberOutcomeTeamMembersUpdateNestedInput = {
  connect?: InputMaybe<Array<TeamMemberWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamMemberConnectOrCreateWithoutTeamMemberOutcomeInput>>;
  create?: InputMaybe<Array<TeamMemberCreateWithoutTeamMemberOutcomeInput>>;
  delete?: InputMaybe<Array<TeamMemberExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamMemberScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamMemberExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamMemberWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamMemberUpdateWithWhereUniqueWithoutTeamMemberOutcomeInput>>;
  updateMany?: InputMaybe<Array<TeamMemberUpdateManyInput>>;
  upsert?: InputMaybe<Array<TeamMemberUpsertWithWhereUniqueWithoutTeamMemberOutcomeInput>>;
};

export type TeamMemberOutcomeUpdateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  teamMembers?: InputMaybe<TeamMemberOutcomeTeamMembersUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberOutcomeUpdateManyInput = {
  data: TeamMemberOutcomeUpdateInput;
  where: TeamMemberOutcomeScalarWhereInput;
};

export type TeamMemberOutcomeUpdateNestedInput = {
  connect?: InputMaybe<TeamMemberOutcomeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberOutcomeConnectOrCreateWithoutTeamMemberInput>;
  create?: InputMaybe<TeamMemberOutcomeCreateWithoutTeamMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamMemberOutcomeUpdateWithoutTeamMemberInput>;
  upsert?: InputMaybe<TeamMemberOutcomeUpsertWithoutTeamMemberInput>;
};

export type TeamMemberOutcomeUpdateWithWhereUniqueWithoutTeamMemberInput = {
  data: TeamMemberOutcomeUpdateWithoutTeamMemberInput;
  where: TeamMemberOutcomeExtendedWhereUniqueInput;
};

export type TeamMemberOutcomeUpdateWithoutTeamMemberInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberOutcomeUpsertWithWhereUniqueWithoutTeamMemberInput = {
  create: TeamMemberOutcomeCreateWithoutTeamMemberInput;
  update: TeamMemberOutcomeUpdateWithoutTeamMemberInput;
  where: TeamMemberOutcomeExtendedWhereUniqueInput;
};

export type TeamMemberOutcomeUpsertWithoutTeamMemberInput = {
  create: TeamMemberOutcomeCreateWithoutTeamMemberInput;
  update: TeamMemberOutcomeUpdateWithoutTeamMemberInput;
};

export type TeamMemberOutcomeWhereInput = {
  AND?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  NOT?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  OR?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  label?: InputMaybe<StringFilter>;
  teamMembers?: InputMaybe<TeamMemberFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TeamMemberOutcomeWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  NOT?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  OR?: InputMaybe<Array<TeamMemberOutcomeWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringFilter>;
  teamMembers?: InputMaybe<TeamMemberFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type TeamMemberOutcomeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberScalarWhereInput = {
  AND?: InputMaybe<Array<TeamMemberScalarWhereInput>>;
  NOT?: InputMaybe<Array<TeamMemberScalarWhereInput>>;
  OR?: InputMaybe<Array<TeamMemberScalarWhereInput>>;
  archivedAt?: InputMaybe<AwsDateTimeNullableFilter>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isManager?: InputMaybe<BooleanNullableFilter>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  onboardingCallJoined?: InputMaybe<BooleanNullableFilter>;
  onboardingCallRegistered?: InputMaybe<BooleanNullableFilter>;
  originalImpersonationCompanyId?: InputMaybe<StringNullableFilter>;
  outcomeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type TeamMemberSessionsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionConnectOrCreateWithoutTeamMemberInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutTeamMemberInput>>;
};

export type TeamMemberSessionsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionConnectOrCreateWithoutTeamMemberInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutTeamMemberInput>>;
  delete?: InputMaybe<Array<SessionExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionUpdateWithWhereUniqueWithoutTeamMemberInput>>;
  updateMany?: InputMaybe<Array<SessionUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionUpsertWithWhereUniqueWithoutTeamMemberInput>>;
};

export type TeamMemberUpdateInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsUpdateNestedInput>;
  company?: InputMaybe<TeamMemberCompanyUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeUpdateNestedInput>;
  sessions?: InputMaybe<TeamMemberSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserUpdateNestedInput>;
};

export type TeamMemberUpdateManyInput = {
  data: TeamMemberUpdateInput;
  where: TeamMemberScalarWhereInput;
};

export type TeamMemberUpdateWithWhereUniqueWithoutBookingHoldInput = {
  data: TeamMemberUpdateWithoutBookingHoldInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpdateWithWhereUniqueWithoutCompanyInput = {
  data: TeamMemberUpdateWithoutCompanyInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpdateWithWhereUniqueWithoutSessionInput = {
  data: TeamMemberUpdateWithoutSessionInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpdateWithWhereUniqueWithoutTeamMemberOutcomeInput = {
  data: TeamMemberUpdateWithoutTeamMemberOutcomeInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpdateWithWhereUniqueWithoutUserInput = {
  data: TeamMemberUpdateWithoutUserInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpdateWithoutBookingHoldInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  company?: InputMaybe<TeamMemberCompanyUpdateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeUpdateNestedInput>;
  outcomeId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<TeamMemberSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberUpdateWithoutCompanyInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeUpdateNestedInput>;
  outcomeId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<TeamMemberSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberUpdateWithoutSessionInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsUpdateNestedInput>;
  company?: InputMaybe<TeamMemberCompanyUpdateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeUpdateNestedInput>;
  outcomeId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberUpdateWithoutTeamMemberOutcomeInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsUpdateNestedInput>;
  company?: InputMaybe<TeamMemberCompanyUpdateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<TeamMemberSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<TeamMemberUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TeamMemberUpdateWithoutUserInput = {
  archivedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  bookings?: InputMaybe<TeamMemberBookingsUpdateNestedInput>;
  company?: InputMaybe<TeamMemberCompanyUpdateNestedInput>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  onboardingCallJoined?: InputMaybe<Scalars['Boolean']['input']>;
  onboardingCallRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  originalImpersonationCompanyId?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<TeamMemberOutcomeUpdateNestedInput>;
  outcomeId?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<TeamMemberSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type TeamMemberUpsertWithWhereUniqueWithoutBookingHoldInput = {
  create: TeamMemberCreateWithoutBookingHoldInput;
  update: TeamMemberUpdateWithoutBookingHoldInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpsertWithWhereUniqueWithoutCompanyInput = {
  create: TeamMemberCreateWithoutCompanyInput;
  update: TeamMemberUpdateWithoutCompanyInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpsertWithWhereUniqueWithoutSessionInput = {
  create: TeamMemberCreateWithoutSessionInput;
  update: TeamMemberUpdateWithoutSessionInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpsertWithWhereUniqueWithoutTeamMemberOutcomeInput = {
  create: TeamMemberCreateWithoutTeamMemberOutcomeInput;
  update: TeamMemberUpdateWithoutTeamMemberOutcomeInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpsertWithWhereUniqueWithoutUserInput = {
  create: TeamMemberCreateWithoutUserInput;
  update: TeamMemberUpdateWithoutUserInput;
  where: TeamMemberExtendedWhereUniqueInput;
};

export type TeamMemberUpsertWithoutBookingHoldInput = {
  create: TeamMemberCreateWithoutBookingHoldInput;
  update: TeamMemberUpdateWithoutBookingHoldInput;
};

export type TeamMemberUpsertWithoutCompanyInput = {
  create: TeamMemberCreateWithoutCompanyInput;
  update: TeamMemberUpdateWithoutCompanyInput;
};

export type TeamMemberUpsertWithoutSessionInput = {
  create: TeamMemberCreateWithoutSessionInput;
  update: TeamMemberUpdateWithoutSessionInput;
};

export type TeamMemberUpsertWithoutTeamMemberOutcomeInput = {
  create: TeamMemberCreateWithoutTeamMemberOutcomeInput;
  update: TeamMemberUpdateWithoutTeamMemberOutcomeInput;
};

export type TeamMemberUpsertWithoutUserInput = {
  create: TeamMemberCreateWithoutUserInput;
  update: TeamMemberUpdateWithoutUserInput;
};

export type TeamMemberUserCreateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutTeamMemberInput>;
  create?: InputMaybe<UserCreateWithoutTeamMemberInput>;
};

export type TeamMemberUserUpdateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutTeamMemberInput>;
  create?: InputMaybe<UserCreateWithoutTeamMemberInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutTeamMemberInput>;
  upsert?: InputMaybe<UserUpsertWithoutTeamMemberInput>;
};

export type TeamMemberWhereInput = {
  AND?: InputMaybe<Array<TeamMemberWhereInput>>;
  NOT?: InputMaybe<Array<TeamMemberWhereInput>>;
  OR?: InputMaybe<Array<TeamMemberWhereInput>>;
  archivedAt?: InputMaybe<AwsDateTimeNullableFilter>;
  bookings?: InputMaybe<BookingHoldFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isManager?: InputMaybe<BooleanNullableFilter>;
  isNot?: InputMaybe<NullArg>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  onboardingCallJoined?: InputMaybe<BooleanNullableFilter>;
  onboardingCallRegistered?: InputMaybe<BooleanNullableFilter>;
  originalImpersonationCompanyId?: InputMaybe<StringNullableFilter>;
  outcome?: InputMaybe<TeamMemberOutcomeWhereInput>;
  outcomeId?: InputMaybe<StringNullableFilter>;
  sessions?: InputMaybe<SessionFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type TeamMemberWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<TeamMemberWhereInput>>;
  NOT?: InputMaybe<Array<TeamMemberWhereInput>>;
  OR?: InputMaybe<Array<TeamMemberWhereInput>>;
  archivedAt?: InputMaybe<AwsDateTimeNullableFilter>;
  bookings?: InputMaybe<BookingHoldFilter>;
  company?: InputMaybe<CompanyWhereInput>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isManager?: InputMaybe<BooleanNullableFilter>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  onboardingCallJoined?: InputMaybe<BooleanNullableFilter>;
  onboardingCallRegistered?: InputMaybe<BooleanNullableFilter>;
  originalImpersonationCompanyId?: InputMaybe<StringNullableFilter>;
  outcome?: InputMaybe<TeamMemberOutcomeWhereInput>;
  outcomeId?: InputMaybe<StringNullableFilter>;
  sessions?: InputMaybe<SessionFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type TeamMemberWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  activationState: ActivationState;
  avatarKey?: Maybe<Scalars['String']['output']>;
  coach?: Maybe<Coach>;
  coachingExposure?: Maybe<Scalars['String']['output']>;
  cognitoId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  email: Scalars['AWSEmail']['output'];
  enquiry: Array<Enquiry>;
  firstName: Scalars['String']['output'];
  hubspotContactId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  intercomUserId?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isImpersonating: Scalars['Boolean']['output'];
  jobLevel?: Maybe<Scalars['String']['output']>;
  lastLogin?: Maybe<Scalars['AWSDateTime']['output']>;
  lastName: Scalars['String']['output'];
  packages: Array<UserPackage>;
  phone?: Maybe<Scalars['String']['output']>;
  reviews: Array<CoachReview>;
  role: UserRole;
  settings: Array<UserSetting>;
  teamMember?: Maybe<TeamMember>;
  timezone?: Maybe<Scalars['String']['output']>;
  type: UserType;
  updatedAt: Scalars['AWSDateTime']['output'];
  userOnboardingChoices: Array<UserOnboardingChoice>;
};

export type UserCoachCreateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutUserInput>;
  create?: InputMaybe<CoachCreateWithoutUserInput>;
};

export type UserCoachUpdateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutUserInput>;
  create?: InputMaybe<CoachCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachUpdateWithoutUserInput>;
  upsert?: InputMaybe<CoachUpsertWithoutUserInput>;
};

export type UserConnectOrCreateWithoutCoachInput = {
  create: UserCreateWithoutCoachInput;
  where: UserWhereUniqueInput;
};

export type UserConnectOrCreateWithoutCoachReviewInput = {
  create: UserCreateWithoutCoachReviewInput;
  where: UserWhereUniqueInput;
};

export type UserConnectOrCreateWithoutEnquiryInput = {
  create: UserCreateWithoutEnquiryInput;
  where: UserWhereUniqueInput;
};

export type UserConnectOrCreateWithoutTeamMemberInput = {
  create: UserCreateWithoutTeamMemberInput;
  where: UserWhereUniqueInput;
};

export type UserConnectOrCreateWithoutUserOnboardingChoiceInput = {
  create: UserCreateWithoutUserOnboardingChoiceInput;
  where: UserWhereUniqueInput;
};

export type UserConnectOrCreateWithoutUserPackageInput = {
  create: UserCreateWithoutUserPackageInput;
  where: UserWhereUniqueInput;
};

export type UserConnectOrCreateWithoutUserSettingInput = {
  create: UserCreateWithoutUserSettingInput;
  where: UserWhereUniqueInput;
};

export type UserCreateInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachCreateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  enquiry?: InputMaybe<UserEnquiryCreateNestedInput>;
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  packages?: InputMaybe<UserPackagesCreateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsCreateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsCreateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberCreateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesCreateNestedInput>;
};

export type UserCreateManyInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UserCreateWithoutCoachInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  enquiry?: InputMaybe<UserEnquiryCreateNestedInput>;
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  packages?: InputMaybe<UserPackagesCreateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsCreateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsCreateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberCreateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesCreateNestedInput>;
};

export type UserCreateWithoutCoachReviewInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachCreateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  enquiry?: InputMaybe<UserEnquiryCreateNestedInput>;
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  packages?: InputMaybe<UserPackagesCreateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsCreateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberCreateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesCreateNestedInput>;
};

export type UserCreateWithoutEnquiryInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachCreateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  packages?: InputMaybe<UserPackagesCreateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsCreateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsCreateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberCreateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesCreateNestedInput>;
};

export type UserCreateWithoutTeamMemberInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachCreateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  enquiry?: InputMaybe<UserEnquiryCreateNestedInput>;
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  packages?: InputMaybe<UserPackagesCreateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsCreateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsCreateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesCreateNestedInput>;
};

export type UserCreateWithoutUserOnboardingChoiceInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachCreateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  enquiry?: InputMaybe<UserEnquiryCreateNestedInput>;
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  packages?: InputMaybe<UserPackagesCreateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsCreateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsCreateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberCreateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UserCreateWithoutUserPackageInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachCreateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  enquiry?: InputMaybe<UserEnquiryCreateNestedInput>;
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsCreateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsCreateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberCreateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesCreateNestedInput>;
};

export type UserCreateWithoutUserSettingInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachCreateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email: Scalars['AWSEmail']['input'];
  enquiry?: InputMaybe<UserEnquiryCreateNestedInput>;
  firstName: Scalars['String']['input'];
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName: Scalars['String']['input'];
  packages?: InputMaybe<UserPackagesCreateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsCreateNestedInput>;
  role?: InputMaybe<UserRole>;
  teamMember?: InputMaybe<UserTeamMemberCreateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesCreateNestedInput>;
};

export type UserEnquiryCreateNestedInput = {
  connect?: InputMaybe<Array<EnquiryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EnquiryConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<EnquiryCreateWithoutUserInput>>;
};

export type UserEnquiryUpdateNestedInput = {
  connect?: InputMaybe<Array<EnquiryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EnquiryConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<EnquiryCreateWithoutUserInput>>;
  delete?: InputMaybe<Array<EnquiryExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EnquiryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EnquiryExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<EnquiryWhereUniqueInput>>;
  update?: InputMaybe<Array<EnquiryUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<EnquiryUpdateManyInput>>;
  upsert?: InputMaybe<Array<EnquiryUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activationState?: InputMaybe<ActivationStateEnumFilter>;
  avatarKey?: InputMaybe<StringNullableFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachingExposure?: InputMaybe<StringNullableFilter>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiry?: InputMaybe<EnquiryFilter>;
  firstName?: InputMaybe<StringFilter>;
  hubspotContactId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  isImpersonating?: InputMaybe<BooleanFilter>;
  jobLevel?: InputMaybe<StringNullableFilter>;
  lastLogin?: InputMaybe<AwsDateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  packages?: InputMaybe<UserPackageFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  reviews?: InputMaybe<CoachReviewFilter>;
  role?: InputMaybe<UserRoleEnumFilter>;
  settings?: InputMaybe<UserSettingFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  timezone?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<UserTypeEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userOnboardingChoices?: InputMaybe<UserOnboardingChoiceFilter>;
};

export type UserFilter = {
  every?: InputMaybe<UserWhereInputWithoutNullables>;
  none?: InputMaybe<UserWhereInputWithoutNullables>;
  some?: InputMaybe<UserWhereInputWithoutNullables>;
};

export type UserIdKeyFieldsInput = {
  key: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserOnboardingChoice = {
  __typename?: 'UserOnboardingChoice';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type UserOnboardingChoiceConnectOrCreateWithoutUserInput = {
  create: UserOnboardingChoiceCreateWithoutUserInput;
  where: UserOnboardingChoiceWhereUniqueInput;
};

export type UserOnboardingChoiceCreateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserOnboardingChoiceUserCreateNestedInput>;
  value: Scalars['String']['input'];
};

export type UserOnboardingChoiceCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type UserOnboardingChoiceCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type UserOnboardingChoiceExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  NOT?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  OR?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserOnboardingChoiceFilter = {
  every?: InputMaybe<UserOnboardingChoiceWhereInputWithoutNullables>;
  none?: InputMaybe<UserOnboardingChoiceWhereInputWithoutNullables>;
  some?: InputMaybe<UserOnboardingChoiceWhereInputWithoutNullables>;
};

export type UserOnboardingChoiceOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  key?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type UserOnboardingChoiceScalarWhereInput = {
  AND?: InputMaybe<Array<UserOnboardingChoiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserOnboardingChoiceScalarWhereInput>>;
  OR?: InputMaybe<Array<UserOnboardingChoiceScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserOnboardingChoiceUpdateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserOnboardingChoiceUserUpdateNestedInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UserOnboardingChoiceUpdateManyInput = {
  data: UserOnboardingChoiceUpdateInput;
  where: UserOnboardingChoiceScalarWhereInput;
};

export type UserOnboardingChoiceUpdateWithWhereUniqueWithoutUserInput = {
  data: UserOnboardingChoiceUpdateWithoutUserInput;
  where: UserOnboardingChoiceExtendedWhereUniqueInput;
};

export type UserOnboardingChoiceUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UserOnboardingChoiceUpsertWithWhereUniqueWithoutUserInput = {
  create: UserOnboardingChoiceCreateWithoutUserInput;
  update: UserOnboardingChoiceUpdateWithoutUserInput;
  where: UserOnboardingChoiceExtendedWhereUniqueInput;
};

export type UserOnboardingChoiceUpsertWithoutUserInput = {
  create: UserOnboardingChoiceCreateWithoutUserInput;
  update: UserOnboardingChoiceUpdateWithoutUserInput;
};

export type UserOnboardingChoiceUserCreateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutUserOnboardingChoiceInput>;
  create?: InputMaybe<UserCreateWithoutUserOnboardingChoiceInput>;
};

export type UserOnboardingChoiceUserUpdateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutUserOnboardingChoiceInput>;
  create?: InputMaybe<UserCreateWithoutUserOnboardingChoiceInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutUserOnboardingChoiceInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserOnboardingChoiceInput>;
};

export type UserOnboardingChoiceWhereInput = {
  AND?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  NOT?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  OR?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserOnboardingChoiceWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  NOT?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  OR?: InputMaybe<Array<UserOnboardingChoiceWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserOnboardingChoiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UserOperationInput = {
  hubspotContactId?: InputMaybe<IntOperation>;
};

export type UserOrderByInput = {
  activationState?: InputMaybe<OrderByArg>;
  avatarKey?: InputMaybe<OrderByArg>;
  coach?: InputMaybe<CoachOrderByInput>;
  coachingExposure?: InputMaybe<OrderByArg>;
  cognitoId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  email?: InputMaybe<OrderByArg>;
  enquiry?: InputMaybe<EnquiryOrderByInput>;
  firstName?: InputMaybe<OrderByArg>;
  hubspotContactId?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  intercomUserId?: InputMaybe<OrderByArg>;
  isActive?: InputMaybe<OrderByArg>;
  isImpersonating?: InputMaybe<OrderByArg>;
  jobLevel?: InputMaybe<OrderByArg>;
  lastLogin?: InputMaybe<OrderByArg>;
  lastName?: InputMaybe<OrderByArg>;
  packages?: InputMaybe<UserPackageOrderByInput>;
  phone?: InputMaybe<OrderByArg>;
  reviews?: InputMaybe<CoachReviewOrderByInput>;
  role?: InputMaybe<OrderByArg>;
  settings?: InputMaybe<UserSettingOrderByInput>;
  teamMember?: InputMaybe<TeamMemberOrderByInput>;
  timezone?: InputMaybe<OrderByArg>;
  type?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  userOnboardingChoices?: InputMaybe<UserOnboardingChoiceOrderByInput>;
};

export type UserPackage = {
  __typename?: 'UserPackage';
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  endDate?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  sessionCount?: Maybe<Scalars['Int']['output']>;
  sessions: Array<Session>;
  updatedAt: Scalars['AWSDateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserPackageCoachCreateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutUserPackageInput>;
  create?: InputMaybe<CoachCreateWithoutUserPackageInput>;
};

export type UserPackageCoachUpdateNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachConnectOrCreateWithoutUserPackageInput>;
  create?: InputMaybe<CoachCreateWithoutUserPackageInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<CoachUpdateWithoutUserPackageInput>;
  upsert?: InputMaybe<CoachUpsertWithoutUserPackageInput>;
};

export type UserPackageConnectOrCreateWithoutCoachInput = {
  create: UserPackageCreateWithoutCoachInput;
  where: UserPackageWhereUniqueInput;
};

export type UserPackageConnectOrCreateWithoutSessionInput = {
  create: UserPackageCreateWithoutSessionInput;
  where: UserPackageWhereUniqueInput;
};

export type UserPackageConnectOrCreateWithoutUserInput = {
  create: UserPackageCreateWithoutUserInput;
  where: UserPackageWhereUniqueInput;
};

export type UserPackageCreateInput = {
  coach?: InputMaybe<UserPackageCoachCreateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  sessions?: InputMaybe<UserPackageSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserPackageUserCreateNestedInput>;
};

export type UserPackageCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UserPackageCreateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  sessions?: InputMaybe<UserPackageSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserPackageUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserPackageCreateWithoutSessionInput = {
  coach?: InputMaybe<UserPackageCoachCreateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserPackageUserCreateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserPackageCreateWithoutUserInput = {
  coach?: InputMaybe<UserPackageCoachCreateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  sessions?: InputMaybe<UserPackageSessionsCreateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UserPackageExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<UserPackageWhereInput>>;
  NOT?: InputMaybe<Array<UserPackageWhereInput>>;
  OR?: InputMaybe<Array<UserPackageWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endDate?: InputMaybe<AwsDateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<StringNullableFilter>;
  plan?: InputMaybe<StringNullableFilter>;
  sessionCount?: InputMaybe<IntNullableFilter>;
  sessions?: InputMaybe<SessionFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilter>;
};

export type UserPackageFilter = {
  every?: InputMaybe<UserPackageWhereInputWithoutNullables>;
  none?: InputMaybe<UserPackageWhereInputWithoutNullables>;
  some?: InputMaybe<UserPackageWhereInputWithoutNullables>;
};

export type UserPackageOperationInput = {
  sessionCount?: InputMaybe<IntOperation>;
};

export type UserPackageOrderByInput = {
  coach?: InputMaybe<CoachOrderByInput>;
  coachId?: InputMaybe<OrderByArg>;
  createdAt?: InputMaybe<OrderByArg>;
  endDate?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  label?: InputMaybe<OrderByArg>;
  plan?: InputMaybe<OrderByArg>;
  sessionCount?: InputMaybe<OrderByArg>;
  sessions?: InputMaybe<SessionOrderByInput>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
};

export type UserPackageScalarWhereInput = {
  AND?: InputMaybe<Array<UserPackageScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserPackageScalarWhereInput>>;
  OR?: InputMaybe<Array<UserPackageScalarWhereInput>>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endDate?: InputMaybe<AwsDateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringNullableFilter>;
  plan?: InputMaybe<StringNullableFilter>;
  sessionCount?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserPackageSessionsCreateNestedInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionConnectOrCreateWithoutUserPackageInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutUserPackageInput>>;
};

export type UserPackageSessionsUpdateNestedInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionConnectOrCreateWithoutUserPackageInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutUserPackageInput>>;
  delete?: InputMaybe<Array<SessionExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionUpdateWithWhereUniqueWithoutUserPackageInput>>;
  updateMany?: InputMaybe<Array<SessionUpdateManyInput>>;
  upsert?: InputMaybe<Array<SessionUpsertWithWhereUniqueWithoutUserPackageInput>>;
};

export type UserPackageUpdateInput = {
  coach?: InputMaybe<UserPackageCoachUpdateNestedInput>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  sessions?: InputMaybe<UserPackageSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserPackageUserUpdateNestedInput>;
};

export type UserPackageUpdateManyInput = {
  data: UserPackageUpdateInput;
  where: UserPackageScalarWhereInput;
};

export type UserPackageUpdateWithWhereUniqueWithoutCoachInput = {
  data: UserPackageUpdateWithoutCoachInput;
  where: UserPackageExtendedWhereUniqueInput;
};

export type UserPackageUpdateWithWhereUniqueWithoutSessionInput = {
  data: UserPackageUpdateWithoutSessionInput;
  where: UserPackageExtendedWhereUniqueInput;
};

export type UserPackageUpdateWithWhereUniqueWithoutUserInput = {
  data: UserPackageUpdateWithoutUserInput;
  where: UserPackageExtendedWhereUniqueInput;
};

export type UserPackageUpdateWithoutCoachInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  sessions?: InputMaybe<UserPackageSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserPackageUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserPackageUpdateWithoutSessionInput = {
  coach?: InputMaybe<UserPackageCoachUpdateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserPackageUserUpdateNestedInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserPackageUpdateWithoutUserInput = {
  coach?: InputMaybe<UserPackageCoachUpdateNestedInput>;
  coachId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  sessions?: InputMaybe<UserPackageSessionsUpdateNestedInput>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UserPackageUpsertWithWhereUniqueWithoutCoachInput = {
  create: UserPackageCreateWithoutCoachInput;
  update: UserPackageUpdateWithoutCoachInput;
  where: UserPackageExtendedWhereUniqueInput;
};

export type UserPackageUpsertWithWhereUniqueWithoutSessionInput = {
  create: UserPackageCreateWithoutSessionInput;
  update: UserPackageUpdateWithoutSessionInput;
  where: UserPackageExtendedWhereUniqueInput;
};

export type UserPackageUpsertWithWhereUniqueWithoutUserInput = {
  create: UserPackageCreateWithoutUserInput;
  update: UserPackageUpdateWithoutUserInput;
  where: UserPackageExtendedWhereUniqueInput;
};

export type UserPackageUpsertWithoutCoachInput = {
  create: UserPackageCreateWithoutCoachInput;
  update: UserPackageUpdateWithoutCoachInput;
};

export type UserPackageUpsertWithoutSessionInput = {
  create: UserPackageCreateWithoutSessionInput;
  update: UserPackageUpdateWithoutSessionInput;
};

export type UserPackageUpsertWithoutUserInput = {
  create: UserPackageCreateWithoutUserInput;
  update: UserPackageUpdateWithoutUserInput;
};

export type UserPackageUserCreateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutUserPackageInput>;
  create?: InputMaybe<UserCreateWithoutUserPackageInput>;
};

export type UserPackageUserUpdateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutUserPackageInput>;
  create?: InputMaybe<UserCreateWithoutUserPackageInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutUserPackageInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserPackageInput>;
};

export type UserPackageWhereInput = {
  AND?: InputMaybe<Array<UserPackageWhereInput>>;
  NOT?: InputMaybe<Array<UserPackageWhereInput>>;
  OR?: InputMaybe<Array<UserPackageWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endDate?: InputMaybe<AwsDateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  label?: InputMaybe<StringNullableFilter>;
  plan?: InputMaybe<StringNullableFilter>;
  sessionCount?: InputMaybe<IntNullableFilter>;
  sessions?: InputMaybe<SessionFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilter>;
};

export type UserPackageWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<UserPackageWhereInput>>;
  NOT?: InputMaybe<Array<UserPackageWhereInput>>;
  OR?: InputMaybe<Array<UserPackageWhereInput>>;
  coach?: InputMaybe<CoachWhereInput>;
  coachId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  endDate?: InputMaybe<AwsDateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringNullableFilter>;
  plan?: InputMaybe<StringNullableFilter>;
  sessionCount?: InputMaybe<IntNullableFilter>;
  sessions?: InputMaybe<SessionFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilter>;
};

export type UserPackageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type UserPackagesCreateNestedInput = {
  connect?: InputMaybe<Array<UserPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPackageConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<UserPackageCreateWithoutUserInput>>;
};

export type UserPackagesUpdateNestedInput = {
  connect?: InputMaybe<Array<UserPackageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPackageConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<UserPackageCreateWithoutUserInput>>;
  delete?: InputMaybe<Array<UserPackageExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPackageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPackageExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPackageWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPackageUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserPackageUpdateManyInput>>;
  upsert?: InputMaybe<Array<UserPackageUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserReviewsCreateNestedInput = {
  connect?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachReviewConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<CoachReviewCreateWithoutUserInput>>;
};

export type UserReviewsUpdateNestedInput = {
  connect?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CoachReviewConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<CoachReviewCreateWithoutUserInput>>;
  delete?: InputMaybe<Array<CoachReviewExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CoachReviewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CoachReviewExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<CoachReviewWhereUniqueInput>>;
  update?: InputMaybe<Array<CoachReviewUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CoachReviewUpdateManyInput>>;
  upsert?: InputMaybe<Array<CoachReviewUpsertWithWhereUniqueWithoutUserInput>>;
};

export enum UserRole {
  Admin = 'admin',
  Guest = 'guest',
  Individual = 'individual',
  Sponsor = 'sponsor',
  Superadmin = 'superadmin',
  TeamMember = 'team_member'
}

export type UserRoleEnumFilter = {
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<UserRoleEnumFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type UserRoleEnumListFilter = {
  equals?: InputMaybe<Array<UserRole>>;
  has?: InputMaybe<UserRole>;
  hasEvery?: InputMaybe<Array<UserRole>>;
  hasSome?: InputMaybe<Array<UserRole>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  activationState?: InputMaybe<ActivationStateEnumFilter>;
  avatarKey?: InputMaybe<StringNullableFilter>;
  coachingExposure?: InputMaybe<StringNullableFilter>;
  cognitoId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailFilter>;
  firstName?: InputMaybe<StringFilter>;
  hubspotContactId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  intercomUserId?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  isImpersonating?: InputMaybe<BooleanFilter>;
  jobLevel?: InputMaybe<StringNullableFilter>;
  lastLogin?: InputMaybe<AwsDateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<UserRoleEnumFilter>;
  timezone?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<UserTypeEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type UserSettingConnectOrCreateWithoutUserInput = {
  create: UserSettingCreateWithoutUserInput;
  where: UserSettingWhereUniqueInput;
};

export type UserSettingCreateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserSettingUserCreateNestedInput>;
  value: Scalars['String']['input'];
};

export type UserSettingCreateManyInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type UserSettingCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value: Scalars['String']['input'];
};

export type UserSettingExtendedWhereUniqueInput = {
  AND?: InputMaybe<Array<UserSettingWhereInput>>;
  NOT?: InputMaybe<Array<UserSettingWhereInput>>;
  OR?: InputMaybe<Array<UserSettingWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
  userId_key?: InputMaybe<UserIdKeyFieldsInput>;
  value?: InputMaybe<StringFilter>;
};

export type UserSettingFilter = {
  every?: InputMaybe<UserSettingWhereInputWithoutNullables>;
  none?: InputMaybe<UserSettingWhereInputWithoutNullables>;
  some?: InputMaybe<UserSettingWhereInputWithoutNullables>;
};

export type UserSettingOrderByInput = {
  createdAt?: InputMaybe<OrderByArg>;
  id?: InputMaybe<OrderByArg>;
  key?: InputMaybe<OrderByArg>;
  updatedAt?: InputMaybe<OrderByArg>;
  user?: InputMaybe<UserOrderByInput>;
  userId?: InputMaybe<OrderByArg>;
  value?: InputMaybe<OrderByArg>;
};

export type UserSettingScalarWhereInput = {
  AND?: InputMaybe<Array<UserSettingScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserSettingScalarWhereInput>>;
  OR?: InputMaybe<Array<UserSettingScalarWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserSettingUpdateInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  user?: InputMaybe<UserSettingUserUpdateNestedInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UserSettingUpdateManyInput = {
  data: UserSettingUpdateInput;
  where: UserSettingScalarWhereInput;
};

export type UserSettingUpdateWithWhereUniqueWithoutUserInput = {
  data: UserSettingUpdateWithoutUserInput;
  where: UserSettingExtendedWhereUniqueInput;
};

export type UserSettingUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UserSettingUpsertWithWhereUniqueWithoutUserInput = {
  create: UserSettingCreateWithoutUserInput;
  update: UserSettingUpdateWithoutUserInput;
  where: UserSettingExtendedWhereUniqueInput;
};

export type UserSettingUpsertWithoutUserInput = {
  create: UserSettingCreateWithoutUserInput;
  update: UserSettingUpdateWithoutUserInput;
};

export type UserSettingUserCreateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutUserSettingInput>;
  create?: InputMaybe<UserCreateWithoutUserSettingInput>;
};

export type UserSettingUserUpdateNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserConnectOrCreateWithoutUserSettingInput>;
  create?: InputMaybe<UserCreateWithoutUserSettingInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutUserSettingInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserSettingInput>;
};

export type UserSettingWhereInput = {
  AND?: InputMaybe<Array<UserSettingWhereInput>>;
  NOT?: InputMaybe<Array<UserSettingWhereInput>>;
  OR?: InputMaybe<Array<UserSettingWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  is?: InputMaybe<NullArg>;
  isNot?: InputMaybe<NullArg>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserSettingWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<UserSettingWhereInput>>;
  NOT?: InputMaybe<Array<UserSettingWhereInput>>;
  OR?: InputMaybe<Array<UserSettingWhereInput>>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  userId_key?: InputMaybe<UserIdKeyFieldsInput>;
};

export type UserSettingsCreateNestedInput = {
  connect?: InputMaybe<Array<UserSettingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSettingConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<UserSettingCreateWithoutUserInput>>;
};

export type UserSettingsUpdateNestedInput = {
  connect?: InputMaybe<Array<UserSettingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSettingConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<UserSettingCreateWithoutUserInput>>;
  delete?: InputMaybe<Array<UserSettingExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSettingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSettingExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSettingWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSettingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserSettingUpdateManyInput>>;
  upsert?: InputMaybe<Array<UserSettingUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserTeamMemberCreateNestedInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateWithoutUserInput>;
  create?: InputMaybe<TeamMemberCreateWithoutUserInput>;
};

export type UserTeamMemberUpdateNestedInput = {
  connect?: InputMaybe<TeamMemberWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamMemberConnectOrCreateWithoutUserInput>;
  create?: InputMaybe<TeamMemberCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TeamMemberUpdateWithoutUserInput>;
  upsert?: InputMaybe<TeamMemberUpsertWithoutUserInput>;
};

export enum UserType {
  Coach = 'coach',
  TeamMember = 'teamMember'
}

export type UserTypeEnumFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<UserTypeEnumFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type UserTypeEnumListFilter = {
  equals?: InputMaybe<Array<UserType>>;
  has?: InputMaybe<UserType>;
  hasEvery?: InputMaybe<Array<UserType>>;
  hasSome?: InputMaybe<Array<UserType>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserUpdateInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachUpdateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiry?: InputMaybe<UserEnquiryUpdateNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  packages?: InputMaybe<UserPackagesUpdateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsUpdateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsUpdateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberUpdateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesUpdateNestedInput>;
};

export type UserUpdateManyInput = {
  data: UserUpdateInput;
  where: UserScalarWhereInput;
};

export type UserUpdateWithWhereUniqueWithoutCoachInput = {
  data: UserUpdateWithoutCoachInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutCoachReviewInput = {
  data: UserUpdateWithoutCoachReviewInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutEnquiryInput = {
  data: UserUpdateWithoutEnquiryInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutTeamMemberInput = {
  data: UserUpdateWithoutTeamMemberInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutUserOnboardingChoiceInput = {
  data: UserUpdateWithoutUserOnboardingChoiceInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutUserPackageInput = {
  data: UserUpdateWithoutUserPackageInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutUserSettingInput = {
  data: UserUpdateWithoutUserSettingInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpdateWithoutCoachInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiry?: InputMaybe<UserEnquiryUpdateNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  packages?: InputMaybe<UserPackagesUpdateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsUpdateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsUpdateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberUpdateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesUpdateNestedInput>;
};

export type UserUpdateWithoutCoachReviewInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachUpdateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiry?: InputMaybe<UserEnquiryUpdateNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  packages?: InputMaybe<UserPackagesUpdateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsUpdateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberUpdateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesUpdateNestedInput>;
};

export type UserUpdateWithoutEnquiryInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachUpdateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  packages?: InputMaybe<UserPackagesUpdateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsUpdateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsUpdateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberUpdateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesUpdateNestedInput>;
};

export type UserUpdateWithoutTeamMemberInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachUpdateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiry?: InputMaybe<UserEnquiryUpdateNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  packages?: InputMaybe<UserPackagesUpdateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsUpdateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsUpdateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesUpdateNestedInput>;
};

export type UserUpdateWithoutUserOnboardingChoiceInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachUpdateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiry?: InputMaybe<UserEnquiryUpdateNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  packages?: InputMaybe<UserPackagesUpdateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsUpdateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsUpdateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberUpdateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type UserUpdateWithoutUserPackageInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachUpdateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiry?: InputMaybe<UserEnquiryUpdateNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsUpdateNestedInput>;
  role?: InputMaybe<UserRole>;
  settings?: InputMaybe<UserSettingsUpdateNestedInput>;
  teamMember?: InputMaybe<UserTeamMemberUpdateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesUpdateNestedInput>;
};

export type UserUpdateWithoutUserSettingInput = {
  activationState?: InputMaybe<ActivationState>;
  avatarKey?: InputMaybe<Scalars['String']['input']>;
  coach?: InputMaybe<UserCoachUpdateNestedInput>;
  coachingExposure?: InputMaybe<Scalars['String']['input']>;
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  enquiry?: InputMaybe<UserEnquiryUpdateNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubspotContactId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  intercomUserId?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isImpersonating?: InputMaybe<Scalars['Boolean']['input']>;
  jobLevel?: InputMaybe<Scalars['String']['input']>;
  lastLogin?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  packages?: InputMaybe<UserPackagesUpdateNestedInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  reviews?: InputMaybe<UserReviewsUpdateNestedInput>;
  role?: InputMaybe<UserRole>;
  teamMember?: InputMaybe<UserTeamMemberUpdateNestedInput>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userOnboardingChoices?: InputMaybe<UserUserOnboardingChoicesUpdateNestedInput>;
};

export type UserUpsertWithWhereUniqueWithoutCoachInput = {
  create: UserCreateWithoutCoachInput;
  update: UserUpdateWithoutCoachInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutCoachReviewInput = {
  create: UserCreateWithoutCoachReviewInput;
  update: UserUpdateWithoutCoachReviewInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutEnquiryInput = {
  create: UserCreateWithoutEnquiryInput;
  update: UserUpdateWithoutEnquiryInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutTeamMemberInput = {
  create: UserCreateWithoutTeamMemberInput;
  update: UserUpdateWithoutTeamMemberInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutUserOnboardingChoiceInput = {
  create: UserCreateWithoutUserOnboardingChoiceInput;
  update: UserUpdateWithoutUserOnboardingChoiceInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutUserPackageInput = {
  create: UserCreateWithoutUserPackageInput;
  update: UserUpdateWithoutUserPackageInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutUserSettingInput = {
  create: UserCreateWithoutUserSettingInput;
  update: UserUpdateWithoutUserSettingInput;
  where: UserExtendedWhereUniqueInput;
};

export type UserUpsertWithoutCoachInput = {
  create: UserCreateWithoutCoachInput;
  update: UserUpdateWithoutCoachInput;
};

export type UserUpsertWithoutCoachReviewInput = {
  create: UserCreateWithoutCoachReviewInput;
  update: UserUpdateWithoutCoachReviewInput;
};

export type UserUpsertWithoutEnquiryInput = {
  create: UserCreateWithoutEnquiryInput;
  update: UserUpdateWithoutEnquiryInput;
};

export type UserUpsertWithoutTeamMemberInput = {
  create: UserCreateWithoutTeamMemberInput;
  update: UserUpdateWithoutTeamMemberInput;
};

export type UserUpsertWithoutUserOnboardingChoiceInput = {
  create: UserCreateWithoutUserOnboardingChoiceInput;
  update: UserUpdateWithoutUserOnboardingChoiceInput;
};

export type UserUpsertWithoutUserPackageInput = {
  create: UserCreateWithoutUserPackageInput;
  update: UserUpdateWithoutUserPackageInput;
};

export type UserUpsertWithoutUserSettingInput = {
  create: UserCreateWithoutUserSettingInput;
  update: UserUpdateWithoutUserSettingInput;
};

export type UserUserOnboardingChoicesCreateNestedInput = {
  connect?: InputMaybe<Array<UserOnboardingChoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserOnboardingChoiceConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<UserOnboardingChoiceCreateWithoutUserInput>>;
};

export type UserUserOnboardingChoicesUpdateNestedInput = {
  connect?: InputMaybe<Array<UserOnboardingChoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserOnboardingChoiceConnectOrCreateWithoutUserInput>>;
  create?: InputMaybe<Array<UserOnboardingChoiceCreateWithoutUserInput>>;
  delete?: InputMaybe<Array<UserOnboardingChoiceExtendedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserOnboardingChoiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserOnboardingChoiceExtendedWhereUniqueInput>>;
  set?: InputMaybe<Array<UserOnboardingChoiceWhereUniqueInput>>;
  update?: InputMaybe<Array<UserOnboardingChoiceUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserOnboardingChoiceUpdateManyInput>>;
  upsert?: InputMaybe<Array<UserOnboardingChoiceUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activationState?: InputMaybe<ActivationStateEnumFilter>;
  avatarKey?: InputMaybe<StringNullableFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachingExposure?: InputMaybe<StringNullableFilter>;
  cognitoId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailFilter>;
  enquiry?: InputMaybe<EnquiryFilter>;
  firstName?: InputMaybe<StringFilter>;
  hubspotContactId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  intercomUserId?: InputMaybe<StringNullableFilter>;
  is?: InputMaybe<NullArg>;
  isActive?: InputMaybe<BooleanFilter>;
  isImpersonating?: InputMaybe<BooleanFilter>;
  isNot?: InputMaybe<NullArg>;
  jobLevel?: InputMaybe<StringNullableFilter>;
  lastLogin?: InputMaybe<AwsDateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  packages?: InputMaybe<UserPackageFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  reviews?: InputMaybe<CoachReviewFilter>;
  role?: InputMaybe<UserRoleEnumFilter>;
  settings?: InputMaybe<UserSettingFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  timezone?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<UserTypeEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userOnboardingChoices?: InputMaybe<UserOnboardingChoiceFilter>;
};

export type UserWhereInputWithoutNullables = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activationState?: InputMaybe<ActivationStateEnumFilter>;
  avatarKey?: InputMaybe<StringNullableFilter>;
  coach?: InputMaybe<CoachWhereInput>;
  coachingExposure?: InputMaybe<StringNullableFilter>;
  cognitoId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<AwsDateTimeFilter>;
  email?: InputMaybe<AwsEmailFilter>;
  enquiry?: InputMaybe<EnquiryFilter>;
  firstName?: InputMaybe<StringFilter>;
  hubspotContactId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  intercomUserId?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  isImpersonating?: InputMaybe<BooleanFilter>;
  jobLevel?: InputMaybe<StringNullableFilter>;
  lastLogin?: InputMaybe<AwsDateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  packages?: InputMaybe<UserPackageFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  reviews?: InputMaybe<CoachReviewFilter>;
  role?: InputMaybe<UserRoleEnumFilter>;
  settings?: InputMaybe<UserSettingFilter>;
  teamMember?: InputMaybe<TeamMemberWhereInput>;
  timezone?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<UserTypeEnumFilter>;
  updatedAt?: InputMaybe<AwsDateTimeFilter>;
  userOnboardingChoices?: InputMaybe<UserOnboardingChoiceFilter>;
};

export type UserWhereUniqueInput = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['AWSEmail']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type AdminImpersonateCompanyMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;


export type AdminImpersonateCompanyMutation = { __typename?: 'Mutation', adminImpersonateCompany: { __typename?: 'ImpersonateCompanyResponse', success: boolean, companyId: string } };

export type CancelSessionMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
}>;


export type CancelSessionMutation = { __typename?: 'Mutation', cancelSession?: { __typename?: 'Session', id: string } | null };

export type CancelSessionCoachMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
}>;


export type CancelSessionCoachMutation = { __typename?: 'Mutation', cancelSessionCoach?: { __typename?: 'Session', id: string } | null };

export type CancelUserPackageMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type CancelUserPackageMutation = { __typename?: 'Mutation', cancelUserPackage: { __typename?: 'CancelUserPackageResponse', success?: boolean | null } };

export type CheckoutMutationVariables = Exact<{
  input: CheckoutInput;
}>;


export type CheckoutMutation = { __typename?: 'Mutation', checkout?: { __typename?: 'CheckoutResponse', success: boolean, message?: string | null } | null };

export type CoachAvailabilityQueryVariables = Exact<{
  coachId: Scalars['String']['input'];
  startsAt: Scalars['String']['input'];
  endsAt: Scalars['String']['input'];
}>;


export type CoachAvailabilityQuery = { __typename?: 'Query', coachAvailability?: Array<{ __typename?: 'CoachEvent', id: string, eventId: string, status?: string | null, startsAt?: any | null, endsAt?: any | null, timeZone?: string | null, iCalUID?: string | null, recurringEventId?: string | null, coach?: { __typename?: 'Coach', handle?: string | null } | null } | null> | null };

export type CountCoachesQueryVariables = Exact<{
  where?: InputMaybe<CoachWhereInput>;
  orderBy?: InputMaybe<Array<CoachOrderByInput> | CoachOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CountCoachesQuery = { __typename?: 'Query', countCoaches: number };

export type CountSessionsQueryVariables = Exact<{
  where?: InputMaybe<SessionWhereInput>;
}>;


export type CountSessionsQuery = { __typename?: 'Query', countSessions: number };

export type CountTeamMembersQueryVariables = Exact<{
  where?: InputMaybe<TeamMemberWhereInput>;
  orderBy?: InputMaybe<Array<TeamMemberOrderByInput> | TeamMemberOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CountTeamMembersQuery = { __typename?: 'Query', countTeamMembers: number };

export type CreateAccountMutationVariables = Exact<{
  input: CreateAccountInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount?: { __typename?: 'User', id: string } | null };

export type CreateBookingHoldTimerMutationVariables = Exact<{
  input: CreateBookingHoldTimerInput;
}>;


export type CreateBookingHoldTimerMutation = { __typename?: 'Mutation', createBookingHoldTimer?: { __typename?: 'BookingHold', id: string } | null };

export type CreateEnquiryMutationVariables = Exact<{
  data: EnquiryCreateInput;
}>;


export type CreateEnquiryMutation = { __typename?: 'Mutation', createEnquiry: { __typename?: 'Enquiry', id: string } };

export type CreateEnquiryChoiceMutationVariables = Exact<{
  data: EnquiryChoiceCreateInput;
}>;


export type CreateEnquiryChoiceMutation = { __typename?: 'Mutation', createEnquiryChoice: { __typename?: 'EnquiryChoice', id: string } };

export type CreateSessionMutationVariables = Exact<{
  input: CreateSessionInput;
}>;


export type CreateSessionMutation = { __typename?: 'Mutation', createSessionWithTags?: { __typename?: 'Session', id: string } | null };

export type CreateSessionReviewMutationVariables = Exact<{
  data: SessionReviewCreateInput;
}>;


export type CreateSessionReviewMutation = { __typename?: 'Mutation', createSessionReview: { __typename?: 'SessionReview', id: string, feelingScore: number } };

export type CreateUserOnobardingChoiceMutationVariables = Exact<{
  data: UserOnboardingChoiceCreateInput;
}>;


export type CreateUserOnobardingChoiceMutation = { __typename?: 'Mutation', createUserOnboardingChoice: { __typename?: 'UserOnboardingChoice', id: string } };

export type CreateUserPackageMutationVariables = Exact<{
  coachId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
}>;


export type CreateUserPackageMutation = { __typename?: 'Mutation', createUserPackage: { __typename?: 'UserPackage', id: string, user: { __typename?: 'User', id: string, teamMember?: { __typename?: 'TeamMember', id: string } | null } } };

export type AllCoachAvailabilityQueryVariables = Exact<{
  start: Scalars['AWSDateTime']['input'];
  end: Scalars['AWSDateTime']['input'];
}>;


export type AllCoachAvailabilityQuery = { __typename?: 'Query', allCoachAvailability?: Array<{ __typename?: 'AllCoachAvailabilityResponse', startsAt: any, endsAt: any } | null> | null };

export type GetLastBookingHoldQueryVariables = Exact<{
  where: BookingHoldWhereInput;
}>;


export type GetLastBookingHoldQuery = { __typename?: 'Query', listBookingHolds?: Array<{ __typename?: 'BookingHold', id: string, startsAt?: any | null, endsAt?: any | null, state: BookingHoldState, sessionStartsAt?: any | null, enquiryId: string, teamMember?: { __typename?: 'TeamMember', id: string } | null, coach?: { __typename?: 'Coach', id: string, displayName?: string | null, handle?: string | null } | null, enquiry: { __typename?: 'Enquiry', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, email: any } | null, enquiryChoices: Array<{ __typename?: 'EnquiryChoice', id: string, key: string, value: string }> } }> | null };

export type GetCoachQueryVariables = Exact<{
  where: CoachExtendedWhereUniqueInput;
}>;


export type GetCoachQuery = { __typename?: 'Query', getCoach?: { __typename: 'Coach', id: string, displayName?: string | null, handle?: string | null, trainingSchool?: string | null, headline?: string | null, bio?: string | null, coachingSince?: any | null, ycmbUrl?: string | null, createdAt: any, published?: boolean | null, position?: number | null, avatarKey?: string | null, user?: { __typename?: 'User', id: string, email: any, firstName: string, lastName: string, avatarKey?: string | null } | null, categories: Array<{ __typename?: 'CoachCategory', id: string, label: string, value: string }>, specialisms: Array<{ __typename?: 'CoachSpecialism', id: string, label: string, value: string }>, audienceExperiences: Array<{ __typename?: 'CoachAudienceExperience', id: string, label: string, value: string }>, approaches: Array<{ __typename?: 'CoachApproach', id: string, label: string, value: string }>, reviews: Array<{ __typename?: 'CoachReview', rating: number, body?: string | null, company?: { __typename?: 'Company', name: string } | null }> } | null };

export type GetCompanyQueryVariables = Exact<{
  where: CompanyExtendedWhereUniqueInput;
}>;


export type GetCompanyQuery = { __typename?: 'Query', getCompany?: { __typename: 'Company', id: string, name: string, accountType: AccountType, logoKey?: string | null, reportUrl?: string | null, createdAt: any, updatedAt: any, companyAdmin?: { __typename: 'CompanyAdmin', id: string, subscriptionActive?: boolean | null, chargebeeSubscriptionId?: string | null, chargebeeCustomerId?: string | null, chargebeeSubscriptionStatus?: string | null, chargebeeTotalSeats: number, chargebeeCancelledAt?: any | null, chargebeeCancelScheduleCreatedAt?: any | null, chargebeeCurrentTermEnd?: any | null, chargebeeCurrentTermStart?: any | null, createdAt: any, updatedAt: any } | null } | null };

export type GetCompanyAdminQueryVariables = Exact<{
  where: CompanyAdminExtendedWhereUniqueInput;
}>;


export type GetCompanyAdminQuery = { __typename?: 'Query', getCompanyAdmin?: { __typename: 'CompanyAdmin', id: string, subscriptionActive?: boolean | null, chargebeeSubscriptionId?: string | null, chargebeeCustomerId?: string | null, chargebeeSubscriptionStatus?: string | null, chargebeeTotalSeats: number, chargebeeCancelledAt?: any | null, chargebeeCancelScheduleCreatedAt?: any | null, chargebeeCurrentTermEnd?: any | null, chargebeeCurrentTermStart?: any | null, createdAt: any, updatedAt: any, company?: { __typename: 'Company', id: string, name: string, accountType: AccountType, logoKey?: string | null, reportUrl?: string | null, createdAt: any, updatedAt: any } | null } | null };

export type GetEnquiryQueryVariables = Exact<{
  where: EnquiryExtendedWhereUniqueInput;
}>;


export type GetEnquiryQuery = { __typename?: 'Query', getEnquiry?: { __typename?: 'Enquiry', id: string, email?: any | null, enquiryChoices: Array<{ __typename?: 'EnquiryChoice', key: string, value: string }> } | null };

export type GetHostedPageMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  itemPriceId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
}>;


export type GetHostedPageMutation = { __typename?: 'Mutation', getHostedPage: { __typename?: 'HostedPageType', id: string, type: string, url: string, state: string, failureReason?: string | null, passThruContent?: string | null, embed: boolean, createdAt: number, expiresAt: number, updatedAt: number, resourceVersion: string, checkoutInfo?: string | null, object?: string | null } };

export type GetLastSessionQueryVariables = Exact<{
  where?: InputMaybe<SessionWhereInput>;
}>;


export type GetLastSessionQuery = { __typename?: 'Query', listSessions?: Array<{ __typename?: 'Session', id: string, startsAt?: any | null }> | null };

export type GetPortalPageMutationVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;


export type GetPortalPageMutation = { __typename?: 'Mutation', getPortalPage: { __typename?: 'PortalPageType', id?: string | null, token?: string | null, accessUrl?: string | null, status?: string | null, createdAt?: number | null, customerId?: string | null } };

export type GetSessionQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetSessionQuery = { __typename?: 'Query', getSession?: { __typename?: 'Session', id: string, startsAt?: any | null, endsAt?: any | null, location?: string | null, state: SessionState, cancelledAt?: any | null, cancellationReason?: string | null, noShow?: boolean | null, coachId?: string | null, coach?: { __typename?: 'Coach', id: string, displayName?: string | null, bio?: string | null, handle?: string | null } | null, teamMember?: { __typename?: 'TeamMember', id: string } | null, sessionReview?: { __typename?: 'SessionReview', feelingScore: number, coachRating: number } | null, categories: Array<{ __typename?: 'SessionCategory', category: { __typename?: 'CoachCategory', label: string } }> } | null };

export type GetSessionByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetSessionByIdQuery = { __typename?: 'Query', getSession?: { __typename?: 'Session', id: string, startsAt?: any | null, endsAt?: any | null, location?: string | null, state: SessionState, cancelledAt?: any | null, cancellationReason?: string | null, noShow?: boolean | null, coachId?: string | null, coach?: { __typename?: 'Coach', id: string, displayName?: string | null, bio?: string | null, handle?: string | null, headline?: string | null } | null, teamMember?: { __typename?: 'TeamMember', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, timezone?: string | null } | null } | null, sessionReview?: { __typename?: 'SessionReview', feelingScore: number, coachRating: number } | null, categories: Array<{ __typename?: 'SessionCategory', category: { __typename?: 'CoachCategory', label: string } }>, userPackage?: { __typename?: 'UserPackage', id: string, plan?: string | null, sessionCount?: number | null } | null } | null };

export type GetSponsorQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;


export type GetSponsorQuery = { __typename?: 'Query', listUsers?: Array<{ __typename?: 'User', id: string, email: any }> | null };

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars['AWSEmail']['input'];
}>;


export type GetUserByEmailQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id: string } | null };

export type InviteTeamMembersMutationVariables = Exact<{
  input: InviteTeamMembersInput;
}>;


export type InviteTeamMembersMutation = { __typename?: 'Mutation', inviteTeamMembers: { __typename?: 'InviteTeamMembersResponse', success?: Array<{ __typename?: 'TeamMember', id: string }> | null, failed?: Array<{ __typename?: 'FailedInviteTeamMemberResponse', reason: string, teamMemberEmail: string }> | null } };

export type ListCoachAudienceApproachesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachAudienceApproachesQuery = { __typename?: 'Query', listCoachApproaches?: Array<{ __typename?: 'CoachApproach', id: string, label: string, value: string }> | null };

export type ListCoachAudienceExperiencesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachAudienceExperiencesQuery = { __typename?: 'Query', listCoachAudienceExperiences?: Array<{ __typename?: 'CoachAudienceExperience', id: string, label: string, value: string }> | null };

export type ListCoachCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachCategoriesQuery = { __typename?: 'Query', listCoachCategories?: Array<{ __typename?: 'CoachCategory', id: string, label: string, value: string }> | null };

export type ListCoachPackagesQueryVariables = Exact<{
  where?: InputMaybe<CoachPackageWhereInput>;
}>;


export type ListCoachPackagesQuery = { __typename?: 'Query', listCoachPackages?: Array<{ __typename?: 'CoachPackage', id: string, label: string, value: string }> | null };

export type ListCoachSessionReviewsQueryVariables = Exact<{
  coachId: Scalars['String']['input'];
}>;


export type ListCoachSessionReviewsQuery = { __typename?: 'Query', listSessionReviews?: Array<{ __typename?: 'SessionReview', feelingScore: number, coachRating: number, testimonial?: string | null, isHidden?: boolean | null, session?: { __typename?: 'Session', teamMember?: { __typename?: 'TeamMember', company?: { __typename?: 'Company', name: string } | null } | null } | null }> | null };

export type ListCoachSpecialismsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachSpecialismsQuery = { __typename?: 'Query', listCoachSpecialisms?: Array<{ __typename?: 'CoachSpecialism', id: string, label: string, value: string }> | null };

export type ListCoachTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCoachTagsQuery = { __typename?: 'Query', listCoachSpecialisms?: Array<{ __typename?: 'CoachSpecialism', id: string, label: string, value: string }> | null, listCoachCategories?: Array<{ __typename?: 'CoachCategory', id: string, label: string, value: string }> | null, listCoachAudienceExperiences?: Array<{ __typename?: 'CoachAudienceExperience', id: string, label: string, value: string }> | null, listCoachApproaches?: Array<{ __typename?: 'CoachApproach', id: string, label: string, value: string }> | null };

export type ListCoachesQueryVariables = Exact<{
  where?: InputMaybe<CoachWhereInput>;
  orderBy?: InputMaybe<Array<CoachOrderByInput> | CoachOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListCoachesQuery = { __typename?: 'Query', listCoaches?: Array<{ __typename?: 'Coach', id: string, displayName?: string | null, trainingSchool?: string | null, headline?: string | null, bio?: string | null, coachingSince?: any | null, ycmbUrl?: string | null, createdAt: any, published?: boolean | null, handle?: string | null, position?: number | null, avatarKey?: string | null, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, avatarKey?: string | null } | null, categories: Array<{ __typename?: 'CoachCategory', id: string, label: string, value: string }>, specialisms: Array<{ __typename?: 'CoachSpecialism', id: string, label: string, value: string }>, audienceExperiences: Array<{ __typename?: 'CoachAudienceExperience', id: string, label: string, value: string }>, approaches: Array<{ __typename?: 'CoachApproach', id: string, label: string, value: string }>, reviews: Array<{ __typename?: 'CoachReview', body?: string | null, rating: number, company?: { __typename?: 'Company', name: string } | null }>, sessions: Array<{ __typename?: 'Session', sessionReview?: { __typename?: 'SessionReview', feelingScore: number, coachRating: number, testimonial?: string | null } | null, teamMember?: { __typename?: 'TeamMember', user?: { __typename?: 'User', firstName: string, lastName: string } | null, company?: { __typename?: 'Company', name: string } | null } | null }> }> | null };

export type ListFeaturesQueryVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;


export type ListFeaturesQuery = { __typename?: 'Query', listFeatures?: Array<{ __typename?: 'Feature', status: FeatureStatus, name: string }> | null, listFeatureCompanies?: Array<{ __typename?: 'FeatureCompany', id: string, feature: { __typename?: 'Feature', name: string } }> | null };

export type ListSessionsQueryVariables = Exact<{
  where?: InputMaybe<SessionWhereInput>;
  orderBy?: InputMaybe<Array<SessionOrderByInput> | SessionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListSessionsQuery = { __typename?: 'Query', listSessions?: Array<{ __typename?: 'Session', id: string, externalId?: string | null, startsAt?: any | null, endsAt?: any | null, state: SessionState, cancelledAt?: any | null, cancellationReason?: string | null, noShow?: boolean | null, location?: string | null, coachId?: string | null, coach?: { __typename?: 'Coach', id: string, displayName?: string | null, bio?: string | null, handle?: string | null, headline?: string | null } | null, teamMember?: { __typename?: 'TeamMember', id: string, user?: { __typename?: 'User', id: string, firstName: string, lastName: string, coachingExposure?: string | null, role: UserRole } | null, company?: { __typename?: 'Company', name: string } | null } | null, packages: Array<{ __typename?: 'SessionPackage', package: { __typename?: 'CoachPackage', label: string } }>, categories: Array<{ __typename?: 'SessionCategory', category: { __typename?: 'CoachCategory', label: string } }>, sessionReview?: { __typename?: 'SessionReview', id: string, feelingScore: number, coachRating: number, tookPlace: boolean } | null, userPackage?: { __typename?: 'UserPackage', id: string, plan?: string | null, sessionCount?: number | null } | null }> | null };

export type ListTeamMembersQueryVariables = Exact<{
  where?: InputMaybe<TeamMemberWhereInput>;
  orderBy?: InputMaybe<Array<TeamMemberOrderByInput> | TeamMemberOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  activeWhere?: InputMaybe<TeamMemberWhereInput>;
}>;


export type ListTeamMembersQuery = { __typename?: 'Query', count: number, activeCount: number, listTeamMembers?: Array<{ __typename?: 'TeamMember', id: string, isManager?: boolean | null, jobTitle?: string | null, createdAt: any, updatedAt: any, companyId?: string | null, user?: { __typename?: 'User', id: string, role: UserRole, isImpersonating: boolean, email: any, firstName: string, lastName: string, avatarKey?: string | null, isActive: boolean, activationState: ActivationState, createdAt: any, updatedAt: any } | null }> | null };

export type ListUserPackagesQueryVariables = Exact<{
  where?: InputMaybe<UserPackageWhereInput>;
  orderBy?: InputMaybe<Array<UserPackageOrderByInput> | UserPackageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListUserPackagesQuery = { __typename?: 'Query', listUserPackages?: Array<{ __typename?: 'UserPackage', id: string, label?: string | null, sessionCount?: number | null, endDate?: any | null, createdAt: any, coach?: { __typename?: 'Coach', displayName?: string | null } | null, sessions: Array<{ __typename?: 'Session', id: string }> }> | null };

export type MarkNoShowMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type MarkNoShowMutation = { __typename?: 'Mutation', markNoShow?: { __typename?: 'Session', id: string } | null };

export type RemoveTeamMemberMutationVariables = Exact<{
  teamMemberId: Scalars['String']['input'];
}>;


export type RemoveTeamMemberMutation = { __typename?: 'Mutation', removeTeamMember: boolean };

export type ResendTeamMemberInviteMutationVariables = Exact<{
  teamMemberId: Scalars['String']['input'];
}>;


export type ResendTeamMemberInviteMutation = { __typename?: 'Mutation', resendTeamMemberInvite: { __typename?: 'TeamMember', id: string } };

export type RestoreTeamMemberMutationVariables = Exact<{
  teamMemberId: Scalars['String']['input'];
}>;


export type RestoreTeamMemberMutation = { __typename?: 'Mutation', restoreTeamMember: boolean };

export type SearchCoachesQueryVariables = Exact<{
  where?: InputMaybe<CoachWhereInput>;
  availability?: InputMaybe<CoachAvailabilityInput>;
  orderBy?: InputMaybe<Array<InputMaybe<CoachOrderByInput>> | InputMaybe<CoachOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchCoachesQuery = { __typename?: 'Query', searchCoaches?: Array<{ __typename?: 'Coach', id: string, displayName?: string | null, trainingSchool?: string | null, headline?: string | null, bio?: string | null, handle?: string | null, avatarKey?: string | null, excludeIndividuals: boolean, categories: Array<{ __typename?: 'CoachCategory', label: string }>, specialisms: Array<{ __typename?: 'CoachSpecialism', label: string }>, audienceExperiences: Array<{ __typename?: 'CoachAudienceExperience', label: string }>, approaches: Array<{ __typename?: 'CoachApproach', label: string }>, reviews: Array<{ __typename?: 'CoachReview', body?: string | null, rating: number, company?: { __typename?: 'Company', name: string } | null }> } | null> | null };

export type SearchCoachesByAvailabilityQueryVariables = Exact<{
  where?: InputMaybe<CoachWhereInput>;
  availability?: InputMaybe<CoachAvailabilityInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchCoachesByAvailabilityQuery = { __typename?: 'Query', searchCoachesByAvailability?: Array<{ __typename?: 'Coach', id: string, displayName?: string | null, trainingSchool?: string | null, headline?: string | null, bio?: string | null, handle?: string | null, avatarKey?: string | null, excludeIndividuals: boolean, categories: Array<{ __typename?: 'CoachCategory', label: string }>, specialisms: Array<{ __typename?: 'CoachSpecialism', label: string }>, audienceExperiences: Array<{ __typename?: 'CoachAudienceExperience', label: string }>, approaches: Array<{ __typename?: 'CoachApproach', label: string }>, reviews: Array<{ __typename?: 'CoachReview', body?: string | null, rating: number, company?: { __typename?: 'Company', name: string } | null }>, events: Array<{ __typename?: 'CoachEvent', id: string, eventId: string, status?: string | null, startsAt?: any | null, endsAt?: any | null, timeZone?: string | null, iCalUID?: string | null, recurringEventId?: string | null, coach?: { __typename?: 'Coach', handle?: string | null } | null }> } | null> | null };

export type UpdateCompanyMutationVariables = Exact<{
  where: CompanyExtendedWhereUniqueInput;
  data: CompanyUpdateInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename: 'Company', id: string, name: string, accountType: AccountType, reportUrl?: string | null, logoKey?: string | null, createdAt: any, updatedAt: any } };

export type UpdateCompanyAdminMutationVariables = Exact<{
  where: CompanyAdminExtendedWhereUniqueInput;
  data: CompanyAdminUpdateInput;
}>;


export type UpdateCompanyAdminMutation = { __typename?: 'Mutation', updateCompanyAdmin: { __typename: 'CompanyAdmin', id: string, subscriptionActive?: boolean | null } };

export type UpdateCompanySponsorMutationVariables = Exact<{
  companyId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type UpdateCompanySponsorMutation = { __typename?: 'Mutation', updateCompanySponsor?: { __typename?: 'Company', id: string } | null };

export type UpdateSessionMutationVariables = Exact<{
  where: SessionExtendedWhereUniqueInput;
  data?: InputMaybe<SessionUpdateInput>;
}>;


export type UpdateSessionMutation = { __typename?: 'Mutation', updateSession: { __typename?: 'Session', id: string } };

export type UpdateTeamMemberMutationVariables = Exact<{
  where: TeamMemberExtendedWhereUniqueInput;
  data: TeamMemberUpdateInput;
}>;


export type UpdateTeamMemberMutation = { __typename?: 'Mutation', updateTeamMember: { __typename: 'TeamMember', id: string, isManager?: boolean | null, jobTitle?: string | null, companyId?: string | null, createdAt: any, updatedAt: any, user?: { __typename?: 'User', id: string } | null } };

export type UpdateUserMutationVariables = Exact<{
  where: UserExtendedWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename: 'User', id: string, cognitoId?: string | null, role: UserRole, isImpersonating: boolean, email: any, firstName: string, lastName: string, avatarKey?: string | null, isActive: boolean, createdAt: any, updatedAt: any } };

export type UpdateUserPackageMutationVariables = Exact<{
  where: UserPackageExtendedWhereUniqueInput;
  data?: InputMaybe<UserPackageUpdateInput>;
}>;


export type UpdateUserPackageMutation = { __typename?: 'Mutation', updateUserPackage: { __typename?: 'UserPackage', id: string } };

export type UpdateUserToIndividualMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type UpdateUserToIndividualMutation = { __typename?: 'Mutation', updateUserToIndividual?: { __typename?: 'User', id: string } | null };

export type UpsertBookingHoldMutationVariables = Exact<{
  create: BookingHoldCreateInput;
  update: BookingHoldUpdateInput;
  where: BookingHoldExtendedWhereUniqueInput;
}>;


export type UpsertBookingHoldMutation = { __typename?: 'Mutation', upsertBookingHold: { __typename?: 'BookingHold', id: string } };

export type UpsertEnquiryChoiceMutationVariables = Exact<{
  create: EnquiryChoiceCreateInput;
  update: EnquiryChoiceUpdateInput;
  where: EnquiryChoiceExtendedWhereUniqueInput;
}>;


export type UpsertEnquiryChoiceMutation = { __typename?: 'Mutation', upsertEnquiryChoice: { __typename?: 'EnquiryChoice', id: string } };

export type WhoAmIQueryVariables = Exact<{
  cognitoId: Scalars['String']['input'];
}>;


export type WhoAmIQuery = { __typename?: 'Query', getUser?: { __typename: 'User', id: string, cognitoId?: string | null, role: UserRole, isImpersonating: boolean, email: any, firstName: string, lastName: string, avatarKey?: string | null, isActive: boolean, activationState: ActivationState, createdAt: any, updatedAt: any, phone?: string | null, timezone?: string | null, teamMember?: { __typename: 'TeamMember', id: string, jobTitle?: string | null, isManager?: boolean | null, createdAt: any, updatedAt: any, companyId?: string | null, company?: { __typename?: 'Company', name: string, features: Array<{ __typename?: 'FeatureCompany', feature: { __typename?: 'Feature', name: string, status: FeatureStatus } }> } | null } | null, coach?: { __typename?: 'Coach', id: string } | null, userOnboardingChoices: Array<{ __typename?: 'UserOnboardingChoice', id: string, key: string }>, settings: Array<{ __typename?: 'UserSetting', id: string, key: string, value: string }>, packages: Array<{ __typename?: 'UserPackage', id: string, sessionCount?: number | null, createdAt: any, endDate?: any | null, label?: string | null, coachId?: string | null, plan?: string | null, coach?: { __typename?: 'Coach', displayName?: string | null, handle?: string | null } | null, sessions: Array<{ __typename?: 'Session', id: string, createdAt: any, startsAt?: any | null, state: SessionState, cancelledAt?: any | null }> }> } | null };



export const AdminImpersonateCompanyDocument = `
    mutation AdminImpersonateCompany($userId: String!, $companyId: String!) {
  adminImpersonateCompany(userId: $userId, companyId: $companyId) {
    success
    companyId
  }
}
    `;

export const useAdminImpersonateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminImpersonateCompanyMutation, TError, AdminImpersonateCompanyMutationVariables, TContext>) => {
    
    return useMutation<AdminImpersonateCompanyMutation, TError, AdminImpersonateCompanyMutationVariables, TContext>(
      {
    mutationKey: ['AdminImpersonateCompany'],
    mutationFn: (variables?: AdminImpersonateCompanyMutationVariables) => fetchData<AdminImpersonateCompanyMutation, AdminImpersonateCompanyMutationVariables>(AdminImpersonateCompanyDocument, variables)(),
    ...options
  }
    )};

export const CancelSessionDocument = `
    mutation CancelSession($sessionId: String!, $reason: String!) {
  cancelSession(sessionId: $sessionId, reason: $reason) {
    id
  }
}
    `;

export const useCancelSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CancelSessionMutation, TError, CancelSessionMutationVariables, TContext>) => {
    
    return useMutation<CancelSessionMutation, TError, CancelSessionMutationVariables, TContext>(
      {
    mutationKey: ['CancelSession'],
    mutationFn: (variables?: CancelSessionMutationVariables) => fetchData<CancelSessionMutation, CancelSessionMutationVariables>(CancelSessionDocument, variables)(),
    ...options
  }
    )};

export const CancelSessionCoachDocument = `
    mutation CancelSessionCoach($sessionId: String!, $reason: String!) {
  cancelSessionCoach(sessionId: $sessionId, reason: $reason) {
    id
  }
}
    `;

export const useCancelSessionCoachMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CancelSessionCoachMutation, TError, CancelSessionCoachMutationVariables, TContext>) => {
    
    return useMutation<CancelSessionCoachMutation, TError, CancelSessionCoachMutationVariables, TContext>(
      {
    mutationKey: ['CancelSessionCoach'],
    mutationFn: (variables?: CancelSessionCoachMutationVariables) => fetchData<CancelSessionCoachMutation, CancelSessionCoachMutationVariables>(CancelSessionCoachDocument, variables)(),
    ...options
  }
    )};

export const CancelUserPackageDocument = `
    mutation cancelUserPackage($id: String!) {
  cancelUserPackage(id: $id) {
    success
  }
}
    `;

export const useCancelUserPackageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CancelUserPackageMutation, TError, CancelUserPackageMutationVariables, TContext>) => {
    
    return useMutation<CancelUserPackageMutation, TError, CancelUserPackageMutationVariables, TContext>(
      {
    mutationKey: ['cancelUserPackage'],
    mutationFn: (variables?: CancelUserPackageMutationVariables) => fetchData<CancelUserPackageMutation, CancelUserPackageMutationVariables>(CancelUserPackageDocument, variables)(),
    ...options
  }
    )};

export const CheckoutDocument = `
    mutation Checkout($input: CheckoutInput!) {
  checkout(input: $input) {
    success
    message
  }
}
    `;

export const useCheckoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CheckoutMutation, TError, CheckoutMutationVariables, TContext>) => {
    
    return useMutation<CheckoutMutation, TError, CheckoutMutationVariables, TContext>(
      {
    mutationKey: ['Checkout'],
    mutationFn: (variables?: CheckoutMutationVariables) => fetchData<CheckoutMutation, CheckoutMutationVariables>(CheckoutDocument, variables)(),
    ...options
  }
    )};

export const CoachAvailabilityDocument = `
    query CoachAvailability($coachId: String!, $startsAt: String!, $endsAt: String!) {
  coachAvailability(coachId: $coachId, startsAt: $startsAt, endsAt: $endsAt) {
    id
    eventId
    status
    startsAt
    endsAt
    timeZone
    iCalUID
    recurringEventId
    coach {
      handle
    }
  }
}
    `;

export const useCoachAvailabilityQuery = <
      TData = CoachAvailabilityQuery,
      TError = unknown
    >(
      variables: CoachAvailabilityQueryVariables,
      options?: Omit<UseQueryOptions<CoachAvailabilityQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CoachAvailabilityQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CoachAvailabilityQuery, TError, TData>(
      {
    queryKey: ['CoachAvailability', variables],
    queryFn: fetchData<CoachAvailabilityQuery, CoachAvailabilityQueryVariables>(CoachAvailabilityDocument, variables),
    ...options
  }
    )};

export const CountCoachesDocument = `
    query countCoaches($where: CoachWhereInput, $orderBy: [CoachOrderByInput!], $skip: Int, $take: Int) {
  countCoaches(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
}
    `;

export const useCountCoachesQuery = <
      TData = CountCoachesQuery,
      TError = unknown
    >(
      variables?: CountCoachesQueryVariables,
      options?: Omit<UseQueryOptions<CountCoachesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CountCoachesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CountCoachesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['countCoaches'] : ['countCoaches', variables],
    queryFn: fetchData<CountCoachesQuery, CountCoachesQueryVariables>(CountCoachesDocument, variables),
    ...options
  }
    )};

export const CountSessionsDocument = `
    query CountSessions($where: SessionWhereInput) {
  countSessions(where: $where)
}
    `;

export const useCountSessionsQuery = <
      TData = CountSessionsQuery,
      TError = unknown
    >(
      variables?: CountSessionsQueryVariables,
      options?: Omit<UseQueryOptions<CountSessionsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CountSessionsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CountSessionsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['CountSessions'] : ['CountSessions', variables],
    queryFn: fetchData<CountSessionsQuery, CountSessionsQueryVariables>(CountSessionsDocument, variables),
    ...options
  }
    )};

export const CountTeamMembersDocument = `
    query countTeamMembers($where: TeamMemberWhereInput, $orderBy: [TeamMemberOrderByInput!], $skip: Int, $take: Int) {
  countTeamMembers(where: $where, orderBy: $orderBy, skip: $skip, take: $take)
}
    `;

export const useCountTeamMembersQuery = <
      TData = CountTeamMembersQuery,
      TError = unknown
    >(
      variables?: CountTeamMembersQueryVariables,
      options?: Omit<UseQueryOptions<CountTeamMembersQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CountTeamMembersQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CountTeamMembersQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['countTeamMembers'] : ['countTeamMembers', variables],
    queryFn: fetchData<CountTeamMembersQuery, CountTeamMembersQueryVariables>(CountTeamMembersDocument, variables),
    ...options
  }
    )};

export const CreateAccountDocument = `
    mutation CreateAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    id
  }
}
    `;

export const useCreateAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateAccountMutation, TError, CreateAccountMutationVariables, TContext>) => {
    
    return useMutation<CreateAccountMutation, TError, CreateAccountMutationVariables, TContext>(
      {
    mutationKey: ['CreateAccount'],
    mutationFn: (variables?: CreateAccountMutationVariables) => fetchData<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, variables)(),
    ...options
  }
    )};

export const CreateBookingHoldTimerDocument = `
    mutation createBookingHoldTimer($input: CreateBookingHoldTimerInput!) {
  createBookingHoldTimer(input: $input) {
    id
  }
}
    `;

export const useCreateBookingHoldTimerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBookingHoldTimerMutation, TError, CreateBookingHoldTimerMutationVariables, TContext>) => {
    
    return useMutation<CreateBookingHoldTimerMutation, TError, CreateBookingHoldTimerMutationVariables, TContext>(
      {
    mutationKey: ['createBookingHoldTimer'],
    mutationFn: (variables?: CreateBookingHoldTimerMutationVariables) => fetchData<CreateBookingHoldTimerMutation, CreateBookingHoldTimerMutationVariables>(CreateBookingHoldTimerDocument, variables)(),
    ...options
  }
    )};

export const CreateEnquiryDocument = `
    mutation createEnquiry($data: EnquiryCreateInput!) {
  createEnquiry(data: $data) {
    id
  }
}
    `;

export const useCreateEnquiryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEnquiryMutation, TError, CreateEnquiryMutationVariables, TContext>) => {
    
    return useMutation<CreateEnquiryMutation, TError, CreateEnquiryMutationVariables, TContext>(
      {
    mutationKey: ['createEnquiry'],
    mutationFn: (variables?: CreateEnquiryMutationVariables) => fetchData<CreateEnquiryMutation, CreateEnquiryMutationVariables>(CreateEnquiryDocument, variables)(),
    ...options
  }
    )};

export const CreateEnquiryChoiceDocument = `
    mutation CreateEnquiryChoice($data: EnquiryChoiceCreateInput!) {
  createEnquiryChoice(data: $data) {
    id
  }
}
    `;

export const useCreateEnquiryChoiceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateEnquiryChoiceMutation, TError, CreateEnquiryChoiceMutationVariables, TContext>) => {
    
    return useMutation<CreateEnquiryChoiceMutation, TError, CreateEnquiryChoiceMutationVariables, TContext>(
      {
    mutationKey: ['CreateEnquiryChoice'],
    mutationFn: (variables?: CreateEnquiryChoiceMutationVariables) => fetchData<CreateEnquiryChoiceMutation, CreateEnquiryChoiceMutationVariables>(CreateEnquiryChoiceDocument, variables)(),
    ...options
  }
    )};

export const CreateSessionDocument = `
    mutation CreateSession($input: CreateSessionInput!) {
  createSessionWithTags(input: $input) {
    id
  }
}
    `;

export const useCreateSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSessionMutation, TError, CreateSessionMutationVariables, TContext>) => {
    
    return useMutation<CreateSessionMutation, TError, CreateSessionMutationVariables, TContext>(
      {
    mutationKey: ['CreateSession'],
    mutationFn: (variables?: CreateSessionMutationVariables) => fetchData<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, variables)(),
    ...options
  }
    )};

export const CreateSessionReviewDocument = `
    mutation CreateSessionReview($data: SessionReviewCreateInput!) {
  createSessionReview(data: $data) {
    id
    feelingScore
  }
}
    `;

export const useCreateSessionReviewMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSessionReviewMutation, TError, CreateSessionReviewMutationVariables, TContext>) => {
    
    return useMutation<CreateSessionReviewMutation, TError, CreateSessionReviewMutationVariables, TContext>(
      {
    mutationKey: ['CreateSessionReview'],
    mutationFn: (variables?: CreateSessionReviewMutationVariables) => fetchData<CreateSessionReviewMutation, CreateSessionReviewMutationVariables>(CreateSessionReviewDocument, variables)(),
    ...options
  }
    )};

export const CreateUserOnobardingChoiceDocument = `
    mutation CreateUserOnobardingChoice($data: UserOnboardingChoiceCreateInput!) {
  createUserOnboardingChoice(data: $data) {
    id
  }
}
    `;

export const useCreateUserOnobardingChoiceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserOnobardingChoiceMutation, TError, CreateUserOnobardingChoiceMutationVariables, TContext>) => {
    
    return useMutation<CreateUserOnobardingChoiceMutation, TError, CreateUserOnobardingChoiceMutationVariables, TContext>(
      {
    mutationKey: ['CreateUserOnobardingChoice'],
    mutationFn: (variables?: CreateUserOnobardingChoiceMutationVariables) => fetchData<CreateUserOnobardingChoiceMutation, CreateUserOnobardingChoiceMutationVariables>(CreateUserOnobardingChoiceDocument, variables)(),
    ...options
  }
    )};

export const CreateUserPackageDocument = `
    mutation CreateUserPackage($coachId: String!, $userId: String!, $label: String, $plan: String, $sessionCount: Int, $endDate: AWSDateTime) {
  createUserPackage(
    data: {label: $label, plan: $plan, sessionCount: $sessionCount, endDate: $endDate, coach: {connect: {id: $coachId}}, user: {connect: {id: $userId}}}
  ) {
    id
    user {
      id
      teamMember {
        id
      }
    }
  }
}
    `;

export const useCreateUserPackageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateUserPackageMutation, TError, CreateUserPackageMutationVariables, TContext>) => {
    
    return useMutation<CreateUserPackageMutation, TError, CreateUserPackageMutationVariables, TContext>(
      {
    mutationKey: ['CreateUserPackage'],
    mutationFn: (variables?: CreateUserPackageMutationVariables) => fetchData<CreateUserPackageMutation, CreateUserPackageMutationVariables>(CreateUserPackageDocument, variables)(),
    ...options
  }
    )};

export const AllCoachAvailabilityDocument = `
    query allCoachAvailability($start: AWSDateTime!, $end: AWSDateTime!) {
  allCoachAvailability(start: $start, end: $end) {
    startsAt
    endsAt
  }
}
    `;

export const useAllCoachAvailabilityQuery = <
      TData = AllCoachAvailabilityQuery,
      TError = unknown
    >(
      variables: AllCoachAvailabilityQueryVariables,
      options?: Omit<UseQueryOptions<AllCoachAvailabilityQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<AllCoachAvailabilityQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<AllCoachAvailabilityQuery, TError, TData>(
      {
    queryKey: ['allCoachAvailability', variables],
    queryFn: fetchData<AllCoachAvailabilityQuery, AllCoachAvailabilityQueryVariables>(AllCoachAvailabilityDocument, variables),
    ...options
  }
    )};

export const GetLastBookingHoldDocument = `
    query GetLastBookingHold($where: BookingHoldWhereInput!) {
  listBookingHolds(where: $where, orderBy: [{endsAt: DESC}], take: 1) {
    id
    startsAt
    endsAt
    state
    sessionStartsAt
    teamMember {
      id
    }
    coach {
      id
      displayName
      handle
    }
    enquiryId
    enquiry {
      id
      user {
        id
        firstName
        lastName
        email
      }
      enquiryChoices {
        id
        key
        value
      }
    }
  }
}
    `;

export const useGetLastBookingHoldQuery = <
      TData = GetLastBookingHoldQuery,
      TError = unknown
    >(
      variables: GetLastBookingHoldQueryVariables,
      options?: Omit<UseQueryOptions<GetLastBookingHoldQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetLastBookingHoldQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetLastBookingHoldQuery, TError, TData>(
      {
    queryKey: ['GetLastBookingHold', variables],
    queryFn: fetchData<GetLastBookingHoldQuery, GetLastBookingHoldQueryVariables>(GetLastBookingHoldDocument, variables),
    ...options
  }
    )};

export const GetCoachDocument = `
    query GetCoach($where: CoachExtendedWhereUniqueInput!) {
  getCoach(where: $where) {
    __typename
    id
    displayName
    user {
      id
      email
      firstName
      lastName
    }
    handle
    trainingSchool
    headline
    bio
    coachingSince
    ycmbUrl
    createdAt
    published
    position
    avatarKey
    user {
      id
      firstName
      lastName
      avatarKey
    }
    categories {
      id
      label
      value
    }
    specialisms {
      id
      label
      value
    }
    audienceExperiences {
      id
      label
      value
    }
    approaches {
      id
      label
      value
    }
    reviews {
      rating
      body
      company {
        name
      }
    }
  }
}
    `;

export const useGetCoachQuery = <
      TData = GetCoachQuery,
      TError = unknown
    >(
      variables: GetCoachQueryVariables,
      options?: Omit<UseQueryOptions<GetCoachQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCoachQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetCoachQuery, TError, TData>(
      {
    queryKey: ['GetCoach', variables],
    queryFn: fetchData<GetCoachQuery, GetCoachQueryVariables>(GetCoachDocument, variables),
    ...options
  }
    )};

export const GetCompanyDocument = `
    query GetCompany($where: CompanyExtendedWhereUniqueInput!) {
  getCompany(where: $where) {
    __typename
    id
    name
    accountType
    logoKey
    reportUrl
    createdAt
    updatedAt
    companyAdmin {
      __typename
      id
      subscriptionActive
      chargebeeSubscriptionId
      chargebeeCustomerId
      chargebeeSubscriptionStatus
      chargebeeTotalSeats
      chargebeeCancelledAt
      chargebeeCancelScheduleCreatedAt
      chargebeeCurrentTermEnd
      chargebeeCurrentTermStart
      createdAt
      updatedAt
    }
  }
}
    `;

export const useGetCompanyQuery = <
      TData = GetCompanyQuery,
      TError = unknown
    >(
      variables: GetCompanyQueryVariables,
      options?: Omit<UseQueryOptions<GetCompanyQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCompanyQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetCompanyQuery, TError, TData>(
      {
    queryKey: ['GetCompany', variables],
    queryFn: fetchData<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, variables),
    ...options
  }
    )};

export const GetCompanyAdminDocument = `
    query GetCompanyAdmin($where: CompanyAdminExtendedWhereUniqueInput!) {
  getCompanyAdmin(where: $where) {
    __typename
    id
    subscriptionActive
    chargebeeSubscriptionId
    chargebeeCustomerId
    chargebeeSubscriptionStatus
    chargebeeTotalSeats
    chargebeeCancelledAt
    chargebeeCancelScheduleCreatedAt
    chargebeeCurrentTermEnd
    chargebeeCurrentTermStart
    createdAt
    updatedAt
    company {
      __typename
      id
      name
      accountType
      logoKey
      reportUrl
      createdAt
      updatedAt
    }
  }
}
    `;

export const useGetCompanyAdminQuery = <
      TData = GetCompanyAdminQuery,
      TError = unknown
    >(
      variables: GetCompanyAdminQueryVariables,
      options?: Omit<UseQueryOptions<GetCompanyAdminQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCompanyAdminQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetCompanyAdminQuery, TError, TData>(
      {
    queryKey: ['GetCompanyAdmin', variables],
    queryFn: fetchData<GetCompanyAdminQuery, GetCompanyAdminQueryVariables>(GetCompanyAdminDocument, variables),
    ...options
  }
    )};

export const GetEnquiryDocument = `
    query GetEnquiry($where: EnquiryExtendedWhereUniqueInput!) {
  getEnquiry(where: $where) {
    id
    email
    enquiryChoices {
      key
      value
    }
  }
}
    `;

export const useGetEnquiryQuery = <
      TData = GetEnquiryQuery,
      TError = unknown
    >(
      variables: GetEnquiryQueryVariables,
      options?: Omit<UseQueryOptions<GetEnquiryQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetEnquiryQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetEnquiryQuery, TError, TData>(
      {
    queryKey: ['GetEnquiry', variables],
    queryFn: fetchData<GetEnquiryQuery, GetEnquiryQueryVariables>(GetEnquiryDocument, variables),
    ...options
  }
    )};

export const GetHostedPageDocument = `
    mutation getHostedPage($userId: String!, $itemPriceId: String!, $companyId: String!) {
  getHostedPage(userId: $userId, itemPriceId: $itemPriceId, companyId: $companyId) {
    id
    type
    url
    state
    failureReason
    passThruContent
    embed
    createdAt
    expiresAt
    updatedAt
    resourceVersion
    checkoutInfo
    object
  }
}
    `;

export const useGetHostedPageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetHostedPageMutation, TError, GetHostedPageMutationVariables, TContext>) => {
    
    return useMutation<GetHostedPageMutation, TError, GetHostedPageMutationVariables, TContext>(
      {
    mutationKey: ['getHostedPage'],
    mutationFn: (variables?: GetHostedPageMutationVariables) => fetchData<GetHostedPageMutation, GetHostedPageMutationVariables>(GetHostedPageDocument, variables)(),
    ...options
  }
    )};

export const GetLastSessionDocument = `
    query GetLastSession($where: SessionWhereInput) {
  listSessions(where: $where, orderBy: [{createdAt: DESC}], take: 1) {
    id
    startsAt
  }
}
    `;

export const useGetLastSessionQuery = <
      TData = GetLastSessionQuery,
      TError = unknown
    >(
      variables?: GetLastSessionQueryVariables,
      options?: Omit<UseQueryOptions<GetLastSessionQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetLastSessionQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetLastSessionQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetLastSession'] : ['GetLastSession', variables],
    queryFn: fetchData<GetLastSessionQuery, GetLastSessionQueryVariables>(GetLastSessionDocument, variables),
    ...options
  }
    )};

export const GetPortalPageDocument = `
    mutation getPortalPage($companyId: String!) {
  getPortalPage(companyId: $companyId) {
    id
    token
    accessUrl
    status
    createdAt
    customerId
  }
}
    `;

export const useGetPortalPageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GetPortalPageMutation, TError, GetPortalPageMutationVariables, TContext>) => {
    
    return useMutation<GetPortalPageMutation, TError, GetPortalPageMutationVariables, TContext>(
      {
    mutationKey: ['getPortalPage'],
    mutationFn: (variables?: GetPortalPageMutationVariables) => fetchData<GetPortalPageMutation, GetPortalPageMutationVariables>(GetPortalPageDocument, variables)(),
    ...options
  }
    )};

export const GetSessionDocument = `
    query GetSession($id: String!) {
  getSession(where: {id: $id}) {
    id
    startsAt
    endsAt
    location
    state
    cancelledAt
    cancellationReason
    noShow
    coachId
    coach {
      id
      displayName
      bio
      handle
    }
    teamMember {
      id
    }
    sessionReview {
      feelingScore
      coachRating
    }
    categories {
      category {
        label
      }
    }
  }
}
    `;

export const useGetSessionQuery = <
      TData = GetSessionQuery,
      TError = unknown
    >(
      variables: GetSessionQueryVariables,
      options?: Omit<UseQueryOptions<GetSessionQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetSessionQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetSessionQuery, TError, TData>(
      {
    queryKey: ['GetSession', variables],
    queryFn: fetchData<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, variables),
    ...options
  }
    )};

export const GetSessionByIdDocument = `
    query GetSessionById($id: String!) {
  getSession(where: {id: $id}) {
    id
    startsAt
    endsAt
    location
    state
    cancelledAt
    cancellationReason
    noShow
    coachId
    coach {
      id
      displayName
      bio
      handle
      headline
    }
    teamMember {
      id
      user {
        id
        firstName
        lastName
        timezone
      }
    }
    sessionReview {
      feelingScore
      coachRating
    }
    categories {
      category {
        label
      }
    }
    userPackage {
      id
      plan
      sessionCount
    }
  }
}
    `;

export const useGetSessionByIdQuery = <
      TData = GetSessionByIdQuery,
      TError = unknown
    >(
      variables: GetSessionByIdQueryVariables,
      options?: Omit<UseQueryOptions<GetSessionByIdQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetSessionByIdQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetSessionByIdQuery, TError, TData>(
      {
    queryKey: ['GetSessionById', variables],
    queryFn: fetchData<GetSessionByIdQuery, GetSessionByIdQueryVariables>(GetSessionByIdDocument, variables),
    ...options
  }
    )};

export const GetSponsorDocument = `
    query getSponsor($companyId: String!) {
  listUsers(
    where: {role: {equals: sponsor}, teamMember: {companyId: {equals: $companyId}}}
    take: 1
  ) {
    id
    email
  }
}
    `;

export const useGetSponsorQuery = <
      TData = GetSponsorQuery,
      TError = unknown
    >(
      variables: GetSponsorQueryVariables,
      options?: Omit<UseQueryOptions<GetSponsorQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetSponsorQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetSponsorQuery, TError, TData>(
      {
    queryKey: ['getSponsor', variables],
    queryFn: fetchData<GetSponsorQuery, GetSponsorQueryVariables>(GetSponsorDocument, variables),
    ...options
  }
    )};

export const GetUserByEmailDocument = `
    query getUserByEmail($email: AWSEmail!) {
  getUser(where: {email: $email}) {
    id
  }
}
    `;

export const useGetUserByEmailQuery = <
      TData = GetUserByEmailQuery,
      TError = unknown
    >(
      variables: GetUserByEmailQueryVariables,
      options?: Omit<UseQueryOptions<GetUserByEmailQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUserByEmailQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetUserByEmailQuery, TError, TData>(
      {
    queryKey: ['getUserByEmail', variables],
    queryFn: fetchData<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, variables),
    ...options
  }
    )};

export const InviteTeamMembersDocument = `
    mutation inviteTeamMembers($input: InviteTeamMembersInput!) {
  inviteTeamMembers(input: $input) {
    success {
      id
    }
    failed {
      reason
      teamMemberEmail
    }
  }
}
    `;

export const useInviteTeamMembersMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<InviteTeamMembersMutation, TError, InviteTeamMembersMutationVariables, TContext>) => {
    
    return useMutation<InviteTeamMembersMutation, TError, InviteTeamMembersMutationVariables, TContext>(
      {
    mutationKey: ['inviteTeamMembers'],
    mutationFn: (variables?: InviteTeamMembersMutationVariables) => fetchData<InviteTeamMembersMutation, InviteTeamMembersMutationVariables>(InviteTeamMembersDocument, variables)(),
    ...options
  }
    )};

export const ListCoachAudienceApproachesDocument = `
    query ListCoachAudienceApproaches {
  listCoachApproaches {
    id
    label
    value
  }
}
    `;

export const useListCoachAudienceApproachesQuery = <
      TData = ListCoachAudienceApproachesQuery,
      TError = unknown
    >(
      variables?: ListCoachAudienceApproachesQueryVariables,
      options?: Omit<UseQueryOptions<ListCoachAudienceApproachesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListCoachAudienceApproachesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListCoachAudienceApproachesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ListCoachAudienceApproaches'] : ['ListCoachAudienceApproaches', variables],
    queryFn: fetchData<ListCoachAudienceApproachesQuery, ListCoachAudienceApproachesQueryVariables>(ListCoachAudienceApproachesDocument, variables),
    ...options
  }
    )};

export const ListCoachAudienceExperiencesDocument = `
    query ListCoachAudienceExperiences {
  listCoachAudienceExperiences {
    id
    label
    value
  }
}
    `;

export const useListCoachAudienceExperiencesQuery = <
      TData = ListCoachAudienceExperiencesQuery,
      TError = unknown
    >(
      variables?: ListCoachAudienceExperiencesQueryVariables,
      options?: Omit<UseQueryOptions<ListCoachAudienceExperiencesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListCoachAudienceExperiencesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListCoachAudienceExperiencesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ListCoachAudienceExperiences'] : ['ListCoachAudienceExperiences', variables],
    queryFn: fetchData<ListCoachAudienceExperiencesQuery, ListCoachAudienceExperiencesQueryVariables>(ListCoachAudienceExperiencesDocument, variables),
    ...options
  }
    )};

export const ListCoachCategoriesDocument = `
    query ListCoachCategories {
  listCoachCategories {
    id
    label
    value
  }
}
    `;

export const useListCoachCategoriesQuery = <
      TData = ListCoachCategoriesQuery,
      TError = unknown
    >(
      variables?: ListCoachCategoriesQueryVariables,
      options?: Omit<UseQueryOptions<ListCoachCategoriesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListCoachCategoriesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListCoachCategoriesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ListCoachCategories'] : ['ListCoachCategories', variables],
    queryFn: fetchData<ListCoachCategoriesQuery, ListCoachCategoriesQueryVariables>(ListCoachCategoriesDocument, variables),
    ...options
  }
    )};

export const ListCoachPackagesDocument = `
    query ListCoachPackages($where: CoachPackageWhereInput) {
  listCoachPackages(where: $where) {
    id
    label
    value
  }
}
    `;

export const useListCoachPackagesQuery = <
      TData = ListCoachPackagesQuery,
      TError = unknown
    >(
      variables?: ListCoachPackagesQueryVariables,
      options?: Omit<UseQueryOptions<ListCoachPackagesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListCoachPackagesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListCoachPackagesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ListCoachPackages'] : ['ListCoachPackages', variables],
    queryFn: fetchData<ListCoachPackagesQuery, ListCoachPackagesQueryVariables>(ListCoachPackagesDocument, variables),
    ...options
  }
    )};

export const ListCoachSessionReviewsDocument = `
    query ListCoachSessionReviews($coachId: String!) {
  listSessionReviews(where: {session: {coachId: {equals: $coachId}}}) {
    feelingScore
    coachRating
    testimonial
    isHidden
    session {
      teamMember {
        company {
          name
        }
      }
    }
  }
}
    `;

export const useListCoachSessionReviewsQuery = <
      TData = ListCoachSessionReviewsQuery,
      TError = unknown
    >(
      variables: ListCoachSessionReviewsQueryVariables,
      options?: Omit<UseQueryOptions<ListCoachSessionReviewsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListCoachSessionReviewsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListCoachSessionReviewsQuery, TError, TData>(
      {
    queryKey: ['ListCoachSessionReviews', variables],
    queryFn: fetchData<ListCoachSessionReviewsQuery, ListCoachSessionReviewsQueryVariables>(ListCoachSessionReviewsDocument, variables),
    ...options
  }
    )};

export const ListCoachSpecialismsDocument = `
    query ListCoachSpecialisms {
  listCoachSpecialisms {
    id
    label
    value
  }
}
    `;

export const useListCoachSpecialismsQuery = <
      TData = ListCoachSpecialismsQuery,
      TError = unknown
    >(
      variables?: ListCoachSpecialismsQueryVariables,
      options?: Omit<UseQueryOptions<ListCoachSpecialismsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListCoachSpecialismsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListCoachSpecialismsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ListCoachSpecialisms'] : ['ListCoachSpecialisms', variables],
    queryFn: fetchData<ListCoachSpecialismsQuery, ListCoachSpecialismsQueryVariables>(ListCoachSpecialismsDocument, variables),
    ...options
  }
    )};

export const ListCoachTagsDocument = `
    query ListCoachTags {
  listCoachSpecialisms {
    id
    label
    value
  }
  listCoachCategories {
    id
    label
    value
  }
  listCoachAudienceExperiences {
    id
    label
    value
  }
  listCoachApproaches {
    id
    label
    value
  }
}
    `;

export const useListCoachTagsQuery = <
      TData = ListCoachTagsQuery,
      TError = unknown
    >(
      variables?: ListCoachTagsQueryVariables,
      options?: Omit<UseQueryOptions<ListCoachTagsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListCoachTagsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListCoachTagsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ListCoachTags'] : ['ListCoachTags', variables],
    queryFn: fetchData<ListCoachTagsQuery, ListCoachTagsQueryVariables>(ListCoachTagsDocument, variables),
    ...options
  }
    )};

export const ListCoachesDocument = `
    query ListCoaches($where: CoachWhereInput, $orderBy: [CoachOrderByInput!], $skip: Int, $take: Int) {
  listCoaches(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    id
    displayName
    trainingSchool
    headline
    bio
    coachingSince
    ycmbUrl
    createdAt
    published
    handle
    position
    avatarKey
    user {
      id
      firstName
      lastName
      avatarKey
    }
    categories {
      id
      label
      value
    }
    specialisms {
      id
      label
      value
    }
    audienceExperiences {
      id
      label
      value
    }
    approaches {
      id
      label
      value
    }
    reviews {
      body
      rating
      company {
        name
      }
    }
    sessions {
      sessionReview {
        feelingScore
        coachRating
        testimonial
      }
      teamMember {
        user {
          firstName
          lastName
        }
        company {
          name
        }
      }
    }
  }
}
    `;

export const useListCoachesQuery = <
      TData = ListCoachesQuery,
      TError = unknown
    >(
      variables?: ListCoachesQueryVariables,
      options?: Omit<UseQueryOptions<ListCoachesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListCoachesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListCoachesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ListCoaches'] : ['ListCoaches', variables],
    queryFn: fetchData<ListCoachesQuery, ListCoachesQueryVariables>(ListCoachesDocument, variables),
    ...options
  }
    )};

export const ListFeaturesDocument = `
    query ListFeatures($companyId: String!) {
  listFeatures {
    status
    name
  }
  listFeatureCompanies(where: {companyId: {equals: $companyId}}) {
    id
    feature {
      name
    }
  }
}
    `;

export const useListFeaturesQuery = <
      TData = ListFeaturesQuery,
      TError = unknown
    >(
      variables: ListFeaturesQueryVariables,
      options?: Omit<UseQueryOptions<ListFeaturesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListFeaturesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListFeaturesQuery, TError, TData>(
      {
    queryKey: ['ListFeatures', variables],
    queryFn: fetchData<ListFeaturesQuery, ListFeaturesQueryVariables>(ListFeaturesDocument, variables),
    ...options
  }
    )};

export const ListSessionsDocument = `
    query listSessions($where: SessionWhereInput, $orderBy: [SessionOrderByInput!], $skip: Int, $take: Int) {
  listSessions(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    id
    externalId
    startsAt
    endsAt
    state
    cancelledAt
    cancellationReason
    noShow
    location
    coachId
    coach {
      id
      displayName
      bio
      handle
      headline
    }
    teamMember {
      id
      user {
        id
        firstName
        lastName
        coachingExposure
        role
      }
      company {
        name
      }
    }
    packages {
      package {
        label
      }
    }
    categories {
      category {
        label
      }
    }
    sessionReview {
      id
      feelingScore
      coachRating
      tookPlace
    }
    userPackage {
      id
      plan
      sessionCount
    }
  }
}
    `;

export const useListSessionsQuery = <
      TData = ListSessionsQuery,
      TError = unknown
    >(
      variables?: ListSessionsQueryVariables,
      options?: Omit<UseQueryOptions<ListSessionsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListSessionsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListSessionsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['listSessions'] : ['listSessions', variables],
    queryFn: fetchData<ListSessionsQuery, ListSessionsQueryVariables>(ListSessionsDocument, variables),
    ...options
  }
    )};

export const ListTeamMembersDocument = `
    query listTeamMembers($where: TeamMemberWhereInput, $orderBy: [TeamMemberOrderByInput!], $skip: Int, $take: Int, $activeWhere: TeamMemberWhereInput) {
  listTeamMembers(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    id
    isManager
    jobTitle
    createdAt
    updatedAt
    companyId
    user {
      id
      role
      isImpersonating
      email
      firstName
      lastName
      avatarKey
      isActive
      activationState
      createdAt
      updatedAt
    }
  }
  count: countTeamMembers(where: $where)
  activeCount: countTeamMembers(where: $activeWhere)
}
    `;

export const useListTeamMembersQuery = <
      TData = ListTeamMembersQuery,
      TError = unknown
    >(
      variables?: ListTeamMembersQueryVariables,
      options?: Omit<UseQueryOptions<ListTeamMembersQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListTeamMembersQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListTeamMembersQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['listTeamMembers'] : ['listTeamMembers', variables],
    queryFn: fetchData<ListTeamMembersQuery, ListTeamMembersQueryVariables>(ListTeamMembersDocument, variables),
    ...options
  }
    )};

export const ListUserPackagesDocument = `
    query ListUserPackages($where: UserPackageWhereInput, $orderBy: [UserPackageOrderByInput!], $skip: Int, $take: Int) {
  listUserPackages(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    id
    label
    sessionCount
    endDate
    createdAt
    coach {
      displayName
    }
    sessions {
      id
    }
  }
}
    `;

export const useListUserPackagesQuery = <
      TData = ListUserPackagesQuery,
      TError = unknown
    >(
      variables?: ListUserPackagesQueryVariables,
      options?: Omit<UseQueryOptions<ListUserPackagesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ListUserPackagesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ListUserPackagesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['ListUserPackages'] : ['ListUserPackages', variables],
    queryFn: fetchData<ListUserPackagesQuery, ListUserPackagesQueryVariables>(ListUserPackagesDocument, variables),
    ...options
  }
    )};

export const MarkNoShowDocument = `
    mutation MarkNoShow($sessionId: String!) {
  markNoShow(sessionId: $sessionId) {
    id
  }
}
    `;

export const useMarkNoShowMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<MarkNoShowMutation, TError, MarkNoShowMutationVariables, TContext>) => {
    
    return useMutation<MarkNoShowMutation, TError, MarkNoShowMutationVariables, TContext>(
      {
    mutationKey: ['MarkNoShow'],
    mutationFn: (variables?: MarkNoShowMutationVariables) => fetchData<MarkNoShowMutation, MarkNoShowMutationVariables>(MarkNoShowDocument, variables)(),
    ...options
  }
    )};

export const RemoveTeamMemberDocument = `
    mutation removeTeamMember($teamMemberId: String!) {
  removeTeamMember(teamMemberId: $teamMemberId)
}
    `;

export const useRemoveTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveTeamMemberMutation, TError, RemoveTeamMemberMutationVariables, TContext>) => {
    
    return useMutation<RemoveTeamMemberMutation, TError, RemoveTeamMemberMutationVariables, TContext>(
      {
    mutationKey: ['removeTeamMember'],
    mutationFn: (variables?: RemoveTeamMemberMutationVariables) => fetchData<RemoveTeamMemberMutation, RemoveTeamMemberMutationVariables>(RemoveTeamMemberDocument, variables)(),
    ...options
  }
    )};

export const ResendTeamMemberInviteDocument = `
    mutation resendTeamMemberInvite($teamMemberId: String!) {
  resendTeamMemberInvite(teamMemberId: $teamMemberId) {
    id
  }
}
    `;

export const useResendTeamMemberInviteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResendTeamMemberInviteMutation, TError, ResendTeamMemberInviteMutationVariables, TContext>) => {
    
    return useMutation<ResendTeamMemberInviteMutation, TError, ResendTeamMemberInviteMutationVariables, TContext>(
      {
    mutationKey: ['resendTeamMemberInvite'],
    mutationFn: (variables?: ResendTeamMemberInviteMutationVariables) => fetchData<ResendTeamMemberInviteMutation, ResendTeamMemberInviteMutationVariables>(ResendTeamMemberInviteDocument, variables)(),
    ...options
  }
    )};

export const RestoreTeamMemberDocument = `
    mutation restoreTeamMember($teamMemberId: String!) {
  restoreTeamMember(teamMemberId: $teamMemberId)
}
    `;

export const useRestoreTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RestoreTeamMemberMutation, TError, RestoreTeamMemberMutationVariables, TContext>) => {
    
    return useMutation<RestoreTeamMemberMutation, TError, RestoreTeamMemberMutationVariables, TContext>(
      {
    mutationKey: ['restoreTeamMember'],
    mutationFn: (variables?: RestoreTeamMemberMutationVariables) => fetchData<RestoreTeamMemberMutation, RestoreTeamMemberMutationVariables>(RestoreTeamMemberDocument, variables)(),
    ...options
  }
    )};

export const SearchCoachesDocument = `
    query SearchCoaches($where: CoachWhereInput, $availability: CoachAvailabilityInput, $orderBy: [CoachOrderByInput], $skip: Int, $take: Int) {
  searchCoaches(
    where: $where
    availability: $availability
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    id
    displayName
    trainingSchool
    headline
    bio
    handle
    avatarKey
    excludeIndividuals
    categories {
      label
    }
    specialisms {
      label
    }
    audienceExperiences {
      label
    }
    approaches {
      label
    }
    reviews {
      body
      rating
      company {
        name
      }
    }
  }
}
    `;

export const useSearchCoachesQuery = <
      TData = SearchCoachesQuery,
      TError = unknown
    >(
      variables?: SearchCoachesQueryVariables,
      options?: Omit<UseQueryOptions<SearchCoachesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<SearchCoachesQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<SearchCoachesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['SearchCoaches'] : ['SearchCoaches', variables],
    queryFn: fetchData<SearchCoachesQuery, SearchCoachesQueryVariables>(SearchCoachesDocument, variables),
    ...options
  }
    )};

export const SearchCoachesByAvailabilityDocument = `
    query SearchCoachesByAvailability($where: CoachWhereInput, $availability: CoachAvailabilityInput, $skip: Int, $take: Int) {
  searchCoachesByAvailability(
    where: $where
    availability: $availability
    skip: $skip
    take: $take
  ) {
    id
    displayName
    trainingSchool
    headline
    bio
    handle
    avatarKey
    excludeIndividuals
    categories {
      label
    }
    specialisms {
      label
    }
    audienceExperiences {
      label
    }
    approaches {
      label
    }
    reviews {
      body
      rating
      company {
        name
      }
    }
    events {
      id
      eventId
      status
      startsAt
      endsAt
      timeZone
      iCalUID
      recurringEventId
      coach {
        handle
      }
    }
  }
}
    `;

export const useSearchCoachesByAvailabilityQuery = <
      TData = SearchCoachesByAvailabilityQuery,
      TError = unknown
    >(
      variables?: SearchCoachesByAvailabilityQueryVariables,
      options?: Omit<UseQueryOptions<SearchCoachesByAvailabilityQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<SearchCoachesByAvailabilityQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<SearchCoachesByAvailabilityQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['SearchCoachesByAvailability'] : ['SearchCoachesByAvailability', variables],
    queryFn: fetchData<SearchCoachesByAvailabilityQuery, SearchCoachesByAvailabilityQueryVariables>(SearchCoachesByAvailabilityDocument, variables),
    ...options
  }
    )};

export const UpdateCompanyDocument = `
    mutation UpdateCompany($where: CompanyExtendedWhereUniqueInput!, $data: CompanyUpdateInput!) {
  updateCompany(where: $where, data: $data) {
    __typename
    id
    name
    accountType
    reportUrl
    logoKey
    createdAt
    updatedAt
  }
}
    `;

export const useUpdateCompanyMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>) => {
    
    return useMutation<UpdateCompanyMutation, TError, UpdateCompanyMutationVariables, TContext>(
      {
    mutationKey: ['UpdateCompany'],
    mutationFn: (variables?: UpdateCompanyMutationVariables) => fetchData<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, variables)(),
    ...options
  }
    )};

export const UpdateCompanyAdminDocument = `
    mutation UpdateCompanyAdmin($where: CompanyAdminExtendedWhereUniqueInput!, $data: CompanyAdminUpdateInput!) {
  updateCompanyAdmin(where: $where, data: $data) {
    __typename
    id
    subscriptionActive
  }
}
    `;

export const useUpdateCompanyAdminMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanyAdminMutation, TError, UpdateCompanyAdminMutationVariables, TContext>) => {
    
    return useMutation<UpdateCompanyAdminMutation, TError, UpdateCompanyAdminMutationVariables, TContext>(
      {
    mutationKey: ['UpdateCompanyAdmin'],
    mutationFn: (variables?: UpdateCompanyAdminMutationVariables) => fetchData<UpdateCompanyAdminMutation, UpdateCompanyAdminMutationVariables>(UpdateCompanyAdminDocument, variables)(),
    ...options
  }
    )};

export const UpdateCompanySponsorDocument = `
    mutation updateCompanySponsor($companyId: String!, $userId: String!) {
  updateCompanySponsor(companyId: $companyId, userId: $userId) {
    id
  }
}
    `;

export const useUpdateCompanySponsorMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanySponsorMutation, TError, UpdateCompanySponsorMutationVariables, TContext>) => {
    
    return useMutation<UpdateCompanySponsorMutation, TError, UpdateCompanySponsorMutationVariables, TContext>(
      {
    mutationKey: ['updateCompanySponsor'],
    mutationFn: (variables?: UpdateCompanySponsorMutationVariables) => fetchData<UpdateCompanySponsorMutation, UpdateCompanySponsorMutationVariables>(UpdateCompanySponsorDocument, variables)(),
    ...options
  }
    )};

export const UpdateSessionDocument = `
    mutation UpdateSession($where: SessionExtendedWhereUniqueInput!, $data: SessionUpdateInput) {
  updateSession(where: $where, data: $data) {
    id
  }
}
    `;

export const useUpdateSessionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSessionMutation, TError, UpdateSessionMutationVariables, TContext>) => {
    
    return useMutation<UpdateSessionMutation, TError, UpdateSessionMutationVariables, TContext>(
      {
    mutationKey: ['UpdateSession'],
    mutationFn: (variables?: UpdateSessionMutationVariables) => fetchData<UpdateSessionMutation, UpdateSessionMutationVariables>(UpdateSessionDocument, variables)(),
    ...options
  }
    )};

export const UpdateTeamMemberDocument = `
    mutation UpdateTeamMember($where: TeamMemberExtendedWhereUniqueInput!, $data: TeamMemberUpdateInput!) {
  updateTeamMember(where: $where, data: $data) {
    __typename
    id
    isManager
    jobTitle
    companyId
    createdAt
    updatedAt
    user {
      id
    }
  }
}
    `;

export const useUpdateTeamMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateTeamMemberMutation, TError, UpdateTeamMemberMutationVariables, TContext>) => {
    
    return useMutation<UpdateTeamMemberMutation, TError, UpdateTeamMemberMutationVariables, TContext>(
      {
    mutationKey: ['UpdateTeamMember'],
    mutationFn: (variables?: UpdateTeamMemberMutationVariables) => fetchData<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(UpdateTeamMemberDocument, variables)(),
    ...options
  }
    )};

export const UpdateUserDocument = `
    mutation UpdateUser($where: UserExtendedWhereUniqueInput!, $data: UserUpdateInput!) {
  updateUser(where: $where, data: $data) {
    __typename
    id
    cognitoId
    role
    isImpersonating
    email
    firstName
    lastName
    avatarKey
    isActive
    createdAt
    updatedAt
  }
}
    `;

export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUser'],
    mutationFn: (variables?: UpdateUserMutationVariables) => fetchData<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, variables)(),
    ...options
  }
    )};

export const UpdateUserPackageDocument = `
    mutation UpdateUserPackage($where: UserPackageExtendedWhereUniqueInput!, $data: UserPackageUpdateInput) {
  updateUserPackage(where: $where, data: $data) {
    id
  }
}
    `;

export const useUpdateUserPackageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserPackageMutation, TError, UpdateUserPackageMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserPackageMutation, TError, UpdateUserPackageMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUserPackage'],
    mutationFn: (variables?: UpdateUserPackageMutationVariables) => fetchData<UpdateUserPackageMutation, UpdateUserPackageMutationVariables>(UpdateUserPackageDocument, variables)(),
    ...options
  }
    )};

export const UpdateUserToIndividualDocument = `
    mutation UpdateUserToIndividual($userId: String!) {
  updateUserToIndividual(userId: $userId) {
    id
  }
}
    `;

export const useUpdateUserToIndividualMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserToIndividualMutation, TError, UpdateUserToIndividualMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserToIndividualMutation, TError, UpdateUserToIndividualMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUserToIndividual'],
    mutationFn: (variables?: UpdateUserToIndividualMutationVariables) => fetchData<UpdateUserToIndividualMutation, UpdateUserToIndividualMutationVariables>(UpdateUserToIndividualDocument, variables)(),
    ...options
  }
    )};

export const UpsertBookingHoldDocument = `
    mutation UpsertBookingHold($create: BookingHoldCreateInput!, $update: BookingHoldUpdateInput!, $where: BookingHoldExtendedWhereUniqueInput!) {
  upsertBookingHold(create: $create, update: $update, where: $where) {
    id
  }
}
    `;

export const useUpsertBookingHoldMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpsertBookingHoldMutation, TError, UpsertBookingHoldMutationVariables, TContext>) => {
    
    return useMutation<UpsertBookingHoldMutation, TError, UpsertBookingHoldMutationVariables, TContext>(
      {
    mutationKey: ['UpsertBookingHold'],
    mutationFn: (variables?: UpsertBookingHoldMutationVariables) => fetchData<UpsertBookingHoldMutation, UpsertBookingHoldMutationVariables>(UpsertBookingHoldDocument, variables)(),
    ...options
  }
    )};

export const UpsertEnquiryChoiceDocument = `
    mutation UpsertEnquiryChoice($create: EnquiryChoiceCreateInput!, $update: EnquiryChoiceUpdateInput!, $where: EnquiryChoiceExtendedWhereUniqueInput!) {
  upsertEnquiryChoice(create: $create, update: $update, where: $where) {
    id
  }
}
    `;

export const useUpsertEnquiryChoiceMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpsertEnquiryChoiceMutation, TError, UpsertEnquiryChoiceMutationVariables, TContext>) => {
    
    return useMutation<UpsertEnquiryChoiceMutation, TError, UpsertEnquiryChoiceMutationVariables, TContext>(
      {
    mutationKey: ['UpsertEnquiryChoice'],
    mutationFn: (variables?: UpsertEnquiryChoiceMutationVariables) => fetchData<UpsertEnquiryChoiceMutation, UpsertEnquiryChoiceMutationVariables>(UpsertEnquiryChoiceDocument, variables)(),
    ...options
  }
    )};

export const WhoAmIDocument = `
    query WhoAmI($cognitoId: String!) {
  getUser(where: {cognitoId: $cognitoId}) {
    __typename
    id
    cognitoId
    role
    isImpersonating
    email
    firstName
    lastName
    avatarKey
    isActive
    activationState
    createdAt
    updatedAt
    phone
    timezone
    teamMember {
      __typename
      id
      jobTitle
      isManager
      createdAt
      updatedAt
      companyId
      company {
        name
        features {
          feature {
            name
            status
          }
        }
      }
    }
    coach {
      id
    }
    userOnboardingChoices {
      id
      key
    }
    settings {
      id
      key
      value
    }
    packages {
      id
      sessionCount
      createdAt
      endDate
      label
      coachId
      plan
      coach {
        displayName
        handle
      }
      sessions {
        id
        createdAt
        startsAt
        state
        cancelledAt
      }
    }
  }
}
    `;

export const useWhoAmIQuery = <
      TData = WhoAmIQuery,
      TError = unknown
    >(
      variables: WhoAmIQueryVariables,
      options?: Omit<UseQueryOptions<WhoAmIQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<WhoAmIQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<WhoAmIQuery, TError, TData>(
      {
    queryKey: ['WhoAmI', variables],
    queryFn: fetchData<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, variables),
    ...options
  }
    )};
