import { Avatar, Card, Dropdown, Menu, type MenuProps } from "antd";

import { NavLink } from "react-router-dom";
import { type IUserContext, useUser } from "$/state/user";
import { ROUTE_PATHS } from "$/configs/routes";
import React from "react";
import { WEB_ASSETS_URL } from "$/configs/app.config";
import { type ICompanyContext, useCompany, CurrentCompany } from "$/state/company";
import { AccountType, Company } from "$/graphql/types.generated";

const { Meta } = Card;

const getAdminItems = (company: CurrentCompany): MenuProps["items"] =>
  [
    {
      key: "sessions",
      label: <NavLink to={ROUTE_PATHS.SESSIONS.MAIN}>View Bookings</NavLink>,
    },
    {
      key: "profile",
      label: <NavLink to={ROUTE_PATHS.USER.SETTINGS}>Edit Profile</NavLink>,
    },
    {
      key: "company-settings",
      label: <NavLink to={ROUTE_PATHS.COMPANY.SETTINGS}>Edit Company</NavLink>,
    },
    company.reportUrl
      ? {
          key: "view-data-report",
          label: (
            <NavLink to={company.reportUrl} target="_blank">
              View Company Report
            </NavLink>
          ),
        }
      : null,
    {
      key: "company-account",
      label: <NavLink to={ROUTE_PATHS.MY_ACCOUNT}>Manage Account</NavLink>,
    },
    {
      key: "contact",
      label: <NavLink to={ROUTE_PATHS.CONTACT_US}>Contact Us</NavLink>,
    },
    {
      key: "signout",
      label: <NavLink to={ROUTE_PATHS.SIGNOUT}>Sign out</NavLink>,
    },
  ].filter(Boolean);

const getUserItems = (company: CurrentCompany): MenuProps["items"] =>
  [
    {
      key: "sessions",
      label: <NavLink to={ROUTE_PATHS.SESSIONS.MAIN}>View Bookings</NavLink>,
    },
    {
      key: "profile",
      label: <NavLink to={ROUTE_PATHS.USER.SETTINGS}>Edit Profile</NavLink>,
    },
    {
      key: "contact",
      label: <NavLink to={ROUTE_PATHS.CONTACT_US}>Contact Us</NavLink>,
    },
    company.accountType === AccountType.Individual
      ? {
          key: "company-account",
          label: <NavLink to={ROUTE_PATHS.COMPANY.BILLING}>Manage Account</NavLink>,
        }
      : null,
    {
      key: "signout",
      label: <NavLink to={ROUTE_PATHS.SIGNOUT}>Sign out</NavLink>,
    },
  ].filter(Boolean);

export const AccountMenu: React.FC<{ mobile?: boolean }> = ({ mobile = false }) => {
  const user = useUser();
  const company = useCompany();

  if (!company.currentCompany) return <div />;

  if (mobile) {
    return (
      <Menu
        {...{
          defaultSelectedKeys: [],
          mode: "vertical",
          theme: "light",
          className: "mh-menu",
          disabledOverflow: true,
        }}
        items={
          user.currentUser?.isAdmin
            ? getAdminItems(company?.currentCompany)
            : getUserItems(company?.currentCompany)
        }
      />
    );
  }

  return (
    <div>
      <div id="account-dropdown-container" />
      <Dropdown
        arrow
        menu={{
          items: user.currentUser?.isAdmin
            ? getAdminItems(company?.currentCompany!)
            : getUserItems(company?.currentCompany),
        }}
        trigger={["click"]}
        placement="bottomRight"
        destroyPopupOnHide={false}
        getPopupContainer={(_trigger) => document.getElementById("account-dropdown-container")!}
      >
        <div className="account-dropdown">
          <Avatar
            size={35}
            src={user.currentUser?.avatarKey || `${WEB_ASSETS_URL}/User_Icon_Smiley_round.svg`}
          />
          <svg
            className="dropdown-arrow"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.05359 5.53125L8.99704 12.4687L14.9464 5.53125"
              stroke="#1E1E1E"
              strokeWidth="0.75"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Dropdown>
    </div>
  );
};
