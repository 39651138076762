import React from "react";
import { Alert, List } from "antd";

export const Message = (props: any) => {
  return (
    <Alert
      message={
        <List
          dataSource={props.items}
          renderItem={(item: any) => {
            const isString = typeof item === "string";

            return <List.Item>{isString ? item : item?.message}</List.Item>;
          }}
        />
      }
      showIcon
      type={props.type}
      style={{ textAlign: "left" }}
    />
  );
};
