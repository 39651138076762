import { useEffect } from "react";
import { useIntercom as useIntercomClient } from "react-use-intercom";

import { useUser } from "$/state/user";
import { useLocation } from "react-router";

// routes we dont want to show intercom on
const blocklist = ["/onboarding", "/login", "/register"];

export const useIntercom = () => {
  const user = useUser();
  const location = useLocation();

  const { show, boot, update } = useIntercomClient();

  const hidden = blocklist.includes(location.pathname);

  useEffect(() => {
    if (hidden) return;
    if (user) {
      boot({
        userId: user.currentUser?.id,
        email: user.currentUser?.email,
      });
      update({
        userId: user.currentUser?.id,
        email: user.currentUser?.email,
      });
    } else {
      boot();
      show();
    }
  }, [boot, update, user]);
};
