import { isSameHour, parseISO } from "date-fns";

export const mergeAvailability = (fullAvailability, moreHappiAvailability, bookedSessions) => {
  let updatedAvailability = fullAvailability.map((slot) => ({ ...slot }));

  const updateSlots = (availability, colour) => {
    availability.forEach((availSlot) => {
      const index = updatedAvailability.findIndex((slot) =>
        isSameHour(parseISO(slot.startsAt), parseISO(availSlot.startsAt)),
      );
      if (index !== -1) {
        updatedAvailability[index].colour = colour;
      }
    });
  };

  updateSlots(moreHappiAvailability, "green");
  updateSlots(bookedSessions, "red");

  updatedAvailability = updatedAvailability.filter((slot) => !slot.colour);

  const coloredSlots = [
    ...moreHappiAvailability.map((slot) => ({ ...slot, colour: "green" })),
    ...bookedSessions.map((slot) => ({ ...slot, colour: "red" })),
  ];

  return [...coloredSlots, ...updatedAvailability].sort(
    (a, b) => parseISO(a.startsAt).getTime() - parseISO(b.startsAt).getTime(),
  );
};
