import ReactDOM from "react-dom";
import Auth from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { App } from "./App";
import { awsAuthConfig } from "$/configs/aws";
import "$/styles/global.less";
import "$/index.css";
import "react-notion/src/styles.css";
import { Mixpanel } from "$/tracking";
import { createRoot } from "react-dom/client";

if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1, // 10%
    environment: import.meta.env.MODE,
  });
}

if (window) {
  const cb = (window as any).Chargebee;

  if (cb) {
    cb.init({
      site: import.meta.env.VITE_CHARGEBEE_SITE,
      publishableKey: import.meta.env.VITE_CHARGEBEE_PUBLISHABLE_KEY,
      iframeOnly: true,
      enableGTMTracking: true,
    });
  }
}

// @ts-ignore
Auth.configure(awsAuthConfig);

Mixpanel.init();

const root = createRoot(document.getElementById("root"));
root.render(<App />);
