import { app } from "$/configs/app.config";

import styles from "./styles.module.less";

export const getAvatarUrl = (imageKey?: string | null) =>
  imageKey
    ? decodeURIComponent(`${app.CLOUDFRONT.USER_ASSETS}/${imageKey}`)
    : undefined;

export const CoachAvatar = ({
  imageKey,
  displayName,
}: {
  imageKey?: string | null;
  displayName?: string | null;
}) => {
  const imageUrl = getAvatarUrl(imageKey);

  return (
    <img
      className={styles["coach-image"]}
      src={imageUrl}
      alt={displayName || "Coach display photo"}
    />
  );
};
