import { useState } from "react";
import { message, Modal, Typography, notification } from "antd";

import { TeamMember, useResendTeamMemberInviteMutation } from "$/graphql/types.generated";

import { Mixpanel } from "$/tracking";

interface Props {
  isOpen: boolean;
  onClose: (modalVisible: boolean) => void;
  teamMember: TeamMember;
  refetch: () => void;
}

export const ConfirmPasswordResetModal = ({ isOpen, onClose, teamMember, refetch }: Props) => {
  const [loading, setLoading] = useState(false);

  const openNotification = () => {
    const args = {
      message: "Temporary Password Sent",
      description: `An email is on its way to ${teamMember?.user?.email}. Please be aware that it may take a minute for the invitation to arrive. `,
      duration: 5,
      className: "notification",
    };

    Mixpanel.track("Team Member Invite Resent");

    notification.open(args);
  };

  const resendTeamMemberInviteMutation = useResendTeamMemberInviteMutation({
    onSuccess: () => {
      openNotification();
      setLoading(false);
      onClose(false);
      refetch();
    },
    onError: () => {
      message.error(
        `${teamMember?.user?.firstName} ${teamMember?.user?.lastName} could not been restored. Please try again or contact us`,
      );

      setLoading(false);
      onClose(false);
      refetch();
    },
  });

  const handleOk = async () => {
    setLoading(true);

    resendTeamMemberInviteMutation.mutate({
      teamMemberId: teamMember.id,
    });
  };

  const handleCancel = () => onClose(false);

  return (
    <Modal
      title={`Reset password for ${teamMember?.user?.firstName} ${teamMember?.user?.lastName}?`}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
      closable={loading}
    >
      <Typography.Paragraph>
        Are you sure you want to reset {teamMember?.user?.firstName}&apos;s password. This will
        prevent them from logging in with their current password.
      </Typography.Paragraph>
      <Typography.Paragraph italic>
        {teamMember?.user?.firstName} may need to reset their password again themselves if they do
        not confirm in time.
      </Typography.Paragraph>
    </Modal>
  );
};
