import { Navigate, useLocation } from "react-router-dom";

import { MasterLayout } from "$/layouts/MasterLayout";
import { PublicLayout } from "$/layouts/PublicLayout";

import { useAuth } from "$/state/auth";

import { ROUTE_PATHS } from "$/configs/routes";
import { useCompany } from "$/state/company";
import { useUser } from "$/state/user";
import { Button } from "antd";
import { RestrictedAccount } from "$/components/RestrictedAccount";

const PrivateRoute = ({ children, disableHeader, disableSubscriptionBanner }: any) => {
  const auth = useAuth();
  const user = useUser();
  const company = useCompany();

  if (auth.state.isAuthenticated) {
    if (!company.isLoading && company?.currentCompany?.accountType === "restricted") {
      return (
        <RestrictedAccount
          companyId={company.currentCompany.id}
          isSponsor={user?.currentUser?.isSponsor}
        />
      );
    }
    return (
      <MasterLayout
        disableHeader={disableHeader}
        disableSubscriptionBanner={disableSubscriptionBanner}
      >
        {children}
      </MasterLayout>
    );
  }

  return <Navigate to={ROUTE_PATHS.LOGIN} />;
};

const PublicRoute = ({
  children,
  evenIfLoggedIn,
  disableHeader,
  disableSubscriptionBanner,
}: any) => {
  const auth = useAuth();

  const location = useLocation();

  if (auth.state.isAuthenticated && !evenIfLoggedIn) {
    return <Navigate to={ROUTE_PATHS.DASHBOARD} state={{ from: location }} />;
  }

  if (evenIfLoggedIn && auth.state.isAuthenticated) {
    return (
      <PrivateRoute
        disableHeader={disableHeader}
        disableSubscriptionBanner={disableSubscriptionBanner}
      >
        {children}
      </PrivateRoute>
    );
  }

  return <PublicLayout disableHeader={disableHeader}>{children}</PublicLayout>;
};

export { PrivateRoute, PublicRoute };
