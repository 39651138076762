import cn from "classnames";

interface TabProps {
  text: string;
  icon?: JSX.Element;
  active: boolean;
  onClick: () => void;
}

function Tab({ icon, text, active, onClick }: TabProps) {
  return (
    <li
      className={cn("flex-1 cursor-pointer", {
        "border-0 border-b-4 border-solid border-yellow": active,
      })}
    >
      <div className="relative block p-4" onClick={onClick}>
        {active && <span className="absolute inset-x-0 -bottom-px h-[3px] w-full bg-primary" />}

        <div className="flex items-center justify-center gap-4">
          {icon}
          <span className="whitespace-nowrap font-medium text-gray-900"> {text} </span>
        </div>
      </div>
    </li>
  );
}

interface TabsProps {
  tabs: Array<{
    text: string;
    icon?: JSX.Element;
  }>;
  activeTab: number;
  setTab: (tab: number) => void;
}

export default function Tabs({ tabs, activeTab = 0, setTab }: TabsProps) {
  return (
    <ul className="flex max-w-full list-none overflow-x-scroll  scrollbar-hide pl-0">
      {tabs.map((tab, index) => (
        <Tab
          {...tab}
          key={tab.text.replaceAll(" ", "-")}
          onClick={() => setTab(index)}
          active={activeTab === index}
        />
      ))}
    </ul>
  );
}
