// @ts-nocheck

import { useState } from "react";
import DownloadLink from "react-download-link";
import validator from "email-validator";
import CSVReader from "react-csv-reader";
import { Space, Table, Tooltip, Typography } from "antd";

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import { ErrorMessage } from "$/components/ErrorMessage";
import { WarningMessage } from "$/components/WarningMessage";

import { UserRole } from "$/graphql/types.generated";

import { DATA_TEST_IDS } from "$/elements.config";

const { Text } = Typography;

const roles = [UserRole.Guest, UserRole.TeamMember, UserRole.Admin];

export const CsvImport = ({
  csvData,
  csvError,
  setCsvData,
  setCsvError,
  setCsvInviteNumbers,
  reset,
}: any) => {
  const [csvTableData, setCsvTableData] = useState([]);

  const placeholderData = `email,firstname,lastname,accountType,isManager
  bill.smith@morehappi.com,Bill,Smith,team_member,FALSE
  sophie.green@morehappi.com,Sophie,Green,admin,TRUE`;

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const isValidEmail = (email: string) => !email || validator.validate(email);
  const isValidRole = (role: string) =>
    role === UserRole.Guest ||
    role === UserRole.TeamMember ||
    role === UserRole.Admin;
  const isValidFirstName = (name: string) =>
    !name || !name.replace(/\s/g, "").includes("firstname");
  const isValidLastName = (name: string) =>
    !name || !name.replace(/\s/g, "").includes("lastname");
  const isValidRow = (row) =>
    isValidEmail(row.email) &&
    isValidRole(row.accounttype) &&
    isValidFirstName(row.firstname) &&
    isValidLastName(row.lastname);

  const onInviteFileLoad = (data) => {
    setCsvTableData([]);
    setCsvData([]);
    setCsvError([]);

    data.forEach((row) => {
      // basic email sanitation, checking for any white space
      if (row.email) {
        row.email = row.email.replace(/\s/g, "").toLowerCase();
      } else {
        row.email = "Email is missing";
      }

      if (row.accounttype) {
        row.accounttype = row.accounttype.toLowerCase();
      } else {
        row.accounttype = UserRole.TeamMember;
      }

      if (!row.firstname) {
        row.firstname = "firstname";
      }

      if (!row.lastname) {
        row.lastname = "lastname";
      }
    });

    // sets fields for display and structures the data correctly
    const tableData = data.map((row) => {
      const newValidData = {
        isValid: isValidRow(row),
        email: row.email.toLowerCase(),
        firstName: row.firstname,
        lastName: row.lastname,
        accountType: row.accounttype,
        isManager: row.ismanager,
      };

      return newValidData;
    });

    const validData = tableData.filter((row) => row.isValid);

    setCsvTableData(tableData);
    setCsvInviteNumbers(validData.length);
    setCsvData(validData);
  };

  const removeItem = (record) => {
    if (csvTableData.length === 0) {
      reset();
    }

    setCsvInviteNumbers(csvTableData.length - 1);

    const updatedCSVData = csvTableData.filter(
      (row) => row.email !== record.email,
    );

    const validData = updatedCSVData.filter((row) => row.isValid);

    setCsvTableData(updatedCSVData);
    setCsvData(validData);
  };

  const CSVImportUsersColumns = [
    {
      title: () => (
        <Tooltip
          placement="topLeft"
          title="This helps identify whether the row needs updates"
        >
          Is Valid
        </Tooltip>
      ),
      dataIndex: "isValid",
      key: "isValid",
      responsive: ["md"],
      render: (isValid) =>
        isValid ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <ExclamationCircleOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "User Details",
      render: (_, record) => (
        <>
          {`${record.firstName} ${record.lastName}`}
          <br />
          {record.email}
          <br />
          {record.isManager ? "Manager" : "Not a manager"}
          <br />
          {record.accountType}
        </>
      ),
      className: "xs-only",
      responsive: ["xs", "sm"],
    },
    {
      title: () => (
        <Tooltip
          placement="topLeft"
          title="This is for your team members email address, double-check it to make sure it is right so we can get them access to the service."
        >
          Email
        </Tooltip>
      ),
      dataIndex: "email",
      key: "email",
      responsive: ["md"],
      render: (email) => {
        return isValidEmail(email) ? (
          <span>{email}</span>
        ) : (
          <Tooltip
            placement="topLeft"
            title={`"${email}" is invalid. Please update and try again.`}
          >
            <s>{email}</s>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <Tooltip
          placement="topLeft"
          title="Nothing worse than a name typo, give this a once over so we can give this team member a beautifully personalised onboarding experience with us."
        >
          First Name
        </Tooltip>
      ),
      dataIndex: "firstName",
      key: "firstName",
      responsive: ["md"],
      render: (firstName) =>
        isValidFirstName(firstName) ? (
          <span>{firstName}</span>
        ) : (
          <Tooltip
            placement="topLeft"
            title={`"${firstName}" is required and should not be firstname`}
          >
            <s>{firstName}</s>
          </Tooltip>
        ),
    },
    {
      title: () => (
        <Tooltip placement="topLeft" title="This helps us identify our users.">
          Last Name
        </Tooltip>
      ),
      dataIndex: "lastName",
      key: "lastName",
      responsive: ["md"],
      render: (lastname) =>
        isValidLastName(lastname) ? (
          <span>{lastname}</span>
        ) : (
          <Tooltip
            placement="topLeft"
            title={`"${lastname}" is required and should not be lastname`}
          >
            <s>{lastname}</s>
          </Tooltip>
        ),
    },
    {
      title: () => (
        <Tooltip
          placement="topLeft"
          title="Staff are able to book unlimited coaching calls, admins can do this too but are also able to add or remove staff members when they join or leave. When you are specifying team members in the file please make sure that you follow the example and declare them as team_member."
        >
          Account Type
        </Tooltip>
      ),
      dataIndex: "accountType",
      key: "accountType",
      responsive: ["md"],
      render: (accountType) =>
        isValidRole(accountType) ? (
          <span>{accountType}</span>
        ) : (
          <Tooltip
            placement="topLeft"
            title={`"${accountType}" is an invalid role. Please use of one ${roles.join(
              ", ",
            )}`}
          >
            <s>{accountType}</s>
          </Tooltip>
        ),
    },
    {
      title: () => (
        <Tooltip
          placement="topLeft"
          title="If this person manages a team you can mark this as true- they will receive our tailored Leadership content. "
        >
          Is a Manager?
        </Tooltip>
      ),
      dataIndex: "isManager",
      key: "isManager",
      responsive: ["md"],
      render: (isManager) => {
        return <span>{isManager ? "Yes" : "No"}</span>;
      },
    },
    {
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <MinusCircleOutlined onClick={() => removeItem(record)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="csvContainerInput">
      <DownloadLink
        className="mh-download-link"
        label="Download CSV template here."
        filename="files/invite_list_template.csv"
        data-cy={DATA_TEST_IDS.TEAMS.IMPORT.MODAL.BUTTONS.CSV_TEMPLATE_DOWNLOAD}
        exportFile={() => placeholderData}
      />
      <CSVReader
        label="Select a CSV with your team members emails"
        onFileLoaded={onInviteFileLoad}
        parserOptions={papaparseOptions}
        data-cy={DATA_TEST_IDS.TEAMS.IMPORT.MODAL.BUTTONS.FILE_UPLOAD}
      />
      <Text>
        Below is the structure that you will need to follow in order for the
        file to successfully upload all of your employees. If you hover over the
        text for each item you will be able to get a more specific idea of what
        each represents.
      </Text>
      <Table
        pagination={{
          position: ["bottomLeft"],
          responsive: false,
        }}
        scroll={{ x: true }}
        className="mh-row-top-md"
        columns={CSVImportUsersColumns}
        dataSource={csvTableData}
      />
      <ErrorMessage errors={csvError} />
      {csvData.length !== csvTableData.length && (
        <WarningMessage
          errors={[
            {
              message: `${csvData.length} human(s) are valid and can be invited. ${csvTableData.length} human(s) are still invalid and need to be updated before they can be invited.`,
            },
          ]}
        />
      )}
    </div>
  );
};
