import cn from "classnames";
import { merge } from "lodash";
import type { FC } from "react";
import { useEffect, useState } from "react";
import type { Props } from "react-select";
import ReactSelect from "react-select";
import type { AsyncProps } from "react-select/async";
import ReactAsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import { InputWrapper, type InputProps } from "$/components/Input";

const customStyles = () => ({
  multiValue: (styles, { data }) =>
    data.isFixed
      ? {
          ...styles,
          backgroundColor: data?.backgroundColor || "#000",
          borderColor: "blue",
        }
      : { ...styles, backgroundColor: data?.backgroundColor || "#F7EFE6" },
  multiValueLabel: (styles, { data }) =>
    data.isFixed
      ? {
          ...styles,
          backgroundColor: data?.backgroundColor,
          color: data?.color || "#000",
          paddingRight: 6,
        }
      : {
          ...styles,
          backgroundColor: data?.backgroundColor,
          color: data?.color || "#000",
        },
  multiValueRemove: (styles, { data }) =>
    data.isFixed
      ? { ...styles, display: "none", color: data?.color || "#000" }
      : {
          ...styles,
          color: data?.color || "#000",
          ":hover": { color: "red" },
        },
  control: (styles) => ({
    ...styles,
    borderColor: "#edd3b6",
    minHeight: "43px",
    borderRadius: "0.25rem",
    ":hover": {
      borderColor: "#edd3b6",
    },
  }),
  input: (styles) => ({
    ...styles,
    paddingLeft: "0.45rem",
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: "0.45rem",
  }),
  singleValue: (styles) => ({
    ...styles,
    paddingLeft: "0.45rem",
    fontSize: "1rem",
  }),
  placeholder: (styles) => ({
    ...styles,
    paddingLeft: "0.45rem",
    fontSize: "1rem",
    color: "#cdd4de",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
});

export type Option = {
  label: string;
  value: string;
};

export type SelectProps = Omit<InputProps, "value" | "defaultValue"> &
  Props<any, boolean> & {
    isCreatable?: boolean;
    wrapperClass?: string;
    resetErrorOnMount?: boolean;
  };

export type AsyncSelectProps = Omit<InputProps, "value" | "defaultValue"> &
  AsyncProps<any, any, any> & { noOptionsMessage?: string };

export const Select = ({
  label,
  isCreatable,
  wrapperClass,
  required,
  helperText,
  helperTextClasses,
  hideLabel,
  resetErrorOnMount,
  disabled,
  noOptionsMessage,
  error,
  ...props
}: SelectProps) => {
  const [showError, setShowError] = useState<boolean>(!resetErrorOnMount);
  const [selectPortal, setSelectPortal] = useState<HTMLElement>();

  useEffect(() => {
    setSelectPortal(document.body);
  }, []);

  const styles = customStyles();
  const SelectComponent = isCreatable ? (
    <CreatableSelect
      isClearable
      noOptionsMessage={() => "Start typing to see results"}
      classNamePrefix="react-select"
      placeholder=" "
      {...props}
      onChange={(event) => {
        if (props?.onChange) props.onChange(event);
      }}
      isDisabled={disabled}
      className={cn({ "border-error": props?.isInvalid }, props.className)}
      styles={merge(styles, props.styles || {})}
    />
  ) : (
    <ReactSelect
      noOptionsMessage={() => noOptionsMessage ?? "Start typing to see results"}
      classNamePrefix="react-select"
      placeholder=" "
      {...props}
      onChange={(event) => {
        if (props?.onChange) props.onChange(event);
      }}
      isDisabled={disabled}
      menuPortalTarget={selectPortal}
      className={cn({ "border-error": props?.isInvalid }, props.className)}
      styles={merge(styles, props.styles || {})}
    />
  );

  return (
    <InputWrapper
      label={label}
      className={wrapperClass}
      required={required}
      hideLabel={hideLabel}
      helperText={helperText}
      isInvalid={props?.isInvalid || !!error}
      helperTextClasses={helperTextClasses}
      error={error}
    >
      {SelectComponent}
    </InputWrapper>
  );
};

export const AsyncSelect: FC<AsyncSelectProps> = ({
  label,
  required,
  wrapperClass,
  helperText,
  helperTextClasses,
  error,
  hideLabel,
  disabled,
  ...props
}) => {
  const styles = customStyles();

  return (
    <InputWrapper
      label={label}
      className={wrapperClass}
      required
      helperText={helperText}
      hideLabel={hideLabel}
      isInvalid={props?.isInvalid || !!error}
      helperTextClasses={helperTextClasses}
      error={error}
    >
      <ReactAsyncSelect
        noOptionsMessage={() => "Start typing to see results"}
        classNamePrefix="react-select"
        placeholder=" "
        {...props}
        isDisabled={disabled}
        className={cn({ "border-error": props?.isInvalid }, props.className)}
        styles={merge(styles, props.styles || {})}
      />
    </InputWrapper>
  );
};
