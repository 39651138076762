import type { FC } from "react";
import { Col, Row } from "antd";

type PageHeaderBlockProps = {
  image: {
    src: string;
    alt: string;
  };
};

export const PageHeaderBlock: FC<PageHeaderBlockProps> = ({
  children,
  image: { src, alt },
}) => (
  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
    <Col lg={14}>{children}</Col>
    <Col lg={6} push={2}>
      <img src={src} alt={alt} className="site-page-header-image" />
    </Col>
  </Row>
);
