import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import {
  Session,
  useCancelSessionMutation,
  useGetSessionByIdQuery,
} from "$/graphql/types.generated";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { TextArea } from "$/components/TextArea";
import { Button } from "$/components/Button";
import { useUser } from "$/state/user";
import { CoacheeSessionCard } from "$/components/SessionCard/CoacheeSessionCard";

interface IProps extends IPageBaseProps {}

export const CancelPage: React.FC<IProps> = () => {
  useScrollTo();
  const navigate = useNavigate();
  const user = useUser();
  const params = useParams();
  const cancel = useCancelSessionMutation();
  const [cancellationReason, setCancellationReason] = useState<string>("");

  const { data, isLoading } = useGetSessionByIdQuery({
    id: params.sessionId,
  });

  const session = data?.getSession;

  useEffect(() => {
    if (user?.currentUser?.id && session?.id) {
      if (user?.currentUser?.coach?.id === session?.coachId) {
        navigate(`/sessions/${params.sessionId}/cancel/coach`);
      } else if (user?.currentUser?.id !== session?.teamMember?.user?.id) {
        navigate(`/`);
      }
    }
  }, [user.currentUser?.id, session?.id]);

  return (
    <div className="mb-20">
      <HtmlMeta title="Cancel session" />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex justify-center items-center w-full mt-8">
          <div className="w-[90%] md:w-[600px] gap-y-4 flex flex-col items-center">
            {dayjs(session?.startsAt).isAfter(dayjs()) ? (
              <>
                {" "}
                <h1 className="text-4xl text-center">Do you want to cancel this booking?</h1>
                <div className="md:w-[600px] mx-auto my-6">
                  <CoacheeSessionCard
                    session={session as Session}
                    timescale="future"
                    hideActions
                    hideSubActions
                  />
                </div>
                <p className="text-lg font-[450] text-center mb-0 mt-8">
                  We require a minimum of
                  <span className="bg-yellow px-[4px] mx-[calc(1ch-6px)]">48 hours</span>before the
                  start of the session to both cancel or reschedule, unless it&apos;s an emergency,
                  so that your slot can be used by another human.
                </p>
                <p className="text-lg  text-center">
                  Please include a reason for your cancellation, this will be shared with your
                  coach. Please remember that on the other side of the call there is a person who
                  has booked their time for you.
                </p>
                <TextArea
                  placeholder="Your reason for cancelling"
                  className="w-full md:w-[600px]"
                  value={cancellationReason}
                  onChange={(event) => setCancellationReason(event.target.value)}
                />
                <Button
                  disabled={!cancellationReason.length}
                  primary
                  isSubmitting={cancel.isPending}
                  onClick={async () => {
                    await cancel.mutateAsync({
                      sessionId: session!.id,
                      reason: cancellationReason,
                    });
                    navigate(`/sessions/${session?.id}/cancel/confirm`);
                  }}
                >
                  Yes, cancel my session
                </Button>
              </>
            ) : (
              <>
                <h1 className="text-4xl text-center mb-0">This session cannot be cancelled</h1>
                <p className="text-lg mb-0">
                  The date for this session has already passed, so cannot be cancelled.
                </p>

                <div className="md:w-[600px] mx-auto my-6">
                  <CoacheeSessionCard session={session as Session} timescale="past" />
                </div>
                <a href="/">
                  <Button primary large className="w-full">
                    Back home
                  </Button>
                </a>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
