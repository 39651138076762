import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Layout } from "antd";
import qs from "query-string";

import { HtmlMeta } from "$/components/HtmlMeta";
import { LoadingSpinner } from "$/components/LoadingSpinner";

import { useScrollTo } from "$/hooks";

import { IPageBaseProps } from "$/interfaces";

interface IProps extends IPageBaseProps {}

export const BookingIframeRedirect: React.FC<IProps> = () => {
  const location = useLocation();

  useScrollTo();

  const query = qs.parse(location.search);

  useEffect(() => {
    if (window && query) {
      const link = document.createElement("a");

      link.href = `${window.location.origin}/booking-confirmed?${location.search}`;
      link.target = "_top";

      document.body.appendChild(link);

      link.click();
    }
  }, [query]);

  return (
    <Layout>
      <HtmlMeta title="Booking Confirmed" />
      <LoadingSpinner />
    </Layout>
  );
};
