import React from "react";
import cn from "classnames";

interface SpinnerProps extends React.ComponentPropsWithoutRef<"div"> {}

export const LoadingSpinner = ({ className = "", color, ...props }: SpinnerProps) => {
  return (
    <div className="flex items-center justify-center">
      <div
        className={cn(
          "loader h-5 w-5 rounded-full border-[0.2rem] border-t-[0.2rem] border-solid border-[#f7c42140] ease-linear border-t-orange",
          className,
        )}
        style={{ borderTopColor: color }}
        {...props}
      />
    </div>
  );
};
