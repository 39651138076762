import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import { useScrollTo } from "$/hooks";
import { HtmlMeta } from "$/components/HtmlMeta";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { CoachBio } from "$/components/CoachBio";
import { useUser } from "$/state/user";
import { useCompany } from "$/state/company";
import { useCompanySubscription } from "$/state/company-subscription";
import { IPageBaseProps } from "$/interfaces";
import {
  AccountType,
  CoachEvent,
  useCoachAvailabilityQuery,
  useCreateAccountMutation,
  useGetCoachQuery,
  useGetHostedPageMutation,
} from "$/graphql/types.generated";
import { Mixpanel } from "$/tracking";
import { Scheduler } from "../Scheduler";
import { LabelList } from "$/components/CoachCard";
import { app } from "$/configs/app.config";
import { SchedulerProvider, useScheduler } from "../Scheduler/schedulerContext";
import { SchedulerTimes } from "../Scheduler/SchedulerTimes";
import { Button } from "$/components/Button";
import { ArrowLeft } from "lucide-react";
import { ROUTE_PATHS } from "$/configs/routes";
import { Modal } from "$/components/Modal";
import { BusinessOrPersonal, RegistrationForm } from "$/pages/Auth/Register";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Auth } from "@aws-amplify/auth";
import * as CompanyEmailValidator from "company-email-validator";
import * as z from "zod";
import { useAuth } from "$/state/auth";
import { toast } from "$/components/Toaster";
import { getItemPriceId } from "$/utils/subscription";
import CoachTestimonials from "$/components/CoachCard/CoachTestimonials";
import { DateTime } from "luxon";
import { LoginForm } from "$/components/LoginForm";
import { differenceInDays, parseISO } from "date-fns";

interface IProps extends IPageBaseProps {}

const registerSchema = z.object({
  email: z
    .string()
    .min(1, "Please enter your email")
    .refine(
      (email: string) => CompanyEmailValidator.isCompanyEmail(email),
      "You must use your work email",
    ),
  firstName: z.string().min(1, "Please enter your first name"),
  lastName: z.string().min(1, "Please enter your last name"),
  password: z.string().min(8, `Please enter a password of at least 8 characters`),
  companyName: z.string().min(1, "Please enter your company name"),
  termsAndConditions: z
    .boolean()
    .refine((val) => val, "You must agree to the terms and conditions"),
});

const SignUpForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const auth = useAuth();
  const [businessOrPersonal, setBusinessOrPersonal] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      companyName: "",
      termsAndConditions: false,
    },
  });

  const createAccount = useCreateAccountMutation();

  return (
    <div className="text-center">
      {!businessOrPersonal ? (
        <BusinessOrPersonal
          onClick={(e, _accountType: string) => {
            Mixpanel.track("Business or personal selected", { type: _accountType });
            setBusinessOrPersonal(_accountType);
          }}
          isSubmitting={isSubmitting}
        />
      ) : (
        <div>
          <h2 className="text-4xl">Create your account</h2>
          <RegistrationForm
            register={register}
            errors={errors}
            accountType={businessOrPersonal}
            control={control}
            isSubmitting={isSubmitting || isLoading}
            showHeader={false}
            className="!max-w-[80%]"
            submit={(e) =>
              handleSubmit(
                () => {
                  handleSubmit(async (values: z.infer<typeof registerSchema>) => {
                    setIsLoading(true);
                    try {
                      const email = values.email?.trim().toLowerCase();

                      const newUser = await Auth.signUp({
                        username: email,
                        password: values.password,
                        attributes: {
                          email,
                          given_name: values.firstName,
                          family_name: values.lastName,
                          "custom:companyName": values.companyName,
                          "custom:role": "admin",
                          "custom:type": businessOrPersonal,
                        },
                      });

                      await createAccount.mutateAsync({
                        input: {
                          cognitoId: newUser.userSub,
                          companyName: values.companyName,
                          email,
                          firstName: values.firstName,
                          lastName: values.lastName,
                          type: businessOrPersonal,
                        },
                      });

                      await Auth.signIn(email, values.password);

                      await auth.initializeUser();
                      setIsLoading(false);
                      onSubmit();
                    } catch (err) {
                      setIsLoading(false);
                      toast.error("There was a problem creating your account", "Please try again");
                    }
                  })(e);
                },
                () => {},
              )(e)
            }
          />
        </div>
      )}
    </div>
  );
};

const SignUpModal = ({ nextUrl }: { nextUrl: string }) => {
  const navigate = useNavigate();
  const [stage, setStage] = useState("intro");

  if (stage === "intro")
    return (
      <div className="flex flex-col items-center">
        <img src="/images/My_account.svg" alt="" className="h-[220px] w-[220px]" />
        <h2 className="text-4xl">You need a membership to book a session</h2>
        <p>
          It only takes a few clicks, and our memberships start at £39.99/month (+VAT). Cancel
          anytime.
        </p>
        <div className="flex gap-x-8 mt-10">
          <Button
            primary
            onClick={() => {
              Mixpanel.track("Start my membership clicked");
              setStage("register");
            }}
            large
          >
            Start my membership
          </Button>
          <Button
            tertiary
            onClick={() => {
              Mixpanel.track("I already have an account clicked");
              setStage("login");
            }}
            large
          >
            I already have an account
          </Button>
        </div>
      </div>
    );

  if (stage === "register")
    return (
      <SignUpForm
        onSubmit={() => {
          const encodedNext = btoa(nextUrl);
          navigate(`?s=1&n=${encodedNext}`);
        }}
      />
    );

  if (stage === "login") {
    return (
      <LoginForm
        onSubmit={() => {
          const encodedNext = btoa(nextUrl);
          navigate(`?s=1&n=${encodedNext}`);
        }}
      />
    );
  }
};

const JoinTeamModal = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center">
      <img src="/images/My_account.svg" alt="" className="h-[220px] w-[220px]" />
      <h2 className="text-4xl">Start a memebership to book sessions</h2>
      <p className="m-0">
        Our Team memberships are for 2+ people and start at £39.99 (+VAT) per person.
      </p>
      <p className="mt-0">The larger your team, the cheaper the price is per person. </p>
      <a
        href={"https://morehappi.com/our-pricing?u=team"}
        target="_blank"
        rel="noopener noreferrer"
        className="mb-6"
      >
        View pricing information
      </a>
      <div className="flex gap-x-8">
        <Button
          primary
          onClick={() => {
            Mixpanel.track("Start my membership clicked");
            navigate("/auth/register");
          }}
          large
        >
          Start my membership
        </Button>
        <Button
          tertiary
          onClick={() => {
            Mixpanel.track("I already have an account clicked");
            navigate("/auth/login");
          }}
          large
        >
          I already have an account
        </Button>
      </div>
    </div>
  );
};

const ChargebeeModal = () => {
  const user = useUser();
  const company = useCompany();
  const companySubscription = useCompanySubscription();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getHostedPageMutation = useGetHostedPageMutation({
    onError: () => {
      toast.error(
        "There was a problem activating your subscription.",
        "Please contact us via chat.",
      );
    },
    onSuccess: (data) => {
      Mixpanel.track("Company Subscription Checkout Opened", {
        active: true,
        status: "active",
      });

      const chargebeeInstance = companySubscription.getChargebeeInstance();

      const hostedPageCallbackSuccess = async (params: any) => {
        toast.success("Thank you! Your subscription is now active", null);

        Mixpanel.track("Company Subscription Activated", {
          active: true,
          status: "active",
        });

        companySubscription.setSubscriptionStatus({
          active: true,
          status: "active",
        });

        if (company.currentCompany?.accountType === AccountType.Free) {
          company.update({
            accountType: AccountType.Starter,
          });
        }
        if (company.currentCompany?.accountType === AccountType.Individual) {
          company.update({
            accountType: AccountType.Individual,
          });
        }

        await company.updateAdmin({
          subscriptionActive: true,
        });
        const next = searchParams.get("n");
        const decoded = atob(next);
        navigate(decoded ?? "#");
      };

      chargebeeInstance?.openCheckout({
        hostedPage: () => Promise.resolve(data?.getHostedPage),
        loaded: () => {
          companySubscription.toggleUpdatingSubscription();
        },
        success: hostedPageCallbackSuccess,
        error: () => {
          toast.error(
            "There was a problem updating your subscription.",
            "Please contact us via chat.",
          );
        },
        close: () => {
          companySubscription.toggleUpdatingSubscription();
        },
      });
    },
  });

  useEffect(() => {
    const open = async () => {
      const itemPriceId = getItemPriceId(company.currentCompany);

      await getHostedPageMutation.mutateAsync({
        userId: user.currentUser?.id,
        companyId: company.currentCompany?.id,
        itemPriceId,
      });
    };
    open();
  }, []);

  return <></>;
};

export const getConfirmUrl = ({
  loggedIn,
  handle,
  date,
  time,
  searchParams,
  postBookingUrl,
}: {
  loggedIn: boolean;
  handle: string;
  date: DateTime;
  time: string | number;
  searchParams?: URLSearchParams;
  postBookingUrl?: string;
}) => {
  const params = searchParams ?? new URLSearchParams();
  params.set("date", date.toFormat("yyyyMMdd"));
  params.set("time", time.toString());
  if (searchParams?.get("r")) {
    params.set("r", searchParams.get("r"));
  }
  if (postBookingUrl) {
    params.set("returnUrl", postBookingUrl);
  }

  return loggedIn
    ? `/coach/${handle}/confirm?${params.toString()}`
    : `/coach/${handle}?${params.toString()}`;
};

interface ScheduleProps {
  coachId: string;
  coachHandle: string;
  postBookingUrl?: string;
  context?: {
    package?: string;
  };
  lastDate?: string;
}

export const Schedule = ({
  coachId,
  coachHandle,
  postBookingUrl,
  context,
  lastDate,
}: ScheduleProps) => {
  const user = useUser();
  const company = useCompany();
  const companySubscription = useCompanySubscription();
  const subscription = useCompanySubscription();
  const navigate = useNavigate();
  const { setEvents, month } = useScheduler();
  const [searchParams] = useSearchParams();

  if (context?.["package"]) searchParams.set("package", context["package"]);

  const [showSignUpModal, setShowSignUpModal] = useState(
    searchParams.get("date")
      ? getConfirmUrl({
          loggedIn: !!user.currentUser?.id,
          handle: coachHandle,
          date: DateTime.fromFormat(searchParams.get("date"), "yyyyMMdd"),
          time: searchParams.get("time"),
          searchParams,
          postBookingUrl,
        })
      : null,
  );
  const [showUpgradeModal, setShowUpgradeModal] = useState(null);
  const [showProgrammeModal, setShowProgrammeModal] = useState(false);
  const [showJoinTeamModal, setShowJoinTeamModal] = useState(false);
  const [showTimeScheduler, setShowTimeScheduler] = useState(false);
  const showChargebeeModal = searchParams.get("s") === "1";
  const teamViewingFromHomepage = searchParams.get("ctx") === "team";

  const { data, isFetching } = useCoachAvailabilityQuery(
    {
      coachId,
      startsAt: month.startOf("month").toISO(),
      endsAt:
        lastDate && differenceInDays(parseISO(lastDate), parseISO(month.endOf("month").toISO())) < 0
          ? lastDate
          : month.endOf("month").toISO(),
    },
    { enabled: !!coachId },
  );

  useEffect(() => {
    if (!isFetching && data.coachAvailability?.length) {
      setEvents((data?.coachAvailability ?? []) as Array<CoachEvent>);
    }
  }, [isFetching]);

  return (
    <div>
      {isFetching ? (
        <div className="w-full flex flex-col gap-4 items-center justify-center h-[465px] md:h-[646px]">
          <h1>Loading availability</h1>
          <LoadingSpinner className="!h-12 !w-12" />
        </div>
      ) : (
        <>
          {showTimeScheduler ? (
            <SchedulerTimes
              hideTimeScheduler={() => setShowTimeScheduler(false)}
              selectTime={(date, time) => {
                Mixpanel.track("Coach booking - Select time", { loggedIn: !!user.currentUser });

                const nextUrl = getConfirmUrl({
                  loggedIn: !!user.currentUser?.id,
                  handle: coachHandle,
                  date,
                  time,
                  searchParams,
                  postBookingUrl,
                });

                if (user.currentUser) {
                  if (
                    subscription.state.isActive ||
                    company.currentCompany?.accountType === AccountType.Enterprise ||
                    company.currentCompany?.accountType === AccountType.Starter ||
                    (user.currentUser.activePackage &&
                      !user.currentUser.activePackage?.hitSessionLimit)
                  ) {
                    navigate(nextUrl);
                  } else if (companySubscription.state.status === "expiredPackage") {
                    setShowProgrammeModal(true);
                  } else {
                    setShowUpgradeModal(nextUrl);
                  }
                } else if (teamViewingFromHomepage) {
                  setShowJoinTeamModal(true);
                } else {
                  setShowProgrammeModal(true);
                }
              }}
            />
          ) : (
            <Scheduler showTimeScheduler={() => setShowTimeScheduler(true)} />
          )}
        </>
      )}

      {showSignUpModal && (
        <Modal onClose={() => setShowSignUpModal(null)} size="lg">
          <SignUpModal nextUrl={showSignUpModal} />
        </Modal>
      )}
      {showJoinTeamModal && (
        <Modal onClose={() => setShowJoinTeamModal(false)} size="lg">
          <JoinTeamModal />
        </Modal>
      )}
      {showUpgradeModal && (
        <Modal onClose={() => setShowUpgradeModal(null)} size="lg">
          <div className="flex flex-col items-center">
            <img src="/images/My_account.svg" alt="" className="h-[220px] w-[220px]" />
            <h2 className="text-4xl">You need a membership to book a session</h2>
            {user.currentUser.isSponsor ? (
              <>
                <p>
                  It only takes a few clicks. Membership costs{" "}
                  {company.currentCompany.accountType === AccountType.Individual
                    ? "£49.99/month (incl VAT)"
                    : "£39.99/month per user (+VAT)"}
                  . Cancel anytime.
                </p>
                <div className="flex gap-x-8 mt-10">
                  <a href={`?s=1&n=${btoa(showUpgradeModal)}`}>
                    <Button
                      primary
                      large
                      onClick={() => Mixpanel.track("Start my membership clicked")}
                    >
                      Start my membership
                    </Button>
                  </a>
                </div>
              </>
            ) : (
              <p>
                Your team does not have a valid membership. Reach out to your sponsor to ask them to
                upgrade.{" "}
              </p>
            )}
          </div>
        </Modal>
      )}
      {showProgrammeModal && (
        <Modal onClose={() => setShowProgrammeModal(null)} size="lg">
          <div className="flex flex-col items-center">
            <img src="/images/My_account.svg" alt="" className="h-[220px] w-[220px]" />
            <h2 className="text-4xl text-center">
              You need to start a programme to book a session
            </h2>
            <>
              <p className="text-center text-lg">
                We offer 1, 3 and 6 session programmes on topics such as improving Confidence,
                stopping Procrastination, gaining Clarity and more.
              </p>
              <div className="flex gap-x-8 mt-10">
                <a href={`/enquiry`}>
                  <Button primary large onClick={() => Mixpanel.track("Start a programme clicked")}>
                    Start a programme
                  </Button>
                </a>
              </div>
            </>
          </div>
        </Modal>
      )}
      {showChargebeeModal && <ChargebeeModal />}
    </div>
  );
};

export const Coach: React.FC<IProps> = () => {
  const user = useUser();
  const navigate = useNavigate();
  const params = useParams();
  const { coachHandle } = params;

  useScrollTo();

  const getCoachQuery = useGetCoachQuery({
    where: { handle: coachHandle },
  });

  useEffect(() => {
    if (!coachHandle || getCoachQuery.error) {
      return navigate("/coaches");
    }

    if (getCoachQuery?.data?.getCoach) {
      Mixpanel.track("Coach Profile Viewed", {
        handle: coachHandle,
        loggedIn: !!user.currentUser,
      });
    }
  }, [coachHandle, getCoachQuery]);

  const coach = getCoachQuery?.data?.getCoach;

  return (
    <div className="w-screen">
      <HtmlMeta title="Coach" />
      <div className="mx-auto mb-20 mt-12">
        {getCoachQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="w-full xl:w-[1000px] px-2 xl:px-0 mx-auto pb-8">
              <section className="grid w-full grid-flow-row md:grid-flow-col bg-white md:bg-black m-0 items-center outline outline-1 outline-solid outline-skin rounded-2xl">
                <div className="h-[100%] w-full md:w-fit bg-white md:bg-black flex flex-col items-center justify-center border rounded-full md:rounded-l-2xl md:rounded-r-none mt-4 md:m-0">
                  <img
                    src={decodeURIComponent(
                      `${app.CLOUDFRONT.USER_ASSETS}/${coach.id}/thumbnail.jpg`,
                    )}
                    alt={coach?.displayName ?? "Profile photo"}
                    className="object-cover  aspect-w-1 aspect-h-1 w-[100px] h-[100px] md:w-[270px] md:h-[270px] rounded-full md:rounded-l-2xl md:rounded-r-none"
                  />
                </div>
                <div className="p-4 w-full md:w-[calc(100vw-270px)] xl:w-[730px] h-full bg-white rounded-xl md:rounded-r-xl md:rounded-l-none">
                  <div>
                    <div className="w-full flex flex-col md:flex-row justify-start md:justify-between md:items-center">
                      <h1 className="text-2xl font-semibold mb-0 whitespace-nowrap">
                        {coach.displayName}
                      </h1>
                      <CoachTestimonials coach={coach} />
                    </div>
                    <h2 className="text-xl font-medium">{coach?.headline}</h2>
                    {coach?.bio && <CoachBio bio={coach?.bio} />}
                    {coach?.trainingSchool && <h4>Trained at {coach.trainingSchool}</h4>}
                    <div className="flex gap-x-4 justify-between">
                      <div className="w-[45%]">
                        <LabelList
                          header="Focus"
                          list={
                            coach.specialisms.length
                              ? coach!.specialisms!.slice(0, 2)
                              : [{ label: "Generalist" }]
                          }
                        />
                      </div>
                      <div className="w-[45%]">
                        <LabelList
                          header="Approach"
                          list={
                            coach.approaches.length
                              ? coach!.approaches!.slice(0, 2)
                              : [{ label: "Flexible" }]
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <SchedulerProvider>
                <Schedule coachId={getCoachQuery?.data?.getCoach?.id} coachHandle={coachHandle} />
              </SchedulerProvider>
            </div>

            <div className="mt-20 flex flex-col items-center text-center md:w-[60%] mx-4 md:mx-auto gap-y-8">
              <img src="/images/user_tools/appraisals.svg" alt="img" />
              <div>
                <h2 className="text-4xl">We have 100+ coaches waiting for you</h2>
                <p className="text-lg">
                  If this coach doesn&apos;t look right for you, that&apos;s okay! If you&apos;d
                  like to see different coaches, you can update your search or browse all our
                  coaches.
                </p>
              </div>
              <div className="flex flex-col md:flex-row gap-4">
                <Button
                  leftIcon={<ArrowLeft />}
                  large
                  className="bg-bottleGreen border-bottleGreen border-solid text-white w-full md:w-fit"
                  onClick={() =>
                    window.history.length > 1
                      ? navigate(-1)
                      : navigate(ROUTE_PATHS.SESSIONS.COACHES)
                  }
                >
                  Back to selected coaches
                </Button>
                <a href={ROUTE_PATHS.SESSIONS.COACHES}>
                  <Button
                    secondary
                    large
                    className="border-bottleGreen text-bottleGreen w-full md:w-fit"
                  >
                    Change my search
                  </Button>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
