import { useCompanySubscription } from "$/state/company-subscription";
import { useUser } from "$/state/user";
import { useNavigate } from "react-router";
import { ProgrammeDetails } from "../helpers/storyblok";
import { BuildPackage, ExplorePackage, ProgressPackage } from "./Programme";
import { SubscriptionRedirectModal } from "$/components/Modals/SubscriptionRedirectModal";
import { UserRole } from "$/graphql/types.generated";
import { NoAccount } from "../account";

interface Props {
  params: string;
  send: (data: { key: string; value: string }) => Promise<void>;
  data: ProgrammeDetails;
}

export const ChooseAProgramme = ({ params, send, data }: Props) => {
  const user = useUser();
  const navigate = useNavigate();
  const companySubscription = useCompanySubscription();

  const expiredSubscriptionCustomer =
    companySubscription.state.subscriptionCustomer && !companySubscription.state.isActive;

  const showPrice = user.currentUser?.id
    ? companySubscription.state.packageCustomer && !companySubscription.state.subscriptionCustomer
    : true;

  // This has been added in to remove individuals from signing up
  // Rather than deleting everything, We can have this in as a stop in case we go back to allowing individuals.
  if (
    user?.currentUser?.role === UserRole.Individual &&
    companySubscription.state.packageCustomer
  ) {
    return <NoAccount />;
  }

  return (
    <div className="flex flex-col md:flex-row gap-4">
      <SubscriptionRedirectModal
        isOpen={expiredSubscriptionCustomer}
        onOk={() => navigate("/company/billing")}
        onClose={() => navigate("/")}
      />

      {!companySubscription.state.subscriptionCustomer && (
        <ExplorePackage
          onClick={async () => {
            await send({ key: "package", value: "explore" });
            navigate(`/checkout?${params}&programme=explore`);
          }}
          showPrice={showPrice}
          data={data}
        />
      )}
      <BuildPackage
        onClick={async () => {
          await send({ key: "package", value: "build" });
          if (companySubscription.state.subscriptionCustomer) {
            navigate(`/add-package?${params}&programme=build`);
          } else {
            navigate(`/checkout?${params}&programme=build`);
          }
        }}
        showPrice={showPrice}
        data={data}
      />
      <ProgressPackage
        onClick={async () => {
          await send({ key: "package", value: "progress" });
          if (companySubscription.state.subscriptionCustomer) {
            navigate(`/add-package?${params}&programme=progress`);
          } else {
            navigate(`/checkout?${params}&programme=progress`);
          }
        }}
        showPrice={showPrice}
        data={data}
        className={"border-4 border-skin bg-black"}
      />
    </div>
  );
};
