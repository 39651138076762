import React from "react";
import { useNavigate } from "react-router";
import { Col, Layout, PageHeader, Row } from "antd";

import { HtmlMeta } from "$/components/HtmlMeta";

import { useScrollTo } from "$/hooks";

import { IPageBaseProps } from "$/interfaces";

import { ROUTE_PATHS } from "$/configs/routes";

import { WEB_ASSETS_URL } from "$/configs/app.config";

interface IProps extends IPageBaseProps {}

export const WhereToStart: React.FC<IProps> = () => {
  const navigate = useNavigate();

  useScrollTo();

  return (
    <Layout>
      <HtmlMeta title="Where to start" />
      <Layout.Content className="site-layout-content">
        <PageHeader
          ghost
          className="site-page-header"
          title="Where do I start?"
        />
        <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}>
          <Col
            className="mh-vertical-spacer"
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={18}
          >
            <p>
              Think of coaching as a 45-min game-changing chat for any problems,
              obstacles, mood or mindset.
            </p>
            <p>Here are some potential starting points:</p>
            <ol>
              <li>
                You already have a goal in mind, but you are not sure how to get
                there. It could be running a marathon,moving forward in your
                career, parenting like you are not winging it, or swapping a bad
                habit for a not-so-bad one...
              </li>
              <li>
                You are not sure know what your goal is, but you would like to
                work it out. Maybe you are feeling a bit stuck. Frustrated in
                your role. Or looking for a change.
              </li>
              <li>
                Or maybe you do not have a starting point, but you would like to
                explore. You might be having a low week, you are not sure where
                to focus your energy, or perhaps you need help to work out where
                to start.
              </li>
            </ol>
            <p>
              These are all brilliant places to start. Our coaches will ask you
              Qs to help you identify next steps for you. Its 100% confidential
              and you are in control.
            </p>
            <p>
              Sessions are available day, evenings, weekend and, as your
              organisation is paying, can even take place in work hours.
              (bonus!)
            </p>
            <p>
              Leave feeling clearer, with more energy and ready to face
              whatevers in front of you.
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} className="mh-vertical-spacer">
            <div className="dashboard-btn-card-conatiner">
              <button
                className="dashboard-btn-card"
                onClick={() => navigate(ROUTE_PATHS.SESSIONS.NEXT_AVAILABLE)}
                type="button"
              >
                <img
                  src={`${WEB_ASSETS_URL}/TILES_how_to_book_by_availability.svg`}
                  alt="Next available coach"
                />
              </button>
              <button
                onClick={() => navigate(ROUTE_PATHS.SESSIONS.COACHES)}
                className="dashboard-btn-card"
                type="button"
              >
                <img
                  src={`${WEB_ASSETS_URL}/TILES_HowTo_BookByCategory_new.svg`}
                  alt="How to book coaching"
                />
              </button>
            </div>
          </Col>
        </Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={18}>
          <h1>Who are the More Happi coaches?</h1>
          <p>
            More Happi is an accelerator for coaches developing their own
            experience and presence within the industry, clocking up their hours
            to reach their next accreditation goal faster. They give their time
            in exchange for their own personal development and are not paid, so
            it is important that you show up to your session and leave feedback
            if you can.
          </p>
          <h1>How we select our coaches</h1>
          <p>
            They are carefully selected based on their experience and shared
            mission to transform the lives of others. They, like us, really care
            about giving you the space you need to grow and develop, resulting
            in endless possibilities. Each coach takes part in an interview, a
            trial coaching session and is required to complete a personality
            test before they can join us. This process allows us to deliver a
            high quality coaching experience with a team of talented and
            passionate coaches
          </p>
          <br />
        </Col>
      </Layout.Content>
    </Layout>
  );
};
