import { Button } from "$/components/Button";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useScheduler } from "./schedulerContext";
import cn from "classnames";
import { Fragment, useEffect, useState } from "react";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { useIsMobile } from "$/hooks";
import { DateTime } from "luxon";
import { Mixpanel } from "$/tracking";
import { useUser } from "$/state/user";

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

interface SchedulerProps {
  showTimeScheduler: () => void;
  isLoading?: boolean;
  open?: boolean;
}

export function Scheduler({ showTimeScheduler, isLoading, open }: SchedulerProps) {
  const user = useUser();
  const [hasAvailability, setHasAvailability] = useState<
    Array<{
      day: number;
      morning: boolean;
      afternoon: boolean;
      moreHappiMorning: boolean;
      moreHappiAfternoon: boolean;
    }>
  >([]);
  const { calendar, month, nextMonth, prevMonth, selectedDate, setSelectedDate, availableSlots } =
    useScheduler();
  const isMobile = useIsMobile();

  useEffect(() => {
    const monthDays = [];
    for (let i = 0; i < month.daysInMonth; i += 1) {
      const morning = availableSlots.month.find(
        (slot) => slot.startsAt.day === i + 1 && slot.startsAt.hour < 12,
      );
      const afternoon = availableSlots.month.find(
        (slot) => slot.startsAt.day === i + 1 && slot.startsAt.hour >= 12,
      );
      const moreHappiMorning = availableSlots.month.find(
        (slot) => slot.startsAt.day === i + 1 && slot.startsAt.hour < 12 && slot.colour === "green",
      );
      const moreHappiAfternoon = availableSlots.month.find(
        (slot) =>
          slot.startsAt.day === i + 1 && slot.startsAt.hour >= 12 && slot.colour === "green",
      );
      monthDays.push({
        day: i + 1,
        morning: !!morning,
        afternoon: !!afternoon,
        moreHappiMorning: !!moreHappiMorning,
        moreHappiAfternoon: !!moreHappiAfternoon,
      });
    }
    setHasAvailability(monthDays);
  }, [availableSlots]);

  return (
    <div className="flex min-h-[384px] w-full flex-col items-center pt-6 ">
      <div className="flex w-full justify-between items-center align-top mb-9 md:px-8">
        <div className="w-[30%]">
          <Button
            className={cn("border border-midnight text-midnight bg-transparent", {
              "opacity-50": month.hasSame(DateTime.now(), "month"),
            })}
            onClick={prevMonth}
            leftIcon={<ArrowLeft strokeWidth={1} size={16} />}
            disabled={month.hasSame(DateTime.now(), "month")}
          >
            {month.minus({ month: 1 }).toFormat(isMobile ? "MMM" : "MMMM")}
          </Button>
        </div>
        <h2 className="text-base m-0">{month.toFormat(isMobile ? "MMM yy" : "MMMM yyyy")}</h2>
        <div className="w-[30%] flex justify-end">
          <Button
            onClick={nextMonth}
            className="border border-midnight text-midnight bg-transparent"
            rightIcon={<ArrowRight strokeWidth={1} size={16} />}
          >
            {month.plus({ month: 1 }).toFormat(isMobile ? "MMM" : "MMMM")}
          </Button>
        </div>
      </div>

      <div className="w-full  mb-4 md:w-[667px] grid grid-cols-7 gap-3">
        {days.map((day, dayIndex) => (
          <div key={`day-${dayIndex}`} className="text-center font-semibold text-lg md:text-2xl">
            {day}
          </div>
        ))}
      </div>
      {isLoading ? (
        <div className="h-[450px] flex justify-center items-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="w-full min-h-[270px] md:min-h-[450px] md:w-[667px] grid grid-cols-7 gap-3">
          {calendar.map((week, weekIndex) => (
            <Fragment key={`week-${weekIndex}`}>
              {week.map((day, dayIndex) => {
                const hasAvailabilityForDay = hasAvailability.find((_day) => _day.day === day);
                const afternoonAvailability = hasAvailabilityForDay?.afternoon;
                const morningAvailability = hasAvailabilityForDay?.morning;
                const moreHappiMorning = hasAvailabilityForDay?.moreHappiMorning;
                const moreHappiAfternoon = hasAvailabilityForDay?.moreHappiAfternoon;
                return (
                  <div
                    key={`day-${dayIndex}`}
                    className={cn(
                      "h-[45px] w-[45px] xl:h-[65px] xl:w-[85px] cursor-pointer items-center justify-center bg-transparent relative md:rounded-lg rounded-xl",
                      {
                        invisible: day === 0,
                        "!bg-primary":
                          selectedDate.day === day && selectedDate.month === month.month,
                        "pointer-events-none": !morningAvailability && !afternoonAvailability,
                      },
                    )}
                    onClick={() => {
                      Mixpanel.track("Coach booking - Select date", {
                        loggedIn: !!user.currentUser,
                      });
                      setSelectedDate(month.set({ day }));
                      showTimeScheduler();
                    }}
                    id={`schedule-day-${String(day).padStart(2, "0")}`}
                  >
                    <div
                      className={cn("z-10", {
                        schedulerMorning: morningAvailability,
                      })}
                    />
                    <div
                      className={cn("z-10", {
                        openSchedulerMorning: moreHappiMorning,
                      })}
                    />
                    <div
                      className={cn("z-10", {
                        schedulerAfternoon: afternoonAvailability,
                      })}
                    />
                    <div
                      className={cn("z-10", {
                        openSchedulerAfternoon: moreHappiAfternoon,
                      })}
                    />
                    <div className="relative z-20 w-[45px] h-[45px] md:w-[85px] md:h-[65px] flex justify-center items-center md:text-xl">
                      {String(day).padStart(2, "0")}
                    </div>
                  </div>
                );
              })}
            </Fragment>
          ))}
        </div>
      )}

      {open ? (
        <div className="flex justify-center">
          <section className="flex flex-col gap-x-4 mt-4 mx-auto">
            <div className="flex items-center gap-x-2 pb-2">
              <div
                className={cn(
                  "h-[18px] w-[24px] cursor-pointer items-center justify-center bg-transparent relative flex",
                )}
              >
                <div className={cn("z-10 openSchedulerMorning-small absolute left-0 top-0")} />
              </div>
              <div className="text-sm">More Happi availability in the mornings</div>
            </div>

            <div className="flex items-center  gap-x-2">
              <div
                className={cn(
                  "h-[18px] w-[24px]  cursor-pointer items-center justify-center bg-transparent relative flex",
                )}
              >
                <div className={cn("z-10 openSchedulerAfternoon-small absolute left-0 top-0")} />
              </div>
              <div className="text-sm">More Happi availability in the afternoons</div>
            </div>
          </section>
        </div>
      ) : (
        <div className="flex justify-center w-[741px]">
          <section className="flex flex-col  gap-x-4 mt-4 mx-auto">
            <div className="flex items-center gap-x-2">
              <div
                className={cn(
                  "h-[18px] w-[24px] cursor-pointer items-center justify-center bg-transparent relative flex",
                )}
              >
                <div className={cn("z-10 schedulerMorning-small absolute left-0 top-0")} />
              </div>
              <div className="text-sm">Available in the mornings</div>
            </div>

            <div className="flex items-center  gap-x-2">
              <div
                className={cn(
                  "h-[18px] w-[24px]  cursor-pointer items-center justify-center bg-transparent relative flex",
                )}
              >
                <div className={cn("z-10 schedulerAfternoon-small absolute left-0 top-0")} />
              </div>
              <div className="text-sm">Available in the afternoons</div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
