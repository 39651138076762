import { Button } from "$/components/Button";
import Checkbox from "$/components/Checkbox";
import { Input } from "$/components/Input";
import { useState } from "react";
import { Send } from "..";

export const MultiChoice = ({
  send,
  title,
  subtitle,
  options,
  includeOther,
  next,
  value = [],
}: {
  send: Send;
  title: string;
  subtitle: string;
  options: Array<{ label: string; state: string }>;
  includeOther?: boolean;
  next?: string;
  value?: Array<string>;
}) => {
  const [other, setOther] = useState(
    value.length
      ? value
          ?.filter((val) => !options.find((opt) => opt.label.replaceAll(" ", "-") === val))
          .join(",")
      : "",
  );
  const [selected, setSelected] = useState(
    value.length
      ? value?.filter((val) => !!options.find((opt) => opt.label.replaceAll(" ", "-") === val))
      : "",
  );

  return (
    <>
      <h1 className="text-center text-2xl md:text-4xl font-semibold mb-2">{title}</h1>
      <p className="text-center mb-14 text-lg">{subtitle}</p>
      <div className="w-full  flex items-center justify-center">
        <div className=" max-w-[400px] flex flex-col gap-4 items-start">
          {options.map((option) => (
            <Checkbox
              key={option.label.replaceAll(" ", "-")}
              checked={selected.includes(option.label.replaceAll(" ", "-"))}
              controlled
              onChange={(newState) => {
                setSelected((prev) => {
                  const newValues = [...prev];
                  const id = option.label.replaceAll(" ", "-");

                  if (newState.target.checked) {
                    return [...newValues, id];
                  } else {
                    return newValues.filter((item) => item !== id);
                  }
                });
              }}
              label={option.label}
              className="text-left"
              labelClass="text-lg"
            />
          ))}
          {includeOther && (
            <Input
              placeholder="Other (in your own words)"
              className="!rounded-3xl !border-0"
              onChange={(event) => setOther(event.target.value)}
              hideLabel
              key={`${title.split(" ").join("-")}-other`}
              value={other}
            />
          )}
        </div>
      </div>
      <Button
        primary
        large
        className="w-full mt-14"
        disabled={selected.length === 0 && !other}
        onClick={() => {
          send({ next, value: other.length ? [...selected, other] : selected });
          setSelected([]);
          setOther("");
        }}
      >
        Continue
      </Button>
    </>
  );
};
