import { Button } from "$/components/Button";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import {
  GetSessionByIdQuery,
  SessionState,
  useCountSessionsQuery,
} from "$/graphql/types.generated";
import { useIsMobile } from "$/hooks";
import { upperFirst } from "lodash";
import { Calendar, Lightbulb } from "lucide-react";
import pluralize from "pluralize";
import { Mixpanel } from "$/tracking";

export const IsProgrammeCheck = ({
  setCurrentStep,
  setIsProgramme,
  session,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  setIsProgramme: React.Dispatch<React.SetStateAction<boolean>>;
  session: GetSessionByIdQuery;
}) => {
  const isMobile = useIsMobile();

  const programme = `${upperFirst(
    session.getSession.userPackage?.plan?.toLowerCase().split("-").join(" "),
  )} (${session.getSession.userPackage.sessionCount} sessions)`;

  const { data: totalSessionCountProgramme, isLoading } = useCountSessionsQuery({
    where: {
      state: { in: [SessionState.Confirmed, SessionState.ConfirmedCoach] },
      userPackageId: { equals: session.getSession.userPackage?.id },
    },
  });

  const sessionsLeft =
    session.getSession.userPackage.sessionCount - totalSessionCountProgramme?.countSessions; // eslint-disable-line no-unsafe-optional-chaining

  const remainingSessionText = `${sessionsLeft} ${pluralize("session", sessionsLeft)} left`;

  return (
    <>
      {isLoading ? (
        <LoadingSpinner className="!h-12 !w-12" />
      ) : (
        <div className="flex flex-col max-w-[800px]">
          <section className="mx-2 md:mx-0 mb-8 bg-white rounded-xl">
            <div className="flex flex-col items-center p-6 w-full md:p-12 mx-auto text-center">
              <div className="flex self-center mb-6">
                <img src="/images/Sponsor_checklist.svg" alt="" className="h-[150px] w-[150px]" />
              </div>
              <h2 className="text-center text-xl md:text-2xl font-semibold mb-4">
                Is this part of a programme?
              </h2>
              <p className="text-center mb-4">
                It looks like {session.getSession.teamMember.user.firstName} is currently on the{" "}
                {programme} programme and has {remainingSessionText}.
              </p>
              <p>Do you want the next session to be part of this programme?</p>
              <p className="mb-6">Alternatively, you can book an ad-hoc session.</p>
              <div className="w-full flex self-center justify-center gap-4 text-center">
                <Button
                  tertiarySolid
                  large
                  className="whitespace-nowrap"
                  leftIcon={<Lightbulb color="white" />}
                  onClick={() => {
                    Mixpanel.track("Coach Booking: Ad-hoc session", {
                      coach: session.getSession.coachId,
                      coachee: session.getSession.teamMember.user.id,
                    });
                    setIsProgramme(false);
                    setCurrentStep("selectTime");
                  }}
                >
                  {isMobile ? "Ad-hoc" : "Ad-hoc Session"}
                </Button>
                <Button
                  primary
                  large
                  leftIcon={<Calendar />}
                  onClick={() => {
                    Mixpanel.track("Coach Booking: Programme session", {
                      coach: session.getSession.coachId,
                      coachee: session.getSession.teamMember.user.id,
                    });
                    setIsProgramme(true);
                    setCurrentStep("selectTime");
                  }}
                >
                  {isMobile ? "Programme" : "Programme Session"}
                </Button>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
