import { Button, Divider } from "antd";

import { useUser } from "$/state/user";
import { useCompany } from "$/state/company";

import { IPageBaseProps } from "$/interfaces";

interface IProps extends IPageBaseProps {}

export const CompanyReport = (props: IProps) => {
  const { currentUser } = useUser();
  const { currentCompany } = useCompany();

  const reportUrl = currentCompany?.reportUrl;

  const isEnterpriseSponsor =
    currentUser?.isAdmin && currentCompany?.isEnterprise;

  const copy = () => {
    return isEnterpriseSponsor ? (
      <>
        <Divider />
        <h3>Want support in engaging your team? </h3>

        <p>
          Our partners who see the highest engagement with More Happi coaching
          are those who embed coaching into their existing people and L&D
          processes and get their people managers on board!
        </p>

        <p>
          For ideas on how to do this, check out step{" "}
          <a
            href="https://midnight-revolve-64c.notion.site/4-Integrate-More-Happi-into-team-ways-of-working-7038bfd35384444e8d1749be77fda345"
            target="_blank"
            rel="noreferrer"
          >
            4 of the Sponsor checklist
          </a>
          , and{" "}
          <a
            href="https://morehappiforyourteam.youcanbook.me"
            target="_blank"
            rel="noreferrer"
          >
            book a call
          </a>{" "}
          with our Customer Success team to discuss what you’ve tried to date
          and where you need further support.
        </p>
      </>
    ) : (
      <>
        <Divider />
        <h3>Want support in engaging your team? </h3>
        <p>
          Our partners who see the highest engagement with More Happi coaching
          are those who embed coaching into their existing people and L&D
          processes and get their people managers on board!
        </p>

        <p>
          For ideas on how to do this, check out step{" "}
          <a
            href="https://midnight-revolve-64c.notion.site/4-Integrate-More-Happi-into-team-ways-of-working-7038bfd35384444e8d1749be77fda345"
            target="_blank"
            rel="noreferrer"
          >
            4 of the Sponsor checklist
          </a>
        </p>
      </>
    );
  };

  return (
    <>
      <h2 id="company_report">Happi Stats</h2>
      <p>
        Learn how your team are using and benefitting from coaching. The data in
        this report updates weekly, so you can monitor progress at any time.
        View the overall picture, or choose to filter by month.
      </p>
      <p>
        {reportUrl ? (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={decodeURIComponent(reportUrl || "#")}
          >
            <Button type="primary" disabled={!reportUrl}>
              View Report
            </Button>
          </a>
        ) : (
          <span>
            Your company report will appear here after your team has booked
            sessions.
          </span>
        )}
      </p>
      {copy()}
    </>
  );
};
