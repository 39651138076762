export function BookColor() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.87598 4C8.87598 2 12.876 1.5 16.376 1.5V12.5C12.876 12.5 8.87598 13 8.87598 15"
        fill="#3A5276"
      />
      <path
        d="M8.87598 4C8.87598 2 4.87598 1.5 1.37598 1.5V12.5C4.87598 12.5 8.87598 13 8.87598 15V4Z"
        fill="#769BB9"
      />
    </svg>
  );
}
