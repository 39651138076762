import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CompanyFeaturePermissions, useCompany } from "$/state/company";

export const usePermissionRedirect = (feature: CompanyFeaturePermissions) => {
  if (process.env.NODE_ENV !== "production") return true;

  const company = useCompany();

  const navigate = useNavigate();

  const featurePermission = company.permissions[feature];

  useEffect(() => {
    if (!featurePermission) {
      return navigate("/");
    }
  }, [featurePermission]);

  return true;
};
