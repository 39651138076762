import { Button } from "$/components/Button";
import { Modal } from "$/components/Modal";
import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

interface CountdownProps {
  initialCount: number;
  coachName: string;
  coachHandle: string;
  sessionTime: string;
}

export const Countdown: React.FC<CountdownProps> = ({
  initialCount,
  coachName,
  coachHandle,
  sessionTime,
}) => {
  const [count, setCount] = useState(initialCount);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const readbaleDateTime = DateTime.fromISO(sessionTime).toLocaleString({
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "numeric",
    hourCycle: "h12",
  });

  useEffect(() => {
    const timerId = setInterval(() => {
      if (count > 0) {
        setCount((count) => count - 1);
      } else setCount(0);
    }, 1000);
    return () => clearInterval(timerId); // cleanup on unmount
  }, []);

  useEffect(() => {
    if (count <= 0) {
      setShowModal(true);
      setCount(0);
    }
  }, [count]);

  const minutes = Math.floor(count / 60);
  const seconds = count % 60;

  return (
    <div className="mx-2">
      <div className="flex w-full justify-between border border-solid border-camel rounded-xl p-4 mt-4">
        <p className="text-lg m-0 w-1/3">Your booking with {coachName}</p>
        <p className="text-lg m-0 w-1/3 mx-3 flex justify-center">At: {readbaleDateTime}</p>
        <p className="text-lg m-0 w-1/3 flex justify-end">
          Time remaining: {minutes}:{seconds < 10 ? "0" : ""}
          {seconds}
        </p>
      </div>
      {showModal && (
        <Modal onClose={() => {}} closeButton={false} size="lg">
          <div className="flex flex-col items-center">
            <img src="/images/Umbrella.svg" alt="" className="h-[300px] w-[300px]" />
            <h2 className="text-2xl text-center mt-2">Your booking slot has expired.</h2>
            <>
              <p className="text-center text-lg">
                You will need to select a new time slot in order to complete your booking.
              </p>
              <div className="flex gap-x-8 mt-4">
                <Button
                  primary
                  large
                  onClick={() => {
                    navigate(
                      `/enquiry/booking/${coachHandle}?ctx=enquiry&eid=${searchParams.get("eid")}`,
                      { replace: true },
                    );
                  }}
                >
                  Select a new time
                </Button>
              </div>
            </>
          </div>
        </Modal>
      )}
    </div>
  );
};
