export function Unhappy({ size = 75 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 76 75"
      fill="none"
    >
      <path
        d="M38 75C58.7107 75 75.5 58.2107 75.5 37.5C75.5 16.7893 58.7107 0 38 0C17.2893 0 0.5 16.7893 0.5 37.5C0.5 58.2107 17.2893 75 38 75Z"
        fill="#FF9394"
      />
      <path
        d="M29.88 28.8701C31.0619 28.8701 32.02 27.912 32.02 26.7301C32.02 25.5482 31.0619 24.5901 29.88 24.5901C28.6981 24.5901 27.74 25.5482 27.74 26.7301C27.74 27.912 28.6981 28.8701 29.88 28.8701Z"
        fill="#504F4E"
      />
      <path
        d="M44.7979 28.8352C45.9645 28.6458 46.7567 27.5466 46.5673 26.38C46.378 25.2133 45.2787 24.4211 44.1121 24.6105C42.9455 24.7999 42.1533 25.8991 42.3426 27.0657C42.532 28.2323 43.6313 29.0246 44.7979 28.8352Z"
        fill="#504F4E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4081 49.6998C19.0078 50.0419 19.7712 49.8331 20.1133 49.2335C23.6874 42.9687 30.4267 38.75 38.1499 38.75C45.873 38.75 52.6124 42.9687 56.1865 49.2335C56.5286 49.8331 57.292 50.0419 57.8916 49.6998C58.4912 49.3577 58.7 48.5943 58.3579 47.9946C54.3572 40.982 46.8069 36.25 38.1499 36.25C29.4928 36.25 21.9426 40.982 17.9418 47.9946C17.5997 48.5943 17.8085 49.3577 18.4081 49.6998Z"
        fill="#504F4E"
      />
    </svg>
  );
}
