import { Col, Layout, PageHeader, Row, Typography } from "antd";

import { PageHeaderBlock } from "$/components/PageHeaderBlock";
import { HtmlMeta } from "$/components/HtmlMeta";
import { ActionTile } from "$/components/ActionTile";
import { theme } from "$/configs/theme";
import { ROUTE_PATHS } from "$/configs/routes";
import { IPageBaseProps } from "$/interfaces";
import React from "react";
import { useUser } from "$/state/user";

interface IProps extends IPageBaseProps {}

export const Browse: React.FC<IProps> = () => {
  const user = useUser();

  const SESSION_NAVIGATION_ITEMS = [
    {
      id: 1,
      title: "Work",
      description: `Ups, downs and WTFs? Explore whatever’s limiting your progress so you can perform at your best.`,
      to: "/coaches?categories=work",
      color: theme.colors.blue,
    },
    {
      id: 2,
      title: "Self",
      description: `Find renewed confidence and purpose. We build on what already works, dig in to what doesn’t. So you can lift the fog and break free.`,
      to: "/coaches?categories=self",
      color: theme.colors.yellow,
    },
    {
      id: 3,
      title: "Relationships",
      description: `We need human connections more than ever. Let’s make yours stronger and healthier.`,
      to: "/coaches?categories=relationships",
      color: theme.colors.red,
    },
    {
      id: 4,
      title: "Purpose",
      description: `A life with meaning is rich and rewarding. We can help you clarify what matters to you and how to make a difference. Say hello to life on your terms.`,
      to: "/coaches?categories=purpose",
      color: theme.colors.lightGreen,
    },
    {
      id: 5,
      title: "Parenting",
      description: `Always juggling? We get it. While you’re supporting everyone else, a coach will support you. Helping you keep those balls safely in the air.`,
      to: "/coaches?categories=parenting",
      color: theme.colors.darkGreen,
    },
    {
      id: 6,
      title: "Find a Coach",
      description: `Search for a coach you already know, or one that has been recommended and see their availability.`,
      to: ROUTE_PATHS.SESSIONS.COACHES,
      color: theme.colors.pink,
    },
    {
      id: 7,
      title: "Next Available",
      description: `Not sure what to talk about, or need to chat urgently? Click here to book purely by the date and time that suits you.`,
      to: ROUTE_PATHS.SESSIONS.NEXT_AVAILABLE,
      ctaSecondary: true,
    },
    user?.currentUser?.coach
      ? {
          id: 8,
          title: "Answer some questions",
          description: `Try our chat based experience to explore what More Happi offers and find the right coach for you.`,
          to: ROUTE_PATHS.ONBOARDING,
          color: theme.colors.orange,
        }
      : null,
  ];

  return (
    <Layout>
      <HtmlMeta title="Book a session" />

      <Layout.Content className="site-layout-content">
        <PageHeader ghost className="site-page-header" title="Book a session">
          <PageHeaderBlock
            image={{
              src: "/images/Book_a_session.svg",
              alt: "Book a session",
            }}
          >
            <Typography.Paragraph>
              Find your coach by choosing from one of the categories below.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Don’t worry if you’re not sure which to pick, your 45-minute conversation can lead
              anywhere (all the best chats do right?) There’s no topic that’s off limits and every
              session is 100% confidential.
            </Typography.Paragraph>
          </PageHeaderBlock>
        </PageHeader>

        <Row className="mh-row-top-md" gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}>
          {SESSION_NAVIGATION_ITEMS.filter(Boolean).map((item) => (
            <Col
              key={item.id}
              className="mh-vertical-spacer"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
            >
              <ActionTile {...item} />
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
};
