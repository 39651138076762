export function Ecstatic({ size = 75 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 75C58.7107 75 75.5 58.2107 75.5 37.5C75.5 16.7893 58.7107 0 38 0C17.2893 0 0.5 16.7893 0.5 37.5C0.5 58.2107 17.2893 75 38 75Z"
        fill="#F7C421"
      />
      <path
        d="M29.88 28.8701C31.0619 28.8701 32.02 27.912 32.02 26.7301C32.02 25.5482 31.0619 24.5901 29.88 24.5901C28.6981 24.5901 27.74 25.5482 27.74 26.7301C27.74 27.912 28.6981 28.8701 29.88 28.8701Z"
        fill="#504F4E"
      />
      <path
        d="M44.7979 28.8352C45.9645 28.6458 46.7567 27.5466 46.5673 26.38C46.378 25.2133 45.2787 24.4211 44.1121 24.6105C42.9455 24.7999 42.1533 25.8991 42.3426 27.0657C42.532 28.2323 43.6313 29.0246 44.7979 28.8352Z"
        fill="#504F4E"
      />
      <path
        d="M60.13 36.25C59.49 36.25 58.97 36.77 58.97 37.41C58.97 48.89 49.63 58.23 38.15 58.23C26.67 58.23 17.33 48.89 17.33 37.41C17.33 36.77 16.81 36.25 16.17 36.25C15.53 36.25 15.01 36.77 15.01 37.41C15.01 50.17 25.39 60.55 38.15 60.55C50.91 60.55 61.29 50.17 61.29 37.41C61.29 36.77 60.77 36.25 60.13 36.25Z"
        fill="#504F4E"
      />
    </svg>
  );
}
