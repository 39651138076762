import { useUser } from "$/state/user";
import { Modal } from "$/components/Modal";
import { Button } from "$/components/Button";
import { Input } from "$/components/Input";
import { Select } from "$/components/Select";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TimezoneSelect } from "$/components/Select/Selectors/Timezone";
import { DateTime } from "luxon";

const levelOptions = [
  { value: "intern", label: "Intern" },
  { value: "entry", label: "Entry level" },
  { value: "associate", label: "Associate" },
  { value: "senior", label: "Mid - Senior level" },
  { value: "director", label: "Director/Team lead" },
  { value: "executive", label: "Executive" },
];

const coachingOptions = [
  { value: "new", label: "I am new to coaching" },
  { value: "entry", label: "I have had a few coaching sessions before" },
  { value: "veteran", label: "I am a coaching veteran" },
  { value: "coach", label: "I am a trained coach" },
];

const schema = z.object({
  phone: z
    .string()
    .min(10, "Please provide a valid phone number")
    .max(15, "Please provide a valid phone number"),
  jobLevel: z.object(
    { value: z.string().min(1, "Please select a job level"), label: z.string() },
    { invalid_type_error: "Please select a job level" },
  ),
  coachingExposure: z.object(
    { value: z.string().min(1, "Please select your exposure to coaching"), label: z.string() },
    { invalid_type_error: "Please select your exposure to coaching" },
  ),
});

export function UserDetailsModal({ onClose }) {
  const { update } = useUser();
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      phone: "",
      jobLevel: null,
      coachingExposure: null,
    },
  });

  return (
    <Modal size="lg" onClose={() => null} closeButton={false}>
      <div className="flex flex-col items-center md:w-[80%] mx-auto gap-y-2 text-center">
        <h1 className="text-2xl md:text-4xl font-semibold">We need a few details from you</h1>
        <Input
          label="Phone number"
          name="phone"
          register={register}
          required
          type="tel"
          popover="This will only be used if our team needs to contact you in a time-senstive manner (e.g. your coach is having technical problems)"
          error={errors.phone}
        />

        <Controller
          name="jobLevel"
          control={control}
          render={({ field }) => (
            <Select
              label="Job level"
              required
              options={levelOptions}
              {...field}
              error={errors.jobLevel}
            />
          )}
        />
        <Controller
          name="coachingExposure"
          control={control}
          render={({ field }) => (
            <Select
              label="Exposure to coaching"
              required
              options={coachingOptions}
              {...field}
              error={errors.jobLevel}
            />
          )}
        />
        {/* <Controller
          name="timezone"
          control={control}
          render={({ field }) => <TimezoneSelect {...field} error={errors.timezone} />}
        /> */}

        <div className="flex flex-col-reverse gap-4 md:flex-row w-full items-center justify-center">
          <Button
            primary
            large
            isSubmitting={isSubmitting}
            onClick={async (e) => {
              handleSubmit(async (values: z.infer<typeof schema>) => {
                await update({
                  phone: values.phone,
                  jobLevel: values.jobLevel.value,
                  coachingExposure: values.coachingExposure.value,
                });
                onClose();
              })(e);
            }}
            className="w-full mt-6"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
