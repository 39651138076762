import cn from "classnames";
import { useState } from "react";
import { Button } from "../Button";

interface TextAreaProps extends React.ComponentPropsWithoutRef<"textarea"> {
  limit?: number;
}

export function TextArea({ className = "", limit, onChange, ...props }: TextAreaProps) {
  return (
    <>
      <textarea
        className={cn(
          "input h-[120px] w-full resize-none  focus:outline-none scrollbar-hideborder-yellow border border-solid rounded-3xl px-3 py-2",
          className,
        )}
        name="textBody"
        {...props}
        onChange={(event) => (limit && event.target.value?.length > limit ? null : onChange(event))}
        disabled={props.disabled}
      />
      <div className="flex justify-end">
        {limit && (
          <div>
            {props?.value?.length}/{limit}
          </div>
        )}
      </div>
    </>
  );
}

interface TextAreaWithButtonProps extends TextAreaProps {
  buttonText?: string;
  handleClick: (value: string) => void;
}

export function TextAreaWithButton({
  buttonText = "Save",
  handleClick,
  ...props
}: TextAreaWithButtonProps) {
  const [text, setText] = useState(props.value ?? "");
  return (
    <div className="flex flex-col items-end gap-y-1 border-yellow border border-solid rounded-3xl p-2 ">
      <TextArea
        onChange={(event) => setText(event.target.value)}
        className="text-lg px-4 pt-2 !border-0"
        {...props}
      />
      <Button primary large onClick={() => handleClick(text)}>
        {buttonText}
      </Button>
    </div>
  );
}
