import { Col, Layout, PageHeader, Row, Typography } from "antd";

import { HtmlMeta } from "$/components/HtmlMeta";
import { PageHeaderBlock } from "$/components/PageHeaderBlock";
import { ActionTile } from "$/components/ActionTile";
import { theme } from "$/configs/theme";
import { IPageBaseProps } from "$/interfaces";
import { useUser } from "$/state/user";
import { UserRole } from "$/graphql/types.generated";

interface IProps extends IPageBaseProps {}

export const Tools: React.FC<IProps> = () => {
  const user = useUser();

  const SESSION_NAVIGATION_ITEMS = [
    {
      id: 1,
      title: "Everyday tools & preparing for your session",
      href: "https://midnight-revolve-64c.notion.site/Everyday-tools-f07e99c2f7e84a5c98409c32c90255f0?pvs=4",
      color: theme.colors.yellow,
      navTitle: "Tools",
      isNew: true,
    },
    {
      id: 2,
      title: "For new starters",
      href: "https://midnight-revolve-64c.notion.site/For-new-starters-d5814733af5b48bbb002a125ebebed5e?pvs=4",
      color: theme.colors.darkGreen,
      navTitle: "Tools",
    },
    {
      id: 3,
      title: "For progression planning & reviews",
      href: "https://midnight-revolve-64c.notion.site/For-appraisals-reviews-75e012e153a844cf90f7b8f6b5d6616a?pvs=4",
      color: theme.colors.blue,
      navTitle: "Tools",
    },
    {
      id: 4,
      title: "For promotions & first-time managers",
      href: "https://midnight-revolve-64c.notion.site/For-promotions-first-time-managers-1f26dc0c0d8241a0b1500fba60bbcd91?pvs=4",
      color: theme.colors.pink,
      navTitle: "Tools",
    },
    {
      id: 5,
      title: "For expecting & parenting",
      href: "https://midnight-revolve-64c.notion.site/For-expecting-parenting-7cd568a4cb7f40a790f140d41de928ea?pvs=4",
      color: theme.colors.orange,
      navTitle: "Tools",
    },
    {
      id: 6,
      title: "For leavers & redundancy",
      href: "https://midnight-revolve-64c.notion.site/For-leavers-redundancy-58785d868bbe41c5800a10fab46dfc26?pvs=4",
      color: theme.colors.skin,
      navTitle: "Tools",
    },
    {
      id: 7,
      title: "The School of More Happi",
      cardColor: "yellow",
      href: "https://morehappi.com/school-of-more-happi/",
      hrefTarget: "_self",
      navTitle: "Training",
    },
    {
      id: 6,
      title: "Promoting yourself as a More Happi organisation",
      href: "https://midnight-revolve-64c.notion.site/Promoting-yourself-as-a-More-Happi-organisation-c4472001a0f9440b8f86787c04040e3b?pvs=4",
      color: theme.colors.lightGreen,
      navTitle: "Sponsor Tools",
      isNew: true,
      role: [UserRole.Sponsor, UserRole.Admin, UserRole.Superadmin],
    },
  ];

  const activeItems = SESSION_NAVIGATION_ITEMS.filter((item) =>
    item.role ? item.role.includes(user?.currentUser?.role!) : true,
  );

  return (
    <Layout>
      <HtmlMeta title="More Happi Tools" />

      <Layout.Content className="site-layout-content">
        <PageHeader title="More Happi Tools" ghost className="site-page-header">
          <PageHeaderBlock
            image={{
              src: "/images/Tools.svg",
              alt: "Book a session",
            }}
          >
            <Typography.Paragraph>
              We&apos;ve created a list of tools that will help you handle important moments of your
              work and personal life. You can use them to help you think about your goals
              differently, and get more out of your coaching sessions.
            </Typography.Paragraph>
          </PageHeaderBlock>
        </PageHeader>

        <Row className="mh-row-top-md" gutter={{ xs: 4, sm: 8, md: 16, lg: 24 }}>
          {activeItems.map((item: any) => (
            <Col
              key={item.id}
              className="mh-vertical-spacer"
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
            >
              <ActionTile
                {...item}
                navTag={item.isNew ? { tag: "New tools", color: item.color } : null}
                textInheritColor={false}
              />
            </Col>
          ))}
        </Row>
      </Layout.Content>
    </Layout>
  );
};
