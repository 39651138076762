export const testMembers = [
  "e3802a9d-f32f-4eaf-8e07-36ab4b5f974c",
  "19c47d57-ba42-4b63-87bf-1b906ce7fed6",
  "e6fe866f-44d4-48eb-9ad8-7825b4507c69",
  "b93089f1-f5af-478d-b6c1-a0e6fe7a43d5",
  "cda949a3-1dcd-4374-a88c-386a5b532e94",
  "45381bb5-6693-4177-b40d-c88b6850c287",
  "4aed60c7-1fd3-4aec-9685-f60e91471a7a",
  "197cd68a-8b97-4936-aa64-85bc718ece5e",
];

// * Liv Blaney
// * Sian Carlisle
// * Jo Gibney
// * Tim Duddridge
// * Andrew Cooke
// * Lina Lo
// * Anise Frost
// * Gandalf
