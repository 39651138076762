import { useNavigate } from "react-router";
import { CalendarOutlined } from "@ant-design/icons";
import { decode } from "html-entities";
import { addDays, isBefore } from "date-fns";
import { Col, Row } from "antd";
import { CoachAvatar } from "../CoachAvatar";
import { CoachBio } from "../CoachBio";
import styles from "./styles.module.less";
import { SearchCoachesQuery } from "$/graphql/types.generated";
import React, { FC } from "react";
import CoachTestimonials from "./CoachTestimonials";
import { useSearchParams } from "react-router-dom";
import { Button } from "../Button";

type Flatten<Type> = Type extends Array<infer Item> ? Item : Type;

interface Props {
  coach: Flatten<SearchCoachesQuery["searchCoaches"]>;
  bookUrl?: string;
  bookText?: string;
}

export const LabelList: FC<{ header: string; list: { label: string }[] }> = ({ header, list }) => {
  return (
    <div>
      <h5 className={styles["coach-tag-header"]}>{header}</h5>
      <div className={styles["coach-tag-container"]}>
        {list.map(({ label }) => (
          <span className={styles["coach-tag"]} key={label}>
            {label}
          </span>
        ))}
      </div>
    </div>
  );
};

export const Buttons = ({
  buttons,
}: {
  buttons: Array<{ icon: React.ReactNode; text: string; onClick: () => void; hide?: boolean }>;
}) => (
  <div className="flex flex-col gap-2">
    {buttons
      .filter((button) => !button.hide)
      .map(({ text, ...rest }, index) => (
        <Button key={`coach-card-buttons-${index}`} {...rest} className="w-full">
          {text}
        </Button>
      ))}
  </div>
);

export const CoachCard = ({ coach, buttons }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <div>
      <Row className={styles["coach-card-container"]}>
        <Col xs={24} sm={8} md={6} lg={5} className={styles["coach-image-container"]}>
          <div className={styles.block}>
            <CoachAvatar imageKey={`${coach!.id}/thumbnail.jpg`} displayName={coach!.displayName} />
            <div className={styles["coach-cta"]}>
              <Buttons buttons={buttons} />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={16} md={18} lg={19}>
          <div className={"p-3"}>
            <div className="w-full flex flex-col md:flex-row justify-start md:justify-between md:items-center">
              <h1 className="text-2xl font-semibold mb-0 whitespace-nowrap">{coach.displayName}</h1>
              <CoachTestimonials coach={coach} />
            </div>{" "}
            <h3>{decode(coach!.headline)}</h3>
            <div className={styles["coach-bio"]}>
              <CoachBio bio={coach!.bio as string} />
            </div>
            {coach?.trainingSchool && <h4>Trained at {coach.trainingSchool}</h4>}
            <div className={styles["coach-tags-container"]}>
              <LabelList header="Approach" list={coach!.approaches!} />
              <LabelList header="Specialisms" list={coach!.specialisms!} />
            </div>
            <div className={styles["coach-cta-mobile"]}>
              <Buttons buttons={buttons} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
