export function Email({ size = 18 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size + size / 18}
      height={size + size / 18}
      viewBox="0 0 19 19"
      fill="none"
    >
      <g clipPath="url(#clip0_11_581)">
        <path d="M1.1875 3.875H18.0625V15.125H1.1875V3.875Z" fill="#3A5276" />
        <path d="M18.0625 15.0312L9.625 6.875L1.1875 15.0313L18.0625 15.0312Z" fill="#1E1E1E" />
        <path d="M1.1875 3.875L9.625 12.0312L18.0625 3.875H1.1875Z" fill="#769BB9" />
      </g>
      <defs>
        <clipPath id="clip0_11_581">
          <rect width={size} height={size} fill="white" transform="translate(0.625 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
