import { Link } from "react-router-dom";
import { Col, Row, Typography } from "antd";

import { useCompany } from "$/state/company";
import { useCompanySubscription } from "$/state/company-subscription";
import { ManageSubscriptionButton } from "$/components/ManageSubscriptionButton";
import { UpgradeSubscriptionButton } from "$/components/UpgradeSubscriptionButton";
import { IPageBaseProps } from "$/interfaces";
import { PackageManagement } from "./PackageManagement";

interface IProps extends IPageBaseProps {}

const { Title, Text } = Typography;

const contactOptions = (
  <>
    <Title level={4}>Need to discuss your membership?</Title>
    <Text>
      If you want to cancel or take a break, you can let us know in the chat on the bottom right
      hand side of the screen or email us at{" "}
      <Link to="mailto:hey@morehappi.com">hey@morehappi.com</Link>
    </Text>
  </>
);

const ManageSubscription = () => {
  const { currentCompany } = useCompany();
  if (!currentCompany) {
    return null;
  }
  const companySubscription = useCompanySubscription();

  const { isActive, isCancelled, status } = companySubscription.state;

  const isEnterprise = currentCompany?.isEnterprise;

  const neverSubscribed = !isActive && !isCancelled;
  const isActiveNotCancelled = isActive && !isCancelled;

  if (isEnterprise) {
    return (
      <div>
        <h1>You are a More Happi enterprise customer</h1>
        <p>
          Your subscription is paid for by your company on a contract basis. See information below
          if you would like to discuss your membership.
        </p>
      </div>
    );
  }

  if (neverSubscribed) {
    return (
      <div>
        <h1>You don&apos;t have a subscription yet!</h1>
        <p>
          You need an active subscription to book coaching sessions. <UpgradeSubscriptionButton />
        </p>
      </div>
    );
  }

  if (isActiveNotCancelled) {
    return (
      <div>
        <div>
          <h2>Manage Billing Information</h2>
          <p className="mt-xl">
            Your subscription is currently{" "}
            <strong>
              {companySubscription?.state?.status === "null"
                ? "active"
                : companySubscription?.state?.status}
            </strong>
            . Update your payment method, reactivate or cancel your subscription by clicking
            &quot;manage subscription&quot;.
          </p>
        </div>
        <div>
          <ManageSubscriptionButton />
        </div>
      </div>
    );
  }

  if (isCancelled) {
    return (
      <Row gutter={24} wrap={false} align="middle">
        <Col>
          <Title level={5}>Manage Billing Information</Title>
          <Text className="mt-xl">
            Your subscription has been cancelled and your payment method will no longer be charged.
            You will not be able to access More Happi coaching after your billing cycle ends on{" "}
            {companySubscription.state?.cancellingAt}
          </Text>
        </Col>
        <Col>
          <ManageSubscriptionButton text="Resume Subscription" />
        </Col>
      </Row>
    );
  }
};

export const CompanySubscriptionSettings = (props: IProps) => {
  const companySubscription = useCompanySubscription();

  return (
    <>
      {(companySubscription.state.subscriptionCustomer ||
        companySubscription.state.status === "expired") && (
        <>
          <ManageSubscription />

          <hr className="my-4" />
        </>
      )}

      {companySubscription.state.packageCustomer && (
        <>
          <PackageManagement />
          <hr className="my-4" />
        </>
      )}
      {contactOptions}
    </>
  );
};
