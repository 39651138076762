import cn from "classnames";
import type { ReactNode } from "react";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { X } from "lucide-react";

function createPortalRoot() {
  const drawerRoot = document.createElement("div");
  drawerRoot.setAttribute("id", "modal-root");

  return drawerRoot;
}

type Size = "sm" | "md" | "lg" | "xl";

export interface ModalProps {
  header?: string | ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  size?: Size;
  closeButton?: boolean;
  onClose: () => void;
  dataTestId?: string;
}

const sizes = (size: Size) => ({
  "w-[320px]": size === "sm",
  "w-[500px]": size === "md",
  "w-[750px]": size === "lg",
  "w-[1000px]": size === "xl",
});

export function Modal({
  onClose,
  header,
  children,
  footer,
  size = "md",
  closeButton = true,
  dataTestId = "",
}: ModalProps) {
  const portalRootRef = useRef(document.getElementById("modal-root") || createPortalRoot());
  const node = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (node.current === event.target) {
      event.stopPropagation();
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    document.body.style.overflow = "hidden";
    document.body.appendChild(portalRootRef.current);
    return () => {
      portalRootRef.current.remove();
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, []);

  return createPortal(
    <>
      <div
        className="fixed inset-0 z-[1300] flex h-[100vh] max-h-[100vh] w-[100vw] justify-center overflow-y-scroll overscroll-none"
        onClick={onClose}
        ref={node}
        data-testid={dataTestId}
      >
        <div
          className="z-[1500] my-auto flex h-fit w-fit max-w-[90%] outline-none focus:outline-none"
          onClick={(e) => e.stopPropagation()}
        >
          <div className={cn("overflow-y-inherit relative my-6 mx-auto", sizes(size))}>
            <div className="relative flex w-full flex-col rounded-3xl border-0 bg-white shadow-lg outline-none focus:outline-none">
              {header && (
                <div className="border-blueGray-200 flex items-center justify-between rounded-t border-b border-solid p-5">
                  <h3 className="pr-10 text-3xl font-semibold">{header}</h3>
                </div>
              )}
              {closeButton && (
                <div className="absolute top-2 right-2 z-10">
                  <div
                    className="h-[40px] rounded-lg cursor-pointer"
                    onClick={onClose}
                    data-testid="modal-close-button"
                  >
                    <X />
                  </div>
                </div>
              )}
              <div className="overflow-y-inherit relative flex-auto p-6 scrollbar-hide">
                {children}
              </div>
              {footer && (
                <div className="border-blueGray-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                  {footer}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-[1000] bg-black opacity-25" onClick={onClose} />
    </>,
    portalRootRef.current,
  );
}
