import { Unhappy } from "./Unhappy";
import { Sad } from "./Sad";
import { Happy } from "./Happy";
import { Medium } from "./Medium";
import { Ecstatic } from "./Ecstatic";

export const Smileys = {
  Unhappy,
  Sad,
  Medium,
  Happy,
  Ecstatic,
};
