import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout, PageHeader } from "antd";

import { HtmlMeta } from "$/components/HtmlMeta";
import { CompanyReport } from "$/components/Report";
import { useRoleRedirect, useScrollTo } from "$/hooks";
import { UserRole } from "$/graphql/types.generated";
import { IPageBaseProps } from "$/interfaces";
import { ROUTE_PATHS } from "$/configs/routes";
import { BackIcon } from "$/icons";

interface IProps extends IPageBaseProps {}

export const CompanyData: React.FC<IProps> = () => {
  const navigate = useNavigate();
  useScrollTo();

  useRoleRedirect([UserRole.Admin, UserRole.Sponsor, UserRole.Individual, UserRole.Superadmin]);

  return (
    <Layout>
      <HtmlMeta title="Company Data" />
      <Layout.Content className="site-layout-content thin">
        <PageHeader
          ghost
          className="site-page-header"
          title="Company Data"
          onBack={() => navigate(ROUTE_PATHS.MY_ACCOUNT)}
          backIcon={<BackIcon style={{ fontSize: "40px" }} />}
        />
        <CompanyReport />
      </Layout.Content>
    </Layout>
  );
};
