export const ROUTE_PATHS = {
  LOGIN: "/auth/login",
  SIGNOUT: "/auth/signout",
  REGISTER: "/auth/register",
  REGISTER_INDIVIDUAL: "/auth/register/individual",

  VERIFY: "/auth/verify",
  RESEND_REGISTRATION_LINK: "/auth/resend-registration",
  RESET_PASSWORD: "/auth/reset-password",
  CHANGE_PASSWORD: "/auth/change-password",
  CONFIRM_RESET_PASSWORD: "/auth/confirm-password-reset",
  // Protected Routes
  DASHBOARD: "/",
  ONBOARDING_VIDEO: "/onboarding",
  ONBOARDING_VIDEOS: "/101",
  ENQUIRY: {
    MAIN: "/enquiry",
    RESULTS: "/enquiry/results",
    BOOKING: "/enquiry/booking/:coachHandle",
    ACCOUNT: "/enquiry/account",
  },
  ADD_PACKAGE: "/add-package",
  SUPPORT_GUIDES: {
    MAIN: "/support-guides",
    SPONSOR_CHECKLIST: {
      MAIN: "/support-guides/checklist",
      ONE: "/support-guides/checklist/step-one",
      TWO: "/support-guides/checklist/step-two",
      THREE: "/support-guides/checklist/step-three",
      FOUR: "/support-guides/checklist/step-four",
      FIVE: "/support-guides/checklist/step-five",
      SIX: "/support-guides/checklist/step-six",
    },
    WHAT_IS_COACHING: "/support-guides/what-is-coaching",
    HOW_TO_USE: "/support-guides/how-to-use",
    ONBOARDING_CALL: {
      LEADERS: "/support-guides/onboarding-call/leaders",
      LEADERS_CONFIRMED: "/support-guides/onboarding-call/leaders/confirmed",
      TEAM_MEMBERS: "/support-guides/onboarding-call/team-members",
      TEAM_MEMBERS_CONFIRMED: "/support-guides/onboarding-call/team-members/confirmed",
    },
  },
  TOOLS: {
    MAIN: "/tools",
    HABIT_TRACKER: "/tools/habit-tracker",
    GOAL_PLANNER: "/tools/goal-planner",
  },
  MY_ACCOUNT: "/my-account",
  CONTACT_US: "/contact",
  CHECKOUT: {
    MAIN: "/checkout",
  },
  SESSIONS: {
    MAIN: "/sessions",
    CANCEL: "/sessions/:sessionId/cancel",
    CANCEL_CONFIRM: "/sessions/:sessionId/cancel/confirm",
    CANCEL_COACH: "/sessions/:sessionId/cancel/coach",
    CANCEL_COACH_CONFIRM: "/sessions/:sessionId/cancel/coach/confirm",
    NO_SHOW: "/sessions/:sessionId/no-show",
    NO_SHOW_CONFIRM: "/sessions/:sessionId/no-show/confirm",
    COACHES: "/coaches",
    COACHES_BY_TIME: "/coaches/availability",
    COACH: "/coach/:coachHandle",
    CONFIRM_BOOKING: "/coach/:coachHandle/confirm",
    BROWSE: "/book-session",
    NEXT_AVAILABLE: "/next-available-coach",
    CATEGORY: "/coaches/:category",
    WHERE_TO_START: "/where-to-start",
    BOOK_NEXT: "/book-next",
    BOOKING_SUCCESS: "/booking-confirmed",
  },
  USER: {
    SETTINGS: "/account",
  },
  COMPANY: {
    BILLING: "/company/billing",
    SETTINGS: "/company/settings",
    MANAGE_TEAM: "/company/manage-team",
    DATA: "/company/data",
  },
  ONBOARDING: "/onboarding",
};
