import React, { useEffect, useState } from "react";
import { useScrollTo } from "$/hooks";
import { CompanyFeaturePermissions, useCompany } from "$/state/company";
import { usePermissionRedirect } from "$/hooks/usePermissionRedirect";
import { useUser } from "$/state/user";
import { SchedulerProvider, useScheduler } from "../Scheduler/schedulerContext";
import {
  AccountType,
  useAllCoachAvailabilityQuery,
  useListCoachPackagesQuery,
} from "$/graphql/types.generated";
import { DateTime } from "luxon";
import { Scheduler } from "../Scheduler";
import { SchedulerTimes } from "../Scheduler/SchedulerTimes";
import { useNavigate } from "react-router";
import { HtmlMeta } from "$/components/HtmlMeta";
import { Button } from "$/components/Button";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { Mixpanel } from "$/tracking";
import {
  coachPackagesForCompanyType,
  orderCoachPackages,
} from "../Coach/helpers/coachPackagesForCompany";

const ScheduleContainer = () => {
  const user = useUser();
  const company = useCompany();
  const navigate = useNavigate();
  const { setEvents, month } = useScheduler();
  const [formStage, setFormStage] = useState(0);
  const [dateTime, setDateTime] = useState<{ date: DateTime; time: string }>();

  const { data, isLoading } = useAllCoachAvailabilityQuery({
    start: month,
    end: month.endOf("month"),
  });

  useEffect(() => {
    if (!isLoading && data?.allCoachAvailability?.length) {
      setEvents((data?.allCoachAvailability ?? []) as Array<CoachEvent>);
    }
  }, [isLoading]);

  const coachPackages = useListCoachPackagesQuery({
    where: {
      id: {
        in: coachPackagesForCompanyType(
          company?.currentCompany?.accountType ?? AccountType.Individual,
        ),
      },
    },
  });

  return (
    <div>
      {formStage === 0 && (
        <Scheduler showTimeScheduler={() => setFormStage(1)} isLoading={isLoading} />
      )}
      {formStage === 1 && (
        <SchedulerTimes
          hideTimeScheduler={() => setFormStage(0)}
          selectTime={async (date, time) => {
            setDateTime({ date, time });
            setFormStage(2);
          }}
        />
      )}
      {formStage === 2 && (
        <div>
          <h1>What do you want to speak to a coach about? </h1>
          {coachPackages?.data?.listCoachPackages?.length > 0 && (
            <div className="flex flex-col gap-y-2">
              {orderCoachPackages(
                coachPackages?.data?.listCoachPackages,
                company.currentCompany.accountType,
              )?.map((coachPackage) => (
                <Button
                  tertiary
                  large
                  onClick={() => {
                    Mixpanel.track("ByAvailability: Coach Package Selected", {
                      label: coachPackage.label,
                    });
                    setTimeout(() => {
                      navigate(
                        `/coaches?date=${dateTime.date.toFormat("yyyyMMdd")}&time=${dateTime.time}`,
                      );
                    }, 5000);
                    setFormStage(3);
                  }}
                >
                  {coachPackage.label}
                </Button>
              ))}
            </div>
          )}
        </div>
      )}
      {formStage === 3 && (
        <div className="flex flex-col w-full h-[70vh] items-center justify-center">
          <h1 className="text-4xl mb-10">Finding the best coaches for you</h1>
          <LoadingSpinner className="!h-12 !w-12" />
        </div>
      )}
    </div>
  );
};

export const CoachesByAvailability: React.FC<IProps> = () => {
  useScrollTo();
  usePermissionRedirect(CompanyFeaturePermissions.coachDirectory);

  return (
    <div className="my-10 w-screen">
      <div className="mx-auto">
        <HtmlMeta title="Coaches by Availability" />
        <div className="flex flex-col justify-between items-center  w-full px-2 xl:px-0 xl:w-[1000px] mx-auto">
          <div className="text-center">
            <h1 className="text-4xl">Find the best coach for you</h1>
            <p className="text-xl">
              Select the date and time you would like your session along with the topic you want to
              speak about and we will show you the best available coaches.
            </p>
          </div>
          <div className="bg-white rounded-lg w-full p-4">
            <SchedulerProvider>
              <ScheduleContainer />
            </SchedulerProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
