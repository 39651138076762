import { createMachine, assign } from "xstate";

interface Context {
  retries: number;
}

export const onboardingMachine = createMachine<Context>({
  id: "fetch",
  initial: "intro",
  predictableActionArguments: true,
  states: {
    intro: {
      on: {
        NEXT: "doYouKnowHowCoachingWorks",
      },
    },
    doYouKnowHowCoachingWorks: {
      on: {
        YES: "whatDoYouWantHelpWith",
        SOMEWHAT: "coachingExplanation",
        NO: "coachingExplanation",
      },
    },
    coachingExplanation: {
      on: {
        NEXT: "whatDoYouWantHelpWith",
      },
    },
    whatDoYouWantHelpWith: {
      on: {
        NEXT: "plan",
      },
    },
    plan: {
      on: {
        CONTEXT_FOR_COACH: "contextForCoach",
        TELL_ME_MORE: "tellMeMore",
        ONE_SESSION: "oneSession",
        TIMEFRAME: "timeframe",
      },
    },
    tellMeMore: {
      on: {
        CONTEXT_FOR_COACH: "contextForCoach",
        ONE_SESSION: "oneSession",
        TIMEFRAME: "timeframe",
      },
    },
    oneSession: {
      on: {
        CONTEXT_FOR_COACH: "contextForCoach",
        TELL_ME_MORE: "tellMeMore",
        TIMEFRAME: "timeframe",
      },
    },
    contextForCoach: {
      on: {
        NEXT: "timeframe",
      },
    },
    timeframe: {
      on: {
        NEXT: "finish",
      },
    },
    finish: {
      type: "final",
    },
  },
});
