import { Button } from "$/components/Button";
import { isFunction } from "lodash";
import cn from "classnames";
import { ProgrammeDetails } from "../helpers/storyblok";
import { Modal } from "$/components/Modal";
import { useState } from "react";

export const Programme = ({
  title,
  tags,
  price,
  body,
  priceTag,
  buttonText,
  onClick = () => {},
  className,
  showPrice = true,
  small = false,
  limitWeeks,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={cn("bg-skin xl:bg-white rounded-xl flex flex-col justify-between", className)}>
      <div>
        <div className="flex items-center justify-center gap-x-2">
          {tags.map((tag) => (
            <div
              style={{ background: tag.color }}
              className="rounded-b-xl py-[6px] px-[10px]"
              key={tag.text}
            >
              {tag.text}
            </div>
          ))}
        </div>

        <div className="p-6 flex flex-col">
          <h1 className="text-center text-4xl font-semibold">{title}</h1>
          {showPrice && (
            <div className="flex items-center justify-center mb-4 gap-2">
              <h1 className="text-center text-4xl font-semibold mb-0">{price}</h1>
              {priceTag && <div className="bg-beige mb-0 px-1 rounded-md">{priceTag}</div>}
            </div>
          )}
          {small ? (
            <div
              className="text-center underline cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              Read more...
            </div>
          ) : (
            <>
              {body}
              {limitWeeks && (
                <p>
                  This programme lasts {limitWeeks} weeks. You must use all your sessions within
                  this timeframe.
                </p>
              )}
            </>
          )}
        </div>
      </div>
      {buttonText && (
        <Button primary className="mb-6 w-[80%] mx-auto" onClick={onClick}>
          {buttonText}
        </Button>
      )}
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div>
            {body}
            {limitWeeks && (
              <p>
                This programme lasts {limitWeeks} weeks. You must use all your sessions within this
                timeframe.
              </p>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export const getPackageBody = (data: ProgrammeDetails, plan: "explore" | "build" | "progress") => {
  return (
    <div>
      <p>
        <strong>
          1 x <span className="bg-[#9DC3F0]">Explore</span> session
        </strong>{" "}
        {data.exploreText}
      </p>
      {(plan === "build" || plan === "progress") && (
        <p>
          <strong>
            2 x <span className="bg-[#FCB0D0]">Build</span> sessions
          </strong>{" "}
          {data.buildText}
        </p>
      )}
      {plan === "progress" && (
        <p>
          <strong>
            3 x <span className="bg-[#9AD6A0]">Progress</span> sessions
          </strong>{" "}
          {data.progressText}
        </p>
      )}

      <p className="whitespace-break-spaces">{data.genericText}</p>
    </div>
  );
};

interface ExtendedPackageProps extends React.HTMLAttributes<HTMLDivElement> {
  data: ProgrammeDetails;
  onClick?: () => void;
  showPrice?: boolean;
}

export const ExplorePackage = ({ onClick, data, ...rest }: ExtendedPackageProps) => (
  <Programme
    title="1 session"
    tags={[{ text: "Explore", color: "#9DC3F0" }]}
    price="£65"
    body={getPackageBody(data, "explore")}
    buttonText={isFunction(onClick) && "Choose 1 session"}
    onClick={onClick}
    {...rest}
  />
);

export const BuildPackage = ({ onClick, data, ...rest }: ExtendedPackageProps) => (
  <Programme
    title="3 sessions"
    tags={[
      { text: "Explore", color: "#9DC3F0" },
      { text: "Build", color: "#FCB0D0" },
    ]}
    price="£135"
    priceTag="31% off"
    body={getPackageBody(data, "build")}
    buttonText={isFunction(onClick) && "Choose 3 sessions"}
    onClick={onClick}
    limitWeeks={12}
    {...rest}
  />
);

export const ProgressPackage = ({ onClick, data, ...rest }: ExtendedPackageProps) => (
  <Programme
    title="6 sessions"
    tags={[
      { text: "Explore", color: "#9DC3F0" },
      { text: "Build", color: "#FCB0D0" },
      { text: "Progress", color: "#9AD6A0" },
    ]}
    price="£198"
    priceTag="50% off"
    body={getPackageBody(data, "progress")}
    buttonText={isFunction(onClick) && "Choose 6 sessions"}
    onClick={onClick}
    limitWeeks={16}
    {...rest}
  />
);

export const packages = [
  {
    title: "explore",
    chargebeeId: "1-session-GBP-Monthly",
    label: "Explore package: 1 session",
    sessionCount: 1,
    expiryWeeks: 4,
  },
  {
    title: "build",
    chargebeeId: "3-sessions-GBP-Monthly",
    label: "Build package: 3 sessions",
    sessionCount: 3,
    expiryWeeks: 12,
  },
  {
    title: "progress",
    chargebeeId: "6-sessions-GBP-Monthly",
    label: "Progress package: 6 sessions",
    sessionCount: 6,
    expiryWeeks: 16,
  },
];
