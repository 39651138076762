import { Button } from "$/components/Button";
import { HtmlMeta } from "$/components/HtmlMeta";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { TextArea } from "$/components/TextArea";
import { useCancelSessionCoachMutation, useGetSessionByIdQuery } from "$/graphql/types.generated";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { useUser } from "$/state/user";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
dayjs.extend(advancedFormat);

interface IProps extends IPageBaseProps {}

export const CancelCoachPage: React.FC<IProps> = () => {
  useScrollTo();
  const user = useUser();
  const navigate = useNavigate();
  const params = useParams();
  const cancel = useCancelSessionCoachMutation();
  const [cancellationReason, setCancellationReason] = useState<string>("");

  const { data, isLoading } = useGetSessionByIdQuery({
    id: params.sessionId,
  });

  const session = data?.getSession;

  useEffect(() => {
    if (!user?.currentUser?.coach?.id) {
      navigate(`/sessions/${params.sessionId}/cancel`);
    }
    if (user?.currentUser?.id && session?.id) {
      if (user?.currentUser?.coach?.id !== session?.coachId) {
        if (user?.currentUser?.id === session?.teamMember?.user?.id) {
          navigate(`/sessions/${params.sessionId}/cancel`);
        } else {
          navigate(`/`);
        }
      }
    }
  }, [user.currentUser?.id, data?.getSession?.id]);

  return (
    <div className="mb-20 w-screen flex flex-col justify-center">
      <HtmlMeta title="Cancel session" />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex justify-center items-center w-full min-h-screen mt-8">
          <div className="w-[90%] md:w-[600px] gap-y-4 flex flex-col items-center">
            {DateTime.fromISO(session?.startsAt).diffNow("minute").minutes > 0 ? (
              <>
                {" "}
                <h1 className="text-4xl text-center">Do you want to cancel this booking?</h1>
                <h2>
                  {dayjs(session.startsAt).format("Do [of] MMMM YYYY [at] h:mma")} with{" "}
                  {session.teamMember.user.firstName} {session.teamMember.user.lastName}
                </h2>
                <p className="text-lg font-[450] text-center mb-0 mt-8">
                  We require a minimum of
                  <span className="bg-yellow px-[4px] mx-[calc(1ch-6px)]">48 hours</span>before the
                  start of the session to cancel, unless it&apos;s an emergency, otherwise it can
                  greatly affect the coachee&apos;s experience
                </p>
                <p className="text-lg  text-center">
                  Please include a reason for your cancellation, this will be shared with{" "}
                  {session.teamMember.user.firstName}.
                </p>
                <TextArea
                  placeholder="Your reason for cancelling"
                  className="w-full md:w-[600px]"
                  value={cancellationReason}
                  onChange={(event) => setCancellationReason(event.target.value)}
                />
                <Button
                  disabled={!cancellationReason.length}
                  primary
                  isSubmitting={cancel.isPending}
                  onClick={async () => {
                    await cancel.mutateAsync({
                      sessionId: session!.id,
                      reason: cancellationReason,
                    });
                    navigate(`/sessions/${session?.id}/cancel/coach/confirm`);
                  }}
                >
                  Yes, cancel my session
                </Button>
              </>
            ) : (
              <>
                {user.currentUser ? (
                  <>
                    <h1 className="text-4xl text-center mb-0">This session cannot be cancelled</h1>
                    <p className="text-lg mb-0">
                      The date for this session has already passed, so cannot be cancelled.
                    </p>

                    <a href="/">
                      <Button primary large className="w-full">
                        Go home
                      </Button>
                    </a>
                  </>
                ) : (
                  <>
                    <h1 className="text-4xl text-center mb-4">You must be logged in to cancel</h1>

                    <p className="text-lg mb-4 text-center">
                      To cancel a session, you must login to your More Happi account. If you
                      don&apos;t have an account or are unsure how to do that, please email{" "}
                      <a href="mailto:hey@morehappi.com" className="underlinefe">
                        support
                      </a>
                      .
                    </p>
                    <a href="/auth/login" className="w-full md:w-[50%]">
                      <Button primary className="w-full" large>
                        Go to login page
                      </Button>
                    </a>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
