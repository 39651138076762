import React, { useEffect } from "react";
import { HtmlMeta } from "$/components/HtmlMeta";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import { merge } from "lodash";
import {
  useSearchCoachesByAvailabilityQuery,
  useSearchCoachesQuery,
  useGetEnquiryQuery,
  OrderByArg,
  GetEnquiryQuery,
  UserRole,
} from "$/graphql/types.generated";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import CoachCarousel from "$/components/CoachCarousel";
import {
  addDays,
  differenceInHours,
  endOfDay,
  nextFriday,
  nextMonday,
  nextSaturday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday,
  setHours,
  startOfDay,
} from "date-fns";
import ResultCoachCard from "./components/ResultCoachCard";
import { ArrowRight } from "lucide-react";
import { Button } from "$/components/Button";
import { Mixpanel } from "$/tracking";
import { useUser } from "$/state/user";
import { useCompanySubscription } from "$/state/company-subscription";
import { useFeatures } from "$/state/features";
import { ProgressBar } from "./components/ProgressBar";
import { canUserAddPackage } from ".";

interface IProps extends IPageBaseProps {}

const getFilters = (enquiry: GetEnquiryQuery["getEnquiry"]) => {
  const experienceChoices = enquiry?.enquiryChoices
    ?.find((choice) => choice.key === "someoneExperiencedWith")
    ?.value?.split(",");

  const approach = [];
  const experience = [];
  if (experienceChoices?.includes("Someone-loose-and-exploratory")) {
    approach.push("loose_and_exploratory");
  }
  if (experienceChoices?.includes("Someone-firm-and-structured")) {
    approach.push("highly_structured");
    approach.push("firm");
  }
  if (experienceChoices?.includes("Someone-experienced-with-neurodiverse-people")) {
    experience.push("neurodiverse");
  }
  if (experienceChoices?.includes("Someone-experienced-with-women")) {
    experience.push("women");
  }
  if (experienceChoices?.includes("Someone-experienced-with-leaders")) {
    experience.push("leaders");
  }
  if (experienceChoices?.includes("Someone-experienced-with-commercial-employees")) {
    experience.push("comercial_employees");
  }
  if (experienceChoices?.includes("Someone-experienced-with-board-members")) {
    experience.push("board_members");
  }
  if (experienceChoices?.includes("Someone-experienced-with-managing-directors-/-CEOs")) {
    experience.push("managing_directors");
  }
  if (experienceChoices?.includes("Someone-experienced-with-first-time-managers")) {
    experience.push("first_time_managers");
  }
  if (experienceChoices?.includes("Someone-experienced-with-managers")) {
    experience.push("managers");
  }
  if (experienceChoices?.includes("Someone-experienced-with-team-leads")) {
    experience.push("team_leads");
  }

  return { approach, experience };
};

const getNextInstanceOfDay = (day: string) => {
  if (day === "Monday") return nextMonday(new Date());
  if (day === "Tuesday") return nextTuesday(new Date());
  if (day === "Wednesday") return nextWednesday(new Date());
  if (day === "Thursday") return nextThursday(new Date());
  if (day === "Friday") return nextFriday(new Date());
  if (day === "Saturday") return nextSaturday(new Date());
  if (day === "Sunday") return nextSunday(new Date());
};

const getDate = (enquiry: GetEnquiryQuery["getEnquiry"]) => {
  const day = enquiry?.enquiryChoices?.find((choice) => choice.key === "whatDay")?.value;
  const time = enquiry?.enquiryChoices
    ?.find((choice) => choice.key === "whatTime")
    ?.value.split(",");

  if (!day) return null;

  const date = getNextInstanceOfDay(day);

  // can't be within 24h so has to look at next week
  if (differenceInHours(date, new Date()) < 24) addDays(date, 7);

  let start = startOfDay(new Date(date));
  let end = endOfDay(new Date(date));

  if (time.includes("Morning") && !time.includes("Afternoon/Evening")) {
    // morning only
    end = setHours(end, 11); // 12pm, 0 indexed
  } else if (time.includes("Afternoon/Evening") && !time.includes("Morning")) {
    // afternoon only
    start = setHours(start, 11); // 12pm, 0 indexed
  }
  return { start, end };
};

export const EnquiryResultsPage: React.FC<IProps> = () => {
  useScrollTo();
  const navigate = useNavigate();
  const features = useFeatures();
  const user = useUser();
  const companySubscription = useCompanySubscription();
  const [searchParams] = useSearchParams();
  const [forcedHold, setForcedHold] = React.useState(true);

  const enquiryQuery = useGetEnquiryQuery({ where: { id: searchParams.get("eid") } });

  const enquiry = enquiryQuery?.data?.getEnquiry;

  const filters = getFilters(enquiry);
  const date = getDate(enquiry);

  const _where = merge(
    { published: { equals: true } },
    filters.approach.length ? { approaches: { some: { value: { in: filters.approach } } } } : {},
    filters.experience.length
      ? { audienceExperiences: { some: { value: { in: filters.experience } } } }
      : {},
    user?.currentUser?.role === UserRole.Individual || !user?.currentUser
      ? { excludeIndividuals: { not: { equals: true } } }
      : {},
  );

  const demoQuery = useSearchCoachesQuery(
    {
      where: { handle: { equals: "martyn-rushton" } },
      orderBy: [{ position: OrderByArg.Asc }],
      take: 1,
      skip: 0,
    },
    { enabled: !!features?.includes("test-coach") },
  );

  const coachQuery = useSearchCoachesByAvailabilityQuery(
    {
      where: _where,
      availability: date ? { startsAt: date.start, endsAt: date.end } : {},
      take: 3,
      skip: 0,
    },
    { enabled: !!enquiry?.id, keepPreviousData: true, refetchOnWindowFocus: false },
  );

  const _backupWhere = merge(
    { published: { equals: true } },
    filters.approach.length ? { approaches: { some: { value: { in: filters.approach } } } } : {},
    user?.currentUser?.role === UserRole.Individual || !user
      ? { excludeIndividuals: { not: { equals: true } } }
      : {},
  );

  // Sometimes we don't get 3 coaches back, so we need to do a backup query without the date filter to get more
  const needsBackup =
    coachQuery?.isFetched && coachQuery?.data?.searchCoachesByAvailability?.length < 3;
  const backupCoachQuery = useSearchCoachesQuery(
    {
      where: merge({}, _backupWhere, {
        id: { notIn: coachQuery?.data?.searchCoachesByAvailability?.map((coach) => coach.id) },
      }),
      orderBy: [{ position: OrderByArg.Asc }],
      take: 3 - (coachQuery?.data?.searchCoachesByAvailability?.length ?? 0),
      skip: 0,
    },
    {
      enabled: !!enquiry?.id && needsBackup,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const finishedLoading =
    enquiry?.id && coachQuery.isFetched && (needsBackup ? backupCoachQuery.isFetched : true);

  const coaches = finishedLoading
    ? [
        ...(demoQuery?.data?.searchCoaches ?? []),
        ...(coachQuery?.data?.searchCoachesByAvailability ?? []),
        ...(backupCoachQuery?.data?.searchCoaches ?? []),
      ].slice(0, 3)
    : null;

  useEffect(() => {
    // We force the user to see loading for at least 5 seconds.
    // This is to give the user a sense that we are doing something in the background even if the queries are quick
    setTimeout(() => {
      setForcedHold(false);
    }, 1000);
  }, []);

  const coachNames = coaches
    ?.map((coach) => coach.displayName?.split(" ")[0])
    ?.reduce((acc, curr, index) => {
      if (index === coaches.length - 1) return `${acc} or ${curr}`;
      else return `${acc}, ${curr}`;
    });

  return (
    <div className="my-10 w-screen">
      <div className="mx-auto">
        <HtmlMeta title="Enquiry results" />
        <div className="flex flex-col justify-between items-center w-full px-2 xl:px-0 xl:w-[1000px] mx-auto">
          <div className="rounded-lg w-full px-4 py-6">
            <ProgressBar progress={11} />
            <div className="flex flex-col items-center justify-center mt-6">
              {!finishedLoading || forcedHold ? (
                <div className="flex flex-col w-full h-[70vh] items-center justify-center">
                  <h1 className="text-4xl mb-10">Finding the best coaches for you</h1>
                  <LoadingSpinner className="!h-12 !w-12" />
                </div>
              ) : (
                <>
                  <h1 className="text-center text-4xl font-semibold mb-6 max-w-[800px]">
                    These coaches are your top matches.
                  </h1>
                  <p className="text-lg">
                    Based on your preferences, these coaches will help you reach your goals faster.
                  </p>
                  <p className="text-lg">
                    Book your first session with {coachNames} to walk you through their approach and
                    next steps.
                  </p>

                  <div className="flex flex-wrap lg:flex-nowrap flex-col md:flex-row gap-4 justify-center">
                    {canUserAddPackage(user.currentUser, companySubscription.state, features) ? (
                      coaches.map((coach) => (
                        <ResultCoachCard
                          key={coach.id}
                          coach={coach}
                          onSelect={() =>
                            navigate(
                              `/enquiry/booking/${coach.handle}?ctx=enquiry&eid=${searchParams.get(
                                "eid",
                              )}`,
                            )
                          }
                        />
                      ))
                    ) : (
                      <div className="w-[70%] overflow-hidden mx-auto">
                        <CoachCarousel coaches={coaches} trackingPrefix="enquiry" />
                      </div>
                    )}
                    <div className="flex flex-col bg-yellow text-center rounded-2xl min-h-[500px] md:w-[250px]">
                      <div>
                        <img
                          src="/images/Book_a_session.svg"
                          alt=""
                          className="h-[250px] w-[250px]"
                        />
                      </div>
                      <div className="p-3">
                        <h2 className="text-2xl font-semibold mb-1">
                          Not who you were looking for?
                        </h2>
                        <p className="text-sm md:text-lg">
                          We have over 100 qualified coaches working with us, so you&apos;re sure to
                          find someone you want to connect with.
                        </p>
                        <a href={`/coaches?ctx=enquiry&eid=${searchParams.get("eid")}`}>
                          <Button
                            secondary
                            className="bg-white text-black w-fit"
                            rightIcon={<ArrowRight />}
                            onClick={() => {
                              Mixpanel.track("Enquiry: Show all coaches");
                            }}
                            id="show-all-coaches-enquiry"
                          >
                            Show me all coaches
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
