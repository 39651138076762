import { useGetSponsorQuery } from "$/graphql/types.generated";
import { Button } from "$/components/Button";
import { LoadingSpinner } from "../LoadingSpinner";
import { useUser } from "$/state/user";
import { WEB_ASSETS_URL } from "$/configs/app.config";

interface RestrictedAccountProps {
  companyId: string;
  isSponsor?: boolean;
}

const WithCheck = ({ text }: { text: string }) => {
  return (
    <div className="flex gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <rect width="24" height="24" rx="6" fill="#006A39" />
        <path
          d="M5.56006 11.5707L10.0681 16.2934L18.4401 7.7067"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {text}
    </div>
  );
};

export function RestrictedAccount({ companyId, isSponsor }: RestrictedAccountProps) {
  const { data, isLoading } = useGetSponsorQuery({ companyId });

  return (
    <div className="flex flex-col h-screen w-full items-center ">
      <div className="max-w-[1000px]">
        <div>
          <img src={`${WEB_ASSETS_URL}/More_Happi_logo.svg`} alt="logo" className="h-[100px]" />
        </div>
      </div>
      <div className="max-w-[1000px] bg-white p-6 text-center rounded-md flex flex-col justify-between">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="w-full h-full flex justify-center">
            <div>
              <div className="text-center max-w-[1000px]">
                <img src="/images/Sponsor_checklist.svg" alt="" className="h-[100px] w-[100px]" />
                <div className="mb-8">
                  <h1 className="mb-4"> Your company account is no longer active </h1>
                  <h3 className="mb-4">
                    Restart your career and personal development by creating a team account
                  </h3>
                  <p>Solve burnout, overwhelm, challenges, conflicts and problems</p>
                </div>
                <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
                  <div className="bg-beige rounded-xl p-4 w-full md:w-[47%] text-center h-52 flex flex-col items-center">
                    <h2>Get back on track</h2>
                    {!isSponsor ? (
                      <p className="w-full italic mt-4">
                        If you&apos;d like your administrator to restart your More Happi
                        subscription, please contact them{" "}
                        {data?.listUsers?.[0]?.email && (
                          <a href={`mailto:${data?.listUsers?.[0]?.email}`}>
                            {data?.listUsers?.[0]?.email}
                          </a>
                        )}
                        <br />
                        For anything else, you can contact our support at{" "}
                        <a href={"mailto:hey@morehappi.com"}>hey@morehappi.com</a>
                      </p>
                    ) : (
                      <p className="w-full mt-4">
                        If you&apos;d like to restart your More Happi subscription, please contact
                        support at <a href={"mailto:hey@morehappi.com"}>hey@morehappi.com</a>
                      </p>
                    )}
                  </div>
                  <div className="flex flex-col gap-8 items-start w-full md:w-[47%]">
                    <WithCheck text="One-to-one sessions with a professional, trained coach" />
                    <WithCheck text="100% confidential sessions" />
                    <WithCheck text="Easy access via web app" />
                    <WithCheck text="Available weekdays, evenings and weekends" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
