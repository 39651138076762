import { HtmlMeta } from "$/components/HtmlMeta";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import { CoacheeSessionCard } from "$/components/SessionCard/CoacheeSessionCard";
import { Session, useGetSessionByIdQuery } from "$/graphql/types.generated";
import { useScrollTo } from "$/hooks";
import { IPageBaseProps } from "$/interfaces";
import { useUser } from "$/state/user";
import { DateTime } from "luxon";
import qs from "query-string";
import React from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";

interface IProps extends IPageBaseProps {}

export const BookingSuccess: React.FC<IProps> = () => {
  useScrollTo();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { data, isLoading } = useGetSessionByIdQuery({
    id: String(searchParams.get("session")),
  });

  const session = data?.getSession;

  const user = useUser();

  const query = qs.parse(location.search);

  const startAt = DateTime.fromISO(String(query?.start_at).replace(" ", "+"));
  const date = startAt.toFormat("dd/MM/yyyy");
  const time = startAt.toFormat("HH:mm ");

  return (
    <div className="my-4">
      <HtmlMeta title="Booking confirmed" />
      <div className="mx-auto mb-20 max-w-[800px]">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="flex justify-center items-center w-full mt-8">
            <div className="w-full mx-4 md:mx-0 md:w-[700px] gap-y-4 flex flex-col items-center">
              <img
                src="/images/Landing_Page.svg"
                alt=""
                className="text-center  h-[120px] w-[120px]"
              />
              <h1 className="text-4xl text-center font-semibold mb-0">
                You&apos;re in! Meet {session?.coach?.displayName?.split(" ")?.[0]} online{" "}
                {DateTime.fromISO(session?.startsAt).toFormat("DDDD")}
              </h1>
              <p className="text-lg text-center">
                Thanks for booking your session with More Happi! We sent you a confirmation email
                with the joining details. Make sure to reflect on topics you&apos;d like to bring to
                the session on {DateTime.fromISO(session?.startsAt).weekdayLong}, so that you can
                get the most out of it.
              </p>
              <div className="w-full md:w-[600px] mx-auto">
                <CoacheeSessionCard
                  session={session as Session}
                  timescale="future"
                  hideSubActions
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
