import cn from "classnames";
import { useState } from "react";

const Connector = () => {
  return (
    <div className="w-full align-center items-center align-middle content-center flex z-10">
      <div className="w-full bg-lightBley opacity-40 rounded items-center align-middle align-center flex-1">
        <div className="bg-lightBley opacity-40 text-xs leading-none py-[2px] text-center rounded w-full" />
      </div>
    </div>
  );
};

const stages = [
  { start: 0, end: 3, color: "red", text: "Very unlikely" },
  { start: 4, end: 5, color: "orange", text: "Somewhat unlikely" },
  { start: 6, end: 7, color: "lightGreen", text: "Somewhat likely" },
  { start: 8, end: 10, color: "green", text: "Very likely" },
];

interface StepProps {
  dirty: boolean;
  selected: boolean;
  onClick: () => void;
  step: number;
}

const Step = ({ dirty, selected, onClick, step }: StepProps) => {
  const curr = stages.find((stage) => step >= stage.start && step <= stage.end);

  const showLabel = selected || (!dirty && (step === 0 || step === 10));
  return (
    <div className="relative">
      {showLabel && (
        <div
          className={cn("absolute -top-10", {
            "-left-[450%]": dirty && curr?.color === "red",
            "-left-[430%]": dirty && curr?.color === "lightGreen",

            "-left-[500%]": curr?.color === "orange",
            "-left-[300%]": dirty && curr?.color === "green",
            "-left-[100%]": !dirty && step === 0,
            "-left-[600%]": !dirty && step === 10,
          })}
        >
          <div
            className={cn("flex text-sm whitespace-nowrap px-2 gap-x-1 rounded-md", {
              "bg-beige": !dirty && !selected,
              "bg-red text-white": dirty && curr?.color === "red",
              "bg-orange": curr?.color === "orange",
              "bg-bottleGreen text-white": dirty && curr?.color === "green",
              "bg-lightGreen text-white": dirty && curr?.color === "lightGreen",
            })}
          >
            <span className="font-bold">{step}</span> {curr?.text}
          </div>
        </div>
      )}
      <div className="flex-1 cursor-pointer" onClick={onClick}>
        <div
          className={cn(
            "w-3 h-3 border-2 border-grey-light mx-auto rounded-full text-lg text-white flex items-center z-20 relative",
            {
              "bg-lightBley": !selected,
              "scale-[2.5] md:scale-[3]": selected,
              "bg-red": selected && curr?.color === "red",
              "bg-orange": selected && curr?.color === "orange",
              "bg-bottleGreen": selected && curr?.color === "green",
              "bg-lightGreen": selected && curr?.color === "lightGreen",
            },
          )}
        />
      </div>
    </div>
  );
};

interface NPSProps {
  onChange: (value: number) => void;
}

export function NPS({ onChange }: NPSProps) {
  const [selected, setSelected] = useState<number | undefined>();
  const [dirty, setDirty] = useState(false);
  const stepArr = Array.from({ length: 11 });
  return (
    <div className="mx-auto my-4 border-b-2 pb-4">
      <div className="flex pb-3 items-center">
        {stepArr.map((_, i) => (
          <>
            <Step
              selected={i === selected}
              onClick={() => {
                onChange(i);
                setDirty(true);
                setSelected(i);
              }}
              step={i}
              dirty={dirty}
            />
            {stepArr.length - 1 !== i && <Connector />}
          </>
        ))}
      </div>
    </div>
  );
}
