export function MapPin({ fill = "none", stroke = "beige", size = 16 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size * 1.4}
      viewBox="0 0 17 23"
      fill={fill}
    >
      <path
        d="M8.79541 0.418091C6.80629 0.418091 4.89863 1.20827 3.49211 2.61479C2.08559 4.02131 1.29541 5.92897 1.29541 7.91809C1.29541 14.6681 8.79541 22.5431 8.79541 22.5431C8.79541 22.5431 16.2954 14.6681 16.2954 7.91809C16.2954 5.92897 15.5052 4.02131 14.0987 2.61479C12.6922 1.20827 10.7845 0.418091 8.79541 0.418091V0.418091Z"
        stroke={stroke}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.79541 10.9181C10.4523 10.9181 11.7954 9.57494 11.7954 7.91809C11.7954 6.26124 10.4523 4.91809 8.79541 4.91809C7.13856 4.91809 5.79541 6.26124 5.79541 7.91809C5.79541 9.57494 7.13856 10.9181 8.79541 10.9181Z"
        stroke={stroke}
        fill={stroke}
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
