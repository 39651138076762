import { Fragment, ReactNode, useEffect, useRef, useState } from "react";

import { HtmlMeta } from "$/components/HtmlMeta";
import { Mixpanel } from "$/tracking";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { Button } from "$/components/Button";
import {
  OrderByArg,
  UserRole,
  useCreateUserOnobardingChoiceMutation,
  useSearchCoachesByAvailabilityQuery,
} from "$/graphql/types.generated";
import { useUser } from "$/state/user";
import { useMachine } from "@xstate/react";
import { onboardingMachine } from "./components/machine";
import { ScrollProvider, useScroll } from "$/utils/scroll";
import cn from "classnames";
import {
  doYouKnowHowCoachingWorks,
  introState,
  coachingExplanation,
  whatDoYouWantHelpWith,
  plan,
  tellMeMore,
  oneSession,
  contextForCoach,
  timeframe,
} from "./components/states";
import UserMessage from "./components/UserMessage";
import { TextArea } from "$/components/TextArea";
import { merge, orderBy } from "lodash";
import MHMessage from "./components/MHMessage";
import { Carousel } from "react-responsive-carousel";
import CoachCard from "./components/CoachCard";
import dayjs from "dayjs";
import { LoadingSpinner } from "$/components/LoadingSpinner";
import CryptoJS from "crypto-js";

const getStates = (loggedIn: boolean) => [
  { state: "intro", data: introState() },
  { state: "doYouKnowHowCoachingWorks", data: doYouKnowHowCoachingWorks() },
  { state: "coachingExplanation", data: coachingExplanation() },
  { state: "whatDoYouWantHelpWith", data: whatDoYouWantHelpWith() },
  { state: "plan", data: plan(loggedIn) },
  { state: "tellMeMore", data: tellMeMore(loggedIn) },
  { state: "oneSession", data: oneSession(loggedIn) },
  { state: "contextForCoach", data: contextForCoach() },
  { state: "timeframe", data: timeframe() },
];

const MHMessageBlock = ({ messages, loading, state }) => {
  if (!messages) return null;

  return messages.map((message, index) => (
    <Fragment key={`message-${message.split(" ").join("-")}`}>
      <MHMessage
        firstOfBlock={index === 0}
        delay={index === 0 ? 0 : index === 1 ? 2000 : 2000 + (index - 1) * 4000}
        loadingTime={index === 0 ? 2000 : 2000 + index * 4000}
        noDelay={!loading}
        text={message}
      />
    </Fragment>
  ));
};

const FreeText = ({ label, submit, ...rest }) => {
  const [textAreaValue, setTextAreaValue] = useState("");
  return (
    <div>
      <TextArea placeholder={label} onChange={(event) => setTextAreaValue(event.target.value)} />
      <div className="flex justify-between mt-1 gap-x-3">
        <Button
          tertiary
          onClick={() => {
            submit(rest.onSkip);
            Mixpanel.track("Onboarding chat: Free text skipped");
          }}
          className="grow"
        >
          Skip
        </Button>
        <Button
          primary
          onClick={() => {
            submit(rest.onSubmit, textAreaValue);
            Mixpanel.track("Onboarding chat: Free text submitted");
          }}
          className="grow"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

const Chat = () => {
  const user = useUser();
  const states = getStates(!!user.currentUser);
  const { scrollToBottom } = useScroll();
  const [state, send, service] = useMachine(onboardingMachine);

  const [currentState, setCurrentState] = useState("intro");
  const [chat, setChat] = useState<Array<ReactNode>>([]);
  const [formValues, setFormValues] = useState({});
  const [searchTimeframe, setSearchTimeframe] = useState({
    startsAt: dayjs().endOf("hour").add(1, "day").toDate(),
    endsAt: dayjs().endOf("hour").add(1, "week").toDate(),
  });
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [carouselSlide, setCarouselSlide] = useState(0);

  const createUserOnboardingChoice = useCreateUserOnobardingChoiceMutation();

  useEffect(() => {
    if (formValues?.timeframe === "this-week") {
      setSearchTimeframe({
        startsAt: dayjs().endOf("hour").add(1, "day").toDate(),
        endsAt: dayjs().endOf("hour").add(1, "week").toDate(),
      });
    } else if (formValues?.timeframe === "next-week") {
      setSearchTimeframe({
        startsAt: dayjs().endOf("hour").add(1, "week").toDate(),
        endsAt: dayjs().endOf("hour").add(2, "week").toDate(),
      });
    } else if (formValues?.timeframe === "flexible") {
      setSearchTimeframe({
        startsAt: dayjs().endOf("hour").add(1, "day").toDate(),
        endsAt: dayjs().endOf("hour").add(1, "month").toDate(),
      });
    }
  }, [formValues?.timeframe]);

  const { options, preOptionsText, postOptionsText } =
    states.find((_s) => _s.state === state.value)?.data ?? {};

  const _where = merge(
    {
      events: {
        some: {
          startsAt: {
            gte: searchTimeframe.startsAt,
          },
          endsAt: {
            lte: searchTimeframe.endsAt,
          },
        },
      },
    },
    user?.currentUser?.role === UserRole.Individual || !user?.currentUser
      ? { excludeIndividuals: { not: { equals: true } } }
      : {},
  );

  const coaches = useSearchCoachesByAvailabilityQuery(
    {
      where: _where,
      take: 20,
      skip: 0,
    },
    {
      enabled: state.value === "finish",
    },
  );

  const topCoaches = orderBy(
    coaches.data?.searchCoachesByAvailability,
    [
      (row) =>
        row?.events?.filter(
          (event) =>
            dayjs().add(1, "day").isBefore(event.startsAt) &&
            dayjs().add(1, "week").isAfter(event.startsAt),
        ).length,
    ],
    ["desc"],
  ).splice(0, 4);

  useEffect(() => {
    Mixpanel.track("Onboarding chat: intro");
  }, []);

  useEffect(() => {
    setButtonsDisabled(true);
    if (state.value !== currentState) {
      Mixpanel.track(`Onboarding chat: ${currentState} to ${state.value}`);
      setCurrentState(state.value);
    }

    const messages = states.find((_s) => _s.state === state.value)?.data?.messages.length;
    if (!messages) scrollToBottom();

    setTimeout(
      () => {
        setButtonsDisabled(false);
        scrollToBottom();
      },
      2000 + (messages - 1) * 4000,
    );
  }, [state.value]);

  useEffect(() => {
    if (!coaches.isLoading) scrollToBottom();
  }, [coaches.isLoading]);

  useEffect(() => {
    const updateChoices = async () => {
      if (state.value === "finish" && user?.currentUser?.id) {
        await Promise.allSettled(
          Object.entries(formValues).map(([key, value]) =>
            createUserOnboardingChoice.mutateAsync({
              data: {
                user: { connect: { id: user.currentUser?.id } },
                key: key as string,
                value: value as string,
              },
            }),
          ),
        );
      }
      sessionStorage.setItem("happiPathHide", "true");
    };
    updateChoices();
  }, [state.value]);

  return (
    <>
      <div className="conversation-body flex flex-col gap-y-2">
        {chat}
        <MHMessageBlock
          messages={states?.find((_s) => _s.state === state.value)?.data?.messages}
          loading
          state={formValues}
        />
      </div>
      {state.value !== "finish" ? (
        <>
          {!buttonsDisabled && preOptionsText && (
            <p className="w-full text-center mt-12 text-lg">{preOptionsText}</p>
          )}

          <div
            className={cn("flex flex-wrap gap-2 ", {
              "flex-col": options?.length < 4,
              "mt-12": !preOptionsText,
            })}
          >
            {options?.map((option) =>
              option.action === "freeText" ? (
                <>
                  {!buttonsDisabled && (
                    <FreeText
                      {...option}
                      submit={(_state, _value) => {
                        send({ type: _state });

                        const userMessage = _value?.length ? _value : "Skipped";

                        const newMessages = [
                          <MHMessageBlock
                            messages={states.find((_s) => _s.state === state.value)?.data.messages}
                            loading={false}
                            state={formValues}
                          />,
                          <UserMessage>{userMessage}</UserMessage>,
                        ];

                        setChat((prev) => [...prev, ...newMessages]);

                        setFormValues((prev) => ({
                          ...prev,
                          [state.value]: CryptoJS.AES.encrypt(userMessage, "somekey").toString(),
                        }));
                        setButtonsDisabled(true);
                      }}
                    />
                  )}
                </>
              ) : (
                !buttonsDisabled && (
                  <Button
                    primary
                    large
                    disabled={buttonsDisabled}
                    id={`onboarding-${currentState}-${
                      option.state ?? option.label?.replaceAll(" ", "-")
                    }`}
                    className={cn({
                      "w-full": options?.length < 4,
                      "w-full md:w-fit": options?.length >= 4,
                    })}
                    onClick={() => {
                      if (option.state) {
                        send({ type: option.state });

                        const newMessages = [
                          <MHMessageBlock
                            messages={states.find((_s) => _s.state === state.value)?.data.messages}
                            loading={false}
                            state={formValues}
                          />,
                          <UserMessage>{option.label}</UserMessage>,
                        ];
                        setChat((prev) => [...prev, ...newMessages]);

                        if (option.key) {
                          setFormValues((prev) => ({ ...prev, [state.value]: option.key }));
                        }
                        setButtonsDisabled(true);
                      }
                      if (option.link) {
                        window.open(option.link, "_blank", "noreferrer");
                      }
                    }}
                  >
                    {option.label}
                  </Button>
                )
              ),
            )}
          </div>

          {!buttonsDisabled && postOptionsText && (
            <p className="w-full text-center mt-2 text-lg">{postOptionsText}</p>
          )}
        </>
      ) : (
        <div className="mt-4">
          {coaches.isLoading ? (
            <div className="w-full h-[300px] bg-white flex flex-col justify-center items-center rounded-3xl px-4">
              <h1 className="text-xl md:text-3xl text-midnight text-center mb-12 font-normal">
                Selecting your perfect coaches from our library of 100+ professionals
              </h1>
              <LoadingSpinner />
            </div>
          ) : (
            <div>
              <Carousel
                centerMode
                centerSlidePercentage={90}
                showArrows={false}
                swipeable
                emulateTouch
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                selectedItem={carouselSlide}
                onChange={(index) => setCarouselSlide(index)}
              >
                {topCoaches.map((coach) => (
                  <div className="px-2 xl:w-[600px]" key={coach.handle}>
                    <CoachCard coach={coach} />
                  </div>
                ))}
                <div className="bg-yellow py-4 mx-2 px-5 md:px-20 rounded-3xl h-[500px] md:h-[300px] flex flex-col justify-center items-center">
                  <h1 className="text-3xl"> Not who you were looking for?</h1>
                  <p className="text-lg">
                    We have over 100 qualified coaches working with us, so you&apos;re sure to find
                    someone you want to connect with.
                  </p>
                  <a href="/coaches">
                    <Button
                      secondary
                      className="bg-white text-black w-fit"
                      large
                      rightIcon={<ArrowRight />}
                      onClick={() => {
                        Mixpanel.track("Onboarding chat: Show all coaches");
                      }}
                      id="onboarding-all-coaches"
                    >
                      Show me all coaches
                    </Button>
                  </a>
                </div>
              </Carousel>
              <div className="flex justify-between mt-4 items-center">
                <div
                  className={cn(
                    "rounded-full flex items-center justify-center h-[44px] w-[44px] bg-yellow cursor-pointer hover:opacity-80",
                    {
                      "opacity-60 hover:opacity-60 cursor-not-allowed": carouselSlide === 0,
                    },
                  )}
                  onClick={() => setCarouselSlide((prev) => (prev > 0 ? prev - 1 : prev))}
                >
                  <ArrowLeft />
                </div>
                <div className="flex gap-x-2">
                  <div
                    className={cn("rounded-full h-[15px] w-[15px] bg-yellow opacity-40", {
                      "!opacity-100": carouselSlide === 0,
                    })}
                  />
                  <div
                    className={cn("rounded-full h-[15px] w-[15px] bg-yellow opacity-40", {
                      "!opacity-100": carouselSlide === 1,
                    })}
                  />
                  <div
                    className={cn("rounded-full h-[15px] w-[15px] bg-yellow opacity-40", {
                      "!opacity-100": carouselSlide === 2,
                    })}
                  />
                  <div
                    className={cn("rounded-full h-[15px] w-[15px] bg-yellow opacity-40", {
                      "!opacity-100": carouselSlide === 3,
                    })}
                  />
                  <div
                    className={cn("rounded-full h-[15px] w-[15px] bg-yellow opacity-40", {
                      "!opacity-100": carouselSlide === 4,
                    })}
                  />
                </div>
                <div
                  className={cn(
                    "rounded-full flex items-center justify-center h-[44px] w-[44px] bg-yellow cursor-pointer hover:opacity-80",
                    {
                      "opacity-60 hover:opacity-60 cursor-not-allowed": carouselSlide === 4,
                    },
                  )}
                  onClick={() => setCarouselSlide((prev) => (prev < 4 ? prev + 1 : prev))}
                >
                  <ArrowRight />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const Onboarding = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [chatRef, setChatRef] = useState();
  useEffect(() => {
    setChatRef(ref);
  }, [ref.current]);

  return (
    <div className="pb-4">
      <HtmlMeta title="Sign Up" />

      <div>
        <div className="h-[calc(100dvh-64px)] overflow-y-scroll overflow-x-hidden px-2 w-screen flex flex-col items-center">
          <div
            className="h-full w-full md:w-[660px]  mx-auto pt-10 mb-0 md:my-10 pb-4 overflow-y-scroll scrollbar-hide flex flex-col justify-between"
            ref={ref}
          >
            <ScrollProvider scrollRef={chatRef}>
              <Chat />
            </ScrollProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
