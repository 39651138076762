import React from "react";

export const usePageCount = (page: number, limit: number) => {
  return React.useMemo(() => {
    return limit * page;
  }, [limit, page]);
};

export const useHideLoadMore = (count: number, total?: number | null) => {
  return React.useMemo(() => {
    return total ? count >= total : false;
  }, [count, total]);
};
