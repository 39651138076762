import React from "react";

import { Button, Popover, Space } from "antd";

import { useUser } from "$/state/user";

import { ICompBaseProps } from "$/interfaces";

import { ActivationState, TeamMember, UserRole } from "$/graphql/types.generated";

interface IProps extends ICompBaseProps {
  teamMember: TeamMember;
  onRemoveTeamMember: (teamMember: TeamMember) => void;
  onRestoreTeamMember: (teamMember: TeamMember) => void;
  onChangeSponsor: (teamMember: TeamMember) => void;
  onResetPassword: (teamMember: TeamMember) => void;
}

export const TeamListActions: React.FC<IProps> = ({
  teamMember,
  onChangeSponsor,
  onRemoveTeamMember,
  onRestoreTeamMember,
  onResetPassword,
}) => {
  const user = useUser();

  const canRemove = user?.currentUser?.id !== teamMember?.user?.id && user?.currentUser?.isAdmin;

  const menu = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {user.currentUser?.isSponsor && (
        <Button
          type="link"
          disabled={teamMember?.user?.role === UserRole.Sponsor}
          onClick={() => onChangeSponsor(teamMember)}
        >
          Make Sponsor
        </Button>
      )}
      <Button
        type="link"
        disabled={
          teamMember?.user?.activationState === ActivationState.Disabled ||
          teamMember?.user?.role === UserRole.Sponsor ||
          teamMember.archivedAt
        }
        onClick={() => onResetPassword(teamMember)}
      >
        Re-send Invite
      </Button>
      {teamMember.user?.activationState === ActivationState.Disabled ? (
        <Button type="link" onClick={() => onRestoreTeamMember(teamMember)} disabled={!canRemove}>
          Restore
        </Button>
      ) : (
        <Button type="link" onClick={() => onRemoveTeamMember(teamMember)} disabled={!canRemove}>
          Remove
        </Button>
      )}
    </div>
  );

  return (
    <Space size="middle">
      <Popover
        content={menu}
        title={`${teamMember?.user?.firstName} ${teamMember?.user?.lastName}`}
      >
        <Button>Action</Button>
      </Popover>
    </Space>
  );
};
