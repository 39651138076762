import { Stars } from "$/components/Icons/Stars";

export const introState = () => {
  const MHIntro = `Welcome to More Happi! We're a coaching platform, here to help you untangle problems and challenges into actionable plans.
  
  Take this 2 minute quiz to get matched with 3 coaches who can help. No account or payment required unless you are ready to book your first session.`;

  const options = [{ label: "Sounds good, let's start", state: "NEXT" }];

  return {
    messages: [MHIntro],
    options,
  };
};

export const doYouKnowHowCoachingWorks = () => {
  const MH = `Do you know what coaching does?`;

  const options = [
    { label: "Yes, I understand what coaching does", state: "YES", key: "yes" },
    { label: "I have an idea, but tell me more", state: "SOMEWHAT", key: "somewhat" },
    { label: "No, I'm new to this", state: "NO", key: "no" },
  ];

  return {
    messages: [MH],
    options,
  };
};

export const coachingExplanation = () => {
  const MH1 = `Coaches use tools to help you look at a problem differently, and turn big plans into small, actionable steps.`;
  const MH2 = `It's not therapy, you won't be analysing the past.\n\nInstead, you will work out what you want to change, and what action you can take to change things.`;

  const MH3 = `Each coaching session will help you get one step closer to your goals, may it be learning a new skill or resolving a conflict.`;

  const options = [
    { label: "Let's get started", state: "NEXT" },
    { label: "Show me some testimonials", link: "https://morehappi.com/blog/category/reviews/" },
  ];

  return {
    messages: [MH1, MH2, MH3],
    options,
    postOptionsText: (
      <div className="flex w-full justify-center  items-center">
        <Stars stars="4.7" />
        <span className="font-semibold ml-1">4.7</span>/5 from 3,250 reviews{" "}
      </div>
    ),
  };
};

export const whatDoYouWantHelpWith = () => {
  const MH = `Our coaches are qualified through top training schools (such as ICF, AC and EMCC), and are here to help you. Out of these options, what feels most important to you right now?`;

  const options = [
    { label: "Manage stress and avoid burnout", state: "NEXT", key: "burnout" },
    {
      label: "Resolve conflicts and communicate better",
      state: "NEXT",
      key: "conflict-and-communication",
    },
    { label: "Cope with anxiety and self-doubt", state: "NEXT", key: "anxiety" },
    {
      label: "Solve problems and make clear decisions",
      state: "NEXT",
      key: "solving-probelms-and-making-decisions",
    },
    { label: "Develop better habits for my wellbeing", state: "NEXT", key: "developing-habits" },
    { label: "Feel more confident in new situations", state: "NEXT", key: "confidence" },
    { label: "Use my time more effectively", state: "NEXT", key: "time-management" },
    { label: "Set and reach career or life goals", state: "NEXT", key: "career-and-life-goals" },
  ];

  return {
    messages: [MH],
    options,
    preOptionsText:
      "Select the one that feels most important to you right now. You will be able to adapt your needs with your coach later on.",
  };
};

export const plan = (loggedIn: boolean) => {
  const MH1 = `We designed coaching courses which will help you achieve your goals. 

  Each session is held online and lasts 45 minutes.`;

  const MH2 = `**Session 1: Get to Know Your Coach** Meet your coach and share your goals. Talk about what you want to achieve.
  
  **Session 2: Understand Your Challenge** Dig deeper into your issue. Find out why it's happening.

  **Session 3: Set Small Goals** Work together to set small, doable goals. Break your big challenge into smaller steps.

  **Session 4: Make Progress** Check how you're doing on your goals. Talk about any problems you're facing.

  **Session 5: Make Progress (part 2)** Keep an eye on your goals. Think about what you've learned.

  **Session 6: Look Back and Forward** Think about what you've achieved.Talk about what's next for you.`;

  const MH3 = `Our customers have experienced transformative results from coaching:

  They feel **80% more confident, 73% better in relationships, 70% more productive and 63% happier**.
  `;

  const options = [
    {
      label: "Sounds good! Let's find a coach",
      state: loggedIn ? "CONTEXT_FOR_COACH" : "TIMEFRAME",
    },
    { label: "Tell me more about how to prepare for coaching sessions", state: "TELL_ME_MORE" },
    { label: "I only want one session", state: "ONE_SESSION" },
  ];

  return {
    messages: [MH1, MH2, MH3],
    options,
  };
};

export const tellMeMore = (loggedIn: boolean) => {
  const MH =
    "Each coaching session is held online via Google Meets, and lasts 45 minutes. During that time, you and your coach will discuss your needs, any pressing matters and what you'd like to achieve with that session. You're in charge of how often you want a session, and you can change coaches as often as you like.";

  const options = [
    {
      label: "Sounds good! Let's find a coach",
      state: loggedIn ? "CONTEXT_FOR_COACH" : "TIMEFRAME",
    },
    { label: "I only want one session", state: "ONE_SESSION" },
  ];

  return {
    messages: [MH],
    options,
  };
};

export const oneSession = (loggedIn: boolean) => {
  const MH =
    "You can absolutely start with just one session, although you will see much more benefits by having several. Coaching is like learning a new skill: it only gets better with time!";

  const options = [
    {
      label: "Sounds good! Let's find a coach",
      state: loggedIn ? "CONTEXT_FOR_COACH" : "TIMEFRAME",
    },
    { label: "Tell me more about how to prepare for coaching sessions", state: "TELL_ME_MORE" },
  ];

  return {
    messages: [MH],
    options,
  };
};

export const contextForCoach = () => {
  const MH =
    "Is there any context that would be important for your coach to know? We have coaches qualified to support neurodivergent people too.";

  const options = [
    {
      label:
        "Write about your situation in your own words, e.g. \nI have ADHD and I'm struggling with time management \nI want to be more confident in meetings",
      action: "freeText",
      onSkip: "NEXT",
      onSubmit: "NEXT",
    },
  ];

  return {
    messages: [MH],
    options,
    preOptionsText:
      "Write about your situation in your own words. We will only ever share this with the coach you select.",
  };
};

export const timeframe = () => {
  const MH =
    "Finally, when would you like your first session to take place? We'll find coaches who are available when you need them.";

  const options = [
    { label: "This week", state: "NEXT", key: "this-week" },
    { label: "Next week", state: "NEXT", key: "next-week" },
    { label: "I'm flexible", state: "NEXT", key: "flexible" },
  ];

  return {
    messages: [MH],
    options,
  };
};
