export function Medium({ size = 75 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 76 75"
      fill="none"
    >
      <path
        d="M38 75C58.7107 75 75.5 58.2107 75.5 37.5C75.5 16.7893 58.7107 0 38 0C17.2893 0 0.5 16.7893 0.5 37.5C0.5 58.2107 17.2893 75 38 75Z"
        fill="#F7A000"
      />
      <path
        d="M29.88 28.8701C31.0619 28.8701 32.02 27.912 32.02 26.7301C32.02 25.5482 31.0619 24.5901 29.88 24.5901C28.6981 24.5901 27.74 25.5482 27.74 26.7301C27.74 27.912 28.6981 28.8701 29.88 28.8701Z"
        fill="#504F4E"
      />
      <path
        d="M44.7979 28.8352C45.9645 28.6458 46.7567 27.5466 46.5673 26.38C46.378 25.2133 45.2787 24.4211 44.1121 24.6105C42.9455 24.7999 42.1533 25.8991 42.3426 27.0657C42.532 28.2323 43.6313 29.0246 44.7979 28.8352Z"
        fill="#504F4E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6299 48.3999C28.6299 47.7095 29.1895 47.1499 29.8799 47.1499H44.4548C45.1452 47.1499 45.7048 47.7095 45.7048 48.3999C45.7048 49.0903 45.1452 49.6499 44.4548 49.6499H29.8799C29.1895 49.6499 28.6299 49.0903 28.6299 48.3999Z"
        fill="#504F4E"
      />
    </svg>
  );
}
