interface NoSessionCardProps {
  message: string;
  imageSrc: string;
  altText: string;
}
export const NoSessionCard = ({ message, imageSrc, altText }: NoSessionCardProps) => {
  return (
    <div className="bg-white p-4 rounded-xl">
      <div className="flex flex-col md:flex-row items-center text-center gap-x-4">
        <img src={imageSrc} alt={altText} />
        <div className="text-2xl">{message}</div>
      </div>
    </div>
  );
};
