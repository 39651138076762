import timezones from "timezones-list";
import { Select, SelectProps } from "../index";
import { useState } from "react";

interface TimezoneSelectProps extends SelectProps {}

export function TimezoneSelect({ label, value, onChange, ...props }: TimezoneSelectProps) {
  return (
    <Select
      label={label ?? "Timezone"}
      defaultValue={{
        label: timezones.find(({ tzCode }) => tzCode === value)?.label,
        value: timezones.find(({ tzCode }) => tzCode === value)?.tzCode,
      }}
      options={timezones.map((opt) => ({ label: opt?.label, value: opt?.tzCode }))}
      onChange={(newVal) => onChange(newVal.value)}
      {...props}
    />
  );
}
