import { Form, Input, Typography, message } from "antd";

import { useUser } from "$/state/user";

import { IPageBaseProps } from "$/interfaces";
import { useUpdateUserMutation } from "$/graphql/types.generated";
import { useEffect, useState } from "react";
import { TimezoneSelect } from "$/components/Select/Selectors/Timezone";
import { Button } from "$/components/Button";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "$/components/Toaster";

interface IProps extends IPageBaseProps {}

const { Title } = Typography;

const schema = z.object({
  timezone: z.string().min(1, "Please select a timezone"),
});

export const UserTimezoneSettings = (props: IProps) => {
  const user = useUser();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: { timezone: user.currentUser?.timezone ?? "Europe/London" },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      toast.success("Timezone updated successfully", "");
    }
  }, [isSubmitSuccessful]);

  return (
    <>
      <Title level={2}>Update Timezone</Title>

      <div>
        <Controller
          name="timezone"
          control={control}
          render={({ field }) => <TimezoneSelect {...field} error={errors.timezone} />}
        />{" "}
        <Button
          primary
          large
          className="w-full"
          isSubmitting={isSubmitting}
          onClick={(e) => {
            handleSubmit(async (values: z.infer<typeof schema>) => {
              await user.update({
                timezone: values.timezone,
              });
            })(e);
          }}
        >
          Save changes
        </Button>
      </div>
    </>
  );
};
