import { useState } from "react";
import { Button } from "$/components/Button";
import { Eye, EyeOff } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Auth } from "@aws-amplify/auth";
import * as z from "zod";
import { Input } from "$/components/Input";

const loginSchema = z.object({
  email: z.string().min(1, "Enter your password"),
  password: z.string().min(1, "Enter your password"),
});

export const LoginForm = ({ onSubmit }: { onSubmit: (email?: string) => Promise<void> | void }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-4xl">Sign in to your workspace</h2>

      <Input
        type="email"
        name="email"
        id="email"
        label="Email"
        required
        register={register}
        pattern={{
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        }}
        error={errors.email}
      />
      <Input
        type={showPassword ? "text" : "password"}
        name="password"
        label="Password"
        id="password"
        required
        rightIcon={
          showPassword ? (
            <EyeOff onClick={() => setShowPassword(false)} />
          ) : (
            <Eye onClick={() => setShowPassword(true)} />
          )
        }
        register={register}
        error={errors.password}
      />
      {error && <p className="bg-pink px-4 py-2 text-lg rounded-lg">{error}</p>}

      <Button
        primary
        large
        isSubmitting={isSubmitting}
        disabled={isSubmitting}
        className="w-[50%]"
        onClick={(e) => {
          handleSubmit(async (values: z.infer<typeof loginSchema>) => {
            try {
              await Auth.signIn(values.email, values.password);
              await onSubmit(values.email);
            } catch (err) {
              setError(err.message);
            }
          })(e);
        }}
      >
        Sign in
      </Button>
    </div>
  );
};
